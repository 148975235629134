import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getFormValues } from "redux-form";
/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "@language";
import { BaseComponent } from "@baseComponent";
import { reduxForm } from "redux-form";
import { Fragment } from "react";

import {
  isProductCateSubCateModalOpen,
  allSelectedSubcategorisID,
  fetchItemSpecification,
  fetchDiscountList,
  fetchVariationMaster,
  iscatsubCatUpdated,
} from "@productmanagementActions";

// Functions Import
import { ProductTextLocalized, classNames } from "@commonFunction";
import { SearchIcon } from "@heroicons/react/solid";

// Import css
import "./style.css";
import toast from "react-hot-toast";
var cateSubObject = [];

class CategorySubcategory extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: "",
      // defaultCateId: _.head(
      //   _.filter(this.props.productCategorylist, { isactive: true })
      // ).id, //commented for crashes
      defaultCateId: 1,
      catesearchText: "",
      cateSubObject: {},
      subCateArray: [], // We will store actual subcategory
    };

    this.closeModal = this.closeModal.bind(this);
    this.categorysearch = this.categorysearch.bind(this);
    this._handleSelectedSubCategorySubmit =
      this._handleSelectedSubCategorySubmit.bind(this);
  }

  componentDidMount() {
    if (
      this.props.productCategorylist &&
      this.props.productCategorylist.length > 0
    ) {
      let defaultCateId = _.head(
        _.filter(this.props.productCategorylist, { isactive: true })
      ).id;
      this.setState({ defaultCateId: defaultCateId });
    }
  }
  componentWillUnmount() {
    this.setState({
      open: false,
      headerText: "",
      catesearchText: "",
      cateSubObject: {},
      subCateArray: [],
      defaultCateId: "",
    });
    cateSubObject = [];
    this.props.reset("addProductCateSubcateForm");
  }

  renderField(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element ${
      touched && error ? "text-danger text-xs" : ""
    }`;
    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    let { isEdit } = this.props;

    if (nextProps.data) {
      // nextProps.data -> MODAL OPEN OR NOT
      this.setState({ open: true });
    }

    if (nextProps.hedaer) {
      // nextProps.hedaer -> MODAL HEADER
      this.setState({ headerText: nextProps.hedaer });
    }

    if (
      nextProps.selectedcatnsub &&
      typeof nextProps.selectedcatnsub !== "undefined" &&
      !_.isEmpty(nextProps.selectedcatnsub)
    ) {
      if (typeof nextProps.selectedcatnsub !== "undefined") {
        // If EDit
        if (isEdit) {
          cateSubObject = nextProps.selectedcatnsub[0];
        }

        this.setState({ cateSubObject: nextProps.selectedcatnsub[0] });
        this.setState({ subCateArray: nextProps.selectedcatnsub[0] }); // new code added
      }
    }
  }

  // Close Modal [Product Category Subcategory]
  closeModal() {
    this.setState({ open: false, catesearchText: "" });
    this.props.isProductCateSubCateModalOpen(false);
  }

  // On Done Click Cate & subcatechoose
  _handleSelectedSubCategorySubmit() {
    let { subCateArray, isEdit } = this.state;
    let { session, formValues } = this.props;
    var cateSubObject_localVar = this.state.cateSubObject
      ? this.state.cateSubObject
      : [];

    // console.log("formValues", formValues);
    // console.log("subCateArray", subCateArray);
    // console.log("cateSubObject_localVar", cateSubObject_localVar);

    var cateAndSubcateArray = [];
    var cateArray = [];
    var subcatinsideArr = []; //(NWA)

    if (subCateArray.length == 0 || cateSubObject_localVar.length == 0) {
      toast.error("Please Add Category and SubCategory");
      return false;
    }

    // Category Loop
    _.forEach(this.props.productCategorylist, function (cateValue) {
      var categoryId = cateValue.id;
      // Subcategory Loop
      _.forEach(
        cateValue.productsubcategory_by_categoryid,
        function (subCateValue) {
          if (_.indexOf(cateSubObject_localVar, subCateValue.id) > -1) {
            cateArray.push(categoryId);
          }
        }
      );
    });

    cateArray = _.uniq(cateArray);
    cateAndSubcateArray.push(cateSubObject_localVar);
    cateAndSubcateArray.push(cateArray);
    subcatinsideArr.push(subCateArray); //(NWA)

    // console.log("cateArray", cateArray);
    // console.log("subCateArray", subCateArray);
    // console.log("join1", _.join(cateAndSubcateArray[1], ","));
    // console.log("join2", _.join(subcatinsideArr[0], ","));
    console.log("joformValuesin2", formValues);
    let updated_catsubcate = false;
    if (
      (formValues?.categoryids &&
        formValues?.categoryids != _.join(cateAndSubcateArray[1], ",")) ||
      (formValues.subcategoryids &&
        formValues.subcategoryids != _.join(subcatinsideArr[0], ",")) ||
      (isEdit &&
        formValues?.categoryids == "" &&
        formValues?.subcategoryids == "")
    ) {
      updated_catsubcate = true;
    }
    // console.log("updated_catsubcate", updated_catsubcate);
    // Save selected Category / Subcategory Stored Action
    this.props.allSelectedSubcategorisID(cateAndSubcateArray);
    // Get Item Specification [ Subcategory specifications => ]
    this.props.fetchItemSpecification(
      this.props.session,
      cateArray,
      subCateArray,
      (callBack) => {}
    );

    /**** Fetch discount price Based On Category and subcategory selected ****/
    this.props.fetchDiscountList(
      this.props.session,
      cateArray,
      cateSubObject_localVar,
      "",
      (callBack) => {}
    );

    /* fetch 'variation_master' */
    this.props.fetchVariationMaster(
      session,
      _.join(cateAndSubcateArray[1], ","),
      // _.join(cateAndSubcateArray[0], ","),// previously sub sub cat id was going
      _.join(subcatinsideArr[0], ","), // subcategory id going now(NoWApplicable)
      (callback) => {}
    );
    if (updated_catsubcate) {
      this.props.iscatsubCatUpdated(true);
    }

    this.closeModal();
  }

  // Category Search
  categorysearch(e) {
    this.setState({ catesearchText: e.target.value });
  }

  // Select Category Id On Category Badge Click
  _handleSelectedCategory = (e, param) => {
    this.setState({ defaultCateId: parseInt(param) });
  };

  // Select Sub Category Checkbox
  _handleSelectedSubCategory = (e, object, cateid) => {
    let { subCateArray } = this.state;
    /* Send parent id if exist other ways send subcategory id */
    // let actId = _.isNull(object.parentid) ? object.id : object.parentid;

    // NEW CHANGE ADDED ON 09/05 BY UDAYAN
    let actId = object.id;

    if (e.target.checked) {
      // Push
      // Old code -> cateSubObject.push(object.id);
      // New code cateSubObject.push(actId);

      if (_.indexOf(cateSubObject, object.id) === -1) {
        cateSubObject.push(object.id);
      }

      if (_.indexOf(subCateArray, actId) === -1) {
        subCateArray.push(actId); // push actual subcategory [Parent id is the subcategory]
        // Set new value in state
        this.setState({
          subCateArray: subCateArray,
        });
      }

      this.setState({
        cateSubObject: cateSubObject,
      });
    } else {
      // Pull
      // Old code -> cateSubObject = _.pull(cateSubObject, object.id);
      // New code -> cateSubObject = _.pull(cateSubObject, actId);

      cateSubObject = _.pull(cateSubObject, object.id);

      if (_.indexOf(subCateArray, actId) !== -1) {
        subCateArray = _.pull(subCateArray, actId); // pull actual subcategory [Parent id is the subcategory]
        // Set new value in state
        this.setState({
          subCateArray: subCateArray,
        });
      }

      this.setState({ cateSubObject: cateSubObject });
    }
  };

  // Form Submit
  onFormSubmit(values) {}

  // Render category List
  renderCategoryList() {
    var { productCategorylist, language, selectedcatnsub, enar } = this.props;
    let { defaultCateId, catesearchText } = this.state;

    // Note :- defaultCateId -> currently highlighted category id

    productCategorylist = _.filter(productCategorylist, {
      isactive: true,
    });

    if (catesearchText !== "") {
      productCategorylist = productCategorylist.filter((v) =>
        v.categoryname_en.toLowerCase().includes(_.lowerCase(catesearchText))
      );
    }

    return _.map(productCategorylist, (cateObject, index) => {
      /* Note :- here "cateList" is at a time one object from "Product category list" */
      return (
        <span
          onClick={(e) => this._handleSelectedCategory(e, cateObject.id)}
          key={cateObject.id}
          id={"category" + cateObject.id}
          className={classNames(
            defaultCateId === cateObject.id
              ? "bg-primaryLight"
              : "bg-quaternary",
            //"category-badge"
            _.indexOf(selectedcatnsub[1], cateObject.id) > -1
              ? "border-2 border-primary category-badge"
              : " category-badge"
          )}>
          {ProductTextLocalized(
            cateObject.categoryname_en,
            cateObject.categoryname_ar,
            enar
          )}
        </span>
      );
    });
  }

  // render Sub-category List
  renderSubCategoryList() {
    var { productCategorylist, language, enar } = this.props;
    let { cateSubObject, defaultCateId, catesearchText } = this.state;

    if (catesearchText !== "") {
      productCategorylist = productCategorylist.filter((v) =>
        ProductTextLocalized(v.categoryname_en, v.categoryname_ar, enar)
          .toLowerCase()
          .includes(_.lowerCase(catesearchText))
      );
    }

    var subCategoryFilter = _.filter(productCategorylist, {
      id: defaultCateId,
    });

    var subCategory = subCategoryFilter[0];

    /*
      Note only take those values ,which isactive is true
    */
    if (subCategory !== undefined) {
      return _.map(
        _.filter(subCategory.productsubcategory_by_categoryid, {
          isactive: true,
        }),
        (subcateList, index) => {
          return (
            <div key={index} className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id={"subcategory" + subcateList.id}
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="focus:ring-primaryLight h-4 w-4 text-primary border-ternary rounded"
                  checked={
                    _.indexOf(cateSubObject, subcateList.id) > -1 ? true : ""
                  }
                  onChange={(e) =>
                    this._handleSelectedSubCategory(
                      e,
                      subcateList,
                      defaultCateId
                    )
                  }
                />
              </div>
              <div className="mx-3 text-sm">
                <label
                  htmlFor="comments"
                  className="font-medium border-ternary">
                  {ProductTextLocalized(
                    subcateList.subcategoryname_en,
                    subcateList.subcategoryname_ar,
                    enar
                  )}
                </label>
              </div>
            </div>
          );
        }
      );

      // return _.map(
      //   subCategory.productsubcategory_by_categoryid,
      //   (subcateList, index) => {
      //     /* Note :- here "cateList" is at a time one object from "Product category list" */
      //     return (
      //       <div key={subcateList.id} className="relative flex items-start">
      //         <div className="flex items-center h-5">
      //           <input
      //             id={"subcategory" + subcateList.id}
      //             aria-describedby="comments-description"
      //             name="comments"
      //             type="checkbox"
      //             className="focus:ring-primaryLight h-4 w-4 text-primary border-ternary rounded"
      //             checked={
      //               _.indexOf(cateSubObject, subcateList.id) > -1 ? true : ""
      //             }
      //             onChange={(e) =>
      //               this._handleSelectedSubCategory(
      //                 e,
      //                 subcateList,
      //                 defaultCateId
      //               )
      //             }
      //           />
      //         </div>
      //         <div className="mx-3 text-sm">
      //           <label
      //             htmlFor="comments"
      //             className="font-medium border-ternary">
      //             {ProductTextLocalized(
      //               subcateList.subcategoryname_en,
      //               subcateList.subcategoryname_ar,
      //               enar
      //             )}
      //           </label>
      //         </div>
      //       </div>
      //     );
      //   }
      // );
    }
  }

  render() {
    var { formValues } = this.props;
    var { cateSubObject, subCateArray } = this.state;
    // console.log("subCateArray", subCateArray);
    // console.log("cateSubObject", cateSubObject);
    // console.log("formValues", formValues);
    return (
      <Transition.Root show={this.state.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.state.open}
          onClose={this.closeModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 h-90 small-modal">
                <div className=" sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="btn-closeModal"
                    onClick={this.closeModal}>
                    <span className="sr-only">{LocaleStrings.close}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="mx-1 md:mx-14 my-4 sm:flex-row-reverse">
                  <div className="mt-3 text-center  sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-secondary">
                      {this.state.headerText}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="w-full border-t border-ternary" />

                <div className="mx-1 md:mx-14 my-4 sm:flex-row-reverse">
                  <div>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon
                          className="h-5 w-5 text-ternary"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="focus:ring-primaryLight focus:border-primaryLight block w-full pl-10 sm:text-sm border-ternary rounded-md"
                        placeholder={LocaleStrings.search_categories}
                        onChange={this.categorysearch}
                      />
                    </div>
                  </div>
                  <div className="w-full border-t border-ternary my-5" />
                  <p className="text-sm leading-6 text-ternary">
                    {LocaleStrings.product_management_modal_subtext}
                  </p>
                  <div className="mb-4 space-y-5">
                    {/* productCategorylist */}
                    {this.renderCategoryList()}
                  </div>

                  <fieldset className="space-y-5">
                    {/* Sub-Category List */}
                    {this.renderSubCategoryList()}

                    {/* Submit Button */}
                    <div className="sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this._handleSelectedSubCategorySubmit}>
                        {LocaleStrings.done}
                      </button>
                    </div>
                  </fieldset>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  return errors;
}

//
function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    language: state.language,
    prodCateSubCateModal: state.prodCateSubCateModal,
    productTitleSubTitleData: state.productTitleSubTitleData,
    productCategorylist: state.productCategorylist
      ? state.productCategorylist.data
      : [],
    selectedSubcategorids: state.selectedSubcategorids, // [action name - allSelectedSubcategorisID]
    formValues: getFormValues("UploadProductForm")(state),
  };
}

export default reduxForm({
  validate,
  form: "addProductCateSubcateForm",
})(
  connect(mapStateToProps, {
    isProductCateSubCateModalOpen,
    allSelectedSubcategorisID,
    fetchItemSpecification,
    fetchDiscountList,
    fetchVariationMaster,
    iscatsubCatUpdated,
  })(CategorySubcategory)
);
