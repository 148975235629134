import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
// Import constant
import { PERSONAL_USER_REDIRECT_TO } from "@constant";
// Import Component
import CommonHeaderRevenueReports from "../commonheaderrevenuereports.js";
import TransactionHistoryTableList from "./transaction-history-tablelist";
//Import Actions
import { onSidebarMenuSelected } from "@sidebarActions";

class RevenueReportTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_transaction_history,
    };
    // All Binded Functions
  }

  componentDidMount() {
    let { session } = this.props;
    let { usertype } = session;

    if (usertype === "personal") {
      this.props.onSidebarMenuSelected(2);
      this.props.history.push(PERSONAL_USER_REDIRECT_TO);
    }
  }

  render() {
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            <div className="relative responsivePadding">
              <CommonHeaderRevenueReports
                history={this.props.history}
                commonHeaderText={LocaleStrings.revenue_reports_list_text3}
              />
              <div>
                <div className="py-6">
                  <TransactionHistoryTableList />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, { onSidebarMenuSelected })(
  RevenueReportTransaction
);
