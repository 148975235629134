import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import toast from "react-hot-toast";
import _ from "lodash";
import { BaseComponent } from "@baseComponent";
import {
  // DATE_FORMAT_TO_SHOW,
  validateEmail,
} from "@constant";

// Import Actions
import { VerifyPhoneStat, logout, countryPhoneLength } from "@mainActions";

import {
  sendSms,
  verifyOtp,
  fetchUsersDeatils,
  updateStoreUsers,
} from "@profileSettingsActions";
import {
  classNames,
  removePhoneNoSpecialChar,
  isValidQatarPhoneNumber,
} from "@commonFunction";
// Functions Import

class VerifyPhone extends BaseComponent {
  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = {
      isVerified: false,
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      hideNumberField: true,
      sendLoader: false,
      fieldsEmptyState: true,
      resendLoader: false,
      time: {},
      seconds: 60,
    };

    // All Binded Functions
    this.changeNumber = this.changeNumber.bind(this);
    this.resendVerificationCode = this.resendVerificationCode.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });

    this.props.fetchUsersDeatils(this.props.session, (callBackResponce) => {});
  }

  startTimer() {
    this.setState({ resendLoader: true });
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
      this.setState({ resendLoader: false, time: {}, seconds: 30 });
      this.timer = 0;
      let timeLeftVar = this.secondsToTime(30);
      this.setState({ time: timeLeftVar });
    }
  }

  secondsToTime(secs) {
    let obj = {
      s: secs,
    };
    return obj;
  }

  componentWillUnMount() {
    this.setState({
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      hideNumberField: true,
      sendLoader: false,
      phone: "",
      country: "",
      isVerified: false,
      fieldsEmptyState: true,
      resendLoader: false,
      time: {},
      seconds: 60,
    });
  }

  /* Change number */
  changeNumber() {
    // toast.error(LocaleStrings.feature_under_construction);
    this.setState({ hideNumberField: false });
  }

  /* Verification Code entry */
  _onVerficationCodeEntry(e, fieldNum) {
    if (fieldNum !== 6 && e.target.value !== "") {
      document.getElementsByName("verifiaction_" + (fieldNum + 1))[0].focus();
    }

    /* Remove disable if verify code is 6 digit long */
    let verifiaction_1 = document.getElementsByName("verifiaction_1");
    let verifiaction_2 = document.getElementsByName("verifiaction_2");
    let verifiaction_3 = document.getElementsByName("verifiaction_3");
    let verifiaction_4 = document.getElementsByName("verifiaction_4");
    let verifiaction_5 = document.getElementsByName("verifiaction_5");
    let verifiaction_6 = document.getElementsByName("verifiaction_6");

    let verficationEntryCode =
      verifiaction_1[0].value +
      verifiaction_2[0].value +
      verifiaction_3[0].value +
      verifiaction_4[0].value +
      verifiaction_5[0].value +
      verifiaction_6[0].value;

    if (_.trim(verficationEntryCode).length === 6) {
      this.setState({ fieldsEmptyState: false });
    } else {
      this.setState({ fieldsEmptyState: true });
    }
  }

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
    this.props.countryPhoneLength(country.dialCode);
  };

  clickSubmit = (key) => {
    this.props.autofill("actiontaken", key);
  };

  /* Resend verification code */
  resendVerificationCode = () => {
    let { session, vendor } = this.props;

    this.startTimer();
    let { phone } = this.state;

    let newPhoneNumber = "";
    if (phone === undefined) {
      /* Given Number */
      newPhoneNumber = vendor.phone;
    } else {
      /* Already register number */
      newPhoneNumber = phone;
    }
    newPhoneNumber = removePhoneNoSpecialChar(newPhoneNumber); // This function will remove special chars from phone number

    this.props.sendSms(this.props.session, newPhoneNumber, true, (callback) => {
      if (callback.success === 1) {
        toast.success(LocaleStrings.otp_has_been_sent_success);
      } else {
        toast.error(callback.message);
      }
    });
  };

  /***** FORM SUBMIT *****/
  onFormSubmit(values) {
    let { vendor } = this.props;
    let { hideNumberField } = this.state;
    let actiontaken = values.actiontaken;

    if (actiontaken == "send") {
      if (values.phone) {
        this.setState({ sendLoader: true });
        let phone = values.phone;
        phone = removePhoneNoSpecialChar(phone); // This function will remove special chars from phone number
        this.props.sendSms(this.props.session, phone, false, (callback) => {
          this.setState({ sendLoader: false });
          if (callback.success === 1) {
            toast.success(LocaleStrings.send_code_success);
          } else {
            toast.error(callback.message);
          }
        });
      } else {
        toast.error(LocaleStrings.verification_code_mismatch);
      }
    } else if (actiontaken == "verify") {
      let verifiaction_1 = document.getElementsByName("verifiaction_1");
      let verifiaction_2 = document.getElementsByName("verifiaction_2");
      let verifiaction_3 = document.getElementsByName("verifiaction_3");
      let verifiaction_4 = document.getElementsByName("verifiaction_4");
      let verifiaction_5 = document.getElementsByName("verifiaction_5");
      let verifiaction_6 = document.getElementsByName("verifiaction_6");

      let verficationEntryCode =
        verifiaction_1[0].value +
        verifiaction_2[0].value +
        verifiaction_3[0].value +
        verifiaction_4[0].value +
        verifiaction_5[0].value +
        verifiaction_6[0].value;

      if (!this.state.isVerified) {
        this.setState({ verifyLoader: true });
        let otp = verficationEntryCode;

        this.props.verifyOtp(this.props.session, otp, (callback) => {
          this.setState({ verifyLoader: false });
          if (callback.success === 1) {
            this.setState({ isVerified: true });
            this.setState({ fieldsEmptyState: true });
            toast.success(LocaleStrings.otp_verified_successfully);

            if (hideNumberField === true && values.phone === undefined) {
              let data = {};
              data.userid = this.props.session.userid;
              data.phoneverified = 1;

              this.props.updateStoreUsers(
                this.props.session,
                data,
                (callback) => {
                  if (callback.status === 1) {
                    // Fetch Vendor Details With LoginIdStore
                    this.props.fetchUsersDeatils(
                      this.props.session,
                      (callBackResponce) => {
                        this.props.changeStep(0); // Reinitialize
                      }
                    );
                  } else {
                    toast.error(LocaleStrings.something_went_wrong);
                  }
                }
              );
            }
          } else {
            this.setState({ fieldsEmptyState: true });
            toast.error(LocaleStrings.otp_not_verified);
          }
        });
      }
    } else if (actiontaken === "update") {
      if (values.phone === undefined) {
        toast.error(
          LocaleStrings.verification_phone_change_number_field_placeholder
        );
        return false;
      }

      if (this.state.isVerified) {
        this.setState({ btnLoader: true });
        let data = {};
        let phone = values.phone ? values.phone : vendor.phone;
        phone = removePhoneNoSpecialChar(phone); // This function will remove special chars from phone number
        data.phone = phone;
        data.userid = this.props.session.userid;
        this.props.updateStoreUsers(this.props.session, data, (callback) => {
          if (callback.status === 1) {
            this.setState({ btnLoader: false });
            this.setState({ verificStateCode: "" });
            this.setState({ fieldsEmptyState: true });
            this.setState({ isVerified: false });

            toast.success(LocaleStrings.phone_updated_successfully);

            this.props.changeStep(0);

            // Fetch Vendor Details With LoginIdStore
            this.props.fetchUsersDeatils(
              this.props.session,
              (callBackResponce) => {}
            );
          }
        });
      } else {
        toast.error(LocaleStrings.mobile_not_verified);
      }
    }
  }

  render() {
    let { initialValues, language, handleSubmit, session } = this.props;
    let { hideNumberField, resendLoader } = this.state;
    let modalFooterText = LocaleStrings.verify;
    return (
      <>
        <form
          className=" "
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
          {/* MOdal Header Ends*/}
          <div className="p-6  bg-primary">
            <p className="text-custWhite text-lg font-medium">
              {LocaleStrings.verification_header_text2}
            </p>
            <p className="text-custWhite text-xs">
              {LocaleStrings.verification_subheader_text2}
            </p>
          </div>
          <div className="space-y-6 py-6 px-6">
            <div>
              <p className=" text-sm font-medium text-custblack">
                {LocaleStrings.phone_number_verfication_text}
              </p>
            </div>
            {/* Change Number? */}
            <div className={classNames(hideNumberField ? "" : "hidden", "")}>
              <p className="py-2">
                <span
                  className="verify-email-resend"
                  onClick={this.changeNumber}>
                  {LocaleStrings.verification_phone_change_number}
                </span>
              </p>
            </div>
            <div className={classNames(hideNumberField ? "hidden" : "", "")}>
              <Field
                name={"phone"}
                label={
                  LocaleStrings.verification_phone_change_number_field_name
                }
                mandatory="true"
                type="text"
                component={this.renderPhonewithFlag}
                className="cust-input-field"
                classNameLabel="text-box-lable"
                countryCodeEditable={false}
                phone={this.state.phone}
                phoneChange={this.phoneChange}
              />
              {/* SEND CODE BUTTON */}
              <div className="mt-6">
                <button
                  className="btn-primary"
                  type="submit"
                  onClick={() => this.clickSubmit("send")}
                  disabled={this.state.sendLoader ? true : false}>
                  {this.state.sendLoader ? (
                    <svg
                      className="animate-spin h-5 w-5 rounded-full mr-3"
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  ) : (
                    ""
                  )}
                  <span className="text-sm">{LocaleStrings.send_code}</span>
                </button>
              </div>
            </div>
            {/* Verification input boxes */}
            <div className="grid grid-flow-col grid-cols-6 grid-rows-1 gap-4 md:gap-8">
              <div>
                <Field
                  name="verifiaction_1"
                  placeholder={"X"}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 1)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_2"
                  id="verifiaction_2"
                  placeholder={"X"}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 2)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_3"
                  id="verifiaction_3"
                  placeholder={"X"}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 3)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_4"
                  id="verifiaction_4"
                  placeholder={"X"}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 4)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_5"
                  id="verifiaction_5"
                  placeholder={"X"}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 5)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_6"
                  id="verifiaction_6"
                  placeholder={"X"}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 6)}
                />
              </div>
            </div>
          </div>

          <div className="p-6 border-t-2 border-quaternary">
            {/* Footer */}
            <button
              className={classNames(
                this.state.fieldsEmptyState ? "btn-disabled" : "btn-primary",
                ""
              )}
              type="submit"
              onClick={() => this.clickSubmit("verify")}
              disabled={this.state.fieldsEmptyState ? true : false}>
              {this.state.verifyLoader ? (
                <svg
                  className="animate-spin h-5 w-5 rounded-full mr-3"
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">{LocaleStrings.verify}</span>
            </button>
            <button
              className={classNames(
                hideNumberField ? "hidden" : "btn-primary",
                ""
              )}
              type="submit"
              onClick={() => this.clickSubmit("update")}>
              {this.state.btnLoader ? (
                <svg
                  className="animate-spin-medium h-5 w-5 rounded-full mx-2"
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">
                {LocaleStrings.update_phone_number}
              </span>
            </button>
          </div>
          {/* Resend? */}
          <div>
            {resendLoader === false ? (
              <p className="py-2 text-center">
                <span
                  className="verify-email-resend"
                  onClick={this.resendVerificationCode}>
                  {LocaleStrings.resend}
                </span>
              </p>
            ) : (
              <p className="py-2 text-center">
                <span className="verify-email-resend-disabled">
                  {LocaleStrings.resend}
                  {" : "}
                  {this.state.time.s}
                </span>
              </p>
            )}
          </div>
          <p className="px-6 text-center ">
            <span className="text-primary">{LocaleStrings.or}</span>
          </p>
          <p className="px-6 py-2 text-center">
            <span
              className="verify-email-resend"
              onClick={(e) => this.props.logout(session)}>
              {LocaleStrings.button_logout}
            </span>
          </p>
        </form>
      </>
    );
  }
}

function validate(values, ownProps) {
  var errors = {};
  var email = values["email"];
  var requiredFields = ["email"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  if (email && !validateEmail(email)) {
    errors["email"] = "Invalid email";
  }
  let countrycode = ownProps.phoneLengthCountry;
  if (countrycode == "974" && values.phone) {
    let phone = values.phone.replace(/[- )( +]/g, "");
    if (isValidQatarPhoneNumber("+" + phone) == false) {
      errors.phone = "Please enter valid phone number";
    }
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    vendor: state.vendorDetails.data,
    phoneLengthCountry: state.phoneLengthCountry,
    initialValues: {
      userid: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.userid
          : ""
        : "",
    },
  };
}

export default connect(mapStateToProps, {
  VerifyPhoneStat,
  sendSms,
  verifyOtp,
  fetchUsersDeatils,
  updateStoreUsers,
  logout,
  countryPhoneLength,
})(
  reduxForm({
    validate,
    form: "VerifyPhoneForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(VerifyPhone)
);
