import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import _ from "lodash";
import moment from "moment-timezone";
import toast from "react-hot-toast";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { validateEmail } from "@constant";
import { format, addDays, parse } from "date-fns";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

// Import Actions
import {
  verifyEmail,
  checkVerificationStatus,
  reSentEmail,
  emailExistOrNot,
  changeEmail,
  logout,
  verifyotpEmail,
  sendotpEmail,
  changeupdateEmail,
  verifyemailProfilesettings,
  isVerficationModalOpen,
} from "@mainActions";
import { isPrflInfoVerifyEmailModalOpen } from "@profileSettingsActions";
// Functions Import
import { ProductTextLocalized, classNames, isEmpty } from "@commonFunction";

// Import Component
import Divider from "@divider";

/* Impport CSS */
import "@starterCss";

class VerifyEmailModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      emailid: "",
      fieldsEmptyState: true,
      hideNumberField: true,
      isVerified: false,
      sendLoader: false,
      verifyLoader: false,
    };

    // All refrence variables
    this.myRef = React.createRef();
    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.closeModal1 = this.closeModal1.bind(this);
  }

  componentWillUnmount() {
    this.reinitializeAllLocalStates();
  }

  /**** Fake Close ****/
  closeModal1() {
    // console.log("1");
    this.props.verifyemailProfilesettings(false);
    this.props.isPrflInfoVerifyEmailModalOpen(false);
  }

  /**** CLOSE MODAL  ****/
  closeModal() {
    // console.log("2");
    this.props.verifyemailProfilesettings(false);
    this.props.isPrflInfoVerifyEmailModalOpen(false);
    this.reinitializeAllLocalStates();
  }

  /* Clear all local objects */
  reinitializeAllLocalStates() {
    this.setState({
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      emailid: "",
    });
  }

  /* Resent email */
  reSendEmail = () => {
    let { session } = this.props;
    /*
        Sent Veriofication link to registerd email address
        */
    this.props.reSentEmail(session, (callBack) => {
      if (callBack.status === 1) {
        toast.success(LocaleStrings.resent_email_alert_text);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  /***** Form Submit *****/
  // onFormSubmit(values) {
  //   let { session, user } = this.props;
  //   let actualEmail = user.email;

  //   if (user.emailverified === true && isEmpty(values)) {
  //     toast.error(LocaleStrings.enter_email_want_to_change);
  //     return false;
  //   }

  //   this.setState({ btnLoader: true, isBtnDisable: true }); // Start The button Loader

  //   /* Check supplied email is alraedy exist or not for df */
  //   this.props.emailExistOrNot(session, values.email, (callBack) => {
  //     if (callBack.status === 1) {
  //       if (callBack.data > 0) {
  //         toast.error(LocaleStrings.email_exist_alert_text);
  //         this.setState({
  //           btnLoader: false,
  //           isBtnDisable: false,
  //         });
  //       } else {
  //         /* Actual work area
  //             Update and send link to new email / update new email
  //           */
  //         this.props.changeEmail(session, values.email, (callBack) => {
  //           if (callBack.status === 1) {
  //             toast.success(LocaleStrings.verification_email_note_text4);
  //             this.setState({
  //               btnLoader: false,
  //               isBtnDisable: false,
  //             });
  //             this.props.isVerficationModalOpen(false);
  //             this.props.logout(session);
  //           } else {
  //             toast.error(LocaleStrings.something_went_wrong);
  //             this.setState({
  //               btnLoader: false,
  //               isBtnDisable: false,
  //             });
  //           }
  //         });
  //       }
  //     } else {
  //       toast.error(LocaleStrings.something_went_wrong);
  //       this.setState({
  //         btnLoader: false,
  //         isBtnDisable: false,
  //       });
  //     }
  //   });
  // }

  _onVerficationCodeEntry(e, fieldNum) {
    if (fieldNum !== 6 && e.target.value !== "") {
      document.getElementsByName("verifiaction_" + (fieldNum + 1))[0].focus();
    }

    /* Remove disable if verify code is 6 digit long */
    let verifiaction_1 = document.getElementsByName("verifiaction_1");
    let verifiaction_2 = document.getElementsByName("verifiaction_2");
    let verifiaction_3 = document.getElementsByName("verifiaction_3");
    let verifiaction_4 = document.getElementsByName("verifiaction_4");
    let verifiaction_5 = document.getElementsByName("verifiaction_5");
    let verifiaction_6 = document.getElementsByName("verifiaction_6");

    let verficationEntryCode =
      verifiaction_1[0].value +
      verifiaction_2[0].value +
      verifiaction_3[0].value +
      verifiaction_4[0].value +
      verifiaction_5[0].value +
      verifiaction_6[0].value;

    if (_.trim(verficationEntryCode).length === 6) {
      this.setState({ fieldsEmptyState: false });
    } else {
      this.setState({ fieldsEmptyState: true });
    }
  }

  clickSubmit = (key) => {
    this.props.autofill("actiontaken", key);
  };

  onFormSubmit(values) {
    let { vendor } = this.props;
    let { hideNumberField } = this.state;
    let actiontaken = values.actiontaken;
    if (!values.email || values.email == "") {
      toast.error(LocaleStrings.verify_modal_errror_addemail);
      return false;
    }
    if (actiontaken == "send") {
      this.setState({ sendLoader: true });
      this.props.sendotpEmail(this.props.session, values.email, (callback) => {
        this.setState({ sendLoader: false });
        if (callback.status === 1) {
          toast.success(LocaleStrings.verify_modal_success_verificationcode);
        } else {
          toast.error(callback.message);
        }
      });
    } else if (actiontaken == "verify") {
      // console.log('2')
      let verifiaction_1 = document.getElementsByName("verifiaction_1");
      let verifiaction_2 = document.getElementsByName("verifiaction_2");
      let verifiaction_3 = document.getElementsByName("verifiaction_3");
      let verifiaction_4 = document.getElementsByName("verifiaction_4");
      let verifiaction_5 = document.getElementsByName("verifiaction_5");
      let verifiaction_6 = document.getElementsByName("verifiaction_6");

      let verficationEntryCode =
        verifiaction_1[0].value +
        verifiaction_2[0].value +
        verifiaction_3[0].value +
        verifiaction_4[0].value +
        verifiaction_5[0].value +
        verifiaction_6[0].value;

      if (!this.state.isVerified) {
        this.setState({ verifyLoader: true });
        let otp = verficationEntryCode;

        this.props.verifyotpEmail(this.props.session, otp, (callback) => {
          this.setState({ verifyLoader: false });
          if (callback.status === 1) {
            this.setState({ isVerified: true });
            this.setState({ fieldsEmptyState: true });
            toast.success(LocaleStrings.otp_verified_successfully);
          } else {
            this.setState({ fieldsEmptyState: true });
            toast.error(LocaleStrings.verify_modal_code_error_mismatched);
          }
        });
      }
    } else if (actiontaken === "update") {
      // console.log('3')
      let { session, user } = this.props;
      let actualEmail = user.email;

      if (user.emailverified === true && isEmpty(values)) {
        toast.error(LocaleStrings.enter_email_want_to_change);
        return false;
      }
      if (this.state.isVerified) {
        this.setState({ btnLoader: true, isBtnDisable: true }); // Start The button Loader
        /* Check supplied email is alraedy exist or not for df */
        this.props.emailExistOrNot(session, values.email, (callBack) => {
          if (callBack.status === 1) {
            if (callBack.data > 0) {
              toast.error(LocaleStrings.email_exist_alert_text);
              this.setState({
                btnLoader: false,
                isBtnDisable: false,
              });
            } else {
              /* Actual work area
                  Update and send link to new email / update new email
                */
              this.props.changeupdateEmail(
                session,
                values.email,
                (callBack) => {
                  // console.log("callback", callBack);
                  if (callBack.status === 1) {
                    // this.props.verifyemailProfilesettings(false);
                    // this.props.isPrflInfoVerifyEmailModalOpen(false);
                    this.props.isVerficationModalOpen(false);
                    this.props.logout(session);
                    window.location.reload();
                    toast.success(LocaleStrings.verification_email_note_text4);
                    this.setState({
                      btnLoader: false,
                      isBtnDisable: false,
                    });
                  } else {
                    toast.error(LocaleStrings.something_went_wrong);
                    this.setState({
                      btnLoader: false,
                      isBtnDisable: false,
                    });
                  }
                }
              );
            }
          } else {
            toast.error(LocaleStrings.something_went_wrong);
            this.setState({
              btnLoader: false,
              isBtnDisable: false,
            });
          }
        });
      } else {
        toast.error(LocaleStrings.verify_modal_code_error_notverified);
      }
    }
  }

  render() {
    let {
      initialValues,
      session,
      handleSubmit,
      isVerifyEmailPrflInfoModal,
      user,
      emailverifyProfilesettings,
    } = this.props;
    let { emailid, hideNumberField } = this.state;
    let modalHeadText = "";

    let modalFooterText = LocaleStrings.verify;
    let emailIdToShow = emailid !== "" ? emailid : session.emailid;
    // console.log("emailverifyProfilesettings", emailverifyProfilesettings);
    return (
      <>
        <Transition.Root show={isVerifyEmailPrflInfoModal} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={isVerifyEmailPrflInfoModal}
            onClose={this.closeModal1}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MODAL HEADER */}
                  <div className="grid grid-cols-6 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4 col-span-4">
                      <div className="text-lg leading-6 font-medium text-secondary h-4">
                        {modalHeadText}
                      </div>
                    </div>
                    <div className="absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        ref={this.myRef}
                        onClick={this.closeModal}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {/* MOdal Header Ends*/}
                    {emailverifyProfilesettings == true ? (
                      <div className="p-6  bg-primary">
                        <p className="text-custWhite text-lg font-medium">
                          Update Email
                        </p>
                        {/* <p className="text-custWhite text-xs">
                          {LocaleStrings.verification_subheader_text1}
                        </p> */}
                      </div>
                    ) : (
                      <div className="p-6  bg-primary">
                        <p className="text-custWhite text-lg font-medium">
                          {LocaleStrings.verification_header_text1}
                        </p>
                        <p className="text-custWhite text-xs">
                          {LocaleStrings.verification_subheader_text1}
                        </p>
                      </div>
                    )}
                    {emailverifyProfilesettings !== true ? (
                      <>
                        {/* In order for us to activate your account */}
                        <div>
                          <p className="px-6 py-6">
                            <span>
                              {LocaleStrings.verification_email_note_text1}
                            </span>
                            <span
                              className="text-primary"
                              id="verify_email_address_id">
                              {emailIdToShow}
                            </span>
                            <span>
                              {LocaleStrings.verification_email_note_text2}
                            </span>
                          </p>
                        </div>
                        {/* Haven't received the email? Resend */}
                        <div>
                          <p className="px-6 py-2">
                            <span
                              className="verify-email-resend"
                              onClick={(e) => this.reSendEmail()}>
                              {LocaleStrings.verification_email_resend_btn}
                            </span>
                          </p>
                        </div>
                        {/* Divider */}
                        <div className="px-6 py-6">
                          <Divider />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* You have enter the wrong email id? You can
              can change the email now 
          */}
                    <div>
                      <p className="px-6 py-6">
                        <span>
                          {LocaleStrings.verification_email_note_text3}
                        </span>
                      </p>
                    </div>
                    <div className="space-y-6 pt-2 pb-4 px-6">
                      <Field
                        name="email"
                        label={LocaleStrings.email_address}
                        placeholder={LocaleStrings.email_address}
                        type="text"
                        component={this.renderFieldText}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-sm font-medium text-secondary"
                      />
                      {/* latest code */}
                      <div className="mt-6">
                        <button
                          className="btn-primary"
                          type="submit"
                          onClick={() => this.clickSubmit("send")}
                          disabled={this.state.sendLoader ? true : false}>
                          {this.state.sendLoader ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : (
                            ""
                          )}
                          <span className="text-sm">
                            {LocaleStrings.send_code}
                          </span>
                        </button>
                      </div>
                      <div className="grid grid-flow-col grid-cols-6 grid-rows-1 gap-4 md:gap-8">
                        <div>
                          <Field
                            name="verifiaction_1"
                            placeholder={"X"}
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            maxlength="1"
                            className="text-center"
                            classNameLabel="text-sm font-medium text-secondary"
                            onChange={(e) => this._onVerficationCodeEntry(e, 1)}
                          />
                        </div>
                        <div>
                          <Field
                            name="verifiaction_2"
                            id="verifiaction_2"
                            placeholder={"X"}
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            maxlength="1"
                            className="text-center"
                            classNameLabel="text-sm font-medium text-secondary"
                            onChange={(e) => this._onVerficationCodeEntry(e, 2)}
                          />
                        </div>
                        <div>
                          <Field
                            name="verifiaction_3"
                            id="verifiaction_3"
                            placeholder={"X"}
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            maxlength="1"
                            className="text-center"
                            classNameLabel="text-sm font-medium text-secondary"
                            onChange={(e) => this._onVerficationCodeEntry(e, 3)}
                          />
                        </div>
                        <div>
                          <Field
                            name="verifiaction_4"
                            id="verifiaction_4"
                            placeholder={"X"}
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            maxlength="1"
                            className="text-center"
                            classNameLabel="text-sm font-medium text-secondary"
                            onChange={(e) => this._onVerficationCodeEntry(e, 4)}
                          />
                        </div>
                        <div>
                          <Field
                            name="verifiaction_5"
                            id="verifiaction_5"
                            placeholder={"X"}
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            maxlength="1"
                            className="text-center"
                            classNameLabel="text-sm font-medium text-secondary"
                            onChange={(e) => this._onVerficationCodeEntry(e, 5)}
                          />
                        </div>
                        <div>
                          <Field
                            name="verifiaction_6"
                            id="verifiaction_6"
                            placeholder={"X"}
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            maxlength="1"
                            className="text-center"
                            classNameLabel="text-sm font-medium text-secondary"
                            onChange={(e) => this._onVerficationCodeEntry(e, 6)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-6 border-t-2 border-quaternary">
                      {/* Footer */}
                      <button
                        className={classNames(
                          this.state.fieldsEmptyState
                            ? "btn-disabled"
                            : "btn-primary",
                          ""
                        )}
                        type="submit"
                        onClick={() => this.clickSubmit("verify")}
                        disabled={this.state.fieldsEmptyState ? true : false}>
                        {this.state.verifyLoader ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full mr-3"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        <span className="text-sm">Verify Code</span>
                      </button>
                      <button
                        type="submit"
                        onClick={() => this.clickSubmit("update")}
                        className="btn-primary">
                        {this.state.btnLoader ? (
                          <svg
                            className="animate-spin-medium h-5 w-5 rounded-full mx-2"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        <span className="text-sm">Update Email</span>
                      </button>
                    </div>
                    {/* <div className="p-6 border-t-2 border-quaternary">
                      <button
                        type="submit"
                        disabled={this.state.isBtnDisable}
                        className={classNames(
                          this.state.isBtnDisable ? "cursor-not-allowed" : "",
                          "btn-primary"
                        )}
                      >
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {LocaleStrings.verify}
                      </button>
                    </div> */}
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = [];

  requiredFields.forEach((field) => {
    if (!values[field] || _.trim(values[field]) === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isVerifyEmailPrflInfoModal: state.isVerifyEmailPrflInfoModal, // For verify email modal from profile information
    emailverifyProfilesettings: state.emailverifyProfilesettings,
  };
}

export default connect(mapStateToProps, {
  isPrflInfoVerifyEmailModalOpen,
  emailExistOrNot,
  verifyEmail,
  checkVerificationStatus,
  reSentEmail,
  changeEmail,
  logout,
  verifyotpEmail,
  sendotpEmail,
  changeupdateEmail,
  verifyemailProfilesettings,
  isVerficationModalOpen,
})(
  reduxForm({
    validate,
    form: "verifyEmailModalForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(VerifyEmailModal)
);
