import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import _ from "lodash";

// Import BaseLoader
import BaseLoader from "@baseLoader";

// Import Actions
import {
  isQaModalOpen,
  fetchqa,
  questionanswer,
} from "@productmanagementActions";
import { openQAReportModal } from "@profileSettingsActions";

// Component Import
//import ChatList from "./chatlist";

// COmmon Fc
import { classNames } from "@commonFunction";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, DotsHorizontalIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

const hiddenStateArr = ["negotiated", "closed", "initiated", "responded"];

class QaWithOutLimit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_qa,
      giveAnswer: false,
      qaid: "",
      answer: "",
      question: "",
      askQuestion: false,
      QuestionForAmswer: "",
    };

    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);
  }

  componentDidMount() {
    let { session, productID } = this.props;

    this._isMounted = true;

    if (this._isMounted) {
      setInterval(() => {
        if (this.props.isQaopen) {
          this.props.fetchqa(session, productID, 0, (callback) => {
            this.setState({ loading: false });
          });

          this.props.fetchqa(session, productID, 1, (callback) => {
            this.setState({ loading: false });
          });
        }
      }, 3000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.isQaModalOpen(false);
  }

  // Render Qa All List
  renderQaWithOutLimit = (e) => {
    let { productQaWithOutLimit, session } = this.props;

    let item = _.map(
      _.orderBy(productQaWithOutLimit, ["updatedat"], ["desc"]),
      (qaObject, index) => {
        return (
          <li
            key={index}
            className={"py-4 bg-custWhite px-4  table-hover-transition"}
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  loading="lazy"
                  className="h-8 w-8"
                  src={"/images2/common/askquestion.svg"}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-secondary truncate mx-2">
                  {qaObject.question}
                </p>
                <p className="text-sm text-ternary truncate">
                  {qaObject.answer}
                </p>
              </div>
              <div className="flex space-x-2">
                <button
                  type="button"
                  className={classNames(
                    !_.isEmpty(qaObject.answer) ? "hidden" : "tbl-btn-primary",
                    ""
                  )}
                  onClick={(e) => this.selectedQuestionForAnswer(e, qaObject)}
                >
                  {LocaleStrings.answer}
                </button>
                {/* Abuse report on Question */}
                <Menu
                  as="div"
                  className={classNames(
                    parseInt(qaObject.askedby) === parseInt(session.userid)
                      ? "hidden"
                      : "",
                    "relative inline-block text-left p-2"
                  )}
                >
                  {({ open }) => (
                    <>
                      <div className="">
                        <Menu.Button className="btn-card">
                          <span className="sr-only">Open options</span>
                          <DotsHorizontalIcon
                            className="h-5 w-5 hover:shadow"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-148 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                        >
                          <div className="py-1 divide-y z-50 w-40">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={
                                    () =>
                                      qaObject.reportabuse === 1
                                        ? toast.error(
                                            LocaleStrings.already_reported
                                          )
                                        : this.reportAbusive(qaObject.id) // report on Question id
                                  }
                                  className={classNames(
                                    active ? "bg-quaternary " : " ",
                                    "block px-4 py-2 text-sm text-primary"
                                  )}
                                >
                                  {LocaleStrings.report_as_abusive}
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </li>
        );
      }
    );

    return item;
  };

  /**** Answer Related  ****/

  // Select Question To answer And show "Type your Answer Input Box"
  selectedQuestionForAnswer = (e, qaObject) => {
    this.setState({ askQuestion: false });

    this.setState({ QuestionForAmswer: qaObject.question });
    this.setState({ giveAnswer: true });
    this.setState({ qaid: qaObject.id });
  };

  /*** Close Answer Section Footer***/
  closeAnswerSection() {
    this.setState({ QuestionForAmswer: "" });
    this.setState({ giveAnswer: false });
    this.setState({ qaid: "" });
  }

  /**** Add answer To state ****/
  _handelAnswerChange = (e) => {
    //let { answer } = this.state;
    let inputVal = e.target.value;
    this.setState({ answer: inputVal });
  };

  /**** Submit Answer ****/
  _submitAnswerForQuestion = (e) => {
    let { qaid, answer } = this.state;
    let { session, productID } = this.props;

    if (_.trim(answer) !== null && _.trim(answer) !== "") {
      // Submit Asked Question Answer
      let ansobj = {
        isanswer: 1,
        qaid: qaid,
        answer: answer,
      };

      this.props.questionanswer(session, ansobj, (callback) => {
        toast.success(LocaleStrings.your_answer_submitted_successfully);
        this.closeAnswerSection();

        this.props.fetchqa(session, productID, 1, (callback) => {
          this.setState({ loading: false });
        });
      });
    } else {
      toast.error(LocaleStrings.type_your_answer);
    }
  };

  /**** Question Related ****/

  /**** Ask Question Button Click ****/
  askAQuestion = (e) => {
    // make Initiale for Answer section
    this.setState({ giveAnswer: false });
    this.setState({ qaid: "" });

    // Start Question Section
    this.setState({ askQuestion: true });
  };

  /**** Add Question To state ****/
  _handelQuestionChange = (e) => {
    let inputVal = e.target.value;
    this.setState({ question: inputVal });
  };

  /****  Submit Asked Question ****/
  _submitQuestion = (e) => {
    let { qaid, answer, question } = this.state;
    let { session, productID } = this.props;

    if (_.trim(question) !== null && _.trim(question) !== "") {
      // Submit a Question
      let qsnobj = {
        isanswer: 0,
        productid: productID,
        askedto: session.userid,
        question: question,
      };

      this.props.questionanswer(session, qsnobj, (callback) => {
        toast.success(LocaleStrings.your_question_submitted_successfully);
        this.closeQuestionSection();
      });
    } else {
      toast.error(LocaleStrings.type_your_question);
    }
  };

  /*** Close Question Section Footer ***/
  closeQuestionSection() {
    this.setState({ QuestionForAmswer: "" });
    this.setState({ giveAnswer: false });
    this.setState({ qaid: "" });

    this.setState({ askQuestion: false });
  }

  /* Report abuse on question [askedby] */
  reportAbusive = (userid) => {
    let { isLoggedIn } = this.props;
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.report_confirm_questions_answers_title,
        message: LocaleStrings.report_confirm_questions_answers_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              this.props.openQAReportModal(true, userid, "question");
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    }
  };

  render() {
    let { isQaopen, language, productQaWithOutLimit, session } = this.props;
    let customDirectionstyle =
      language === "ar" ? { direction: "rtl" } : { direction: "ltl" };
    // Price Functionality

    return (
      <>
        <Transition.Root show={isQaopen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            open={isQaopen}
            onClose={this.closeSlideOvers}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              {LocaleStrings.questions_answers}
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                className="btn-closeModal"
                                onClick={this.closeSlideOvers}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="px-4">
                          <div className="block mt-4">
                            <p className="text-xs font-semibold text-secondary">
                              {LocaleStrings.questions_answers}
                            </p>
                            <p className="mt-1 text-base text-primary">
                              {LocaleStrings.questions_answers_subtext}
                            </p>
                          </div>

                          <button
                            className="bg-blue-100 border-2 border-primary p-2 mt-2 rounded text-xs"
                            onClick={(e) => this.askAQuestion(e)}
                          >
                            {LocaleStrings.ask_a_question}
                          </button>
                        </div>
                        <div className="mt-6 relative flex-1 px-4 sm:px-6">
                          {Object.keys(productQaWithOutLimit).length > 0 ? (
                            <>
                              <ul
                                role="list"
                                className="-my-5 divide-y divide-gray-200 mt-2 mb-2"
                              >
                                {this.renderQaWithOutLimit()}
                              </ul>
                            </>
                          ) : (
                            <>{this.renderLoader()}</>
                          )}
                        </div>
                      </div>
                      <div className="flex-shrink-0 px-4 py-4  justify-center">
                        {/* Answer Section  */}
                        <div
                          className={classNames(
                            !this.state.giveAnswer ? "hidden" : "",
                            ""
                          )}
                        >
                          <div className="grid grid-cols-4 gap-2">
                            <div>
                              <img
                                loading="lazy"
                                src="/images2/common/askquestion.svg"
                                className="w-5 cursor-pointer"
                              />
                            </div>
                            <div className="col-span-2 text-left">
                              {this.state.QuestionForAmswer}
                            </div>
                            <div onClick={(e) => this.closeAnswerSection()}>
                              <p
                                className={classNames(
                                  language === "ar"
                                    ? "text-left"
                                    : "text-right",
                                  "text-sm font-medium text-primary truncate cursor-pointer"
                                )}
                              >
                                {LocaleStrings.close}
                              </p>
                            </div>
                          </div>

                          <div
                            className="mt-1 relative rounded-md shadow-sm"
                            style={customDirectionstyle}
                          >
                            <input
                              type="text"
                              name="answer_of_question_input"
                              id="answer_of_question_input"
                              className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-secondary px-4 rounded-full"
                              placeholder={LocaleStrings.type_your_answer}
                              onChange={(e) => this._handelAnswerChange(e)}
                            />
                            <div
                              className={classNames(
                                language === "ar" ? "left-0" : "right-0",
                                "absolute inset-y-0  px-3 flex items-center"
                              )}
                            >
                              <img
                                loading="lazy"
                                src="/images2/common/send_btn.svg"
                                className="w-5 cursor-pointer"
                                onClick={(e) => this._submitAnswerForQuestion()}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Question Section */}
                        <div
                          className={classNames(
                            !this.state.askQuestion ? "hidden" : "",
                            ""
                          )}
                        >
                          <div className="grid grid-cols-4 gap-2">
                            <div></div>
                            <div className="col-span-2 text-left"></div>
                            <div onClick={(e) => this.closeQuestionSection()}>
                              <p
                                className={classNames(
                                  language === "ar"
                                    ? "text-left"
                                    : "text-right",
                                  "text-sm font-medium text-primary truncate cursor-pointer"
                                )}
                              >
                                {LocaleStrings.close}
                              </p>
                            </div>
                          </div>

                          <div
                            className="mt-1 relative rounded-md shadow-sm"
                            style={customDirectionstyle}
                          >
                            <input
                              type="text"
                              name="answer_of_question_input"
                              id="answer_of_question_input"
                              className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-secondary px-4 rounded-full"
                              placeholder={LocaleStrings.type_your_question}
                              onChange={(e) => this._handelQuestionChange(e)}
                            />
                            <div
                              className={classNames(
                                language === "ar" ? "left-0" : "right-0",
                                "absolute inset-y-0  px-3 flex items-center"
                              )}
                            >
                              <img
                                loading="lazy"
                                src="/images2/common/send_btn.svg"
                                className="w-5 cursor-pointer"
                                onClick={(e) => this._submitQuestion()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user, language } = state;

  return {
    session,
    user,
    language,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isQaopen: state.isQaopen,
    productQaWithOutLimit: !_.isEmpty(state.productQaWithOutLimit)
      ? !_.isEmpty(state.productQaWithOutLimit.data)
        ? state.productQaWithOutLimit.data
        : []
      : [],
    productID: ownProps.productID,
  };
}

export default connect(mapStateToProps, {
  isQaModalOpen,
  fetchqa,
  questionanswer,
  openQAReportModal,
})(QaWithOutLimit);
