import {
  SALESMANAGEMENT_VENDORSTATS,
  SALESMANAGEMENT_VENDORSALESSTATS,
  SALESMANAGEMENT_STATBOX_SELECTED,
  SALESMANAGEMENT_NEW_ORDERS,
  SALESMANAGEMENT_NEGOTIATION_LIST,
  IS_NEGO_SLIDEOVERS_MODAL_OPEN,
  SALESMANAGEMENT_NEGOT_DETAILS,
  IS_NEW_ORDERDETAILS_MODAL_OPEN,
  SALESMANAGEMENT_NEWORDERS_ORDERID,
  SALESMANAGEMENT_NEWORDERS_ORDEROBJ,
  IS_CAME_FROM_SALES_MANAGEMNET,
} from "@salesmanagementActions";

import LocaleStrings from "@language";

// Tabs On Saler Managment Data Tabs
export var salesDateTabs = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.saler_tab_text_daily,
      href: "#",
      current: true,
      position: 0,
    },
    {
      name: LocaleStrings.saler_tab_text_weekly,
      href: "#",
      current: false,
      position: 1,
    },
    {
      name: LocaleStrings.saler_tab_text_monthly,
      href: "#",
      current: false,
      position: 2,
    },
  ];

  return state;
};

// Tabs On Saler Managment Data Tabs
export var salesCancRetuReplTabs = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.tab_text_cancelled,
      href: "#",
      current: true,
      position: 0,
    },
    {
      name: LocaleStrings.tab_text_returns,
      href: "#",
      current: false,
      position: 1,
    },
    {
      name: LocaleStrings.tab_text_replaced,
      href: "#",
      current: false,
      position: 2,
    },
  ];

  return state;
};

/***** Month List With API index [Monthly Tab] *****/
export var salesDateMonthsIndex = (state = [], action) => {
  var state = [
    { actname: "January", name: LocaleStrings.january, position: 1 },
    { actname: "February", name: LocaleStrings.february, position: 2 },
    { actname: "March", name: LocaleStrings.march, position: 3 },
    { actname: "April", name: LocaleStrings.april, position: 4 },
    { actname: "May", name: LocaleStrings.may, position: 5 },
    { actname: "June", name: LocaleStrings.june, position: 6 },
    { actname: "July", name: LocaleStrings.july, position: 7 },
    { actname: "August", name: LocaleStrings.august, position: 8 },
    { actname: "September", name: LocaleStrings.september, position: 9 },
    { actname: "October", name: LocaleStrings.october, position: 10 },
    { actname: "November", name: LocaleStrings.november, position: 11 },
    { actname: "December", name: LocaleStrings.december, position: 12 },
  ];

  return state;
};

/**** Fetch vendor Stats *****/
export var VendorStatsStore = (state = {}, action) => {
  switch (action.type) {
    case SALESMANAGEMENT_VENDORSTATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Fetch vendor Stats *****/
export var VendorSalesStatsStore = (state = {}, action) => {
  switch (action.type) {
    case SALESMANAGEMENT_VENDORSALESSTATS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Store vendor Negotiation Offer List *****/
export var VendorNegotiationListStore = (state = {}, action) => {
  switch (action.type) {
    case SALESMANAGEMENT_NEGOTIATION_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Fetch vendor Order List *****/
export var VendorOrderListStore = (state = {}, action) => {
  switch (action.type) {
    case SALESMANAGEMENT_NEW_ORDERS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/***** sales State box selected name *****/
export var salesmanStatboxSelectedStore = (state = {}, action) => {
  switch (action.type) {
    case SALESMANAGEMENT_STATBOX_SELECTED:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// Add Negotiation Slide-overs Modal storage
export var isNegotiationopen = (state = false, action) => {
  if (action.type === IS_NEGO_SLIDEOVERS_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** Store IS modal Open Or not As new Order Details ****/
export var isNewOrderDetlModalopen = (state = false, action) => {
  if (action.type === IS_NEW_ORDERDETAILS_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** Store New Order Order Id */
export var CurrentOrderID = (state = "#", action) => {
  if (action.type === SALESMANAGEMENT_NEWORDERS_ORDERID) {
    state = action.payload;
  }

  return state;
};

/**** Store New Order Order Object */
export var CurrentOrderObj = (state = {}, action) => {
  if (action.type === SALESMANAGEMENT_NEWORDERS_ORDEROBJ) {
    state = action.payload;
  }
  return state;
};

/**** Store vendor Negotiation Details *****/
export var VendorNegotiationDetailsStore = (state = {}, action) => {
  switch (action.type) {
    case SALESMANAGEMENT_NEGOT_DETAILS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Store IS modal Open Or not As new Order Details ****/
export var cameFromSalesMange = (state = false, action) => {
  if (action.type === IS_CAME_FROM_SALES_MANAGEMNET) {
    state = action.payload;
  }

  return state;
};
