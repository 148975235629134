import React, { Component } from "react";
import { connect } from "react-redux";

import _ from "lodash";

import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
// Import Actions
import {
  isNegotiationOpen,
  fetchNegotiationDetails,
} from "@salesmanagementActions";

// Common Functions
import {
  ProductTextLocalized,
  classNames,
  getBilangualeText,
} from "@commonFunction";
import LocaleStrings from "@language";

class NegotiationTableList extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
    this.orderStatusArr = [
      {
        name: "negotiated",
        nametoshow: LocaleStrings.order_negotiated,
        lang: "order_negotiated",
      },
      {
        name: "closed",
        nametoshow: LocaleStrings.order_closed,
        lang: "order_closed",
      },
      {
        name: "initiated",
        nametoshow: LocaleStrings.order_initiated,
        lang: "order_initiated",
      },
      {
        name: "responded",
        nametoshow: LocaleStrings.order_responded,
        lang: "order_responded",
      },
      {
        name: "requested",
        nametoshow: LocaleStrings.order_requested,
        lang: "order_requested",
      },
    ];
  }

  componentDidMount() {
    let { notifiSingleObjectStore } = this.props;

    /* This section work when it comes from notification */
    if (!_.isEmpty(notifiSingleObjectStore)) {
      if (
        notifiSingleObjectStore.notifications_by_notificationid
          .notificationtype === "negotiation"
      ) {
        let threadid =
          notifiSingleObjectStore.notifications_by_notificationid
            .negotiationthreadid;

        this._handleNegoDetails(this, threadid);
      }
    }
  }

  /***** Open nego Detals On Click *****/
  _handleNegoDetails(e, threadid) {
    this.props.fetchNegotiationDetails(
      this.props.session,
      threadid,
      (callBack) => {}
    );
    this.props.isNegotiationOpen(true);
  }

  /* Get Status text */
  getBilingualStatuesText = (status) => {
    let filteredArry = _.filter(this.orderStatusArr, ["name", status]);

    if (_.size(filteredArry) > 0) {
      return filteredArry[0].nametoshow;
    } else {
      return status;
    }
  };

  render() {
    let { negotiationObj, local_lang, localCurrency } = this.props;

    let discounttype = "";
    let discount_percentage = "";
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    /* Negotiated currency */
    let negoCurrency = negotiationObj.odcurrency;

    var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${this.props.negotiationObj.productimage}?api_key=${APP_API_KEY}`;
    let productname = ProductTextLocalized(
      this.props.negotiationObj.productname_en,
      this.props.negotiationObj.productname_ar,
      local_lang
    ); // language

    let quantity = this.props.negotiationObj.quantity;

    let status = "";
    status = this.props.negotiationObj.status;

    /* DISCOUNT */
    if (!_.isEmpty(this.props.negotiationObj.discount)) {
      discounttype = negotiationObj.discount.discounttype;

      // If Discount Object exsist
      discount_percentage = parseFloat(
        this.props.negotiationObj.discount.discount_percentage
      ).toFixed(2);
    }

    let originalprice = isCurrencyRateApplicable(
      localCurrency,
      negoCurrency,
      negotiationObj?.originalprice
    );

    let priceToshow = "";
    let priceCrossed = "";

    if (negotiationObj.discountactive) {
      if (negotiationObj.shipping_type === "flat") {
        priceToshow =
          parseFloat(originalprice).toFixed(2) -
          parseFloat(discount_percentage).toFixed(2);
        priceToshow = parseFloat(priceToshow).toFixed(2);
      } else {
        priceToshow =
          parseFloat(originalprice).toFixed(2) -
          (parseFloat(originalprice).toFixed(2) * discount_percentage) / 100;
      }

      priceCrossed = parseFloat(originalprice).toFixed(2);
    } else {
      priceToshow = parseFloat(originalprice).toFixed(2);
    }

    let price = isCurrencyRateApplicable(
      localCurrency,
      negoCurrency,
      negotiationObj.price
    );

    discount_percentage = isCurrencyRateApplicable(
      localCurrency,
      negoCurrency,
      discount_percentage
    );

    return (
      <tr
        key={`negolist-${this.props.position}`}
        className="cursor-pointer"
        onClick={(e) =>
          this._handleNegoDetails(
            e,
            this.props.negotiationObj.negotiationthreadid
          )
        }
      >
        <td className="sales-table-td">
          <div className=" h-10 w-10">
            <img
              loading="lazy"
              className="h-10 w-10 rounded-full"
              src={productImageUrl}
              alt="product-image"
            />
          </div>
          <div className="mt-1">
            <div className="text-sm font-medium text-secondary">
              {productname.length > 30
                ? productname.substring(0, 30) + "..."
                : productname}
            </div>
          </div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text">
            {this.props.negotiationObj.purchasedby.username}
          </div>
        </td>
        {/* Pricing column */}
        <td className="sales-table-td">
          {/* <p>
            {"shipping_type -"}
            {negotiationObj.shipping_type}
          </p>
          <p>
            {"discountactive = "}
            {negotiationObj.discountactive}
          </p>
          <p>
            {"discounttype = "}
            {discounttype}
          </p> */}
          <p className="text-xs font-semibold text-secondary">
            {currency}
            {parseFloat(negotiationObj.price).toFixed(2)} /{" "}
            {getBilangualeText(negotiationObj.units)}
            {negotiationObj?.thread?.units}
          </p>
          {/* Originalprice */}
          <div>
            {negotiationObj.discountactive ? (
              <p className="text-xs line-through ...">
                {currency}
                {parseFloat(negotiationObj?.originalprice).toFixed(2)}
              </p>
            ) : (
              ""
            )}
          </div>
          {/* Discount Percentage */}
          <div>
          {negotiationObj.discountactive ? (
          <p
          className={classNames(
            discount_percentage !== "" ? "" : "hidden",
            "text-sm text-danger"
          )}
        >
          {/* {currency} */}
          {parseFloat(discount_percentage).toFixed(2)}
          {discounttype === "percentage" ? "%" : ""} {LocaleStrings.off}
        </p>
          ):(
            ''
          )}
          
          </div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text">{quantity}</div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text capitalize">
            {this.getBilingualStatuesText(status)}
          </div>
        </td>
      </tr>
    );
  }
}
// isNegotiationopen

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    local_lang: state.language,
    isNegotiationopen: state.isNegotiationopen,
    notifiSingleObjectStore: _.isEmpty(state.notifiSingleObjectStore)
      ? []
      : state.notifiSingleObjectStore,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {
  isNegotiationOpen,
  fetchNegotiationDetails,
})(NegotiationTableList);
