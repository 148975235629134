/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";

import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import LocaleStrings from "@language";

// Import Actions
import { selectedProfileSettingsMenu } from "@profileSettingsActions";
import { openStoreSetupModal } from "@productmanagementActions";
import { onSidebarMenuSelected } from "@sidebarActions";

function SetupBlockerModal(props) {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const history = useHistory();

  let { setupStoreBlockerModal } = props;

  const closeModal = () => {
    props.openStoreSetupModal(false);
  };

  const redirectToStore = () => {
    let { session } = props;
    props.openStoreSetupModal(false);
    props.onSidebarMenuSelected(2); // Select side menu
    history.push("/dashboard/profilesettings");
    if (session.usertype == "personal") {
      props.selectedProfileSettingsMenu(session, 6);
    } else {
      props.selectedProfileSettingsMenu(session, 1);
    }
  };

  useEffect(() => {
    return () => {
      props.openStoreSetupModal(false);
    };
  }, []);

  let title = LocaleStrings.setup_store_header_text;
  let description = LocaleStrings.setup_store_subheader_text;
  if (props.session.usertype == "personal") {
    title = LocaleStrings.setup_address_header_text_personal;
    description = LocaleStrings.setup_address_subheader_text_personal;
  }
  return (
    <Transition.Root show={setupStoreBlockerModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        // onClose={setOpen}
        open={setupStoreBlockerModal}
        onClose={() => closeModal()}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-secondary">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="btn-danger"
                  onClick={() => closeModal()}
                  ref={cancelButtonRef}>
                  {LocaleStrings.alert_head_cancel}
                </button>
                <button
                  type="button"
                  className="btn-primary"
                  onClick={() => redirectToStore()}>
                  {LocaleStrings.setup_store_setup_btn_text}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state) {
  var { session } = state;

  return {
    session,
    isLoggedIn: state.isLoggedIn,
    setupStoreBlockerModal: state.setupStoreBlockerModal,
  };
}

export default connect(mapStateToProps, {
  openStoreSetupModal,
  onSidebarMenuSelected,
  selectedProfileSettingsMenu,
})(SetupBlockerModal);
