import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import moment from "moment-timezone";
import _ from "lodash";
import { converDateIntoLocal } from "@commonFunction";
import { DATE_FORMAT_TO_SHOW } from "@constant";
import toast, { Toaster } from "react-hot-toast";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
// Import BaseLoader
import BaseLoader from "@baseLoader";
// Import Actions
import { updateorder } from "../../../actions/index";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";

// Component Import

/* This example requires Tailwind CSS v2.0+ */

import { CheckIcon } from "@heroicons/react/solid";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class ShippingStatus extends Component {
  constructor(props) {
    super(props);

    // this.state = {

    // };

    // All Binded Functions
    //
    this.statusArr = [
      { name: "delivered", nametoshow: LocaleStrings.order_delivered },
      { name: "intransit", nametoshow: LocaleStrings.in_transit },
      { name: "dispatched", nametoshow: LocaleStrings.order_dispatched },
      { name: "placed", nametoshow: LocaleStrings.order_Placed },
      { name: "cancelled", nametoshow: LocaleStrings.order_cancelled },
      { name: "returnreceived", nametoshow: LocaleStrings.return_received },
      { name: "returnpicked", nametoshow: LocaleStrings.return_picked },
      { name: "initiatepickup", nametoshow: LocaleStrings.initiate_pickup },
      { name: "return", nametoshow: LocaleStrings.return_requested },
      { name: "replace", nametoshow: LocaleStrings.replacement_requested },
      { name: "refund", nametoshow: LocaleStrings.refund_initiated },
      { name: "pending", nametoshow: LocaleStrings.payment_pending },
      { name: "refundrejected", nametoshow: LocaleStrings.refund_rejected },
      { name: "refunded", nametoshow: LocaleStrings.refunded },
      { name: "reshipdelivered", nametoshow: LocaleStrings.reshipdelivered },
      { name: "reship", nametoshow: LocaleStrings.replace_reshippped },
      { name: "cancelrefund", nametoshow: LocaleStrings.cancel_refund },
      { name: "cancelrefunded", nametoshow: LocaleStrings.cancel_refunded },
      {
        name: "cancelrefundrejected",
        nametoshow: LocaleStrings.cancel_refundrejected,
      },
      {
        name: "initiatereplacepickup",
        nametoshow: LocaleStrings.initiate_replace_pickup,
      },
      {
        name: "cancelreturn",
        nametoshow: LocaleStrings.return_cancelled,
      },
      {
        name: "cancelledrefundprocessing",
        nametoshow: LocaleStrings.refund_processing,
      },
      {
        name: "returnrefundprocessing",
        nametoshow: LocaleStrings.refund_processing,
      },
    ];
  }

  componentDidMount() {}

  componentWillUnmount() {}

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  render() {
    let {
      isNewOrdDtilOpen,
      language,
      currentOrderID,
      vendorOrderListStore,
      data,
      localCurrency,
      orderCurrency,
    } = this.props;
    let local_lang = this.props.language;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    // let price = isCurrencyRateApplicable(
    //   localCurrency,
    //   negoCurrency,
    //   negotiationObj.price
    // );

    return (
      <section
        aria-labelledby="timeline-title"
        className="lg:col-start-3 lg:col-span-1">
        <div className="bg-custWhite px-4 py-5 shadow sm:rounded-lg sm:px-6 mt-6">
          <h2
            id="timeline-title"
            className="text-sm font-medium text-secondary">
            {LocaleStrings.shipping_status}
          </h2>

          {/* Activity Feed */}
          <div className="mt-6 flow-root">
            <ul className="-mb-8">
              {data.map((item, itemIdx) => (
                <li key={item.id}>
                  <div className="relative pb-10">
                    {itemIdx !== data.length - 1 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full  border-primary cust-dash"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div className="bg-custWhite">
                        <span
                          className={
                            "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                          }>
                          {item.status == "cancelled" ? (
                            <XIcon
                              className="w-5 h-5 text-white bg-danger rounded-full"
                              aria-hidden="true"
                            />
                          ) : (
                            <CheckIcon
                              className="w-5 h-5 text-white bg-success rounded-full"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </div>
                      <div className="min-w-0 flex-1  flex justify-between space-x-4">
                        <div>
                          <p className="text-sm text-secondary">
                            {
                              _.find(this.statusArr, { name: item.status })
                                .nametoshow
                            }{" "}
                            {/* Show refunded amount if status 'refunded' */}
                            {item?.status === "refunded"
                              ? "(" +
                                currency +
                                parseFloat(
                                  item?.refundamount
                                  // isCurrencyRateApplicable(
                                  //   localCurrency,
                                  //   orderCurrency,
                                  //   item?.refundamount
                                  // )
                                ).toFixed(2) +
                                ")"
                              : ""}
                          </p>
                          <p className="text-sm text-ternary">
                            {moment(item.updatedat).format("D MMM YYYY")}
                            {/* {converDateIntoLocal(item.updatedat).format(
                              DATE_FORMAT_TO_SHOW
                            )} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    localCurrency: state.currency,
    currentOrderID: state.currentOrderID,
    data: ownProps.data,
  };
}

export default connect(mapStateToProps, {
  updateorder,
})(ShippingStatus);
