import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import Actions
import { isCouponManageModalopen, selectedCouponsTab } from "../actions/index";

// Common Functions

// Functions Import
import { classNames } from "@commonFunction";

class CouponManagHead extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
    this.state = {};
  }

  // Modal Open Fc
  openCouponsManagementModal() {
    this.props.isCouponManageModalopen(true);
  }

  // Change Tabs Selection
  _handleTab(e, tabPosition) {
    this.props.selectedCouponsTab(parseInt(tabPosition));
  }

  render() {
    return (
      <>
        {/* Header Part */}
        <div className="grid gap-4 grid-cols-2  bg-custWhite rounded ">
          <div className="mx-4 mt-1 xs:col-span-2 md:col-span-1">
            <nav
              className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
              aria-label="Tabs"
            >
              {this.props.couponsTabs.map((tab, tabIdx) => (
                <a
                  key={tab.name}
                  onClick={(e) => this._handleTab(e, tab.position)}
                  className={classNames(
                    tab.position === this.props.storedCouponsTab
                      ? "text-primary"
                      : "text-ternary hover:text-secondary",
                    tabIdx === 0 ? "rounded-l-lg" : "",
                    tabIdx === this.props.couponsTabs.length - 1
                      ? "rounded-r-lg"
                      : "",
                    "saler-date-tabs"
                  )}
                  aria-current={
                    tab.position === this.props.storedCouponsTab
                      ? "page"
                      : undefined
                  }
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      tab.position === this.props.storedCouponsTab
                        ? "bg-primary"
                        : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </a>
              ))}
            </nav>
          </div>
          <div className="px-4 end-item xs:col-span-2 md:col-span-1">
            <div className="w-48">
              <button
                type="submit"
                className="btn-primary mt-3 w-48"
                onClick={(e) => this.openCouponsManagementModal(e)}
              >
                {LocaleStrings.add_new_coupons}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    couponsTabs: state.couponsTabs,
    storedCouponsTab: state.storedCouponsTab,
  };
}

export default connect(mapStateToProps, {
  isCouponManageModalopen,
  selectedCouponsTab,
})(CouponManagHead);
