import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  change,
  FieldArray,
  getFormValues,
} from "redux-form";
import LocaleStrings from "@language";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";

import moment from "moment";
import _ from "lodash";
import ReactReadMoreReadLess from "react-read-more-read-less";

// Import BaseLoader
import BaseLoader from "@baseLoader";

// Import Tailwind
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { StarIcon } from "@heroicons/react/outline";
import renderHTML from "react-render-html";

// Import Actions
import {
  productFeeModal,
  setProductFeeDefault,
  setProductConfirmBtnClickedOrNot,
  fetchProductManagementDefaultSettings,
  isCommentsModalOpen,
  isLikesModalOpen,
  deleteproduct,
  productbyidtoedit,
  productbyid,
  toggleunlistproduct,
  isbidPlacedSliderOpen,
  fetchPublicBids,
  cancelBid,
  showUploadSection,
  fetchGlobalSpecifications,
  fetchProductCategorylist,
  directPeoductUpdateWithObject,
  relistwhileUpdatingProduct,
} from "@productmanagementActions";
import { deleteDiscountWithID } from "@discountsmanagementActions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
// Import Action
import { fetchSettingsMaster } from "@legaldocsActions";

// Functions Import
import {
  classNames,
  isEmpty,
  ProductTextLocalized,
  updateMRP,
} from "@commonFunction";

// Import Components
import DetailsImgCarousel from "./detailsimgcarousel";
import QaWithLimit from "./qawithlimit.js";
import ViewComments from "./viewcomments.js";
import ViewLikes from "./view-likes.js";
import PublicBid from "./public-bid.js";
import ProductPaymentStatus from "./product-payment-status.js";
import ReviewAndRatingLimited from "@commonFolder/review-and-rating-limited.js";
import ReportAbuseQa from "@reportAbuseQa";

// Constants
import { APP_API_KEY, BASE_IMAGES_URL, timeDiffCalc } from "@constant";
const striptags = require("striptags").striptags;
var selectionArr = []; // Array for refkeys for Variant [XL] [L]

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_product_details,
      btnLoader: false,
      isDefault: true,
      viewAllDetailsState: false,
      allVariants: [],
      productVariants: [],
      variantId: [],
      selectedVariant: [],
      isBtnDisable: false,
      btnLoader: false,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let productId = ""; // Store url product id
    let variantId = ""; // url variant id
    let { productDetails, language, session, history } = this.props;

    let productAndVariantID = this.props.match.params.id; // It may containt priduct id or pri=oductid and variant id

    let paramArray = _.split(productAndVariantID, "-");

    if (_.size(paramArray) === 2) {
      // Product id and variant id both exixst

      productId = paramArray[0];
      variantId = paramArray[1];
    } else if (_.size(paramArray) === 1) {
      // Only Product id exist
      productId = paramArray[0];
    } else {
    }

    // Get Product Default settings
    this.props.fetchProductManagementDefaultSettings(
      this.props.session,
      (CallBackResponse) => {}
    );

    // Settings master
    this.props.fetchSettingsMaster(this.props.session, (callback) => {
      this.setState({ loading: false });
    });

    // Get Product Category And Subcategory List
    this.props.fetchProductCategorylist(
      this.props.session,
      (CallBackResponse) => {}
    );

    /* false => reinitialize or black */
    this.props.productbyid(session, productId, false, (callback) => {
      if (callback.status === 1) {
        // If variant id exisit in url than use that other ways take default
        if (variantId === null || variantId === "null" || variantId === "") {
          variantId = productDetails.variantid;
        }

        productDetails = callback.data;
        this.setState({ loading: false });
        this.setState({
          variantId: variantId,
        });

        // All Variants
        if (
          productDetails.all_variants &&
          productDetails.all_variants.length > 0
        ) {
          let allVariants = [];

          _.map(productDetails.all_variants, (obj, index) => {
            obj.selected = obj.id == variantId ? true : false;
            allVariants.push(obj);
          });

          this.setState({ allVariants: allVariants });
        }

        let selectedVariantArr = _.filter(
          productDetails.all_variants,
          function (o) {
            return o.id == variantId;
          }
        );

        // Product Variants

        if (
          productDetails.product_variants &&
          productDetails.product_variants.length > 0
        ) {
          let productVariants = [];
          _.map(productDetails.product_variants, (obj, index) => {
            let variantnames = [];
            if (obj.variantnames && obj.variantnames.length > 0) {
              _.map(obj.variantnames, (data, index) => {
                data.selected =
                  selectedVariantArr &&
                  selectedVariantArr.length > 0 &&
                  selectedVariantArr[0].refkey.indexOf(data.refkey) !== -1
                    ? true
                    : false;
                variantnames.push(data);
              });
            }
            obj.variantnames = variantnames;
            productVariants.push(obj);
          });

          this.setState({ productVariants: productVariants });
        }

        this.setState({ selectedVariant: selectedVariantArr });
        this.props.fetchPublicBids(session, variantId);
      } else {
      }
    });
  }

  componentWillUnmount() {
    let { session } = this.props;
    /* Reinitialize all local staes */
    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_product_details,
      btnLoader: false,
      isDefault: true,
      viewAllDetailsState: false,
      allVariants: [],
      productVariants: [],
      variantId: [],
      selectedVariant: [],
    };

    /* Reinitialize the single product details */
    this.props.productbyid(session, "", true, (callback) => {});
  }

  /* Handel featured product */
  _handelFeaturedProductToggle = (product) => {
    let { session } = this.props;
    let values = {
      id: parseInt(product.id),
      isfeatured: product.isfeatured === "1" ? 0 : 1,
    };

    // Update any object with id frp product table
    this.props.directPeoductUpdateWithObject(session, values, (callback) => {
      if (callback.success === 1) {
        if (parseInt(product.isfeatured) === 0) {
          toast.success(LocaleStrings.fetured_success);
        } else {
          toast.success(LocaleStrings.unfetured_success);
        }

        this.props.productbyid(
          session,
          parseInt(product.id),
          false, // for edit
          (callback1) => {}
        );
      } else {
        toast.error(LocaleStrings.something_went_wrong);
      }
    });
  };

  /* Scroll to rating */
  _handelScroolTorating = (count = 0, callfor) => {
    const isChrome = window.chrome ? true : false;

    if (count > 0) {
      window.scrollTo(
        0,
        this.myRef.current.scrollIntoView({
          behavior: isChrome ? "auto" : "smooth",
          block: "end",
          inline: "nearest",
        })
      );
    }
  };

  _handleBack = (e, param) => {
    // Param -> goto list
    this.props.showUploadSection(param); // this.props.showUploadSection(e, "VL");
    this.props.history.push("/dashboard/productmanagement");
  };

  /**** Arrow Function for BASE VARIANT LOOP THROUGH ****/
  renderBaseVariants = () => {
    let { language, productDetails } = this.props;
    let { isDefault } = this.state;

    if (isDefault) {
      // For default make it blck so do not take each render value
      selectionArr = [];
    }

    var item = _.map(
      productDetails.product_variants,
      (basevariantsObj, index) => {
        if (isDefault) {
          // For default MAke array with all first veriant

          selectionArr.push(basevariantsObj.variantnames[0].refkey);
        }

        let variant = ProductTextLocalized(
          basevariantsObj.name_en,
          basevariantsObj.name_ar,
          language
        );

        return (
          <div className="">
            <div className=" inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-ternary" />
            </div>
            <div className="py-2">
              <p className="text-sm  text-secondary">{variant}</p>
            </div>
            <div className="py-2">
              {
                /**** Product variants name ****/
                _.map(
                  basevariantsObj.variantnames,
                  (variantnamesObj, indexInner) => {
                    let variantName = ProductTextLocalized(
                      variantnamesObj.name_en,
                      variantnamesObj.name_ar,
                      language
                    );

                    return isDefault === true && indexInner === 0 ? (
                      <button
                        type="button"
                        className={classNames(
                          isDefault
                            ? "border-primary text-primary"
                            : "border-ternary text-secondary",
                          "mx-2 inline-flex items-center px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
                        )}
                        onClick={(e) =>
                          this._handelVariantBtnClick(
                            variantnamesObj.refkey,
                            index,
                            variantnamesObj
                          )
                        }>
                        {variantName}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={classNames(
                          selectionArr[index] === variantnamesObj.refkey
                            ? "border-primary text-primary"
                            : "border-ternary text-secondary",
                          "mx-2 inline-flex items-center px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
                        )}
                        onClick={(e) =>
                          this._handelVariantBtnClick(
                            variantnamesObj.refkey,
                            index,
                            variantnamesObj
                          )
                        }>
                        {variantName}
                      </button>
                    );
                  }
                )
              }
            </div>
          </div>
        );
      }
    );

    return item;
  };

  /**** Variant Names XL/L Button Click ****/
  _handelVariantBtnClick(refkey, index, variantnamesObj) {
    let { session } = this.props;
    if (selectionArr[index] !== refkey) {
      // array modified
      selectionArr[index] = refkey;
    }

    this.setState({ isDefault: false });
  }

  renderProductVariant = (variantnames, pos, obj) => {
    let { language, session } = this.props;

    if (variantnames && variantnames.length > 0) {
      return _.map(variantnames, (vari, index) => {
        return (
          <button
            type="button"
            onClick={
              vari.selected
                ? null
                : () => this.makeVariantSelection(vari.refkey, pos, vari)
            }
            className={classNames(
              vari.selected
                ? "border-primary text-primary"
                : "border-ternary text-secondary",
              "justify-items-center mx-2  px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
            )}
            key={index}>
            {ProductTextLocalized(vari.name_en, vari.name_ar, language)}
          </button>
        );
      });
    }
  };

  makeVariantSelection = (refkey, pos, obj) => {
    let { productVariants, allVariants } = this.state;
    let { session } = this.props;

    if (Object.keys(productVariants).length > 0) {
      let productVariantsArr = [];
      let selectedVariantKeys = [];

      _.map(productVariants, (obj, row) => {
        let variantnames = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.map(obj.variantnames, (data, index) => {
            if (pos == row) {
              data.selected = data.refkey == refkey ? true : false;
              if (data.selected) {
                selectedVariantKeys.push(refkey);
              }
            } else {
              data.selected = data.selected;
              if (data.selected) {
                selectedVariantKeys.push(data.refkey);
              }
            }

            variantnames.push(data);
          });
        }

        obj.variantnames = variantnames;
        productVariantsArr.push(obj);
      });

      this.setState({ productVariants: productVariantsArr });

      let selectedVariantKey = selectedVariantKeys.join("-");

      if (allVariants.length > 0) {
        let allVariantsArr = [];

        _.map(allVariants, (obj, index) => {
          obj.selected = obj.refkey == selectedVariantKey ? true : false;
          allVariantsArr.push(obj);
        });

        this.setState({ allVariants: allVariantsArr });

        let selectedVariantArr = _.filter(allVariantsArr, function (o) {
          return o.selected;
        });

        if (selectedVariantArr && selectedVariantArr.length > 0) {
          this.setState({ selectedVariant: selectedVariantArr });
          this.setState({ variantId: selectedVariantArr[0].id });
        }

        this.props.fetchPublicBids(session, selectedVariantArr[0].id);
      }
    }
  };

  /**** Arrow Function for images ****/
  renderFirstImgs = () => {
    let { selectedVariant } = this.state;
    let { productDetails } = this.props;

    var item = _.map(productDetails.all_variants, (mediaObject, index) => {
      var imageUrl = `${BASE_IMAGES_URL}/vendor/variations/${mediaObject.media[0]?.thumbfilename}?api_key=${APP_API_KEY}`;

      return (
        <div className="border-2 border-quaternary mx-1">
          <img
            loading="lazy"
            src={imageUrl}
            className="h-10 w-10 "
            alt="image"
          />
        </div>
      );
    });

    return item;
  };

  /*** Open Comments Modal ***/
  viewComments = () => {
    this.props.isCommentsModalOpen(true);
  };

  /*** Open Likes Modal ***/
  viewLikes = () => {
    this.props.isLikesModalOpen(true);
  };

  /*** Open Public Bids Modal ***/
  viewPublicBids = () => {
    this.props.isbidPlacedSliderOpen(true);
  };

  /*** To Unlist a Product use following api (vendor-datascript/toggleunlistproduct) ***/
  // old code
  // _toggleunlistproduct = (value) => {
  //   let { productDetails, session } = this.props;
  //   let productid = productDetails.id;

  //     this.props.toggleunlistproduct(session, value, productid, (callback) => {
  //       if (callback.status === 1) {
  //         if (parseInt(value) === 1) {
  //           toast.success(LocaleStrings.product_listed_successfully);
  //         } else {
  //           toast.success(LocaleStrings.product_unlisted_successfully);
  //         }
  //         this.props.showUploadSection("VL");
  //       } else {
  //         toast.error(LocaleStrings.something_went_wrong);
  //       }
  //     });
  // };
  // latest code
  _toggleunlistproduct = (value) => {
    let { productDetails, session } = this.props;
    let productid = productDetails.id;
    if (productDetails.saletype != "auction") {
      this.props.toggleunlistproduct(session, value, productid, (callback) => {
        if (callback.status === 1 && callback?.data?.result === "success") {
          //added to refresh unlist relist
          this.props.productbyid(session, productid, false, (callback) => {});
          if (parseInt(value) === 1) {
            toast.success(LocaleStrings.product_listed_successfully);
          } else {
            toast.success(LocaleStrings.product_unlisted_successfully);
          }
          this.props.showUploadSection("VL");
        } else {
          if (callback?.data?.result === "fail") {
            toast.error(
              "This product can't be re-list due to missing data.Please edit and update"
            );
          } else {
            toast.error(LocaleStrings.something_went_wrong);
          }
        }
      });
    } else {
      if (parseInt(value) === 1) {
        this.props.productbyidtoedit(
          session,
          productid,
          1,
          "auctionrelist",
          (callback) => {
            // After Storing tobeedit Product Data in Store Open Upload Product Data
            this.props.relistwhileUpdatingProduct(true);
            this.props.showUploadSection("UP");
            this.props.history.push("/dashboard/productmanagement");
          }
        );
      } else {
        this.props.toggleunlistproduct(
          session,
          value,
          productid,
          (callback) => {
            if (callback.status === 1 && callback?.data?.result === "success") {
              //added to refresh unlist relist
              this.props.productbyid(
                session,
                productid,
                false,
                (callback) => {}
              );
              if (parseInt(value) === 1) {
                toast.success(LocaleStrings.product_listed_successfully);
              } else {
                toast.success(LocaleStrings.product_unlisted_successfully);
              }
              this.props.showUploadSection("VL");
            } else {
              if (callback?.data?.result === "fail") {
                toast.error(
                  "This product can't be re-list due to missing data.Please edit and update"
                );
              } else {
                toast.error(LocaleStrings.something_went_wrong);
              }
            }
          }
        );
      }
    }
  };

  /**** DELETE PRODUCT ****/
  _deleteProduct = () => {
    let { productDetails, session } = this.props;
    let productid = productDetails.id;

    // Before delte confirmation
    confirmAlert({
      title: LocaleStrings.product_delete_confirmation_header_text,
      message: `${LocaleStrings.product_delete_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            // Yes -> Delete
            this.props.deleteproduct(session, productid, (callback) => {
              toast.success(LocaleStrings.product_deleted_successfully);
              this.props.showUploadSection("VL");
              // latest code
              this.props.history.push("/dashboard/productmanagement");
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  /**** Cancel bid ****/
  _cancel_bid = () => {
    let { productDetails, session } = this.props;
    let productid = productDetails.id;

    // Before delte confirmation
    confirmAlert({
      title: LocaleStrings.cancel_bid_confirmation_header_text,
      message: `${LocaleStrings.cancel_bid_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            // Yes -> Delete
            this.props.cancelBid(session, productid, (callback) => {
              if (callback.status === 1) {
                /* After delete fetch product details for Product DetaILS PAGE
                  false => reinitialize or black
                */
                this.props.productbyid(
                  session,
                  productid,
                  false,
                  (callback) => {
                    toast.success(LocaleStrings.bid_cancelled_successfully);
                  }
                );
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  /**** EDIT PRODUCT ****/
  _editProduct = () => {
    let { productDetails, session } = this.props;
    let productid = productDetails.id;

    // call this before opening edit mode pass the data in response
    this.props.productbyidtoedit(session, productid, 1, "", (callback) => {
      // After Storing tobeedit Product Data in Store Open Upload Product Data
      this.props.showUploadSection("UP");
      this.props.history.push("/dashboard/productmanagement");
    });
  };

  renderSPecifications = (callFor, arrayOfObjects) => {
    let { language } = this.props;
    if (callFor === "global") {
      var item = _.map(arrayOfObjects, (object, index) => {
        return (
          <p className="text-xs font-semibold text-ternary my-2">
            {ProductTextLocalized(object.name_en, object.name_ar, language)} :{" "}
            {ProductTextLocalized(object.value_en, object.value_ar, language)}
          </p>
        );
      });
    } else if (callFor === "item") {
      var item = _.map(arrayOfObjects, (object, index) => {
        return (
          <p className="text-xs font-semibold text-ternary my-2">
            {ProductTextLocalized(object.name_en, object.name_ar, language)} :{" "}
            {ProductTextLocalized(object.value_en, object.value_ar, language)}
          </p>
        );
      });
    } else {
    }

    return item;
  };

  deleteLocalDiscount(e) {
    let { session, productDetails } = this.props;

    // this.setState({ isBtnDisable: true });
    // this.setState({ btnLoader: true });

    let discountId = productDetails?.discount?.iddiscount;
    // Delete from store & db
    // console.log("productDetails", productDetails);
    // return false;

    confirmAlert({
      title: "Delete Discount",
      message: `Are you sure you want to delete this discount?`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            // Yes -> Delete
            this.props.deleteDiscountWithID(session, discountId, (callback) => {
              if (callback.status === 1) {
                /* false => reinitialize or black */
                let variantId = "";
                this.props.productbyid(
                  session,
                  productDetails.id,
                  false,
                  (callback) => {
                    if (callback.status === 1) {
                      // If variant id exisit in url than use that other ways take default
                      if (
                        variantId === null ||
                        variantId === "null" ||
                        variantId === ""
                      ) {
                        variantId = productDetails.variantid;
                      }

                      productDetails = callback.data;
                      this.setState({ loading: false });
                      this.setState({
                        variantId: variantId,
                      });

                      // All Variants
                      if (
                        productDetails.all_variants &&
                        productDetails.all_variants.length > 0
                      ) {
                        let allVariants = [];

                        _.map(productDetails.all_variants, (obj, index) => {
                          obj.selected = obj.id == variantId ? true : false;
                          allVariants.push(obj);
                        });

                        this.setState({ allVariants: allVariants });
                      }

                      let selectedVariantArr = _.filter(
                        productDetails.all_variants,
                        function (o) {
                          return o.id == variantId;
                        }
                      );

                      // Product Variants

                      if (
                        productDetails.product_variants &&
                        productDetails.product_variants.length > 0
                      ) {
                        let productVariants = [];
                        _.map(productDetails.product_variants, (obj, index) => {
                          let variantnames = [];
                          if (obj.variantnames && obj.variantnames.length > 0) {
                            _.map(obj.variantnames, (data, index) => {
                              data.selected =
                                selectedVariantArr &&
                                selectedVariantArr.length > 0 &&
                                selectedVariantArr[0].refkey.indexOf(
                                  data.refkey
                                ) !== -1
                                  ? true
                                  : false;
                              variantnames.push(data);
                            });
                          }
                          obj.variantnames = variantnames;
                          productVariants.push(obj);
                        });

                        this.setState({ productVariants: productVariants });
                      }

                      this.setState({ selectedVariant: selectedVariantArr });
                      this.props.fetchPublicBids(session, variantId);
                      this.setState({ isBtnDisable: false });
                      this.setState({ btnLoader: false });
                    } else {
                    }
                  }
                );
              } else {
                this.setState({ isBtnDisable: false });
                this.setState({ btnLoader: false });
                toast.error(LocaleStrings.something_went_wrong);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    let {
      session,
      language,
      productDetails,
      user,
      productCategorylist,
      localCurrency,
      commentList,
      publicBids,
      reportAbuseQaModal,
      settingsMasterStore,
    } = this.props;

    // console.log("productDetails", productDetails);

    let { viewAllDetailsState, productVariants, variantId, selectedVariant } =
      this.state;

    let productlink =
      "http://shopez.staging.teks.co.in/product-details/" +
      _.kebabCase(productDetails.productname_en) +
      "-" +
      productDetails.id +
      "-" +
      productDetails.variantid;

    /* Product currency */
    let productCurrency = productDetails.currency;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    let categoryNames = [];
    let subCategoryNames = [];
    let userName = user.username;
    let productName = "";
    let productDesc = "";
    let specificationDesc = "";
    let productConditionName = "";
    let selectedVariantDisplay = "";
    let returnpolicy = "";

    let tooltipStyle = language === "ar" ? { left: "30px" } : { right: "30px" };
    let itemPrice =
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant[0].price_unit
        ? selectedVariant[0].price_unit
        : 0;

    let discountedPrice = "";
    let strProductDiscount = "";

    if (productDetails) {
      let priceObj = updateMRP(productDetails, variantId);
      discountedPrice = priceObj.discountedPrice;
      strProductDiscount = priceObj.strProductDiscount;
    }
    //  selectionArr data.return_time_details.name_en
    productName = ProductTextLocalized(
      productDetails.productname_en,
      productDetails.productname_ar,
      language
    );
    productDesc = ProductTextLocalized(
      productDetails.product_desc_en,
      productDetails.product_desc_ar,
      language
    );
    // [Field - standerd description]
    specificationDesc = ProductTextLocalized(
      productDetails.specification_desc_en,
      productDetails.specification_desc_ar,
      language
    );

    returnpolicy =
      productDetails && productDetails.return_time_details
        ? ProductTextLocalized(
            productDetails.return_time_details.name_en,
            productDetails.return_time_details.name_ar,
            language
          )
        : "";

    productConditionName =
      productDetails && productDetails.product_condition
        ? ProductTextLocalized(
            productDetails.product_condition.condition_en,
            productDetails.product_condition.condition_ar,
            language
          )
        : "";

    let bidactive = productDetails.bidactive;
    let bidcancelled = productDetails.bidcancelled;

    let avgRating =
      productDetails &&
      productDetails.ratings &&
      productDetails.ratings.avgrating
        ? productDetails.ratings.avgrating
        : 0;
    let remRating = 5 - avgRating;

    let sortedData = [];
    if (
      productDetails &&
      productDetails.reviews &&
      productDetails.reviews.length > 0
    ) {
      sortedData = _.orderBy(productDetails.reviews, ["createdat"], ["desc"]);
    }

    if (!_.isEmpty(selectedVariant)) {
      selectedVariantDisplay = ProductTextLocalized(
        selectedVariant[0].name_en,
        selectedVariant[0].name__ar,
        language
      );
    }

    let filterdCategory = _.filter(productCategorylist, (cateObj, index) => {
      return (
        _.indexOf(
          _.split(productDetails.categoryids, ",").map(Number),
          cateObj.id
        ) > -1
      );
    });

    _.forEach(filterdCategory, (fltdCateObject, index) => {
      // Get category names
      categoryNames.push(
        ProductTextLocalized(
          fltdCateObject.categoryname_en,
          fltdCateObject.categoryname_ar,
          language
        )
      );
    });

    // Fetch Subcategory Names
    _.forEach(filterdCategory, (seletedCateObject, index) => {
      _.forEach(
        seletedCateObject.productsubcategory_by_categoryid,
        (cateSubcateObject) => {
          if (
            _.indexOf(
              _.split(productDetails.subcategoryids, ",").map(Number),
              cateSubcateObject.id
            ) > -1
          ) {
            subCategoryNames.push(
              ProductTextLocalized(
                cateSubcateObject.subcategoryname_en,
                cateSubcateObject.subcategoryname_ar,
                language
              )
            );
          }
        }
      );
    });

    /*** SHOW AUCTION DATE ***/
    var auctionText = "";
    var endDate = "";
    if (productDetails.saletype == "auction") {
      let currentDate = moment();
      if (
        productDetails.auction_start_date &&
        productDetails.auction_end_date &&
        currentDate.isAfter(moment(productDetails.auction_start_date)) &&
        currentDate.isBefore(moment(productDetails.auction_end_date))
      ) {
        auctionText = LocaleStrings.ends_in;
        endDate = productDetails.auction_end_date;
      } else if (
        productDetails.auction_start_date &&
        productDetails.auction_end_date &&
        currentDate.isBefore(moment(productDetails.auction_start_date)) &&
        currentDate.isBefore(moment(productDetails.auction_end_date))
      ) {
        auctionText = LocaleStrings.starts_in;
        endDate = productDetails.auction_start_date;
      } else if (
        productDetails.auction_start_date &&
        productDetails.auction_end_date &&
        currentDate.isAfter(moment(productDetails.auction_start_date)) &&
        currentDate.isAfter(moment(productDetails.auction_end_date))
      ) {
        auctionText = LocaleStrings.expired;
        endDate = "";
      }

      if (bidcancelled === "1" || bidcancelled === 1) {
        auctionText = LocaleStrings.cancelled;
      }
    }

    let productIsActive = false;

    productIsActive =
      productDetails.isactive == "1" || productDetails.isactive == 1
        ? true
        : false;
    /*
    If user currency and product currency dont match 
    return currencyrate other waise no need to convert currency value so return 1
    */

    // discountedPrice = isCurrencyRateApplicable(
    //   localCurrency,
    //   productCurrency,
    //   discountedPrice
    // );

    // itemPrice = isCurrencyRateApplicable(
    //   localCurrency,
    //   productCurrency,
    //   itemPrice
    // );
    let shipping_cost = 0;
    if (productDetails?.shipping_cost && productDetails?.shipping_cost != "") {
      shipping_cost = productDetails?.shipping_cost;
    }

    // let shipping_cost = isCurrencyRateApplicable(
    //   localCurrency,
    //   productCurrency,
    //   productDetails?.shipping_cost
    // );

    let outofstock = false;
    let variant = _.filter(productDetails.all_variants, {
      id: variantId,
    });

    if (variant.length > 0 && variant[0].outofstock == 1) {
      outofstock = true;
    }

    var hidemenu = {
      display: "",
    };

    if (auctionText == "Expired" && bidactive == 1) {
      hidemenu = {
        display: "none",
      };
    }

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="py-6 bg-custWhite ">
              {/* HEADER PART */}
              <div className="grid grid-cols-3 gap-4">
                <div
                  align="left"
                  className="flex cursor-pointer col-span-2 px-6">
                  <img
                    loading="lazy"
                    className={" h-6 w-6 "}
                    src={"/images2/common/arrow.svg"}
                    // onClick={(e) => this.props.showUploadSection(e, "VL")}
                    onClick={(e) => this._handleBack(e, "VL")}
                  />
                  <p
                    className="text-primary mx-2 text-l font-bold"
                    // onClick={(e) => this.props.showUploadSection(e, "VL")}
                    onClick={(e) => this._handleBack(e, "VL")}>
                    {LocaleStrings.product_details}
                  </p>
                </div>

                <div
                  className="mx-5 flex gap-4 flex-row-reverse"
                  style={hidemenu}>
                  <Menu
                    as="div"
                    // className={classNames(
                    //   productIsActive ? "" : "sr-only",
                    //   "flex-shrink-0 pr-2"
                    // )}
                    className="flex-shrink-0 pr-2">
                    <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-primary rounded-full hover:text-ternary">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="w-5 h-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items
                        className={classNames(
                          language == "ar" ? "left-10" : "right-10",
                          "z-10 mx-3 origin-top-right absolute  top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-ternary focus:outline-none"
                        )}
                        // className=""
                      >
                        <div className="py-1">
                          {/* EDIT [DROPDOWN] 
                          Note :- In Product details screen when opened from My Products and if bidactive=true give only two options
                          Cancel Bid & Delete
                      */}
                          {/* publicBids?.data?.length */}
                          {productDetails.saletype == "auction" ? (
                            <>
                              {publicBids?.data?.length == 0 ||
                              bidcancelled == 1 ? (
                                <>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        className={classNames(
                                          active
                                            ? "bg-quaternary text-secondary"
                                            : "text-ternary",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                        onClick={(e) => this._editProduct()}>
                                        {LocaleStrings.edit}
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        className={classNames(
                                          active
                                            ? "bg-quaternary text-secondary"
                                            : "text-ternary",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                        onClick={(e) => this._deleteProduct()}>
                                        {LocaleStrings.delete}
                                      </a>
                                    )}
                                  </Menu.Item>
                                  {productDetails.listingexpriydate !== null &&
                                  productDetails.listingexpriydate !== "" &&
                                  moment(
                                    productDetails.listingexpriydate
                                  ).isBefore() ? (
                                    ""
                                  ) : (productDetails.isapproved == 1 ||
                                      productDetails.isapproved == "1") &&
                                    productDetails.isdraft == 0 &&
                                    !outofstock ? (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          className={classNames(
                                            active
                                              ? "bg-quaternary text-secondary"
                                              : "text-ternary",
                                            "block px-4 py-2 text-sm cursor-pointer"
                                          )}
                                          onClick={(e) =>
                                            this._toggleunlistproduct(
                                              parseInt(
                                                productDetails.listed
                                              ) === 1
                                                ? 0
                                                : 1
                                            )
                                          }>
                                          {parseInt(productDetails.listed) === 1
                                            ? LocaleStrings.unlist
                                            : LocaleStrings.relist}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ) : productDetails.isdraft == 0 &&
                                    !outofstock ? (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          className={classNames(
                                            active
                                              ? "bg-quaternary text-secondary"
                                              : "text-ternary",
                                            "block px-4 py-2 text-sm cursor-pointer"
                                          )}
                                          onClick={(e) =>
                                            this._toggleunlistproduct(
                                              parseInt(
                                                productDetails.listed
                                              ) === 1
                                                ? 0
                                                : 1
                                            )
                                          }>
                                          {parseInt(productDetails.listed) === 1
                                            ? LocaleStrings.unlist
                                            : LocaleStrings.relist}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : bidcancelled == 0 &&
                                productDetails.isdraft == 0 &&
                                !outofstock ? (
                                <>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        className={classNames(
                                          active
                                            ? "bg-quaternary text-secondary"
                                            : "text-ternary",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                        onClick={(e) => this._cancel_bid()}>
                                        {LocaleStrings.cancel_bid}
                                      </a>
                                    )}
                                  </Menu.Item>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    className={classNames(
                                      active
                                        ? "bg-quaternary text-secondary"
                                        : "text-ternary",
                                      "block px-4 py-2 text-sm cursor-pointer"
                                    )}
                                    onClick={(e) => this._editProduct()}>
                                    {LocaleStrings.edit}
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    className={classNames(
                                      active
                                        ? "bg-quaternary text-secondary"
                                        : "text-ternary",
                                      "block px-4 py-2 text-sm cursor-pointer"
                                    )}
                                    onClick={(e) => this._deleteProduct()}>
                                    {LocaleStrings.delete}
                                  </a>
                                )}
                              </Menu.Item>
                              {productDetails.listingexpriydate !== null &&
                              productDetails.listingexpriydate !== "" &&
                              moment(
                                productDetails.listingexpriydate
                              ).isBefore() ? (
                                ""
                              ) : (productDetails.isapproved == 1 ||
                                  productDetails.isapproved == "1") &&
                                productDetails.isdraft == 0 &&
                                !outofstock ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-quaternary text-secondary"
                                          : "text-ternary",
                                        "block px-4 py-2 text-sm cursor-pointer"
                                      )}
                                      onClick={(e) =>
                                        this._toggleunlistproduct(
                                          parseInt(productDetails.listed) === 1
                                            ? 0
                                            : 1
                                        )
                                      }>
                                      {parseInt(productDetails.listed) === 1
                                        ? LocaleStrings.unlist
                                        : LocaleStrings.relist}
                                    </a>
                                  )}
                                </Menu.Item>
                              ) : productDetails.isdraft == 0 && !outofstock ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-quaternary text-secondary"
                                          : "text-ternary",
                                        "block px-4 py-2 text-sm cursor-pointer"
                                      )}
                                      onClick={(e) =>
                                        this._toggleunlistproduct(
                                          parseInt(productDetails.listed) === 1
                                            ? 0
                                            : 1
                                        )
                                      }>
                                      {parseInt(productDetails.listed) === 1
                                        ? LocaleStrings.unlist
                                        : LocaleStrings.relist}
                                    </a>
                                  )}
                                </Menu.Item>
                              ) : (
                                ""
                              )}
                            </>
                          )}

                          {/* {bidactive === 0 && bidcancelled === 0 ? (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active
                                    ? "bg-quaternary text-secondary"
                                    : "text-ternary",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                                onClick={(e) => this._editProduct()}
                              >
                                {LocaleStrings.edit}
                              </a>
                            )}
                          </Menu.Item>
                        ) : (
                          ""
                        )} */}

                          {/* DELETE [DROPDOWN] */}
                          {/* {bidactive !== 1 ? 
                          (<Menu.Item>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active
                                    ? "bg-quaternary text-secondary"
                                    : "text-ternary",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                                onClick={(e) => this._deleteProduct()}
                              >
                                {LocaleStrings.delete}
                              </a>
                            )}
                          </Menu.Item>
                          ):('')}
                        */}
                          {/* CANCEL BID [DROPDOWN] 
                          Note :- In Product details screen when opened from My Products and if bidactive=true give only two options
                          Cancel Bid & Delete
                        */}
                          {}
                          {/* {bidactive === 1 ? (
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active
                                      ? "bg-quaternary text-secondary"
                                      : "text-ternary",
                                    "block px-4 py-2 text-sm cursor-pointer"
                                  )}
                                  onClick={(e) => this._cancel_bid()}
                                >
                                  {LocaleStrings.cancel_bid}
                                </a>
                              )}
                            </Menu.Item>
                          ) : (
                            ""
                          )} */}

                          {/* Unlist /relist [Dropdown] 
                          Note :- In Product details screen when opened from My Products and if bidactive=true give only two options
                          Cancel Bid & Delete
                        */}
                          {/* {bidactive === 0 ? (
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active
                                      ? "bg-quaternary text-secondary"
                                      : "text-ternary",
                                    "block px-4 py-2 text-sm cursor-pointer"
                                  )}
                                  onClick={(e) =>
                                    this._toggleunlistproduct(
                                      parseInt(productDetails.listed) === 1
                                        ? 0
                                        : 1
                                    )
                                  }
                                >
                                  {parseInt(productDetails.listed) === 1
                                    ? LocaleStrings.unlist
                                    : LocaleStrings.relist}
                                </a>
                              )}
                            </Menu.Item>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            {/* BODY */}
            <div className="p-6">
              {!_.isEmpty(selectedVariant) ? (
                <DetailsImgCarousel selectedVariant={selectedVariant} />
              ) : (
                ""
              )}
              <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-1 my-5 justify-items-start md:justify-items-center">
                <div>
                  <div className="flex items-center space-x-2">
                    <div
                      className="flex flex-shrink-0 -space-x-1 cursor-pointer"
                      onClick={(e) => this.viewLikes()}>
                      {productDetails?.likes?.map((like) => (
                        <img
                          loading="lazy"
                          key={like.id}
                          className="max-w-none h-8 w-8 rounded-full ring-2 ring-white "
                          src={`${BASE_IMAGES_URL}/users/${like.likedby}.jpg?api_key=${APP_API_KEY}`}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images2/common/user.png";
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <div className="" align="right">
                    <img
                      loading="lazy"
                      className="h-8 w-8 my-auto cursor-pointer"
                      src="/images2/common/like.svg"
                      onClick={(e) => this.viewLikes()}></img>
                  </div>
                  <div className="">
                    <p
                      className="text-base font-semibold text-secondary px-2 pt-2 my-auto"
                      onClick={(e) => this.viewLikes()}>
                      {productDetails.numlikes} {LocaleStrings.likes}
                    </p>
                  </div>
                </div>
                <div className=" space-x-2">
                  <button
                    className="bg-blue-100 border-2 border-primary p-2 rounded"
                    onClick={(e) => this.viewComments()}>
                    {/* Old code which not refresh it self  "productDetails.numcomments" */}
                    {_.size(commentList.data)} {LocaleStrings.comments}
                  </button>
                </div>
              </div>
              {/* PAYMENT STATUS [for personal user] */}
              {session.usertype === "personal" &&
              !_.isEmpty(settingsMasterStore) &&
              settingsMasterStore.listing_perproduct > 0 ? (
                <ProductPaymentStatus />
              ) : (
                ""
              )}
              {/* Pricing */}
              <div className="flex">
                <div>
                  <p className="text-base font-semibold text-secondary ">
                    {currency} {_.round(discountedPrice, 2)}
                  </p>
                </div>
                <div
                  className={classNames(
                    productDetails.discountactive !== 1 ? "hidden" : "",
                    ""
                  )}>
                  <p className="text-base line-through px-2">
                    {currency} {_.round(itemPrice, 2)}
                  </p>
                </div>
                {/* <div
                  className={classNames(
                    productDetails.discountactive !== 1 ? "hidden" : "",
                    ""
                  )}>
                  <p className="text-base text-danger ">{strProductDiscount}</p>
                </div> */}
              </div>
              {productDetails.hasOwnProperty("discount") ? (
                <div className="flex mt-2">
                  <div>
                    <p className="text-base mt-2">
                      Discount Applied:{" "}
                      {productDetails?.discount?.discount_percentage}%
                    </p>
                  </div>
                  <div className="ml-6">
                    <button
                      type="button"
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? "cursor-not-allowed" : "",
                        "btn-primary"
                      )}
                      onClick={(e) => this.deleteLocalDiscount(e)}>
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      Delete
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* Vendor and Product Name */}
              <p
                className={classNames(
                  productDetails.shipping_type !== "local" ? "my-2" : "hidden",
                  "text-base text-ternary"
                )}>
                + {currency} {_.round(shipping_cost, 2)}{" "}
                {LocaleStrings.shipping}
              </p>
              <p className="text-base font-semibold text-primary ">
                {userName}
              </p>
              <p className="text-primary text-l font-bold cursor-pointer underline">
                <a href={productlink} target="_blank">
                  View Product
                </a>
              </p>
              <p className="text-sm font-semibold text-secondary">
                {productName}
              </p>
              <p className="text-xs font-semibold text-ternary mt-1">
                SKU - {productDetails.sku}
              </p>
              {/* Ratings Reviews and solds ,New changes added for auction outofstock  */}
              <div className="flex my-2">
                {productDetails.saletype == "auction" && outofstock === true ? (
                  <span className="card-badge-danger">
                    {LocaleStrings.purchased}
                  </span>
                ) : outofstock == true ? (
                  <span className="card-badge-danger">
                    {LocaleStrings.out_of_stock}
                  </span>
                ) : (
                  ""
                )}

                {parseInt(productDetails.isactive) === 0 &&
                parseInt(productDetails.isapproved) === 1 ? (
                  <span className="card-badge-danger">
                    {LocaleStrings.banned}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="flex mt-4">
                {productDetails.saletype == "auction" ? (
                  <>
                    {/* auction */}
                    {productDetails.isdraft != "1" &&
                    productDetails.isdraft != 1 ? (
                      <>
                        <span className="p-2">
                          <div className="text-primary block text-sm px-4 text-left">
                            {auctionText}
                          </div>
                          <div
                            className={classNames(
                              endDate === ""
                                ? "card-badge-danger"
                                : "card-badge-3",
                              ""
                            )}>
                            {endDate === ""
                              ? LocaleStrings.bid_over
                              : timeDiffCalc(new Date(endDate), new Date())}
                          </div>
                        </span>
                        <span
                          className="card-badge cursor-pointer"
                          onClick={(e) => this.viewPublicBids()}>
                          {publicBids?.data?.length} {LocaleStrings.bids}
                        </span>
                      </>
                    ) : (
                      ""
                    )}

                    {/* <span className="card-badge">
                      <img
                        loading="lazy"
                        className="badge-icon"
                        src={"/images2/product_management_images/eye.svg"}
                      />
                      {productDetails.numwatching}
                    </span> */}
                  </>
                ) : (
                  <>
                    <div className="flex items-center text-sm font-medium ">
                      <img
                        loading="lazy"
                        className={classNames(
                          parseInt(productDetails?.ratings?.totalratings) > 0
                            ? "cursor-pointer"
                            : "",
                          "h-4 w-4 mx-0.5"
                        )}
                        src={"/images2/common/graystar.svg"}
                        onClick={(e) =>
                          this._handelScroolTorating(
                            productDetails?.ratings?.totalratings,
                            "rating"
                          )
                        }
                      />
                      <div className="ml-1">
                        <p className="text-base font-semibold text-ternary ">
                          {productDetails?.ratings?.totalratings}
                        </p>
                      </div>
                    </div>
                    <div className="px-4">
                      <p
                        className={classNames(
                          parseInt(productDetails?.ratings?.totalreviews) > 0
                            ? "cursor-pointer"
                            : "",
                          "text-base text-primary px-2"
                        )}
                        onClick={(e) =>
                          this._handelScroolTorating(
                            productDetails?.ratings?.totalratings,
                            "reviews"
                          )
                        }>
                        {productDetails?.ratings?.totalreviews}{" "}
                        {LocaleStrings.reviews}
                      </p>
                    </div>
                    <div>
                      <p className="text-base text-ternary ">
                        {selectedVariant[0]?.numsold
                          ? selectedVariant[0]?.numsold
                          : 0}{" "}
                        {LocaleStrings.sold}
                      </p>
                    </div>
                  </>
                )}
              </div>

              {/* Fetured / Unfetured option commented */}
              {/* <div className="end-item">
                <div className="tooltip">
                  <StarIcon
                    className={classNames(
                      parseInt(productDetails.isfeatured) === 0
                        ? "text-black"
                        : "text-warning",
                      "h-5 w-5 mb-1.5 cursor-pointer relative tooltip "
                    )}
                    aria-hidden="true"
                    onClick={(e) =>
                      this._handelFeaturedProductToggle(productDetails)
                    }
                  />
                  <span className="tooltiptextleft1" style={tooltipStyle}>
                    {parseInt(productDetails.isfeatured) === 0
                      ? LocaleStrings.isfetured
                      : LocaleStrings.isunfetured}
                  </span>
                </div>
              </div> */}
              {/* If product has no variant hide the variation section */}
              {parseInt(productDetails?.hasvariant) ? (
                <>
                  {/* Render Variants */}
                  <div className="border-t border-primary">
                    {productVariants.length > 0
                      ? _.map(productVariants, (prodV, index) => {
                          return (
                            <div key={index}>
                              <div className="mt-2 text-sm  text-secondary">
                                {ProductTextLocalized(
                                  prodV.name_en,
                                  prodV.name_ar,
                                  language
                                )}
                              </div>
                              <div
                                className={classNames(
                                  index + 1 < productVariants.length
                                    ? "border-b border-primary"
                                    : "",
                                  "mt-2.5"
                                )}>
                                <div className="pb-2.5 grid grid-cols-3 xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-4">
                                  {this.renderProductVariant(
                                    prodV.variantnames,
                                    index,
                                    productDetails
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  {/* Selected Variant */}
                  <div>
                    <p className="mt-1 text-xs text-ternary">
                      {LocaleStrings.selected_variant}:
                      <span className="text-secondary">
                        {" "}
                        {selectedVariantDisplay}{" "}
                      </span>
                    </p>
                  </div>
                  {/* Render Images */}
                  {!_.isEmpty(selectedVariant) ? (
                    <div className="relative z-0 inline-flex shadow-sm rounded-md  mt-4">
                      {this.renderFirstImgs()}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : null}

              {/* PRODUCT DESCRIPTION [OUTTER]*/}
              <div className="block mt-4">
                <p className="text-xs font-semibold text-secondary">
                  {LocaleStrings.product_description}:
                </p>
                {/* Product subtitle - producr description */}
                <pre className="mt-1 text-xs text-ternary">{productDesc}</pre>
              </div>
              {/* 
                PRODUCT SPECIFICATION / SPECIFICATION [OUTTER]
                1. GLOBAL SPECIFICATION
                2. ITEM SPECIFICATION
                3. STANDERD DESCRIPTION
              */}

              {productDetails.globalspecifications.length > 0 ||
              productDetails.specifications.length > 0 ? (
                <div className="block mt-4">
                  <p className="text-xs font-semibold text-secondary">
                    {LocaleStrings.specification}:
                  </p>
                  {this.renderSPecifications(
                    "global",
                    productDetails.globalspecifications
                  )}
                  {this.renderSPecifications(
                    "item",
                    productDetails.specifications
                  )}
                  <pre className="mt-1 text-xs text-ternary">
                    {_.size(specificationDesc) > 0 ? (
                      <ReactReadMoreReadLess
                        charLimit={200}
                        readMoreText={" More ▼"}
                        readLessText={" Less ▲"}
                        readMoreClassName="read-more-less--more"
                        readLessClassName="read-more-less--less">
                        {renderHTML(specificationDesc)}
                      </ReactReadMoreReadLess>
                    ) : (
                      ""
                    )}
                  </pre>
                </div>
              ) : (
                ""
              )}

              <ul
                role="list"
                className=" divide-y divide-quaternary border-t-2 border-b-2 border-quaternary mt-4"
                onClick={(e) =>
                  this.setState({ viewAllDetailsState: !viewAllDetailsState })
                }>
                <li className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-primary truncate">
                        {LocaleStrings.view_all_details}
                      </p>
                    </div>
                    <div>
                      <img
                        loading="lazy"
                        className={classNames(
                          viewAllDetailsState ? "rotate-90" : "",
                          "h-8 transform  px-2 duration-300 ease-in-out"
                        )}
                        src="/images2/profile_settings_images/arrow-right.svg"
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <div
                className={classNames(
                  viewAllDetailsState ? "" : "hidden",
                  "mt-4 duration-300 ease-in-out"
                )}>
                {/* 
                PRODUCT SPECIFICATION / SPECIFICATION [INNER]
                1. GLOBAL SPECIFICATION
                2. ITEM SPECIFICATION
                3. CATEGORY - SUB CATEGORY
                4. STANDERD DESCRIPTION
              */}
                <div className="block mt-4">
                  <p className="text-xs font-semibold text-secondary">
                    {LocaleStrings.product_description}:
                  </p>
                  {/* Product subtitle - producr description */}
                  <p className="mt-1 text-xs text-ternary">{productName}</p>

                  <pre className="mt-1 text-xs text-ternary">{productDesc}</pre>
                  <p className="mt-1 text-xs text-ternary">
                    SKU - {productDetails.sku}
                  </p>
                </div>

                {/* CATEGORY - SUB CATEGORY */}
                <div className="block mt-4 mx-4">
                  <p className="mt-1 text-xs text-ternary">
                    {LocaleStrings.category}: {_.join(categoryNames, ",")}
                  </p>
                  <p className="mt-1 text-xs text-ternary">
                    {LocaleStrings.subcategory}: {_.join(subCategoryNames, ",")}
                  </p>
                  <p className="mt-1 text-xs text-ternary">
                    {LocaleStrings.condition}: {productConditionName}{" "}
                  </p>
                  <p className="mt-1 text-xs text-ternary">
                    {LocaleStrings.selected_variant}: {selectedVariantDisplay}{" "}
                  </p>
                </div>

                {/* PRODUCT SPECIFICATION / SPECIFICATION [INNER] */}
                {productDetails.globalspecifications.length > 0 ||
                productDetails.specifications.length > 0 ? (
                  <div className="block mt-4">
                    <p className="text-xs font-semibold text-secondary">
                      {LocaleStrings.specification}:
                    </p>
                    {this.renderSPecifications(
                      "global",
                      productDetails.globalspecifications
                    )}
                    {this.renderSPecifications(
                      "item",
                      productDetails.specifications
                    )}
                    {/* renderHTML */}
                    <div className="mt-1 text-xs text-ternary">
                      {specificationDesc !== undefined
                        ? renderHTML(specificationDesc)
                        : null}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* Return Policy */}
              {productDetails && productDetails.return_time_details ? (
                <div className="px-30 pt-2.5 pb-4">
                  <div
                    className={classNames(
                      productDetails.refund_type === null ? "sr-only" : "",
                      "text-primary font-normal text-base pb-1"
                    )}>
                    {productDetails && productDetails.product_refundtype
                      ? ProductTextLocalized(
                          productDetails.product_refundtype.name_en,
                          productDetails.product_refundtype.name_ar,
                          language
                        )
                      : ""}
                  </div>
                  <div className="mt-1 text-xs text-ternary">
                    {ProductTextLocalized(
                      productDetails.return_time_details.name_en,
                      productDetails.return_time_details.name_ar,
                      language
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {reportAbuseQaModal ? (
                <ReportAbuseQa
                  type="comment"
                  productid={productDetails?.productid}
                />
              ) : (
                ""
              )}
              {/* 
                REVIEW & RETINGS 
                Note :- Keep the below span elemnt for scrolling
              */}
              <ReviewAndRatingLimited
                productDetails={productDetails}
                wholeObject={productDetails}
                limit={1}
                callFrom={"product"}
              />
              <span ref={this.myRef} className="sr-only"></span>
              {/* Questions & Answers */}
              <QaWithLimit productID={productDetails.id} />
              {/* View Comments [ We will Not Exceute This One If Comments From Notification is Open ] */}
              {_.isEmpty(this.props.notificationDetailsStore) &&
              productDetails.id ? (
                <ViewComments productID={productDetails.id} />
              ) : (
                ""
              )}
              {/* View Likes */}
              {productDetails.id ? (
                <>
                  <ViewLikes productID={productDetails.id} />
                  {/* Public Bids */}
                  <PublicBid productID={productDetails.id} />
                </>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, vendorDetails, currency, commentList } = state;

  return {
    session,
    user,
    language: state.language,
    isLoggedIn: state.isLoggedIn,
    defaultSettings: !_.isEmpty(state.DefaultSettings)
      ? !_.isEmpty(state.DefaultSettings.data)
        ? state.DefaultSettings.data
        : []
      : [],
    isProductFeeOpen: state.isProductFeeOpen,
    productCategorylist: !_.isEmpty(state.productCategorylist)
      ? !_.isEmpty(state.productCategorylist.data)
        ? state.productCategorylist.data
        : []
      : [],
    productDetails: state.productDetails ? state.productDetails : [],
    vendorDetails: !_.isEmpty(state.vendorDetails)
      ? !_.isEmpty(state.vendorDetails.data)
        ? state.vendorDetails.data
        : []
      : [],
    notificationDetailsStore: state.notificationDetailsStore
      ? state.notificationDetailsStore.data
        ? state.notificationDetailsStore.data
        : []
      : [],
    localCurrency: currency,
    commentList,
    publicBids: state.publicBids,
    reportAbuseQaModal: state.reportAbuseQaModal, // Abuse report
    settingsMasterStore: state.settingsMasterStore
      ? state.settingsMasterStore.data !== "undefined"
        ? state.settingsMasterStore.data
        : []
      : [],
  };
}
// old code
// export default connect(mapStateToProps, {
//   fetchProductManagementDefaultSettings,
//   productFeeModal,
//   setProductFeeDefault,
//   setProductConfirmBtnClickedOrNot,
//   isCommentsModalOpen,
//   isLikesModalOpen,
//   deleteproduct,
//   productbyidtoedit,
//   productbyid,
//   toggleunlistproduct,
//   isbidPlacedSliderOpen,
//   fetchPublicBids,
//   cancelBid,
//   showUploadSection,
//   fetchGlobalSpecifications,
//   fetchProductCategorylist,
//   directPeoductUpdateWithObject, // Update any object with id frp product table
//   fetchSettingsMaster,
// })(ProductDetails);

// latest code
export default connect(mapStateToProps, {
  fetchProductManagementDefaultSettings,
  productFeeModal,
  setProductFeeDefault,
  setProductConfirmBtnClickedOrNot,
  isCommentsModalOpen,
  isLikesModalOpen,
  deleteproduct,
  productbyidtoedit,
  productbyid,
  toggleunlistproduct,
  isbidPlacedSliderOpen,
  fetchPublicBids,
  cancelBid,
  showUploadSection,
  fetchGlobalSpecifications,
  fetchProductCategorylist,
  directPeoductUpdateWithObject, // Update any object with id frp product table
  fetchSettingsMaster, // Translate API call
  relistwhileUpdatingProduct,
  deleteDiscountWithID,
})(
  reduxForm({
    form: "ProductdetailForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ProductDetails)
);
