import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import moment from "moment";
import _ from "lodash";

import { updateMRP, classNames, abbreviateNumber } from "@commonFunction";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
import { APP_API_KEY, BASE_IMAGES_URL, timeDiffCalc } from "@constant";

// Import Actions
import { fetchProductDetails } from "@productmanagementActions";

class ProductsTableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeText: "",
      timeInterval: 0,
    };
  }

  changeRouteTo = (e, productObj) => {
    /* Redirect to product details page */
    this.props.history.push(
      `/dashboard/productmanagement/product-details/${productObj.id}-${productObj.variantid}`
    );
  };

  componentDidMount() {
    this.interVal = setInterval(() => {
      this.setState({ timeInterval: this.state.timeInterval + 1 });
    }, 2000);
  }

  componentWillUnmount() {
    this.state = {
      timeText: "",
      timeInterval: 0,
    };
    this.clearInterval();
  }

  clearInterval = () => {
    clearInterval(this.interVal);
  };

  render() {
    let { localCurrency, productObj, vendorDetails } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    /* Product currency */
    let productCurrency = productObj.currency;

    /* 
    If user currency and product currency dont match 
    return currencyrate other waise no need to convert currency value so return 1
    */

    // Convert the itemPrice discounted amount

    let item = productObj;

    var cardTitle = productObj.productname_en + "-" + productObj.variantname_en;
    let cardDesc = productObj.product_desc_en;
    let saletype =
      productObj.saletype == "directsale"
        ? LocaleStrings.direct_sale
        : LocaleStrings.auction;
    let data = this.props.productObj;

    var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${this.props.productObj.productimage}?api_key=${APP_API_KEY}`;

    var curObjVariant = _.head(
      _.filter(this.props.productObj?.all_variants, {
        id: this.props.productObj.variantid,
      })
    );

    let bidcancelled = productObj?.bidcancelled ? productObj?.bidcancelled : 0;

    let price_unit = curObjVariant?.price_unit;

    let selectedVariant = _.filter(data?.all_variants, function (o) {
      return o.id == data.variantid;
    });
    let variantId = data.variantid;
    let itemPrice =
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant[0].price_unit
        ? selectedVariant[0].price_unit
        : 0;
    let priceObj = updateMRP(data, variantId);
    let discountedPrice = priceObj.discountedPrice;
    // Convert the discounted amount
    // discountedPrice = isCurrencyRateApplicable(
    //   localCurrency,
    //   productCurrency,
    //   discountedPrice
    // );
    // Convert the itemPrice discounted amount
    // itemPrice = isCurrencyRateApplicable(
    //   localCurrency,
    //   productCurrency,
    //   itemPrice
    // );

    let strProductDiscount = priceObj.strProductDiscount;

    /*** SHOW AUCTION DATE ***/
    var auctionText = "";
    var endDate = "";
    if (item.saletype == "auction") {
      let currentDate = moment();
      if (
        item.auction_start_date &&
        item.auction_end_date &&
        currentDate.isAfter(moment(item.auction_start_date)) &&
        currentDate.isBefore(moment(item.auction_end_date))
      ) {
        auctionText = LocaleStrings.ends_in;
        endDate = item.auction_end_date;
      } else if (
        item.auction_start_date &&
        item.auction_end_date &&
        currentDate.isBefore(moment(item.auction_start_date)) &&
        currentDate.isBefore(moment(item.auction_end_date))
      ) {
        auctionText = LocaleStrings.starts_in;
        endDate = item.auction_start_date;
      } else if (
        item.auction_start_date &&
        item.auction_end_date &&
        currentDate.isAfter(moment(item.auction_start_date)) &&
        currentDate.isAfter(moment(item.auction_end_date))
      ) {
        auctionText = LocaleStrings.expired;
        endDate = "";
      }

      if (bidcancelled === "1" || bidcancelled === 1) {
        auctionText = LocaleStrings.cancelled;
      }
    }

    // Banned Items- If a product, person is banned and we have a reference link, then show temporarily not available
    const productUnvailableTest =
      productObj?.isactive === false || vendorDetails?.isactive === "0"
        ? LocaleStrings.temporarily_unavailable
        : null;

    return (
      <tr
        id={`table-list-${this.props.position}`}
        key={`table-list-${this.props.position}`}
        className="cursor-pointer table-hover-transition"
        onClick={(e) => this.changeRouteTo(e, this.props.productObj)}
      >
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img
                loading="lazy"
                className="h-10 w-10 rounded-full"
                src={productImageUrl}
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images2/default.jpeg";
                }}
              />
            </div>
            <div className="ml-4">
              {/* Product or user unavailbale then of warning text */}
              {_.size(productUnvailableTest) > 0 ? (
                <p className="text-xs font-semibold text-danger">
                  {productUnvailableTest}
                </p>
              ) : null}
              <div className="text-sm font-medium text-secondary">
                {cardTitle}
              </div>
              <div className="text-sm text-ternary">
                {cardDesc.length > 100
                  ? cardDesc.substring(0, 100) + "..."
                  : cardDesc}
              </div>
              <div className="text-sm text-ternary">{saletype}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div>
            <p className="text-xs font-semibold text-secondary">
              {currency} {parseFloat(discountedPrice).toFixed(2)}
            </p>
          </div>
          <div>
            {this.props.productObj.discountactive ? (
              <p className="text-xs line-through">
                {currency} {parseFloat(itemPrice).toFixed(2)}
              </p>
            ) : (
              ""
            )}
          </div>
          <div>
            <p className="text-xs text-danger">
              {this.props.productObj.discountactive ? strProductDiscount : ""}
            </p>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {parseInt(this.props.productObj.isapproved) === 0 ? (
            <span className="card-badge">
              {LocaleStrings.waiting_for_approval}
            </span>
          ) : this.props.productObj.saletype == "auction" ? (
            <>
              {/* auction */}
              <span className="p-2">
                <div className="text-primary block text-sm px-4 text-left">
                  {auctionText}
                </div>
                <div
                  className={classNames(
                    endDate === "" ? "card-badge-danger" : "card-badge-3",
                    ""
                  )}
                >
                  {endDate === ""
                    ? LocaleStrings.bid_over
                    : timeDiffCalc(new Date(endDate), new Date())}
                </div>
              </span>
              <span className="card-badge">
                {this.props.productObj.numbids} {LocaleStrings.bids}
              </span>
              {/* <span className="card-badge">
                <img
                loading="lazy"
                  className="badge-icon"
                  src={"/images2/product_management_images/eye.svg"}
                />
                {this.props.productObj.numwatching}
              </span> */}
            </>
          ) : (
            <>
              {/* Directsale */}
              <span className="card-badge">
                <img
                  loading="lazy"
                  className="badge-icon"
                  src={"/images2/product_management_images/rating-review.svg"}
                />
                {this.props.productObj.ratings.avgrating}
              </span>
              <span className="card-badge">
                <img
                  loading="lazy"
                  className="badge-icon"
                  src={
                    "/images2/product_management_images/Filled-Heart-circle.svg"
                  }
                />
                {abbreviateNumber(this.props.productObj.numlikes)}
              </span>
              <span className="card-badge">
                {abbreviateNumber(this.props.productObj.numsold)}{" "}
                {LocaleStrings.sold}
              </span>
            </>
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
          {/* <button
                            type="button"
                            className="tbl-btn-primary hidden"
                        >
                            {LocaleStrings.edit_product}
                        </button>
                      {' '}
                        <button
                            type="button"
                            className="tbl-btn-danger hidden"
                        >
                            {LocaleStrings.delete_product}
                        </button> */}
        </td>
      </tr>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  localCurrency: state.currency,
  vendorDetails: state.vendorDetails
    ? state.vendorDetails.data
      ? state.vendorDetails.data
      : []
    : [],
});

export default connect(mapStateToProps, {
  fetchProductDetails,
})(ProductsTableList);
