import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import moment from "moment-timezone";

// Import Functions
import { converDateIntoLocal, classNames } from "@commonFunction";

// Import Components
import BaseLoader from "@baseLoader";
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  DATE_FORMAT_TO_SHOW_SHORT,
} from "@constant";

// Import ACtions
import {
  fetchMyFollowers,
  isFollowersDetailsOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  setFollowunfollow,
  selectedFollowersShearedWishlist,
  emptySharedWishlist,
  viewPublicProfile,
  profileViewNavigation,
} from "@profileSettingsActions";

class CurrentFollowerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_my_followers,
      btnLoader: false,
      followingId: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    /**** Fetch My Followers ****/
    this.props.fetchMyFollowers(this.props.session, (callback) => {
      this.setState({ loading: false });
    });
  }

  /**** Follow Unfollow ****/
  _handleFollowUnfollow(valObj) {
    this.setState({
      btnLoader: true,
      followingId: valObj.userid,
    });

    this.props.setFollowunfollow(
      this.props.session,
      valObj.userid,
      (callback) => {
        if (callback.status) {
          this.props.selectedFollowerUserDetails(
            this.props.session,
            valObj.users_by_userid.userid,
            (callback) => {
              this.setState({
                btnLoader: false,
                followingId: "",
              });
            }
          );

          this.props.selectedFollowersDetails(
            this.props.session,
            valObj.users_by_userid.userid,
            (callback) => {}
          );
        }
      }
    );
  }

  /**** Followers Details Modal Open Fc ****/
  openFollowersDelaisModal(valObj) {
    let { navigateUser, session } = this.props;
    if (session.userid == valObj.userid) {
      /* Dont allow to click if own profile */
      return false;
    }

    let userArr = navigateUser;
    this.props.isFollowersDetailsOpen(true);
    this.props.emptySharedWishlist();

    this.props.selectedFollowerUserDetails(
      this.props.session,
      valObj.userid,
      (callback) => {}
    );

    this.props.selectedFollowersDetails(
      this.props.session,
      valObj.userid,
      (callback) => {}
    );

    this.props.selectedFollowersShearedWishlist(
      this.props.session,
      valObj.userid,
      (callback) => {}
    );

    if (valObj.userid != session.userid) {
      this.props.viewPublicProfile(session, valObj.userid);
    }

    if (!_.includes(userArr, valObj.userid)) {
      console.log("1");
      userArr.push(valObj.userid);
      this.props.profileViewNavigation(userArr);
    }
  }

  /**** Render Profile settings My Followers List *****/
  renderList() {
    let { session } = this.props;

    var item = _.map(this.props.currentMyFollowersData, (valObj, index) => {
      var usersByFollowerid = valObj.users_by_followerid;
      let followUnfollowBtnText = valObj.following
        ? LocaleStrings.btn_unfollow
        : LocaleStrings.btn_follow;

      var productImageUrl = `${BASE_IMAGES_URL}/users/${valObj.userid}.jpg?api_key=${APP_API_KEY}`;
      let userName =
        this.props.session.userid == valObj.userid
          ? LocaleStrings.text_you
          : valObj.username;

      return (
        <li
          key={index}
          className="py-4 bg-custWhite px-4 table-hover-transition">
          <div
            className={classNames(
              session.userid == valObj.userid ? "" : "cursor-pointer",
              "flex items-center space-x-4 mx-2"
            )}>
            <div
              className="flex-shrink-0"
              onClick={(e) => this.openFollowersDelaisModal(valObj)}>
              <img
                loading="lazy"
                className="user-img-rounded"
                src={productImageUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images2/common/user.png";
                }}
                alt="Image"
              />
            </div>
            <div
              className={classNames(
                session.userid == valObj.userid ? "" : "cursor-pointer",
                "flex-1 min-w-0"
              )}
              onClick={(e) => this.openFollowersDelaisModal(valObj)}>
              <p className="text-sm font-medium text-secondary truncate mx-2">
                {userName}
              </p>
              {/* 
               *** Dont remove the code [We may need myfollowers dates later *** 
              <p className="text-sm text-ternary truncate mx-2">
                {converDateIntoLocal(valObj.createdon).format(
                  DATE_FORMAT_TO_SHOW_SHORT
                )}
              </p> */}
            </div>
            {/* <div className="w-24">
              {this.props.session.userid == valObj.userid ? (
                ""
              ) : (
                <button
                  type="button"
                  className="btn-primary cursor-pointer"
                  onClick={(e) => this._handleFollowUnfollow(valObj)}
                >
                  {this.state.btnLoader &&
                  this.state.followingId == valObj.userid ? (
                    <svg
                      className="animate-spin-medium  h-5 w-5 rounded-full mr-3"
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  ) : (
                    followUnfollowBtnText
                  )}
                </button>
              )}
            </div> */}
          </div>
        </li>
      );
    });

    return item;
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <BaseLoader data={this.state.loaderDesc} />
        ) : (
          <>
            <div className=" xs:px-2">
              <div className="flow-root mt-8">
                <ul role="list" className="-my-5 divide-y divide-gray-200">
                  {this.renderList()}
                </ul>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    currentMyFollowersData: state.storeCurrentMyFollowersData
      ? state.storeCurrentMyFollowersData.data
        ? state.storeCurrentMyFollowersData.data
        : []
      : [],
    profileFollower: state.profileFollower,
    navigateUser: state.navigateUser,
  };
}

export default connect(mapStateToProps, {
  fetchMyFollowers,
  isFollowersDetailsOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  setFollowunfollow,
  emptySharedWishlist,
  viewPublicProfile,
  profileViewNavigation,
})(CurrentFollowerList);
