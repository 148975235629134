import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import renderHTML from "react-render-html";
import ReactReadMoreReadLess from "react-read-more-read-less";
import moment from "moment";
// Import Actions
import {
  productFeeModal,
  setProductFeeDefault,
  setProductConfirmBtnClickedOrNot,
  fetchProductManagementDefaultSettings,
} from "@productmanagementActions";
import { BASE_IMAGES_URL, APP_API_KEY, timeDiffCalc } from "@constant";

// Constants
import { currencyCode, currencyRate } from "@currencyActions";

// Functions Import
import { classNames, ProductTextLocalized } from "@commonFunction";

// Import Components
import ProductImgCarousel from "./productimgcarousel";
import ProductListingFees from "./productdetails/components/productfees/product-listing-fees"; // Componet will show listing

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

var selectionArr = []; // Array for refkeys for Variant [XL] [L]
var orginalPrice = "";
var orginalPriceAfterDiscount = "";
var discountPercentage = "";
class ProductFee extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      loading: true,
      btnLoader: false,
      viewAllDetailsState: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.closeModal1 = this.closeModal1.bind(this);
  }

  componentDidMount() {}

  // On Confirm button click change All states and redux
  onConfirm(productObjectForFeeDetails) {
    this.setState({ btnLoader: true });
    this.props.setProductConfirmBtnClickedOrNot(true); // Now Confirm

    this.props.setProductFeeDefault(true);
    this.setState({ btnLoader: false });
    selectionArr = []; // Reintialize

    this.props.productFeeModal(false); // Close Modal

    setTimeout(function () {
      document.getElementById("uploadproductformbtn").click();
    }, 1500);
  }

  /**** Fake Close ****/
  closeModal1(param) {
    //this.myRef.focus();
  }

  /**** Close Modal  ****/
  closeModal() {
    this.props.productFeeModal(false);
  }

  /**** Variant Names XL/L Button Click ****/
  _handelVariantBtnClick(refkey, index, variantnamesObj) {
    if (selectionArr[index] !== refkey) {
      // array modified
      selectionArr[index] = refkey;
    }
    this.props.setProductFeeDefault(false);
  }

  /**** Arrow Function for BASE VARIANT LOOP THROUGH ****/
  renderBaseVariants = () => {
    let { language, productObjectForFeeDetails, isProductFeeDefault } =
      this.props;

    if (isProductFeeDefault) {
      // For default make it blck so do not take each render value
      selectionArr = [];
    }

    var item = _.map(
      productObjectForFeeDetails.basevariants,
      (basevariantsObj, index) => {
        if (isProductFeeDefault) {
          // For default MAke array with all first veriant

          selectionArr.push(basevariantsObj.variantnames[0].refkey);
        }

        let variant = ProductTextLocalized(
          basevariantsObj.name_en,
          basevariantsObj.name_ar,
          language
        );

        return (
          <div className="">
            <div className=" inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-ternary" />
            </div>
            <div className="py-2">
              <p className="text-sm  text-secondary">{variant}</p>
            </div>
            <div className="py-2">
              {
                /**** Product variants name ****/
                _.map(
                  basevariantsObj.variantnames,
                  (variantnamesObj, indexInner) => {
                    let variantName = ProductTextLocalized(
                      variantnamesObj.name_en,
                      variantnamesObj.name_ar,
                      language
                    );

                    return isProductFeeDefault === true && indexInner === 0 ? (
                      <button
                        type="button"
                        className={classNames(
                          isProductFeeDefault
                            ? "border-primary text-primary"
                            : "border-ternary text-secondary",
                          "mx-2 inline-flex items-center px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
                        )}
                        onClick={(e) =>
                          this._handelVariantBtnClick(
                            variantnamesObj.refkey,
                            index,
                            variantnamesObj
                          )
                        }>
                        {variantName}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={classNames(
                          selectionArr[index] === variantnamesObj.refkey
                            ? "border-primary text-primary"
                            : "border-ternary text-secondary",
                          "mx-2 inline-flex items-center px-2.5 py-1.5 border  shadow-sm text-xs font-medium rounded  bg-white hover:bg-quaternary"
                        )}
                        onClick={(e) =>
                          this._handelVariantBtnClick(
                            variantnamesObj.refkey,
                            index,
                            variantnamesObj
                          )
                        }>
                        {variantName}
                      </button>
                    );
                  }
                )
              }
            </div>
          </div>
        );
      }
    );

    return item;
  };

  /**** Arrow Function for images ****/
  renderFirstImgs = () => {
    var reader = new FileReader();
    let { productObjectForFeeDetails } = this.props;
    console.log("productObjectForFeeDetails", productObjectForFeeDetails);
    var item = _.map(
      productObjectForFeeDetails.detailedvariants,
      (detaileDvariantsObj, index) => {
        // Old code
        var imageUrl = "";
        if (
          detaileDvariantsObj.variations_media_raw[0].raw_thumbnail.url == "" ||
          detaileDvariantsObj.variations_media_raw[0].raw_thumbnail.url ==
            undefined
        ) {
          imageUrl = `${BASE_IMAGES_URL}/vendor/variations/${detaileDvariantsObj.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`;
        } else {
          let arrayBuffer =
            detaileDvariantsObj.variations_media_raw[0].raw_thumbnail.url;
          var blob = new Blob([arrayBuffer], { type: "image/jpeg" });
          var urlCreator = window.URL || window.webkitURL;
          imageUrl = urlCreator.createObjectURL(blob);
        }
        console.log("url", imageUrl);
        // New codes let imageUrl = detaileDvariantsObj.variations_media_raw[0].file_path;

        return (
          <div className="border-2 border-quaternary mx-1">
            <img
              loading="lazy"
              src={imageUrl}
              className="h-10 w-10 "
              alt="image"
            />
          </div>
        );
      }
    );

    return item;
  };

  renderSPecifications = (arrayOfObjects) => {
    let { language } = this.props;

    var item = _.map(arrayOfObjects, (object, index) => {
      return (
        <p className="text-xs font-semibold text-ternary my-2">
          {ProductTextLocalized(object.name_en, object.name_ar, language)} :{" "}
          {ProductTextLocalized(object.value_en, object.value_ar, language)}
        </p>
      );
    });

    return item;
  };

  render() {
    let {
      language,
      localCurrency,
      modalHeadText,
      modalFooterText,
      productObjectForFeeDetails,
      session,
      user,
      productCategorylist,
      defaultSettings,
      prodDiscAddedData,
      vendorDetails,
      settingsMasterStore,
    } = this.props;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);
    let { viewAllDetailsState } = this.state;
    let categoryNames = [];
    let subCategoryNames = [];
    let productCondition = [];
    let userName = user.username;
    let productName = "";
    let productDesc = "";
    let specificationDesc = "";
    let productConditionName = "";
    let selectedVariantDisplay = "";
    let retunTime = ""; // Get data from default settings and make bylangual
    let refundTypeFieldLavel = ""; // Get data from default settings and make bylangual
    /*** SHOW AUCTION DATE ***/
    var auctionText = "";
    var endDate = "";
    if (productObjectForFeeDetails.saletype == "auction") {
      let currentDate = moment();
      if (
        productObjectForFeeDetails.auction_start_date &&
        productObjectForFeeDetails.auction_end_date &&
        currentDate.isAfter(
          moment(productObjectForFeeDetails.auction_start_date)
        ) &&
        currentDate.isBefore(
          moment(productObjectForFeeDetails.auction_end_date)
        )
      ) {
        auctionText = LocaleStrings.ends_in;
        endDate = productObjectForFeeDetails.auction_end_date;
      } else if (
        productObjectForFeeDetails.auction_start_date &&
        productObjectForFeeDetails.auction_end_date &&
        currentDate.isBefore(
          moment(productObjectForFeeDetails.auction_start_date)
        ) &&
        currentDate.isBefore(
          moment(productObjectForFeeDetails.auction_end_date)
        )
      ) {
        auctionText = LocaleStrings.starts_in;
        endDate = productObjectForFeeDetails.auction_start_date;
      } else if (
        productObjectForFeeDetails.auction_start_date &&
        productObjectForFeeDetails.auction_end_date &&
        currentDate.isAfter(
          moment(productObjectForFeeDetails.auction_start_date)
        ) &&
        currentDate.isAfter(moment(productObjectForFeeDetails.auction_end_date))
      ) {
        auctionText = LocaleStrings.expired;
        endDate = "";
      }
    }

    productCondition = _.filter(defaultSettings.product_condition, {
      id: parseInt(productObjectForFeeDetails.product_condition),
    });

    let filterdCategory = _.filter(productCategorylist, (cateObj, index) => {
      return (
        _.indexOf(
          _.split(productObjectForFeeDetails.categoryids, ",").map(Number),
          cateObj.id
        ) > -1
      );
    });

    _.forEach(filterdCategory, (fltdCateObject, index) => {
      // Get category names
      categoryNames.push(
        ProductTextLocalized(
          fltdCateObject.categoryname_en,
          fltdCateObject.categoryname_ar,
          language
        )
      );
    });

    // Fetch Subcategory Names
    _.forEach(filterdCategory, (seletedCateObject, index) => {
      _.forEach(
        seletedCateObject.productsubcategory_by_categoryid,
        (cateSubcateObject) => {
          if (
            _.indexOf(
              _.split(productObjectForFeeDetails.subcategoryids, ",").map(
                Number
              ),
              cateSubcateObject.id
            ) > -1
          ) {
            subCategoryNames.push(
              ProductTextLocalized(
                cateSubcateObject.subcategoryname_en,
                cateSubcateObject.subcategoryname_ar,
                language
              )
            );
          }
        }
      );
    });

    productName = ProductTextLocalized(
      productObjectForFeeDetails.productname_en,
      productObjectForFeeDetails.productname_ar,
      language
    );
    productDesc = ProductTextLocalized(
      productObjectForFeeDetails.product_desc_en,
      productObjectForFeeDetails.product_desc_ar,
      language
    );
    specificationDesc = ProductTextLocalized(
      productObjectForFeeDetails.specification_desc_en,
      productObjectForFeeDetails.specification_desc_ar,
      language
    );

    // Create return time text
    if (productObjectForFeeDetails && productObjectForFeeDetails.return_time) {
      let return_time_object = _.filter(defaultSettings.return_time, [
        "idc",
        productObjectForFeeDetails.return_time,
      ]);

      retunTime = ProductTextLocalized(
        return_time_object[0].name_en,
        return_time_object[0].name_ar,
        language
      );
    }

    // Refund type
    if (
      productObjectForFeeDetails &&
      productObjectForFeeDetails.return_time &&
      productObjectForFeeDetails.refund_type
    ) {
      let refund_type_object = _.filter(defaultSettings.refund_type, [
        "id",
        parseInt(productObjectForFeeDetails.refund_type),
      ]);

      refundTypeFieldLavel = ProductTextLocalized(
        refund_type_object[0].name_en,
        refund_type_object[0].name_ar,
        language
      );
    }

    if (!_.isEmpty(productCondition)) {
      productConditionName = ProductTextLocalized(
        productCondition[0].condition_en,
        productCondition[0].condition_ar,
        language
      );
    }

    // Note :- isProductFeeDefault [true/false] to show default status
    // console.log('productObjectForFeeDetails',productObjectForFeeDetails)
    if (!_.isEmpty(productObjectForFeeDetails)) {
      if (_.isEmpty(selectionArr)) {
        // Refkey array somewho empty
        selectedVariantDisplay = ProductTextLocalized(
          productObjectForFeeDetails.detailedvariants[0].name_en,
          productObjectForFeeDetails.detailedvariants[0].name_ar,
          language
        );

        if (_.isEmpty(prodDiscAddedData)) {
          orginalPrice =
            productObjectForFeeDetails.detailedvariants[0].price_unit;
        } else {
          discountPercentage = parseFloat(
            prodDiscAddedData.discount_percentage
          );

          if (prodDiscAddedData.discounttype === "fixed") {
            /* If fixed */
            orginalPriceAfterDiscount =
              productObjectForFeeDetails.detailedvariants[0].price_unit;
            orginalPrice = orginalPriceAfterDiscount - discountPercentage;
          } else {
            /* If percentage */
            orginalPriceAfterDiscount = parseFloat(
              productObjectForFeeDetails.detailedvariants[0].price_unit
            );

            orginalPrice =
              productObjectForFeeDetails.detailedvariants[0].price_unit -
              (parseFloat(
                productObjectForFeeDetails.detailedvariants[0].price_unit
              ) *
                parseFloat(prodDiscAddedData.discount_percentage)) /
                100;
          }
        }
      } else {
        let detailedVariantSingle = _.filter(
          productObjectForFeeDetails.detailedvariants,
          { refkey: _.join(selectionArr, "-") }
        );
        if (_.isEmpty(detailedVariantSingle)) {
          selectedVariantDisplay = ProductTextLocalized(
            productObjectForFeeDetails.detailedvariants[0].name_en,
            productObjectForFeeDetails.detailedvariants[0].name_ar,
            language
          );

          if (_.isEmpty(prodDiscAddedData)) {
            orginalPrice =
              productObjectForFeeDetails.detailedvariants[0].price_unit;
          } else {
            orginalPriceAfterDiscount = parseFloat(
              productObjectForFeeDetails.detailedvariants[0].price_unit
            );
            orginalPrice =
              (parseFloat(
                productObjectForFeeDetails.detailedvariants[0].price_unit
              ) *
                parseFloat(prodDiscAddedData.discount_percentage)) /
              100;
            discountPercentage = parseFloat(
              prodDiscAddedData.discount_percentage
            );
          }
        } else {
          selectedVariantDisplay = ProductTextLocalized(
            detailedVariantSingle[0].name_en,
            detailedVariantSingle[0].name_ar,
            language
          );

          discountPercentage = parseFloat(
            prodDiscAddedData.discount_percentage
          );

          if (_.isEmpty(prodDiscAddedData)) {
            orginalPrice = detailedVariantSingle[0].price_unit;
          } else {
            if (prodDiscAddedData.discounttype === "fixed") {
              orginalPriceAfterDiscount = detailedVariantSingle[0].price_unit;
              orginalPrice = orginalPriceAfterDiscount - discountPercentage;
            } else {
              /* If percentage */
              orginalPriceAfterDiscount = parseFloat(
                detailedVariantSingle[0].price_unit
              );
              orginalPrice =
                parseFloat(detailedVariantSingle[0].price_unit) -
                (parseFloat(detailedVariantSingle[0].price_unit) *
                  parseFloat(prodDiscAddedData.discount_percentage)) /
                  100;
            }
          }
        }
      }
    }

    return (
      <>
        <Transition.Root show={this.props.isProductFeeOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isProductFeeOpen}
            onClose={this.closeModal1}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-3 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {session.usertype === "personal"
                          ? modalHeadText
                          : LocaleStrings.product_details}
                      </div>
                    </div>
                    <div className="absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}
                        ref={this.myRef}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  {/* Modal Body */}

                  {!_.isEmpty(productObjectForFeeDetails) ? (
                    <div className="m-6">
                      <ProductImgCarousel
                        selectedVariantDataArray={selectionArr}
                      />

                      {/* Pricing */}
                      <div className="grid grid-cols-3 gap-4 mt-4">
                        <div>
                          <p className="text-xs font-semibold text-secondary">
                            {currency}{" "}
                            {/* Old code{parseFloat(orginalPrice * currencyrate).toFixed(2)} */}
                            {parseFloat(orginalPrice).toFixed(2)}
                          </p>
                        </div>
                        <div
                          className={classNames(
                            orginalPriceAfterDiscount === "" ? "hidden" : "",
                            ""
                          )}>
                          <p className="text-xs line-through ...">
                            {currency}{" "}
                            {/* Old code 
                            {parseFloat(
                              orginalPriceAfterDiscount * currencyrate
                            ).toFixed(2)}{" "} */}
                            {parseFloat(orginalPriceAfterDiscount).toFixed(2)}{" "}
                          </p>
                        </div>
                        <div
                          className={classNames(
                            discountPercentage === "" ||
                              _.isNaN(discountPercentage)
                              ? "hidden"
                              : "",
                            ""
                          )}>
                          <p className="text-xs text-danger">
                            {" "}
                            {parseFloat(discountPercentage).toFixed(2)}{" "}
                            {prodDiscAddedData?.discounttype === "fixed"
                              ? LocaleStrings.off
                              : "%"}
                          </p>
                        </div>
                      </div>
                      {/* For Shipping Cost */}
                      <p
                        className={classNames(
                          productObjectForFeeDetails.shipping_type !== "local"
                            ? "my-2"
                            : "hidden",
                          "text-xs text-ternary"
                        )}>
                        + {currency}{" "}
                        {/* Old Code
                        {parseFloat(
                          productObjectForFeeDetails.shipping_cost *
                            currencyrate
                        ).toFixed(2)}{" "} */}
                        {parseFloat(
                          productObjectForFeeDetails.shipping_cost
                        ).toFixed(2)}{" "}
                        {LocaleStrings.shipping}
                      </p>

                      <p className="text-base font-semibold text-primary ">
                        {userName}
                      </p>

                      <p className="text-sm font-semibold text-secondary">
                        {productName}
                      </p>

                      {/* ACTION RELATED DATE */}
                      <div className="flex mt-4">
                        {productObjectForFeeDetails.saletype == "auction" ? (
                          <>
                            {/* auction */}
                            <div className="text-primary block text-sm -px-4 text-left">
                              {auctionText}
                            </div>
                            <div
                              className={classNames(
                                endDate === ""
                                  ? "card-badge-danger"
                                  : "card-badge-3",
                                ""
                              )}>
                              {endDate === ""
                                ? LocaleStrings.bid_over
                                : timeDiffCalc(new Date(endDate), new Date())}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* If product has no variant hide the variation section */}
                      {parseInt(productObjectForFeeDetails?.hasvariant) ? (
                        <div className="py-4">{this.renderBaseVariants()}</div>
                      ) : null}

                      {/* PRODUCT DESCRIPTION [OUTTER]*/}
                      <div className="block mt-4">
                        <p className="text-xs font-semibold text-secondary">
                          {LocaleStrings.product_description}:
                        </p>
                        {/* producr description */}
                        <pre className="mt-1 text-xs text-ternary">
                          {productDesc}
                        </pre>
                      </div>

                      {/* 
                        PRODUCT SPECIFICATION / SPECIFICATION [OUTTER]
                        1. GLOBAL SPECIFICATION
                        2. ITEM SPECIFICATION
                        3. STANDERD DESCRIPTION
                      */}

                      <div className="block mt-4">
                        <p className="text-xs font-semibold text-secondary">
                          {LocaleStrings.specification}:
                        </p>
                        {this.renderSPecifications(
                          productObjectForFeeDetails.product_specifications
                        )}
                        <pre className="mt-1 text-xs text-ternary">
                          {_.size(specificationDesc) > 0 ? (
                            <ReactReadMoreReadLess
                              charLimit={200}
                              readMoreText={" More ▼"}
                              readLessText={" Less ▲"}
                              readMoreClassName="read-more-less--more"
                              readLessClassName="read-more-less--less">
                              {specificationDesc !== undefined
                                ? renderHTML(specificationDesc)
                                : null}
                            </ReactReadMoreReadLess>
                          ) : (
                            ""
                          )}
                        </pre>
                      </div>
                      {/* First Image */}
                      <div className="relative z-0 inline-flex shadow-sm rounded-md  mt-4">
                        {this.renderFirstImgs()}
                      </div>

                      {/* View All Details - Button */}
                      <ul
                        role="list"
                        className=" divide-y divide-quaternary border-t-2 border-b-2 border-quaternary mt-4"
                        onClick={(e) =>
                          this.setState({
                            viewAllDetailsState: !viewAllDetailsState,
                          })
                        }>
                        <li
                          key={"view_all_details_id"}
                          className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition">
                          <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-primary truncate">
                                {LocaleStrings.view_all_details}
                              </p>
                            </div>
                            <div>
                              <img
                                loading="lazy"
                                className={classNames(
                                  viewAllDetailsState ? "rotate-90" : "",
                                  "h-8 transform  px-2 duration-300 ease-in-out"
                                )}
                                src="/images2/profile_settings_images/arrow-right.svg"
                              />
                            </div>
                          </div>
                        </li>
                      </ul>

                      {/* View All Details - Details */}
                      <div
                        className={classNames(
                          viewAllDetailsState ? "" : "hidden",
                          "mt-4 duration-300 ease-in-out"
                        )}>
                        {/* 
                          PRODUCT SPECIFICATION / SPECIFICATION [INNER]
                          1. GLOBAL SPECIFICATION
                          2. ITEM SPECIFICATION
                          3. CATEGORY - SUB CATEGORY
                          4. STANDERD DESCRIPTION
                        */}
                        <div className="block mt-4">
                          <p className="text-xs font-semibold text-secondary">
                            {LocaleStrings.product_description}:
                          </p>
                          {/* Product subtitle - producr description */}
                          <p className="mt-1 text-xs text-ternary">
                            {productName}
                          </p>
                          <pre className="mt-1 text-xs text-ternary">
                            {productDesc}
                          </pre>
                        </div>

                        {/* CATEGORY - SUB CATEGORY */}
                        <div className="block mt-4 mx-4">
                          <p className="mt-1 text-xs text-ternary">
                            {LocaleStrings.category}:{" "}
                            {_.join(categoryNames, ",")}
                          </p>
                          <p className="mt-1 text-xs text-ternary">
                            {LocaleStrings.subcategory}:{" "}
                            {_.join(subCategoryNames, ",")}
                          </p>
                          <p className="mt-1 text-xs text-ternary">
                            {LocaleStrings.condition}: {productConditionName}
                          </p>
                          <p className="mt-1 text-xs text-ternary">
                            {LocaleStrings.selected_variant}:
                            {selectedVariantDisplay}
                          </p>
                        </div>

                        {/* PRODUCT SPECIFICATION / SPECIFICATION [INNER] */}
                        <div className="block mt-4">
                          <p className="text-xs font-semibold text-secondary">
                            {LocaleStrings.specification}:
                          </p>
                          {this.renderSPecifications(
                            productObjectForFeeDetails.product_specifications
                          )}
                          <p className="mt-1 text-xs text-ternary">
                            {specificationDesc !== undefined
                              ? renderHTML(specificationDesc)
                              : null}
                          </p>
                        </div>
                      </div>

                      {/* Return Policy */}
                      {productObjectForFeeDetails &&
                      productObjectForFeeDetails.return_time ? (
                        <div className="px-30 pt-2.5 pb-4">
                          <div
                            className={classNames(
                              refundTypeFieldLavel === "" ? "sr-only" : "",
                              "text-primary font-normal text-base pb-1"
                            )}>
                            {refundTypeFieldLavel}
                          </div>
                          <div className="mt-1 text-xs text-ternary">
                            {retunTime}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* When User Type Personal */}
                      {session.usertype === "personal" &&
                      settingsMasterStore.listing_perproduct > 0 ? (
                        <>
                          <ProductListingFees
                            vendorDetails={vendorDetails}
                            productObjectForFeeDetails={
                              productObjectForFeeDetails
                            }
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="m-6">
                    {/* Modal Footer */}
                    <button
                      className="btn-primary"
                      type="button"
                      // onClick={this.props.onConfirm(this.props.)}
                      onClick={(e) =>
                        this.onConfirm(productObjectForFeeDetails)
                      }>
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "hidden",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>{" "}
                      {modalFooterText}
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localCurrency: state.currency,
    defaultSettings: !_.isEmpty(state.DefaultSettings)
      ? !_.isEmpty(state.DefaultSettings.data)
        ? state.DefaultSettings.data
        : []
      : [],
    isProductFeeOpen: state.isProductFeeOpen,
    productObjectForFeeDetails: state.productObjectForFeeDetails,
    productCategorylist: !_.isEmpty(state.productCategorylist)
      ? !_.isEmpty(state.productCategorylist.data)
        ? state.productCategorylist.data
        : []
      : [],
    isProductFeeDefault: state.isProductFeeDefault,
    prodDiscAddedData: state.prodDiscAddedData ? state.prodDiscAddedData : [],
    vendorDetails: !_.isEmpty(state.vendorDetails)
      ? !_.isEmpty(state.vendorDetails.data)
        ? state.vendorDetails.data
        : []
      : [],
    producvtSubmitConfirm: state.producvtSubmitConfirm,
    settingsMasterStore: state.settingsMasterStore
      ? state.settingsMasterStore.data !== "undefined"
        ? state.settingsMasterStore.data
        : []
      : [],
  };
}

export default connect(mapStateToProps, {
  fetchProductManagementDefaultSettings,
  productFeeModal,
  setProductFeeDefault,
  setProductConfirmBtnClickedOrNot,
})(ProductFee);
