import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { DATE_FORMAT } from "@constant";
import LocaleStrings from "@language";
import { fetchSettingsMaster } from "@legaldocsActions";

import {
  fetchLastPaymentStatusById,
  isRepaymentModalOpen,
} from "@productmanagementActions";

// Common Functions
import { converDateIntoLocal } from "@commonFunction";

import RepaymentModal from "./repayment-modal.js";
import moment from "moment";

const ProductPaymentStatus = (props) => {
  const { id } = useParams(); // Product ID [398-594]
  let idArr = _.split(id, "-");

  let productId = idArr[0];

  const [btnLoader, setBtnLoader] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  let { session, productDetails, productLastPaymentStatus, isRepaymentOpen } =
    props;

  useEffect(() => {
    props.fetchSettingsMaster(session, (callback) => {});
    props.fetchLastPaymentStatusById(session, productId, (callback) => {});

    return () => {};
  }, []);

  const openRepaymentModal = () => {
    props.isRepaymentModalOpen(true);
  };

  let statusClass = "";
  statusClass =
    productLastPaymentStatus?.[0]?.paymentstatus === "approve"
      ? "card-badge-success"
      : productLastPaymentStatus?.[0]?.paymentstatus === "pending" ||
        productLastPaymentStatus?.[0]?.paymentstatus ===
          "pending_confirmation_bank"
      ? "card-badge-warning"
      : "card-badge-danger";
  let reject_reason = "";

  if (
    productDetails.reject_reason !== "" &&
    productDetails.reject_reason !== null
  ) {
    reject_reason = `: ${productDetails.reject_reason}`;
  }
  //  console.log('productLastPaymentStatus',productLastPaymentStatus)
  //  console.log('productDetails',productDetails)
  return (
    <div>
      {/* Listing status
        Rules - 
        1. isapproved = 0 => 'waiting for approval'
        2. isapproved = 1 => Show "Approved (Expairy date - dd/mm/yyyy)"
        3. Expairy date < Current date then "Expired"
       */}
      <div className="mb-2">
        <span className="text-xs font-semibold text-secondary">
          {LocaleStrings.listing_status} :
        </span>
        <div className="card-badge">
          {productDetails.isapproved === "0" &&
          (productDetails.reject_reason !== "" ||
            productDetails.reject_reason == null) ? (
            <>
              {LocaleStrings.waiting_for_approval} {reject_reason}
            </>
          ) : moment(productDetails.listingexpriydate).isBefore() ? (
            LocaleStrings.expired
          ) : (
            _.replace(
              LocaleStrings.approved_with_expairy_date,
              "#",
              converDateIntoLocal(productDetails.listingexpriydate).format(
                DATE_FORMAT
              )
            )
          )}
          {productDetails.isdraft == 1 || productDetails.isdraft == "1" ? (
            <span className="ml-1">(Drafted Product)</span>
          ) : (
            ""
          )}
          {/* //: ((productDetails.listingexpriydate !== null && productDetails.listingexpriydate !== 'null') ?  : '')} */}
        </div>
      </div>
      <div className="mb-2">
        {/* Payment Status */}
        <span className="text-xs font-semibold text-secondary">
          {LocaleStrings.payment_status} :
        </span>
        <div className={statusClass}>
          {productLastPaymentStatus?.[0]?.paymentstatus === "approve"
            ? LocaleStrings.payment_approve
            : productLastPaymentStatus?.[0]?.paymentstatus === "pending"
            ? LocaleStrings.payment_pending
            : productLastPaymentStatus?.[0]?.paymentstatus === "failed"
            ? LocaleStrings.payment_failed
            : productLastPaymentStatus?.[0]?.paymentstatus === "declined"
            ? LocaleStrings.payment_declined
            : productLastPaymentStatus?.[0]?.paymentstatus ===
              "pending_confirmation_bank"
            ? LocaleStrings.payment_pending_confirmation_bank
            : productLastPaymentStatus?.[0]?.paymentstatus === "cancelled"
            ? LocaleStrings.payment_cancelled
            : "-"}
        </div>
      </div>
      {productLastPaymentStatus?.[0]?.paymentstatus !== "approve" &&
      productLastPaymentStatus?.[0]?.paymentstatus !==
        "pending_confirmation_bank" &&
      (productDetails.isdraft === "0" || productDetails.isdraft === 0) ? (
        <>
          <div className="w-40 my-4">
            <button
              type="button"
              className="btn-primary"
              onClick={() => openRepaymentModal()}>
              {LocaleStrings.repayment_btn}
            </button>
          </div>
          {isRepaymentOpen ? <RepaymentModal relist={0} /> : ""}
        </>
      ) : productDetails.listingexpriydate !== null &&
        (productDetails.isdraft === "0" || productDetails.isdraft === 0) &&
        productDetails.listingexpriydate !== "" &&
        moment(productDetails.listingexpriydate).isBefore() ? (
        <>
          <div className="w-40 my-4">
            <button
              type="button"
              className="btn-primary"
              onClick={() => openRepaymentModal()}>
              {LocaleStrings.repayment_btn_relist}
            </button>
          </div>
          {isRepaymentOpen ? <RepaymentModal relist={1} /> : ""}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  let {} = state;

  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    isRepaymentOpen: state.isRepaymentOpen,
    productDetails: state.productDetails ? state.productDetails : [],
    productLastPaymentStatus: _.isEmpty(state.productLastPaymentStatus)
      ? []
      : state.productLastPaymentStatus.data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchLastPaymentStatusById, isRepaymentModalOpen, fetchSettingsMaster },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPaymentStatus);
