import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import BaseLoader
import BaseLoader from "@baseLoader";
// Import constant
import { PERSONAL_USER_REDIRECT_TO } from "@constant";
// Import Component
import CommonHeaderLegalDocs from "../commonheaderlegaldocs";
import SalesPolicyEdit from "./sales-policy-edit";
//Action Import
import { camefromSalesManagemnet } from "@salesmanagementActions";

class LegalDocsSlaesPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaderDesc: LocaleStrings.preparing_legal_docs,
    };

    // All Binded Functions
  }

  //Arrow function for renderLoader
  componentDidMount() {
    let { session } = this.props;
    let { usertype } = session;

    if (usertype === "personal") {
      this.props.history.push(PERSONAL_USER_REDIRECT_TO);
    }
  }

  render() {
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <div className="responsivePadding">
            <CommonHeaderLegalDocs
              history={this.props.history}
              commonHeaderText={LocaleStrings.legal_docs_list_text3}
            />
            {this.state.loading ? (
              <>
                <BaseLoader data={this.state.loaderDesc} />
              </>
            ) : (
              <SalesPolicyEdit />
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(LegalDocsSlaesPolicy);
