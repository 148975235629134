import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LocaleStrings from "@language";
import { CURRENCY_RATE_QR } from "@constant";
import _ from "lodash";

// Import Action
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
import { fetchSettingsMaster } from "@legaldocsActions";

// Functions Import
import {
  ProductTextLocalized,
  getTotalPriceToPay,
  getTotalTaxToPay,
  priceConversion,
} from "@commonFunction";

const ProductListingFees = (props) => {
  let listingTotal = 0;
  let product_listing_price = 0;
  let tax_n_charges = 0;

  let {
    vendorDetails,
    productObjectForFeeDetails,
    language,
    session,
    localCurrency,
    settingsMasterStore,
  } = props;
  let currency = currencyCode(localCurrency);
  let currencyrate = currencyRate(localCurrency);

  /**** Render Detaild variant List ****/

  const renderDetailedVariantsList = () => {
    let { productObjectForFeeDetails, language } = props;

    return _.map(productObjectForFeeDetails.detailedvariants, (item, index) => {
      listingTotal = parseFloat(listingTotal + parseFloat(item.price_unit));

      return (
        <tr key={`detailedvariants-${index}`} className="">
          <td className="sales-table-td">
            <div className="sales-table-td-text">
              {ProductTextLocalized(item.name_en, item.name_ar, language)}
            </div>
          </td>
          <td className="sales-table-td">
            <div className="sales-table-td-text capitalize">
              {currency} {parseFloat(item.price_unit).toFixed(2)}
            </div>
          </td>
        </tr>
      );
    });
  };

  const getPrice = (settingsMasterStoreParam, priceToCal) => {
    /* Old code  Don't remove */

    // if (settingsMasterStoreParam.listingfee_type === "percentage") {
    //   return (
    //     parseFloat(priceToCal) *
    //     (settingsMasterStoreParam.listing_perproduct / 100)
    //   );
    // } else {
    //   return settingsMasterStoreParam.listing_perproduct;
    // }

    /* New Code */
    return settingsMasterStoreParam.listing_perproduct;
  };

  const product_listingprice = isCurrencyRateApplicable(
    localCurrency,
    "QAR",
    settingsMasterStore.listing_perproduct
  );

  // Tax & Charges
  // Old code tax_n_charges = parseFloat(vendorDetails.tax * currencyrate).toFixed(2);
  //tax_n_charges = parseFloat(vendorDetails.tax).toFixed(2);

  tax_n_charges = 0;
  // Product Listing Price

  product_listing_price = parseFloat(listingTotal) + parseFloat(tax_n_charges);

  useEffect(() => {
    // Without Pagination
    props.fetchSettingsMaster(session, (callback) => {});

    return () => {
      listingTotal = 0;
      product_listing_price = 0;
      tax_n_charges = 0;
    };
  }, []);

  return (
    <>
      {/* Table of "detailedvariants" */}
      <div className="flex flex-col mt-6">
        <div
          className="-my-2 overflow-x-auto
              ">
          <div className="py-2 align-middle inline-block min-w-full ">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="sales-table-th">
                      {
                        LocaleStrings.product_fees_listing_detailed_variants_thead_text1
                      }
                    </th>
                    <th scope="col" className="sales-table-th">
                      {
                        LocaleStrings.product_fees_listing_detailed_variants_thead_text2
                      }
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renderDetailedVariantsList()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Total Fees to pay */}
      <div className="block mt-4">
        <p className="text-xs font-semibold text-secondary">
          {LocaleStrings.listing_fee}:-
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4 ">
        <div>
          <p className="text-xs font-semibold text-secondary">
            {LocaleStrings.product_listing_price}
          </p>
        </div>
        {/* Product Listing Price */}
        <div>
          <p className="text-xs text-ternary text-right">
            {/* Code listing fee [Don't remove]
             {currency}{" "}
            {parseFloat(
              (getPrice(settingsMasterStore, listingTotal) + listingTotal) *
                currencyrate
            ).toFixed(2)} */}
            {currency}{" "}
            {/* Old code {(settingsMasterStore.listing_perproduct * currencyrate).toFixed(2)} */}
            {/* {settingsMasterStore.listing_perproduct.toFixed(2)} */}
            {product_listingprice.toFixed(2)}
          </p>
        </div>
        {/* Tax & Charges */}
        {/* <div>
          <p className="text-xs font-semibold text-secondary">
            {LocaleStrings.tax_charges} ({parseFloat(tax_n_charges).toFixed(2)}
            %)
          </p>
        </div> */}
        {/* <div>
          <p className="text-xs text-ternary text-right"> */}
        {/*  old code - {currency} {parseFloat(tax_n_charges * currencyrate).toFixed(2)} */}
        {/* {currency}
            {getTotalTaxToPay(settingsMasterStore, tax_n_charges)}
          </p>
        </div> */}
        {/* Devide */}
        <div
          className="col-span-2 inset-0 flex items-center"
          aria-hidden="true">
          <div className="w-full border-t border-ternary"></div>
        </div>
        {/* Total */}
        <div>
          <p className="text-xs font-semibold text-secondary">
            {LocaleStrings.total}
          </p>
        </div>
        <div>
          <p className="text-xs text-ternary text-right">
            {currency}{" "}
            {/*
              Old listing fee [Don't delete]
             {(
              parseFloat(
                (getPrice(settingsMasterStore, listingTotal) + listingTotal) *
                  currencyrate
              ) + parseFloat(tax_n_charges * currencyrate)
            ).toFixed(2)} */}
            {/* {(
              (settingsMasterStore.listing_perproduct +
                parseFloat(tax_n_charges)) *
              currencyrate
            ).toFixed(2)} */}
            {/* {getTotalPriceToPay(settingsMasterStore, tax_n_charges)} old code */}
            {product_listingprice.toFixed(2)}
          </p>
        </div>
        {/* Devide */}
        <div
          className="col-span-2 inset-0 flex items-center"
          aria-hidden="true">
          <div className="w-full border-t border-ternary"></div>
        </div>

        {/* Tax Inclusive */}
        <div className="col-span-2 " aria-hidden="true">
          <p className="text-base font-semibold text-primary text-right">
            {/* {currency} {getTotalPriceToPay(settingsMasterStore, tax_n_charges)} old code */}
            {currency} {product_listingprice.toFixed(2)}
            {localCurrency == "USD" ? (
              <div className="text-right text-cardfooter font-normal text-xl">
                (= QAR
                {priceConversion(
                  localCurrency,
                  "QAR",
                  product_listingprice,
                  CURRENCY_RATE_QR
                )}
                )
              </div>
            ) : (
              ""
            )}
          </p>
          <p className="text-xs font-semibold text-ternary">
            {LocaleStrings.tax_inclusive_text}
          </p>
        </div>

        {/* Approval note */}
        <div className="col-span-2 ">
          <p className="text-xs text-ternary">
            {LocaleStrings.productfee_screen_listing_note_text}
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  let { session } = state;

  return {
    session,
    language: state.language,
    localCurrency: state.currency,
    settingsMasterStore: state.settingsMasterStore
      ? state.settingsMasterStore.data !== "undefined"
        ? state.settingsMasterStore.data
        : []
      : [],
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchSettingsMaster }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductListingFees);
