import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import _ from "lodash";
import toast from "react-hot-toast";
// Import constant
import { PERSONAL_USER_REDIRECT_TO } from "@constant";
// Icons
import { SearchIcon } from "@heroicons/react/solid";
// Import BaseLoader
import BaseLoader from "@baseLoader";
// Import Component
import CommonHeaderRevenueReports from "../commonheaderrevenuereports.js";

import SealesDateTabs from "./sealesdatetabs";
import SalesReportAndTotal from "./salesreportandtotal";
import SalesTableList from "./salestablelist";
// Import Common Functions
import { classNames } from "@commonFunction";
//Import Actions
import { fetchVendorDetailedSales } from "@revenuereportsActions";
import { onSidebarMenuSelected } from "@sidebarActions";

const limit = 10; // Table item per page
const source = "web"; // param for revenue list
class RevenueReportsSales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_reports,
      pageNumber: 1,
      searchText: "",
      index: 0,
      tab: 0,
      startdate: "",
      enddate: "",
    };

    // All Binded Functions
    //this.updateSearchText = this.updateSearchText.bind(this);
  }

  // update page number
  updatePageNumber = (pageNum) => {
    this.setState({ pageNumber: pageNum });
  };

  /* update index */
  updateIndex = (indexNum) => {
    this.setState({ index: indexNum });
  };

  /* update tab */
  updateTab = (tabNum) => {
    this.setState({ tab: tabNum });
  };

  // updateSearchText(test = "") {
  //   this.setState({ searchText: test });
  // }

  componentDidMount() {
    let { pageNumber, searchText, index, tab, startdate, enddate } = this.state;
    let { session } = this.props;
    let { usertype } = session;
    if (usertype === "personal") {
      this.props.onSidebarMenuSelected(2);
      this.props.history.push(PERSONAL_USER_REDIRECT_TO);
    }
    /*
    Sales Report
    vendor-datascript/detailedsales => Listing
    New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
    vendor-datascript/salessearch => Search
    Params: {searchterm:<text>}
    */
    this.props.fetchVendorDetailedSales(
      this.props.session,
      index, // Index
      tab, // tab
      source,
      limit,
      pageNumber, // page
      searchText, // searchterm
      startdate,
      enddate,
      (callBack) => {
        this.setState({ loading: false });
      }
    );
  }

  handleSearch = (event) => {
    let { userid } = this.props;
    let { index, tab, startdate, enddate } = this.state;

    var searchKey = event.target.value;
    let pageNumber = 1;

    if (searchKey === "") {
      this.setState({ loading: true });

      this.props.fetchVendorDetailedSales(
        this.props.session,
        index, // Index
        tab, // tab
        source,
        limit,
        pageNumber, // page
        "", // searchterm
        startdate,
        enddate,
        (callBack) => {
          this.setState({ loading: false });
        }
      );
    } else {
      if (_.size(searchKey) > 3) {
        /* 
        search list without pagination
        */
        this.props.fetchVendorDetailedSales(
          this.props.session,
          index, // Index
          tab, // tab
          source,
          limit,
          pageNumber, // page
          searchKey, // searchterm
          startdate,
          enddate,
          (callBack) => {
            this.setState({ loading: false });
          }
        );
      }
    }
    this.setState({ pageNumber });
    this.setState({ searchText: searchKey });
  };

  /* Handel date change */
  handleDates = (event) => {
    let { index, tab, searchText, pageNumber } = this.state;

    if (event.target.name === "revenue_report_enddate") {
      this.setState({ enddate: event.target.value ? event.target.value : "" });
    }

    if (event.target.name === "revenue_report_startdate") {
      this.setState({
        startdate: event.target.value ? event.target.value : "",
      });
    }

    let stdate = document.getElementById("revenue_report_startdate").value;
    let enddate = document.getElementById("revenue_report_enddate").value;
    /* 
    Date validation
    */
    if (_.size(stdate) === 10 && _.size(enddate) === 10) {
      let stdate_replaced = _.replace(stdate, "-", "");
      let enddate_replaced = _.replace(enddate, "-", "");

      stdate_replaced = _.replace(stdate_replaced, "-", "");
      enddate_replaced = _.replace(enddate_replaced, "-", "");

      if (parseInt(stdate_replaced) > parseInt(enddate_replaced)) {
        toast.error(LocaleStrings.invalid_date_range);
      } else {
        this.props.fetchVendorDetailedSales(
          this.props.session,
          index, // Index
          tab, // tab
          source,
          limit,
          pageNumber, // page
          searchText, // searchterm
          stdate,
          enddate,
          (callBack) => {
            this.setState({ loading: false });
          }
        );
      }
    } else if (_.size(stdate) === 0 && _.size(enddate) === 0) {
      this.props.fetchVendorDetailedSales(
        this.props.session,
        index, // Index
        tab, // tab
        source,
        limit,
        pageNumber, // page
        searchText, // searchterm
        "", // Start date
        "", // End date
        (callBack) => {
          this.setState({ loading: false });
        }
      );
    } else {
    }
  };

  componentWillUnmount() {
    // Reinitialize all states
    this.setState({
      pageNumber: 1,
      searchText: "",
      index: 0,
      tab: 0,
      startdate: "",
      enddate: "",
    });
  }

  render() {
    let { searchText, startdate, enddate } = this.state;
    let isDateEmpty = false; // Check if both the dates are present then only make it true
    isDateEmpty = _.size(startdate) > 0 && _.size(enddate) > 0 ? true : false;

    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <BaseLoader data={this.state.loaderDesc} />
            ) : (
              <div className="responsivePadding">
                <CommonHeaderRevenueReports
                  history={this.props.history}
                  commonHeaderText={LocaleStrings.revenue_reports_list_text2}
                />
                <div className="relative  ">
                  <div className="py-6">
                    <div
                      className={classNames(
                        _.size(searchText) !== 0 ? "hidden" : "",
                        "flex flex-wrap -mx-3 mb-6"
                      )}>
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          for="revenue_report_startdate"
                          className="text-sm font-medium text-secondary px-2">
                          {LocaleStrings.start_date}:-{""}
                        </label>
                        <input
                          className="appearance-none px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm w-40"
                          type="date"
                          id="revenue_report_startdate"
                          name="revenue_report_startdate"
                          onChange={this.handleDates}
                          placeHolder="Start Date"
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          for="revenue_report_enddate"
                          className="text-sm font-medium text-secondary px-2">
                          {LocaleStrings.end_date}:-{""}
                        </label>
                        <input
                          className="appearance-none  px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm w-40"
                          type="date"
                          id="revenue_report_enddate"
                          name="revenue_report_enddate"
                          onChange={this.handleDates}
                          placeholder="End Date"
                        />
                      </div>
                    </div>

                    {/* SEARCH SECTION START*/}
                    <div
                      className={classNames(
                        isDateEmpty ? "hidden" : "",
                        "mb-6"
                      )}>
                      <label htmlFor="search-field" className="sr-only">
                        {LocaleStrings.search_product}
                      </label>
                      <div className="relative w-full text-ternary focus-within:text-secondary ">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="revenue-report-search-field"
                          placeholder={
                            LocaleStrings.revenue_report_sales_search_by
                          }
                          type="search"
                          name="revenuereports_product_search"
                          value={this.state.searchText}
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                    {/* SEARCH SECTION ENDS*/}
                    <SealesDateTabs
                      searchText={this.state.searchText}
                      pageNumber={this.state.pageNumber}
                      source={source}
                      limit={limit}
                      index={this.state.index}
                      tab={this.state.tab}
                      updatePageNumber={this.updatePageNumber}
                      updateIndex={this.updateIndex}
                      updateTab={this.updateTab}
                      startdate={this.state.startdate}
                      enddate={this.state.enddate}
                    />
                    {/* All TIme | Daily | Weekly | Monthly */}
                    <SalesTableList
                      searchText={this.state.searchText}
                      pageNumber={this.state.pageNumber}
                      source={source}
                      limit={limit}
                      index={this.state.index}
                      tab={this.state.tab}
                      updatePageNumber={this.updatePageNumber}
                      updateIndex={this.updateIndex}
                      updateTab={this.updateTab}
                      startdate={this.state.startdate}
                      enddate={this.state.enddate}
                    />
                    <SalesReportAndTotal
                      searchText={this.state.searchText}
                      pageNumber={this.state.pageNumber}
                      source={source}
                      limit={limit}
                      index={this.state.index}
                      tab={this.state.tab}
                      updatePageNumber={this.updatePageNumber}
                      updateIndex={this.updateIndex}
                      updateTab={this.updateTab}
                      startdate={this.state.startdate}
                      enddate={this.state.enddate}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {
  fetchVendorDetailedSales,
  onSidebarMenuSelected,
})(RevenueReportsSales);
