import { SETTINGS_MASTER } from "../actions/index";

import LocaleStrings from "@language";

/**** Legal Doc Static List ****/
export var storeLegalDocsList = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.legal_docs_list_text1,
      subname: LocaleStrings.legal_docs_list_subtext1,
      href: "termsncons",
      icon: "termsandcons",
      current: false,
      position: 0,
    },
    {
      name: LocaleStrings.legal_docs_list_text2,
      subname: LocaleStrings.legal_docs_list_subtext2,
      href: "privacypolicy",
      icon: "privacypolicy",
      current: false,
      position: 1,
    },
    // {
    //   name: LocaleStrings.legal_docs_list_text3,
    //   subname: LocaleStrings.legal_docs_list_subtext3,
    //   href: "salespolicy",
    //   icon: "privacypolicy",
    //   current: false,
    //   position: 2,
    // },
    {
      name: LocaleStrings.legal_docs_list_text4,
      subname: LocaleStrings.legal_docs_list_subtext4,
      href: "aboutus",
      icon: "aboutus",
      current: false,
      position: 3,
    },
  ];

  return state;
};

/**** Fetch SETTINGS_MASTER data  *****/
export var settingsMasterStore = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_MASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
