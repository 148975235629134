import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import Components

// Import constant
import { PERSONAL_USER_REDIRECT_TO, OTHERS_USER_REDIRECT_TO } from "@constant";
// Import Actions
import { camefromSalesManagemnet } from "@salesmanagementActions";
import { onSidebarMenuSelected } from "@sidebarActions";

class CommonHeaderRevenueReports extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  /**** Handle Back Button ****/
  _handleBack(e) {
    let { cameFromSalesMange, session } = this.props;
    let { usertype } = session;

    if (
      this.props.history.location.pathname ===
        "/dashboard/revenuereports/sales" &&
      cameFromSalesMange
    ) {
      this.props.camefromSalesManagemnet(false);

      this.props.history.push(
        usertype === "personal"
          ? PERSONAL_USER_REDIRECT_TO
          : OTHERS_USER_REDIRECT_TO
      );

      this.props.onSidebarMenuSelected(usertype === "personal" ? 2 : 1);
    } else {
      this.props.history.push("/dashboard/revenuereports");
    }
  }

  render() {
    return (
      <>
        <div className="py-6 bg-custWhite ">
          {/* Header Part */}
          <div className="grid gap-4 grid-cols-1 px-6 md:grid-cols-3 gap-3 sm:grid-cols-2 ">
            <div
              align="left"
              className="flex cursor-pointer icon-hover-transition"
              onClick={(e) => this._handleBack(e)}
            >
              <img
                loading="lazy"
                className={" h-6 w-6 "}
                src={"/images2/common/arrow.svg"}
              />
              <p className="text-primary mx-2 text-l font-bold">
                {this.props.commonHeaderText}
              </p>
            </div>
            <div className="justify-items-center"></div>
            <div className="mx-5 mt-2 flex gap-4 flex-row-reverse "></div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let { commonHeaderText } = ownProps;
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    cameFromSalesMange: state.cameFromSalesMange,
    commonHeaderText,
  };
}

export default connect(mapStateToProps, {
  camefromSalesManagemnet,
  onSidebarMenuSelected,
})(CommonHeaderRevenueReports);
