import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import toast from "react-hot-toast";
// Import Functions
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
// Functions Import
import { classNames } from "@commonFunction";
//Import Actions
import { downloadRevenueReport } from "@revenuereportsActions";

class ReveneuReportAndTotal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_legal_docs,
      btnLoader: false,
      isBtnDisable: false,
    };

    // All Binded Functions
  }

  /*
  And to generate report, just add paramsgenerate:true with all other parameters in both the APIs, it will return a csv file URL i
  */
  downloadRevenueReport = (e) => {
    let {
      index,
      tab,
      source,
      limit,
      pageNumber,
      searchText,
      startdate,
      enddate,
    } = this.props;
    this.setState({ btnLoader: true, isBtnDisable: true });

    this.props.downloadRevenueReport(
      this.props.session,
      index, // Index
      tab, // tab
      source,
      limit,
      pageNumber, // page
      searchText, // searchterm
      startdate,
      enddate,
      (callBack) => {
        if (callBack.success === 1) {
          if (callBack.message.data.csv) {
            window.location.href = callBack.message.data.csv;
          } else {
            toast.error(LocaleStrings.no_result_found);
          }
          //
        } else {
          toast.error(LocaleStrings.something_went_wrong);
        }
        this.setState({ btnLoader: false, isBtnDisable: false });
      }
    );
  };

  render() {
    let itemTotalSold = 0;

    let { vendorDetailedRevenueStore, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    if (!_.isEmpty(vendorDetailedRevenueStore)) {
      itemTotalSold = vendorDetailedRevenueStore.stats.total_earning;
    }

    let orderCurrency = vendorDetailedRevenueStore?.stats?.odcurrency;

    // itemTotalSold = isCurrencyRateApplicable(
    //   localCurrency,
    //   orderCurrency,
    //   itemTotalSold
    // );
    // console.log("vendorDetailedRevenueStore", vendorDetailedRevenueStore);
    return (
      <>
        {/* Footer Part Start*/}
        <div className="w-full bottom-0 absolute">
          <div className="grid gap-4 grid-cols-1 mb-2 md:grid-cols-3 gap-3 sm:grid-cols-2 bg-custWhite rounded items-center">
            <div className="col-span-2 ">
              <p className="text-sm font-medium text-primary p-4">
                {currency}{" "}
                {itemTotalSold.toFixed(2) +
                  " " +
                  LocaleStrings.revenue_report_footer_text}
              </p>
            </div>

            {vendorDetailedRevenueStore &&
            vendorDetailedRevenueStore.count &&
            vendorDetailedRevenueStore.count > 0 ? (
              <div className="px-4 ">
                <button
                  type="button"
                  disabled={this.state.isBtnDisable}
                  className={classNames(
                    this.state.isBtnDisable ? "cursor-not-allowed" : "",
                    "btn-primary"
                  )}
                  onClick={(e) => this.downloadRevenueReport()}>
                  <svg
                    className={classNames(
                      this.state.btnLoader ? "" : "sr-only",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {LocaleStrings.download_report}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Footer Part Ends*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, isLoggedIn, currency } = state;
  return {
    session,
    user,
    revenueSalesDateTabs: state.revenueSalesDateTabs,
    vendorDetailedRevenueStore: !_.isEmpty(state.vendorDetailedRevenueStore)
      ? !_.isEmpty(state.vendorDetailedRevenueStore.data)
        ? state.vendorDetailedRevenueStore.data
        : []
      : [],
    localCurrency: currency,
  };
}

export default connect(mapStateToProps, { downloadRevenueReport })(
  ReveneuReportAndTotal
);
