import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import moment from "moment-timezone";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import toast, { Toaster } from "react-hot-toast";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "@language";

// Import constant
import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";

// Import Actions
import {
  fetchCountries,
  updateAccount,
  addAccount,
  fetchAccount,
} from "@profileSettingsActions";

// Functions Import
import { classNames, isEmpty } from "@commonFunction";

class ActSettingsEdit extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { session } = this.props;
    /* Fetch Account details */
    this.props.fetchAccount(session, (callBackResponce) => {});
    /* Fetch Country List */
    this.props.fetchCountries(session, (res) => {
      this.setState({ loading: false });
    });
  }

  onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  /***** Form Submit *****/
  onFormSubmit(values) {
    if (this.props.initialValues && this.props.initialValues.id) {
      /* Edit/Update Account */
      this.setState({ btnLoader: true });
      this.setState({ isBtnDisable: true });
      values.iban_code =
        values.country_code +
        // values.check_code +
        values.bank_code +
        values.bank_account_number;
      this.props.updateAccount(
        this.props.session,
        values,
        this.props.initialValues.id,
        (res) => {
          if (res.success == 1) {
            this.props.fetchAccount(this.props.session, (callBackResponce) => {
              this.setState({ btnLoader: false });
              this.setState({ isBtnDisable: false });
              toast.success(LocaleStrings.account_update_success);
            });
          } else {
            this.setState({ btnLoader: false });
            this.setState({ isBtnDisable: false });
            toast.error(LocaleStrings.common_fail_message);
          }
        }
      );
    } else {
      /* Add/create Account */
      this.setState({ btnLoader: true });
      this.setState({ isBtnDisable: true });
      values.useridfk = this.props.session.userid;
      values.iban_code =
        values.country_code +
        // values.check_code +
        values.bank_code +
        values.bank_account_number;
      this.props.addAccount(this.props.session, values, (res) => {
        if (res.success == 1) {
          this.props.fetchAccount(this.props.session, (callBackResponce) => {
            this.setState({ btnLoader: false });
            this.setState({ isBtnDisable: false });
            toast.success(LocaleStrings.account_add_success);
          });
        } else {
          this.setState({ btnLoader: false });
          this.setState({ isBtnDisable: false });
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
    };
  }

  render() {
    var countriesOptions = []; // Countries
    let { initialValues, language, handleSubmit, storeCountries, editMode } =
      this.props;

    //Discount Area Object Creation
    _.forEach(storeCountries, function (value) {
      var obj = {
        name: value.name,
        value: value.id,
      };

      countriesOptions.push(obj);
    });

    return (
      <>
        <div className=" xs:px-2 sm:px-10 2xl:px-48">
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
              <div className="p-10 space-y-8">
                <div className="">
                  <p className="text-sm font-medium text-secondary pb-4">
                    {LocaleStrings.profile_settings_actsettings_terms_text}
                  </p>
                </div>
                {/* Country Code */}
                <Field
                  name="benificiary_name"
                  label={LocaleStrings.beneficiary_name}
                  placeholder={LocaleStrings.beneficiary_name}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
                <Field
                  name="benificiary_bankname"
                  label={LocaleStrings.beneficiary_bank_name}
                  placeholder={LocaleStrings.beneficiary_bank_name}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
                <Field
                  name="bank_account_number"
                  label={LocaleStrings.act_settings_act_number_placeholder}
                  placeholder={
                    LocaleStrings.act_settings_act_number_placeholder
                  }
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  maxlength="21"
                  classNameLabel="text-sm font-medium text-secondary"
                />
                <Field
                  name="bank_code"
                  label={LocaleStrings.act_settings_swift_code_lbl}
                  placeholder={
                    LocaleStrings.act_settings_swift_code_placeholder
                  }
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  maxlength="4"
                  classNameLabel="text-box-lable"
                />
                <Field
                  name="country_code"
                  label={LocaleStrings.country_code}
                  placeholder={LocaleStrings.country_code}
                  component={this.renderSelect}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  opts={countriesOptions}
                  className="cust-input-field2"
                  classNameLabel="block text-box-lable"
                />
                {/* <Field
                  name="check_code"
                  label={LocaleStrings.check_digits}
                  placeholder={LocaleStrings.check_digits}
                  type="number"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  maxlength="2"
                  onKeyPress={this.onKeyPress}
                  classNameLabel="text-box-lable"
                /> */}

                <Field
                  name="benificiary_contactno"
                  label={LocaleStrings.act_settings_benificiary_contactno}
                  placeholder={LocaleStrings.act_settings_benificiary_contactno}
                  type="number"
                  component={this.renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
              </div>
              {/* Footer */}
              <div className="p-6 border-t-2 border-quaternary">
                <div className="grid grid-cols-3 gap-4">
                  <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                    <button
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? "cursor-not-allowed" : "",
                        "btn-primary"
                      )}
                      type="submit">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {editMode
                        ? LocaleStrings.update_account
                        : LocaleStrings.add_account}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var regex = /^\s+$/;
  var myRegEx = /[^A-Z\d]/i;

  var benificiary_contactno = values.benificiary_contactno;

  if (
    benificiary_contactno !== undefined &&
    benificiary_contactno !== null &&
    benificiary_contactno !== ""
  ) {
    if (benificiary_contactno.length > 15) {
      errors.benificiary_contactno = "Contact number should be 15 or less";
    }
  }

  if (!values.country_code) {
    errors.country_code = LocaleStrings.required;
  }

  if (!values.check_code) {
    errors.check_code = LocaleStrings.required;
  } else if (values.check_code.match(regex)) {
    errors.check_code = LocaleStrings.required;
  } else if (values.check_code.length != 2) {
    errors.check_code = LocaleStrings.invalid_length;
  }

  if (!values.bank_code) {
    errors.bank_code = LocaleStrings.required;
  } else if (values.bank_code.match(regex)) {
    errors.bank_code = LocaleStrings.required;
  } else if (values.bank_code.length != 4) {
    errors.bank_code = LocaleStrings.invalid_length;
  }

  let check = /^[A-Z0-9-]*$/.test(values.bank_account_number);
  if (!values.bank_account_number) {
    errors.bank_account_number = LocaleStrings.required;
  } else if (!check) {
    errors.bank_account_number = LocaleStrings.bank_account_number_error_text;
  } else if (values.bank_account_number.length != 21) {
    errors.bank_account_number = LocaleStrings.invalid_length;
  }

  var requiredFields = ["benificiary_bankname", "benificiary_name"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user, language, isLoggedIn } = state;
  var initVals = {};
  var editMode = false;

  if (
    state.bankAccount &&
    state.bankAccount.data &&
    state.bankAccount.data.length > 0 &&
    state.bankAccount.data[0].id
  ) {
    initVals = state.bankAccount.data[0];
    editMode = true;
  }

  return {
    session,
    user,
    isLoggedIn,
    language,
    initialValues: initVals,
    bankAccount: state.bankAccount,
    storeCountries: !_.isEmpty(state.storeCountries)
      ? !_.isEmpty(state.storeCountries.data)
        ? state.storeCountries.data
        : []
      : [],
    editMode: editMode,
  };
}

export default connect(mapStateToProps, {
  fetchCountries,
  fetchAccount,
  updateAccount,
  addAccount,
})(
  reduxForm({
    validate,
    form: "accountSettingsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ActSettingsEdit)
);
