import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
// Functions Import
import { classNames } from "@commonFunction";

import {
  fetchVendorStats,
  salesmanagementStatboxSelected,
} from "../actions/index";

// Import BaseLoader
import BaseLoader from "@baseLoader";

class Vendorstats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_vendor_statistics,
      showBox1: false,
      showBox2: false,
      showBox3: false,
      showBox4: false,
    };

    // All Binded Functions
  }

  // Compnent Mount
  componentDidMount() {
    this.props.fetchVendorStats(this.props.session, (callback) => {
      this.setState({ loading: false });
    });
  }

  /***** Handle Sales Tabs Select *****/
  _handleSalesTab = (e, selectedSalesTab) => {
    if (selectedSalesTab === "new") {
      this.props.history.push("/dashboard/salesmanagement/salesnew");
    } else if (selectedSalesTab === "delivered") {
      this.props.history.push("/dashboard/salesmanagement/salesdelivered");
    } else if (selectedSalesTab === "replace") {
      this.props.history.push("/dashboard/salesmanagement/salescancreturepl");
    } else if (selectedSalesTab === "nego") {
      this.props.history.push("/dashboard/salesmanagement/salesnegotiation");
    } else {
      this.props.salesmanagementStatboxSelected(selectedSalesTab);
    }
  };

  handleBoxToggle = (boxname) => {
    if (boxname === "new") {
      this.setState({ showBox1: true });
    } else if (boxname === "delivered") {
      this.setState({ showBox2: true });
    } else if (boxname === "replace") {
      this.setState({ showBox3: true });
    } else if (boxname === "nego") {
      this.setState({ showBox4: true });
    } else {
    }
  };

  switchHover = (boxname) => {
    if (boxname === "new") {
      this.setState({ showBox1: false });
    } else if (boxname === "delivered") {
      this.setState({ showBox2: false });
    } else if (boxname === "replace") {
      this.setState({ showBox3: false });
    } else if (boxname === "nego") {
      this.setState({ showBox4: false });
    } else {
    }
  };

  // Render Vendor Stats
  renderVendorStatBoxs = () => {
    let { language } = this.props;
    let { showBox1, showBox2, showBox3, showBox4 } = this.state;
    return (
      <>
        {/* Open Orders */}
        <div
          className="cc-status-box"
          onClick={(e) => this._handleSalesTab(e, "new")}
          onMouseEnter={(e) => this.handleBoxToggle("new")}
          onMouseLeave={(e) => this.switchHover("new")}
        >
          <dt>
            <div
              className={classNames(
                showBox1 ? "cc-sales-box-icon" : "cc-sales-box-icon-revert",
                ""
              )}
            >
              <img
                loading="lazy"
                className={"h-5 w-5"}
                src={
                  showBox1
                    ? "/images2/sales_managemnet_images/order_white.svg"
                    : "/images2/sales_managemnet_images/order.svg"
                }
                alt="new_orders"
              />
            </div>
            <p
              className={classNames(
                language === "ar" ? "mr-16" : "ml-14",
                "text-2xl font-semibold text-secondary mt-3"
              )}
            >
              +{this.props.vendorStats.neworders}
            </p>
            <img
              loading="lazy"
              className={"icon-hover-transition cc-sales-redirect-arrow"}
              src={"/images2/common/arrow-right.svg"}
              onClick={(e) => this._handleNameChangeAndindex(e, "plus")}
            />
          </dt>
          <dd
            className={classNames(
              language === "ar" ? "mr-16" : "ml-14",
              "flex items-baseline"
            )}
          >
            <p className="text-xs font-medium text-ternary truncate mx-2">
              {LocaleStrings.open_orders}
            </p>
          </dd>
        </div>
        {/* delivered */}
        <div
          className="cc-status-box"
          onClick={(e) => this._handleSalesTab(e, "delivered")}
          onMouseEnter={(e) => this.handleBoxToggle("delivered")}
          onMouseLeave={(e) => this.switchHover("delivered")}
        >
          <dt>
            <div
              className={classNames(
                showBox2 ? "cc-sales-box-icon" : "cc-sales-box-icon-revert",
                ""
              )}
            >
              <img
                loading="lazy"
                className={"h-5 w-5"}
                src={
                  showBox2
                    ? "/images2/sales_managemnet_images/deliverby_white.svg"
                    : "/images2/sales_managemnet_images/deliverby.svg"
                }
                alt="delivered_orders"
              />
            </div>
            <p
              className={classNames(
                language === "ar" ? "mr-16" : "ml-14",
                "text-2xl font-semibold text-secondary mt-3"
              )}
            >
              +{this.props.vendorStats.delivered}
            </p>
          </dt>
          <dd
            className={classNames(
              language === "ar" ? "mr-16" : "ml-14",
              "flex items-baseline"
            )}
          >
            <p className=" text-xs font-medium text-ternary truncate mx-2">
              {LocaleStrings.delivered}
            </p>
            <img
              loading="lazy"
              className={"icon-hover-transition cc-sales-redirect-arrow"}
              src={"/images2/common/arrow-right.svg"}
              onClick={(e) => this._handleNameChangeAndindex(e, "plus")}
            />
          </dd>
        </div>
        {/* Replace / Return / Cancel Box*/}
        <div
          className=" cc-status-box"
          onClick={(e) => this._handleSalesTab(e, "replace")}
          onMouseEnter={(e) => this.handleBoxToggle("replace")}
          onMouseLeave={(e) => this.switchHover("replace")}
        >
          <dt>
            <div
              className={classNames(
                showBox3 ? "cc-sales-box-icon" : "cc-sales-box-icon-revert",
                ""
              )}
            >
              <img
                loading="lazy"
                className={"h-5 w-5"}
                src={
                  showBox3
                    ? "/images2/sales_managemnet_images/clubbed_white.svg"
                    : "/images2/sales_managemnet_images/clubbed.svg"
                }
                alt="clubbed_orders"
              />
            </div>
            <p
              className={classNames(
                language === "ar" ? "mr-16" : "ml-14",
                "text-2xl font-semibold text-secondary mt-3"
              )}
            >
              +{this.props.vendorStats.clubbed}
            </p>
            <img
              loading="lazy"
              className={"icon-hover-transition cc-sales-redirect-arrow"}
              src={"/images2/common/arrow-right.svg"}
              onClick={(e) => this._handleNameChangeAndindex(e, "plus")}
            />
          </dt>
          <dd
            className={classNames(
              language === "ar" ? "mr-16" : "ml-14",
              "flex items-baseline"
            )}
          >
            <p className=" text-xs font-medium text-ternary ">
              {LocaleStrings.clubbed}
            </p>
          </dd>
        </div>
        {/* Negotiation Box */}
        <div
          className="cc-status-box"
          onClick={(e) => this._handleSalesTab(e, "nego")}
          onMouseEnter={(e) => this.handleBoxToggle("nego")}
          onMouseLeave={(e) => this.switchHover("nego")}
        >
          <dt>
            <div
              className={classNames(
                showBox4 ? "cc-sales-box-icon" : "cc-sales-box-icon-revert",
                ""
              )}
            >
              <img
                loading="lazy"
                className={"h-5 w-5"}
                src={
                  showBox4
                    ? "/images2/sales_managemnet_images/negotiation_white.svg"
                    : "/images2/sales_managemnet_images/negotiation.svg"
                }
                alt="negotiations_image"
              />
            </div>
            <p
              className={classNames(
                language === "ar" ? "mr-14" : "ml-14",
                "text-2xl font-semibold text-secondary mt-3"
              )}
            >
              +{this.props.vendorStats.negotiations}
            </p>
          </dt>
          <dd
            className={classNames(
              language === "ar" ? "mr-14" : "ml-14",
              "flex items-baseline"
            )}
          >
            <p className=" text-xs font-medium text-ternary truncate mx-2">
              {LocaleStrings.negotiation}
            </p>
            <img
              loading="lazy"
              className={"icon-hover-transition cc-sales-redirect-arrow"}
              src={"/images2/common/arrow-right.svg"}
              onClick={(e) => this._handleNameChangeAndindex(e, "plus")}
            />
          </dd>
        </div>
      </>
    );
  };

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <>{this.renderLoader()}</>
        ) : (
          <div className="mx-6 mb-10">
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              {this.renderVendorStatBoxs()}
            </dl>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    vendorStats: state.vendorStats
      ? state.vendorStats.data
        ? state.vendorStats.data
        : []
      : [],
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  fetchVendorStats,
  salesmanagementStatboxSelected,
})(Vendorstats);
