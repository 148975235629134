import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import moment from "moment-timezone";
import _ from "lodash";

// Import BaseLoader

// Import Component

// Import Common Functions
import { classNames } from "@commonFunction";

//Import Action
import { fetchVendorDetailedRevenue } from "@revenuereportsActions";

//Icons
import { SearchIcon } from "@heroicons/react/solid";

class ReveneuDateTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDateTab: 0,
      dateIndex: 0,
      dateNameToShow: "",
      dateNameToHidden: "",
      dailyCurrentSelection: "",
      yearMonthName: "",
    };

    // All Binded Functions
  }

  // Date Tab Nav Chnage {Daily,weekly,Monthly} [TAB CHANGE]
  _handleDateTab = (e, selectedTabPos) => {
    let { source, limit, searchText, startdate, enddate } = this.props;

    this.setState({ loading1: true });

    this.setState({ currentDateTab: selectedTabPos });
    this.setState({ dateIndex: 0 }); // Reset Index
    this.setState({ dailyCurrentSelection: moment().format("Do MMM YYYY") }); // Reset Daily

    if (selectedTabPos == 3) {
      // Monthly
      this.setState({ dateNameToShow: moment().format("MMMM") });
      this.setState({ dateNameToHidden: moment().format("MMMM") });
    } else if (selectedTabPos == 2) {
      // Weekly
      this.setState({ dateNameToShow: LocaleStrings.this_week });
      this.setState({ dateNameToHidden: LocaleStrings.this_week });
    } else if (selectedTabPos == 1) {
      // Daily
      this.setState({ dateNameToShow: LocaleStrings.today });
      this.setState({ dateNameToHidden: LocaleStrings.today });
    } else {
      this.setState({ dateNameToShow: "" });
      this.setState({ dateNameToHidden: "" });
    }

    /*
    Revenue Report
    vendor-datascript/revenuestats => Listing
    New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
    vendor-datascript/revenuesearch => Search
    Params: {searchterm:<text>}
    */
    this.props.fetchVendorDetailedRevenue(
      this.props.session,
      0, // Index
      selectedTabPos, // tab
      source,
      limit,
      1, // page
      searchText, // searchterm
      startdate,
      enddate,
      (callBack) => {
        this.setState({ loading1: false });
      }
    );

    /* As tab is changing will reset the page number here */
    this.props.updatePageNumber(1);

    this.props.updateTab(selectedTabPos);
    this.props.updateIndex(this.state.dateIndex);
  };

  /**** Date change With Arrows *****/
  _handleNameChangeAndindex = (e, leftorright) => {
    let { source, limit, searchText, pageNumber, startdate, enddate } =
      this.props;

    this.setState({ loading1: true });

    if (this.state.currentDateTab === 3) {
      // For Month
      if (leftorright == "minus") {
        // Minus
        var stateVal = this.state.dateIndex;
        var currVal = --stateVal;

        this.setState({ dateIndex: currVal });

        //let monthName = moment().subtract(Math.abs(currVal), "month").format('MMMM');
        let monthName = moment(this.state.dateNameToHidden, "MMMM")
          .subtract(1)
          .format("MMMM");

        this.setState({ dateNameToShow: monthName }); // Showing
        this.setState({ dateNameToHidden: monthName }); // Hidding

        // if (moment().format("MMMM") == monthName) {
        //   // reset Again
        //   this.setState({ dateIndex: 0 });
        // }
      } else {
        // Plus
        var stateVal = this.state.dateIndex;
        var currVal = ++stateVal;

        this.setState({ dateIndex: currVal });

        //let monthName = moment().add(Math.abs(currVal), "month").format('MMMM');
        let monthName = moment(this.state.dateNameToHidden, "MMMM")
          .add(1, "month")
          .format("MMMM");

        this.setState({ dateNameToShow: monthName }); // Showing
        this.setState({ dateNameToHidden: monthName }); // Hidding

        // if (moment().format("MMMM") == monthName) {
        //   // reset Again
        //   this.setState({ dateIndex: 0 });
        //   currVal = 0;
        // }
      }
    } else if (this.state.currentDateTab === 2) {
      // Weekly

      if (leftorright == "minus") {
        // Minus
        var stateVal = this.state.dateIndex;
        var currVal = --stateVal;

        if (currVal == 0) {
          this.setState({ dateNameToShow: LocaleStrings.this_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.this_week }); // Hidding
        } else if (currVal == -1) {
          this.setState({ dateNameToShow: LocaleStrings.last_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.last_week }); // Hidding
        } else if (currVal == 1) {
          this.setState({ dateNameToShow: LocaleStrings.next_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.next_week }); // Hidding
        } else {
          let dynamicWeekName = "";
          if (currVal < 0) {
            dynamicWeekName =
              Math.abs(currVal) + " " + LocaleStrings.weeks_back;
          } else {
            dynamicWeekName =
              LocaleStrings.after +
              " " +
              Math.abs(currVal) +
              " " +
              LocaleStrings.weeks;
          }

          this.setState({ dateNameToShow: dynamicWeekName }); // Showing
          this.setState({ dateNameToHidden: dynamicWeekName }); // Hidding
        }
      } else {
        // Plus
        var stateVal = this.state.dateIndex;
        var currVal = ++stateVal;

        if (currVal == 0) {
          this.setState({ dateNameToShow: LocaleStrings.this_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.this_week }); // Hidding
        } else if (currVal == -1) {
          this.setState({ dateNameToShow: LocaleStrings.last_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.last_week }); // Hidding
        } else if (currVal == 1) {
          this.setState({ dateNameToShow: LocaleStrings.next_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.next_week }); // Hidding
        } else {
          let behindText = currVal < 0 ? " " + LocaleStrings.weeks_back : "";
          let dynamicWeekName = "";
          if (currVal < 0) {
            dynamicWeekName =
              Math.abs(currVal) + " " + LocaleStrings.weeks_back;
          } else {
            dynamicWeekName =
              LocaleStrings.after +
              " " +
              Math.abs(currVal) +
              " " +
              LocaleStrings.weeks;
          }

          this.setState({ dateNameToShow: dynamicWeekName }); // Showing
          this.setState({ dateNameToHidden: dynamicWeekName }); // Hidding
        }
      }

      this.setState({ dateIndex: currVal });

      //Plus
    } else {
      // Daily
      if (leftorright == "minus") {
        // Minus
        var stateVal = this.state.dateIndex;
        var currVal = --stateVal;

        this.setState({
          dailyCurrentSelection: moment(
            this.state.dailyCurrentSelection,
            "Do MMM YYYY"
          )
            .subtract(1, "days")
            .format("Do MMM YYYY"),
        });
        if (currVal == 0) {
          this.setState({ dateNameToShow: LocaleStrings.today }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.today }); // Hidding
        } else if (currVal == -1) {
          this.setState({ dateNameToShow: LocaleStrings.yesterday }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.yesterday }); // Hidding
        } else {
          this.setState({
            dateNameToShow: moment(
              this.state.dailyCurrentSelection,
              "Do MMM YYYY"
            )
              .subtract(1, "days")
              .format("Do MMM YYYY"),
          }); // Showing
          this.setState({
            dateNameToHidden: moment(
              this.state.dailyCurrentSelection,
              "Do MMM YYYY"
            )
              .subtract(1, "days")
              .format("Do MMM YYYY"),
          }); // Hidding
        }
      } else {
        // Plus
        var stateVal = this.state.dateIndex;
        var currVal = ++stateVal;

        this.setState({
          dailyCurrentSelection: moment(
            this.state.dailyCurrentSelection,
            "Do MMM YYYY"
          )
            .add(1, "days")
            .format("Do MMM YYYY"),
        });

        if (currVal == 0) {
          this.setState({ dateNameToShow: LocaleStrings.today }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.today }); // Hidding
        } else if (currVal == -1) {
          this.setState({ dateNameToShow: LocaleStrings.yesterday }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.yesterday }); // Hidding
        } else {
          this.setState({
            dateNameToShow: moment(
              this.state.dailyCurrentSelection,
              "Do MMM YYYY"
            )
              .add(1, "days")
              .format("Do MMM YYYY"),
          }); // Showing
          this.setState({
            dateNameToHidden: moment(
              this.state.dailyCurrentSelection,
              "Do MMM YYYY"
            )
              .add(1, "days")
              .format("Do MMM YYYY"),
          }); // Hidding
        }
      }

      this.setState({ dateIndex: currVal });
    }

    /*
    Revenue Report
    vendor-datascript/revenuestats => Listing
    New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
    vendor-datascript/revenuesearch => Search
    Params: {searchterm:<text>}
    */
    this.props.fetchVendorDetailedRevenue(
      this.props.session,
      currVal, // Index
      this.state.currentDateTab, // tab
      source,
      limit,
      pageNumber, // page
      startdate,
      enddate,
      searchText, // searchterm
      (callBack) => {}
    );

    this.props.updateTab(this.state.currentDateTab);
    this.props.updateIndex(currVal);
  };

  showYearMonth = () => {
    var d = new Date();
    var nowx = d.setMonth(d.getMonth() - -this.state.dateIndex);
    return moment(nowx).format("YYYY/MMMM");
  };

  render() {
    let { session, searchText, startdate, enddate } = this.props;
    let { currentDateTab, dateNameToShow } = this.state; // [currentDateTab - Hole tab index ||all time/daily/weekly/monthly]

    let isDateEmpty = false; // Check if both the dates are present then only make it true
    isDateEmpty = _.size(startdate) > 0 && _.size(enddate) > 0 ? true : false;

    return (
      <>
        <div
          className={classNames(session.usertype !== "personal" ? "" : "", "")}
        >
          {/* NOTE:- Hide Tab section if Search text is present
          OR both dates are present */}
          <nav
            className={classNames(
              _.size(searchText) !== 0 || isDateEmpty ? "hidden" : "",
              "relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
            )}
            aria-label="Tabs"
          >
            {this.props.revenueDateTabs.map((box, tabIdx) => (
              <a
                key={box.name}
                onClick={(e) => this._handleDateTab(e, box.position)}
                className={classNames(
                  box.position == this.state.currentDateTab
                    ? "text-primary"
                    : "text-ternary hover:text-secondary",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === this.props.revenueDateTabs.length - 1
                    ? "rounded-r-lg"
                    : "",
                  "saler-date-tabs"
                )}
                aria-current={
                  box.position == this.state.currentDateTab ? "page" : undefined
                }
              >
                <span>{box.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    box.position == this.state.currentDateTab
                      ? "bg-primary"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>

        {/* 
          LEFT & RIGHT ARROW SECTION 
        
          NOTE:- Hide Tab section if Search text is present
          OR both dates are present
        */}
        <div
          className={classNames(
            _.size(searchText) !== 0 || isDateEmpty ? "hidden" : "",
            ""
          )}
        >
          <div
            className={classNames(
              session.usertype === "personal" || this.state.currentDateTab === 0
                ? "hidden"
                : "",
              "flex justify-center my-10"
            )}
          >
            <div className>
              <img
                loading="lazy"
                className={" h-8 w-8 cursor-pointer icon-hover-transition"}
                src={"/images2/common/arrow-left.svg"}
                onClick={(e) => this._handleNameChangeAndindex(e, "minus")}
              />
            </div>
            <div className="mt-1 w-40 text-center">
              {currentDateTab === 3 ? this.showYearMonth() : dateNameToShow}
            </div>
            <div>
              <img
                loading="lazy"
                className={" h-8 w-8  cursor-pointer icon-hover-transition"}
                src={"/images2/common/arrow-right.svg"}
                onClick={(e) => this._handleNameChangeAndindex(e, "plus")}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, isLoggedIn } = state;
  return {
    session,
    user,
    revenueDateTabs: state.revenueDateTabs,
    vendorDetailedRevenueStore: !_.isEmpty(state.vendorDetailedRevenueStore)
      ? !_.isEmpty(state.vendorDetailedRevenueStore.data)
        ? state.vendorDetailedRevenueStore.data
        : []
      : [],
    salesDateMonthsList: state.salesDateMonthsList,
  };
}

export default connect(mapStateToProps, {
  fetchVendorDetailedRevenue,
})(ReveneuDateTabs);
