import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import renderHTML from "react-render-html";

// Import Component
import CommonHeaderLegalDocs from "../commonheaderlegaldocs.js";
//Import action
import { fetchSettingsMaster } from "../../actions/index";
// Import BaseLoader
import BaseLoader from "@baseLoader";

class LegalDocsTermsNCons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_legal_docs,
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchSettingsMaster(this.props.session, (callback) => {
      this.setState({ loading: false });
    });
  }

  render() {
    let { settingsMasterStore, language } = this.props;

    let dataContent = "";

    if (!_.isEmpty(settingsMasterStore)) {
      dataContent =
        language === "en"
          ? settingsMasterStore.terms_conditions_en
          : settingsMasterStore.terms_conditions_ar;
    }

    return (
      <>
        {this.props.user.isfreeze == false ? (
          <div className="responsivePadding">
            <CommonHeaderLegalDocs
              history={this.props.history}
              commonHeaderText={LocaleStrings.legal_docs_list_text1}
            />
            <div className="policyHeader">
              <p className="text-xl font-medium">
                {LocaleStrings.legal_docs_list_text1}
              </p>
              <p className="font-normal">
                {LocaleStrings.legal_docs_list_subtext1}
              </p>
            </div>
            {this.state.loading ? (
              <>
                <BaseLoader data={this.state.loaderDesc} />
              </>
            ) : (
              <div className="relative p-10 bg-custWhite">
                {dataContent !== undefined ? renderHTML(dataContent) : null}
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    language: state.language,
    settingsMasterStore: state.settingsMasterStore
      ? state.settingsMasterStore.data !== "undefined"
        ? state.settingsMasterStore.data
        : []
      : [],
  };
}

export default connect(mapStateToProps, {
  fetchSettingsMaster,
})(LegalDocsTermsNCons);
