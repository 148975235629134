import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { currencyCode, currencyRate } from "@currencyActions";
import Pagination from "react-js-pagination";
// Import BaseLoader
import BaseLoader from "@baseLoader";

// Import Component
import TransactionHistoryRows from "./transaction-items";
// Import Actions
import { fetchTransactionHistory } from "@revenuereportsActions";
// Icons
import { SearchIcon } from "@heroicons/react/solid";

import { updateMRP, ProductTextLocalized } from "@commonFunction";

import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";
const limit = 10;
class TransactionHistoryTableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pageNumber: 1,
      loaderDesc: LocaleStrings.preparing_transaction_history,
      searchText: "",
    };

    // All Binded Functions
  }

  //Arrow function for renderLoader
  componentDidMount() {
    let { userid } = this.props;
    let { pageNumber } = this.state;

    /* 
      Fetch transaction history list [paginated]
      New Params : {foruserid:userid,"limit":<num of rows to show>,"page":curpage,type:"listing"}
    */
    this.props.fetchTransactionHistory(
      this.props.session,
      userid,
      limit,
      pageNumber,
      "listing",
      "",
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  paginationCallback = (pageNumber) => {
    let { userid } = this.props;

    this.props.fetchTransactionHistory(
      this.props.session,
      userid,
      limit,
      pageNumber,
      "listing",
      "",
      (CallBackResponse) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    let { userid } = this.props;
    var searchText = event.target.value;
    var pageNumber = 1;

    if (searchText === "") {
      this.setState({ loading: true });
      // Paginated list
      /* 
      Fetch transaction history list [paginated]
      New Params : {foruserid:userid,"limit":<num of rows to show>,"page":curpage,type:"listing"}
      */
      this.props.fetchTransactionHistory(
        this.props.session,
        userid,
        limit,
        pageNumber,
        "listing",
        "",
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    } else {
      /* 
      search list without pagination
      Params: {foruserid:userid,type:"search",searchterm:<search text>}
      */
      this.props.fetchTransactionHistory(
        this.props.session,
        userid,
        limit,
        pageNumber,
        "search",
        searchText,
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    }
    this.setState({ searchText, pageNumber });
  };

  transactionDetailsList() {
    let { transactionDetailsStore, language, localCurrency } = this.props;
    let pageDataLength = transactionDetailsStore.data.length;
    let { searchText, pageNumber } = this.state;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    var items = _.map(transactionDetailsStore.data, (values) => (
      <TransactionHistoryRows
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));

    return items;
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="">
              <label htmlFor="search-field" className="sr-only">
                {LocaleStrings.search_product}
              </label>
              <div className="relative w-full text-ternary focus-within:text-secondary ">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="revenue-report-search-field"
                  placeholder={LocaleStrings.search_by_transaction_id}
                  type="search"
                  name="revenuereports_product_search"
                  // onChange={(e) =>
                  //   this.setState({ searchText: e.target.value })
                  // }
                  value={this.state.searchText}
                  onChange={this.handleSearch}
                />
              </div>
            </div>
            {/* Table Seaction*/}
            <div className="w-full bottom-0 py-6">
              <div className="bg-custWhite rounded p-6 mb-10">
                {_.isEmpty(this.props.transactionDetailsStore.data) ? (
                  <div className="center-item lg:my-48 text-lg text-ternary">
                    {LocaleStrings.no_result_found}
                  </div>
                ) : (
                  <>
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                          >
                            {LocaleStrings.transaction_id}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                          >
                            {LocaleStrings.transaction_amount}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                          >
                            {LocaleStrings.transaction_product}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                          >
                            {LocaleStrings.transaction_type}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                          >
                            {LocaleStrings.transaction_status}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {this.transactionDetailsList()}
                      </tbody>
                    </table>
                    {this.state.searchText === "" ? (
                      <Pagination
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={limit}
                        totalItemsCount={parseInt(
                          this.props.transactionDetailsStore.count
                        )}
                        pageRangeDisplayed={limit}
                        onChange={this.paginationCallback}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
            {/* Table Section Ends*/}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, isLoggedIn, language, currency } = state;
  return {
    session,
    user,
    language,
    localCurrency: currency,
    transactionDetailsStore: _.isEmpty(state.transactionDetailsStore)
      ? {}
      : state.transactionDetailsStore,
  };
}

export default connect(mapStateToProps, { fetchTransactionHistory })(
  TransactionHistoryTableList
);
