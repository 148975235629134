import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import _ from "lodash";
import { SearchIcon } from "@heroicons/react/solid";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "react-js-pagination";
import SetupBlockerModal from "./setup-blocker-modal.js";
import { classNames } from "@commonFunction";
// Import BaseLoader
import BaseLoader from "@baseLoader";
import { APP_API_KEY } from "@constant";
// Import Actions
import {
  fetchProductList,
  fetchProductManagementDefaultSettings,
  fetchProductCategorylist,
  fetchGlobalSpecifications,
  openStoreSetupModal,
  checkShippingBearer,
  showUploadSection,
  setListAndGridToggle,
  getlistingpriceAmount,
  fetchCountryList,
  fetchContinentList,
  productbyidtoedit,
  tabListWithSelection,
} from "@productmanagementActions";
import {
  fetchUsersDeatils,
  fetchStoreDetails,
  fetchpersonalStoreDetails,
} from "@profileSettingsActions";

import { fetchsessionData } from "@mainActions";

// Component Import
import ProductsList from "./productslist";
import ProductsTableList from "./productstablelist";
import UploadProduct from "./uploadproduct";
import ProductDetails from "./productdetails/components/index";
import ProductFee from "./productfee";
import Productdiscount from "../components/addproductdiscount";
import ExcludeShipping from "./excludeshipping";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";
const limit = 10; // Table item per page
class ProductManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_product_management,
      showUploadSection: false,
      // sectionToShow: "VL",

      pageNumber: 1,
      searchText: "",
      isdraft: 0,
      params: {},
      sessionData: {},
      searching: false,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { pageNumber, searchText } = this.state;
    let { session } = this.props;

    var url_string = window.location.href;
    var query = url_string.split("?")[1];

    var params = {};

    if (query) {
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      this.setState({ params: params });
    }
    // debugger;
    // console.log("params", params);
    let sessionData = {
      sessionToken: params.session_token,
      apiKey: APP_API_KEY,
    };
    if (params.session_token) {
      this.props.fetchsessionData(params, (response) => {
        // console.log("response", response);
        sessionData.userid = response.data1.userdata.userid;
        this.setState({ sessionData: sessionData });
        if (
          response.success &&
          response.success != undefined &&
          response.success == 1
        ) {
          // debugger;
          this.props.fetchCountryList(sessionData, (callback) => {});
          this.props.fetchContinentList(sessionData, (callback) => {});

          this.props.fetchGlobalSpecifications(sessionData, (callback) => {
            /* Fetch global specification records */
          });

          // Get Product Default settings
          this.props.fetchProductManagementDefaultSettings(
            sessionData,
            (CallBackResponse) => {}
          );

          // Get Product List With session
          this.props.fetchProductList(
            sessionData,
            limit,
            pageNumber, // page
            this.state.searchText,
            this.state.isdraft,
            (CallBackResponse) => {}
          );

          // Get Product Category And Subcategory List
          this.props.fetchProductCategorylist(
            sessionData,
            (CallBackResponse) => {}
          );

          this.props.fetchUsersDeatils(sessionData, (CallBackResponse) => {});
          this.props.getlistingpriceAmount(
            sessionData,
            (CallBackResponse) => {}
          );

          if (params.type == "add") {
            let e = "";
            this.uploadProductToggle(e, "UP");

            setTimeout(
              function () {
                //Start the timer
                this.setState({ loading: false });
              }.bind(this),
              1000
            );
          } else {
            let e = "";
            this.props.productbyidtoedit(
              sessionData,
              params.productid,
              1,
              "",
              (callback) => {
                this.uploadProductToggle(e, "UP");
                setTimeout(
                  function () {
                    this.setState({ loading: false });
                  }.bind(this),
                  1000
                );
              }
            );
          }
        } else {
          this.props.history.push("/login");
        }
      });
    } else {
      this.props.fetchCountryList(session, (callback) => {});
      this.props.fetchContinentList(session, (callback) => {});

      this.props.fetchGlobalSpecifications(session, (callback) => {
        /* Fetch global specification records */
      });

      // Get Product Default settings
      this.props.fetchProductManagementDefaultSettings(
        this.props.session,
        (CallBackResponse) => {}
      );

      // Get Product List With session
      this.props.fetchProductList(
        this.props.session,
        limit,
        pageNumber, // page
        this.state.searchText,
        this.state.isdraft,
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );

      // Get Product Category And Subcategory List
      this.props.fetchProductCategorylist(
        this.props.session,
        (CallBackResponse) => {}
      );

      this.props.fetchUsersDeatils(
        this.props.session,
        (CallBackResponse) => {}
      );
      this.props.getlistingpriceAmount(session, (CallBackResponse) => {});
    }

    let newTabs = _.map(this.props.tabs, (value, index) => {
      if (value.tabindex === 1) {
        value.current = true;
      } else {
        value.current = false;
      }

      return value;
    });

    this.props.tabListWithSelection(newTabs);
  }

  componentWillUnmount() {
    /* Reinitializing all */
    let { session, toggleGridAndList } = this.props;
    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_product_management,
      showUploadSection: false,
      // sectionToShow: "VL",
      pageNumber: 1,
      searchText: "",
    };
    // if (this.state.sessionData?.sessionToken) {
    //   this.props.checkShippingBearer(
    //     this.state.sessionData,
    //     true,
    //     (callback) => {}
    //   );
    // } else if (session.sessionToken) {
    //   this.props.checkShippingBearer(session, true, (callback) => {});
    // }
  }

  // This Fc reinitialize all after add or edit
  backToParent() {
    let { pageNumber, searchText } = this.state;
    this.setState({ loading: true });

    this.props.showUploadSection("VL"); // this.setState({ sectionToShow: "VL" });
    // isShowUploadSection
    // Get Product Default settings
    this.props.fetchProductManagementDefaultSettings(
      this.props.session,
      (CallBackResponse) => {}
    );

    // Get Product Category And Subcategory List
    this.props.fetchProductCategorylist(
      this.props.session,
      (CallBackResponse) => {}
    );

    this.props.fetchUsersDeatils(this.props.session, (CallBackResponse) => {});

    // Get Product List With session
    this.props.fetchProductList(
      this.props.session,
      limit,
      pageNumber, // page
      this.state.searchText,
      this.state.isdraft,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  //Arrow function for
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  // Upload button click
  uploadProductToggle = (e, param) => {
    let { session } = this.props;

    if (
      param === "UP"
      // &&
      // (session.usertype === "vendor" || session.usertype === "homebiz")
    ) {
      /* 
        Blocker if store is not set for [home buiz & company] 
        check id the store is set if not show blocker and send to "Profile setting"
      */
      if (session.usertype === "vendor" || session.usertype === "homebiz") {
        this.props.fetchStoreDetails(session, (CallBackResponse) => {
          if (_.size(CallBackResponse.data) === 0) {
            /* ADD BLOCKER */
            /* Open Modal Store Setup */
            this.props.openStoreSetupModal(true);
          } else {
            /* Go noramal */
            this.props.showUploadSection(param); // this.setState({ sectionToShow: param });
          }
        });
      } else if (session.usertype === "personal") {
        this.props.fetchpersonalStoreDetails(session, (CallBackResponse) => {
          if (_.size(CallBackResponse.data) === 0) {
            /* ADD BLOCKER */
            /* Open Modal Store Setup */
            this.props.openStoreSetupModal(true);
          } else {
            /* Go noramal */
            this.props.showUploadSection(param); // this.setState({ sectionToShow: param });
          }
        });
      }
    } else {
      let { pageNumber, searchText } = this.state;
      this.props.showUploadSection(param); // this.setState({ sectionToShow: param });

      if (param === "VL") {
        // Came From delete Need Refresh

        this.setState({ loading: true });

        // Get Product List With session
        this.props.fetchProductList(
          this.props.session,
          limit,
          pageNumber, // page
          this.state.searchText,
          this.state.isdraft,
          (CallBackResponse) => {
            this.setState({ loading: false });
          }
        );
      }
    }

    /* Check if this user has shippingcostbearer option from shopez */

    if (this.state.sessionData?.sessionToken) {
      this.props.checkShippingBearer(
        this.state.sessionData,
        false,
        (callback) => {}
      );
    } else if (session.sessionToken) {
      this.props.checkShippingBearer(session, false, (callback) => {});
    }
  };

  // Toggle List And Grid Of Product Variants Listing
  listAndGridToggle = (e) => {
    let { toggleGridAndList } = this.props;

    this.props.setListAndGridToggle(!toggleGridAndList);
  };

  // Render Grid product list
  renderProductGridList() {
    return _.map(this.props.productList.data, (productObj, index) => {
      return (
        <ProductsList
          key={productObj.index}
          productObj={productObj}
          position={index}
          history={this.props.history}
          showUploadSection={this.uploadProductToggle}
        />
      );
    });
  }

  // render Product Variant List
  renderProductList() {
    return _.map(this.props.productList.data, (productObj, index) => {
      return (
        <ProductsTableList
          key={productObj.appuserid}
          productObj={productObj}
          position={index}
          history={this.props.history}
          showUploadSection={this.uploadProductToggle}
        />
      );
    });
  }

  setSearchTextToState(e) {
    let pageNumber = 1;

    let searchterm = e.target.value; // Get Value OnChange
    this.setState({ searchText: searchterm });

    if (_.isEmpty(searchterm)) {
      //List
      // this.setState({ loading: true });
      this.setState({ searching: true });
      this.props.fetchProductList(
        this.props.session,
        limit,
        pageNumber,
        "",
        this.state.isdraft,
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    } else {
      if (_.size(searchterm) > 2) {
        // Search
        this.props.fetchProductList(
          this.props.session,
          limit,
          pageNumber,
          searchterm,
          this.state.isdraft,
          (CallBackResponse) => {}
        );
      }
    }

    this.setState({ pageNumber });
  }

  paginationCallback = (pageNumber) => {
    let { searchText } = this.state;
    /*
      Product Listing Vendor
      => /vendor-datascript/fetchmyproducts  => POST
    */

    // Get Product List With session
    this.props.fetchProductList(
      this.props.session,
      limit,
      pageNumber, // page
      searchText,
      this.state.isdraft,
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );

    this.setState({ searchText, pageNumber });
  };

  _handletabChange = (e, isdraft) => {
    let selectedisdraft = isdraft;
    this.setState({ loading: true });
    this.props.fetchProductList(
      this.props.session,
      limit,
      this.state.pageNumber, // page
      // this.state.searchText,
      "",
      selectedisdraft,
      (CallBackResponse) => {
        this.setState({ isdraft: selectedisdraft });
        this.setState({ searchText: "" });
        this.setState({ searching: false });
        this.setState({ loading: false });
      }
    );
  };

  render() {
    let { productList, isShowUploadSection, toggleGridAndList, vendorDetails } =
      this.props;
    let isactive = true;

    if (vendorDetails.data && vendorDetails.data) {
      isactive = vendorDetails.data.isactive;
    }

    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>{this.renderLoader()}</>
            ) : (
              <>
                {isShowUploadSection === "VL" ? ( // List and grid
                  <>
                    <div className="py-6">
                      <div className="py-6  mx-auto w-96">
                        <div className="">
                          <span className="isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              onClick={(e) => this._handletabChange(e, "0")}
                              className={classNames(
                                this.state.isdraft == 0 ||
                                  this.state.calledfor == "0"
                                  ? " border-2 border-indigo-500"
                                  : "",
                                "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                              )}>
                              All Product
                            </button>
                            <button
                              onClick={(e) => this._handletabChange(e, "1")}
                              type="button"
                              className={classNames(
                                this.state.isdraft == 1 ||
                                  this.state.calledfor == "1"
                                  ? "border-indigo-500"
                                  : "",
                                "relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                              )}>
                              Drafted Products
                            </button>
                          </span>
                        </div>
                      </div>
                      {/* Header Part */}
                      <div className="grid gap-4 grid-cols-1 px-6 md:grid-cols-3 gap-3 sm:grid-cols-2 ">
                        {productList.count > 0 ||
                        this.state.searchText != "" ||
                        this.state.searching ? (
                          <div className="justify-items-center">
                            <label htmlFor="search-field" className="sr-only">
                              {LocaleStrings.search}
                            </label>
                            <div className="relative w-full text-ternary focus-within:text-secondary ">
                              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                                <SearchIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </div>
                              <input
                                id="search-field"
                                className="revenue-report-search-field"
                                placeholder={
                                  LocaleStrings.product_list_search_by
                                }
                                type="search"
                                name="search"
                                onChange={(e) => this.setSearchTextToState(e)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="justify-items-center"></div>
                        )}

                        <div align="center">
                          <span className="relative z-0 inline-flex shadow-sm rounded-md">
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-secondary hover:bg-quaternary  rounded-md"
                              onClick={(e) => this.listAndGridToggle(e)}>
                              <img
                                loading="lazy"
                                className="h-3 px-1"
                                src={`/images2/product_management_images/${
                                  toggleGridAndList ? "list" : "grid"
                                }.svg`}
                              />
                              {}
                              {toggleGridAndList
                                ? LocaleStrings.list
                                : LocaleStrings.grid}
                            </button>
                          </span>
                        </div>
                        <div>
                          {isactive == true ? (
                            <button
                              type="submit"
                              className="btn-primary"
                              onClick={(e) =>
                                this.uploadProductToggle(e, "UP")
                              }>
                              {LocaleStrings.upload_product}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="py-1">
                      {this.props.productList &&
                      this.props.productList.data &&
                      this.props.productList.data.length > 0 ? (
                        toggleGridAndList ? (
                          <div className="relative bg-quaternary pt-2 pb-20 px-4 sm:px-6 lg:pt-2 lg:pb-28 lg:px-8">
                            <div className="absolute inset-0">
                              <div className="bg-custWhite h-full" />
                            </div>
                            <div className="relative max-w-7xl mx-auto">
                              <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 lg:max-w-none">
                                {this.renderProductGridList()}
                              </div>
                              {this.state.searchText === "" &&
                              productList.count > 0 ? (
                                <Pagination
                                  activePage={this.state.pageNumber}
                                  itemsCountPerPage={limit}
                                  totalItemsCount={parseInt(productList.count)}
                                  pageRangeDisplayed={limit}
                                  onChange={this.paginationCallback}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="relative bg-quaternary pt-2 pb-20 px-4 sm:px-6 lg:pt-2 lg:pb-28 lg:px-8">
                            <div className="absolute inset-0">
                              <div className="bg-custWhite h-full" />
                            </div>

                            <div className="relative max-w-7xl mx-auto mt-10">
                              <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                  <div className="py-2 align-middle inline-block min-w-full ">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                      <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                          <tr>
                                            <th
                                              scope="col"
                                              className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider">
                                              {LocaleStrings.title}
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider">
                                              {LocaleStrings.pricing}
                                            </th>
                                            <th
                                              colSpan={2}
                                              scope="col"
                                              className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider">
                                              {LocaleStrings.status}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                          {this.renderProductList()}
                                        </tbody>
                                      </table>
                                      {this.state.searchText === "" &&
                                      productList.count > 0 ? (
                                        <Pagination
                                          activePage={this.state.pageNumber}
                                          itemsCountPerPage={limit}
                                          totalItemsCount={parseInt(
                                            productList.count
                                          )}
                                          pageRangeDisplayed={limit}
                                          onChange={this.paginationCallback}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className=" lg:my-48 text-lg text-ternary">
                          {this.state.searchText === "" ? (
                            <>
                              <div className="center-item">
                                <p>{LocaleStrings.no_product_header}</p>
                              </div>
                              <div className="center-item">
                                <p>{LocaleStrings.no_product_subheader}</p>
                              </div>
                            </>
                          ) : (
                            <div className="center-item">
                              <p>No search results found</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                ) : isShowUploadSection === "UP" ? ( // UPLOAD PRODUCTS
                  // UPLOAD
                  <>
                    <UploadProduct
                      showUploadSection={this.uploadProductToggle}
                      parentCallBack={this.backToParent.bind(this)}
                      fromApp={this.state.params.session_token ? true : false}
                    />
                  </>
                ) : isShowUploadSection === "PD" ? ( // PRODUCT DETAILS
                  // PRODUCT DETAILS
                  <>
                    <ProductDetails
                      showUploadSection={this.uploadProductToggle}
                      parentCallBack={this.backToParent.bind(this)}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            <Toaster />
            <ProductFee
              modalHeadText={LocaleStrings.product_fee}
              modalFooterText={LocaleStrings.confirm_btn_text}
            />
            {/* Add Discount Modal */}
            {this.props.isproductdiscountopen ? (
              <Productdiscount
                data={this.props.isproductdiscountopen}
                hedaer={LocaleStrings.product_discount}
              />
            ) : (
              ""
            )}
            {/* Exclude Shipping Modal */}
            {this.props.isExcludeshippingopen ? (
              <ExcludeShipping
                data={this.props.isExcludeshippingopen}
                hedaer={LocaleStrings.product_discount}
              />
            ) : (
              ""
            )}
            {/* Add Discount Modal */}
            {this.props.setupStoreBlockerModal ? <SetupBlockerModal /> : ""}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    productList: state.productList,
    defaultSettings: state.DefaultSettings,
    isproductdiscountopen: state.isProductDiscountopen,
    isExcludeshippingopen: state.isExcludeshippingopen,
    setupStoreBlockerModal: state.setupStoreBlockerModal,
    isShowUploadSection: state.isShowUploadSection,
    toggleGridAndList: state.toggleGridAndList,
    vendorDetails: state.vendorDetails,
    tabs: state.uploadProductsTabs,
  };
}

export default connect(mapStateToProps, {
  fetchProductManagementDefaultSettings,
  fetchProductList,
  fetchProductCategorylist,
  fetchUsersDeatils,
  fetchGlobalSpecifications, // Fetch global specificatin
  fetchStoreDetails,
  fetchpersonalStoreDetails,
  openStoreSetupModal,
  checkShippingBearer,
  showUploadSection,
  setListAndGridToggle,
  getlistingpriceAmount,
  fetchCountryList,
  fetchContinentList,
  fetchsessionData,
  productbyidtoedit,
  tabListWithSelection,
})(ProductManagement);
