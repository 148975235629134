import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import toast from "react-hot-toast";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { validateEmail } from "@constant";
// Import Actions
import {
  verifyEmail,
  checkVerificationStatus,
  reSentEmail,
  emailExistOrNot,
  changeEmail,
  logout,
  isVerficationModalOpen,
  verifyemailProfilesettings,
  updateEmailverified,
} from "@mainActions";
import { isPrflInfoVerifyEmailModalOpen } from "@profileSettingsActions";
import {} from "@mainActions";
// Functions Import
import { classNames } from "@commonFunction";

// Import Component
import Divider from "@divider";

/* Impport CSS */
import "@starterCss";

class VerifyEmail extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      emailid: "",
      btnresendLoader: false,
      btnresendisBtnDisable: false,
      seconds: 60,
    };
    this.starttimer = this.starttimer.bind(this);
    // All Binded Functions
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      /* This will check get [emailverified & phoneverified] column value from "users" table */
      this.props.checkVerificationStatus(this.props.session, (callBackRes) => {
        if (callBackRes.data[0].emailverified) {
          this.props.changeStep(2);
        }
      });
    }, 10000);
  }

  starttimer() {
    this.interval1 = setInterval(() => {
      this.setState((prevState) => ({
        seconds: prevState.seconds - 1,
      }));
    }, 1000);
  }

  /* Resent email */
  reSendEmail = () => {
    let { session } = this.props;
    /*
    Sent Veriofication link to registerd email address
    */
    this.setState({ btnresendLoader: true });
    // this.setState({ btnresendisBtnDisable: true });
    this.props.reSentEmail(session, (callBack) => {
      if (callBack.status === 1) {
        toast.success(LocaleStrings.resent_email_alert_text);
        this.setState({ btnresendLoader: false });
        this.setState({ btnresendisBtnDisable: true });
        this.starttimer();
        setTimeout(() => {
          this.setState({ btnresendisBtnDisable: false });
          this.setState({ seconds: 60 });
          clearInterval(this.interval1);
        }, 60000);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    var id = window.setTimeout(function () {}, 0);

    while (id--) {
      window.clearTimeout(id); // will do nothing if no timeout with id is present
    }

    let { session } = this.props;
    let actualEmail = this.props.user.email;

    let updateobj = {
      email: actualEmail,
      emailverified: 1,
      userid: this.props.session.userid,
    };

    this.setState({ btnLoader: true, isBtnDisable: true }); // Start The button Loader

    this.props.updateEmailverified(session, updateobj, (callBack) => {
      if (callBack.status === 1) {
        toast.success(LocaleStrings.verification_email_note_text4);
        this.setState({
          btnLoader: false,
        });
        this.setState({
          isBtnDisable: false,
        });

        this.props.isVerficationModalOpen(false);
      } else {
        toast.error(LocaleStrings.something_went_wrong);
        this.setState({
          btnLoader: false,
          isBtnDisable: false,
        });
      }
    });

    /* Check supplied email is alraedy exist or not for df */
    // this.props.emailExistOrNot(session, values.email, (callBack) => {
    //   if (callBack.status === 1) {
    //     if (callBack.data > 0) {
    //       toast.error(LocaleStrings.email_exist_alert_text);
    //       this.setState({
    //         btnLoader: false,
    //         isBtnDisable: false,
    //       });
    //     } else {
    //       /* Actual work area
    //         Update and send link to new email / update new email
    //       */
    //       this.props.changeEmail(session, values.email, (callBack) => {
    //         if (callBack.status === 1) {
    //           toast.success(LocaleStrings.verification_email_note_text4);
    //           this.setState({
    //             btnLoader: false,
    //             isBtnDisable: false,
    //           });
    //           this.props.isVerficationModalOpen(false);
    //           this.props.logout(session);
    //         } else {
    //           toast.error(LocaleStrings.something_went_wrong);
    //           this.setState({
    //             btnLoader: false,
    //             isBtnDisable: false,
    //           });
    //         }
    //       });
    //     }
    //   } else {
    //     toast.error(LocaleStrings.something_went_wrong);
    //     this.setState({
    //       btnLoader: false,
    //       isBtnDisable: false,
    //     });
    //   }
    // });

    // this.props.verifyEmail(this.props.session, valueObj, (res) => {
    //   this.setState({ btnLoader: false }); // End The button Loader
    //   this.setState({ isBtnDisable: false }); // End The button Loader

    //   this.props.changeStep(2);
    // });
  }

  showVerifyEmailProflInfoModal = () => {
    // Trigger modal open
    this.props.verifyemailProfilesettings(true);
    this.props.isPrflInfoVerifyEmailModalOpen(true);
  };

  render() {
    let { handleSubmit, session } = this.props;
    // console.log("session", this.props.session);
    let { emailid } = this.state;
    let modalFooterText = LocaleStrings.verify;
    let emailIdToShow = emailid !== "" ? emailid : session.emailid;

    return (
      <>
        <form
          className=" "
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
          {/* MOdal Header Ends*/}
          <div className="p-6  bg-primary">
            <p className="text-custWhite text-lg font-medium">
              {LocaleStrings.verification_header_text1}
            </p>
            <p className="text-custWhite text-xs">
              {LocaleStrings.verification_subheader_text1}
            </p>
          </div>
          {/* In order for us to activate your account */}
          <div>
            <p className="px-6 py-6">
              <span>{LocaleStrings.verification_email_note_text1}</span>
              <span className="text-primary" id="verify_email_address_id">
                {emailIdToShow}
              </span>
              <span>{LocaleStrings.verification_email_note_text2}</span>
            </p>
          </div>
          {/* Haven't received the email? Resend */}
          <div className="">
            <div className="flex">
              <p className="px-6 py-2">
                <span
                  className="verify-email-resend"
                  // onClick={(e) => this.reSendEmail()}
                >
                  {LocaleStrings.verification_email_resend_btn}
                </span>
              </p>
              <button
                type="button"
                disabled={this.state.btnresendisBtnDisable}
                className={classNames(
                  this.state.btnresendisBtnDisable
                    ? "cursor-not-allowed opacity-50"
                    : "",
                  "btn-primary ml-3"
                )}
                onClick={(e) => this.reSendEmail()}
                style={{ width: "auto" }}>
                <svg
                  className={classNames(
                    this.state.btnresendLoader ? "" : "sr-only",
                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                  )}
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
                {this.state.btnresendisBtnDisable ? (
                  <span>Resend in {this.state.seconds} second(s)</span>
                ) : (
                  "Resend"
                )}
              </button>
            </div>
            <p className="px-6 ">
              <span className="text-primary">{LocaleStrings.or}</span>
            </p>
            <p className="px-6 py-2">
              <span
                className="verify-email-resend"
                onClick={(e) => this.props.logout(session)}>
                {LocaleStrings.button_logout}
              </span>
            </p>
          </div>
          {/* Divider */}
          <div className="px-6 py-6">
            <Divider />
          </div>
          {/* You have enter the wrong email id? You can
              can change the email now 
          */}
          <div>
            <p className="px-6 py-6">
              <span>{LocaleStrings.verification_email_note_text3}</span>
            </p>
          </div>
          <div
            onClick={(e) => this.showVerifyEmailProflInfoModal()}
            className="mx-6">
            <button className="btn-primary" type="button">
              Update Email
            </button>
          </div>
          {/* <div className="space-y-6 pt-2 pb-4 px-6">
            <Field
              name="email"
              label={LocaleStrings.email_address}
              placeholder={LocaleStrings.email_address}
              type="text"
              component={this.renderFieldText}
              mandatory="true"
              labelposition={LABEL_POSITION_TOP}
              classNameLabel="text-sm font-medium text-secondary"
            />
          </div> */}

          <div className="p-6 border-t-2 border-quaternary">
            {/* Footer */}
            <button
              type="submit"
              disabled={this.state.isBtnDisable}
              className={classNames(
                this.state.isBtnDisable ? "cursor-not-allowed" : "",
                "btn-primary"
              )}>
              <svg
                className={classNames(
                  this.state.btnLoader ? "" : "sr-only",
                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                )}
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true">
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
              {LocaleStrings.verify}
            </button>
          </div>
        </form>
        {/* SKIP BUTTON 
          Note:- Email is none manadetory feild so vendor can skip this part directly go to 
        */}
        <div
          className="p-6 border-t-2 border-quaternary"
          onClick={(e) => this.props.skipEmailVerification()}>
          {/* Footer */}
          <button type="submit" className={"btn-primary"}>
            {LocaleStrings.skip}
          </button>
        </div>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var email = values["email"];
  var requiredFields = ["email"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  if (email && !validateEmail(email)) {
    errors["email"] = LocaleStrings.invalid_email;
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  verifyEmail,
  checkVerificationStatus,
  reSentEmail,
  emailExistOrNot,
  changeEmail,
  logout,
  isVerficationModalOpen,
  verifyemailProfilesettings,
  isPrflInfoVerifyEmailModalOpen,
  updateEmailverified,
})(
  reduxForm({
    validate,
    form: "verifyEmailForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(VerifyEmail)
);
