import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import _ from "lodash";

// Import Components
import BaseLoader from "@baseLoader";
import { itemCount } from "@constant";

// Import Actions
import { selectedProfileSettingsMenu } from "@profileSettingsActions";

// Common Function
import { classNames } from "@commonFunction";

class ProfileSettingsList extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  /**** On Profile Menu Select ****/
  onProfileMenuSelect(curProfileSelect) {
    this.props.selectedProfileSettingsMenu(
      this.props.session,
      curProfileSelect
    );
  }

  /**** Render Profile settings Div List *****/
  renderList() {
    let { profileSettingsList, session, user } = this.props;

    let newArr;
    if (user.isfreeze) {
      newArr = _.filter(this.props.profileSettingsList, function (o) {
        return o.position === 2;
      });
    } else {
      newArr = this.props.profileSettingsList;
    }

    var item = _.map(newArr, (valObj, index) => {
      let position = valObj.position;
      return (
        <li
          key={index}
          className={classNames(
            session.usertype === "personal" &&
              (position === 1 ||
                position === 2 ||
                position === 3 ||
                position === 5)
              ? "hidden"
              : (session.usertype === "homebiz" ||
                  session.usertype === "vendor") &&
                position === 6
              ? "hidden"
              : "",
            "py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
          )}
          onClick={(e) => this.onProfileMenuSelect(valObj.position)}>
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <img
                loading="lazy"
                className="h-8 w-8"
                src={"/images2/profile_settings_images/" + valObj.icon + ".svg"}
                alt=""
              />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-secondary truncate mx-2">
                {valObj.name}
              </p>
              <p className="text-sm text-ternary truncate mx-2">
                {valObj.subname}
              </p>
            </div>
            <div>
              <img
                loading="lazy"
                className="h-7 transform rotate-180 px-2"
                src={"/images2/profile_settings_images/arrow.svg"}
              />
            </div>
          </div>
        </li>
      );
    });

    return item;
  }

  render() {
    return (
      <>
        <div className=" xs:px-2 sm:px-10 2xl:px-48">
          <div className="flow-root mt-6">
            <ul role="list" className="-my-5 divide-y divide-gray-200">
              {this.renderList()}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    profileSettingsList: state.profileSettingsList,
  };
}

export default connect(mapStateToProps, {
  selectedProfileSettingsMenu,
})(ProfileSettingsList);
