import {
  SELECTED_SIDEBAR_MENU,
  IS_NOTIFICATION_SLIDEOVERS_MODAL_OPEN,
  NOTIFICATION_COUNT,
  NOTIFICATION_DETAILS_LIST,
  MESSAGING_DETAILS_LIST,
  IS_MESSAGING_SLIDEOVERS_OPEN,
  THREAD_MESSAGE,
  ORDER_THREAD_MESSAGES,
  SINGLE_NOTIFICATION_OBJECT,
  NOTIFICATION_DETAILS,
  SELECTED_MESSAGE_TAB,
  ORDER_MESSAGING_DETAILS_LIST,
  ORDER_MESSAGING_STEP,
  ORDER_THREAD_BASICDATA,
} from "@sidebarActions";
import LocaleStrings from "@language";

// Initial selected order step is "1"
export var currentOrderStep = (state = 1, action) => {
  if (action.type === ORDER_MESSAGING_STEP) {
    state = action.payload;
  }
  return state;
};

// Initial selected message tab is "Product"
export var selectedMessageTab = (state = "product", action) => {
  if (action.type === SELECTED_MESSAGE_TAB) {
    state = action.payload;
  }
  return state;
};

// Initial select tab is "SELECTED_SIDEBAR_MENU"
export var SidebarSelectedMenu = (state = 1, action) => {
  if (action.type === SELECTED_SIDEBAR_MENU) {
    state = action.payload;
  }
  return state;
};

export var SideBarMenus = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      name: LocaleStrings.sales_management,
      href: "salesmanagement",
      icon: "sales_management",
      current: true,
      position: 1,
    },
    {
      name: LocaleStrings.profile_settings,
      href: "profilesettings",
      icon: "profile-setting",
      current: false,
      position: 2,
    },
    {
      name: LocaleStrings.language,
      href: "language",
      icon: "language",
      current: false,
      position: 3,
    },
    // {
    //   name: LocaleStrings.currency,
    //   href: "currency",
    //   icon: "currency",
    //   current: false,
    //   position: 4,
    // },
    {
      name: LocaleStrings.product_management,
      href: "productmanagement",
      icon: "product_management",
      current: false,
      position: 5,
    },
    {
      name: LocaleStrings.revenue_reports,
      href: "revenuereports",
      icon: "revenue_reports",
      current: false,
      position: 6,
    },
    {
      name: LocaleStrings.coupons_management,
      href: "couponsmanagement",
      icon: "mycoupons",
      current: false,
      position: 7,
    },
    {
      name: LocaleStrings.discounts_management,
      href: "discountsmanagement",
      icon: "discounts_management",
      current: false,
      position: 8,
    },
    // {
    //   name: LocaleStrings.ratings_reviews,
    //   href: "ratingsreviews",
    //   icon: "ratings_reviews",
    //   current: false,
    //   position: 9,
    // },
    {
      name: LocaleStrings.faq_support,
      href: "faqsupport",
      icon: "faq",
      current: false,
      position: 10,
    },
    {
      name: LocaleStrings.legal_docs,
      href: "legaldocs",
      icon: "legal-docs",
      current: false,
      position: 11,
    },
    {
      name: LocaleStrings.app_suggestions,
      href: "appsuggestions",
      icon: "app-suggestion",
      current: false,
      position: 12,
    },
  ];

  return state;
};

// export default (SidebarSelectedMenu,SideBarMenus);

//NOTIFICATION
/**** NOTIFICATION COUNT ****/

export var appcounters = (state = false, action) => {
  if (action.type === NOTIFICATION_COUNT) {
    state = action.payload;
  }

  return state;
};

/**** Add NOTIFICATION SLIDE-OVERS Modal storage ****/
export var isNotificationOpen = (state = false, action) => {
  if (action.type === IS_NOTIFICATION_SLIDEOVERS_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** NOTIFICATION DETAILS LIST ****/
export var notificationList = (state = {}, action) => {
  if (action.type === NOTIFICATION_DETAILS_LIST) {
    state = action.payload;
  }

  return state;
};

/**** SELECTED NOTIFICATION DETAILS LIST ****/
export var notificationSingleObjectStore = (state = {}, action) => {
  if (action.type === SINGLE_NOTIFICATION_OBJECT) {
    state = action.payload;
  }

  return state;
};

/**** NOTIFICATION DETAILS BY [NOTIFICATION ID] ****/
export var notificationDetailsStore = (state = {}, action) => {
  if (action.type === NOTIFICATION_DETAILS) {
    state = action.payload;
  }

  return state;
};

// MESSAGES
export var messageList = (state = {}, action) => {
  if (action.type === MESSAGING_DETAILS_LIST) {
    state = action.payload;
  }

  return state;
};

// ORDER MESSAGES
export var orderMessageList = (state = {}, action) => {
  if (action.type === ORDER_MESSAGING_DETAILS_LIST) {
    state = action.payload;
  }

  return state;
};

/**** Add MESSAGES SLIDE-OVERS Modal storage ****/
export var isMessageOpen = (state = false, action) => {
  if (action.type === IS_MESSAGING_SLIDEOVERS_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** Storage Of Thread Messages With Thread id ****/
export var storeMessageList = (state = {}, action) => {
  if (action.type === THREAD_MESSAGE) {
    state = action.payload;
  }

  return state;
};

/**** Storage Of Order Thread Messages With Thread id ****/
export var storeOrdMessageList = (state = {}, action) => {
  if (action.type === ORDER_THREAD_MESSAGES) {
    state = action.payload;
  }
  return state;
};

/**** Storage Some basic order thread data [Order id and all as object ] ****/
export var basicOrderThreadData = (state = {}, action) => {
  if (action.type === ORDER_THREAD_BASICDATA) {
    state = action.payload;
  }
  return state;
};
