import React, { Component } from "react";
import {
  EditorState,
  ContentState,
  RichUtils,
  //convertToRaw
} from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import { BASE_INLINE_UPLOAD_BASE_URL } from "./constants";
import axiosFileupload from "axios-fileupload";
import { connect } from "react-redux";
// import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

var initialized = false;

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    // here we create the empty state
    var editorState = EditorState.createEmpty();
    // if the redux-form field has a value
    if (props.input.value.length > 0) {
      const blocksFromHtml = htmlToDraft(props.input.value);
      const contentState = ContentState.createFromBlockArray(blocksFromHtml);
      editorState = EditorState.createWithContent(contentState);
    }

    this.state = { editorState: editorState };

    this.onChange = (editorState) => {
      //var { input } = this.props;
      // converting to the raw JSON on change
      //let html = draftToHtml(convertToRaw(editorState.getCurrentContent())); //stateToHTML(editorState.getCurrentContent());

      // Vinay (This below line is commented as because with this aboute content menu is getting one issue)
      //input.onChange(html);

      // Set it on the state
      this.setState({ editorState });
    };
    /*this.focus = () => this.refs.editor.focus();
    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);*/

    initialized = true;
  }

  _handleKeyCommand(command) {
    var { editorState } = this.state;
    var newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    var maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  componentWillReceiveProps(newProps) {
    if (initialized && newProps.input.value.length > 0) {
      const blocksFromHtml = htmlToDraft(newProps.input.value);
      const contentState = ContentState.createFromBlockArray(blocksFromHtml);
      const editorNewState = EditorState.createWithContent(contentState);
      this.setState({ editorState: editorNewState });
    }
  }

  handleEditorChange = (e) => {};

  render() {
    var { editorState } = this.state;

    var { input } = this.props;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    /*let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }*/
    //'fontFamily''colorPicker','fontSize','fontFamily'
    /*
    ,
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    }
    */
    var tooloptions = {};
    var height = 0;
    if (this.props.type === "minimal") {
      height = 120;
      tooloptions = {
        options: ["inline", "fontFamily"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
      };
    } else if (this.props.type === "webview-editor") {
      height = 342;
      tooloptions = {
        options: ["inline", "fontFamily"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
      };
    } else {
      height = 354;
      tooloptions = {
        options: ["inline", "fontSize", "fontFamily", "link", "colorPicker"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        image: {
          uploadCallback: uploadImageCallBack,
          alt: { present: true, mandatory: false },
          inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          urlEnabled: true,
          uploadEnabled: true,
          defaultSize: {
            height: "auto",
            width: "auto",
          },
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
      };
    }

    return (
      <div className="rdw-storybook-root">
        <Editor
          {...input}
          spellCheck
          placeholder={input.placeholder}
          ref="editor"
          editorState={editorState}
          toolbarClassName="rdw-storybook-toolbar"
          wrapperClassName="rdw-storybook-wrapper"
          editorClassName="rdw-storybook-editor"
          onEditorStateChange={this.onChange}
          editorStyle={{
            height: height,
            background: "white",
            paddingLeft: 10,
            paddingRight: 10,
          }}
          toolbar={tooloptions}
          stripPastedStyles={true}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

export default connect(mapStateToProps, { axiosFileupload })(RichTextEditor);

// Custom overrides for "code" style.
/*var styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: 'Lato-Regular',
    fontSize: 16,
    padding: 2,
  },
};*/

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    axiosFileupload(BASE_INLINE_UPLOAD_BASE_URL, file)
      .then(({ data }) => {
        resolve({ data: { link: data } });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
