import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { ProductTextLocalized, timeAgo, classNames } from "@commonFunction";
import renderHTML from "react-render-html";
// Import Actions
import {
  isNotificationModalOpen,
  fetchNotificationDetails,
  markallread,
  markRead,
  isSendMessageModalOpen,
  NotificationSingleObject,
  NotificationDeatils,
  onSidebarMenuSelected,
} from "../actions/index";
// Import Action [SALES MANAGEMENT]
import {
  salesmanagementStatboxSelected,
  isNegotiationOpen,
  fetchNegotiationDetails,
} from "@salesmanagementActions";
// Import Action [PRODUCT MANAGEMENT]
import {
  isCommentsNotificModalOpen,
  isQasNotificModalOpen,
} from "@productmanagementActions";

// Import BaseLoader
import BaseLoader from "@baseLoader";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";

import NoContentSlideOver from "../../../../common/no-content-slideover"; // Common No Content For Slide Over Modals
import ViewComments1 from "../../productmanagement/components/productdetails/components/viewcomments1"; // Import Component From [Product Deatils Page To Show Mension Comments]
import QaWithOutLimit1 from "../../productmanagement/components/productdetails/components/qawithoutlimit1"; // Import Component From [Product Deatils Page To Show Mension Comments]

const notificationArr = [
  { name: "New Message", values: "newmessage" },
  { name: "General", values: "general" },
  { name: "Negotiation", values: "negotiation" },
  { name: "Bid", values: "bid" },
  { name: "Mention", values: "mention" },
  { name: "Following", values: "following" },
  { name: "Order Update", values: "orderupdate" },
  { name: "Top Deal", values: "topdeal" },
  { name: "Invitation", values: "invitation" },
  { name: "Violation", values: "violation" },
  { name: "Offers", values: "offers" },
  { name: "Auction Update", values: "auctionupdate" },
  { name: "Interests", values: "interests" },
  { name: "Question", values: "question" },
  { name: "Answer", values: "answer" },
  { name: "Admin", values: "admin" },
  { name: "In Stock", values: "instock" },
  { name: "Bid Cancelled", values: "cancelbid" },
  { name: "Order Not Processed", values: "order_not_processed" },
  { name: "Wonbids Not Purchased", values: "wonbids_not_purchased" },
  { name: "Approved Product", values: "approvedproduct" },
  { name: "Approved User", values: "approveduser" },
  { name: "Update Vendor", values: "updatevendor" },
  { name: "Update Tax", values: "updatetax" },
  { name: "Make Featured", values: "makefeatured" },
  { name: "Make Discount", values: "makediscount" },
  { name: "Ban Product", values: "banproduct" },
  { name: "Product Rejected", values: "productrejected" },
];
// 'newmessage','general','negotiation','bid','mention','following','orderupdate','topdeal','invitation','violation','offers',
// 'auctionupdate','interests','question','answer','admin','discounts','instock','cancelbid','order_not_processed','wonbids_not_purchased','approvedproduct',
// 'approveduser','updatevendor','updatetax','makefeatured','makediscount','banproduct','productrejected'

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_notifications,
    };

    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      setInterval(() => {
        if (this.props.isNotificationOpen) {
          this.props.fetchNotificationDetails(
            this.props.session,
            (callBack) => {
              this.setState({ loading: false });
            }
          );
        }
      }, 3000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.isNotificationModalOpen(false);
  }

  /*** Handel Redirect Notification ***/
  _handelRedirectNotification = (e, obj) => {
    let { session } = this.props;
    this.props.NotificationSingleObject(obj);

    this.props.markRead(this.props.session, obj.notificationid, (res) => {
      /* It will make this notification as read with particular id */
    });

    this.props.NotificationDeatils(
      this.props.session,
      obj.notificationid,
      (callBack) => {
        // Call to get notification Details

        if (
          obj.notifications_by_notificationid.notificationtype === "negotiation"
        ) {
          this.closeSlideOvers(); // Close SlideOver
          this.props.onSidebarMenuSelected(1); // Select side menu
          if (
            this.props.history.location.pathname !==
            "/dashboard/salesmanagement/salesnegotiation"
          ) {
            this.props.history.push(
              "/dashboard/salesmanagement/salesnegotiation"
            ); // Redirect to its page
          } else {
            /* If already in negotiation page call */

            this.props.fetchNegotiationDetails(
              session,
              obj.notifications_by_notificationid.negotiationthreadid,
              (callBack) => {
                this.props.isNegotiationOpen(true);
              }
            );
          }
        } else if (
          obj.notifications_by_notificationid.notificationtype === "newmessage"
        ) {
          this.closeSlideOvers(); // Close SlideOver
          this.props.isSendMessageModalOpen(true);
          let threadid = obj.notifications_by_notificationid.threadid;
        } else if (
          obj.notifications_by_notificationid.notificationtype === "mention"
        ) {
          this.closeSlideOvers(); // Close SlideOver
          this.props.isCommentsNotificModalOpen(true);
        } else if (
          obj.notifications_by_notificationid.notificationtype === "question" ||
          obj.notifications_by_notificationid.notificationtype === "answer"
        ) {
          toast.error(LocaleStrings.feature_under_construction);
          // this.closeSlideOvers(); // Close SlideOver

          // if (
          //   this.props.history.location.pathname !==
          //   "/dashboard/productmanagement"
          // ) {
          //   this.props.history.push("/dashboard/productmanagement"); // Redirect to its page
          // }
          // this.props.onSidebarMenuSelected(5);
          // this.props.isQasNotificModalOpen(true);
        } else if (
          obj.notifications_by_notificationid.notificationtype === "admin"
        ) {
        } else {
          toast.error(LocaleStrings.feature_under_construction);
        }
      }
    );
  };

  // Arrow Function for Notification List
  renderNotificationList = (notificationList) => {
    let { language } = this.props;
    var item = _.map(notificationList, (valObj, index) => {
      let desc = ProductTextLocalized(
        valObj.notifications_by_notificationid.notificationtext_en,
        valObj.notifications_by_notificationid.notificationtext_ar,
        language
      );
      let title = "";
      if (
        valObj?.notifications_by_notificationid?.notificationtype == "admin"
      ) {
        title = ProductTextLocalized(
          valObj.notifications_by_notificationid?.notificationtitle_en,
          valObj.notifications_by_notificationid?.notificationtitle_en,
          language
        );
      }
      let replaced_title = "";
      if (valObj?.notifications_by_notificationid?.notificationtype) {
        let filteredArr = _.filter(notificationArr, (o) => {
          return (
            o.values ==
            valObj?.notifications_by_notificationid?.notificationtype
          );
        });
        if (filteredArr.length > 0) {
          replaced_title = filteredArr[0]["name"];
        }
      }

      // replaced_title = title.replace(/_/g, " ");

      return (
        <li
          key={index}
          className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
          onClick={(e) => this._handelRedirectNotification(e, valObj)}>
          <div className="flex items-center space-x-4">
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-secondary  capitalize">
                {replaced_title}
              </p>
              <div>
                <p className="text-sm text-ternary ">{title}</p>
              </div>
              <div className="flex">
                <p className="text-sm text-ternary ">
                  {desc != "" && desc != null ? (
                    <div>{renderHTML(desc)}</div>
                  ) : (
                    ""
                  )}
                </p>
                <div
                  className={classNames(
                    valObj.isread === false ? "" : "hidden",
                    "flex-shrink-0 w-2.5 h-2.5 rounded-full bg-yellow-500 mx-2 mt-1.5"
                  )}
                  aria-hidden="true"></div>
              </div>
            </div>
            <div className="flex-shrink-0 text-sm text-ternary">
              {timeAgo(valObj.notifications_by_notificationid.createdat)}
            </div>
          </div>
        </li>
      );
    });

    return item;
  };

  //**** Mark Read All Notification ****/
  _handelReadAllNotification(e) {
    let { session } = this.props;
    this.props.markallread(session, (callBack) => {
      toast.success(LocaleStrings.mark_all_messages_as_read);
    });
  }

  render() {
    let { isNotificationOpen, language, notificationList } = this.props;
    let { loading } = this.state;

    return (
      <>
        <Transition.Root show={isNotificationOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            open={isNotificationOpen}
            onClose={this.closeSlideOvers}>
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              {LocaleStrings.notifications}
                              <span
                                className="mx-2 cursor-pointer"
                                onClick={(e) =>
                                  this._handelReadAllNotification(e)
                                }>
                                ({LocaleStrings.read_all})
                              </span>
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                className="btn-closeModal"
                                onClick={this.closeSlideOvers}>
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 relative flex-1 px-4 sm:px-6">
                          <ul>
                            {!loading ? (
                              <>
                                {_.isEmpty(notificationList) ? (
                                  <NoContentSlideOver
                                    imageprop={
                                      "/images2/navbar/notification.svg"
                                    }
                                    headerprop={
                                      LocaleStrings.no_notification_header
                                    }
                                    subheaderprop={
                                      LocaleStrings.no_notification_subheader
                                    }
                                  />
                                ) : (
                                  this.renderNotificationList(notificationList)
                                )}
                              </>
                            ) : (
                              <BaseLoader data={this.state.loaderDesc} />
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="flex-shrink-0 px-4 py-4 flex justify-end"></div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* View Comments */}
        {this.props.isCommentsNotificOpen ? (
          <>
            <ViewComments1
              productIDNoti={
                this.props.notificationDetailsStore.commentdetails.productid
              }
            />
          </>
        ) : (
          ""
        )}
        {/* Quation and answer 
          - Below condition -
          qadata.productid -> Quation
          product.id -> Answer
        */}
        {this.props.isQaNotificOpen ? (
          <>
            <QaWithOutLimit1
              productID={
                this.props.notificationDetailsStore?.qadata?.productid !==
                undefined
                  ? this.props.notificationDetailsStore.qadata.productid
                  : this.props.notificationDetailsStore.product.id
              }
            />
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isNotificationOpen: state.isNotificationOpen,
    isCommentsNotificOpen: state.isCommentsNotificOpen,
    isQaNotificOpen: state.isQaNotificOpen,
    notificationList: state.notificationList
      ? state.notificationList.data
        ? state.notificationList.data
        : {}
      : {},
    notificationDetailsStore: state.notificationDetailsStore
      ? state.notificationDetailsStore.data
        ? state.notificationDetailsStore.data
        : []
      : [],
    notifiSingleObjectStore: _.isEmpty(state.notifiSingleObjectStore)
      ? []
      : state.notifiSingleObjectStore,
  };
}

export default connect(mapStateToProps, {
  isNotificationModalOpen,
  fetchNotificationDetails,
  markallread,
  markRead,
  salesmanagementStatboxSelected,
  isSendMessageModalOpen,
  NotificationSingleObject,
  NotificationDeatils,
  isCommentsNotificModalOpen,
  isQasNotificModalOpen,
  onSidebarMenuSelected,
  isNegotiationOpen,
  fetchNegotiationDetails,
})(Notification);
