import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from "moment-timezone";
import LocaleStrings from "@language";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import renderHTML from "react-render-html";
//Import Component
import LoginRegisterCommonHeader from "./login-register-commonheader";
// Functions Import
import {
  classNames,
  removePhoneNoSpecialChar,
  isValidQatarPhoneNumber,
} from "@commonFunction";

// Import constant
import {
  validateEmail,
  DATE_FORMAT,
  PERSONAL_USER_REDIRECT_TO,
  OTHERS_USER_REDIRECT_TO,
  MIN_DATE_FORMAT,
  validatepassword,
} from "@constant";

import {
  mainLogin,
  mainRegister,
  resetStore,
  socialSignupAvailable,
  phoneValidate,
  sendEmail,
  validatebusiness,
  countryPhoneLength,
} from "@mainActions";

var btype = "";
class Register extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      isContinue: false,
      selectedType: "vendor",
      genderOptions: [
        {
          name: "Male",
          value: "male",
          index: 1,
        },
        { name: "Female", value: "female", index: 2 },
        { name: "Rather Not Say", value: "rather not say", index: 0 },
      ],
      currencyOptions: [
        {
          name: LocaleStrings.qatari_riyal,
          value: "QAR",
          index: 0,
        },
        { name: LocaleStrings.dollar, value: "USD", index: 1 },
      ],
      show: false,
      show1: false,
      country_id: "",
      isTncClicked: false, // T&C button is clicked or not
      isEmailaCopyClicked: false, // T&C Email me a copy buttom
    };

    // All Bind Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      isContinue: false,
      selectedType: "vendor",
      genderOptions: [
        {
          name: "Male",
          value: "male",
          index: 1,
        },
        { name: "Female", value: "female", index: 2 },
        { name: "Rather Not Say", value: "rather not say", index: 0 },
      ],
      currencyOptions: [
        {
          name: LocaleStrings.qatari_riyal,
          value: "QAR",
          index: 0,
        },
        { name: LocaleStrings.dollar, value: "USD", index: 1 },
      ],
      show: false,
      show1: false,
      country_id: "",
    });
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      isContinue: false,
      selectedType: "vendor",
      genderOptions: [
        {
          name: "Male",
          value: "male",
          index: 1,
        },
        { name: "Female", value: "female", index: 2 },
        { name: "Rather Not Say", value: "rather not say", index: 0 },
      ],
      currencyOptions: [
        {
          name: LocaleStrings.qatari_riyal,
          value: "QAR",
          index: 0,
        },
        { name: LocaleStrings.dollar, value: "USD", index: 1 },
      ],
      show: false,
      show1: false,
      country_id: "",
      isTncClicked: false, // T&C button is clicked or not
      isEmailaCopyClicked: false, // T&C Email me a copy buttom
    });
  }

  changeShow() {
    let { show } = this.state;
    this.setState({ show: !show });
  }

  changeShow1() {
    let { show1 } = this.state;
    this.setState({ show1: !show1 });
  }

  phoneChange = (value, country, e, formattedValue) => {
    if (!_.isEmpty(formattedValue)) {
      let country_code_arr = _.split(formattedValue, " ", 1); // Get First array index
      country_code_arr = _.trim(country_code_arr, "+"); // Trim +
      this.setState({ country_id: country_code_arr });
      this.props.countryPhoneLength(country.dialCode);
    }
  };

  /* FORM SUBMIT */
  onFormSubmit(values) {
    let { session } = this.props;
    let { usertype } = session;
    let { selectedType, country_id, isTncClicked, isEmailaCopyClicked } =
      this.state;

    this.setState({ isBtnDisable: true });
    this.setState({ btnLoader: true });

    // TNC check
    if (!isTncClicked) {
      toast.error(LocaleStrings.please_accept_tnc);
      this.setState({ isBtnDisable: false });
      this.setState({ btnLoader: false });
      return false;
    }

    // If Not match Both Password
    if (_.trim(values.new_password) !== _.trim(values.password)) {
      toast.error(LocaleStrings.password_dont_match);
      this.setState({ isBtnDisable: false });
      this.setState({ btnLoader: false });
      return false;
    }

    values.phone = removePhoneNoSpecialChar(values.phone); // This function will remove special chars from phone number

    /* Check is busness name is unique or not */
    this.props.validatebusiness(session, values["businessname"], (callback) => {
      if (callback.status === 1) {
        // Check businesss name is available or not
        if (callback.data.available === 0) {
          toast.error(
            selectedType === "personal"
              ? LocaleStrings.nick_name_not_available
              : LocaleStrings.business_name_not_available
          );

          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });
          return false;
        } else {
          // Play ground is yours [Feel free to code for next part]

          /* Check phone number is valide or not from DB */
          this.props.phoneValidate(session, values["phone"], (callBack) => {
            if (callBack.data.result === "success") {
              /* Check phone number is valide or not */
              if (callBack.data.phoneexist === 1) {
                toast.error(LocaleStrings.not_a_valid_phone_number);
                this.setState({ isBtnDisable: false });
                this.setState({ btnLoader: false });
                return false;
              } else {
                values["usertype"] = selectedType;
                values["businesstype"] =
                  selectedType === "homebiz"
                    ? "home"
                    : selectedType === "personal"
                    ? "personal"
                    : "company";

                values["isvendortoo"] = 1;
                values["country_id"] = country_id;

                let name_arr = _.split(values["name"], " ");

                if (values.gender === "rather not say") {
                  /*
                    emum ->male / female only
                  */
                  values.gender = "";
                }

                if (name_arr.length > 1) {
                  values["first_name"] = name_arr[0];
                  values["last_name"] = name_arr[1];
                } else {
                  values["first_name"] = values["name"];
                  values["last_name"] = "";
                }

                if (selectedType === "personal") {
                  values["dob"] = moment(values.validity_from).format(
                    DATE_FORMAT
                  );
                }

                // If Email Entery Already vendor
                this.props.socialSignupAvailable(values.email, (response) => {
                  if (response.success === 1) {
                    toast.error(LocaleStrings.account_exist_alert_text);
                    this.props.history.push("/login");
                    this.setState({ isBtnDisable: false });
                    this.setState({ btnLoader: false });
                    return false;
                  } else {
                    let tncObject = {
                      emailtype: "emailterm",
                      email: values.email,
                    };

                    /* Mail a copy of tnc template  */
                    if (isEmailaCopyClicked) {
                      this.props.sendEmail(
                        this.props.session,
                        tncObject,
                        (callBack) => {
                          if (
                            callBack.status === 1 ||
                            callBack.status === true
                          ) {
                            toast.success(callBack.message);
                          } else {
                            toast.error(callBack.message);
                          }
                        }
                      );
                    }

                    // If not registered already

                    this.props.mainRegister(
                      values,
                      this.props.session,
                      (response) => {
                        if (response.success === 1) {
                          let newValues = {
                            email: values["email"],
                            password: values["new_password"],
                          };

                          // Then Go For Login
                          this.props.mainLogin(
                            newValues,
                            this.props.session,
                            (response) => {
                              if (response.success === 0) {
                                toast.error(
                                  LocaleStrings.invalid_email_password
                                );
                                this.props.resetStore();
                              } else {
                                toast.success(LocaleStrings.login_success);
                                this.props.history.push(
                                  usertype === "personal"
                                    ? PERSONAL_USER_REDIRECT_TO
                                    : OTHERS_USER_REDIRECT_TO
                                );
                              }
                            }
                          );
                        } else {
                          // If Un successfull Registration
                          toast.error(LocaleStrings.something_went_wrong);
                          this.setState({ isBtnDisable: false });
                          this.setState({ btnLoader: false });
                          return false;
                        }
                      }
                    );
                  } // Else End
                }); // Avilibility checkes end
              }
            } else {
              toast.error(LocaleStrings.common_fail_message);
              this.setState({ isBtnDisable: false });
              this.setState({ btnLoader: false });
              return false;
            }
          });
        }
      } else {
        toast.error(LocaleStrings.common_fail_message);
        this.setState({ isBtnDisable: false });
        this.setState({ btnLoader: false });
        return false;
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.showModal) {
    //   this.setState({ ...this.state, loading: false });
    // }
  }

  setBusinessType(e, param) {
    this.setState({ selectedType: param });
    btype = param;
  }

  _handelContinueBtn(e) {
    this.setState({ isContinue: true });
  }

  /* Handel tnc button */
  _handelTncBtn = (e) => {
    this.setState({ isTncClicked: e.target.checked }); // Set tnc check box value
  };

  /* Email a copy */
  _handelTncEmailaCopyBtn = (e) => {
    this.setState({ isEmailaCopyClicked: e.target.checked }); // Set tnc check box value
  };

  render() {
    var { handleSubmit, btnLoading, language, phoneLengthCountry } = this.props;
    var { selectedType, isContinue, isTncClicked } = this.state;
    return (
      <>
        <LoginRegisterCommonHeader history={this.props.history} />

        <div className="p-4 xl:p-12">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-4 px-4 text-center sm:px-6 lg:px-8 ">
              <div className="space-y-8 sm:space-y-12">
                <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                  <h2 className="text-secondary font-normal text-3xl">
                    {LocaleStrings.identif_your_business}
                  </h2>
                  <p className="text-hint font-normal text-ternary">
                    {LocaleStrings.password_reset_link_text}
                  </p>
                </div>
                <ul
                  role="list"
                  className="mx-auto grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-3">
                  <li
                    key="key-vendor"
                    onClick={(e) => this.setBusinessType(e, "vendor")}>
                    <div className="space-y-4 cursor-pointer">
                      {/* <img loading="lazy" className="mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24" src="" alt="vendor" /> */}
                      <div
                        className={classNames(
                          selectedType === "vendor" ? " border-primary " : "",
                          "border-2 bg-custWhite relative mx-auto sm:h-24 sm:w-24 xs:h-20 xs:w-20 rounded-full"
                        )}>
                        <img
                          loading="lazy"
                          className="absolute sm:h-8 sm:w-8 xs:h-7 xs:w-7 sm:top-8 xs:top-6  sm:left-7 xs:left-6"
                          src={`/images2/common/${
                            selectedType === "vendor"
                              ? "company_1"
                              : "company_2"
                          }.svg`}
                          alt="vendor_items"
                        />
                      </div>
                      <div className="space-y-2">
                        <div className="text-xs font-medium lg:text-sm">
                          <h3>{LocaleStrings.company}</h3>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    key="key-homebiz_business"
                    onClick={(e) => this.setBusinessType(e, "homebiz")}>
                    <div className="space-y-4 cursor-pointer">
                      <div
                        className={classNames(
                          selectedType === "homebiz" ? " border-primary " : "",
                          "border-2 bg-custWhite relative mx-auto sm:h-24 sm:w-24 xs:h-20 xs:w-20 rounded-full"
                        )}>
                        <img
                          loading="lazy"
                          className="absolute sm:h-8 sm:w-8 xs:h-7 xs:w-7 sm:top-8 xs:top-6  sm:left-7 xs:left-6"
                          src={`/images2/common/${
                            selectedType === "homebiz" ? "home_1" : "home_2"
                          }.svg`}
                          alt="personal_items"
                        />
                      </div>
                      <div className="space-y-2">
                        <div className="text-xs font-medium lg:text-sm">
                          <h3>{LocaleStrings.home_business}</h3>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    key="key-personal-item"
                    onClick={(e) => this.setBusinessType(e, "personal")}>
                    <div className="space-y-4 cursor-pointer">
                      <div
                        className={classNames(
                          selectedType === "personal" ? " border-primary " : "",
                          "border-2 bg-custWhite relative mx-auto sm:h-24 sm:w-24 xs:h-20 xs:w-20 rounded-full"
                        )}>
                        <img
                          loading="lazy"
                          className="absolute sm:h-8 sm:w-8 xs:h-7 xs:w-7 sm:top-8 xs:top-6  sm:left-7 xs:left-6"
                          src={`/images2/common/${
                            selectedType === "personal"
                              ? "personal_1"
                              : "personal_2"
                          }.svg`}
                          alt="personal_items"
                        />
                      </div>
                      <div className="space-y-2">
                        <div className="text-xs font-medium lg:text-sm">
                          <h3>{LocaleStrings.personal_items}</h3>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Signup Form */}
            <div className="bg-white shadow mt-8 sm:mx-auto p-4 sm:w-full sm:max-w-md">
              <form
                className="space-y-6"
                onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                <div className="mb-12">
                  <div className="text-secondary font-normal text-3xl">
                    {LocaleStrings.signup}
                  </div>
                  <div className="text-hint font-normal text-ternary">
                    {LocaleStrings.signup_sub_header_text}
                  </div>
                </div>

                <Field
                  name="email"
                  label={LocaleStrings.email_address}
                  placeholder={LocaleStrings.email_address}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />

                <Field
                  name="name"
                  label={LocaleStrings.name}
                  placeholder={LocaleStrings.name}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />

                <Field
                  name="businessname"
                  label={
                    selectedType !== "personal"
                      ? LocaleStrings.business_name
                      : LocaleStrings.nick_name
                  }
                  placeholder={
                    selectedType !== "personal"
                      ? LocaleStrings.business_name
                      : LocaleStrings.nick_name
                  }
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
                {selectedType === "personal" ? (
                  <Field
                    name="dob"
                    label={LocaleStrings.date_of_birth}
                    placeholder={LocaleStrings.date_of_birth}
                    component={this.renderDatePickerWithLabel}
                    mandatory="true"
                    maxDate={
                      new Date(
                        moment().subtract(216, "months").format(MIN_DATE_FORMAT)
                      )
                    }
                    // selected={moment()}
                    className="cust-input-field"
                    classNameLabel="text-box-lable"
                  />
                ) : (
                  ""
                )}

                <Field
                  name={"phone"}
                  label={LocaleStrings.phone_number}
                  mandatory="true"
                  phoneChange={this.phoneChange}
                  type="text"
                  component={this.renderPhonewithFlag}
                  countryCodeEditable={false}
                  className="cust-input-field"
                  classNameLabel="text-box-lable"
                />

                <Field
                  name="password"
                  label={LocaleStrings.password}
                  placeholder={LocaleStrings.password}
                  type="password"
                  component={this.renderFieldText}
                  mandatory="true"
                  show={this.state.show}
                  setShow={this.changeShow}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                  className="cust-pr-20"
                />

                <Field
                  name="new_password"
                  label={LocaleStrings.confirm_password}
                  placeholder={LocaleStrings.confirm_password}
                  type="password"
                  component={this.renderFieldText}
                  mandatory="true"
                  show={this.state.show1}
                  setShow={this.changeShow1}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                  className="cust-pr-20"
                />
                {/* Gender */}
                <div className="mb-5">
                  <p>{LocaleStrings.gender} *</p>
                  {_.map(this.state.genderOptions, (opt, index) => {
                    return (
                      <Field
                        name="gender"
                        label={
                          opt.value === "male"
                            ? LocaleStrings.male
                            : opt.value === "female"
                            ? LocaleStrings.female
                            : LocaleStrings.rather_not_say
                        }
                        value={opt.value}
                        className="text-sm font-medium text-primary"
                        classNameLabel="text-sm font-medium text-secondary mx-3"
                        lebelAlign="R"
                        type="radio"
                        component={this.renderFieldRadio1}
                      />
                    );
                  })}
                </div>
                {/* Language of Preference */}
                <div className="mb-5">
                  <p>{LocaleStrings.language_of_preference} *</p>

                  <Field
                    name="languagechoice"
                    label={LocaleStrings.arabic}
                    value={"ar"}
                    className="text-sm font-medium text-primary"
                    classNameLabel="text-sm font-medium text-secondary mx-3"
                    lebelAlign="R"
                    type="radio"
                    mandatory={"true"}
                    component={this.renderFieldRadio1}
                  />
                  <Field
                    name="languagechoice"
                    label={LocaleStrings.english}
                    value={"en"}
                    className="text-sm font-medium text-primary"
                    classNameLabel="text-sm font-medium text-secondary mx-3"
                    lebelAlign="R"
                    type="radio"
                    mandatory={"true"}
                    component={this.renderFieldRadio1}
                  />
                </div>
                {/* Currency of Preference */}
                <div className="mb-5">
                  <p>{LocaleStrings.currency_of_preference}</p>
                  <div className="text-danger text-xs h-8 w-56 bg-blue-200 py-2 px-4 rounded-lg ">
                    {" "}
                    * {LocaleStrings.currency_of_preference_note}
                  </div>
                  {_.map(this.state.currencyOptions, (opt, index) => {
                    return (
                      <Field
                        name="currencychoice"
                        label={
                          opt.value === "QAR"
                            ? LocaleStrings.qatari_riyal
                            : opt.value === "USD"
                            ? LocaleStrings.dollar
                            : "-"
                        }
                        value={opt.value}
                        className="text-sm font-medium text-primary"
                        classNameLabel="text-sm font-medium text-secondary mx-3"
                        lebelAlign="R"
                        type="radio"
                        mandatory={"true"}
                        component={this.renderFieldRadio1}
                      />
                    );
                  })}
                </div>
                {/* I Accept terms and conditions */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-primary focus:ring-custWhite border-gray-300 rounded"
                      onClick={(e) => this._handelTncBtn(e)}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-primary underline">
                      <a
                        href="http://shopez.staging.teks.co.in/legaldocs/termsncons?origin=vendor"
                        target="_blank">
                        {LocaleStrings.legal_docs_accept_tnc_text}
                      </a>
                    </label>
                  </div>

                  <div className="text-sm"></div>
                </div>

                {/* Email me a copy of Terms & Conditions */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="email-me-a-copy"
                      name="email-me-a-copy"
                      type="checkbox"
                      className="h-4 w-4 text-primary focus:ring-custWhite border-gray-300 rounded"
                      onClick={(e) => this._handelTncEmailaCopyBtn(e)}
                    />
                    <label
                      htmlFor="email-me-a-copy"
                      className="ml-2 block text-sm text-primary ">
                      {LocaleStrings.tnc_email_me_a_copy}
                    </label>
                  </div>

                  <div className="text-sm"></div>
                </div>

                <div className="flex items-center justify-between">
                  {/* FORGOT PASSWORD BUTTON */}
                  <div className="text-sm"></div>
                </div>
                {/* Submit Button */}
                <div className="grid grid-cols-3 gap-4 mt-8">
                  <div className="col-start-2 col-span-1 ...">
                    <button
                      type="submit"
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? "cursor-not-allowed" : "",
                        "btn-primary"
                      )}>
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.signup}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Registration Section */}
        </div>

        <Toaster />
      </>
    );
  }
}

function validate(values, ownProps) {
  var errors = {};
  var requiredFields = [];
  var email = values["email"];
  var phone = values["phone"];
  var password = values["password"];

  if (btype !== "personal") {
    requiredFields = [
      "email",
      "businessname",
      "name",
      "password",
      "new_password",
      "gender",
      "currencychoice",
      "languagechoice",
      "phone",
    ];
  } else {
    requiredFields = [
      "email",
      "businessname",
      "name",
      "password",
      "new_password",
      "gender",
      "currencychoice",
      "languagechoice",
      "phone",
      // "dob",
    ];
  }
  let countrycode = ownProps.phoneLengthCountry;

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  if (email && !validateEmail(email)) {
    errors["email"] = "Invalid email";
  }

  let passwordErrorMessage =
    '<div style="width: 350px; display: table;margin-top:10px;"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One lowercase character</div><div style="display: table-cell;">&#8226; One upper case</div></div></div><div style="width: 350px; display: table;margin-top:10px"><div style="display: table-row"><div style=" display: table-cell;">&#8226; 8 character minimum</div><div style="display: table-cell;"> &#8226; 20 characters maximum</div></div></div><div style="width: 350px; display: table;margin-top:10px;"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One special character</div> <div style=" display: table-cell;">&#8226; Atleast one number</div>';
  if (password && !validatepassword(password)) {
    errors["password"] = renderHTML(passwordErrorMessage);
  }
  if (countrycode == "974" && values.phone) {
    let phone = values.phone.replace(/[- )( +]/g, "");
    if (isValidQatarPhoneNumber("+" + phone) == false) {
      errors.phone = "Please enter valid phone number";
    }
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  let initval = {
    gender: "rather not say",
    currencychoice: "QAR",
    languagechoice: "ar",
  };
  return {
    session,
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    language: state.language,
    phoneLengthCountry: state.phoneLengthCountry,
    initialValues: initval,
  };
}

// export default reduxForm({
//   validate,
//   form: "registerForm",
//   enableReinitialize: true,
//   keepDirtyOnReinitialize: true,
//   initialValues: {
//     gender: "rather not say",
//     currencychoice: "QAR",
//     languagechoice: "ar",
//   },
// })(
//   connect(mapStateToProps, {
//  mainLogin,
// resetStore,
// socialSignupAvailable,
// mainRegister,
// phoneValidate,
// sendEmail,
// validatebusiness,
//   })(Register)
// );

export default connect(mapStateToProps, {
  mainLogin,
  resetStore,
  socialSignupAvailable,
  mainRegister,
  phoneValidate,
  sendEmail,
  validatebusiness,
  countryPhoneLength,
})(
  reduxForm({
    validate,
    form: "registerForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Register)
);
