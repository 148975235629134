import {
  CURRENCY_RATE,
  CURRENCY_RATE_USD,
  CURRENCY_RATE_QR,
  CURRENCY_USD,
  CURRENCY_QR,
} from "@constant";

export function currencyRate(currency) {
  let returnText = currency == "QAR" ? CURRENCY_RATE_QR : CURRENCY_RATE_USD;
  return returnText;
}

export function currencyRate2(usercurrency, productcurrency) {
  let returnText = 1;
  if (usercurrency === productcurrency) {
    returnText = 1;
  } else {
    returnText =
      productcurrency == "QAR" ? CURRENCY_RATE_QR : CURRENCY_RATE_USD;
  }

  return returnText;
}

export function currencyCode(currency) {
  let returnText = currency == "QAR" ? CURRENCY_QR : CURRENCY_USD;
  return returnText;
}

/* 
If user currency and product currency dont match 
return currencyrate other waise no need to convert currency value so return 1
*/
export function isCurrencyRateApplicable(
  userCurrency,
  productCurrency,
  amount
) {
  amount = parseFloat(amount);

  let newAmount = 0;
  if (userCurrency !== productCurrency) {
    if (userCurrency === "USD" && productCurrency === "QAR") {
      newAmount = amount / CURRENCY_RATE_QR;
    } else {
      newAmount = amount * CURRENCY_RATE_QR;
      // newAmount = amount / CURRENCY_RATE_USD; //old code
    }
  } else {
    newAmount = amount;
  }

  return newAmount;
}
