import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import { classNames } from "@commonFunction";
/*** IMPORT ACTIONS ***/
import { resetStore, localeLanguage } from "@mainActions";
/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
/* Import Component */
import CurrencySelect from "./currency-select";

class LoginRegisterCommonHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Bind Functions
  }

  // Toggle Beetween Sign In and Create new account
  toggleSignInAadUp(e, requestFor) {
    let parts = this.props.history.location.pathname.substring(11).split("/");
    let answer = parts[0];

    if (answer === requestFor) {
      // Don't Do anything [Call For same page]
    } else {
      this.props.history.push("/" + requestFor);
    }
  }

  // On Language Dropdown Change
  _handelLanguage(e, lang) {
    let { session } = this.props;
    this.props.localeLanguage(lang, session);
  }

  /** Language Drop Down Component **/
  renderLanguageDropDown() {
    let { language } = this.props;
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2  text-sm font-medium text-custWhite uppercase">
            {language}
            <ChevronDownIcon className="mx-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-custWhite">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-quaternary text-primary" : "text-secondary",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={(e) => this._handelLanguage(e, "en")}
                  >
                    {LocaleStrings.signup_header_english}
                  </span>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-quaternary text-primary" : "text-secondary",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={(e) => this._handelLanguage(e, "ar")}
                  >
                    عربي
                  </span>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  render() {
    let currentPath = this.props.history.location.pathname;
    return (
      <div
        className={classNames(this.props.language === "en" ? "" : "rtl", "")}
      >
        <header className="bg-primary min-h-64 sm:px-6 xs:px-0">
          <nav
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
            aria-label="Top"
          >
            <div className="w-full flex items-center justify-between border-b border-primary lg:border-none">
              <div className="flex items-center gap-4">
                <a href="#">
                  <span className="sr-only">Shopez-Vendor-Web</span>
                  <img
                    loading="lazy"
                    className="h-4 w-auto"
                    src="/images2/common/english_white.svg"
                    alt="image"
                  />
                </a>
                {/* Language Drop Down Component */}
                {this.renderLanguageDropDown()}
                {/* 
                  Note :- As logout we don't need currency preference
                <CurrencySelect /> */}
              </div>
              <div className="flex">
                {/* Sign In Button */}
                <span
                  className={classNames(
                    currentPath === "/register" ? "sr-only" : "",
                    "text-custWhite flex items-center px-2 py-2 text-sm rounded-md cursor-pointer"
                  )}
                  onClick={(e) => this.toggleSignInAadUp(e, "register")}
                >
                  <img
                    loading="lazy"
                    className="sidebar-icons"
                    src="/images2/common/signup.svg"
                  />
                  <span className="">{LocaleStrings.signup_as_vendor}</span>
                </span>
                {/* Sign Up Button */}
                <span
                  className={classNames(
                    currentPath === "/login" || currentPath === "/"
                      ? "sr-only"
                      : "",
                    "text-custWhite flex items-center px-2 py-2 text-sm rounded-md cursor-pointer"
                  )}
                  onClick={(e) => this.toggleSignInAadUp(e, "login")}
                >
                  <img
                    loading="lazy"
                    className="sidebar-icons"
                    src="/images2/common/signin-white.svg"
                  />
                  <span className="">{LocaleStrings.signin}</span>
                </span>
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  language: state.language,
});

export default connect(mapStateToProps, {
  resetStore,
  localeLanguage,
})(LoginRegisterCommonHeader);
