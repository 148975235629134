import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";

import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";

// Import Actions
import {
  isQaModalOpen,
  fetchCommentList,
  questionanswer,
  isCommentsNotificModalOpen,
  productComments,
  deletComment, // Delete a comment with id
  updateComment, // Update comment with id
} from "@productmanagementActions";

// Import From [ SideBard ]
import {
  NotificationSingleObject,
  makeNotificationDetailsEmpty,
} from "@sidebarActions";

// Component Import

// Common Fc
import { classNames, timeAgo, replaceTags } from "@commonFunction";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, DotsHorizontalIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";

import NoContentSlideOver from "../../../../../../common/no-content-slideover.js"; // Common No Content For Slide Over Modals

class ViewComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_product_comments,
      giveAnswer: false,
      qaid: "",
      comment: "",
      commentId: null,
    };
    this.myRef = React.createRef();
    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);

    this.processQueue = false;
    this.intervalUserList = null;
  }

  componentDidMount() {
    let { session, productIDNoti } = this.props;

    this.intervalUserList = setInterval(() => {
      this.props.fetchCommentList(session, productIDNoti, (callback) => {
        this.setState({ loading: false });
      });

      if (this.props.isCommentsNotificOpen) this.processQueue = true;
      else this.processQueue = false;

      if (!this.processQueue) {
        this.clearInterval();
      }
    }, 3000);
  }

  componentWillUnmount() {
    this.clearInterval();
    this.setState({ commentId: null });
  }

  clearInterval = () => {
    clearInterval(this.intervalUserList);
    this.intervalUserList = null;
    this.processQueue = false;
  };

  //Arrow function for renderLoader
  renderLoader = () => {
    return (
      <NoContentSlideOver
        imageprop={"/SVG/Cart.svg"}
        headerprop={LocaleStrings.no_comment_header}
        subheaderprop={LocaleStrings.no_comment_yet}
      />
    );
  };

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.NotificationSingleObject({}); // When Close Modal Blank Already selected Notification Object
    this.props.makeNotificationDetailsEmpty({}); // When Close Modal Blank Already selected Notification Object
    this.props.isCommentsNotificModalOpen(false);

    this.setState({ commentId: null });
  }

  replaceTags = (comment, tags) => {
    let tagsArr = tags.split(",");
    let returnText = comment;
    _.map(tagsArr, (item, index) => {
      let sent1 = "<a href='http://" + parseInt(item) + "'>";
      if (returnText.indexOf(sent1) >= 0) {
        returnText = returnText.replace(sent1, "@");
        returnText = returnText.replace("</a>", "");
      }
    });
    let finalArr = returnText.split(" ");

    return finalArr;
  };

  /**** Edit or delete comment *****/
  editOrDeleteComment = (commentObj, action, open) => {
    let { session } = this.props;
    if (action === "edit") {
      // Edit comment function
      //let value = this.replaceTags(commentObj.comment, commentObj.tags);
      let value = commentObj.comment;
      this.setState({ comment: value, commentId: commentObj.id });
      document.getElementById("comment_of_question_input").value = value;
    } else if (action === "delete") {
      let commentId = commentObj.id;
      // Delete comment function
      this.props.deletComment(session, commentId, (res) => {
        if (res.success === 1 || res.success === true) {
          toast.success(LocaleStrings.commnet_deleted_successfully);
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
      return false;
    }
  };

  // // Render Comment List
  renderCommentList = (e) => {
    let { commentList, session } = this.props;

    let item = _.map(
      _.orderBy(commentList, ["updatedat"], ["desc"]),
      (commentObj, index) => {
        var productImageUrl = `${BASE_IMAGES_URL}/users/${commentObj.commentby.userid}.jpg?api_key=${APP_API_KEY}`;

        return (
          <li
            key={index}
            className={"py-4 bg-custWhite px-4  table-hover-transition"}
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  loading="lazy"
                  className="user-img-rounded"
                  src={productImageUrl}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images2/common/user.png";
                  }}
                  alt="Image"
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-secondary truncate mx-2">
                  {commentObj.commentby.username}
                </p>
                <p className="text-sm  truncate">
                  {_.map(
                    replaceTags(commentObj.comment, commentObj.tags),
                    (obj, pos) => {
                      return obj.indexOf("@") >= 0 ? (
                        <span className="text-primary pr-1">{obj}</span>
                      ) : (
                        <span className="text-ternary pr-1">{obj}</span>
                      );
                    }
                  )}
                </p>
              </div>
              <div className="text-ternary text-xs">
                {/*  
                    --- Don't delete the code we may reqiire it later ----
                    <span className="p-2">{timeAgo(commentObj.createdat)}</span> 
                 */}
                {/* Abuse report on Comment */}
                <Menu as="div" className="relative inline-block text-left p-2">
                  {({ open }) => (
                    <>
                      <div className="">
                        <Menu.Button className="btn-card">
                          <span className="sr-only">Open options</span>
                          <DotsHorizontalIcon
                            className="h-5 w-5 hover:shadow"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-148 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                        >
                          <div className="py-1 divide-y z-50 w-40">
                            <Menu.Item>
                              {({ active }) => (
                                /* Report as abuse */
                                <>
                                  {parseInt(commentObj.commentby.userid) ===
                                  parseInt(session.userid) ? (
                                    <>
                                      {" "}
                                      <a
                                        summary="Edit a comment"
                                        onClick={() =>
                                          this.editOrDeleteComment(
                                            commentObj.id,
                                            "edit"
                                          )
                                        }
                                        className={classNames(
                                          active ? "bg-quaternary " : " ",
                                          "block px-4 py-2 text-sm text-primary icon-hover-transition"
                                        )}
                                      >
                                        {LocaleStrings.edit}
                                      </a>
                                      <a
                                        summary="Delete a comment"
                                        onClick={() =>
                                          this.editOrDeleteComment(
                                            commentObj.id,
                                            "delete"
                                          )
                                        }
                                        className={classNames(
                                          active ? "bg-quaternary " : " ",
                                          "block px-4 py-2 text-sm text-primary icon-hover-transition"
                                        )}
                                      >
                                        {LocaleStrings.delete}
                                      </a>
                                    </>
                                  ) : (
                                    <a
                                      onClick={
                                        () =>
                                          commentObj.reportabuse === 1
                                            ? toast.error(
                                                LocaleStrings.already_reported
                                              )
                                            : this.reportAbusive(commentObj.id) // report on Question id
                                      }
                                      className={classNames(
                                        active ? "bg-quaternary " : " ",
                                        "block px-4 py-2 text-sm text-primary icon-hover-transition"
                                      )}
                                    >
                                      {LocaleStrings.report_as_abusive}
                                    </a>
                                  )}
                                </>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </li>
        );
      }
    );

    return item;
  };

  /**** Answer Related  ****/

  // Select Question To answer And show "Type your Answer Input Box"
  selectedQuestionForAnswer = (e, commentObj) => {};

  /*** Close COMMENT Footer***/
  closeAnswerSection() {
    this.setState({ giveAnswer: false });
    this.setState({ qaid: "" });
  }

  /**** Comment Change ****/
  _handelCommentChange = (e) => {
    let inputVal = e.target.value;
    this.setState({ comment: inputVal });
  };

  /**** Submit Comment ****/
  _submitComment = (e) => {
    this.setState({ loading: true });

    let { comment, commentId } = this.state;
    let { session, productIDNoti } = this.props;

    if (_.trim(comment) !== null && _.trim(comment) !== "") {
      if (commentId !== null) {
        let object = {
          commentby: session.userid,
          productid: productIDNoti,
          tags: "",
          comment: comment,
        };

        /* Edit commnet */
        let id = parseInt(commentId);
        this.props.updateComment(session, object, id, (res) => {
          if (res.success == 1) {
            toast.success(LocaleStrings.your_comment_updated_successfully);
            document.getElementById("comment_of_question_input").value = ""; // Blank the input box value
            this.setState({ comment: "", commentId: null }); // Initialize state
            this.props.fetchCommentList(session, productIDNoti, (callback) => {
              this.setState({ loading: false });
            });
          } else {
            toast.error(LocaleStrings.common_fail_message);
            this.setState({ loading: false });
          }
        });
      } else {
        // Add commnet
        // Submit comment
        let object = {
          commentby: session.userid,
          productid: productIDNoti,
          tags: "",
          comment: comment,
        };
        // let body = {product: object};

        this.props.productComments(session, object, (callback) => {
          toast.success(LocaleStrings.your_comment_submitted_successfully);
          //this.closeAnswerSection();
          document.getElementById("comment_of_question_input").value = "";
          this.props.fetchCommentList(session, productIDNoti, (callback) => {
            this.setState({ loading: false });
          });
        });
      }
    } else {
      toast.error(LocaleStrings.type_your_comment);
    }
  };

  render() {
    let { isCommentsNotificOpen, language, commentList, session } = this.props;
    let customDirectionstyle =
      language === "ar" ? { direction: "rtl" } : { direction: "ltl" };
    return (
      <>
        <Transition.Root show={isCommentsNotificOpen} as={Fragment}>
          <Dialog
            initialFocus={this.myRef}
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            open={isCommentsNotificOpen}
            onClose={this.closeSlideOvers}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              {LocaleStrings.mentions}
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                className="btn-closeModal"
                                onClick={this.closeSlideOvers}
                                ref={this.myRef}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="mt-6 relative flex-1 px-4 sm:px-6">
                          {Object.keys(commentList).length > 0 ? (
                            <>
                              <ul
                                role="list"
                                className="-my-5 divide-y divide-gray-200 mt-2 mb-2"
                              >
                                {this.renderCommentList()}
                              </ul>
                            </>
                          ) : (
                            <>{this.renderLoader()}</>
                          )}
                        </div>
                      </div>
                      <div className="flex-shrink-0 px-4 py-4  justify-center">
                        {/* Question Section */}
                        <div style={customDirectionstyle}>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              name="comment_of_question_input"
                              id="comment_of_question_input"
                              className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-secondary px-4 rounded-full"
                              placeholder=""
                              onChange={(e) => this._handelCommentChange(e)}
                            />
                            <div
                              className={classNames(
                                language === "ar" ? "left-0" : "right-0",
                                "absolute inset-y-0  px-3 flex items-center"
                              )}
                            >
                              <img
                                loading="lazy"
                                src="/images2/common/send_btn.svg"
                                className="w-5 cursor-pointer"
                                onClick={(e) => this._submitComment()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isCommentsNotificOpen: state.isCommentsNotificOpen,
    commentList: !_.isEmpty(state.commentList)
      ? !_.isEmpty(state.commentList.data)
        ? state.commentList.data
        : []
      : [],
    productIDNoti: ownProps.productIDNoti,
  };
}

export default connect(mapStateToProps, {
  isQaModalOpen,
  fetchCommentList,
  questionanswer,
  isCommentsNotificModalOpen,
  productComments,
  NotificationSingleObject,
  makeNotificationDetailsEmpty,
  deletComment, // Delete a comment with id
  updateComment, // Update comment with id
})(ViewComments);
