import axios from "axios";
import { APP_API_KEY, INSTANCE_URL, LOGIN } from "@constant";
import {
  postRequest,
  patchRequest,
  postRequestAccess,
  getRequest,
} from "@networkCall";
// import { getHeaders } from "../common/common-utils.js";
import LocaleStrings from "@language";
import _ from "lodash";

export var ACTION_LOGOUT = "ACTION_LOGOUT";
export var SESSION_TOKEN = "SESSION_TOKEN";
export var USER_PICTURE = "USER_PICTURE";
export var USER_NAME = "USER_NAME";
export var USER_ID = "USER_ID";
export var USER_EMAIL = "USER_EMAIL";
export var USER_TYPE = "USER_TYPE";
export var IS_APPROVE = "IS_APPROVE";
export var IS_ACTIVE = "IS_ACTIVE";
export var USER_ISFREEZE = "USER_ISFREEZE";
export var IS_VENDORTOO = "IS_VENDORTOO";
export var CAN_RECEIVE_NOTIFICATION = "CAN_RECEIVE_NOTIFICATION";
export var PHONE_VERIFIED = "PHONE_VERIFIED";
export var EMAIL_VERIFIED = "EMAIL_VERIFIED";
export var DEVICE_SIZE = "DEVICE_SIZE";
export var IS_FORGOT_PASSWORD_OPEN = "IS_FORGOT_PASSWORD_OPEN";
export var LOCALE_LANGUAGE = "LOCALE_LANGUAGE";
export var SET_CURRENCY = "SET_CURRENCY";
export var USER_TAX = "USER_TAX";
export var IS_VERFICATION_MODAL_OPEN = "IS_VERFICATION_MODAL_OPEN";
export var VENDOR_VERIFIED = "VENDOR_VERIFIED"; // [Verified Vendor BY Super Admin {true/false}]
export var CALLEDFROM_APP = "CALLEDFROM_APP"; // [Verified Vendor BY Super Admin {true/false}]
export var VERIFYEMAILPROFILE_SETTINGS = "VERIFYEMAILPROFILE_SETTINGS"; // verification email comes from
export var COUNTRY_PHONE_LENGTH = "COUNTRY_PHONE_LENGTH";

// Patch users db table for preferred
export function localeLanguage(lang, session) {
  if (session.userid) {
    var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
    let object = {
      userid: session.userid,
      languagechoice: lang,
    };
    let body = { resource: object };

    // Actual Data Call
    return (dispatch) => {
      patchRequest(
        url,
        body,
        session,
        dispatch,
        (response) => {
          // Lan
          if (lang === "ar") {
            LocaleStrings.setLanguage("ar");
          } else if (lang === "en") {
            LocaleStrings.setLanguage("en");
          } else {
          }
          dispatch({ type: LOCALE_LANGUAGE, payload: lang });
        },
        (error) => {}
      );
    };
  } else {
    if (lang === "ar") {
      // Before login
      LocaleStrings.setLanguage("ar");
    } else if (lang === "en") {
      LocaleStrings.setLanguage("en");
    } else {
    }

    return { type: LOCALE_LANGUAGE, payload: lang };
  }
}

export function setCurrency(session, currency, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  let object = {
    userid: session.userid,
    currencychoice: currency,
  };
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({ type: SET_CURRENCY, payload: currency });
        callback({ success: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ success: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

export function deviceSize(size) {
  return { type: DEVICE_SIZE, payload: size };
}

// Forgot password Modal open or not
export function isVerficationModalOpen(isOpen) {
  return { type: IS_VERFICATION_MODAL_OPEN, payload: isOpen };
}

// Forgot password Modal open or not
export function isForgotPasswordOpen(isOpen) {
  return { type: IS_FORGOT_PASSWORD_OPEN, payload: isOpen };
}

export function resetStore() {
  return (dispatch) => {
    dispatch({ type: "RESET" });
    dispatch({ type: SESSION_TOKEN, payload: {} });
  };
}

export function mainLogin(values, session, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };
  // console.log("called");
  var data = {
    email: values.email,
    password: values.password,
    type: _.has(values, "type")
      ? values.type
      : _.indexOf(values.email, "@") === -1
      ? "phone"
      : "email",
  };

  if (_.has(values, "uniqueid")) {
    data["uniqueid"] = values.uniqueid;
  }

  var url = `${INSTANCE_URL}/api/v2/user/session`;
  var response = axios.post(url, data, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        // console.log("Daatadd", data);
        //Validate if the user is admin or, not
        // console.log("data", data);
        if (
          data.usertype === "vendor" ||
          data.usertype === "personal" ||
          data.usertype === "homebiz"
        ) {
          // Only Entry For [vendor]
          dispatch({ type: SESSION_TOKEN, payload: data });
          dispatch({ type: USER_ID, payload: data.id });
          dispatch({ type: USER_EMAIL, payload: data.email });
          dispatch({ type: USER_TYPE, payload: data.usertype });
          dispatch({ type: USER_NAME, payload: data.username });
          dispatch({ type: USER_PICTURE, payload: data.userimage });
          dispatch({ type: IS_APPROVE, payload: data.isapprove });
          dispatch({ type: IS_ACTIVE, payload: data.isactive });
          dispatch({ type: USER_ISFREEZE, payload: data.isfreeze });
          dispatch({ type: IS_VENDORTOO, payload: data.isvendortoo });
          dispatch({
            type: CAN_RECEIVE_NOTIFICATION,
            payload: data.canreceivenotification,
          });
          dispatch({ type: SET_CURRENCY, payload: data.currencychoice });
          dispatch({ type: USER_TAX, payload: data.tax });

          dispatch({ type: PHONE_VERIFIED, payload: data.phoneverified });
          dispatch({ type: EMAIL_VERIFIED, payload: data.emailverified });
          dispatch({ type: VENDOR_VERIFIED, payload: data.verified }); // [Verified Vendor BY Super Admin {true/false}]

          dispatch({ type: LOGIN, payload: true });
          callback({ success: 1, data: data });
        } else {
          callback({
            success: 0,
            message: LocaleStrings.login_error_other_than_vendor,
          });
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

//LOGOUT
export function logout(session) {
  return (dispatch) => {
    dispatch({ type: ACTION_LOGOUT, payload: false });
    dispatch({ type: "RESET" });
  };
}

export function validateSocialId(socialID, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=verifysocialid`;
  var params = { uniqueid: socialID };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        if (response.result === "success" && response.email) {
          callback({
            success: 1,
            email: response.email,
          });
        } else {
          callback({
            success: 0,
          });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function socialSignupAvailable(email, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=socialsignupavailable`;
  var params = { email: email };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        if (response.result === "success" && response.available === 1) {
          callback({
            success: 1,
          });
        } else {
          callback({
            success: 0,
          });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
        // Note - If available = 1 means the user already registered as a Vendor, Show error
      }
    );
  };
}

// REGISTER
export function mainRegister(values, session, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = values;

  var url = `${INSTANCE_URL}/api/v2/user/register?login=true`;

  var response = axios.post(url, data, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1 });
        dispatch({ type: LOCALE_LANGUAGE, payload: values.languagechoice });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

/**** To verify email call PATCH ****/
export function verifyEmail(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({ type: EMAIL_VERIFIED, payload: true });
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** o verify phone call PATCH ****/
export function VerifyPhoneStat(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({ type: PHONE_VERIFIED, payload: true });
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/*** Varify phone number [from db -> ALready exist or not] ***/
export function phoneValidate(session, phoneNum, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/phonevalidate`;

  return (dispatch) => {
    postRequest(
      url,
      {
        phone: phoneNum,
      },
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

/* Check supplied email is alraedy exist or not for df */
export function emailExistOrNot(session, email, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?fields=*&filter=email=${email}`;

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource.length,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* This will check get [emailverified & phoneverified] column value from "users" table */
export function checkVerificationStatus(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?fields=emailverified,phoneverified&filter=userid=${session.userid}`;

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.resource[0].emailverified) {
          /* Phone Number IS verified */
          dispatch({ type: EMAIL_VERIFIED, payload: true });
        }

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* Sent Veriofication link to registerd email address  */
export function reSentEmail(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;

  return (dispatch) => {
    postRequest(
      url,
      {
        emailtype: "verifyemail",
      },
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

/* This fc will replace the old email with new one and sent a verification link to it */
export function changeEmail(session, newEmail, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;

  return (dispatch) => {
    postRequest(
      url,
      {
        email: newEmail,
        emailtype: "changeemail",
        usertype: "app",
      },
      session,
      dispatch,
      (response) => {
        // dispatch({ type: SESSION_TOKEN, payload: response.session_token });

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

/* Will use this for send email with type for all perpose */
export function sendEmail(session, emailObject, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  return (dispatch) => {
    postRequest(
      url,
      emailObject,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.copy_emil_sent_successfully_msg,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.email_not_sent_msg,
          data: error,
        });
      }
    );
  };
}

/**** Sent reset link to vendor****/
export function updatesource(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/updatesource`;

  return (dispatch) => {
    postRequest(
      url,
      object,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

/**** Unique business name check ****/
export function validatebusiness(session, bizname, callback) {
  let url = `${INSTANCE_URL}/api/v2/appscript/validatebusiness`;
  let object = {
    bizname: bizname,
  };
  return (dispatch) => {
    postRequest(
      url,
      object,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

// Email new api
export function sendotpEmail(session, email, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  return (dispatch) => {
    postRequest(
      url,
      {
        emailtype: "sendotp",
        email: email,
      },
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

export function verifyotpEmail(session, code, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  return (dispatch) => {
    postRequest(
      url,
      {
        emailtype: "verifyotp",
        code: code,
      },
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

export function changeupdateEmail(session, newEmail, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;

  return (dispatch) => {
    postRequest(
      url,
      {
        email: newEmail,
        emailtype: "changeemail",
      },
      session,
      dispatch,
      (response) => {
        if (response.result == "success" && response.data) {
          // dispatch({ type: SESSION_TOKEN, payload: response.data });

          callback({
            status: 1,
          });
        } else {
          callback({
            status: 0,
          });
        }
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

export function verifyemailProfilesettings(val) {
  return (dispatch) => {
    dispatch({ type: VERIFYEMAILPROFILE_SETTINGS, payload: val });
  };
}

export function countryPhoneLength(length = "974") {
  // console.log("length", length);
  return (dispatch) => {
    dispatch({ type: COUNTRY_PHONE_LENGTH, payload: length });
  };
}

export function fetchsessionData(values, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
      "X-DreamFactory-Session-Token": values.session_token,
    },
  };
  let data = {};

  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/refreshtoken`;
  var response = axios.post(url, data, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        //Validate if the user is admin or, not
        let data1 = data?.userdata;

        // console.log("data1", data1);
        if (
          data1.usertype === "vendor" ||
          data1.usertype === "personal" ||
          data1.usertype === "homebiz"
        ) {
          data1.session_token = values.session_token;
          // Only Entry For [vendor]
          dispatch({ type: SESSION_TOKEN, payload: data1 });
          dispatch({ type: USER_ID, payload: data1.userid });
          dispatch({ type: USER_EMAIL, payload: data1.email });
          dispatch({ type: USER_TYPE, payload: data1.usertype });
          dispatch({ type: USER_NAME, payload: data1.username });
          dispatch({ type: USER_PICTURE, payload: data1.userimage });
          dispatch({ type: IS_APPROVE, payload: false });
          dispatch({ type: IS_ACTIVE, payload: data1.isactive });
          dispatch({ type: USER_ISFREEZE, payload: data1.isfreeze });
          dispatch({ type: IS_VENDORTOO, payload: data1.isvendortoo });
          dispatch({
            type: CAN_RECEIVE_NOTIFICATION,
            payload: data1.canreceivenotification,
          });
          dispatch({ type: SET_CURRENCY, payload: data1.currencychoice });
          dispatch({ type: USER_TAX, payload: data1.tax });

          dispatch({ type: PHONE_VERIFIED, payload: data1.phoneverified });
          dispatch({ type: EMAIL_VERIFIED, payload: data1.emailverified });
          dispatch({ type: VENDOR_VERIFIED, payload: data1.verified }); // [Verified Vendor BY Super Admin {true/false}]
          dispatch({ type: CALLEDFROM_APP, payload: true }); // [Verified Vendor BY Super Admin {true/false}]

          dispatch({ type: LOGIN, payload: true });
          callback({ success: 1, data1: data });
        } else {
          callback({
            success: 0,
            message: LocaleStrings.login_error_other_than_vendor,
          });
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function updateEmailverified(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({ type: EMAIL_VERIFIED, payload: true });
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}
