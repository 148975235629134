import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

import moment from "moment-timezone";
import _ from "lodash";
import { classNames } from "@commonFunction";
import { Toaster } from "react-hot-toast";
// Import BaseLoader
import BaseLoader from "@baseLoader";
// Component Import
import Vendorstats from "./vendorstats";
// Import constant
import { PERSONAL_USER_REDIRECT_TO, PUBLIC_FILE_PATH } from "@constant";
import VendorVerification from "../../../starter/vendorverification";
//Action Import
import {
  fetchVendorSalesStats,
  salesmanagementStatboxSelected,
  camefromSalesManagemnet,
} from "@salesmanagementActions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
import { onSidebarMenuSelected } from "@sidebarActions";
import { isVerficationModalOpen } from "@mainActions";
import { fetchUsersDeatils, fetchStoreDetails } from "@profileSettingsActions";
// Import css/style sheet [sales management style sheet]
import "../slaesstyle.css";
import VerifyEmailModal from "@starter/verifyemailmodal";

class SalesManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loading1: false,
      loaderDesc: LocaleStrings.preparing_sales_management,
      loaderDesc1: LocaleStrings.preparing_vendor_statistics,
      currentDateTab: 0,
      dateIndex: 0,
      dateNameToShow: LocaleStrings.today,
      //dateNameToShow: moment().format("MMMM"),
      dateNameToHidden: "Today",
      //dateNameToHidden: moment().format("MMMM"),
      dailyCurrentSelection: moment().format("Do MMM YYYY"),
      //dailyCurrentSelection: moment().format("Do MMM YYYY"),
    };

    // All Binded Functions
  }

  componentDidMount() {
    // Call API to Fetch Data

    let { session, user } = this.props;
    let { usertype } = session;
    // console.log("user", user);
    this.props.fetchVendorSalesStats(
      this.props.session,
      this.state.dateIndex,
      this.state.currentDateTab,
      (callBack) => {
        this.setState({ loading: false });
      }
    );
    this.props.fetchStoreDetails(this.props.session, (callback) => {});
    this.props.fetchUsersDeatils(this.props.session, (callback) => {});
    // if (this.props.user.usertype !== "personal") { // Old code
    // As email is not mandetory anymore
    if (this.props.user.phoneverified === false) {
      if (this.props.user.usertype === "personal") {
        this.props.onSidebarMenuSelected(2);
        this.props.history.push(PERSONAL_USER_REDIRECT_TO);
      }
      this.props.isVerficationModalOpen(true);
    }
  }

  // Handel Redirect
  _handelRedirect(e) {
    this.props.onSidebarMenuSelected(6);
    this.props.history.push("/dashboard/revenuereports/sales");
    /*
    this action is need if user goes to sales retort from Slaes management
    */
    this.props.camefromSalesManagemnet(true);
  }

  // Date Tab Nav Chnage {Daily,weekly,Monthly}
  _handleDateTab = (e, selectedTabPos) => {
    this.setState({ loading1: true });

    this.setState({ currentDateTab: selectedTabPos });
    this.setState({ dateIndex: 0 }); // Reset Index
    this.setState({ dailyCurrentSelection: moment().format("Do MMM YYYY") }); // Reset Daily

    if (selectedTabPos == 2) {
      let { salesDateMonthsList } = this.props;
      let filteredMonthArray = _.filter(salesDateMonthsList, [
        "actname",
        moment().format("MMMM"),
      ]);
      // Monthly

      this.setState({ dateNameToShow: filteredMonthArray[0].name });
      this.setState({ dateNameToHidden: moment().format("MMMM") });
    } else if (selectedTabPos == 1) {
      // Weekly
      this.setState({ dateNameToShow: LocaleStrings.this_week });
      this.setState({ dateNameToHidden: LocaleStrings.this_week });
    } else {
      // Daily
      this.setState({ dateNameToShow: LocaleStrings.today });
      this.setState({ dateNameToHidden: LocaleStrings.today });
    }

    this.props.fetchVendorSalesStats(
      this.props.session,
      0,
      selectedTabPos,
      (callBack) => {
        this.setState({ loading1: false });
      }
    );
  };

  /**** Date change With Arrows *****/
  _handleNameChangeAndindex = (e, leftorright) => {
    this.setState({ loading1: true });

    if (this.state.currentDateTab === 2) {
      let { salesDateMonthsList } = this.props;
      // For Month
      if (leftorright == "minus") {
        // Minus
        var stateVal = this.state.dateIndex;
        var currVal = --stateVal;

        this.setState({ dateIndex: currVal });

        //let monthName = moment().subtract(Math.abs(currVal), "month").format('MMMM');
        let monthName = moment(this.state.dateNameToHidden, "MMMM")
          .subtract(1)
          .format("MMMM");

        let filteredMonthArray = _.filter(salesDateMonthsList, [
          "actname",
          monthName,
        ]);

        this.setState({ dateNameToShow: filteredMonthArray[0].name }); // Showing
        this.setState({ dateNameToHidden: monthName }); // Hidding

        if (moment().format("MMMM") == monthName) {
          // reset Again
          this.setState({ dateIndex: 0 });
        }
      } else {
        // Plus
        var stateVal = this.state.dateIndex;
        var currVal = ++stateVal;

        this.setState({ dateIndex: currVal });

        //let monthName = moment().add(Math.abs(currVal), "month").format('MMMM');
        let monthName = moment(this.state.dateNameToHidden, "MMMM")
          .add(1, "month")
          .format("MMMM");

        let filteredMonthArray = _.filter(salesDateMonthsList, [
          "actname",
          monthName,
        ]);

        this.setState({ dateNameToShow: filteredMonthArray[0].name }); // Showing
        this.setState({ dateNameToHidden: monthName }); // Hidding

        if (moment().format("MMMM") == monthName) {
          // reset Again
          this.setState({ dateIndex: 0 });
          currVal = 0;
        }
      }
    } else if (this.state.currentDateTab === 1) {
      // Weekly

      if (leftorright == "minus") {
        // Minus
        var stateVal = this.state.dateIndex;
        var currVal = --stateVal;

        if (currVal == 0) {
          this.setState({ dateNameToShow: LocaleStrings.this_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.this_week }); // Hidding
        } else if (currVal == -1) {
          this.setState({ dateNameToShow: LocaleStrings.last_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.last_week }); // Hidding
        } else if (currVal == 1) {
          this.setState({ dateNameToShow: LocaleStrings.next_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.next_week }); // Hidding
        } else {
          let dynamicWeekName = "";
          if (currVal < 0) {
            dynamicWeekName =
              Math.abs(currVal) + " " + LocaleStrings.weeks_back;
          } else {
            dynamicWeekName =
              LocaleStrings.after +
              " " +
              Math.abs(currVal) +
              " " +
              LocaleStrings.weeks;
          }

          this.setState({ dateNameToShow: dynamicWeekName }); // Showing
          this.setState({ dateNameToHidden: dynamicWeekName }); // Hidding
        }
      } else {
        // Plus
        var stateVal = this.state.dateIndex;
        var currVal = ++stateVal;

        if (currVal == 0) {
          this.setState({ dateNameToShow: LocaleStrings.this_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.this_week }); // Hidding
        } else if (currVal == -1) {
          this.setState({ dateNameToShow: LocaleStrings.last_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.last_week }); // Hidding
        } else if (currVal == 1) {
          this.setState({ dateNameToShow: LocaleStrings.next_week }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.next_week }); // Hidding
        } else {
          let behindText = currVal < 0 ? " " + LocaleStrings.weeks_back : "";
          let dynamicWeekName = "";
          if (currVal < 0) {
            dynamicWeekName =
              Math.abs(currVal) + " " + LocaleStrings.weeks_back;
          } else {
            dynamicWeekName =
              LocaleStrings.after +
              " " +
              Math.abs(currVal) +
              " " +
              LocaleStrings.weeks;
          }

          this.setState({ dateNameToShow: dynamicWeekName }); // Showing
          this.setState({ dateNameToHidden: dynamicWeekName }); // Hidding
        }
      }

      this.setState({ dateIndex: currVal });

      //Plus
    } else {
      // Daily
      if (leftorright == "minus") {
        // Minus
        var stateVal = this.state.dateIndex;
        var currVal = --stateVal;

        this.setState({
          dailyCurrentSelection: moment(
            this.state.dailyCurrentSelection,
            "Do MMM YYYY"
          )
            .subtract(1, "days")
            .format("Do MMM YYYY"),
        });
        if (currVal == 0) {
          this.setState({ dateNameToShow: LocaleStrings.today }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.today }); // Hidding
        } else if (currVal == -1) {
          this.setState({ dateNameToShow: LocaleStrings.yesterday }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.yesterday }); // Hidding
        } else {
          this.setState({
            dateNameToShow: moment(
              this.state.dailyCurrentSelection,
              "Do MMM YYYY"
            )
              .subtract(1, "days")
              .format("Do MMM YYYY"),
          }); // Showing
          this.setState({
            dateNameToHidden: moment(
              this.state.dailyCurrentSelection,
              "Do MMM YYYY"
            )
              .subtract(1, "days")
              .format("Do MMM YYYY"),
          }); // Hidding
        }
      } else {
        // Plus
        var stateVal = this.state.dateIndex;
        var currVal = ++stateVal;

        this.setState({
          dailyCurrentSelection: moment(
            this.state.dailyCurrentSelection,
            "Do MMM YYYY"
          )
            .add(1, "days")
            .format("Do MMM YYYY"),
        });

        if (currVal == 0) {
          this.setState({ dateNameToShow: LocaleStrings.today }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.today }); // Hidding
        } else if (currVal == -1) {
          this.setState({ dateNameToShow: LocaleStrings.yesterday }); // Showing
          this.setState({ dateNameToHidden: LocaleStrings.yesterday }); // Hidding
        } else {
          this.setState({
            dateNameToShow: moment(
              this.state.dailyCurrentSelection,
              "Do MMM YYYY"
            )
              .add(1, "days")
              .format("Do MMM YYYY"),
          }); // Showing
          this.setState({
            dateNameToHidden: moment(
              this.state.dailyCurrentSelection,
              "Do MMM YYYY"
            )
              .add(1, "days")
              .format("Do MMM YYYY"),
          }); // Hidding
        }
      }

      this.setState({ dateIndex: currVal });
    }
    // Call API to Fetch Data
    this.props.fetchVendorSalesStats(
      this.props.session,
      currVal,
      this.state.currentDateTab,
      (callBack) => {
        this.setState({ loading1: false });
      }
    );
  };

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  //Arrow function for renderLoader
  renderLoader1 = () => {
    return <BaseLoader data={this.state.loaderDesc1} />;
  };

  render() {
    let { session, localCurrency, language, vendorSalesStatsData } = this.props;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    // Order currency
    let orderCurrency = vendorSalesStatsData.currency;

    // let earning = isCurrencyRateApplicable(
    //   localCurrency,
    //   orderCurrency,
    //   vendorSalesStatsData?.earning
    // );
    let earning = vendorSalesStatsData?.earning;

    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>{this.renderLoader()}</>
            ) : this.props.salesmanStatboxSelected === "new" ? (
              {
                /* <div className="responsivePadding">
             <SalesNew />
           </div> */
              }
            ) : this.props.salesmanStatboxSelected === "delivered" ? (
              {
                /* <div className="responsivePadding">
             <SalesDelivered />
           </div> */
              }
            ) : this.props.salesmanStatboxSelected === "replace" ? (
              {
                /* <div className="responsivePadding">
             <SalesCancRetuRepl />
           </div> */
              }
            ) : this.props.salesmanStatboxSelected === "nego" ? (
              {
                /* <div className="responsivePadding">
             <SalesNegotiation />
           </div> */
              }
            ) : (
              /***** Tabs [Daily,Weekly,Monthly] *****/
              <div className="responsivePadding ">
                {/* Header text */}
                <p className="sales-header">{LocaleStrings.sales_overview}</p>
                {/* Vendor Stats */}
                <Vendorstats history={this.props.history} />
                {/* Header text */}
                <p className="sales-header">{LocaleStrings.sales_report}</p>

                <div className="grid md:grid-cols-3 mx-6 my-6 gap-4 grid-cols-1">
                  {/* Section 1 */}
                  <div
                    className={classNames(
                      session.usertype !== "personal" ? "" : "hidden",
                      "md:col-span-2 col-span-3"
                    )}>
                    <nav className="cc-dates-nav" aria-label="Tabs">
                      {this.props.salesDateTabs.map((tab, tabIdx) => (
                        <a
                          key={tab.name}
                          onClick={(e) => this._handleDateTab(e, tab.position)}
                          className={classNames(
                            tab.position == this.state.currentDateTab
                              ? "text-primary"
                              : "text-ternary hover:text-secondary",
                            tabIdx === 0 ? "rounded-l-lg" : "",
                            tabIdx === this.props.salesDateTabs.length - 1
                              ? "rounded-r-lg"
                              : "",
                            "saler-date-tabs"
                          )}
                          aria-current={
                            tab.position == this.state.currentDateTab
                              ? "page"
                              : undefined
                          }>
                          <span>{tab.name}</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              tab.position == this.state.currentDateTab
                                ? "bg-primary"
                                : "bg-transparent",
                              "absolute inset-x-0 bottom-0 h-0.5"
                            )}
                          />
                        </a>
                      ))}
                    </nav>
                  </div>
                  {/* Section 2 */}

                  {/* Left - Right Arrow */}
                  <div className="bg-custWhite h-13 rounded cc-theme-shadow md:col-span-1 col-span-3">
                    <div
                      className={classNames(
                        session.usertype !== "personal" ? "" : "hidden",
                        "flex justify-center py-3"
                      )}>
                      <div className>
                        <img
                          loading="lazy"
                          className={
                            " h-7 w-7  cursor-pointer icon-hover-transition"
                          }
                          src={"/images2/common/arrow-left.svg"}
                          onClick={(e) =>
                            this._handleNameChangeAndindex(e, "minus")
                          }
                        />
                      </div>
                      <div className="mt-1 w-40 text-center">
                        {this.state.dateNameToShow}
                      </div>
                      <div>
                        <img
                          loading="lazy"
                          className={
                            " h-7 w-7 cursor-pointer icon-hover-transition"
                          }
                          // src={
                          //   PUBLIC_FILE_PATH +
                          //   (language === "ar" ? "arrow-left.svg" : "arrow-right.svg")
                          // }
                          src={PUBLIC_FILE_PATH + `arrow-right.svg`}
                          onClick={(e) =>
                            this._handleNameChangeAndindex(e, "plus")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.loading1 ? (
                  this.renderLoader1()
                ) : (
                  <div
                    className={classNames(
                      session.usertype !== "personal" ? "" : "hidden",
                      ""
                    )}>
                    <div
                      className={classNames(
                        session.usertype !== "personal" ? "" : "hidden",
                        " mx-6"
                      )}>
                      {/* <div className=" sm:col-start-2 xs:col-start-1 sm:col-span-1 xs:col-span-3 ">
                     <div className="box-content h-24 rounded-lg   text-center rounded ...">
                       <p className="text-2xl font-semibold pt-5 text-secondary">
                         {currency}{" "}
                         {parseFloat(
                           this.props.vendorSalesStatsData.earning * currencyrate
                         ).toFixed(2)}
                       </p>
                       <p className=" text-xs font-medium text-ternary truncate mx-2">
                         {LocaleStrings.total_earnings}
                       </p>
                     </div>
                   </div> */}
                      <div className="cc-status-box3" align="center">
                        <div className="w-60">
                          <dt>
                            <div className="cc-sales-box-icon-revert">
                              <img
                                loading="lazy"
                                className={"h-5 w-5"}
                                src={
                                  "/images2/sales_managemnet_images/Vector.svg"
                                }
                                alt="total_earnings"
                              />
                            </div>
                            <p
                              className={classNames(
                                language === "ar" ? "mr-16" : "ml-2",
                                "text-2xl font-semibold text-secondary mt-3 text-center"
                              )}>
                              {currency} {parseFloat(earning).toFixed(2)}
                            </p>
                          </dt>
                          <dd
                            className={classNames(
                              language === "ar" ? "mr-16" : "ml-2",
                              "text-center"
                            )}>
                            <p className=" text-xs font-medium text-ternary truncate mx-2">
                              {LocaleStrings.total_earnings}
                            </p>
                          </dd>
                        </div>
                      </div>
                    </div>

                    {/* Dinamic 3 Box Containt  */}
                    <div className="grid grid-cols-3 gap-4 mx-6 sm:mx-6 xs:mx-1 mt-10 ">
                      <div className="cc-sales-card2">
                        <p className="cc-sales-card-header">
                          +{this.props.vendorSalesStatsData.neworders}
                        </p>
                        <p className=" cc-sales-card-subheader ">
                          {LocaleStrings.open_orders}
                        </p>
                      </div>
                      <div className="cc-sales-card2">
                        <p className="cc-sales-card-header">
                          +{this.props.vendorSalesStatsData.delivered}
                        </p>
                        <p className=" cc-sales-card-subheader">
                          {LocaleStrings.delivered}
                        </p>
                      </div>
                      <div className="cc-sales-card2">
                        <p className="cc-sales-card-header">
                          +{this.props.vendorSalesStatsData.returned}
                        </p>
                        <p className=" cc-sales-card-subheader">
                          {LocaleStrings.clubbed}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* // View Report Button  */}
                <div
                  className={classNames(
                    session.usertype !== "personal" ? "" : "hidden",
                    "grid grid-cols-3 gap-4 mt-8"
                  )}>
                  <div className="col-start-2 col-span-1 ...">
                    <button
                      type="button"
                      className="btn-primary"
                      onClick={(e) => this._handelRedirect()}>
                      {LocaleStrings.saler_btn_text_viewreport}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <VendorVerification history={this.props.history} />
            {this.props.isVerifyEmailPrflInfoModal ? (
              <VerifyEmailModal calledfrom={"signup"} />
            ) : null}
            <Toaster />
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, currency } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    localCurrency: currency,
    salesDateTabs: state.salesDateTabs,
    salesDateMonthsList: state.salesDateMonthsList,
    vendorSalesStatsData: state.vendorSalesStatsData
      ? state.vendorSalesStatsData.data
        ? state.vendorSalesStatsData.data
        : []
      : [],
    salesmanStatboxSelected: state.salesmanStatboxSelected
      ? state.salesmanStatboxSelected
      : "",
    isVerifyEmailPrflInfoModal: state.isVerifyEmailPrflInfoModal,
  };
}

export default connect(mapStateToProps, {
  fetchVendorSalesStats,
  salesmanagementStatboxSelected,
  onSidebarMenuSelected,
  isVerficationModalOpen,
  camefromSalesManagemnet,
  fetchUsersDeatils,
  fetchStoreDetails,
})(SalesManagement);
