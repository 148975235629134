import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import toast, { Toaster } from "react-hot-toast";
import { validatepassword } from "@constant";
// Import Components
import BaseLoader from "@baseLoader";
import renderHTML from "react-render-html";

import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";

// Import Actions
import {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  updateUserPassord,
} from "@profileSettingsActions";

// Functions Import
import { classNames, isEmpty } from "@commonFunction";

/* This example requires Tailwind CSS v2.0+ */

class ChangePassSection extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      loaderDesc: LocaleStrings.preparing_profile_information,
      show: false,
      show1: false,
    };

    // All Binded Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
  }

  componentDidMount() {}

  changeShow() {
    let { show } = this.state;

    this.setState({ show: !show });
  }

  changeShow1() {
    let { show1 } = this.state;

    this.setState({ show1: !show1 });
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ btnLoader: true, isBtnDisable: true });

    if (values.confirm_password !== values.new_password) {
      toast.error(LocaleStrings.password_dont_match);
      this.setState({ btnLoader: false, isBtnDisable: false });
      return false;
    } else {
      let newValues = {
        email: values.email,
        new_password: values.new_password,
        old_password: values.current_password,
      };

      // After file upload
      this.props.updateUserPassord(
        this.props.session,
        newValues,
        (callback) => {
          if (callback.status === 1) {
            toast.success(callback.data.message);
            this.setState({ btnLoader: false, isBtnDisable: false });
            this.props.closeModal();
            this.props.reset();
          } else {
            this.setState({ btnLoader: false, isBtnDisable: false });
            toast.error(callback.data.message);
          }
        }
      );
    }
  }

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      vendorDetails,
      storeSelectedGender,
    } = this.props;

    return (
      <>
        <form
          className=""
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
          <div className="pb-52 px-6 space-y-8">
            <Field
              name="current_password"
              label={LocaleStrings.current_password}
              placeholder={LocaleStrings.current_password}
              type="text"
              component={this.renderFieldText}
              mandatory="true"
              labelposition={LABEL_POSITION_TOP}
              classNameLabel="text-sm font-medium text-secondary"
            />

            <Field
              name="new_password"
              label={LocaleStrings.password}
              placeholder={LocaleStrings.password}
              type="password"
              component={this.renderFieldText}
              mandatory="true"
              show={this.state.show}
              setShow={this.changeShow}
              labelposition={LABEL_POSITION_TOP}
              classNameLabel="text-sm font-medium text-secondary"
              className="cust-pr-20"
            />
            <Field
              name="confirm_password"
              label={LocaleStrings.confirm_password}
              placeholder={LocaleStrings.confirm_password}
              show={this.state.show1}
              type="password"
              component={this.renderFieldText}
              mandatory="true"
              setShow={this.changeShow1}
              labelposition={LABEL_POSITION_TOP}
              classNameLabel="text-sm font-medium text-secondary"
              className="cust-pr-20"
            />
          </div>
          <div className="px-6 py-12 border-t-2 border-quaternary">
            {/* FOOTER */}

            <button
              type="submit"
              disabled={this.state.isBtnDisable}
              className={classNames(
                this.state.isBtnDisable ? "cursor-not-allowed" : "",
                "btn-primary"
              )}>
              <svg
                className={classNames(
                  this.state.btnLoader ? "" : "sr-only",
                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                )}
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true">
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
              {LocaleStrings.update_password}
            </button>
          </div>
        </form>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var password = values["new_password"];
  var requiredFields = ["current_password", "new_password", "confirm_password"];

  let passwordErrorMessage =
    '<div style="width: 350px; display: table;margin-top:10px;"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One lowercase character</div><div style="display: table-cell;">&#8226; One upper case</div></div></div><div style="width: 350px; display: table;margin-top:10px"><div style="display: table-row"><div style=" display: table-cell;">&#8226; 8 character minimum</div><div style="display: table-cell;"> &#8226; 20 characters maximum</div></div></div><div style="width: 350px; display: table;margin-top:10px;"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One special character</div> <div style=" display: table-cell;">&#8226; Atleast one number</div>';
  if (password && !validatepassword(password)) {
    errors["new_password"] = renderHTML(passwordErrorMessage);
  }

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

function mapStateToProps(state, ownProps) {
  let newObj = {};

  if (state.vendorDetails)
    newObj = {
      username: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.username
          : ""
        : "",
      userid: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.userid
          : ""
        : "",
      email: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.email
          : ""
        : "",
    };

  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    isOpenProfileInfoModal: state.isOpenProfileInfoModal,
    vendorDetails: state.vendorDetails
      ? state.vendorDetails.data
        ? state.vendorDetails.data
        : []
      : [],
    initialValues: newObj,
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  updateUserPassord,
})(
  reduxForm({
    validate,
    form: "updatePasswordForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ChangePassSection)
);
