import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import Components
import BaseLoader from "@baseLoader";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
import StoreInfoeEdit from "./storeinfoeedit";

// Import Actions
import { fetchStoreDetails } from "../../actions/index";

class StoreInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_store_information,
    };

    // All Binded Functions
  }

  /**** Compoent Did mount ****/
  componentDidMount() {
    /**** Fetch Store Information ****/
    this.props.fetchStoreDetails(this.props.session, (callback) => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <>
        <CommonHeaderProfileSettings
          commonHeaderText={LocaleStrings.profile_settings_list_text2}
        />
        {this.state.loading ? (
          <BaseLoader data={this.state.loaderDesc} />
        ) : (
          <>
            <StoreInfoeEdit />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
  };
}

export default connect(mapStateToProps, {
  fetchStoreDetails,
})(StoreInformation);
