import {
  getRequest,
  postRequest,
  deleteRequest,
  patchRequest,
} from "@networkCall";

import { INSTANCE_URL } from "@constant";

import LocaleStrings from "@language";

import { DISCOUNTMANAGEMENT_PRODUCTLIST } from "../../discountsmanagement/actions/index";

// Data
export const COUPONMANAGEMENT_MASTER = "COUPONMANAGEMENT_MASTER";
export const COUPONMANAGEMENT_MASTER_SINGLE = "COUPONMANAGEMENT_MASTER_SINGLE";
export const COUPONMANAGEMENT_SELECTED_TAB = "COUPONMANAGEMENT_SELECTED_TAB";
// Modal
export const IS_COUPONMANAGEMENT_MODAL_OPEN = "IS_COUPONMANAGEMENT_MODAL_OPEN";

/**** Fetch Product Category data 
Fetch Criteria Master Data 
-> TAKING FROM DISCOUNT MANAGMENT ACTONS ****/

/**** Fetch Coupons Master data List ****/
export function fetchCouponMaster(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster?fields=*&related=couponrule_by_couponid&filter=(createdby=${session.userid})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: COUPONMANAGEMENT_MASTER,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Is Open Coupons management modal ****/
export function isCouponManageModalopen(isOpen) {
  return { type: IS_COUPONMANAGEMENT_MODAL_OPEN, payload: isOpen };
}

/**** Select Coupons active / Inactive Tabs ****/
export function selectedCouponsTab(selected) {
  return { type: COUPONMANAGEMENT_SELECTED_TAB, payload: selected };
}

/**** Add & Edit ****/

/**** Add Coupon Rule *****/
export function addCouponRule(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponrule`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Add Coupon Master ****/

export function addCouponMaster(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource[0].id,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Products List ****/
/* Parameters
  
  If only category selection is available
  [“categoryid”:<selected-categoryid>,”discount”:1]

  If subcategory selection is available
	[“categoryid”:<selected-categoryid>,
  “subcategoryid”:<selected-subcategoryid>]

  */
export function fetchProductsByCateSubCate(
  session,
  selected_categoryid,
  selected_subcategoryid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/listproductsbycategory`;

  if (
    (selected_categoryid === null || selected_categoryid === "") &&
    (selected_subcategoryid === null || selected_subcategoryid === "")
  ) {
    return (dispatch) => {
      dispatch({
        type: DISCOUNTMANAGEMENT_PRODUCTLIST,
        payload: {},
      });
    };
  } else {
    if (selected_subcategoryid === null || selected_subcategoryid === "") {
      var object = {
        categoryid: selected_categoryid,
        discount: 1,
      };
    } else {
      var object = {
        categoryid: selected_categoryid,
        subcategoryid: selected_subcategoryid,
      };
    }

    // Actual Data Call
    return (dispatch) => {
      postRequest(
        url,
        object,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: DISCOUNTMANAGEMENT_PRODUCTLIST,
            payload: { data: response.data },
          });

          callback({ status: 1, message: LocaleStrings.api_success_status });
        },
        (error) => {
          callback({ status: 0, message: LocaleStrings.something_went_wrong });
        }
      );
    };
  }
}

/**** Update Coupon Rules ****/
export function updateCouponRule(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponrule`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Update Coupon Master *****/
export function updateCouponMaster(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Deactivate Coupon With Coupon Id ****/
export function deactivateCoupon(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Single Object For Coupons ****/
export function getSelectedCouponsObj(coupmasterObj = {}, session) {
  if (Object.keys(coupmasterObj).length > 0) {
    coupmasterObj["couponruleid"] = coupmasterObj.couponrule_by_couponid[0].id;
    coupmasterObj["categoryid"] =
      coupmasterObj.couponrule_by_couponid[0].categoryid;
    coupmasterObj["subcategoryid"] =
      coupmasterObj.couponrule_by_couponid[0].subcategoryid;
    coupmasterObj["productid"] =
      coupmasterObj.couponrule_by_couponid[0].productid;
    coupmasterObj["times"] = coupmasterObj.couponrule_by_couponid[0].times;
  }

  return { type: COUPONMANAGEMENT_MASTER_SINGLE, payload: coupmasterObj };
}

export function deleteCoupon(session, object, callback) {
  var filter = encodeURI(`filter=(id=${object.id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/couponmaster?${filter}`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}
