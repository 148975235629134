import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import LocaleStrings from "@language";
import _ from "lodash";
import moment from "moment-timezone";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import toast from "react-hot-toast";
import Dropzone from "react-dropzone";
import { confirmAlert } from "react-confirm-alert";
import "react-phone-input-2/lib/style.css";
import PlacesAutocomplete from "react-places-autocomplete";
import StoreInlineCropper from "./store-innercropper";

import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

// Import constant
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  GOOGLE_MAP_KEY,
  DATE_FORMAT_WITH_TIME_WITH_SECOND,
  MIN_DATE_FORMAT,
  QATAR_LAT,
  QATAR_LONG,
  getCountryCode,
} from "@constant";

// Import Actions
import {
  uploadMediaLarge,
  updateStore,
  isStoreInfoMapModalOpen,
  fetchStoreDetails,
  addStore,
  updateStoreCoverLocally,
  fetchCountries,
} from "@profileSettingsActions";

// Functions Import
import {
  classNames,
  removePhoneNoSpecialChar,
  isValidQatarPhoneNumber,
} from "@commonFunction";

import { countryPhoneLength } from "@mainActions";

// Component Import
import StoreMap from "./storemap";

var phoneFieldNames = []; // Hold Dynamic Phone number names

class StoreInfoeEdit extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      fileUrl: "",
      fileArr: {},
      fileArrNew: {},
      fileArrMap: {},
      contactNumber: [],
      flag: true,
      address: "",
      flag1: true,
      geolocObject: {
        location: "",
        latitude: "",
        longitude: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        place_id: "",
      },
      storeEventCover: "",
      storeCoverAvailableLocally: false,
      storeCoverAvailableOnDrop: false,
      storeCoverReset: false,
    };

    // All Binded Functions
    this.updateGeolocObject = this.updateGeolocObject.bind(this);
    this.onDropStoreCover = this.onDropStoreCover.bind(this);
  }

  //return a promise that resolves with a File instance
  urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  handleChange = (address) => {
    this.setState({ address });
    this.setState({ flag1: false });
  };

  handleSelect = (address) => {
    const reader = new FileReader();
    let that = this;
    let { geolocObject } = this.state;
    this.setState({ address });

    let location = address;
    let zipcode = "";
    let place_id = "";
    let country = "";
    let state = "";
    let city = "";
    let latitude = "";
    let longitude = "";

    geocodeByAddress(address)
      .then((results) => {
        _.forEach(results[0].address_components, function (value) {
          // postal_code
          if (_.indexOf(value.types, "postal_code") > -1) {
            zipcode = value.long_name;
          }

          // country
          if (_.indexOf(value.types, "country") > -1) {
            country = value.long_name;
          }

          // state / administrative_area_level_1
          if (_.indexOf(value.types, "administrative_area_level_1") > -1) {
            state = value.long_name;
          }

          // state / administrative_area_level_2
          if (_.indexOf(value.types, "administrative_area_level_2") > -1) {
            city = value.long_name;
          }
        });
        place_id = results[0].place_id; // place_id / zipcode

        geolocObject["zipcode"] = zipcode;
        geolocObject["place_id"] = place_id;
        geolocObject["country"] = country;
        geolocObject["state"] = state;
        geolocObject["city"] = city;
        geolocObject["location"] = location;

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        latitude = latLng.lat;
        longitude = latLng.lng;

        geolocObject["latitude"] = latitude;
        geolocObject["longitude"] = longitude;

        this.setState({ geolocObject: geolocObject });

        // Store Image
        let filearr = [];
        let mapImage = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue|${latitude},${longitude}&zoom=14&size=400x400&key=${GOOGLE_MAP_KEY}`;

        that
          .urltoFile(mapImage, "storeimage.jpeg", "image/jpeg")
          .then(function (file) {
            reader.readAsArrayBuffer(file);

            reader.onload = (e) => {
              file.url = e.target.result;

              filearr.push(file);

              that.setState({ fileArrMap: filearr });
            };
          });
      })
      .catch((error) => console.error("Error", error));
  };

  componentDidMount() {
    let { session, initialValues } = this.props;
    console.log("initialValues", initialValues);
    if (
      initialValues &&
      initialValues.contactnumber &&
      initialValues.contactnumber != null &&
      initialValues.contactnumber != ""
    ) {
      let dialCode = getCountryCode(initialValues.contactnumber);
      console.log("dialCode", dialCode);
      // this.props.countryPhoneLength(dialCode);
    }
    this.props.fetchCountries(session, (res) => {});
    if (_.isEmpty(this.props.storeDetails)) {
      var arr = [];
      let innnerObj = {
        name: "",
        value: "",
        index: "",
        ccode: "",
      };
      let name = "contactnumber_" + 0;

      innnerObj["name"] = name;
      innnerObj["value"] = "";
      innnerObj["index"] = 0;
      innnerObj["ccode"] = "";

      arr.push(innnerObj);

      this.setState({
        contactNumber: arr,
      });
    }
  }

  componentWillUnmount() {
    this.setState({ loading: true });
    this.setState({ btnLoader: false });
    this.setState({ fileUrl: "" });
    this.setState({ fileArr: {} });
    this.setState({ fileArrNew: {} });
    this.setState({ fileArrMap: {} });
    this.setState({ contactNumber: [] });
    this.setState({ flag: true });
    this.setState({ address: "" });
    this.setState({ flag1: true });
    this.setState({
      geolocObject: {
        location: "",
        latitude: "",
        longitude: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        place_id: "",
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    var arr = [];
    let { geolocObject } = this.state;

    if (this.state.flag === true) {
      if (!_.isEmpty(this.props.storeContacts)) {
        if (
          this.props.storeContacts.contactnumber !== "" &&
          this.props.storeContacts.contactnumber !== null
        ) {
          // split -> contactnumber,anothercontactnumber,...

          let contactCodeArr = _.split(
            this.props.storeContacts.contactcode,
            ","
          ); // String to array..  String - "in,us,in,us,vc,in",
          _.split(this.props.storeContacts.contactnumber, ",").forEach(
            (field, index) => {
              let innnerObj = {
                name: "",
                value: "",
                index: "",
              };
              let name = "contactnumber_" + index;
              let value = field;

              innnerObj["name"] = name;
              innnerObj["value"] = value;
              innnerObj["index"] = index;
              innnerObj["ccode"] = contactCodeArr[index]; // It will insert each country code respect to each number

              arr.push(innnerObj);
            }
          );
          this.setState({ flag: false });

          this.setState({
            contactNumber: arr,
          });
        }
      }

      if (!_.isEmpty(this.props.initialValues)) {
        // Geo Location array
        geolocObject["zipcode"] = this.props.initialValues.zipcode;
        geolocObject["place_id"] = this.props.initialValues.placeid;
        geolocObject["country"] = this.props.initialValues.country;
        geolocObject["state"] = this.props.initialValues.state;
        geolocObject["city"] = this.props.initialValues.city;
        geolocObject["location"] = this.props.initialValues.location;
        geolocObject["latitude"] = this.props.initialValues.latitude;
        geolocObject["longitude"] = this.props.initialValues.longitude;
      }
    }
  }

  /**** Modal Open Function  ****/
  openStoreInfoMapModal() {
    this.props.isStoreInfoMapModalOpen(true);
  }

  /**** Update geolocObject state ****/
  updateGeolocObject = async (lat, lng, address) => {
    let that = this;
    const reader = new FileReader();
    let { geolocObject, fileArrNew } = this.state;

    let location = address;
    let zipcode = "";
    let place_id = "";
    let country = "";
    let state = "";
    let city = "";
    let latitude = "";
    let longitude = "";

    this.setState({ address });

    geocodeByAddress(address)
      .then((results) => {
        _.forEach(results[0].address_components, function (value) {
          // postal_code
          if (_.indexOf(value.types, "postal_code") > -1) {
            zipcode = value.long_name;
          }

          // country
          if (_.indexOf(value.types, "country") > -1) {
            country = value.long_name;
          }

          // state / administrative_area_level_1
          if (_.indexOf(value.types, "administrative_area_level_1") > -1) {
            state = value.long_name;
          }

          // state / administrative_area_level_2
          if (_.indexOf(value.types, "administrative_area_level_2") > -1) {
            city = value.long_name;
          }
        });
        place_id = results[0].place_id; // place_id / zipcode

        geolocObject["zipcode"] = zipcode;
        geolocObject["place_id"] = place_id;
        geolocObject["country"] = country;
        geolocObject["state"] = state;
        geolocObject["city"] = city;
        geolocObject["location"] = location;

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        latitude = latLng.lat;
        longitude = latLng.lng;

        geolocObject["latitude"] = latitude;
        geolocObject["longitude"] = longitude;

        this.setState({ geolocObject: geolocObject });
      })
      .catch((error) => console.error("Error", error));

    // Store Image
    let filearr = [];
    let mapImage = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue|${lat},${lng}&zoom=14&size=400x400&key=${GOOGLE_MAP_KEY}`;

    that
      .urltoFile(mapImage, "storeimage.jpeg", "image/jpeg")
      .then(function (file) {
        reader.readAsArrayBuffer(file);

        reader.onload = (e) => {
          file.url = e.target.result;

          filearr.push(file);

          that.setState({ fileArrMap: filearr });
        };
      });
  };

  /**** Dynamic Contact Number Inut on Change ****/
  phoneChange = (value, country, e, formattedValue, index) => {
    let { contactNumber } = this.state;

    let curIndex = parseInt(index);
    if (!_.isEmpty(formattedValue)) {
      contactNumber[curIndex].value = formattedValue;
      contactNumber[curIndex].ccode = country.countryCode; // in,ar
      this.setState({ contactNumber: contactNumber }); // Set To state
      this.props.countryPhoneLength(country.dialCode);
    }
  };

  /**** Delete Contact Number Field ****/
  deleteContactBtnClick(e, object) {
    let { contactNumber } = this.state;

    confirmAlert({
      title: LocaleStrings.delete_contact_confirm,
      message: `${LocaleStrings.delete_contact_number_confirm_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            let { contactNumber } = this.state;

            let curIndex = _.findIndex(contactNumber, function (o) {
              return o.index === object.index;
            });

            /***** FOR DELETE CONCEPT *****/
            contactNumber.splice(curIndex, 1); // first element removed

            this.setState({ contactNumber: contactNumber });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  /**** Add Contact Number ****/
  _handelAddContact() {
    let { contactNumber } = this.state;

    if (_.isEmpty(contactNumber)) {
      // If No Contact number already there
      contactNumber = [];
    }
    let newIndex = _.isEmpty(contactNumber)
      ? 0
      : parseInt(contactNumber.length) - 1;

    let dynamicIndex = _.isEmpty(contactNumber)
      ? 1
      : contactNumber[newIndex].index + 1;

    let innnerObj = {
      name: "contactnumber_" + dynamicIndex,
      value: "",
      index: dynamicIndex,
      ccode: "",
    };
    this.props.autofill("contactnumber_" + dynamicIndex, "");
    contactNumber.push(innnerObj);
    this.setState({
      contactNumber: contactNumber,
    });
  }

  // Add File
  onDrop = async (files) => {
    if (files !== null && files !== "" && files !== "null") {
      const file = await this.getFileFromUrl(files, "text.jpg");

      let filearr = [];

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        file.url = e.target.result;
        // file.name   = file.name;
        // file.url = reader.result;
        filearr.push(file);
      };

      this.setState({ fileArrNew: filearr });
      this.setState({ fileUrl: URL.createObjectURL(file) });
    }

    // let file = files[0];
    // let filearr = [];

    // const reader = new FileReader();
    // reader.readAsArrayBuffer(file);

    // reader.onload = (e) => {
    //   file.url = e.target.result;
    //   // file.name   = file.name;
    //   // file.url = reader.result;
    //   filearr.push(file);
    // };

    // this.setState({ fileArrNew: filearr });
    // this.setState({ fileUrl: URL.createObjectURL(file) });
  };

  async getFileFromUrl(url, name, defaultType = "image/jpeg") {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    let {
      storeCoverReset,
      storeCoverAvailableOnDrop,
      storeCoverAvailableLocally,
    } = this.state;
    // console.log('values',values);
    let phoneerror = 0;
    _.forEach(this.state.contactNumber, function (value, index) {
      if (value.value.length < 5) {
        phoneerror++;
      }
    });
    if (!phoneerror == 0) {
      toast.error(LocaleStrings.store_information_phoneno_error_alert);
      return false;
    }
    /* Store image validation  [For new user]*/
    if (
      (values.coverimage === null &&
        !storeCoverReset &&
        !storeCoverAvailableOnDrop &&
        !storeCoverAvailableLocally) ||
      (values.coverimage === null &&
        !storeCoverReset &&
        storeCoverAvailableOnDrop &&
        !storeCoverAvailableLocally)
    ) {
      toast.error(LocaleStrings.store_information_upload_store_image_warning);
      return false;
    }

    /* Store image validation  [For Old user]*/
    if (
      (storeCoverReset &&
        !storeCoverAvailableOnDrop &&
        !storeCoverAvailableLocally) ||
      (storeCoverReset &&
        storeCoverAvailableOnDrop &&
        !storeCoverAvailableLocally)
    ) {
      toast.error(LocaleStrings.store_information_upload_store_image_warning);
      return false;
    }

    // if((values.coverimage === null) && )

    // Variables
    let resource_coverimage = "";
    let resource_mapimage = "";
    let uploaderCounter = 0;
    let filetobeupload = [];
    let arrPromise = [];
    this.setState({ btnLoader: true });
    let that = this;
    let { geolocObject } = this.state;
    let { storeDetails } = this.props;

    /* Validations IF NOT QATAR */
    if (
      (geolocObject.latitude === null ||
        geolocObject.latitude === "null" ||
        geolocObject.latitude === "" ||
        geolocObject.latitude === undefined) &&
      values.country !== "Qatar"
    ) {
      toast.error(LocaleStrings.store_location_verfication_text);
      this.setState({ btnLoader: false });
      return false;
    }

    /* Take Contact Numbers */
    let contactsString = _.join(
      this.state.contactNumber.map((item, index) => {
        return removePhoneNoSpecialChar(item.value);
      }),
      ","
    );

    /* Take Contact Code */
    let contactsCodeString = _.join(
      this.state.contactNumber.map((item, index) => {
        return item.ccode;
      }),
      ","
    );

    if (_.isEmpty(storeDetails)) {
      /* ADD */

      if (
        Object.keys(this.state.fileArrMap).length > 0 ||
        Object.keys(this.state.fileArrNew).length > 0
      ) {
        // With Cover Image
        if (Object.keys(this.state.fileArrNew).length > 0) {
          // Image Exist Store Image
          let fileArrNew = this.state.fileArrNew[0];

          let ts = new Date().getTime(); // Timestamp

          var medianame = "storecover_" + ts + "." + "jpeg"; // Image Name Creation
          resource_coverimage = medianame;

          let ext = fileArrNew.name.split(".").pop(); // File extension

          let coverJson = {
            // type: "cover",
            type: "image",
            name: medianame,
            media: fileArrNew.url,
          };
          filetobeupload.push(coverJson);
          var counter = filetobeupload.length;

          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                this.props.uploadMediaLarge(
                  this.props.session,
                  item,
                  (response) => {
                    if (response.success === 0) {
                      resolve();
                    } else {
                      uploaderCounter++;
                      resolve();
                    }
                  }
                );
              })
            );
          });
        }

        // Map Image
        if (Object.keys(this.state.fileArrMap).length > 0) {
          // Image Exist Map Image
          let fileArrMap = this.state.fileArrMap[0];

          let ts = new Date().getTime(); // Timestamp

          var medianame = "storemap_" + ts + "." + "jpeg"; // Image Name Creation
          resource_mapimage = medianame;

          let ext = fileArrMap.name.split(".").pop(); // File extension

          let coverJson = {
            // type: "cover",
            type: "image",
            name: medianame,
            media: fileArrMap.url,
          };
          filetobeupload.push(coverJson);
          var counter = filetobeupload.length;

          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                this.props.uploadMediaLarge(
                  this.props.session,
                  item,
                  (response) => {
                    if (response.success === 0) {
                      resolve();
                    } else {
                      uploaderCounter++;
                      resolve();
                    }
                  }
                );
              })
            );
          });
        }

        Promise.all(arrPromise).then(() => {
          // Store Object
          let storeObject = {
            coverimage: resource_coverimage,
            mapimage: resource_mapimage,
            // awayenddate:
            //   values.awayenddate !== null
            //     ? moment(values.awayenddate).format(
            //         DATE_FORMAT_WITH_TIME_WITH_SECOND
            //       )
            //     : values.awayenddate,
            // awaystartdate:
            //   values.awaystartdate !== null
            //     ? moment(values.awaystartdate).format(
            //         DATE_FORMAT_WITH_TIME_WITH_SECOND
            //       )
            //     : values.awaystartdate, oldcode
            awayenddate:
              values.awayenddate && values.awayenddate !== null
                ? moment(values.awayenddate).format(
                    DATE_FORMAT_WITH_TIME_WITH_SECOND
                  )
                : null,
            awaystartdate:
              values.awaystartdate && values.awaystartdate !== null
                ? moment(values.awaystartdate).format(
                    DATE_FORMAT_WITH_TIME_WITH_SECOND
                  )
                : null, //latest code
            automessage: values.automessage,
            isverified: 0,
            createdby: this.props.session.userid,
            location:
              values.country === "Qatar"
                ? values.building_no +
                  "~" +
                  // values.zone_no. + //old code
                  values.zone_no.label + //latest code
                  "~" +
                  values.street_no
                : geolocObject.location,
            zone: values.country === "Qatar" ? values.zone_no.value : null,
            latitude:
              values.country === "Qatar" ? QATAR_LAT : geolocObject.latitude,
            longitude:
              values.country === "Qatar" ? QATAR_LONG : geolocObject.longitude,
            city: values.country === "Qatar" ? "" : geolocObject.city,
            country:
              values.country === "Qatar"
                ? values.country
                : geolocObject.country,
            state: values.country === "Qatar" ? "" : geolocObject.state,
            zipcode:
              values.country === "Qatar"
                ? values.zipcode
                : geolocObject.zipcode,
            pobox: values.country === "Qatar" ? values.pobox : null,
            place_id: values.country === "Qatar" ? "" : geolocObject.place_id,
          };

          // Map array not present
          if (Object.keys(this.state.fileArrMap).length > 0) {
          } else {
            storeObject = _.omit(storeObject, "mapimage"); //It will return a new object
          }

          // Cover array not present
          if (Object.keys(this.state.fileArrNew).length > 0) {
          } else {
            storeObject = _.omit(storeObject, "coverimage"); //It will return a new object
          }

          // Store Contacts Object
          let storeContactObject = {
            storeid: "",
            contactname: values.contactname,
            contactnumber: removePhoneNoSpecialChar(contactsString),
            contactcode: contactsCodeString,
          };

          //if (counter == uploaderCounter) {
          // After file upload
          this.props.addStore(
            this.props.session,
            storeObject,
            storeContactObject,
            (callback) => {
              if (callback.status === 1) {
                this.setState({ btnLoader: false });
                this.setState({ fileArr: {} });
                this.setState({ fileArrNew: {} });
                this.setState({ fileArrMap: {} });
                toast.success(LocaleStrings.store_updated_successfully);
                /**** Fetch Store Information ****/
                this.props.fetchStoreDetails(
                  this.props.session,
                  (callback) => {}
                );
              }
            }
          );
          // }
        });
      } else {
        // Without Image

        let storeObject = {
          // awayenddate: moment(values.awayenddate).format(
          //   DATE_FORMAT_WITH_TIME_WITH_SECOND
          // ),
          // awaystartdate: moment(values.awaystartdate).format(
          //   DATE_FORMAT_WITH_TIME_WITH_SECOND
          // ), oldcode
          awayenddate:
            values.awayenddate && values.awayenddate !== null
              ? moment(values.awayenddate).format(
                  DATE_FORMAT_WITH_TIME_WITH_SECOND
                )
              : null,
          awaystartdate:
            values.awaystartdate && values.awaystartdate !== null
              ? moment(values.awaystartdate).format(
                  DATE_FORMAT_WITH_TIME_WITH_SECOND
                )
              : null, //latest code
          automessage: values.automessage,
          isverified: 0,
          createdby: this.props.session.userid,
          location:
            values.country === "Qatar"
              ? values.building_no +
                "~" +
                // values.zone_no. + //old code
                values.zone_no.label + //latest code
                "~" +
                values.street_no
              : geolocObject.location,
          zone: values.country === "Qatar" ? values.zone_no.value : null,
          latitude:
            values.country === "Qatar" ? QATAR_LAT : geolocObject.latitude,
          longitude:
            values.country === "Qatar" ? QATAR_LONG : geolocObject.longitude,
          city: values.country === "Qatar" ? "" : geolocObject.city,
          country:
            values.country === "Qatar" ? values.country : geolocObject.country,
          state: values.country === "Qatar" ? "" : geolocObject.state,
          zipcode:
            values.country === "Qatar" ? values.zipcode : geolocObject.zipcode,
          pobox: values.country === "Qatar" ? values.pobox : null,
          place_id: values.country === "Qatar" ? "" : geolocObject.place_id,
        };

        /* Store Contacts Object */
        let storeContactObject = {
          storeid: "",
          contactname: values.contactname,
          contactnumber: removePhoneNoSpecialChar(contactsString),
          contactcode: contactsCodeString,
        };

        this.props.addStore(
          this.props.session,
          storeObject,
          storeContactObject,
          (callback) => {
            if (callback.status === 1) {
              this.setState({ btnLoader: false });
              toast.success(LocaleStrings.store_updated_successfully);
              /**** Fetch Store Information ****/
              this.props.fetchStoreDetails(
                this.props.session,
                (callback) => {}
              );
            }
          }
        );
      }
    } else {
      /* UPDATE */

      if (
        Object.keys(this.state.fileArrMap).length > 0 ||
        Object.keys(this.state.fileArrNew).length > 0
      ) {
        // Cover Image
        if (Object.keys(this.state.fileArrNew).length > 0) {
          // Image Exist Store Image
          let fileArrNew = this.state.fileArrNew[0];

          let ts = new Date().getTime(); // Timestamp

          var medianame = "storecover_" + ts + "." + "jpeg"; // Image Name Creation
          resource_coverimage = medianame;

          let ext = fileArrNew.name.split(".").pop(); // File extension

          let coverJson = {
            // type: "cover",
            type: "image",
            name: medianame,
            media: fileArrNew.url,
          };
          filetobeupload.push(coverJson);
          var counter = filetobeupload.length;

          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                this.props.uploadMediaLarge(
                  this.props.session,
                  item,
                  (response) => {
                    if (response.success === 0) {
                      resolve();
                    } else {
                      uploaderCounter++;
                      resolve();
                    }
                  }
                );
              })
            );
          });
        }

        // Map Image
        if (Object.keys(this.state.fileArrMap).length > 0) {
          // Image Exist Map Image
          let fileArrMap = this.state.fileArrMap[0];

          let ts = new Date().getTime(); // Timestamp

          var medianame = "storemap_" + ts + "." + "jpeg"; // Image Name Creation
          resource_mapimage = medianame;

          let ext = fileArrMap.name.split(".").pop(); // File extension

          let coverJson = {
            // type: "cover",
            type: "image",
            name: medianame,
            media: fileArrMap.url,
          };
          filetobeupload.push(coverJson);
          var counter = filetobeupload.length;

          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                this.props.uploadMediaLarge(
                  this.props.session,
                  item,
                  (response) => {
                    if (response.success === 0) {
                      resolve();
                    } else {
                      uploaderCounter++;
                      resolve();
                    }
                  }
                );
              })
            );
          });
        }

        Promise.all(arrPromise).then(() => {
          // Store Object
          let storeObject = {
            storeid: values.storeid,
            coverimage: resource_coverimage,
            mapimage: resource_mapimage,
            // awayenddate: moment(values.awayenddate).format(
            //   DATE_FORMAT_WITH_TIME_WITH_SECOND
            // ),
            // awaystartdate: moment(values.awaystartdate).format(
            //   DATE_FORMAT_WITH_TIME_WITH_SECOND
            // ),oldcode
            awayenddate:
              values.awayenddate && values.awayenddate !== null
                ? moment(values.awayenddate).format(
                    DATE_FORMAT_WITH_TIME_WITH_SECOND
                  )
                : null,
            awaystartdate:
              values.awaystartdate && values.awaystartdate !== null
                ? moment(values.awaystartdate).format(
                    DATE_FORMAT_WITH_TIME_WITH_SECOND
                  )
                : null, //latest code
            automessage: values.automessage,
            location:
              values.country === "Qatar"
                ? values.building_no +
                  "~" +
                  // values.zone_no. + //old code
                  values.zone_no.label + //latest code
                  "~" +
                  values.street_no
                : geolocObject.location,
            zone: values.country === "Qatar" ? values.zone_no.value : null,
            latitude:
              values.country === "Qatar" ? QATAR_LAT : geolocObject.latitude,
            longitude:
              values.country === "Qatar" ? QATAR_LONG : geolocObject.longitude,
            city: values.country === "Qatar" ? "" : geolocObject.city,
            country:
              values.country === "Qatar"
                ? values.country
                : geolocObject.country,
            state: values.country === "Qatar" ? "" : geolocObject.state,
            zipcode:
              values.country === "Qatar"
                ? values.zipcode
                : geolocObject.zipcode,
            pobox: values.country === "Qatar" ? values.pobox : null,
            place_id: values.country === "Qatar" ? "" : geolocObject.place_id,
          };

          // Map array not present
          if (Object.keys(this.state.fileArrMap).length > 0) {
          } else {
            storeObject = _.omit(storeObject, "mapimage"); //It will return a new object
          }

          // Cover array not present
          if (Object.keys(this.state.fileArrNew).length > 0) {
          } else {
            storeObject = _.omit(storeObject, "coverimage"); //It will return a new object
          }

          // Store Contacts Object
          let storeContactObject = {
            id: values.id,
            storeid: values.storeid,
            contactname: values.contactname,
            contactnumber: removePhoneNoSpecialChar(contactsString),
            contactcode: contactsCodeString,
          };

          //if (counter == uploaderCounter) {
          // After file upload
          this.props.updateStore(
            this.props.session,
            storeObject,
            storeContactObject,
            (callback) => {
              if (callback.status === 1) {
                this.setState({ btnLoader: false });
                this.setState({ fileArr: {} });
                this.setState({ fileArrNew: {} });
                this.setState({ fileArrMap: {} });
                toast.success(LocaleStrings.store_updated_successfully);
                /**** Fetch Store Information ****/
                this.props.fetchStoreDetails(
                  this.props.session,
                  (callback) => {}
                );
              }
            }
          );
          // }
        });
      } else {
        // Without Image

        let storeObject = {
          storeid: values.storeid,
          // awayenddate: moment(values.awayenddate).format(
          //   DATE_FORMAT_WITH_TIME_WITH_SECOND
          // ),
          // awaystartdate: moment(values.awaystartdate).format(
          //   DATE_FORMAT_WITH_TIME_WITH_SECOND
          // ),oldcode
          awayenddate:
            values.awayenddate && values.awayenddate !== null
              ? moment(values.awayenddate).format(
                  DATE_FORMAT_WITH_TIME_WITH_SECOND
                )
              : null,
          awaystartdate:
            values.awaystartdate && values.awaystartdate !== null
              ? moment(values.awaystartdate).format(
                  DATE_FORMAT_WITH_TIME_WITH_SECOND
                )
              : null, //latest code
          automessage: values.automessage,
          location:
            values.country === "Qatar"
              ? values.building_no +
                "~" +
                // values.zone_no. + //old code
                values.zone_no.label + //latest code
                "~" +
                values.street_no
              : geolocObject.location,
          zone: values.country === "Qatar" ? values.zone_no.value : null,
          latitude:
            values.country === "Qatar" ? QATAR_LAT : geolocObject.latitude,
          longitude:
            values.country === "Qatar" ? QATAR_LONG : geolocObject.longitude,
          city: values.country === "Qatar" ? "" : geolocObject.city,
          country:
            values.country === "Qatar" ? values.country : geolocObject.country,
          state: values.country === "Qatar" ? "" : geolocObject.state,
          zipcode:
            values.country === "Qatar" ? values.zipcode : geolocObject.zipcode,
          pobox: values.country === "Qatar" ? values.pobox : null,
          place_id: values.country === "Qatar" ? "" : geolocObject.place_id,
        };

        // Store Contacts Object
        let storeContactObject = {
          id: values.id,
          storeid: values.storeid,
          contactname: values.contactname,
          contactnumber: removePhoneNoSpecialChar(contactsString),
          contactcode: contactsCodeString,
        };

        this.props.updateStore(
          this.props.session,
          storeObject,
          storeContactObject,
          (callback) => {
            if (callback.status === 1) {
              this.setState({ btnLoader: false });
              toast.success(LocaleStrings.store_updated_successfully);
              /**** Fetch Store Information ****/
              this.props.fetchStoreDetails(
                this.props.session,
                (callback) => {}
              );
            }
          }
        );
      }
    } // Eit Else End
  }

  renderDynamicContactNumberList() {
    let innerNumArr = [];
    var item = _.map(this.state.contactNumber, (valObj, index) => {
      let dynamicLabelName =
        index === 0
          ? LocaleStrings.contact_number_label
          : LocaleStrings.another_number_label;

      innerNumArr.push(valObj.name);

      if (valObj.value !== null && valObj.value !== "") {
        this.props.autofill(valObj.name, valObj.value);
      }

      return (
        <div className="grid grid-cols-6 gap-4">
          <div className="mb-5 mt-2 col-span-5">
            <Field
              name={valObj.name}
              label={dynamicLabelName}
              mandatory="true"
              phoneChange={this.phoneChange}
              phoneIndex={index}
              type="text"
              component={this.renderPhonewithFlag}
              countryCodeEditable={false}
              className="cust-input-field"
              classNameLabel="text-box-lable"
            />
          </div>
          <div className="my-auto">
            {index !== 0 ? (
              <img
                loading="lazy"
                onClick={(e) => this.deleteContactBtnClick(e, valObj)}
                className={"sidebar-icons cursor-pointer"}
                src={"/images2/common/delete.svg"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });

    phoneFieldNames = innerNumArr;
    return item;
  }

  storeCoverAvailableOnDrop = (available) => {
    if (available !== undefined)
      this.setState({ storeCoverAvailableOnDrop: available });
  };

  /**** Store cover image ****/
  onStoreCoverAvailable = (available) => {
    if (available !== undefined)
      this.setState({
        storeCoverAvailableLocally: available,
        storeCoverAvailableOnDrop: available,
      });
  };

  /*** On Drop store image ***/
  onDropStoreCover(files) {
    // console.log("files", files);
    this.setState({ storeEventCover: files, storeCoverReset: true });

    this.props.updateStoreCoverLocally(files);

    this.onDrop(files);
  }

  /* Back -> cancel image upload */
  cancelImageUpload = (event) => {
    let { initialValues } = this.props;
    let { storeCoverReset } = this.state;

    let previewUrl = "";
    if (!_.isEmpty(initialValues)) {
      previewUrl = `${BASE_IMAGES_URL}/store/${initialValues.coverimage}?api_key=${APP_API_KEY}`;
      this.setState({
        storeCoverReset: false,
        storeCoverAvailableOnDrop: false,
        storeCoverPreviewUrl: previewUrl,
      });
    } else {
    }
  };

  /* Handle Country change */
  _handelCountryChange = (event) => {
    let { geolocObject } = this.state;
    if (event.target.value !== "") {
      geolocObject.country = event.target.value;

      if (event.target.value !== "Qatar") {
        geolocObject.location = "";
        geolocObject.latitude = "";
        geolocObject.latitude = "";
        geolocObject.city = "";
        geolocObject.state = "";
        geolocObject.zipcode = "";
        geolocObject.place_id = "";
        geolocObject.country = "";
      }
      // console.log("geolocObject1", geolocObject);
      this.setState({ geolocObject });
    }
  };

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      formValues,
      storeCountries,
      zoneAndpincodes,
    } = this.props;
    let {
      fileUrl,
      address,
      flag1,
      geolocObject,
      storeCoverAvailableOnDrop,
      storeCoverReset,
    } = this.state;

    var zonePincodeOptions = []; // Zone and pin code list
    // Zone and pin code list objects creation
    _.forEach(zoneAndpincodes, (val, index) => {
      var obj = {};
      // latest code
      obj = {
        label: val.zone,
        value: val.id,
      };
      zonePincodeOptions.push(obj);
    });
    let coverimage = "";
    let mapImage = "";

    let storeCoverPreviewUrl = "";

    let pointedAddress = "";
    if ((address === "" || address === null) && flag1 === true) {
      if (!_.isEmpty(initialValues)) {
        pointedAddress = initialValues.location;
      } else {
        pointedAddress = "";
      }
    } else {
      pointedAddress = address;
    }

    if (!_.isEmpty(initialValues) || geolocObject?.latitude !== undefined) {
      if (initialValues?.latitude !== geolocObject?.latitude) {
        mapImage = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue|${geolocObject?.latitude},${geolocObject?.longitude}&zoom=14&size=400x400&key=${GOOGLE_MAP_KEY}`;
      } else {
        mapImage = `${BASE_IMAGES_URL}/store/${initialValues?.mapimage}?api_key=${APP_API_KEY}`;
      }
    }

    var countriesOptions = []; // Countries array
    _.forEach(storeCountries, function (value) {
      var obj = {
        name: value.name,
        value: value.name,
      };
      countriesOptions.push(obj);
    });

    // Map Image

    // Store Image // Old code
    // if (fileUrl === "" || coverimage === "null" || coverimage === null) {
    //   if (!_.isEmpty(initialValues)) {
    //     coverimage = `${BASE_IMAGES_URL}/store/${initialValues.coverimage}?api_key=${APP_API_KEY}`;
    //     storeCoverPreviewUrl = `${BASE_IMAGES_URL}/store/${initialValues.coverimage}?api_key=${APP_API_KEY}`;
    //     storeCoverAvailableOnDrop = true;
    //   } else {
    //     coverimage = "";
    //   }
    // } else {
    //   coverimage = fileUrl;
    // }

    if (
      !_.isEmpty(initialValues) &&
      !storeCoverReset &&
      initialValues.coverimage !== null
    ) {
      storeCoverPreviewUrl = `${BASE_IMAGES_URL}/store/${initialValues.coverimage}?api_key=${APP_API_KEY}`;
      storeCoverAvailableOnDrop = true;
    } else {
    }

    return (
      <>
        <div className=" xs:px-2 sm:px-10 2xl:px-48">
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
              <div className="p-10 space-y-8">
                <div className="grid grid-cols-3 gap-4">
                  <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                    {/* <div className="dropzonecover" key={`drop`}>
                      <div className="img_wrpcover relative">
                        <img
                        loading="lazy"
                          src={coverimage}
                          className="dropimgcover"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images2/common/demo.jpeg";
                          }}
                        />
                      </div>
                       <Dropzone
                        onDrop={(e) => this.onDrop(e)}
                        accept="image/jpeg, image/png ,image.jpg"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="h-full w-full absolute top-0"
                          >
                            <input {...getInputProps()} />
                            {this.state.imageLoader ? (
                              <i className="fa fa-refresh fa-spin"></i>
                            ) : (
                              <p className="paragraph-seconday text-xs">
                                {LocaleStrings.drop_or_select}
                              </p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div> */}
                    {/* Store information image Start */}

                    <div className="form-group formdiv">
                      <div className="col-sm-12 col-sm-offset-0 imgPreview">
                        <section>
                          <StoreInlineCropper
                            imgwidth={750}
                            imgheight={360}
                            width={395}
                            height={220}
                            border={2}
                            onImageSave={this.onDropStoreCover}
                            onImageAvailableLocally={this.onStoreCoverAvailable}
                            onImageDropped={this.storeCoverAvailableOnDrop}
                            imagepath={storeCoverPreviewUrl}
                            imageType="image/jpeg, image/png ,image.jpg"
                            className="bigdropbox"
                            innerText={
                              <div className="drop-text-extended mt-16">
                                <div className="flex justify-center">
                                  <img
                                    loading="lazy"
                                    src={`/images2/common/picture.png`}
                                  />
                                </div>
                                <div className="text-xs font-medium text-ternary">
                                  {LocaleStrings.drop_or_select} <br />
                                  {LocaleStrings.or}{" "}
                                  <span className="select-file">
                                    {LocaleStrings.select_file}
                                  </span>
                                </div>
                              </div>
                            }
                            ref={this.coverCropperRef}
                          />
                          <Field
                            name="eventcover"
                            component="input"
                            type="hidden"
                          />
                        </section>

                        {storeCoverAvailableOnDrop ? (
                          <div style={{ height: 20 }}>&nbsp;</div>
                        ) : (
                          <div className="">
                            <div className="">
                              <p className="text-xs font-medium text-ternary ">
                                {" "}
                                - {
                                  LocaleStrings.store_image_upload_icon_notes1
                                }{" "}
                              </p>
                              <p className="text-xs font-medium text-ternary ">
                                {" "}
                                - {
                                  LocaleStrings.store_image_upload_icon_notes2
                                }{" "}
                              </p>
                            </div>
                            {initialValues?.coverimage ? (
                              <button
                                type="button"
                                className="tbl-btn-danger mt-2 "
                                onClick={(e) => this.cancelImageUpload(e)}>
                                <span className="icon-hover-transition">
                                  {LocaleStrings.cancel_upload}
                                </span>
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Store information image Ends */}
                  </div>
                </div>
                <div>
                  <Field
                    name="contactname"
                    label={LocaleStrings.contact_person_label}
                    placeholder={LocaleStrings.contact_person_placeholder}
                    type="text"
                    component={this.renderFieldText}
                    mandatory="true"
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-sm font-medium text-secondary"
                  />
                </div>
                {/* Contact Number  */}
                {this.renderDynamicContactNumberList()}
                <div className="text-right">
                  <span
                    className="underline text-primaryLight cursor-pointer"
                    onClick={(e) => this._handelAddContact()}>
                    {LocaleStrings.plus_add_another_number}
                  </span>
                </div>
                {/* Country Name */}
                <Field
                  name="country"
                  label={LocaleStrings.country}
                  placeholder={LocaleStrings.select_country}
                  component={this.renderSelect}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  opts={countriesOptions}
                  className="cust-input-field2"
                  onChange={(e) => this._handelCountryChange(e)}
                  classNameLabel="block text-box-lable"
                />

                {geolocObject?.country == "Qatar" ? (
                  <>
                    <Field
                      name="building_no"
                      label={LocaleStrings.building_no}
                      placeholder={LocaleStrings.enter_building_no}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                    {/* old code */}
                    {/* <Field
                      name="zone_no"
                      label={LocaleStrings.zone_no}
                      placeholder={LocaleStrings.enter_zone_no}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    /> */}
                    {/* latest code */}
                    <Field
                      name="zone_no"
                      label={LocaleStrings.zone_no}
                      placeholder={LocaleStrings.zone_no}
                      options={zonePincodeOptions}
                      component={this.renderAutoComplete1}
                      labelposition={LABEL_POSITION_TOP}
                      labelKey="label"
                      valueKey="value"
                      className="mt-2 ring-2 ring-red-500"
                      classNameLabel="text-box-lable"
                      isMulti={false}
                    />
                    <Field
                      name="street_no"
                      label={LocaleStrings.street_no}
                      placeholder={LocaleStrings.enter_street_no}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                    <Field
                      name="zipcode"
                      label={LocaleStrings.unit_no}
                      placeholder={LocaleStrings.enter_unit_no}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                    <Field
                      name="pobox"
                      label={LocaleStrings.pobox_no}
                      placeholder={LocaleStrings.enter_pobox_no}
                      type="text"
                      component={this.renderFieldText}
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                  </>
                ) : (
                  <>
                    {/* Auto Complete Map */}
                    <div>
                      <p className="text-sm font-medium text-secondary truncate pb-4">
                        {LocaleStrings.location_header}
                      </p>
                      <p className="text-sm text-ternary truncate pb-1">
                        {LocaleStrings.store_location_text}
                      </p>
                      <PlacesAutocomplete
                        value={pointedAddress}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}>
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: LocaleStrings.search_places,
                                className: "location-search-input",
                              })}
                            />
                            <div
                              className={
                                Object.keys(suggestions).length > 0
                                  ? "autocomplete-dropdown-container"
                                  : "hidden"
                              }>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    key={suggestion.index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    {/* Google map */}
                    {!_.isEmpty(initialValues) ||
                    geolocObject?.latitude !== undefined ? (
                      <div className="grid grid-cols-3 gap-4">
                        <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                          <div className="aspect-w-3 aspect-h-2">
                            <img
                              loading="lazy"
                              className="object-cover shadow-lg rounded-lg cursor-pointer"
                              src={mapImage}
                              alt=""
                              onClick={(e) => this.openStoreInfoMapModal()}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
                <div className="">
                  <p className="text-sm font-medium text-secondary truncate">
                    {LocaleStrings.select_away_time}
                  </p>
                </div>
                <div>
                  <Field
                    name="awaystartdate"
                    label={LocaleStrings.store_info_start_date_label}
                    component={this.renderDateTimePickerWithLabel1}
                    mandatory="false"
                    autoComplete="off"
                    minDate={
                      Object.keys(initialValues).length > 0
                        ? ""
                        : new Date(moment().format(MIN_DATE_FORMAT))
                    }
                    // selected={moment()}
                    className="cust-input-field"
                    classNameLabel="text-box-lable"
                  />
                </div>
                <div>
                  <Field
                    name="awayenddate"
                    label={LocaleStrings.store_info_end_date_label}
                    component={this.renderDateTimePickerWithLabel1}
                    mandatory="false"
                    minDate={
                      Object.keys(initialValues).length > 0
                        ? formValues &&
                          formValues.awaystartdate &&
                          formValues.awaystartdate !== "0000-00-00 00:00:00"
                          ? formValues.awaystartdate
                          : ""
                        : new Date(moment().format(MIN_DATE_FORMAT))
                    }
                    // selected={moment()}
                    className="cust-input-field"
                    classNameLabel="text-box-lable"
                  />
                </div>
                <div>
                  <p className="text-sm font-medium text-secondary ">
                    {LocaleStrings.set_automatic_response_text}
                  </p>
                  <Field
                    name="automessage"
                    label={LocaleStrings.set_automatic_response_subtext}
                    placeholder={
                      LocaleStrings.set_automatic_response_placeholder
                    }
                    mandatory="true"
                    component={this.renderFieldTextarea}
                    maxlength={200}
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-box-lable"
                    className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                    onChange={this.handleProductSubTitleKeyUp}
                  />
                </div>
              </div>

              <div className="p-6 border-t-2 border-quaternary">
                <div className="grid grid-cols-3 gap-4">
                  <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                    <button className="btn-primary" type="submit">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "hidden",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.update_store_info}
                    </button>
                  </div>
                </div>

                {/* Footer */}
              </div>
            </form>
          </div>
        </div>

        {this.props.storeInfoMapModal ? (
          <StoreMap
            intialData={this.state.geolocObject}
            handleOutterScreen={this.updateGeolocObject}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function validate(values, ownProps) {
  var errors = {};
  var errors = { phoneFieldNames: [] };
  var requiredFields = [
    "automessage",
    "contactname",
    "criteriaid",
    "country",
    "building_no",
    "zone_no",
    "street_no",
    "zipcode",
  ].concat(phoneFieldNames);

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  phoneFieldNames?.forEach((v, i) => {
    let countrycode = ownProps.phoneLengthCountry;
    // console.log("countrycode", countrycode);
    if (countrycode == "974" && values[v] && values[v] != "") {
      let phone = values[v].replace(/[- )( +]/g, "");
      if (isValidQatarPhoneNumber("+" + phone) == false) {
        errors[v] = "Please enter valid phone number";
      }
    }
  });
  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  var newObj = {};

  if (!_.isEmpty(state.storeDetails)) {
    newObj = { ...state.storeDetails.data, ...state.storeContacts.data };
    // let countryName = newObj.country; //Country name
    // newObj.country = { name: countryName, value: countryName };
    if (newObj.country === "Qatar") {
      // latest code
      let obj = {};
      if (newObj.zone_master_by_zone) {
        obj.value = newObj.zone_master_by_zone.id;
        obj.label = newObj.zone_master_by_zone.zone;
      }
      // If not Qatar
      // Search and select address *, manual address line 2, Prefill - City *, State * and Zip * from selected address.
      let newLocArr = _.split(newObj.location, "~", 3);
      newObj["building_no"] = newLocArr[0]; // pos 0
      // newObj["zone_no"] = newLocArr[1]; // pos 1 old code
      newObj["zone_no"] = obj; // latest code
      newObj["street_no"] = newLocArr[2]; // pos 2
    }

    if (
      newObj.awaystartdate &&
      newObj.awaystartdate === "0000-00-00 00:00:00"
    ) {
      newObj.awaystartdate = null;
    }
    if (newObj.awayenddate && newObj.awayenddate === "0000-00-00 00:00:00") {
      newObj.awayenddate = null;
    }
  }

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    initialValues: newObj,
    phoneLengthCountry: state.phoneLengthCountry,
    storeInfoMapModal: state.isOpenStoreInfoMapModal,
    storeContacts: !_.isEmpty(state.storeContacts)
      ? !_.isEmpty(state.storeContacts.data)
        ? state.storeContacts.data
        : []
      : [],
    storeDetails: !_.isEmpty(state.storeDetails)
      ? !_.isEmpty(state.storeDetails.data)
        ? state.storeDetails.data
        : {}
      : {},
    storeCountries: !_.isEmpty(state.storeCountries)
      ? !_.isEmpty(state.storeCountries.data)
        ? state.storeCountries.data
        : []
      : [],
    formValues: getFormValues("updateStoreInfoForm")(state),
    zoneAndpincodes: state.zoneAndpincodes?.data,
  };
}

export default connect(mapStateToProps, {
  uploadMediaLarge,
  updateStore,
  addStore,
  isStoreInfoMapModalOpen,
  fetchStoreDetails,
  updateStoreCoverLocally,
  fetchCountries,
  countryPhoneLength,
})(
  reduxForm({
    validate,
    form: "updateStoreInfoForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(StoreInfoeEdit)
);
