/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import LocaleStrings from "../components/languages";
function SingleImageModal(props) {
  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={props.setisOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle 
             sm:p-6 bigmodal"
            >
              {/* MODAL HEADER */}
              <div className="grid grid-cols-6 border-b-2 border-quaternary pb-4 sticky-header z-10">
                <div></div>
                <div className="flex flex-wrap content-center justify-center mt-4 col-span-4">
                  <div className="text-lg leading-6 font-medium text-secondary  h-12"></div>
                </div>
                <div className="absolute top-0 right-0 pt-4 pr-4 ">
                  <button
                    type="button"
                    className="btn-closeModal"
                    onClick={() => props.setisOpen(false)}
                  >
                    <span className="sr-only">{LocaleStrings.close}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="center-item">
                {/* <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"></div> */}
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-secondary"
                  ></Dialog.Title>
                  <div className="mt-2">
                    <img
                      loading="lazy"
                      className="object-cover  xs:w-full xs:h-full"
                      src={props.currentImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="btn-primary"
                  onClick={() => props.setisOpen(false)}
                >
                  {LocaleStrings.close}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state) {
  var { session } = state;

  return {
    session,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {})(SingleImageModal);
