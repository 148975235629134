import React, { Component } from "react";
import { connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LocaleStrings from "@language";
// import { createBrowserHistory } from "history";
import thunk from "redux-thunk";

import { loadingBarMiddleware } from "react-redux-loading-bar";
import { errorBarMiddleware } from "./common/errorbar/index";

import Login from "./components/starter/login.js";
import Register from "./components/starter/register.js";
import Sidebar from "@sidebar";
import reducers from "./reducers";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import { loadState, saveState } from "./localStorage";

var persistedState = loadState();

var createStoreWithMiddleware = applyMiddleware(
  thunk,
  loadingBarMiddleware(),
  errorBarMiddleware()
)(createStore);

export var store = createStoreWithMiddleware(reducers, persistedState);

store.subscribe(() => {
  saveState(store.getState());
});

class App extends Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
    }

    if (this.props.language === "ar") {
      LocaleStrings.setLanguage("ar");
    } else {
      LocaleStrings.setLanguage("en");
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
    }

    return (
      <div className="full-height">
        <TransitionGroup>
          <CSSTransition
            transitionName="example"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
            timeout={300}>
            <BrowserRouter>
              <Switch>
                <Route
                  path="/register"
                  component={(props) => <Register {...props} key="2" />}
                />
                <Route
                  path="/dashboard"
                  component={(props) => <Sidebar {...props} key="3" />}
                />
                <Route
                  path="/"
                  component={(props) => <Login {...props} key="1" />}
                />
              </Switch>
            </BrowserRouter>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    language: state.language,
  };
}

export default connect(mapStateToProps)(App);
