import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { converDateIntoLocal } from "@commonFunction";
// Import Components
import BaseLoader from "@baseLoader";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
import ReportAbuse from "./report-abuse";
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  DATE_FORMAT_TO_SHOW_SHORT,
} from "@constant";
import FollowerDetails from "./followerdetails";
import NoDataFoundComponet from "@noDataFound";

// Import ACtions
import {
  fetchMyFollowers,
  isFollowersDetailsOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  fetchMyFolloings,
  profileViewNavigation,
  viewPublicProfile,
} from "@profileSettingsActions";

class MyFollowers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_my_followers,
    };

    // All Binded Functions
  }

  componentDidMount() {
    /**** Fetch My Followers ****/
    this.props.fetchMyFollowers(this.props.session, (callback) => {
      this.setState({ loading: false });
    });

    /**** Fetch My Following ****/
    this.props.fetchMyFolloings(this.props.session, (callback) => {
      //this.setState({loading:false});
    });
  }

  /**** Followers Details Modal Open Fc ****/
  openFollowersDelaisModal(valObj) {
    let { session, navigateUser } = this.props;
    let userArr = navigateUser;
    this.props.isFollowersDetailsOpen(true);

    this.props.selectedFollowerUserDetails(
      this.props.session,
      valObj.followerid,
      (callback) => {}
    );

    this.props.selectedFollowersDetails(
      this.props.session,
      valObj.followerid,
      (callback) => {}
    );

    /* Sheared wish list for this user */
    this.props.selectedFollowersShearedWishlist(
      this.props.session,
      valObj.followerid,
      (callback) => {}
    );

    this.props.viewPublicProfile(session, valObj.followerid);

    if (!_.includes(userArr, valObj.followerid)) {
      userArr.push(valObj.followerid);
      this.props.profileViewNavigation(userArr);
    }
  }

  /**** Render Profile settings My Followers List *****/
  renderList() {
    var item = _.map(this.props.myFollowersData, (valObj, index) => {
      var usersByFollowerid = valObj.users_by_followerid;

      var productImageUrl = `${BASE_IMAGES_URL}/users/${usersByFollowerid.userid}.jpg?api_key=${APP_API_KEY}`;

      return (
        <li
          key={index}
          className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
          onClick={(e) => this.openFollowersDelaisModal(valObj)}>
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <img
                loading="lazy"
                className="user-img-rounded"
                src={productImageUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images2/common/user.png";
                }}
                alt="Image"
              />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-secondary truncate  mx-2">
                {usersByFollowerid.username}
              </p>
              {/* 
               *** Dont remove the code [We may need myfollowers dates later *** ]
              <p className="text-sm text-ternary truncate mx-2">
                {converDateIntoLocal(valObj.createdon).format(
                  DATE_FORMAT_TO_SHOW_SHORT
                )}
              </p> */}
            </div>
            <div></div>
          </div>
        </li>
      );
    });

    return item;
  }

  render() {
    let { reportAbuseModal, myFollowersData } = this.props;

    return (
      <>
        <CommonHeaderProfileSettings
          commonHeaderText={LocaleStrings.profile_settings_list_text5}
        />
        {this.state.loading ? (
          <BaseLoader data={this.state.loaderDesc} />
        ) : (
          <>
            <div className=" xs:px-2 sm:px-10 2xl:px-48">
              <div className="flow-root mt-8">
                {_.size(myFollowersData) > 0 ? (
                  <ul role="list" className="-my-5 divide-y divide-gray-200">
                    {this.renderList()}
                  </ul>
                ) : (
                  <NoDataFoundComponet
                    text={LocaleStrings.no_followers_yet}
                    subtext={""}
                  />
                )}
              </div>
            </div>
            <FollowerDetails />
            {reportAbuseModal ? <ReportAbuse /> : ""}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    myFollowersData: state.storeMyFollowersData
      ? state.storeMyFollowersData.data
        ? state.storeMyFollowersData.data
        : []
      : [],
    navigateUser: state.navigateUser,
    reportAbuseModal: state.reportAbuseModal,
  };
}

export default connect(mapStateToProps, {
  fetchMyFollowers,
  isFollowersDetailsOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  fetchMyFolloings,
  viewPublicProfile,
  profileViewNavigation,
})(MyFollowers);
