import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import Components
import BaseLoader from "@baseLoader";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
import ProfileInformationEdit from "./profileinformationedit.js";
import VerifyEmailModal from "@starter/verifyemailmodal";
// Import Actions
import { fetchUsersDeatils } from "@profileSettingsActions";

class ProfileInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_profile_information,
    };

    // All Binded Functions
  }

  componentDidMount() {
    // Fetch Vendor Details With LoginIdStore
    this.props.fetchUsersDeatils(this.props.session, (callBackResponce) => {
      this.setState({ loading: false });
    });
  }

  render() {
    let { isVerifyEmailPrflInfoModal } = this.props;

    return (
      <>
        <CommonHeaderProfileSettings
          commonHeaderText={LocaleStrings.profile_settings_list_text1}
        />
        {this.state.loading ? (
          <BaseLoader data={this.state.loaderDesc} />
        ) : (
          <>
            <ProfileInformationEdit />
            {isVerifyEmailPrflInfoModal ? <VerifyEmailModal /> : null}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    isVerifyEmailPrflInfoModal: state.isVerifyEmailPrflInfoModal, // For verify email modal from profile information
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
})(ProfileInformation);
