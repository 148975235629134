import {  IS_RAISETICKET_MODAL_OPEN,FAQ_LIST } from "../actions/index";

/**** Raise Ticket Modal  ****/ 
export var isRaiseTicketopen = (
    state = false,
    action
  ) => {
  
    if (action.type === IS_RAISETICKET_MODAL_OPEN) {
      state = action.payload;
    }
  
    return state;
};

/**** FAQ Data List  ****/ 
export var faqdata = (
  state = {},
  action
) => {

  if (action.type === FAQ_LIST) {
    state = action.payload;
  }

  return state;
};