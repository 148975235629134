import {
  getRequest,
  postRequest,
  deleteRequest,
  patchRequest,
} from "@networkCall";

import { INSTANCE_URL, itemCount } from "@constant";

import _ from "lodash";

import LocaleStrings from "@language";

// Data
export const DISCOUNTMANAGEMENT_MASTER = "DISCOUNTMANAGEMENT_MASTER";
export const DISCOUNTMANAGEMENT_PRODUCTCATEGORY =
  "DISCOUNTMANAGEMENT_PRODUCTCATEGORY";
export const DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY =
  "DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY";
export const DISCOUNTMANAGEMENT_CRITERIAMASTER =
  "DISCOUNTMANAGEMENT_CRITERIAMASTER";
export const DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION =
  "DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION";
export const DISCOUNTMANAGEMENT_PRODUCTLIST = "DISCOUNTMANAGEMENT_PRODUCTLIST";
export const DISCOUNTMANAGEMENT_MASTER_SINGLE =
  "DISCOUNTMANAGEMENT_MASTER_SINGLE";
// Modal
export const IS_DISCOUNTMANAGEMENT_MODAL_OPEN =
  "IS_DISCOUNTMANAGEMENT_MODAL_OPEN";
export const DISCOUNTMANAGEMENT_ALLPRODUCTLIST =
  "DISCOUNTMANAGEMENT_ALLPRODUCTLIST";

/**** Fetch Product Category data  ****/
export function fetchProductCategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productcategory?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_PRODUCTCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

export function fetchallProduct(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_ALLPRODUCTLIST,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Product Subcategory Data ****/
export function fetchProductSubcategory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/productsubcategory?filter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Criteria Master Data ****/
export function fetchCriteriaMaster(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/criteriamaster`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_CRITERIAMASTER,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Discount Area Data ****/
export function fetchDiscountArea(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discountareaselection`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Discount Master data List ****/
/* Parameters
  
  If only category selection is available
  [“categoryid”:<selected-categoryid>,”discount”:1]

  If subcategory selection is available
	[“categoryid”:<selected-categoryid>,
  “subcategoryid”:<selected-subcategoryid>]

  */
export function fetchDiscountMaster(session, pageCount = 0, callback) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;

  //var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master?include_count=true&fields=*&related=discountarea_by_iddiscount&filter=((createdby=${session.userid})AND(fromproduct=0))&${offSetQuery}`;
  /* Note:
  vendor-datascript/discounts
  params: `{"usertype":"web","limit":<10>,"page":1}` 
  
  owner: 1|0 <=== To determine which one belongs to you
  status: 'requested | accepted' <== If rejected the discount won't list

  All the discounts created by the Superadmin will come first then your discounts..
  */

  let url = `${INSTANCE_URL}/api/v2/vendor-datascript/discounts`;

  return (dispatch) => {
    postRequest(
      url,
      {
        usertype: "web",
        limit: 10,
        page: pageCount,
      },
      session,
      dispatch,
      (response) => {
        if (response.result === "success") {
          dispatch({
            type: DISCOUNTMANAGEMENT_MASTER,
            payload: { data: response.data, count: response.count },
          });
          callback({ status: 1, message: LocaleStrings.api_success_status });
        } else {
          callback({ status: 0, message: LocaleStrings.something_went_wrong });
        }
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/***** Accept or reject discount from super admin
 * params: {"discountid":19,"status":"accepted | rejected"}
 * *****/
export function discountactions(session, id, status, callback) {
  let url = `${INSTANCE_URL}/api/v2/vendor-datascript/discountactions`;

  return (dispatch) => {
    postRequest(
      url,
      {
        discountid: id,
        status: status,
      },
      session,
      dispatch,
      (response) => {
        if (response.result === "success") {
          callback({ status: 1, message: LocaleStrings.api_success_status });
        } else {
          callback({ status: 0, message: LocaleStrings.something_went_wrong });
        }
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Fetch Products List ****/
export function fetchProductsByCateSubCate(
  session,
  selected_categoryid,
  selected_subcategoryid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/listproductsbycategory`;

  if (
    (selected_categoryid == null || selected_categoryid == "") &&
    (selected_subcategoryid == null || selected_subcategoryid == "")
  ) {
    return (dispatch) => {
      dispatch({
        type: DISCOUNTMANAGEMENT_PRODUCTLIST,
        payload: {},
      });
    };
  } else {
    if (selected_subcategoryid == null || selected_subcategoryid == "") {
      var object = {
        categoryid: selected_categoryid,
        discount: 1,
      };
    } else {
      var object = {
        categoryid: selected_categoryid,
        subcategoryid: selected_subcategoryid,
      };
    }

    // Actual Data Call
    return (dispatch) => {
      postRequest(
        url,
        object,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: DISCOUNTMANAGEMENT_PRODUCTLIST,
            payload: { data: response.data },
          });

          callback({ status: 1, message: LocaleStrings.api_success_status });
        },
        (error) => {
          callback({ status: 0, message: LocaleStrings.something_went_wrong });
        }
      );
    };
  }
}

/**** Update Discount Master *****/
export function updateDiscountMaster(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master`;
  let body = { resource: object };
  // debugger;
  // Actual Data Call

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          error: error,
        });
      }
    );
  };
}

/**** Add Discount Master ****/

export function addDiscountMaster(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource[0].id,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Add Discount Area *****/
export function addDiscountArea(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discountarea`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Validate Discount Area { Validate discount (Call this before adding or updating discount) } ****/
export function validatedDiscount(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/validatediscount`;
  let body = { rules: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Update Discount area ****/
export function updateDiscountArea(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discountarea`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Is Open Discount management modal ****/
export function isOpenDiscManagModal(isOpen) {
  return { type: IS_DISCOUNTMANAGEMENT_MODAL_OPEN, payload: isOpen };
}

/**** Single Object For Discount ****/
export function getSelectedDiscountObj(discountObj = {}, session) {
  if (Object.keys(discountObj).length > 0) {
    discountObj["discounareaid"] =
      discountObj.discountarea_by_iddiscount[0].discounareaid;
    discountObj["idcategory"] =
      discountObj.discountarea_by_iddiscount[0].idcategory;
    discountObj["idsubcategory"] =
      discountObj.discountarea_by_iddiscount[0].idsubcategory;
    discountObj["idproduct"] =
      discountObj.discountarea_by_iddiscount[0].idproduct;
  }

  return { type: DISCOUNTMANAGEMENT_MASTER_SINGLE, payload: discountObj };
}

/*** Delete discount from DB with ID ***/
export function deleteDiscountWithID(session, id, callback) {
  var filter = encodeURI(`filter=(id=${id})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/discount_master?${filter}`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: [],
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

/*** Delete discount from redux ***/
export function deleteLocalDiscount(session, callback) {
  return (dispatch) => {
    dispatch({
      type: DISCOUNTMANAGEMENT_PRODUCTLIST,
      payload: {},
    });
    callback({
      status: 1,
      message: LocaleStrings.api_success_status,
      data: [],
    });
  };
}
