import React, { useStatef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import LocaleStrings from "@language";
import { BASE_IMAGES_URL, APP_API_KEY } from "@constant";

// Common Fc
import { timeAgo } from "@commonFunction";

import BaseLoader from "@baseLoader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
// import NoImg from "@noImg";
import { isbidPlacedSliderOpen } from "../../../actions/index";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";

function PublicBid(props) {
  const [loading, setLoading] = useState(false);
  const {
    session,
    isLoggedIn,
    localLanguage,
    publicBidModal,
    publicBids,
    localCurrency,
  } = props;

  let currency = currencyCode(localCurrency);
  let currencyrate = currencyRate(localCurrency);

  const closeSlideOvers = () => {
    props.isbidPlacedSliderOpen(false);
  };

  const renderBids = (item, index) => {
    /* Bid currency */
    let bidCurrency = item?.odcurrency;
    let bidprice =  item?.bidprice;
    // let bidprice = isCurrencyRateApplicable(
    //   localCurrency,
    //   bidCurrency,
    //   item.bidprice
    // );
    //return item.isprivate == 1 && item.userid == session.userid ? ( // Old code
    /*
      For anynomous user we will not gonna show his/her name and profile image
    */
    return item.isprivate == 1 ? (
      // Private bid
      <div className="grid grid-cols-4">
        <div className="col-span-3 flex pb-6">
          <div className="pr-3">
            <img
              loading="lazy"
              key={item.id}
              className="max-w-none user-img-rounded ring-2 ring-white"
              src={`/images2/common/user.png`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images2/common/user.png";
              }}
            />
          </div>
          <div>
            <div className="text-uname text-base font-normal">
              {LocaleStrings.anonymous}
            </div>
            <div className="text-cardfooter text-xs font-normal">
              {timeAgo(item.createdat)}
            </div>
          </div>
        </div>
        <div className="text-link text-base font-normal text-right">
          {currency} {parseFloat(bidprice).toFixed(2)}
        </div>
      </div>
    ) : item.isprivate == 0 ? (
      // Public bid
      <div className="grid grid-cols-4">
        <div className="col-span-3 flex pb-6">
          <div className="pr-3">
            <img
              loading="lazy"
              key={item.id}
              className="max-w-none user-img-rounded ring-2 ring-white"
              src={`${BASE_IMAGES_URL}/users/${item.userid}.jpg?api_key=${APP_API_KEY}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images2/common/user.png";
              }}
            />
          </div>
          <div>
            <div className="text-uname text-base font-normal">
              {item.username}
            </div>
            <div className="text-cardfooter text-xs font-normal">
              {timeAgo(item.createdat)}
            </div>
          </div>
        </div>
        <div className="text-link text-base font-normal text-right">
          {currency} {parseFloat(bidprice).toFixed(2)}
        </div>
      </div>
    ) : (
      ""
    );
  };

  return (
    <>
      <Transition.Root show={publicBidModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={publicBidModal}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-primary"
                            >
                              {LocaleStrings.buying_history_bids_placed}
                            </h2>
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="relative flex-1 mt-6">
                          {publicBids && publicBids.data ? (
                            publicBids.data.length > 0 ? (
                              publicBids.data.map((item, index) => {
                                return renderBids(item, index);
                              })
                            ) : (
                              ""
                            )
                          ) : (
                            <BaseLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  localCurrency: state.currency,
  deviceSize: state.deviceSize,
  publicBidModal: state.publicBidModal,
  publicBids: state.publicBids,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isbidPlacedSliderOpen,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PublicBid);
