import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
  uploadEncodedFileLarge,
} from "@networkCall";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
  BASE_IMAGES_URL,
} from "@constant";

import LocaleStrings from "@language";

import _, { isEmpty } from "lodash";

export const VENDOR_PRODUCT_LIST = "VENDOR_PRODUCT_LIST";
export const VENDOR_PRODUCTMANAGEMENT_DEFAULTSETTINGS =
  "VENDOR_PRODUCTMANAGEMENT_DEFAULTSETTINGS";
export const IS_PRODUCT_DISCOUNT_MODAL_OPEN = "IS_PRODUCT_DISCOUNT_MODAL_OPEN";
export const IS_EXCLUDE_SHIPPING_MODAL_OPEN = "IS_EXCLUDE_SHIPPING_MODAL_OPEN";
export const IS_CHARITY_VIEW_MODAL_OPEN = "IS_CHARITY_VIEW_MODAL_OPEN";
export const IS_VARIANTS_DETAILS_MODAL_OPEN = "IS_VARIANTS_DETAILS_MODAL_OPEN";
export const IS_CROPPER_MODAL_OPEN = "IS_CROPPER_MODAL_OPEN"; // image cropper
export const IS_CATE_SUBCATE_MODAL_OPEN = "IS_CATE_SUBCATE_MODAL_OPEN";
export const PRODUCT_TITLE_SUBTITLE_TEXT = "PRODUCT_TITLE_SUBTITLE_TEXT";
export const PRODUCT_CATEGORY_SUBCATEGORY_LIST =
  "PRODUCT_CATEGORY_SUBCATEGORY_LIST";
export const PRODUCT_ITEM_SPECIFICATION_DATA =
  "PRODUCT_ITEM_SPECIFICATION_DATA";
export const PRODUCT_FEE_DETAILS = "PRODUCT_FEE_DETAILS"; // AFTER ADD SHOW PRODUCT FEE
export const IS_PRODUCT_FEE_DEFAULT = "IS_PRODUCT_FEE_DEFAULT"; // For First time
export const IS_PRODUCT_SUBMIT_CONFIRM = "IS_PRODUCT_SUBMIT_CONFIRM"; // For First time
export const VENDOR_PRODUCTMANAGEMENT_DISCOUNTLIST =
  "VENDOR_PRODUCTMANAGEMENT_DISCOUNTLIST"; // Discount List
export const SELECTED_PRODUCT_DETAILS = "SELECTED_PRODUCT_DETAILS"; // Show product details
export const SELECTED_PRODUCT_QA_WITHLIMIT = "SELECTED_PRODUCT_QA_WITHLIMIT"; // fetch qa with limit e
export const SELECTED_PRODUCT_QA_WITHOUT_LIMIT =
  "SELECTED_PRODUCT_QA_WITHOUT_LIMIT"; // fetch qa with OUT limit e
export const COMMENT_LIST = "COMMENT_LIST"; // COmments List
export const PRODUCTLIKES_LIST = "PRODUCTLIKES_LIST"; // Product Likes
export const TOBEEDIT_PRODUCT_DATA = "TOBEEDIT_PRODUCT_DATA"; // TO BE EDIT PRODUCT DATA

export const PRODUCT_TAB_LIST = "PRODUCT_TAB_LIST"; // TAB LIST
export const PRODUCT_UPLOAD_SHIPPINGTYPE = "PRODUCT_UPLOAD_SHIPPINGTYPE"; // shippingType

export const VENDOR_GLOBAL_SPECIFICATIONS = "VENDOR_GLOBAL_SPECIFICATIONS"; // Global specification

export const CHECK_SHIPPING_BEARER = "CHECK_SHIPPING_BEARER"; // shippingcostbearer option from shopez available for user or not

export const TOGGLE_GRID_AND_LIST = "TOGGLE_GRID_AND_LIST"; // Will store grid or list view of product list

// Variation master list
export const VARIATION_MASTER_LIST = "VARIATION_MASTER_LIST";

// Category And Subcategory Modal
export const PRODUCT_SELECTED_SUBCATE = "PRODUCT_SELECTED_SUBCATE";

// Product Discout add
export const PRODUCT_SELECTED_DISCOUNT = "PRODUCT_SELECTED_DISCOUNT";

// Product Fee Modal
export const IS_PRODUCT_FEE_MODAL_OPEN = "IS_PRODUCT_FEE_MODAL_OPEN";

// Setup store Modal
export const IS_SETUP_STORE_BLOCKER_MODAL_OPEN =
  "IS_SETUP_STORE_BLOCKER_MODAL_OPEN";

// Qa Modal / Sideover
export const IS_PRODUCT_QA_MODAL_OPEN = "IS_PRODUCT_QA_MODAL_OPEN";
export const IS_PRODUCT_QA_N0ITIFI_MODAL_OPEN =
  "IS_PRODUCT_QA_N0ITIFI_MODAL_OPEN";

// View Comments
export const IS_PRODUCT_COMMENTS_MODAL_OPEN = "IS_PRODUCT_COMMENTS_MODAL_OPEN";
export const IS_PRODUCT_COMMENTS_N0ITIFI_MODAL_OPEN =
  "IS_PRODUCT_COMMENTS_N0ITIFI_MODAL_OPEN";

// Likes Modal
export const IS_PRODUCT_LIKES_MODAL_OPEN = "IS_PRODUCT_LIKES_MODAL_OPEN";
// PUBLIC BIDS MODAL
export const IS_PUBLIC_BIDS_MODAL_OPEN = "IS_PUBLIC_BIDS_MODAL_OPEN";
export const PUBLIC_BIDS = "PUBLIC_BIDS";

// screen to show
export const SHOW_UPLOAD_SECTION = "SHOW_UPLOAD_SECTION";

// Payment status of a product
export const PAYMENT_STATUS_BY_PRODUCTID = "PAYMENT_STATUS_BY_PRODUCTID";

// Repayment modal / paynow
export const IS_REPAYMENT_MODAL_OPEN = "IS_REPAYMENT_MODAL_OPEN";

export const ZONE_PINCODE_LIST = "ZONE_PINCODE_LIST";
export const RELIST_WHILEUPDATE = "RELIST_WHILEUPDATE"; //Relist while update product
export const SHIIPPING_CHARGES_DEFAULT = "SHIIPPING_CHARGES_DEFAULT"; //shipping charges Latest code
export const LISTING_AMOUNT = "LISTING_AMOUNT"; //listing amount
export const COUNTRY_LIST_ARR = "COUNTRY_LIST_ARR"; //listing amount
export const CONTINENT_LIST_ARR = "CONTINENT_LIST_ARR"; //listing amount
export const PRODUCT_EXCLUDE_LOCATION = "PRODUCT_EXCLUDE_LOCATION"; //listing amount
export const SELECTED_CONTINENT_LENGTH = "SELECTED_CONTINENT_LENGTH"; //listing amount
export const CATSUBCAT_UPDATED = "CATSUBCAT_UPDATED"; //listing amount

/****  Will store grid or list view of product list ****/
export function setListAndGridToggle(type) {
  return { type: TOGGLE_GRID_AND_LIST, payload: type };
}

/***** Subcategory specifications *****/
export function fetchItemSpecification(session, cateIds, subcateIds, callback) {
  if (_.isEmpty(subcateIds)) {
    // No data
    return { type: PRODUCT_ITEM_SPECIFICATION_DATA, payload: {} };
  } else {
    // Has data
    let values = {};

    /* Useing toString => conver array to string */
    values = {
      categoryids: _.toString(cateIds),
      subcategoryids: _.toString(subcateIds),
    };

    var url = `${INSTANCE_URL}/api/v2/vendor-datascript/getspecifications`;
    return (dispatch) => {
      postRequest(
        url,
        values,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: PRODUCT_ITEM_SPECIFICATION_DATA,
            payload: { data: response.data },
          });

          callback({ status: 1 });
        },
        (error) => {
          callback({ status: 0 });
        }
      );
    };
  } //End ELSE
}

/*** Fetch global specification [Upload product -> Product specification]***/
export function fetchGlobalSpecifications(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/globalspecifications`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: VENDOR_GLOBAL_SPECIFICATIONS,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

export function fetchProductList(
  session,
  limit,
  page,
  searchText,
  isdraft,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/fetchmyproducts`;
  let values = {};

  if (searchText === "") {
    values.limit = limit;
    values.page = page;
    values.type = "listing";
  } else {
    values.type = "search";
    values.searchterm = searchText;
  }
  values.isdraft = isdraft;
  values.usertype = "admin";

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (searchText === "") {
          // List
          dispatch({
            type: VENDOR_PRODUCT_LIST,
            payload: { data: response.products, count: response.totalrows },
          });
        } else {
          // Search
          dispatch({
            type: VENDOR_PRODUCT_LIST,
            payload: {
              data: response.products,
              count: response.products.length,
            },
          });
        }

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/***** Get Default settongs for product management *****/
export function fetchProductManagementDefaultSettings(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/productmanagement/defaultsettings`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: VENDOR_PRODUCTMANAGEMENT_DEFAULTSETTINGS,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

/**** Screen to show ****/
export function showUploadSection(screen) {
  return { type: SHOW_UPLOAD_SECTION, payload: screen };
}

/**** Shipping Type ****/
export function currentShippngType(ShippngType) {
  return { type: PRODUCT_UPLOAD_SHIPPINGTYPE, payload: ShippngType };
}

/**** Chnage To New Tabs Onject [When Tab Click] ****/
export function tabListWithSelection(obj = {}) {
  // console.log("obj", obj);
  return { type: PRODUCT_TAB_LIST, payload: obj };
}

/***** On Product Subcategory Selected From Modal ( Category Subcategory ) *****/
export function allSelectedSubcategorisID(obj = {}) {
  return { type: PRODUCT_SELECTED_SUBCATE, payload: obj };
}

/***** Is Product Discount Modal open or not *****/
export function isProductDiscountopen(isOpen) {
  return { type: IS_PRODUCT_DISCOUNT_MODAL_OPEN, payload: isOpen };
}
/***** Is Exclude shipping Modal open or not *****/
export function isExcludeshippingmodalopen(isOpen) {
  return { type: IS_EXCLUDE_SHIPPING_MODAL_OPEN, payload: isOpen };
}
/***** Is Charity View Modal *****/
export function charityviewModalopen(isOpen) {
  return { type: IS_CHARITY_VIEW_MODAL_OPEN, payload: isOpen };
}

/***** Is Variation Details Modal Open *****/
export function isVariationDeatilsopen(isOpen) {
  return { type: IS_VARIANTS_DETAILS_MODAL_OPEN, payload: isOpen };
}

/***** Is Variation Details Modal Open *****/
export function isCroppermodalactionopen(isOpen) {
  return { type: IS_CROPPER_MODAL_OPEN, payload: isOpen };
}

/***** Product category Subcategory Modal open *****/
export function isProductCateSubCateModalOpen(isOpen) {
  return { type: IS_CATE_SUBCATE_MODAL_OPEN, payload: isOpen };
}

/***** CALLING ACTION TO STORE PRODUCT TITLE & SUBTITLE *****/
export function productTitleSubTitleData(data = {}) {
  return { type: PRODUCT_TITLE_SUBTITLE_TEXT, payload: data };
}

/***** Fetch Product category List *****/
export function fetchProductCategorylist(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/productmanagement/categorylist`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PRODUCT_CATEGORY_SUBCATEGORY_LIST,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/***** Product Added Discount data [reducers -> productDiscountAddedData / main reducer -> prodDiscAddedData]*****/
export function productAddedDiscount(data) {
  return { type: PRODUCT_SELECTED_DISCOUNT, payload: data };
}

/**** Open Product Fee Modal ****/
export function productFeeModal(isOpen) {
  return { type: IS_PRODUCT_FEE_MODAL_OPEN, payload: isOpen };
}

/**** 
  Open [Setup Store
  Please setup our Store before adding
  a Product] Modal 
  Reducer name - setupStoreBlockerModal
****/
export function openStoreSetupModal(isOpen) {
  return { type: IS_SETUP_STORE_BLOCKER_MODAL_OPEN, payload: isOpen };
}

/**** Before Upload Show Product Fee ****/
export function fetchProductFeeDetails(data) {
  return { type: PRODUCT_FEE_DETAILS, payload: data };
}

/**** Upload Product Media ****/

export function uploadMediaLargeVendorVariation(
  session,
  uploadFiles,
  callback
) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else if (filetype == "image") {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  } else {
    var blob = new Blob([filecontent], { type: filetype });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/vendor/variations/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/**** Add Product Master ****/

export function addproduct(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/addproduct`;
  let body = { product: object, source: "web" };

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // ,data:response.resource[0].id
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          productid: response.productid,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Delete Product ****/
export function deleteproduct(session, productID, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/deleteproduct`;
  // let body = {product: object};

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      {
        productid: productID,
      },
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/***** Get Product By ID For EDIT ****/
// call this before opening edit mode pass the data in response
export function productbyidtoedit(
  session,
  productID,
  toedit,
  auctionrelist,
  callback
) {
  var cateAndSubcateArray = [];

  var objectVal = {
    productid: productID,
    toedit: toedit,
  };

  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/productbyid`;

  return (dispatch) => {
    postRequest(
      url,
      objectVal,
      session,
      dispatch,
      (response) => {
        let data = response.product;
        var variationDetailsObj = {
          id: 0,
          refkey: "",
          name_en: "",
          name_ar: "",
          price_unit: 0,
          quantity: "",
          units: "",
          isavailable: 1,
          isdeleted: false,
          variations_media_raw: [
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
          ],
          variations_media: [],
        };
        // UPDATE SELECTED Category & Sub-Categories

        if (response.product.subcategoryids != "") {
          cateAndSubcateArray.push(
            _.split(response.product.subcategoryids, ",").map(function (item) {
              return parseInt(item, 10);
            })
          );
        }

        if (response.product.categoryids != "") {
          cateAndSubcateArray.push(
            _.split(response.product.categoryids, ",").map(function (item) {
              return parseInt(item, 10);
            })
          );
        }

        dispatch({
          type: PRODUCT_SELECTED_SUBCATE,
          payload: cateAndSubcateArray,
        });

        //Shippping Type
        dispatch({
          type: PRODUCT_UPLOAD_SHIPPINGTYPE,
          payload: data.shipping_type,
        });

        if (data.hasOwnProperty("shipping_service")) {
          data["delivery_company"] = data.shipping_service.id; //// field not in db
        }

        if (data.shipping_type === "flat") {
          data["shipping_cost_f"] = data.shipping_cost; //// field name not in db
        } else if (data.shipping_type === "calculated") {
          data["shipping_cost_c"] = data.shipping_cost;
        }

        if (!_.isEmpty(data.idcharityevents)) {
          // [Make a Donation]
          data["make_donation"] = true;
        }
        if (
          data.hasOwnProperty("product_condition") &&
          data.product_condition != null &&
          data.product_condition != ""
        ) {
          data["product_condition"] = data.product_condition.id;
        }

        /* Item specification */
        if (_.size(data.specifications) > 0) {
          // Use of _.forEach() method
          _.forEach(data.specifications, function (value, index) {
            data["itemspec_name_" + value.idspecification] = value.name_en;

            // For both languge will implement it later
            data["itemspec_name_en_" + value.idspecification] = value.name_en;
            data["itemspec_name_ar_" + value.idspecification] = value.name_ar;
          });
        }

        /* Product specification [Global specification] */
        if (_.size(data.globalspecifications) > 0) {
          // Use of _.forEach() method
          _.forEach(data.globalspecifications, function (value, index) {
            data["globalspec_name_" + value.idspecification] = value.name_en;

            // For both languge will implement it later
            data["globalspec_name_" + value.idspecification] = value.name_en;
            data["globalspec_name_" + value.idspecification] = value.name_ar;
          });
        }
        // Get Discount Data
        dispatch(
          fetchDiscountList(
            session,
            response.product.categoryids,
            response.product.subcategoryids,
            response.product.productid,
            (callback) => {}
          )
        );

        /* Fetch item specifications time of edit */
        dispatch(
          fetchItemSpecification(
            session,
            _.toString(cateAndSubcateArray[1]), // Category
            _.toString(cateAndSubcateArray[0]), // Sub category
            (callback) => {}
          )
        );
        // latest code for removing date
        if (auctionrelist == "auctionrelist") {
          response.product.auction_end_date = null;
          response.product.auction_start_date = null;
        }
        let finalArr = response?.product;
        let productvariant_arr = [];

        let product_variants = response?.product?.product_variants;
        _.forEach(product_variants, (variant, index) => {
          let obj = variant;
          let variantnames_arr = [];
          _.forEach(variant.variantnames, (variantnames, vvindex) => {
            if (_.has(variantnames, "media")) {
              let curObject = variantnames;
              curObject.variations_media_raw =
                variationDetailsObj.variations_media_raw;
              curObject.variations_media = variantnames.media;
              variantnames_arr.push(variantnames);
            }
          });
          obj.variantnames = variantnames_arr;
          productvariant_arr.push(obj);
        });
        finalArr.product_variants = productvariant_arr;
        // console.log('finalArr',finalArr)
        dispatch({
          type: TOBEEDIT_PRODUCT_DATA,
          payload: { response: finalArr },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/* Get Product By ID */
export function productbyid(
  session,
  productID,
  reinitialize = false,
  callback
) {
  if (reinitialize === true) {
    return (dispatch) => {
      dispatch({
        type: SELECTED_PRODUCT_DETAILS,
        payload: {},
      });
    };
  } else {
    var url = `${INSTANCE_URL}/api/v2/vendor-datascript/productbyid`;
    var objectVal = {
      productid: productID,
    };
    return (dispatch) => {
      postRequest(
        url,
        objectVal,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: SELECTED_PRODUCT_DETAILS,
            payload: response.product,
          });

          callback({ status: 1, data: response.product });
        },
        (error) => {}
      );
    };
  }
}

export function unSetTobeEditProductStore() {
  return { type: TOBEEDIT_PRODUCT_DATA, payload: {} };
}

/* Fetch records from variation_master table [Reducers name - variationMasterList]*/
export function fetchVariationMaster(
  session,
  categoryids,
  subcategoryids,
  callback
) {
  // old var url = `${INSTANCE_URL}/api/v2/shopez/_table/variation_master?fields=id,name_en,name_ar`;
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/getvariationmaster`;
  var objectParams = {
    categoryids: categoryids,
    subcategoryids: subcategoryids,
  };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      objectParams,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: VARIATION_MASTER_LIST,
          payload: { data: response?.data },
        });
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response?.data,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* Fetch zone and pincode list */
export function fetchZoneAndPincode(session, callback) {
  // var url = `${INSTANCE_URL}/api/v2/shopez/_table/shipping_charges?&related=*`; old code
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/zone_master?filter=(isactive=1)&related=*`; // latest code

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ZONE_PINCODE_LIST,
          payload: { data: response.resource },
        });
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

export function fetchShippingCharges(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/shipping_charges?filter=(district=default)`; //old code
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SHIIPPING_CHARGES_DEFAULT,
          payload: { data: response.resource },
        });
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* Fetch Payment ststus by product ID [Reducers name - productLastPaymentStatus]*/
export function fetchLastPaymentStatusById(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/transactions?fields=*&filter=productid=${productid}&limit=1&order=createdat`;

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PAYMENT_STATUS_BY_PRODUCTID,
          payload: { data: response.resource },
        });
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* Check if this user has shippingcostbearer option from shopez
reducers name - IsCheckShippingBearerAvailable
*/
export function checkShippingBearer(session, makeEmpty = false, callback) {
  if (makeEmpty) {
    // No data
    return { type: CHECK_SHIPPING_BEARER, payload: {} };
  } else {
    var url = `${INSTANCE_URL}/api/v2/vendor-datascript/checkshippingbearer`;
    return (dispatch) => {
      postRequest(
        url,
        {},
        session,
        dispatch,
        (response) => {
          dispatch({
            type: CHECK_SHIPPING_BEARER,
            payload: { data: response },
          });

          callback({ status: 1 });
        },
        (error) => {
          callback({ status: 0 });
        }
      );
    };
  } //End ELSE
}

/**** Discount ****/

/***** Clear discount list  *****/
export function clearDiscountList() {
  return { type: VENDOR_PRODUCTMANAGEMENT_DISCOUNTLIST, payload: {} };
}

/***** Fetch discount price Based On Category and subcategory selected *****/
export function fetchDiscountList(session, cate, subcate, productid, callback) {
  let categoryids = _.join(cate, ",");
  let subcategoryids = _.join(subcate, ",");
  var url = "";

  if (_.isEmpty(productid)) {
    url = `${INSTANCE_URL}/api/v2/productmanagement/discountlist?categoryids=${categoryids}&subcategoryids=${subcategoryids}&createdby=${session.userid}`;
  } else {
    url = `${INSTANCE_URL}/api/v2/productmanagement/discountlist?categoryids=${categoryids}&subcategoryids=${subcategoryids}&createdby=${session.userid}&productid=${productid}`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: VENDOR_PRODUCTMANAGEMENT_DISCOUNTLIST,
          payload: { data: response.resource },
        });

        if (!_.isEmpty(productid)) {
          if (!_.isEmpty(response.resource)) {
            // Set Data For Discount AT the time edit open(Product  Edit)
            let newDisObject = {
              discount_title: response.resource[0].title,
              validity_from: response.resource[0].validity_from,
              validity_end: response.resource[0].validity_end,
              discount_percentage: response.resource[0].discount_percentage,
              discounttype: response.resource[0].discounttype,
              id: response.resource[0].id,
            };

            dispatch(productAddedDiscount(newDisObject));
          }
        }

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

/**** Product Details ****/

/**** List / Unlist [To Unlist a Product use following api] ****/
export function toggleunlistproduct(session, listed, productID, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/toggleunlistproduct`;
  // let body = {product: object};

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      {
        productid: productID,
        listed: listed,
      },
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

export function fetchProductDetails(data) {
  return { type: SELECTED_PRODUCT_DETAILS, payload: data };
}

export function cancelBid(session, productID, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/cancelbid`;

  return (dispatch) => {
    postRequest(
      url,
      {
        productid: productID,
      },
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

/**** QA with or with out limit ****/
export function fetchqa(session, productID, withlimit, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/qa`;

  return (dispatch) => {
    postRequest(
      url,
      {
        productid: productID,
        withlimit: withlimit,
      },
      session,
      dispatch,
      (response) => {
        if (withlimit === 1) {
          // 3 rows
          dispatch({
            type: SELECTED_PRODUCT_QA_WITHLIMIT,
            payload: { data: response.data },
          });
        } else {
          // All
          dispatch({
            type: SELECTED_PRODUCT_QA_WITHOUT_LIMIT,
            payload: { data: response.data },
          });
        }

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Product QA Modal Open ****/
export function isQaModalOpen(isOpen) {
  return { type: IS_PRODUCT_QA_MODAL_OPEN, payload: isOpen };
}

/**** Product QA Modal Open  For Notification ****/
export function isQasNotificModalOpen(isOpen) {
  return { type: IS_PRODUCT_QA_N0ITIFI_MODAL_OPEN, payload: isOpen };
}

/**** Product QA Modal Open ****/
export function isCommentsModalOpen(isOpen) {
  return { type: IS_PRODUCT_COMMENTS_MODAL_OPEN, payload: isOpen };
}

/**** Product QA Modal Open ****/
export function isCommentsNotificModalOpen(isOpen) {
  return { type: IS_PRODUCT_COMMENTS_N0ITIFI_MODAL_OPEN, payload: isOpen };
}

export function iscatsubCatUpdated(val = false) {
  return { type: CATSUBCAT_UPDATED, payload: val };
}

/**** Product Likes Modal Open ****/
export function isLikesModalOpen(isOpen) {
  return { type: IS_PRODUCT_LIKES_MODAL_OPEN, payload: isOpen };
}

/**** Product Public Bids Modal Open ****/
export function isbidPlacedSliderOpen(isOpen) {
  return { type: IS_PUBLIC_BIDS_MODAL_OPEN, payload: isOpen };
}

/**** REPAYMENT OPTION FROM PRODUCT DEATILS PAGE [personal] [Reducers = isRepaymentOpen] ****/
export function isRepaymentModalOpen(isOpen) {
  return { type: IS_REPAYMENT_MODAL_OPEN, payload: isOpen };
}

/**** Submit Question and answer  ****/
export function questionanswer(session, dataObject, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/questionanswer`;

  return (dispatch) => {
    postRequest(
      url,
      dataObject,
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch Comment List ****/
export function fetchCommentList(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/comments`;
  let values = { productid: productid };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: COMMENT_LIST,
          payload: { data: response.comments },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/***** Delete a comment with comment ID *****/
export function deletComment(session, id, callback) {
  var deleteUrl = `${INSTANCE_URL}/api/v2/shopez/_table/product_comments/${id}`;
  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (delres) => {
        callback({ success: 1, message: delres });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/**** Update a comment with comment ID ****/
export function updateComment(session, values, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/product_comments/${id}`;
  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, message: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/**** Fetch Products Likes ****/
export function fetchproductlikes(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/productlikes`;
  let values = { productid: productid };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PRODUCTLIKES_LIST,
          payload: { data: response.likes },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** submit comments ****/
export function productComments(session, dataObject, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/product_comments`;

  let body = { resource: dataObject };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function fetchPublicBids(session, variantid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/publicbids`;
  let values = {};
  values.variantid = variantid;
  let body = { resource: values };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PUBLIC_BIDS,
          payload: { data: response.bids },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/***** Subcategory specifications *****/
export function enabledbestoffer(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/enabledbestoffer`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, data: response });
      },
      (error) => {
        callback({ status: 0, data: error });
      }
    );
  };
}

/* Reinitial Item Specification */
export function reinitializeProduxtSpecification(data = {}) {
  return { type: PRODUCT_ITEM_SPECIFICATION_DATA, payload: data };
}

/**** Translate API ****/
export function getTranslatedResults(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: error });
      }
    );
  };
}

/**** Product Fee ****/

/**** For Fisrt Time show default ****/
export function setProductFeeDefault(isFisrtTime) {
  return { type: IS_PRODUCT_FEE_DEFAULT, payload: isFisrtTime };
}

export function setProductConfirmBtnClickedOrNot(status) {
  return { type: IS_PRODUCT_SUBMIT_CONFIRM, payload: status };
}

/**** Common update for product table [PATCH] ****/
export function directPeoductUpdateWithObject(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/products`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({
          success: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          success: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

export function relistwhileUpdatingProduct(data = false) {
  // console.log('relist_while_product_upload',data)
  return { type: RELIST_WHILEUPDATE, payload: data };
}

// latest code
export function getlistingpriceAmount(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/getlistingprice`;
  return (dispatch) => {
    postRequest(
      url,
      {},
      session,
      dispatch,
      (response) => {
        dispatch({
          type: LISTING_AMOUNT,
          payload: { data: response.amount },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function makepaymentPersonalvendor(session, values, callback) {
  // var url = `${INSTANCE_URL}/api/v2/fatorapay`;
  var url = `${INSTANCE_URL}/api/v2/skipcashpay`; //latest
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.success == true) {
          callback({ success: 1, data: response });
        } else {
          callback({ success: 0, data: response });
        }
      },
      (error) => {
        callback({ success: 0, data: error });
      }
    );
  };
}

/* Fetch country list */
// export function fetchCountryList(session, callback) {
//   var url = `${INSTANCE_URL}/api/v2/shopez/_table/country`; // latest code
//   // Actual Data Call
//   return (dispatch) => {
//     getRequest(
//       url,
//       session,
//       dispatch,
//       (response) => {
//         dispatch({
//           type: COUNTRY_LIST_ARR,
//           payload: { data: response.resource },
//         });
//         callback({
//           status: 1,
//           message: LocaleStrings.api_success_status,
//           data: response.resource,
//         });
//       },
//       (error) => {
//         callback({ status: 0 });
//       }
//     );
//   };
// }

export function fetchCountryList(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/country`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: COUNTRY_LIST_ARR,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function updateMastervariationListArray(data) {
  return { type: VARIATION_MASTER_LIST, payload: { data: data } };

  // dispatch({
  //   type: VARIATION_MASTER_LIST,
  //   payload: { data: response?.data },
  // });
}

export function fetchContinentList(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/continent?related=*`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CONTINENT_LIST_ARR,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function productExculdelocation(data) {
  return { type: PRODUCT_EXCLUDE_LOCATION, payload: data };
}
export function continentLength(value) {
  return { type: SELECTED_CONTINENT_LENGTH, payload: value };
}
