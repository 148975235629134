import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import Components
import BaseLoader from "@baseLoader";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
import StoreVerfiEdit from "./storeverfiedit";

// Import Actions
import { fetchUsersDeatils } from "../../actions/index";

class StoreVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_store_verification,
    };

    // All Binded Functions
  }

  componentDidMount() {
    // Fetch Vendor Details With LoginIdStore
    this.props.fetchUsersDeatils(this.props.session, (callBackResponce) => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <>
        <CommonHeaderProfileSettings
          commonHeaderText={LocaleStrings.profile_settings_list_text3}
        />
        {this.state.loading ? (
          <BaseLoader data={this.state.loaderDesc} />
        ) : (
          <>
            <StoreVerfiEdit />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
})(StoreVerification);
