import { combineReducers } from "redux";
import { loadState, reset } from "../localStorage";
import { reducer as formReducer } from "redux-form";
import { LOGIN, APP_API_KEY } from "@constant";

import {
  ACTION_LOGOUT,
  SESSION_TOKEN,
  USER_ID,
  USER_NAME,
  USER_TYPE,
  USER_EMAIL,
  USER_PICTURE,
  IS_APPROVE,
  IS_ACTIVE,
  IS_VENDORTOO,
  CAN_RECEIVE_NOTIFICATION,
  IS_FORGOT_PASSWORD_OPEN,
  LOCALE_LANGUAGE,
  SET_CURRENCY,
  USER_TAX,
  PHONE_VERIFIED,
  CALLEDFROM_APP,
  EMAIL_VERIFIED,
  IS_VERFICATION_MODAL_OPEN,
  VENDOR_VERIFIED,
  USER_ISFREEZE,
  VERIFYEMAILPROFILE_SETTINGS,
  COUNTRY_PHONE_LENGTH,
} from "../actions";

// Side BAr
import {
  SidebarSelectedMenu,
  SideBarMenus,
  isNotificationOpen,
  appcounters,
  notificationList,
  messageList,
  isMessageOpen,
  storeMessageList,
  notificationSingleObjectStore,
  notificationDetailsStore,
  selectedMessageTab,
  orderMessageList,
  currentOrderStep,
  storeOrdMessageList,
  basicOrderThreadData,
} from "@sidebarReducers";

// Product Management
import {
  uploadProductsTabs,
  ProductList,
  fetchProductManagementDefaultSettings,
  isProductDiscountopen,
  isExcludeshippingopen,
  ischarityviewModalopen,
  isProdCateSubCateModalOpen,
  productTitleSubTitleData,
  ProductCategorylist,
  allSelectedSubcategorisID,
  isVariationDeatilsopen,
  isCropperModalopen, //cropper
  storedItemSpecifications,
  productDiscountAddedData,
  isProductFeeOpen,
  productObjectForFeeDetails,
  isProductFeeDefault,
  producvtSubmitConfirm,
  discountlist,
  productDetails,
  productQaWithLimit,
  productQaWithOutLimit,
  isQaopen,
  isQaNotificOpen,
  isCommentsOpen,
  isCommentsNotificOpen,
  commentList,
  toBeEditproduct,
  ShippngTypeStatus,
  isLikesopen,
  productlikesData,
  publicBidModal,
  publicBids,
  globalSpecifications, // Global specification
  setupStoreBlockerModal,
  isCheckShippingBearerAvailable, // Shipping Bearer Available
  variationMasterList, // Variation master list
  isShowUploadSection, // Substitute of sectionToShow state[this.props.showUploadSection]
  productLastPaymentStatus, // product payment status [personal vendor]
  isRepaymentOpen, // Paynow / repayment option
  toggleGridAndList, // state for grid or list view of product list
  zoneAndpincodes, // Will store all zone and pincode list here
  relist_while_product_upload, //relist while adding product
  defaultShippingCharges,
  listingamount, //listingamount
  countryList, //countryList
  continentList, //continentList
  productexcludeLocationdata, //productexcludeLocationdata
  continentLengthdata, //continentLengthdat
  auction_daysList,
  isCatsubcatupdatedData,
} from "@productmanagementReducers";
// Slaes Management
import {
  salesDateTabs,
  salesCancRetuReplTabs,
  VendorStatsStore,
  salesDateMonthsIndex,
  VendorSalesStatsStore,
  salesmanStatboxSelectedStore,
  VendorOrderListStore,
  VendorNegotiationListStore,
  isNegotiationopen,
  VendorNegotiationDetailsStore,
  isNewOrderDetlModalopen,
  CurrentOrderID,
  CurrentOrderObj,
  cameFromSalesMange,
} from "@salesmanagementReducers";

// Discount Management
import {
  DiscountMasterStore,
  ProductCategory,
  ProductSubCategory,
  ProductListdiscount,
  CriteriaMaster,
  DiscountArea,
  ProductByCateSubCate,
  isDiscManageModalopen,
  singleDiscountObject,
} from "@discountsmanagementReducers";

// Coupons Management
import {
  CouponMasterStore,
  isCouponManageModalopen,
  CouponsTabs,
  storedCouponsTab,
  singleCouponObject,
} from "@couponsmanagementReducers";
// Profile Settings
import {
  profileSettingsList,
  ProfileSettingsSelectedMenu,
  storeMyFollowersData,
  isFollowersDetailsModalOpen,
  storeCurrentMyFollowersData,
  storeCurrentMyFollowersUserData,
  myFolloersTabList,
  currentShearedWishlist,
  StoreDetails,
  personalvendorStoreDetails,
  StoreContacts,
  isOpenStoreInfoMapModal,
  isOpenProfileInfoModal,
  storeUsersDetails,
  storeSelectedGender,
  storeCountries,
  bankAccount,
  navigateUser,
  publicProfile,
  reportAbuseModal,
  reportAbuseQaModal,
  storeInfoLocalCoverImageReducer,
  isVerifyEmailPrflInfoModal, // Email will open from Profile info if not verified email already
} from "@profileSettingsReducers";

// FAQ & Support
import { isRaiseTicketopen, faqdata } from "@faqsupportReducers";

//Legal Docs
import { storeLegalDocsList, settingsMasterStore } from "@legaldocsReducers";

//Revenue Reports
import {
  revenueSalesDateTabs,
  storeReportTypeList,
  VendorDetailedSalesStore,
  salesSearchDetailsStore,
  revenueDateTabs,
  VendorDetailedRevenueStore,
  transactionDetailsStore,
} from "@revenuereportsReducers";

// import { SESSION_TOKEN_PASSWORD } from "@profileSettingsActions";

var LoginReducer = (state = true, action) => {
  // need to change it to false
  var state = loadState();

  if (state === undefined || state === "undefined") {
    state = {
      isLoggedIn: false,
    };
  } else {
    if (state.projectName && state.projectName === "shopezVendor") {
      // DO Nothing
    } else {
      reset();
    }
  }

  if (action.type === LOGIN) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  return state.isLoggedIn;
};

var phoneLengthCountry = (state = "0", action) => {
  // var phoneLengthCountry = (state = "974", action) => {
  if (action.type === COUNTRY_PHONE_LENGTH) {
    state = action.payload;
  }

  return state;
};

// stores Data for session also on localstorage
var TokensReducer = (state = {}, action) => {
  var state = loadState();

  if (state === undefined || state.session === undefined) {
    state = {
      session: {
        apiKey: APP_API_KEY,
        sessionToken: "",
        emailid: "",
        userid: "",
        usertype: "",
      },
    };
  }

  if (action.type === SESSION_TOKEN) {
    // console.log("action.payload.session_token", action.payload.session_token);
    // debugger;
    if (action.payload.session_token) {
      state.session.sessionToken = action.payload.session_token;
    }
    state.session.emailid = action.payload.email;
    state.session.usertype = action.payload.usertype;
    state.session.userid = action.payload.userid;
  }

  if (action.type === ACTION_LOGOUT) {
    state.session.sessionToken = "";
    state.session.emailid = "";
    state.session.userid = "";
    state.session.usertype = "";
    reset();
  }

  return state.session;
};

// Stores User Related data
var UserReducer = (state = {}, action) => {
  var state = loadState();
  if (state === undefined || state.user === undefined) {
    state = {
      user: {
        userid: "",
        username: "",
        usertype: "",
        userimage: "",
        tax: "",
        email: "",
        isapprove: false,
        isfreeze: false,
        isactive: false,
        isvendortoo: false,
        canreceivenotification: false,
        calledfromapp: false,
      },
    };
  }

  // DF user id
  if (action.type === USER_ID) {
    state.user.userid = action.payload;
  }

  if (action.type === USER_NAME) {
    state.user.username = action.payload;
  }

  if (action.type === USER_TYPE) {
    state.user.usertype = action.payload;
  }

  if (action.type === USER_PICTURE) {
    state.user.userimage = action.payload;
  }

  if (action.type === USER_EMAIL) {
    state.user.email = action.payload;
  }

  if (action.type === VENDOR_VERIFIED) {
    state.user.verified = action.payload;
  }
  if (action.type === IS_APPROVE) {
    state.user.isapprove = action.payload;
  }
  if (action.type === IS_ACTIVE) {
    state.user.isactive = action.payload;
  }
  if (action.type === USER_ISFREEZE) {
    state.user.isfreeze = action.payload;
  }
  if (action.type === IS_VENDORTOO) {
    state.user.isvendortoo = action.payload;
  }
  if (action.type === CAN_RECEIVE_NOTIFICATION) {
    state.user.canreceivenotification = action.payload;
  }
  if (action.type === USER_TAX) {
    state.user.tax = action.payload;
  }
  if (action.type === EMAIL_VERIFIED) {
    state.user.emailverified = action.payload;
  }
  if (action.type === PHONE_VERIFIED) {
    state.user.phoneverified = action.payload;
  }
  if (action.type === CALLEDFROM_APP) {
    state.user.calledfromapp = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.user.userid = "";
    state.user.username = "";
    state.usertype = "";
    state.user.userimage = "";
    state.user.email = "";
    state.user.isapprove = false;
    state.user.calledfromapp = false;
    state.user.isactive = false;
    state.user.isfreeze = false;
    state.user.isvendortoo = false;
    state.user.canreceivenotification = false;
    state.user.tax = "";
  }

  return state.user;
};

// Is Forgot password modal open
export var isForgotPasswordOpen = (state = false, action) => {
  if (action.type === IS_FORGOT_PASSWORD_OPEN) {
    state = action.payload;
  }

  return state;
};

// Is Verfication Modal Open
export var isVerficationOpen = (state = false, action) => {
  if (action.type === IS_VERFICATION_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

// Locale Language change
export var localeLanguage = (state = "ar", action) => {
  if (action.type === LOCALE_LANGUAGE) {
    state = action.payload;
  }
  return state;
};

export var localCurrency = (state = "QAR", action) => {
  if (action.type === SET_CURRENCY) {
    state = action.payload;
  }
  return state;
};
export var emailverifyProfilesettings = (state = {}, action) => {
  switch (action.type) {
    case VERIFYEMAILPROFILE_SETTINGS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

var rootReducer = combineReducers({
  isLoggedIn: LoginReducer,
  session: TokensReducer,
  user: UserReducer,
  isForgotPasswordOpen: isForgotPasswordOpen,
  isVerficationOpen: isVerficationOpen,
  language: localeLanguage,
  currency: localCurrency,
  form: formReducer,
  emailverifyProfilesettings: emailverifyProfilesettings,
  //SignUp

  //Sidebar
  sideBarMenus: SideBarMenus,
  sidebarSelectedMenu: SidebarSelectedMenu,
  isNotificationOpen: isNotificationOpen,
  appcounters: appcounters, // STORE notification Values
  notificationList: notificationList, // Notification List
  messageList: messageList, // Messaging List
  orderMessageList, // Order Messaging
  isMessageOpen: isMessageOpen, //STORE Messaging Modal Is open
  storeMessageList: storeMessageList, //STORE Messaging List With Thread Id
  notifiSingleObjectStore: notificationSingleObjectStore, //STORE Notification Object
  notificationDetailsStore: notificationDetailsStore, //STORE Notification Details with [Notification ID]
  selectedMessageTab,
  currentOrderStep,
  storeOrdMessageList,
  basicOrderThreadData,

  // Product Management
  uploadProductsTabs: uploadProductsTabs,
  productList: ProductList,
  DefaultSettings: fetchProductManagementDefaultSettings,
  isProductDiscountopen: isProductDiscountopen,
  isExcludeshippingopen: isExcludeshippingopen,
  ischarityviewModalopen: ischarityviewModalopen,
  isVariationDeatilsopen: isVariationDeatilsopen,
  isCropperModalopen: isCropperModalopen,
  prodCateSubCateModal: isProdCateSubCateModalOpen,
  productTitleSubTitleData: productTitleSubTitleData,
  productCategorylist: ProductCategorylist,
  selectedSubcategorids: allSelectedSubcategorisID,
  storedItemSpecifications: storedItemSpecifications,
  prodDiscAddedData: productDiscountAddedData,
  producvtSubmitConfirm: producvtSubmitConfirm,
  discountlist: discountlist,
  productDetails: productDetails,
  productQaWithLimit: productQaWithLimit,
  productQaWithOutLimit,
  isCommentsOpen: isCommentsOpen,
  isCommentsNotificOpen: isCommentsNotificOpen,
  commentList: commentList,
  toBeEditproduct: toBeEditproduct,
  shippngTypeStatus: ShippngTypeStatus,
  variationMasterList: variationMasterList,
  isShowUploadSection: isShowUploadSection,
  productLastPaymentStatus: productLastPaymentStatus,
  isRepaymentOpen: isRepaymentOpen,
  toggleGridAndList,
  zoneAndpincodes,
  relist_while_product_upload: relist_while_product_upload,
  defaultShippingCharges: defaultShippingCharges,
  listingamount: listingamount,
  countryList: countryList,
  continentList: continentList,
  productexcludeLocationdata: productexcludeLocationdata,
  continentLengthdata: continentLengthdata,
  auction_daysList: auction_daysList,
  isCatsubcatupdatedData: isCatsubcatupdatedData,
  // Sales Management
  salesDateTabs: salesDateTabs,
  vendorStats: VendorStatsStore,
  salesDateMonthsList: salesDateMonthsIndex,
  vendorSalesStatsData: VendorSalesStatsStore,
  salesmanStatboxSelected: salesmanStatboxSelectedStore,
  vendorOrderListStore: VendorOrderListStore,
  vendorNegotiationListStore: VendorNegotiationListStore,
  salesCancRetuReplTabs: salesCancRetuReplTabs,
  isNegotiationopen: isNegotiationopen,
  negotiationDetails: VendorNegotiationDetailsStore,
  isNewOrderDetlModalopen: isNewOrderDetlModalopen,
  currentOrderID: CurrentOrderID,
  currentOrderObj: CurrentOrderObj,
  cameFromSalesMange: cameFromSalesMange,

  // Discount Management
  discountMasterStore: DiscountMasterStore,
  productCategory: ProductCategory,
  productSubCategory: ProductSubCategory,
  productListdiscount: ProductListdiscount,
  criteriaMaster: CriteriaMaster,
  discountArea: DiscountArea,
  productByCateSubCate: ProductByCateSubCate,
  isDiscManageModalopen: isDiscManageModalopen,
  singleDiscountObject: singleDiscountObject,

  // Coupons Management
  couponMasterStore: CouponMasterStore,
  isCouponManageModalopen: isCouponManageModalopen,
  couponsTabs: CouponsTabs,
  storedCouponsTab: storedCouponsTab,
  singleCouponObject: singleCouponObject,

  // Profile Settings
  profileSettingsList: profileSettingsList,
  profileSettingsSelectedMenu: ProfileSettingsSelectedMenu,
  storeMyFollowersData: storeMyFollowersData,
  isFollowersDetailsModalOpen: isFollowersDetailsModalOpen,
  storeCurrentMyFollowersData: storeCurrentMyFollowersData,
  storeCurrentMyFollowersUserData: storeCurrentMyFollowersUserData,
  myFolloersTabList: myFolloersTabList,
  currentShearedWishlist: currentShearedWishlist,
  storeDetails: StoreDetails,
  personalvendorStoreDetails: personalvendorStoreDetails,
  storeContacts: StoreContacts,
  isOpenStoreInfoMapModal: isOpenStoreInfoMapModal,
  vendorDetails: storeUsersDetails,
  isOpenProfileInfoModal: isOpenProfileInfoModal,
  storeSelectedGender: storeSelectedGender,
  storeCountries: storeCountries,
  bankAccount: bankAccount,
  navigateUser: navigateUser,
  publicProfile: publicProfile,
  reportAbuseModal: reportAbuseModal,
  reportAbuseQaModal: reportAbuseQaModal,
  localImageCover: storeInfoLocalCoverImageReducer,
  isVerifyEmailPrflInfoModal: isVerifyEmailPrflInfoModal,

  //Product Fee
  isProductFeeOpen: isProductFeeOpen,
  productObjectForFeeDetails: productObjectForFeeDetails,
  isProductFeeDefault: isProductFeeDefault,
  isQaopen: isQaopen,
  isQaNotificOpen: isQaNotificOpen,
  isLikesopen: isLikesopen,
  publicBidModal: publicBidModal,
  publicBids: publicBids,
  globalSpecifications: globalSpecifications,
  setupStoreBlockerModal: setupStoreBlockerModal,
  productlikesData: productlikesData,
  isCheckShippingBearerAvailable: isCheckShippingBearerAvailable,

  // FAQ & Support
  isRaiseTicketopen: isRaiseTicketopen,
  faqdata: faqdata,

  //Legal Docs
  storeLegalDocsList: storeLegalDocsList,
  settingsMasterStore: settingsMasterStore,
  storeReportTypeList: storeReportTypeList,
  revenueSalesDateTabs: revenueSalesDateTabs,
  vendorDetailedSalesStore: VendorDetailedSalesStore,
  salesSearchDetailsStore: salesSearchDetailsStore,
  revenueDateTabs: revenueDateTabs,
  vendorDetailedRevenueStore: VendorDetailedRevenueStore,
  transactionDetailsStore: transactionDetailsStore,

  // qatar phone validate
  phoneLengthCountry: phoneLengthCountry,
});

export default rootReducer;
