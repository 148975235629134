import {
  REVENUEREPORTS_DETAILEDSALES,
  SALES_SEARCHDEATILS,
  REVENUEREPORTS_DETAILEDREVENUE,
  TRANSACTION_HISTORY,
} from "../actions/index";
import LocaleStrings from "@language";

/**** Revenue Reports Static List ****/
export var storeReportTypeList = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.revenue_reports_list_text1,
      subname: "",
      href: "revenue",
      icon: "revenue_reports",
      current: false,
      position: 0,
    },
    {
      name: LocaleStrings.revenue_reports_list_text2,
      subname: "",
      href: "sales",
      icon: "sales_management",
      current: false,
      position: 1,
    },
    {
      name: LocaleStrings.revenue_reports_list_text3,
      subname: "",
      href: "transaction",
      icon: "transaction",
      current: false,
      position: 2,
    },
  ];

  return state;
};

//SALES
// Tabs On Revenue Reports Data Tabs [Sales Tabs]
export var revenueSalesDateTabs = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.saler_tab_text_alltime,
      href: "#",
      current: false,
      position: 0,
    },
    {
      name: LocaleStrings.saler_tab_text_daily,
      href: "#",
      current: false,
      position: 1,
    },
    {
      name: LocaleStrings.saler_tab_text_weekly,
      href: "#",
      current: false,
      position: 2,
    },
    {
      name: LocaleStrings.saler_tab_text_monthly,
      href: "#",
      current: true,
      position: 3,
    },
  ];

  return state;
};

/**** Fetch Sales Report Data [Detailed Sales] *****/
export var VendorDetailedSalesStore = (state = {}, action) => {
  switch (action.type) {
    case REVENUEREPORTS_DETAILEDSALES:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Fetch Sales Search [Detailed Sales Search] *****/
export var salesSearchDetailsStore = (state = {}, action) => {
  switch (action.type) {
    case SALES_SEARCHDEATILS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

//REVENUE
// Tabs On Revenue Reports Data Tabs [Revenue Tabs]
export var revenueDateTabs = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.saler_tab_text_alltime,
      href: "#",
      current: false,
      position: 0,
    },
    {
      name: LocaleStrings.saler_tab_text_daily,
      href: "#",
      current: false,
      position: 1,
    },
    {
      name: LocaleStrings.saler_tab_text_weekly,
      href: "#",
      current: false,
      position: 2,
    },
    {
      name: LocaleStrings.saler_tab_text_monthly,
      href: "#",
      current: true,
      position: 3,
    },
  ];

  return state;
};

/**** Fetch Revenue Report Data [Detailed Revenue] *****/
export var VendorDetailedRevenueStore = (state = {}, action) => {
  switch (action.type) {
    case REVENUEREPORTS_DETAILEDREVENUE:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** STORE TRANSACTION HISTORY [Detailed Revenue] *****/
export var transactionDetailsStore = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTION_HISTORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
