import React, { Component } from "react";
// import { PushSpinner } from "react-spinners-kit";

class BaseLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      // loaderDesc: LocaleStrings.preparing_discounts_management,
    };

    // All Binded Functions
  }

  render() {
    return (
      <>
        <div className="bg-custWhite min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex">
              <div className="sm:ml-6">
                <div
                  className="--sm:border-l sm:border-ternary sm:pl-6 transition ease-in-out duration-700"
                  align="center"
                >
                  {/* 
                  Old loader
                  <PushSpinner
                  size={40}
                  color="var(--theme-color)"
                  loading={this.state.loading}
                /> */}

                  <img
                    src="/images2/common/shopez-loader.gif"
                    className="h-20 w-20"
                    alt="loader"
                  />
                  <p className="text-base font-normal text-primary text-center tracking-tight mt-4">
                    {this.props.data}
                  </p>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default BaseLoader;

// var mapStateToProps = (state,) => ({
//     isLoggedIn: state.isLoggedIn,
//     user: state.user,
//     session: state.session,
//   });

// export default connect(mapStateToProps,{

// })(BaseLoader);
