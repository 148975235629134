import {
  getRequest,
  postRequest,
  patchRequest,
  uploadEncodedFileLarge,
} from "@networkCall";

import { INSTANCE_URL, BASE_IMAGES_URL } from "@constant";

import {
  // SESSION_TOKEN,
  EMAIL_VERIFIED,
  PHONE_VERIFIED,
  USER_ID,
  VENDOR_VERIFIED,
  SET_CURRENCY,
  USER_TAX,
  USER_NAME,
  USER_ISFREEZE,
  USER_PICTURE,
} from "@mainActions";

import _ from "lodash";

import LocaleStrings from "@language";

export var SELECTED_PROFILESETTINGS_MENU = "SELECTED_PROFILESETTINGS_MENU";
export var PROFILESETTINGS_MYFOLLOWERS = "PROFILESETTINGS_MYFOLLOWERS";
export var PROFILESETTINGS_MYFOLLOWERS_CURRENT =
  "PROFILESETTINGS_MYFOLLOWERS_CURRENT";
export var IS_NEGO_SLIDEOVERS_MODAL_OPEN = "IS_NEGO_SLIDEOVERS_MODAL_OPEN";
export var PROFILESETTINGS_MYFOLLOWERS_CURRENT_USERDATA =
  "PROFILESETTINGS_MYFOLLOWERS_CURRENT_USERDATA";
export var PROFILESETTINGS_CURUSER_SHAREDWISHLIST =
  "PROFILESETTINGS_CURUSER_SHAREDWISHLIST";
export var IS_STOREINFO_MAP_MODAL_OPEN = "IS_STOREINFO_MAP_MODAL_OPEN";

export var PROFILESETTINGS_STOREDETAILS = "PROFILESETTINGS_STOREDETAILS";
export var PROFILESETTINGS_STORECONTACTS = "PROFILESETTINGS_STORECONTACTS";

export var PROFILESETTINGS_USERSDETAILS = "PROFILESETTINGS_USERSDETAILS";

export var IS_PROFILEINFO_COMMON_MODAL_OPEN =
  "IS_PROFILEINFO_COMMON_MODAL_OPEN";
export var PROFILEINFO_SELECTED_GENDER = "PROFILEINFO_SELECTED_GENDER";
export var ACTSETTINGS_COUNTRIES = "ACTSETTINGS_COUNTRIES";

export var USER_ACCOUNT = "USER_ACCOUNT";

export const NAVIGATE_PROFILE_VIEW = "NAVIGATE_PROFILE_VIEW";
export const PUBLIC_PROFILE = "PUBLIC_PROFILE";
export const REPORT_ABUSE_MODAL = "REPORT_ABUSE_MODAL";
export const REPORT_ABUSE_QA_MODAL = "REPORT_ABUSE_QA_MODAL";

export var UPDATE_STORE_COVER_LOCALLY = "UPDATE_STORE_COVER_LOCALLY"; // CROPPER JS
export var IS_VERIFY_EMAIL_PROFILEINFO_MODAL_OPEN =
  "IS_VERIFY_EMAIL_PROFILEINFO_MODAL_OPEN"; // VERIFY EMAIL FROM PROFILE MANAGEMENT
export var PROFILESETTINGS_PERSONALVENDOR_STOREDETAILS =
  "PROFILESETTINGS_PERSONALVENDOR_STOREDETAILS"; // PERSONAL VENDOR STORE DETAILS
// export var SESSION_TOKEN_PASSWORD = "SESSION_TOKEN_PASSWORD"; // SESSION_TOKEN_PASSWORD

export function profileViewNavigation(userlist) {
  console.log("userlist", userlist);
  return (dispatch) => {
    dispatch({
      type: NAVIGATE_PROFILE_VIEW,
      payload: userlist,
    });
  };
}

/**** On Click Select Profile Settings Menu ****/
export function selectedProfileSettingsMenu(session, selectedMenu) {
  return (dispatch) => {
    dispatch({ type: SELECTED_PROFILESETTINGS_MENU, payload: selectedMenu });
  };
}

/**** Fetch My Followers *****/
export function fetchMyFollowers(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users_followers?related=*&fields=*&filter=userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_MYFOLLOWERS,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/***** Is Followers Deatls Modal open or not *****/
export function isFollowersDetailsOpen(isOpen) {
  return { type: IS_NEGO_SLIDEOVERS_MODAL_OPEN, payload: isOpen };
}

/**** Fetch Selected Followrs Details ****/
export function selectedFollowersDetails(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/userfollowers`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: followerid,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_MYFOLLOWERS_CURRENT,
          payload: { data: response.followers },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch Current Selected Follower User Deatils ****/
export function selectedFollowerUserDetails(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?&filter=userid=${followerid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_MYFOLLOWERS_CURRENT_USERDATA,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Selected Followers Sheared Wishlist ****/
export function selectedFollowersShearedWishlist(
  session,
  followerid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/usersharedwishlists`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: followerid,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_CURUSER_SHAREDWISHLIST,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Selected Followers Sheared Wishlist ****/
export function emptySharedWishlist(callback) {
  return (dispatch) => {
    dispatch({
      type: PROFILESETTINGS_CURUSER_SHAREDWISHLIST,
      payload: {},
    });
  };
}

/**** Follow/Unfollow ****/
export function setFollowunfollow(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/followunfollow`;

  return (dispatch) => {
    postRequest(
      url,
      {
        followeduser: followerid,
      },
      session,
      dispatch,
      (response) => {
        //  dispatch({

        //   type: PROFILESETTINGS_CURUSER_SHAREDWISHLIST,
        //   payload: { data: response.data },

        // });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch My Following List ****/

export function fetchMyFolloings(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/userfollowers`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: session.userid,
      },
      session,
      dispatch,
      (response) => {
        //  dispatch({

        //   type: PROFILESETTINGS_CURUSER_SHAREDWISHLIST,
        //   payload: { data: response.data },

        // });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function viewPublicProfile(session, userid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/publicprofile`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: userid,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PUBLIC_PROFILE,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function openReportModal(showModal = false, typeid = "") {
  return {
    type: REPORT_ABUSE_MODAL,
    payload: {
      showModal: showModal,
      typeid: typeid,
    },
  };
}

export function openQAReportModal(showModal = false, typeid = "", type = "") {
  return {
    type: REPORT_ABUSE_QA_MODAL,
    payload: {
      showModal: showModal,
      typeid: typeid,
      type: type,
    },
  };
}

export function reportAbuse(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/reportabuse`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

/****** STORE INFORMATION ******/

/**** fetch store details ****/
export function fetchStoreDetails(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store?related=*&filter=createdby=${session.userid}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.resource.length > 0) {
          let storeid = response.resource[0].storeid;
          dispatch({
            type: PROFILESETTINGS_STOREDETAILS,
            payload: { data: response.resource[0] },
          });

          // Call Contacts deytails (storeid)
          dispatch(fetchStoreContacts(session, storeid));
        }

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: [],
        });
      }
    );
  };
}

/****  Fetch Contacts ****/
export function fetchStoreContacts(session, storeid) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store_contacts?filter=storeid=${storeid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_STORECONTACTS,
          payload: { data: response.resource[0] },
        });

        ///callback({ status: 1 ,message: LocaleStrings.api_success_status});
      },
      (error) => {
        //callback({ status: 0 ,message: LocaleStrings.something_went_wrong});
      }
    );
  };
}

export function uploadData(session, data, callback) {
  return { allgood: "yes" };
}

/**** Upload store Media ****/

export function uploadMediaLarge(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/store/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/*** ADD Store Data ***/
export function addStore(session, object, objcontact, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        objcontact.storeid = response.resource[0].storeid;

        // Store Contacts ()
        dispatch(addStoreContacts(session, objcontact, (innercallback) => {}));

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Update Store Data ****/
export function updateStore(session, object, objcontact, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // Store Contacts ()
        dispatch(
          updateStoreContacts(session, objcontact, (innercallback) => {})
        );

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Update Store Contact Data ****/
export function updateStoreContacts(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store_contacts`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}
/**** Update Store Contact Data ****/
export function addStoreContacts(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store_contacts`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/***** Is Store Info Map Modal open or not *****/
export function isStoreInfoMapModalOpen(isOpen) {
  return { type: IS_STOREINFO_MAP_MODAL_OPEN, payload: isOpen };
}

/**** Update User Password ****/
export function updateUserPassord(session, object, callback) {
  // var url = `${INSTANCE_URL}/api/v2/user/password`;
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/resetpassword`;
  //let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      object,
      session,
      dispatch,
      (response) => {
        // console.log("response?.session_token", response?.session_token);
        // dispatch({
        //   type: SESSION_TOKEN_PASSWORD,
        //   payload: { session_token: response?.session_token },
        // });

        if (response.result == "error") {
          callback({
            status: 0,
            message: response.message,
            data: response,
          });
        } else {
          callback({
            status: 1,
            message: "Updated Successfully",
            data: response,
          });
        }
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

/* Store Verfication */

/**** User Details (users atble) with session Id ****/
export function fetchUsersDeatils(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?filter=userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_USERSDETAILS,
          payload: { data: response.resource[0] },
        });

        dispatch({
          type: PROFILEINFO_SELECTED_GENDER,
          payload: { data: response.resource[0].gender },
        });

        dispatch({
          type: PHONE_VERIFIED,
          payload: response.resource[0].phoneverified,
        });

        dispatch({
          type: EMAIL_VERIFIED,
          payload: response.resource[0].emailverified,
        });

        dispatch({
          type: VENDOR_VERIFIED,
          payload: response.resource[0].verified,
        });

        dispatch({
          type: USER_TAX,
          payload: response.resource[0].tax,
        });

        dispatch({
          type: SET_CURRENCY,
          payload: response.resource[0].currencychoice,
        });

        dispatch({
          type: USER_ISFREEZE,
          payload: response.resource[0].isfreeze,
        });

        dispatch({ type: USER_ID, payload: response.resource[0].id });

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Upload store Media ****/

export function uploadMediaLargeStoreVerfic(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else if (filetype == "image") {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  } else {
    var blob = new Blob([filecontent], { type: filetype });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/store/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/**** Store Users Updated ****/
export function updateStoreUsers(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (object.username) {
          dispatch({
            type: USER_NAME,
            payload: object.username,
          });
        }
        if (object.phoneverified && object.phoneverified == 1) {
          dispatch({
            type: PHONE_VERIFIED,
            payload: true,
          });
        }
        if (object.userimage == null) {
          dispatch({ type: USER_PICTURE, payload: null });
        }

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* Profile Information */

/* Email verification from profile information */
export function isPrflInfoVerifyEmailModalOpen(isOpen) {
  return { type: IS_VERIFY_EMAIL_PROFILEINFO_MODAL_OPEN, payload: isOpen };
}

export function uploadMediaLargeProfileInfo(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else if (filetype == "image") {
    var blob = new Blob([filecontent], { type: "image/jpg" });
  } else {
    var blob = new Blob([filecontent], { type: filetype });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/users/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/***** Is PROFILE INFORMATION COMMON Modal open or not *****/
export function isProfileinfoCommonOpen(isOpen) {
  return { type: IS_PROFILEINFO_COMMON_MODAL_OPEN, payload: isOpen };
}

/**** MAKE GENDER SELECTED ****/
export function setSelectedGender(gender) {
  return { type: PROFILEINFO_SELECTED_GENDER, payload: { data: gender } };
}

export function sendSms(session, phone, isResend = false, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendsms/sendotp`;
  var params = { mobile: `${phone}`, resend: isResend };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        if (response.success && response.success == "fail") {
          callback({ success: 0, message: response.message });
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function verifyOtp(session, otp, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendsms/verifyotp`;
  var params = { code: otp };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        callback({ success: response.success ? 1 : 0 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function updateStoreCoverLocally(image) {
  return (dispatch) => {
    dispatch({ type: UPDATE_STORE_COVER_LOCALLY, payload: image });
  };
}

// ACCOUNT SETTINGS

/**** Fetch Contries ****/
export function fetchCountries(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/country?order=name%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ACTSETTINGS_COUNTRIES,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* Fetch account detailes of login user */
export function fetchAccount(session, callback) {
  var filter = encodeURI(`(useridfk=${session.userid})`);

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_account?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: USER_ACCOUNT,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

/* Add/create account settings / acoount details */
export function addAccount(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_account`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (
          response.resource &&
          response.resource.length > 0 &&
          response.resource[0].id
        ) {
          callback({ success: 1, accountid: response.resource[0].id });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

/* Edit/Update account settings / acoount details */
export function updateAccount(session, values, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_account/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function verifyEmailid(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  return (dispatch) => {
    postRequest(
      url,
      {
        emailtype: "verifyemail",
      },
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function fetchpersonalStoreDetails(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/personalstore?related=*&filter=createdby=${session.userid}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.resource.length > 0) {
          dispatch({
            type: PROFILESETTINGS_PERSONALVENDOR_STOREDETAILS,
            payload: { data: response.resource[0] },
          });
        }

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: [],
        });
      }
    );
  };
}

/*** ADD  Personal Store Data ***/
export function addpersonalStore(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/personalstore`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Update Personal Store Data ****/
export function updatepersonalStore(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/personalstore`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

export function removeprofileImage(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/removepicture`;

  return (dispatch) => {
    postRequest(
      url,
      {},
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}
