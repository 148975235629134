import { postRequest, getRequest } from "@networkCall";
import LocaleStrings from "@language";
import { INSTANCE_URL } from "@constant";
import _ from "lodash";
import { parse } from "date-fns";

export const SALESMANAGEMENT_VENDORSTATS = "SALESMANAGEMENT_VENDORSTATS";
export const SALESMANAGEMENT_VENDORSALESSTATS =
  "SALESMANAGEMENT_VENDORSALESSTATS";
export const SALESMANAGEMENT_STATBOX_SELECTED =
  "SALESMANAGEMENT_STATBOX_SELECTED";

export const IS_NEGO_SLIDEOVERS_MODAL_OPEN = "IS_NEGO_SLIDEOVERS_MODAL_OPEN";
export const IS_NEW_ORDERDETAILS_MODAL_OPEN = "IS_NEW_ORDERDETAILS_MODAL_OPEN";

export const SALESMANAGEMENT_NEW_ORDERS = "SALESMANAGEMENT_NEW_ORDERS";
export const SALESMANAGEMENT_NEGOTIATION_LIST =
  "SALESMANAGEMENT_NEGOTIATION_LIST";
export const SALESMANAGEMENT_NEGOT_DETAILS = "SALESMANAGEMENT_NEGOT_DETAILS";

export const SALESMANAGEMENT_NEWORDERS_ORDERID =
  "SALESMANAGEMENT_NEWORDERS_ORDERID";

export const SALESMANAGEMENT_NEWORDERS_ORDEROBJ =
  "SALESMANAGEMENT_NEWORDERS_ORDEROBJ";

export const IS_CAME_FROM_SALES_MANAGEMNET = "IS_CAME_FROM_SALES_MANAGEMNET";

/***** On Sales Stats box Selected *****/
export function salesmanagementStatboxSelected(obj = "") {
  return { type: SALESMANAGEMENT_STATBOX_SELECTED, payload: obj };
}

/***** Fetch Negotiation Offers List *****/
export function fetchVendorNegotiationList(
  session,
  type,
  limit,
  page,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/negotiations`;

  let values = {};
  if (type === "listing") {
    // Paginated
    values.limit = limit;
    values.page = page;
  } else {
    // With out pagination [search result]
    values.searchterm = searchterm;
  }

  values.source = "web";
  values.usertype = "vendor";
  values.type = type;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SALESMANAGEMENT_NEGOTIATION_LIST,
          payload: { data: response.data, count: response.count },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch New Order List *****/
export function fetchVendorOrderList(
  session,
  status,
  usertype,
  type,
  limit,
  page,
  searchterm,
  filter,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/orders`;

  let values = {};
  if (type === "listing") {
    // Paginated
    values.limit = limit;
    values.page = page;

    if (status === "new" || status === "delivered") {
      /* Filter only applicable for [new , delivered] */
      values.filter = filter;
    }
  } else {
    // With out pagination [search result]
    values.searchterm = searchterm;
  }

  values.status = status;
  values.usertype = usertype;
  values.type = type;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SALESMANAGEMENT_NEW_ORDERS,
          payload: { data: response.orders, count: response.totalrows },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch vendor Stats *****/
export function fetchVendorStats(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/vendorstats`;

  return (dispatch) => {
    postRequest(
      url,
      {
        // session: session,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SALESMANAGEMENT_VENDORSTATS,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch vendor Sales Stats *****/
export function fetchVendorSalesStats(session, index, tab, callback) {
  var tab = tab == 2 ? "monthly" : tab == 1 ? "weekly" : "daily";

  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/sales`;

  return (dispatch) => {
    postRequest(
      url,
      {
        index: index,
        tab: tab,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SALESMANAGEMENT_VENDORSALESSTATS,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Negotiation Details With threadid ****/
export function fetchNegotiationDetails(session, threadid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/negotiationdetails`;

  return (dispatch) => {
    postRequest(
      url,
      {
        threadid: threadid,
        usertype: "vendor",
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SALESMANAGEMENT_NEGOT_DETAILS,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Negotiation Details With threadid ****/
export function updatenegotiation(session, threadid, status, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/updatenegotiation`;

  return (dispatch) => {
    postRequest(
      url,
      {
        threadid: threadid,
        type: "status",
        status: status,
      },
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/***** Is Negotiation Slide-overs Modal open or not *****/
export function isNegotiationOpen(isOpen) {
  return { type: IS_NEGO_SLIDEOVERS_MODAL_OPEN, payload: isOpen };
}

/***** Is new Order Details Modal Open or not *****/

export function toggleNewOrderDetailsModal(isOpen) {
  return { type: IS_NEW_ORDERDETAILS_MODAL_OPEN, payload: isOpen };
}

/***** Get Order Id On Modal Open  *****/
export function setNewOrderOrderID(orderId, orderObject) {
  return { type: SALESMANAGEMENT_NEWORDERS_ORDERID, payload: orderId };
}

/***** Get Order Object On Modal Open  *****/
export function setNewOrderOrderOBJECT(orderId, orderObject) {
  return { type: SALESMANAGEMENT_NEWORDERS_ORDEROBJ, payload: orderId };
}

/**** Orders Details Status update Vendor side *****/
export function updateorder(session, dataObj, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/updateorder`;

  return (dispatch) => {
    postRequest(
      url,
      dataObj,
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/*
    this action is need if user goes to sales retort from Slaes management
*/
export function camefromSalesManagemnet(isOpen) {
  return { type: IS_CAME_FROM_SALES_MANAGEMNET, payload: isOpen };
}

/* Create thread for order */
export function createOrderThread(session, orderId, buyerId, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/order_thread`;
  let object = {
    firstuserid: session.userid,
    seconduserid: buyerId,
    orderid: orderId,
  };
  let body = { resource: object };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: response });
      },
      (error) => {
        callback({ status: 0, message: error });
      }
    );
  };
}

/* If not created create and open order thread */
export function createOrOpenOrderThread(session, orderId, buyerId, callback) {
  let { userid } = session;
  userid = parseInt(userid);
  buyerId = parseInt(buyerId);

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/order_thread?fields=*&filter=((firstuserid=${session.userid})AND(seconduserid=${buyerId})AND(orderid=${orderId}))`;

  // First check if the thread is created or not
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (_.size(response.resource) > 0) {
          // Thread already created
          callback({
            status: 1,
            threadavailable: true,
            message: response.resource[0],
          });
        } else {
          // Create a order thread
          dispatch(
            createOrderThread(session, orderId, buyerId, (res) => {
              if (res.status === 1) {
                callback({
                  status: 1,
                  threadavailable: false,
                  message: res?.message?.resource[0],
                });
              } else {
                callback({
                  status: 0,
                  message: LocaleStrings.something_went_wrong,
                });
              }
            })
          );
        }
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

export function updateinitiateRefund(session, values, callback) {
  // var url = `${INSTANCE_URL}/api/v2/fatorapay`;//old
  var url = `${INSTANCE_URL}/api/v2/skipcashpay`; //skipcash

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.success == true) {
          callback({ status: 1 });
        } else {
          callback({ status: 0 });
        }
      },
      (error) => {}
    );
  };
}

export function partnerScriptorder(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/partnerscript/createorder`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log("response", response);
        if (response.result == "success") {
          callback({ status: 1, data: response });
        } else {
          callback({ status: 0 });
        }
      },
      (error) => {}
    );
  };
}

export function downloadorderInvoice(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/downloadinvoice`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.success == true) {
          callback({ success: 1, data: response });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {}
    );
  };
}
