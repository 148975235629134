import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from "@networkCall";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from "@constant";

import LocaleStrings from "@language";

// All Exports

export var SELECTED_SIDEBAR_MENU = "SELECTED_SIDEBAR_MENU";
export var NOTIFICATION_COUNT = "NOTIFICATION_COUNT"; // STORE NOTIFICATION COUNT

export const IS_NOTIFICATION_SLIDEOVERS_MODAL_OPEN =
  "IS_NOTIFICATION_SLIDEOVERS_MODAL_OPEN"; // ON NAV BAR (NOTIFICATION ICON)
export const NOTIFICATION_DETAILS_LIST = "NOTIFICATION_DETAILS_LIST"; // NOTIFICATION DETAILS LIST (NOTIFICATION ICON CLICK)
export const MESSAGING_DETAILS_LIST = "MESSAGING_DETAILS_LIST"; // MESSAGE DETAILS LIST (NAV BAR ICON)
export const IS_MESSAGING_SLIDEOVERS_OPEN = "IS_MESSAGING_SLIDEOVERS_OPEN"; // ON NAV BAR (MESSAGE ICON)
export const THREAD_MESSAGE = "THREAD_MESSAGE"; // Messages with Thread ID
export const SINGLE_NOTIFICATION_OBJECT = "SINGLE_NOTIFICATION_OBJECT"; // NOTIFICATION SELECTED OBJECT {SINGEL}
export const NOTIFICATION_DETAILS = "NOTIFICATION_DETAILS"; // NOTIFICATION DETAILS [BY ID]

export const SELECTED_MESSAGE_TAB = "SELECTED_MESSAGE_TAB"; // COMMON MASSAGE for products and order rated message
export const ORDER_MESSAGING_DETAILS_LIST = "ORDER_MESSAGING_DETAILS_LIST";
export const ORDER_MESSAGING_STEP = "ORDER_MESSAGING_STEP"; // This time putting step into redux for order messages thread
export const ORDER_THREAD_MESSAGES = "ORDER_THREAD_MESSAGES"; // Order Messages with Thread ID
export const ORDER_THREAD_BASICDATA = "ORDER_THREAD_BASICDATA"; // SOME BASIC DATA WE WILL USE IN ORDER MESSAGE SCREEN

/* Update SOME BASIC DATA */
export function updateBasicOrderthreadData(data) {
  return (dispatch) => {
    dispatch({ type: ORDER_THREAD_BASICDATA, payload: data });
  };
}

/* Update current step for order message thread [1 -> all thread list 2 means messgae list]*/
export function updateOrderStep(step) {
  return (dispatch) => {
    dispatch({ type: ORDER_MESSAGING_STEP, payload: step });
  };
}

/* Update the current tab name for massage section */
export function changeMessageTabName(selectedTab) {
  return (dispatch) => {
    dispatch({ type: SELECTED_MESSAGE_TAB, payload: selectedTab });
  };
}

export function onSidebarMenuSelected(selectedMenu) {
  return (dispatch) => {
    dispatch({ type: SELECTED_SIDEBAR_MENU, payload: selectedMenu });
  };
}

// NOTIFICATON
/***** Notification Slide-overs Modal open or not *****/
export function isNotificationModalOpen(isOpen) {
  return { type: IS_NOTIFICATION_SLIDEOVERS_MODAL_OPEN, payload: isOpen };
}

/**** Fetch Notification Count ****/
export function showNotificatonCount(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/appcounters`;
  let body = {};

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: NOTIFICATION_COUNT,
          payload: { data: response.data },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** FEtch Notification Details ****/
export function fetchNotificationDetails(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_notifications?related=notifications_by_notificationid&fields=*&filter=userid=${session.userid}&order=id%20DESC`;
  let body = {};

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: NOTIFICATION_DETAILS_LIST,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** Mark all Read Notification ****/
export function markallread(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/markallread`;
  let body = {};

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/*** Mark as read with notification ID ***/
export function markRead(session, notificationId, callback) {
  var url = `${INSTANCE_URL}/api/v2/notificationscript/patch`;
  let body = {
    notificationid: notificationId,
  };

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/*** Set Notification SIngle Object ***/
export function NotificationSingleObject(data) {
  return { type: SINGLE_NOTIFICATION_OBJECT, payload: data };
}

/*** Set Notification Details By Notification ID ***/
export function NotificationDeatils(session, notificationId, callback) {
  // return { type: NOTIFICATION_DETAILS, payload: data };
  var url = `${INSTANCE_URL}/api/v2/notificationscript/notificationdetailsbyid`;
  let body = {
    notificationid: notificationId,
  };

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: NOTIFICATION_DETAILS,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/*** Make Notification Details Empty [After Any modal close] */
export function makeNotificationDetailsEmpty() {
  return { type: NOTIFICATION_DETAILS, payload: {} };
}

// MESSAGES

/**** FETCH MESSAGING LIST ****/
export function fetchMessageList(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/messagethreads`;
  let body = {};

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: MESSAGING_DETAILS_LIST,
          payload: { data: response.threads },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* FETCH ORDER MESSAGE */
export function fetchOrderMessageList(session, callback) {
  //const url = `${INSTANCE_URL}/api/v2/shopez/_table/order_thread?related=*&fields=*&filter=firstuserid=${session.userid}&order=id%20DESC`;
  const url = `${INSTANCE_URL}/api/v2/vendor-datascript/orderthreads`;
  let body = {};
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ORDER_MESSAGING_DETAILS_LIST,
          payload: { data: response.threads },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/***** MESSAGING Slide-overs open or not *****/
export function isSendMessageModalOpen(isOpen) {
  return { type: IS_MESSAGING_SLIDEOVERS_OPEN, payload: isOpen };
}

/**** Get Messages With thread id ****/
export function fetchThreadMessagesList(session, threadid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/threadmessages`;
  let body = { threadid: threadid };

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: THREAD_MESSAGE,
          payload: { data: response.messages },
        });

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/*** Made thread list blank when component unmount or goes out of modal***/
export function makeThreadMessagesBlank(callback) {
  // Actual Data Call
  return (dispatch) => {
    dispatch({
      type: THREAD_MESSAGE,
      payload: { data: {} },
    });
    callback({ status: 1, message: LocaleStrings.api_success_status });
  };
}

/**** Send Message With Thread ID ****/

export function sendMessageStore(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/messages`;
  var body = { resource: values };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

/**** Send Order message with Thread ID */
export function sendOrderMessageToStore(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/order_messages`;
  var body = { resource: values };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

/**** Get Order Messages With thread id ****/
export function fetchOrderThreadMsgList(session, threadid, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/order_messages?related=*&fields=*&filter=threadid=${threadid}`;

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ORDER_THREAD_MESSAGES,
          payload: { data: response?.resource },
        });

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/****  Get single thread data to into basic thread details ****/
export function getOrderThreadWithId(session, threadid, callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/orderthreadmessages`;
  let body = {
    threadid: threadid,
  };

  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: response });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}
