import React, { Component } from "react";
import { connect } from "react-redux";
import { Fragment } from "react";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import LocaleStrings from "@language";
import { Dialog, Menu, Transition } from "@headlessui/react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { BASE_IMAGES_URL, APP_API_KEY } from "@constant";
import { Switch, Route } from "react-router-dom";

// Import Action
import { localeLanguage, logout, fetchsessionData } from "@mainActions";
import {
  showNotificatonCount,
  isNotificationModalOpen,
  isSendMessageModalOpen,
  onSidebarMenuSelected,
} from "@sidebarActions";

// Common Functions
import { isEmpty, classNames } from "@commonFunction";

// Import pages
import SalesManagement from "@salesmanagement";
import SalesManagementSalesNew from "@salesmanagementSalesTab/salesnew";
import SalesManagementSalesDelivered from "@salesmanagementSalesTab/salesdelivered";
import SalesManagementSalesCancRetuRepl from "@salesmanagementSalesTab/salescancreturepl";
import SalesManagementSalesNegotiation from "@salesmanagementSalesTab/salesnegotiation";

import ProfileSettings from "@profileSettings";
import Language from "@languageComponent";
import Currency from "@currency";

import ProductDetails from "@productdetails";
import ProductManagement from "@productmanagement";

import RevenueReportsRevenue from "@revenuereportsrevenue";
import RevenueReportsSales from "@revenuereportssales";
import RevenueReportTransaction from "@revenuereportstransaction";
import RevenueReports from "@revenuereports";
import CouponsManagement from "@couponsmanagement";
import DiscountsManagement from "@discountsmanagement";
import RatingsReviews from "@ratingsreviews";
import FAQNSupport from "@faqsupport";

import LegalDocs from "@legaldocs";
import LegalDocsTermsNCons from "@legaldocstermsncons";
import LegalDocsPrivacyPolicy from "@legaldocsprivacypolicy";
import LegalDocsSlaesPolicy from "@legaldocssalespolicy";
import LegalDocsAboutUS from "@legaldocsaboutus";
import PayFail from "../../productmanagement/components/productdetails/payfail";
import AppSuggestions from "@appsuggestions";

import Notification from "./notification";
import Message from "./message";

import toast, { Toaster } from "react-hot-toast";

import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { selectedProfileSettingsMenu } from "@profileSettingsActions";
import moment from "moment";

// Import style/css
import "../sidebar.css";

const DONTSHOWFORPERSONAL = [
  "salesmanagement",
  "revenuereports",
  "couponsmanagement",
  "discountsmanagement",
];

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      selectMenuNum: 1,
      textDiretion: this.props.language === "ar" ? "rtl" : "",
      selectedThreadId: "",
      params: {},
    };

    // All Binded Functions
    this.setSidebarOpen = this.setSidebarOpen.bind(this);
    this.onLogoutClicked = this.onLogoutClicked.bind(this);
  }

  // Component Did mount
  componentDidMount() {
    let { session } = this.props;

    var url_string = window.location.href;
    var query = url_string.split("?")[1];

    var params = {};

    if (query) {
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      this.setState({ params: params });
    }
    let sessionData = {
      sessionToken: params.session_token,
      apiKey: APP_API_KEY,
    };
    // debugger;
    // console.log("pa", params);

    if (!params.session_token) {
      if (this.props.language === "en") {
        this.props.localeLanguage("en", session);
      } else {
        this.props.localeLanguage("ar", session);
      }
    } else {
      // this.props.fetchsessionData(params, (response) => {
      if (params.language === "en") {
        this.props.localeLanguage("en", sessionData);
      } else {
        this.props.localeLanguage("ar", sessionData);
      }
      // this.props.history.push("/dashboard/productmanagement");
      if (params.type == "edit") {
        this.props.history.push(
          "/dashboard/productmanagement?session_token=" +
            params.session_token +
            "&language=" +
            params.language +
            "&type=edit&productid=" +
            params.productid
        );
        // this.props.history.push("/dashboard/productmanagement");
      } else {
        this.props.history.push(
          "/dashboard/productmanagement?session_token=" +
            params.session_token +
            "&language=" +
            params.language +
            "&type=add"
        );
        // this.props.history.push("/dashboard/productmanagement");
      }
      // });
    }

    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation, { href: answer });
    this.props.onSidebarMenuSelected(singleNavigationRow.position);

    if (this.props.isLoggedIn === true) {
      // this.props.history.push('/dashboard');
    } else {
      if (!params.session_token) {
        this.props.history.push("/login");
      }
    }

    /*** CALL NOTIFICATION COUNT (Bell Icon Count) ***/
    this.interVal = setInterval(() => {
      this.props.showNotificatonCount(session, (callBack) => {});
    }, 15000);
  }

  componentWillUnmount() {
    clearInterval(this.interVal);
  }

  componentDidUpdate(prevState) {
    if (prevState.language !== this.props.language) {
      if (this.props.language === "en") {
        this.setState({ textDiretion: "" });
      } else {
        this.setState({ textDiretion: "rtl" });
      }
    }
  }

  // Menu Select (Route change)
  onMenuSelect = (item) => {
    this.props.onSidebarMenuSelected(item.position);
    this.props.history.push("/dashboard/" + item.href);
    this.setSidebarOpen();
  };

  // Logout Button Click
  onLogoutClicked() {
    confirmAlert({
      title: LocaleStrings.button_logout,
      message: `${LocaleStrings.logout_want_to_logout}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            clearInterval(this.interVal);

            this.props.logout(this.props.session);
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  setSidebarOpen() {
    this.setState({ sidebarOpen: false });
  }

  /**** OPEN MODAL FOR NOTIFICATION ****/
  _handleNotificationModalOpen(e) {
    this.props.isNotificationModalOpen(true);
  }

  /****  Open Modal For MESSAGE ****/
  _handleMessageModalOpen(e) {
    this.props.isSendMessageModalOpen(true);
  }

  /**** Redirect to profile Information ****/
  _handelMyprofileRedirect(e) {
    // console.log('session',this.props.user);
    if (this.props.user.isfreeze == false) {
      this.props.history.push("/dashboard/profilesettings");
      // Zero for "Profile Information"
      this.props.selectedProfileSettingsMenu(this.props.session, 0);
    }
  }

  /***** Sidebar header logo component *****/
  sidebarHeaderLogo = () => {
    let { language } = this.props;
    return (
      <div className="cc-sidebar-header">
        <img
          loading="lazy"
          src={
            language == "en"
              ? "/images2/logo/logo_en.png"
              : "/images2/logo/logo_ar.png"
          }
          className="cc-sidebar-logo"
        />
      </div>
    );
  };

  render() {
    var { textDiretion } = this.state;
    var {
      session,
      language,
      notificationCount,
      messageCount,
      user,
      navigation,
    } = this.props;
    let { usertype } = session;

    let newNavigation; // Array
    if (user.isfreeze == 1 || user.isfreeze == "1" || user.isfreeze == true) {
      newNavigation = _.filter(navigation, function (o) {
        return o.position === 2;
      });
    } else {
      newNavigation = navigation;
    }
    var userImageSrc = `${BASE_IMAGES_URL}/users/${
      session.userid
    }.jpg?api_key=${APP_API_KEY}&t=${moment()}`;

    return (
      <div
        className={
          textDiretion + " h-screen flex overflow-hidden bg-quaternary"
        }>
        <Transition.Root show={this.state.sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 flex z-40 md:hidden"
            open={this.state.sidebarOpen}
            onClose={this.setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-secondary bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-custWhite">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => this.setState({ sidebarOpen: false })}>
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Shopez sidebarheader [Component] */}
                {this.sidebarHeaderLogo()}
                <div className="flex-shrink-0 flex items-center px-4 ">
                  {/* Login user / Name /email /Image */}
                  <span
                    onClick={(e) => this._handelMyprofileRedirect()}
                    className="flex-shrink-0 group block px-2 cursor-pointer">
                    <div className="flex items-center">
                      <div>
                        <img
                          loading="lazy"
                          id="profile-image-3"
                          className="inline-block user-img-rounded"
                          src={userImageSrc}
                          alt="Image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images2/common/user.png";
                          }}
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-secondary ">
                          {this.props.user.username}
                        </p>
                        <p className="text-sm font-medium text-ternary ">
                          {this.props.user.email}
                        </p>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  {/* SMALL SCREEN */}
                  <nav className="px-2 space-y-1">
                    {newNavigation.map((item) =>
                      _.indexOf(DONTSHOWFORPERSONAL, item.href) > -1 &&
                      usertype === "personal" ? (
                        ""
                      ) : (
                        <span
                          key={item.name}
                          onClick={(e) => this.onMenuSelect(item)}
                          className={classNames(
                            this.props.sidebarSelectedMenu === item.position
                              ? "bg-ternary text-secondary"
                              : "text-ternary hover:bg-quaternary hover:text-secondary",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer "
                          )}>
                          <img
                            loading="lazy"
                            className="sidebar-icons"
                            src={"/images2/menu/" + item.icon + ".svg"}
                          />
                          {item.name}
                        </span>
                      )
                    )}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow border-r border-trenary  pb-4 bg-custWhite overflow-y-auto">
              {/* Shopez sidebarheader [Component] */}
              {this.sidebarHeaderLogo()}
              {/* Login user / Name /email /Image */}
              <span
                onClick={(e) => this._handelMyprofileRedirect()}
                className="flex-shrink-0 group block px-2 cursor-pointer">
                <div className="flex items-center">
                  <div>
                    <img
                      loading="lazy"
                      id="profile-image-1"
                      className="inline-block user-img-rounded"
                      src={userImageSrc}
                      alt="Image"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/images2/common/user.png";
                      }}
                    />
                  </div>
                  <div className="mx-3">
                    <p className="text-base font-medium text-secondary ">
                      {this.props.user.username}
                    </p>
                  </div>
                </div>
                <div className="mt-2 ml-1">
                  <p className="text-xs font-medium text-ternary ">
                    {this.props.user.email}
                  </p>
                </div>
              </span>

              <div className="mt-5 flex-grow flex flex-col">
                <nav className="flex-1 px-2 bg-custWhite space-y-1">
                  {newNavigation.map((item) =>
                    _.indexOf(DONTSHOWFORPERSONAL, item.href) > -1 &&
                    usertype === "personal" ? (
                      ""
                    ) : (
                      <span
                        key={item.name}
                        onClick={(e) => this.onMenuSelect(item)}
                        className={classNames(
                          this.props.sidebarSelectedMenu === item.position
                            ? "bg-quaternary text-secondary"
                            : "text-ternary hover:bg-quaternary hover:text-secondary",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer"
                        )}>
                        <img
                          loading="lazy"
                          className="sidebar-icons"
                          src={"/images2/menu/" + item.icon + ".svg"}
                        />
                        {item.name}
                      </span>
                    )
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {this.state.params && !this.state.params.session_token ? (
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-custWhite shadow">
              <button
                className="px-4 border-r border-trenary text-ternary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
                onClick={() => this.setState({ sidebarOpen: true })}>
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 px-4 flex justify-between">
                <div className="flex-1 flex"></div>
                <div className="flex items-center">
                  {this.props.user.isfreeze == false ? (
                    <>
                      <button
                        className="relative bg-custWhite p-1 rounded-full text-ternary hover:text-secondary mx-2"
                        onClick={(e) => this._handleMessageModalOpen(e)}>
                        <span className="sr-only">View messages</span>
                        <img
                          loading="lazy"
                          className={" h-5 w-5 icon-hover-transition"}
                          src={"/images2/navbar/message.svg"}
                        />
                        <div
                          className={classNames(
                            messageCount > 0 ? "" : "hidden",
                            "notificatino-icon-text absolute"
                          )}
                          aria-hidden="true">
                          {messageCount}
                        </div>
                      </button>
                      <button
                        className="relative bg-custWhite p-1 rounded-full text-ternary hover:text-secondary mx-2"
                        onClick={(e) => this._handleNotificationModalOpen(e)}>
                        <span className="sr-only">View notifications</span>{" "}
                        {/* Hidden */}
                        <img
                          loading="lazy"
                          className={" h-5 w-5 icon-hover-transition"}
                          src={"/images2/navbar/notification.svg"}
                        />
                        <div
                          className={classNames(
                            notificationCount > 0 ? "" : "hidden",
                            "notificatino-icon-text absolute"
                          )}
                          aria-hidden="true">
                          {notificationCount}
                        </div>
                      </button>
                    </>
                  ) : (
                    ""
                  )}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative mx-2">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="max-w-xs bg-custWhite flex items-center text-sm rounded-full">
                            <span className="sr-only">Open user menu</span>
                            <img
                              loading="lazy"
                              id="profile-image-2"
                              className="h-8 w-8 rounded-full"
                              src={userImageSrc}
                              alt="Image"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images2/common/user.png";
                              }}
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items
                            static
                            className={classNames(
                              language === "en"
                                ? "origin-top-right right-0"
                                : "origin-top-left left-0",
                              " absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-custWhite ring-1 ring-black ring-opacity-5 focus:outline-none"
                            )}>
                            <Menu.Item key="sign_out">
                              <a
                                onClick={this.onLogoutClicked}
                                className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                                {LocaleStrings.signout}
                              </a>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <main className="main-tag-class">
            <Switch>
              <Route
                component={SalesManagementSalesNew}
                history={this.props.history}
                path="/dashboard/salesmanagement/salesnew"
              />
              <Route
                component={SalesManagementSalesDelivered}
                history={this.props.history}
                path="/dashboard/salesmanagement/salesdelivered"
              />
              <Route
                component={SalesManagementSalesCancRetuRepl}
                history={this.props.history}
                path="/dashboard/salesmanagement/salescancreturepl"
              />
              <Route
                component={SalesManagementSalesNegotiation}
                history={this.props.history}
                path="/dashboard/salesmanagement/salesnegotiation"
              />
              <Route
                component={SalesManagement}
                history={this.props.history}
                path="/dashboard/salesmanagement"
              />
              <Route
                component={ProfileSettings}
                path="/dashboard/profilesettings"
              />
              <Route component={Language} path="/dashboard/language" />
              <Route component={Currency} path="/dashboard/currency" />
              <Route
                component={PayFail}
                history={this.props.history}
                path="/dashboard/productmanagement/product-details/payfail"
              />
              <Route
                component={ProductDetails}
                history={this.props.history}
                path="/dashboard/productmanagement/product-details/:id"
              />
              <Route
                component={ProductManagement}
                history={this.props.history}
                path="/dashboard/productmanagement"
              />

              <Route
                component={RevenueReportsRevenue}
                history={this.props.history}
                path="/dashboard/revenuereports/revenue"
              />
              <Route
                component={RevenueReportsSales}
                history={this.props.history}
                path="/dashboard/revenuereports/sales"
              />
              <Route
                component={RevenueReportTransaction}
                history={this.props.history}
                path="/dashboard/revenuereports/transaction"
              />
              <Route
                component={RevenueReports}
                history={this.props.history}
                path="/dashboard/revenuereports"
              />

              <Route
                component={CouponsManagement}
                path="/dashboard/couponsmanagement"
              />
              <Route
                component={DiscountsManagement}
                path="/dashboard/discountsmanagement"
              />
              <Route
                component={RatingsReviews}
                path="/dashboard/ratingsreviews"
              />
              <Route component={FAQNSupport} path="/dashboard/faqsupport" />

              <Route
                component={LegalDocsTermsNCons}
                history={this.props.history}
                path="/dashboard/legaldocs/termsncons"
              />
              <Route
                component={LegalDocsPrivacyPolicy}
                history={this.props.history}
                path="/dashboard/legaldocs/privacypolicy"
              />
              <Route
                component={LegalDocsSlaesPolicy}
                history={this.props.history}
                path="/dashboard/legaldocs/salespolicy"
              />
              <Route
                component={LegalDocsAboutUS}
                history={this.props.history}
                path="/dashboard/legaldocs/aboutus"
              />
              <Route
                component={LegalDocs}
                history={this.props.history}
                path="/dashboard/legaldocs"
              />

              <Route
                component={AppSuggestions}
                history={this.props.history}
                path="/dashboard/appsuggestions"
              />
            </Switch>
            <Notification history={this.props.history} />
            <Message selectedThreadId={this.state.selectedThreadId} />
            <Toaster />
          </main>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    navigation: state.sideBarMenus,
    notificationCount: !_.isEmpty(state.appcounters)
      ? !_.isEmpty(state.appcounters.data)
        ? state.appcounters.data.notifications_count
        : 0
      : 0,
    messageCount: !_.isEmpty(state.appcounters)
      ? !_.isEmpty(state.appcounters.data)
        ? state.appcounters.data.message_count
        : 0
      : 0,
  };
}

export default connect(mapStateToProps, {
  logout,
  onSidebarMenuSelected,
  localeLanguage,
  fetchsessionData,
  showNotificatonCount,
  isNotificationModalOpen,
  isSendMessageModalOpen,
  selectedProfileSettingsMenu,
})(SideBar);
