import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import toast, { Toaster } from "react-hot-toast";

import ForgotPassword from "./forgotpassword";
import LocaleStrings from "@language";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
//import { randomString } from "@commonFunction";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import TwitterLogin from "react-twitter-login";

//Import Component
import LoginRegisterCommonHeader from "./login-register-commonheader";

import {
  mainLogin,
  resetStore,
  isForgotPasswordOpen,
  validateSocialId,
  socialSignupAvailable,
} from "@mainActions";

import {
  FACEBOOKID,
  GOOGLEID,
  CONSUMER_KEY,
  CONSUMER_SECRET,
  SOCIALPWD,
  PERSONAL_USER_REDIRECT_TO,
  OTHERS_USER_REDIRECT_TO,
  FREEZE_USER_REDIRECT_TO,
} from "@constant";
import _ from "lodash";

const REDIRECT_URI = "http://localhost:3001/login";

class Login extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoading: false,
      showerror: false,
      show: false,
      fbloader: false,
      updated_window: false,
    };

    // All Bind Functions
    this.forGotPassword_click = this.forGotPassword_click.bind(this);
    this.changeShow = this.changeShow.bind(this);
  }
  componentDidMount() {}

  UNSAFE_componentWillMount() {
    let { session, user } = this.props;
    let { usertype } = session;
    if (this.props.isLoggedIn === true) {
      this.props.history.push(
        user.isfreeze == true || user.isfreeze == 1 || user.isfreeze == "1"
          ? FREEZE_USER_REDIRECT_TO
          : usertype === "personal"
          ? PERSONAL_USER_REDIRECT_TO
          : OTHERS_USER_REDIRECT_TO
      );
    } else {
      this.props.history.push("/login");
    }
  }

  changeShow() {
    let { show } = this.state;
    this.setState({ show: !show });
  }

  // Forgot Password Button Click
  forGotPassword_click() {
    this.props.isForgotPasswordOpen(true);
  }

  responseFacebook = (res) => {
    // debugger;
    if (res && res.userID) {
      if (res.email) {
        // if user email available
        let uniqueId = res.userID;
        let email = res.email;
        this.props.socialSignupAvailable(res.email, (response) => {
          if (response.success == 1) {
            this.socialLogin(email, uniqueId, "fb");
          } else {
            // let name = res.name;
            // let nameArr = name.split(" ");
            // var user = {};
            // user.email = email;
            // user.socialid = uniqueId;
            // user.display_name = name;
            // user.first_name = nameArr.length > 0 ? nameArr[0] : "";
            // user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
            // user.name = name;
            // this.socialLoginRegister(user, "fb");
            toast.error(LocaleStrings.create_a_account);
          }
        });
      } else {
        let uniqueId = res.userID;

        this.props.validateSocialId(uniqueId, (response) => {
          if (response.success === 1 && response.email) {
            this.socialLogin(response.email, uniqueId, "fb");
          } else {
            // let name = res.name;
            // let nameArr = name.split(" ");
            // let email = randomString(12) + "@shopez.social";
            // var user = {};
            // user.email = email;
            // user.socialid = uniqueId;
            // user.display_name = name;
            // user.first_name = nameArr.length > 0 ? nameArr[0] : "";
            // user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
            // user.name = name;
            // this.socialLoginRegister(user, "fb");
            toast.error(LocaleStrings.create_a_account);
          }
        });
      }
    }
  };

  socialLogin = (email, uniqueId, socialType) => {
    let { session, user } = this.props;
    let { usertype } = session;

    let values = {};
    values.email = email;
    values.uniqueid = uniqueId;
    values.type = socialType;
    values.password = SOCIALPWD;

    this.props.mainLogin(values, this.props.session, (response) => {
      if (response.success === 0) {
        toast.error(response.message.response.data.error.message);
      } else {
        toast.success(LocaleStrings.login_success);
        this.props.history.push(
          user.isfreeze
            ? FREEZE_USER_REDIRECT_TO
            : usertype === "personal"
            ? PERSONAL_USER_REDIRECT_TO
            : OTHERS_USER_REDIRECT_TO
        );
      }
    });
  };

  socialLoginRegister = (user, socialType) => {
    let { session } = this.props;
    let { usertype } = session;

    var params = {};
    params.email = user.email;
    params.logintype = socialType;
    params.display_name = user.display_name;
    params.first_name = user.first_name;
    params.last_name = user.last_name;
    params.name = user.name;
    params.new_password = SOCIALPWD;
    switch (socialType) {
      case "fb":
        params.facebookid = user.socialid;
        break;
      case "google":
        params.googleid = user.socialid;
        break;
      case "tw":
        params.twitterid = user.socialid;
        break;
    }

    this.props.addConsumer(params, (response) => {
      if (response.success == 0) {
        toast.error(LocaleStrings.common_fail_message);
      } else {
        toast.success(LocaleStrings.signup_success);
        //this.props.history.push("/activity");
        this.props.history.push(
          usertype === "personal"
            ? PERSONAL_USER_REDIRECT_TO
            : OTHERS_USER_REDIRECT_TO
        );
      }
    });
  };

  responseGoogle = (res) => {
    if (res && res.profileObj) {
      let uniqueId = res.profileObj.googleId;
      let email = res.profileObj.email;
      this.props.socialSignupAvailable(email, (response) => {
        if (response.success == 1) {
          this.socialLogin(email, uniqueId, "google");
        } else {
          // let name = res.profileObj.name;
          // let nameArr = name.split(" ");
          // var user = {};
          // user.email = email;
          // user.socialid = uniqueId;
          // user.display_name = name;
          // user.first_name = nameArr.length > 0 ? nameArr[0] : "";
          // user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
          // user.name = name;
          // this.socialLoginRegister(user, "google");
          toast.error(LocaleStrings.create_a_account);
        }
      });
    } else {
      //toast.error(LocaleStrings.common_fail_message);
    }
  };

  twitterHandler = (err, data) => {
    //debugger;
    if (data && data.user_id) {
      let uniqueId = data.user_id;
      let name = data.screen_name;
      if (data.email) {
        let email = data.email;
        this.props.socialSignupAvailable(email, (response) => {
          if (response.success == 1) {
            this.socialLogin(email, uniqueId, "tw");
          } else {
            // let nameArr = name.split(" ");
            // var user = {};
            // user.email = email;
            // user.socialid = uniqueId;
            // user.display_name = name;
            // user.first_name = nameArr.length > 0 ? nameArr[0] : "";
            // user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
            // user.name = name;
            // this.socialLoginRegister(user, "tw");
            toast.error(LocaleStrings.create_a_account);
          }
        });
      } else {
        this.props.validateSocialId(uniqueId, (response) => {
          if (response.success == 1 && response.email) {
            this.socialLogin(response.email, uniqueId, "tw");
          } else {
            // let nameArr = name.split(" ");
            // let email = randomString(12) + "@shopez.social";
            // var user = {};
            // user.email = email;
            // user.socialid = uniqueId;
            // user.display_name = name;
            // user.first_name = nameArr.length > 0 ? nameArr[0] : "";
            // user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
            // user.name = name;
            // this.socialLoginRegister(user, "tw");
            toast.error(LocaleStrings.create_a_account);
          }
        });
      }
    } else {
      //toast.error(LocaleStrings.common_fail_message);
    }
  };

  onFormSubmit(values) {
    let { session, user } = this.props;
    let { usertype } = session;
    this.setState({ loading: true });

    this.props.mainLogin(values, this.props.session, (response) => {
      if (response.success === 0) {
        toast.error(LocaleStrings.invalid_email_phone_password);

        this.props.resetStore();
      } else {
        this.props.history.push(
          response.data.isfreeze
            ? FREEZE_USER_REDIRECT_TO
            : response.data.usertype === "personal"
            ? PERSONAL_USER_REDIRECT_TO
            : OTHERS_USER_REDIRECT_TO
        );
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.showModal) {
    //   this.setState({ ...this.state, loading: false });
    // }
  }

  render() {
    var { handleSubmit } = this.props;

    return (
      <>
        <LoginRegisterCommonHeader history={this.props.history} />
        <div className="">
          <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form
                  className="space-y-6"
                  onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                  <div className="mb-12">
                    <div className="text-secondary font-normal text-3xl">
                      {LocaleStrings.login}
                    </div>
                    <div className="text-hint font-normal text-ternary">
                      {LocaleStrings.login_sub_haeder_text}
                    </div>
                  </div>
                  <Field
                    name="email"
                    label={LocaleStrings.email_phone}
                    placeholder={LocaleStrings.email_phone}
                    type="text"
                    component={this.renderFieldText}
                    mandatory="true"
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-sm font-medium text-secondary"
                  />
                  <Field
                    name="password"
                    label={LocaleStrings.password}
                    placeholder={LocaleStrings.password}
                    type="password"
                    component={this.renderFieldText}
                    mandatory="true"
                    show={this.state.show}
                    setShow={this.changeShow}
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-sm font-medium text-secondary"
                    className="cust-pr-20"
                  />
                  <div className="flex items-center justify-between">
                    {/* FORGOT PASSWORD BUTTON */}
                    <div className="text-sm">
                      <a
                        href="#"
                        onClick={this.forGotPassword_click}
                        className="font-medium text-primary hover:text-primaryHover">
                        {LocaleStrings.forgot_your_password}
                      </a>
                    </div>
                  </div>
                  <div>
                    <button type="submit" className="btn-primary">
                      {LocaleStrings.signin}
                    </button>
                  </div>
                </form>
                {/* SOCIAL LOGIN */}
                <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white text-secondary">
                        {LocaleStrings.or}
                      </span>
                    </div>
                  </div>
                  <div className="pt-3.5 text-center text-secondary font-normal text-base">
                    {LocaleStrings.connect_social_media_text}
                  </div>

                  {/* SOCIAL LOGIN   */}
                  <div className="mt-6 grid grid-cols-1 gap-3">
                    {/* <div className="flex justify-center cursor-pointer">
                      <TwitterLogin
                        authCallback={this.twitterHandler}
                        consumerKey={CONSUMER_KEY}
                        consumerSecret={CONSUMER_SECRET}
                        children={
                          <img
                          loading="lazy"
                            src={"/images2/common/twitter.png"}
                            className="h-12 w-18"
                          />
                        }
                      />
                    </div> */}
                    <div className="flex justify-center cursor-pointer">
                      <GoogleLogin
                        clientId={GOOGLEID}
                        buttonText={""}
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                        render={(renderProps) => (
                          <img
                            loading="lazy"
                            src={"/images2/common/google.png"}
                            className="h-12 w-18"
                            onClick={renderProps.onClick}
                          />
                        )}
                      />
                    </div>
                    <div className="flex justify-center cursor-pointer">
                      {/*
                          --- Don't remove the code for now we may need it later ---
                        
                        <FacebookLogin
                        appId={FACEBOOKID}
                        //autoLoad={true}
                        size="large"
                        fields="name,email"
                        callback={this.responseFacebook}
                        render={(renderProps) => (
                          <img
                          loading="lazy"
                            src={"/images2/common/facebook.png"}
                            className="h-12 w-18"
                            onClick={renderProps.onClick}
                            loading={this.state.fbloader}
                          />
                        )}
                        textButton={LocaleStrings.signup_facebook_option}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Forgot Password Modal */}
        <ForgotPassword
          data={this.props.isforgotfasswordopen}
          hedaer={LocaleStrings.forgot_password}
        />
        <Toaster />
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  if (!values.password) {
    errors.password = LocaleStrings.login_form_validation_password_required;
  }
  return errors;
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  showModal: state.openLoginOTPModal,
  isforgotfasswordopen: state.isForgotPasswordOpen,
});

export default reduxForm({
  validate,
  form: "LoginForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    mainLogin,
    resetStore,
    isForgotPasswordOpen,
    validateSocialId,
    socialSignupAvailable,
  })(Login)
);
