import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { Cropper } from "react-advanced-cropper";
import _ from "lodash";
import cloneDeep from "lodash.clonedeep";
import LocaleStrings from "@language";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import VideoThumbnail from "react-video-thumbnail";
import imageCompression from "browser-image-compression";
import ProductCropper from "./productcropper";
import { getRandomNum, getExtensionNameFromFile } from "@commonFunction";

// Constants
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  MAX_WIDTH_OR_HEIGHT_VIDEO,
  MAX_WIDTH_OR_HEIGHT_IMAGE,
  MAX_VIDEO_DURATION_IN_SEC,
  MAX_IMAGE_SIZE_IN_BYTES,
} from "@constant";

var rn = require("random-number");
var rnOptions = {
  min: 100000000000000000,
  max: 999999999999999999,
  integer: true,
};

class Drop extends Component {
  constructor() {
    super();

    this.state = {
      files: [],
      cropperimage: "",
      cropperparentindex: "",
      cropperindex: "",
      isOpen: false,
      imageLoader: false,
    };

    this.removeFile = this.removeFile.bind(this);
  }

  //return a promise that resolves with a File instance
  urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  // Add Video ThumbNail File to array
  getVideoThumbBase64 = (base64thumb, parentIndex, index) => {
    const reader = new FileReader(); // Define reader
    let { updateStateArr } = this.props;

    let variationDetailsArr = this.props.mainarr;
    let dynamicThumnailName =
      variationDetailsArr[parentIndex].variations_media_raw[index].file_name;
    let nameWithoutExtension = dynamicThumnailName.substring(
      0,
      dynamicThumnailName.indexOf(".")
    );

    //variationDetailsArr[parentIndex].variations_media_raw[index].file_thumbnail_name = nameWithoutExtension+'_thumb.jpg';

    this.urltoFile(
      base64thumb,
      nameWithoutExtension + ".jpg",
      "image/jpg"
    ).then(async function (file) {
      reader.readAsArrayBuffer(file); // Read the file raw_thumbnail

      reader.onload = (e) => {
        // Insert URL after read
        file.url = e.target.result;
      };
      // OPTION FOR THUMBNAIL
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT_VIDEO,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);

        reader.readAsArrayBuffer(compressedFile); // Read the file raw_thumbnail

        reader.onload = (e) => {
          // Insert URL after read
          compressedFile.url = e.target.result;
        };

        variationDetailsArr[parentIndex].variations_media_raw[
          index
        ].raw_thumbnail = compressedFile;

        updateStateArr(variationDetailsArr);
        //await uploadToServer(compressedFile); // write your own logic
      } catch (error) {}
    });
  };

  /* ON drop validate videos aND IMAGES */
  validateUploadedFile(fileObject) {
    return new Promise((resolve) => {
      let fileTypeArry = _.split(fileObject.type, "/"); // type: "video/mp4" => ['video',mp4]

      if (fileTypeArry[0] === "video") {
        var video = document.createElement("video"); // Create a video element
        video.preload = "metadata";
        video.src = URL.createObjectURL(fileObject); // Assign url

        video.onloadedmetadata = async function () {
          if (video.duration > MAX_VIDEO_DURATION_IN_SEC) {
            toast.error(
              _.replace(
                LocaleStrings.variation_details_max_duration_error_text,
                "#",
                video.duration
              )
            );

            resolve("rejected");
          } else {
            resolve("resolved");
          }
        };
      } else {
        if (fileObject.size > MAX_IMAGE_SIZE_IN_BYTES) {
          // MAX limit 2 mb [Image]
          resolve("rejected");
          toast.error(
            _.replace(
              LocaleStrings.variation_details_max_image_size_limit_in_bytes_text,
              "#",
              MAX_IMAGE_SIZE_IN_BYTES
            )
          );
        }

        resolve("resolved");
      }
    });
  }

  // Add File
  onDrop = async (files, parentIndex, index, callfrom) => {
    // let variationDetailsArr = this.props.mainarr;
    // let variationDetailsArr = JSON.parse(JSON.stringify(this.props.mainarr));//commented for error 7/6/23
    let variationDetailsArr = this.props.mainarr;
    this.setState({ imageLoader: true });

    const reader = new FileReader(); // Define reader

    // let fileData = files[0]; // TAKE FILE

    let fileData = "";
    if (callfrom == "cropper") {
      fileData = files; // TAKE FILE
    } else {
      fileData = files[0]; // TAKE FILE
    }

    if ((await this.validateUploadedFile(fileData)) === "resolved") {
      reader.readAsArrayBuffer(fileData); // Read the file raw_thumbnail

      reader.onload = (e) => {
        // Insert URL after read
        fileData.url = e.target.result;
      };

      let randNum = rn(rnOptions); // Random String Generator

      //let variationDetailsArr = this.props.mainarr;

      let dynamicFileName =
        randNum + "." + getExtensionNameFromFile(fileData.name); // Name Of the Image to store on DB

      variationDetailsArr[parentIndex].variations_media_raw[index].type =
        fileData.type;
      variationDetailsArr[parentIndex].variations_media_raw[index].file_type =
        fileData.type.substring(0, 5);
      variationDetailsArr[parentIndex].variations_media_raw[index].file_name =
        dynamicFileName;
      variationDetailsArr[parentIndex].variations_media_raw[index].file_path =
        URL.createObjectURL(fileData);
      variationDetailsArr[parentIndex].variations_media_raw[index].raw =
        fileData;

      // Image Thumbnail Creates here
      if (fileData.type.substring(0, 5) == "image") {
        // OPTION FOR THUMBNAIL
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT_IMAGE,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(fileData, options);

          reader.readAsArrayBuffer(compressedFile); // Read the file raw_thumbnail

          reader.onload = (e) => {
            // Insert URL after read
            compressedFile.url = e.target.result;

            let dynamicImgThumbName =
              randNum +
              "_thumb." +
              getExtensionNameFromFile(compressedFile.name);

            variationDetailsArr[parentIndex].variations_media_raw[
              index
            ].file_thumbnail_name = dynamicImgThumbName;
            variationDetailsArr[parentIndex].variations_media_raw[
              index
            ].raw_thumbnail = compressedFile;

            console.log("compressedFile", compressedFile);
            console.log("variationDetailsArr", variationDetailsArr);

            this.props.updateStateArr(variationDetailsArr);

            // PUT COMPRESSED FILES DETAILS TO ARRAY

            //await uploadToServer(compressedFile); // write your own logic

            this.setState({ imageLoader: false });
          };
        } catch (error) {
          this.setState({ imageLoader: false });
        }
      } else {
        variationDetailsArr[parentIndex].variations_media_raw[
          index
        ].file_thumbnail_name = randNum + "_thumb.jpg";

        this.props.updateStateArr(variationDetailsArr);

        this.setState({ imageLoader: false });
      }
    } else {
      return false;
    }
  };

  // remove FIle
  removeFile = (files, parentIndex, index) => {
    // console.log(parentIndex);
    let { isEdit } = this.props;
    // take delete Confirmation from user
    confirmAlert({
      title: LocaleStrings.media_delete_confirmation_header_text,
      message: `${LocaleStrings.media_delete_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            // let variationDetailsArr = this.props.mainarr;

            // let variationDetailsArr = cloneDeep(this.props.mainarr);
            let variationDetailsArr = JSON.parse(
              JSON.stringify(this.props.mainarr)
            );

            variationDetailsArr[parentIndex].variations_media_raw[
              index
            ].file_type = "";
            variationDetailsArr[parentIndex].variations_media_raw[
              index
            ].file_name = "";
            variationDetailsArr[parentIndex].variations_media_raw[
              index
            ].file_thumbnail_name = "";
            variationDetailsArr[parentIndex].variations_media_raw[
              index
            ].file_path = "";
            variationDetailsArr[parentIndex].variations_media_raw[index].raw =
              "";
            variationDetailsArr[parentIndex].variations_media_raw[
              index
            ].raw_thumbnail = "";
            variationDetailsArr[parentIndex].variations_media_raw[index].type =
              "";
            if (isEdit) {
              // DELETE as isdeleted - > 0 to isdeleted -> 1 [When Image showing from DB]
              if (
                !_.isEmpty(
                  variationDetailsArr[parentIndex].variations_media[index]
                )
              ) {
                variationDetailsArr[parentIndex].variations_media[
                  index
                ].isdeleted = 1;
              }
            }

            this.props.updateStateArr(variationDetailsArr, "delete");
            toast.success(LocaleStrings.media_delete_sucess);
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  openCropper = (e, parentindex, i, url) => {
    this.setState({ cropperimage: url });
    this.setState({ parentindex: parentindex });
    this.setState({ cropperindex: i });
    this.setState({ isOpen: true });
  };

  closeCropper = (e) => {
    this.setState({ cropperimage: "" });
    this.setState({ parentindex: "" });
    this.setState({ cropperindex: "" });
    this.setState({ isOpen: false });
  };

  componentDidMount() {
    this.props.updateStateArr(this.props.mainarr);
  }

  updateImage = (parentindex, index, img, file) => {
    // console.log("parentindex", parentindex);
    // console.log("index", index);
    // console.log("imimgage", img);
    // console.log("file", file);
    this.onDrop(file, parentindex, index, "cropper");
    // this.props.autofill(`data[${index}].mobileimage`, img == "" ? "" : img);
  };

  render() {
    let { isEdit } = this.props;
    const files = this.state.files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    let { mainarr, parentIndex } = this.props;
    let { cropperindex, parentindex, cropperimage } = this.state;
    // console.log("mainarr", mainarr);

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {_.times(mainarr[parentIndex]?.variations_media_raw?.length, (i) => {
          return (
            <div className="dropzonenew" key={`drop` + parentIndex + i}>
              {
                mainarr[parentIndex].variations_media_raw[i].file_type ==
                "image" ? (
                  <div className="img_wrp-relative h-full pb-2">
                    <img
                      loading="lazy"
                      src={
                        mainarr[parentIndex].variations_media_raw[i].file_path
                      }
                      className="dropimg"
                    />

                    <img
                      loading="lazy"
                      onClick={(e) =>
                        this.removeFile(e, this.props.parentIndex, i)
                      }
                      className={
                        "sidebar-icons cursor-pointer absolute top-2 right-2"
                      }
                      src={"/images2/common/delete.svg"}
                    />
                    <img
                      loading="lazy"
                      onClick={(e) =>
                        this.openCropper(
                          e,
                          this.props.parentIndex,
                          i,
                          mainarr[parentIndex].variations_media_raw[i].file_path
                        )
                      }
                      className={
                        "sidebar-icons cursor-pointer absolute top-2 right-12"
                      }
                      src={"/images2/edit.png"}
                    />
                  </div>
                ) : mainarr[parentIndex].variations_media_raw[i].file_type ==
                  "video" ? (
                  <div className="img_wrp-relative h-full">
                    <video
                      controls
                      controlsList="nodownload"
                      className="dropimg">
                      <source
                        src={`${mainarr[parentIndex].variations_media_raw[i].file_path}#t=0.1`}
                        type="video/mp4"
                      />
                    </video>
                    <div className="hidden">
                      <VideoThumbnail
                        videoUrl={
                          mainarr[parentIndex].variations_media_raw[i].file_path
                        }
                        thumbnailHandler={(thumbnail) =>
                          this.getVideoThumbBase64(
                            thumbnail,
                            this.props.parentIndex,
                            i
                          )
                        }
                      />
                    </div>
                    <img
                      loading="lazy"
                      onClick={(e) =>
                        this.removeFile(e, this.props.parentIndex, i)
                      }
                      className={
                        "sidebar-icons cursor-pointer absolute top-2 right-2"
                      }
                      src={"/images2/common/delete.svg"}
                    />
                  </div>
                ) : // Time Of Edit
                isEdit ? (
                  !_.isEmpty(mainarr[parentIndex].variations_media[i]) &&
                  mainarr[parentIndex].variations_media[i]?.isdeleted ===
                    "0" ? (
                    mainarr[parentIndex].variations_media[i].type ===
                    "image" ? (
                      <>
                        <div className="img_wrp-relative h-full">
                          <img
                            loading="lazy"
                            src={`${BASE_IMAGES_URL}/vendor/variations/${mainarr[parentIndex].variations_media[i].thumbfilename}?api_key=${APP_API_KEY}`}
                            className="dropimg"
                          />
                          {/* latest code (not allowing to delete old uploaded image) */}
                          <img
                            loading="lazy"
                            onClick={(e) =>
                              this.removeFile(e, this.props.parentIndex, i)
                            }
                            className={
                              "sidebar-icons cursor-pointer absolute top-2 right-2"
                            }
                            src={"/images2/common/delete.svg"}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {mainarr[parentIndex].variations_media[i].id ? (
                          <div className="img_wrp-relative h-full">
                            <img
                              src={`${BASE_IMAGES_URL}/vendor/variations/${mainarr[parentIndex].variations_media[i].thumbfilename}?api_key=${APP_API_KEY}`}
                              className="dropimg "
                            />
                            {/* { latest code (not allowing to delete old uploaded video) } */}
                            <img
                              loading="lazy"
                              onClick={(e) =>
                                this.removeFile(e, this.props.parentIndex, i)
                              }
                              className={
                                "sidebar-icons cursor-pointer absolute top-2 right-2"
                              }
                              src={"/images2/common/delete.svg"}
                            />
                            <img
                              loading="lazy"
                              src={"/images2/video-gallery2.png"}
                              className="absolute variation-video-play-icon"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {/*
                          For now we will not play video from vendor side
                           <div className="img_wrp-relative h-full">
                          {mainarr[parentIndex].variations_media[i].id}
                          <video
                            controls
                            controlsList="nodownload"
                            className="dropimg"
                          >
                            <source
                              //src={`${thumbfilename}#t=0.1`}
                              type="video/mp4"
                            />
                          </video>
                          <img
                          loading="lazy"
                            onClick={(e) =>
                              this.removeFile(e, this.props.parentIndex, i)
                            }
                            className={
                              "sidebar-icons cursor-pointer absolute top-2 right-2"
                            }
                            src={
                              "/images2/common/delete.svg"
                            }
                          />
                        </div> */}
                      </>
                    )
                  ) : (
                    <>
                      <Dropzone
                        onDrop={(e) =>
                          this.onDrop(e, this.props.parentIndex, i)
                        }
                        accept="image/jpeg, image/png ,image.jpg ,video/mp4"
                        multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="h-full">
                            <input {...getInputProps()} />
                            {this.state.imageLoader ? (
                              <i className="fa fa-refresh fa-spin"></i>
                            ) : (
                              <p className="paragraph-seconday text-xs">
                                {LocaleStrings.drop_or_select}
                              </p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </>
                  )
                ) : (
                  <Dropzone
                    onDrop={(e) => this.onDrop(e, this.props.parentIndex, i)}
                    accept="image/jpeg, image/png ,image.jpg ,video/mp4"
                    multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="h-full">
                        <input {...getInputProps()} />
                        {this.state.imageLoader ? (
                          <i className="fa fa-refresh fa-spin"></i>
                        ) : (
                          <p className="paragraph-seconday text-xs">
                            {LocaleStrings.drop_or_select}
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>
                )
                //((mainarr[parentIndex].variations_media[i].type) ? '' : '')
              }
            </div>
          );
        })}
        <ProductCropper
          isOpen={this.state.isOpen}
          setisOpen={this.closeCropper}
          mainarr={mainarr}
          finalarr={[]}
          cropperindex={cropperindex}
          parentindex={parentindex}
          imagepath={cropperimage}
          imageType="jpg"
          displaySize={{ width: 400, height: 400 }} // For image display style
          requiredSize={{ width: 400, height: 400 }} // For image size required validation
          cropperSize={{ width: 400, height: 400 }} // Cropper display size. Note its add 50px for padding
          onImageSave={(img, file) =>
            this.updateImage(parentindex, cropperindex, img, file)
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    isEdit: !_.isEmpty(state.toBeEditproduct)
      ? !_.isEmpty(state.toBeEditproduct.response)
        ? true
        : false
      : false,
  };
}

export default connect(mapStateToProps, {})(Drop);
