import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getBilangualeText,
  ProductTextLocalized,
  classNames,
} from "@commonFunction";
import _ from "lodash";
import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";
import {
  currencyCode,
  currencyRate,
  currencyRate2,
  isCurrencyRateApplicable,
} from "@currencyActions";
import LocaleStrings from "@language";
class TransactionHistoryRows extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderProductList(productsObject) {
    let { language, localCurrency } = this.props;

    return _.map(productsObject, (productObj, index) => {
      var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${productObj.productimage}?api_key=${APP_API_KEY}`;
      var cardTitle = ProductTextLocalized(
        productObj.productname_en,
        productObj.productname_ar,
        language
      );

      return (
        <div className="flex items-center mt-2" key={cardTitle + "-" + index}>
          <div className="flex-shrink-0 h-10 w-10">
            <img
              loading="lazy"
              className="h-10 w-10 rounded-full"
              src={productImageUrl}
              alt=""
            />
          </div>
          <div className="ml-4">
            <div className="text-xs font-medium text-secondary mx-2">
              <div className="tooltip">
                <div className="text-sm font-medium text-secondary">
                  {cardTitle.length > 30
                    ? cardTitle.substring(1, 30) + "..."
                    : cardTitle}
                </div>
                <span className="tooltiptextleft">{cardTitle}</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    let { localCurrency, language } = this.props;
    let productObj = this.props.values;
    // console.log('productObj',productObj)
    //let currencyrate = currencyRate(localCurrency);

    /* Product currency */
    let productCurrency = productObj.currency;

    let currency = currencyCode(localCurrency);

    /*
    If user currency and Order Order currency dont match 
    return currencyrate other waise no need to convert currency value so return 1
    */
    // let covertedAmount = isCurrencyRateApplicable(
    //   localCurrency,
    //   productCurrency,
    //   productObj.amount
    // );
    let covertedAmount = productObj.amount;

    return (
      <tr
        id={`table-list-${productObj.id}`}
        key={`table-list-${productObj.id}`}
        className="cursor-pointer table-hover-transition">
        <td
          className={classNames(
            language === "ar" ? "text-right" : "text-left",
            "px-6 py-4 whitespace-nowrap  text-xs font-medium"
          )}>
          <div className="text-xs text-secondary">
            {productObj.transactionid}
          </div>
        </td>
        <td
          className={classNames(
            language === "ar" ? "text-right" : "text-left",
            "px-6 py-4 whitespace-nowrap  text-xs font-medium"
          )}>
          <div
            className={classNames(
              productObj.orderdetails[0]?.currentstatus !== "refunded" ||
                productObj.orderdetails[0]?.currentstatus !== "cancelrefunded"
                ? "text-secondary"
                : "text-red-400",
              "text-xs"
            )} //latest code
            // "text-xs text-secondary" //old code
          >
            {currency} {_.round(covertedAmount, 2)}
            {" (" + getBilangualeText(productObj.paymentmode) + ")"}
          </div>
        </td>
        <td
          className={classNames(
            language === "ar" ? "text-right" : "text-left",
            "px-6 py-4 whitespace-nowrap  text-xs font-medium"
          )}>
          {this.renderProductList(productObj.products)}
        </td>
        <td
          className={classNames(
            language === "ar" ? "text-right" : "text-left",
            "px-6 py-4 whitespace-nowrap  text-xs font-medium"
          )}>
          <span className=" capitalize inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-quaternary text-secondary">
            {productObj.type === "sold"
              ? LocaleStrings.sold
              : productObj.type === "purchased"
              ? LocaleStrings.purchased
              : productObj.type}
          </span>
        </td>
        <td
          className={classNames(
            language === "ar" ? "text-right" : "text-left",
            "px-6 py-4 whitespace-nowrap  text-xs font-medium"
          )}>
          <span className="capitalize inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-quaternary text-secondary">
            {productObj.paymentstatus === "approve"
              ? LocaleStrings.payment_approve
              : productObj.paymentstatus === "pending"
              ? LocaleStrings.payment_pending
              : productObj.paymentstatus === "failed"
              ? LocaleStrings.payment_failed
              : productObj.paymentstatus === "declined"
              ? LocaleStrings.payment_declined
              : productObj.paymentstatus === "pending_confirmation_bank"
              ? LocaleStrings.payment_pending_confirmation_bank
              : productObj.paymentstatus === "cancelled"
              ? LocaleStrings.payment_cancelled
              : "-"}
          </span>
        </td>
      </tr>
    );
  }
}

var mapStateToProps = (state) => ({
  language: state.language,
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  localCurrency: state.currency,
});

export default connect(mapStateToProps, {})(TransactionHistoryRows);
