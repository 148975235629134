import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import BaseLoader
import BaseLoader from "@baseLoader";
// Import Component
import ReportTypeList from "./reporttypelist.js";

class RevenueReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_revenue_reports,
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>
                <BaseLoader data={this.state.loaderDesc} />
              </>
            ) : (
              <div className="py-6">
                <ReportTypeList history={this.props.history} />
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(RevenueReports);
