import React, { Component } from "react";
import { connect } from "react-redux";
import { RadioGroup } from "@headlessui/react";

import LocaleStrings from "@language";

// Import BaseLoader
import BaseLoader from "@baseLoader";

// Import Actions
import { localeLanguage } from "@mainActions";
import { onSidebarMenuSelected } from "@sidebarActions";

const settings = [
  { name: "(en)", description: "English" },
  { name: "(ar)", description: "عربى" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Language extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: settings[0],
      loading: true,
      loaderDesc: LocaleStrings.preparing_language,
    };

    // All Binded Functions
  }

  componentDidMount() {
    if (this.props.language === "ar") {
      this.setState({ selected: settings[1] });
    }
    this.setState({ loading: false });
  }

  // On Radio Chnage
  onRadioChange = (e) => {
    let { session } = this.props;
    this.setState({ selected: e });

    if (e.name === "(en)") {
      this.props.localeLanguage("en", session);
    } else {
      this.props.localeLanguage("ar", session);
    }
  };

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  render() {
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>{this.renderLoader()}</>
            ) : (
              <div className="py-6 responsivePadding">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  <h1 className="text-2xl font-semibold text-secondary">
                    {LocaleStrings.change_language}
                  </h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  {/* Replace with your content */}
                  <div className="py-4">
                    <RadioGroup
                      value={this.state.selected}
                      onChange={(e) => this.onRadioChange(e)}>
                      <RadioGroup.Label className="sr-only">
                        {LocaleStrings.language_privacysettings}
                      </RadioGroup.Label>
                      <div className="bg-custWhite rounded-md -space-y-px">
                        {settings.map((setting, settingIdx) => (
                          <RadioGroup.Option
                            key={setting.name}
                            value={setting}
                            className={({ checked }) =>
                              classNames(
                                settingIdx === 0
                                  ? "rounded-tl-md rounded-tr-md"
                                  : "",
                                settingIdx === settings.length - 1
                                  ? "rounded-bl-md rounded-br-md"
                                  : "",
                                checked
                                  ? "bg-primaryLight border-primaryHover z-10"
                                  : "border-quaternary",
                                "relative border p-4 flex cursor-pointer focus:outline-none"
                              )
                            }>
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    checked
                                      ? "bg-primary border-transparent"
                                      : "bg-custWhite border-quaternary",
                                    active
                                      ? "ring-2 ring-offset-2 ring-primaty"
                                      : "",
                                    "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true">
                                  <span className="rounded-full bg-custWhite w-1.5 h-1.5" />
                                </span>
                                <div className="mx-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-primary"
                                        : "text-secondary",
                                      "block text-sm font-medium"
                                    )}>
                                    {setting.name}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked ? "text-primary" : "text-ternary",
                                      "block text-sm"
                                    )}>
                                    {setting.description}
                                  </RadioGroup.Description>
                                </div>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                  {/* /End replace */}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  language: state.language,
  navigation: state.sideBarMenus,
  sidebarSelectedMenu: state.sidebarSelectedMenu,
});

export default connect(mapStateToProps, {
  localeLanguage,
  onSidebarMenuSelected,
})(Language);
