import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { currencyCode, currencyRate } from "@currencyActions";
import Pagination from "react-js-pagination";

//Import Actions
import { fetchVendorDetailedSales } from "@revenuereportsActions";

//Import Actions
import { updateMRP, ProductTextLocalized } from "@commonFunction";

import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";

class SalesTableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_legal_docs,
    };

    // All Binded Functions
  }

  renderProductList() {
    let {
      vendorDetailedSalesStore,
      language,
      salesSearchDetailsStore,
      searchText,
    } = this.props;

    return _.map(vendorDetailedSalesStore.items, (productObj, index) => {
      let variant = _.isEmpty(productObj.product_variant)
        ? "-"
        : ProductTextLocalized(
            productObj.product_variant.name_en,
            productObj.product_variant.name_ar,
            language
          );
      var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${productObj.productimage}?api_key=${APP_API_KEY}`;
      var cardTitle = productObj.productname_en;
      let cardDesc = ProductTextLocalized(
        productObj.product_desc_en,
        productObj.product_desc_ar,
        language
      );

      var variantName = ProductTextLocalized(
        productObj.variant.name_en,
        productObj.variant.name_ar,
        language
      );

      return (
        <tr
          id={`table-list-${index}`}
          key={`table-list-${index}`}
          className="table-hover-transition"
        >
          <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  loading="lazy"
                  className="h-10 w-10 rounded-full"
                  src={productImageUrl}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-secondary">
                  {/* {cardTitle.length > 30
                    ? cardTitle.substring(0, 30) + "..."
                    : cardTitle} */}
                  {cardTitle}
                </div>
                <div className="text-sm text-ternary">
                  {cardDesc.length > 60
                    ? cardDesc.substring(0, 60) + "..."
                    : cardDesc}
                </div>
                <div className="text-sm text-ternary">{productObj?.productname_en == productObj?.variant?.name_en ?('No Variant'):(<span>{variantName}</span>)}</div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
            <div className="text-sm text-ternary">{productObj.num_sold}</div>
          </td>
        </tr>
      );
    });
  }

  paginationCallback = (pageNumber) => {
    let { userid, source, limit, searchText, index, tab, startdate, enddate } =
      this.props;

    /*
    Revenue Report
    vendor-datascript/revenuestats => Listing
    New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
    vendor-datascript/revenuesearch => Search
    Params: {searchterm:<text>}
    */
    this.props.fetchVendorDetailedSales(
      this.props.session,
      index, // Index
      tab, // tab
      source,
      limit,
      pageNumber, // page
      searchText, // searchterm
      startdate,
      enddate,
      (callBack) => {}
    );

    this.props.updatePageNumber(pageNumber);
  };

  render() {
    let itemTotalSold = 0;

    let { vendorDetailedSalesStore } = this.props;

    if (!_.isEmpty(vendorDetailedSalesStore)) {
      itemTotalSold = vendorDetailedSalesStore.stats.total_sold;
    }

    return (
      <>
        {/* Footer Part Start*/}

        <div className="w-full bottom-0 p-6">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-20">
            <div className="bg-custWhite rounded p-6 mb-20">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                    >
                      {LocaleStrings.title}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                    >
                      {LocaleStrings.qty_sold}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {this.renderProductList()}
                </tbody>
              </table>
              {this.props.searchText === "" &&
              vendorDetailedSalesStore.count > 0 ? (
                <Pagination
                  activePage={this.props.pageNumber}
                  itemsCountPerPage={this.props.limit}
                  totalItemsCount={parseInt(vendorDetailedSalesStore.count)}
                  pageRangeDisplayed={this.props.limit}
                  onChange={this.paginationCallback}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* Footer Part Ends*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, isLoggedIn, language } = state;

  return {
    session,
    user,
    revenueSalesDateTabs: state.revenueSalesDateTabs,
    vendorDetailedSalesStore: !_.isEmpty(state.vendorDetailedSalesStore)
      ? !_.isEmpty(state.vendorDetailedSalesStore.data)
        ? state.vendorDetailedSalesStore.data
        : []
      : [],
    salesSearchDetailsStore: !_.isEmpty(state.salesSearchDetailsStore)
      ? !_.isEmpty(state.salesSearchDetailsStore.data)
        ? state.salesSearchDetailsStore.data
        : []
      : [],
  };
}

export default connect(mapStateToProps, { fetchVendorDetailedSales })(
  SalesTableList
);
