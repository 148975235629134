import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
import _ from "lodash";

// Common Fc
import { classNames, timeAgo, getBilangualeText } from "@commonFunction";

class ChatList extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  componentDidMount() {}

  render() {
    let { item, session, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    let negoCurrency = item?.odcurrency;
    let price = isCurrencyRateApplicable(
      localCurrency,
      negoCurrency,
      item.price
    );
    return (
      <>
        {parseInt(item.senderid) == session.userid ? (
          <div className="flex justify-start">
            <div>
              <div
                className="bg-quaternary px-3.5 py-3"
                style={{ borderRadius: "22px 22px 22px 5px" }}>
                <div className="flex">
                  <div className="text-primary text-base font-medium">
                    {_.startCase(LocaleStrings.negotiation_status)}:
                  </div>
                  <div
                    className={classNames(
                      item.status == "rejected"
                        ? "text-danger"
                        : "text-success",
                      "text-base font-medium mx-1"
                    )}>
                    {getBilangualeText(item.status)}
                  </div>
                </div>
                <div className="text-ternary text-xs">
                  {timeAgo(item.createdat)}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-end">
            <div className="my-2">
              {item.status == "rejected" ? (
                <div
                  className="bg-quaternary px-3.5 py-3"
                  style={{ borderRadius: "22px 22px 5px 22px" }}>
                  <div className="flex">
                    <div
                      className={classNames(
                        item.status == "rejected"
                          ? "text-danger"
                          : "text-success",
                        "text-base font-medium mx-1"
                      )}>
                      {getBilangualeText(item.message)}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="bg-primary text-custWhite px-3 py-2"
                  style={{ borderRadius: "22px 22px 5px 22px" }}>
                  {currency} {parseFloat(item.price).toFixed(2)}
                </div>
              )}

              <div className="text-ternary text-xs">
                {timeAgo(item.createdat)}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    localCurrency: state.currency,
  };
}

export default connect(mapStateToProps, {})(ChatList);
