import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import Components
import BaseLoader from "@baseLoader";
import ProfileSettingsList from "./profilesettingslist";
import MyFollowers from "./myfollowers/myfollowers";
import AccountSettings from "./accountsettings/accountsettings";
import StoreVerification from "./storeverification/storeverification";
import StoreInformation from "./storeinformation/storeinformation";
import ProfileInformation from "./profileinformation/profileinformation";
import PersonalvendorAddress from "./personalvendoraddress/index";
//src/components/starter/vendorverification.js
import VendorVerification from "../../../starter/vendorverification";
import OrderHandle from "./orderhandle/index";
import { APP_API_KEY } from "@constant";

// Import Actions
import { fetchStoreDetails, fetchpersonalStoreDetails } from "../actions/index";
import { isVerficationModalOpen } from "@mainActions";
import { fetchZoneAndPincode } from "../../productmanagement/actions/index";

import toast, { Toaster } from "react-hot-toast";

class ProfileSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaderDesc: LocaleStrings.preparing_profile_settings,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { session, user } = this.props;
    /**** Fetch Store Information ****/
    this.props.fetchStoreDetails(this.props.session, (callback) => {});

    if (session.usertype == "personal") {
      this.props.fetchpersonalStoreDetails(
        this.props.session,
        (callback) => {}
      );
    }
    this.props.fetchZoneAndPincode(session, (callback) => {});

    /*****  Check email and phone number is verified or not *****/
    if (user.phoneverified === false) {
      this.props.isVerficationModalOpen(true);
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : this.props.profSettSelectedMenu === 6 ? (
          <PersonalvendorAddress />
        ) : this.props.profSettSelectedMenu === 5 ? (
          <OrderHandle />
        ) : this.props.profSettSelectedMenu === 4 ? (
          <MyFollowers />
        ) : this.props.profSettSelectedMenu === 3 ? (
          <AccountSettings />
        ) : this.props.profSettSelectedMenu === 2 ? (
          <StoreVerification />
        ) : this.props.profSettSelectedMenu === 1 ? (
          <StoreInformation />
        ) : this.props.profSettSelectedMenu === 0 ? (
          <ProfileInformation />
        ) : (
          <div className="py-6">
            <ProfileSettingsList />
          </div>
        )}
        <VendorVerification history={this.props.history} />
        <Toaster />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    profileSettingsList: state.profileSettingsList,
    profSettSelectedMenu: state.profileSettingsSelectedMenu,
    isVerficationOpen: state.isVerficationOpen,
  };
}

export default connect(mapStateToProps, {
  fetchStoreDetails,
  isVerficationModalOpen, // Varification modal fc
  fetchZoneAndPincode,
  fetchpersonalStoreDetails,
})(ProfileSettings);
