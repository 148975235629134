import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from "@networkCall";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from "@constant";
import _ from "lodash";

//SALES
export const REVENUEREPORTS_DETAILEDSALES = "REVENUEREPORTS_DETAILEDSALES";
export const SALES_SEARCHDEATILS = "SALES_SEARCHDEATILS";
//REVENUE
export const REVENUEREPORTS_DETAILEDREVENUE = "REVENUEREPORTS_DETAILEDREVENUE";
//TRANSACTION HISTORY
export const TRANSACTION_HISTORY = "TRANSACTION_HISTORY";

// SALES
/**** Fetch vendor Detailed Sales Stats *****/
export function fetchVendorDetailedSales(
  session,
  index,
  tab,
  source,
  limit,
  page,
  searchText,
  startdate,
  enddate,
  callback
) {
  /*
   Sales Report
   vendor-datascript/detailedsales => Listing
   New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
   vendor-datascript/salessearch => Search
   Params: {searchterm:<text>}
  */
  var tab =
    tab == 3 ? "monthly" : tab == 2 ? "weekly" : tab == 1 ? "daily" : "all";
  var url = "";
  let values = {};

  if (searchText === "") {
    url = `${INSTANCE_URL}/api/v2/vendor-datascript/detailedsales`;
    // Listing
    values.index = index;
    values.tab = tab;
    values.source = source;
    values.limit = limit;
    values.page = page;
  } else {
    url = `${INSTANCE_URL}/api/v2/vendor-datascript/salessearch`;
    // Search
    values.searchterm = searchText;
  }

  if (startdate !== "" && enddate !== "") {
    // If both of them are present sent with [daterange:<startdate#enddate> (yyyy-MM-dd format)]
    values.daterange = startdate + "#" + enddate;
  }

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: REVENUEREPORTS_DETAILEDSALES,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/* Exact same as avobe function with diffrent response */
export function downloadSalesReport(
  session,
  index,
  tab,
  source,
  limit,
  page,
  searchText,
  startdate,
  enddate,
  callback
) {
  /*
  Sales Report - CSV DOWNLAOD

  vendor-datascript/detailedsales => Listing
  New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
   
  vendor-datascript/salessearch => Search
  Params: {searchterm:<text>}
  */

  var tab =
    tab == 3 ? "monthly" : tab == 2 ? "weekly" : tab == 1 ? "daily" : "all";

  var url = "";
  let values = {};
  if (searchText === "") {
    url = `${INSTANCE_URL}/api/v2/vendor-datascript/detailedsales`;
    // Listing
    values.index = index;
    values.tab = tab;
    values.source = source;
    values.limit = limit;
    values.page = page;
    values.generate = true;
  } else {
    url = `${INSTANCE_URL}/api/v2/vendor-datascript/salessearch`;
    // Search
    values.searchterm = searchText;
    values.generate = true;
  }

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, message: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/**** Fetch vendor Detailed Sales Stats *****/
export function salessearch(session, searchterm = "", callback) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/salessearch`;

  if (_.isEmpty(searchterm)) {
    return (dispatch) => {
      dispatch({
        type: SALES_SEARCHDEATILS,
        payload: { data: {} },
      });
    };
  } else {
    return (dispatch) => {
      postRequest(
        url,
        {
          searchterm: searchterm,
        },
        session,
        dispatch,
        (response) => {
          dispatch({
            type: SALES_SEARCHDEATILS,
            payload: { data: response.data },
          });

          callback({ status: 1 });
        },
        (error) => {}
      );
    };
  }
}

//REVENUE
/**** Fetch vendor Detailed Revenue Stats *****/
export function fetchVendorDetailedRevenue(
  session,
  index,
  tab,
  source,
  limit,
  page,
  searchText,
  startdate,
  enddate,
  callback
) {
  /*
  Revenue Report -

  vendor-datascript/revenuestats => Listing
  New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
  
  vendor-datascript/revenuesearch => Search
  Params: {searchterm:<text>}
  */

  var tab =
    tab == 3 ? "monthly" : tab == 2 ? "weekly" : tab == 1 ? "daily" : "all";

  var url = "";
  let values = {};
  if (searchText === "") {
    url = `${INSTANCE_URL}/api/v2/vendor-datascript/revenuestats`;
    // Listing
    values.index = index;
    values.tab = tab;
    values.source = source;
    values.limit = limit;
    values.page = page;
  } else {
    url = `${INSTANCE_URL}/api/v2/vendor-datascript/revenuesearch`;
    // Search
    values.searchterm = searchText;
  }

  if (startdate !== "" && enddate !== "") {
    // If both of them are present sent with [daterange:<startdate#enddate> (yyyy-MM-dd format)]
    values.daterange = startdate + "#" + enddate;
  }

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: REVENUEREPORTS_DETAILEDREVENUE,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/* Exact same as avobe function with diffrent response */
export function downloadRevenueReport(
  session,
  index,
  tab,
  source,
  limit,
  page,
  searchText,
  startdate,
  enddate,
  callback
) {
  /*

  Revenue Report - CSV DOWNLAOD

  vendor-datascript/revenuestats => Listing
  New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
  
  vendor-datascript/revenuesearch => Search
  Params: {searchterm:<text>}
  */

  var tab =
    tab == 3 ? "monthly" : tab == 2 ? "weekly" : tab == 1 ? "daily" : "all";

  var url = "";
  let values = {};
  if (searchText === "") {
    url = `${INSTANCE_URL}/api/v2/vendor-datascript/revenuestats`;
    // Listing
    values.index = index;
    values.tab = tab;
    values.source = source;
    values.limit = limit;
    values.page = page;
    values.generate = true;
    // values.daterange = "2021-01-01#2022-12-31";
  } else {
    url = `${INSTANCE_URL}/api/v2/vendor-datascript/revenuesearch`;
    // Search
    values.searchterm = searchText;
    values.generate = true;
    // values.daterange = "2021-01-01#2022-12-31";
  }

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, message: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

// TRANSACTION HISTORY
export function fetchTransactionHistory(
  session,
  userid,
  limit,
  page,
  type,
  searchterm,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/vendor-datascript/transactions`;
  let values = {};
  if (type === "listing") {
    // Paginated
    values.foruserid = userid;
    values.limit = limit;
    values.page = page;
    values.type = type;
  } else {
    // With out pagination [search result]
    values.foruserid = userid;
    values.type = type;
    values.searchterm = searchterm;
  }

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (type === "listing") {
          // Paginated
          dispatch({
            type: TRANSACTION_HISTORY,
            payload: { data: response.data, count: response.count },
          });
        } else {
          // With out pagination [search result]
          dispatch({
            type: TRANSACTION_HISTORY,
            payload: { data: response.data },
          });
        }

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}
