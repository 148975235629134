import React, { Component, useCallback, Fragment } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import _ from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { Transition, Menu } from "@headlessui/react";
import { DotsHorizontalIcon } from "@heroicons/react/outline";
import { confirmAlert } from "react-confirm-alert";

// Import BaseLoader
import BaseLoader from "@baseLoader";

// Import Actions
import {
  isQaModalOpen,
  fetchqa,
  questionanswer,
} from "@productmanagementActions";
import { openQAReportModal } from "@profileSettingsActions";

// Component Import
import QaWithOutLimit from "./qawithoutlimit";
import ReportAbuseQa from "@reportAbuseQa";

// Common Fc
import { classNames } from "@commonFunction";

var Loader = require("react-loaders").Loader; // import "AwesomeLoaderCss";

class QaWithLimit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_product_management,

      giveAnswer: false,
      qaid: "",
      answer: "",
      question: "",
      askQuestion: false,
      QuestionForAmswer: "",

      btnLoader: false,
    };
    this.myRef = React.createRef();
    // All Binded Functions
  }

  // component mount
  componentDidMount() {
    let { productID, session } = this.props;

    this.props.fetchqa(session, productID, 1, (callback) => {
      this.setState({ loading: false });
    });

    this.props.fetchqa(session, productID, 0, (callback) => {
      this.setState({ loading: false });
    });
  }

  // Component Unmount
  componentWillUnmount() {
    this.setState({ btnLoader: false });
  }

  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  /* Report abuse on question [askedby] */
  reportAbusive = (userid) => {
    let { isLoggedIn } = this.props;
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.report_confirm_questions_answers_title,
        message: LocaleStrings.report_confirm_questions_answers_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              this.props.openQAReportModal(true, userid, "question");
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    }
  };

  // Render Qa 3 List
  renderQaWithLimit = (e) => {
    let { productQaWithLimit, session, language } = this.props;

    let item = _.map(
      _.orderBy(productQaWithLimit, ["updatedat"], ["desc"]),
      (qaObject, index) => {
        return (
          <li
            key={index}
            className={"py-4 bg-custWhite px-4  table-hover-transition"}>
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  loading="lazy"
                  className="h-8 w-8"
                  src={"/images2/common/askquestion.svg"}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-secondary truncate">
                  {qaObject.question}
                </p>
                <p className="text-sm text-ternary truncate">
                  {qaObject.answer}
                </p>
              </div>
              <div className="flex space-x-2">
                {/* Answer for question */}
                <button
                  type="button"
                  className={classNames(
                    !_.isEmpty(qaObject.answer) ? "hidden" : "tbl-btn-primary",
                    ""
                  )}
                  onClick={(e) => this.selectedQuestionForAnswer(e, qaObject)}>
                  {LocaleStrings.answer}
                </button>
                {/* Abuse report on Question */}
                <Menu
                  as="div"
                  className={classNames(
                    parseInt(qaObject.askedby) === parseInt(session.userid)
                      ? "hidden"
                      : "",
                    "relative inline-block text-left p-2"
                  )}>
                  {({ open }) => (
                    <>
                      <div className="">
                        <Menu.Button className="btn-card">
                          <span className="sr-only">Open options</span>
                          <DotsHorizontalIcon
                            className="h-5 w-5 hover:shadow"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items
                          static
                          className={classNames(
                            language === "ar" ? "left-0" : "right-0",
                            "origin-top-right absolute  mt-2 w-148 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                          )}>
                          <div className="py-1 divide-y z-50 w-40">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={
                                    () =>
                                      qaObject.reportabuse === 1
                                        ? toast.error(
                                            LocaleStrings.already_reported
                                          )
                                        : this.reportAbusive(qaObject.id) // report on Question id
                                  }
                                  className={classNames(
                                    active ? "bg-quaternary " : " ",
                                    "block px-4 py-2 text-sm text-primary"
                                  )}>
                                  {LocaleStrings.report_as_abusive}
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </li>
        );
      }
    );

    return item;
  };

  openQaList = () => {
    this.closeAnswerSection();
    this.closeQuestionSection();

    this.props.isQaModalOpen(true);
  };

  /**** Answer Related  ****/

  // Select Question To answer And show "Type your Answer Input Box"
  selectedQuestionForAnswer = (e, qaObject) => {
    this.setState({ askQuestion: false });

    this.setState({ QuestionForAmswer: qaObject.question });
    this.setState({ giveAnswer: true });
    this.setState({ qaid: qaObject.id });
    setTimeout(() => {
      this._handelScroolToQuestion();
    }, 500);
  };

  /*** Close Answer Section Footer***/
  closeAnswerSection() {
    this.setState({ QuestionForAmswer: "" });
    this.setState({ giveAnswer: false });
    this.setState({ qaid: "" });
    this.setState({ answer: "" });

    this.setState({ askQuestion: false });
    this.setState({ question: "" });
  }

  /**** Add answer To state ****/
  _handelAnswerChange = (e) => {
    //let { answer } = this.state;
    let inputVal = e.target.value;
    this.setState({ answer: inputVal });
  };

  /**** Submit Answer ****/
  _submitAnswerForQuestion = (e) => {
    let { qaid, answer } = this.state;
    let { session, productID } = this.props;

    if (_.trim(answer) !== null && _.trim(answer) !== "") {
      // Submit Asked Question Answer
      let ansobj = {
        isanswer: 1,
        qaid: qaid,
        answer: answer,
      };

      this.props.questionanswer(session, ansobj, (callback) => {
        toast.success(LocaleStrings.your_answer_submitted_successfully);
        this.closeAnswerSection();
        this.setState({ answer: "" });
        this.props.fetchqa(session, productID, 1, (callback) => {});

        this.props.fetchqa(session, productID, 0, (callback) => {});
      });
    } else {
      toast.error(LocaleStrings.type_your_answer);
    }
  };

  /**** Question Related ****/

  /**** Ask Question Button Click ****/
  askAQuestion = (e) => {
    // make Initiale for Answer section
    this.setState({ giveAnswer: false });
    this.setState({ qaid: "" });

    // Start Question Section
    this.setState({ askQuestion: true });
    setTimeout(() => {
      this._handelScroolToQuestion();
    }, 500);
  };

  _handelScroolToQuestion = () => {
    const isChrome = window.chrome ? true : false;
    window.scrollTo(
      200,
      this.myRef.current.scrollIntoView({
        behavior: isChrome ? "auto" : "smooth",
        block: "end",
        inline: "nearest",
      })
    );
  };

  /**** Add Question To state ****/
  _handelQuestionChange = (e) => {
    let inputVal = e.target.value;
    this.setState({ question: inputVal });
  };

  /****  Submit Asked Question ****/
  _submitQuestion = (e) => {
    this.setState({ btnLoader: true });

    let { qaid, answer, question } = this.state;
    let { session, productID } = this.props;

    if (_.trim(question) !== null && _.trim(question) !== "") {
      // Submit a Question
      let qsnobj = {
        isanswer: 0,
        productid: productID,
        askedto: session.userid,
        question: question,
      };

      this.props.questionanswer(session, qsnobj, (callback) => {
        this.setState({ btnLoader: false, question: "" }); // Loader stop
        document.getElementById("answer_of_question_input1").value = "";
        document.getElementById("answer_of_question_input").value = "";

        toast.success(LocaleStrings.your_question_submitted_successfully);
        this.closeQuestionSection();

        this.props.fetchqa(session, productID, 1, (callback) => {
          this.setState({ loading: false });
        });

        this.props.fetchqa(session, productID, 0, (callback) => {
          this.setState({ loading: false });
        });
      });
    } else {
      toast.error(LocaleStrings.type_your_question);
      this.setState({ btnLoader: false });
    }
  };

  /*** Close Question Section Footer ***/
  closeQuestionSection() {
    this.setState({
      QuestionForAmswer: "",
      giveAnswer: false,
      qaid: "",
      askQuestion: false,
      question: "",
    });
    document.getElementById("answer_of_question_input1").value = "";
  }

  render() {
    let {
      productQaWithLimit,
      productQaWithOutLimit,
      productID,
      reportAbuseQaModal,
      language,
    } = this.props;
    let { question } = this.state;

    let customDirectionstyle =
      language === "ar" ? { direction: "rtl" } : { direction: "ltl" };

    return (
      <>
        {this.state.loading ? (
          <>{this.renderLoader()}</>
        ) : (
          <>
            {/* Ask a question */}
            <div className="block mt-4">
              <p className="text-xs font-semibold text-secondary">
                {LocaleStrings.questions_answers}
              </p>
              <p className="mt-1 text-base text-primary">
                {LocaleStrings.questions_answers_subtext}
              </p>
            </div>
            <button
              className="bg-blue-100 border-2 border-primary p-2 mt-2 rounded text-xs"
              onClick={(e) => this.askAQuestion(e)}>
              {LocaleStrings.ask_a_question}
            </button>
            {/* Question Section  */}
            <ul
              role="list"
              className="-my-5 divide-y divide-gray-200 mt-2 mb-2">
              {productQaWithLimit.length > 0 ? this.renderQaWithLimit() : ""}
            </ul>
            {/* Answer Section  */}
            <div
              className={classNames(
                !this.state.giveAnswer ? "hidden" : "",
                ""
              )}>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex">
                  <img
                    loading="lazy"
                    src="/images2/common/askquestion.svg"
                    className="w-5 cursor-pointer"
                  />
                  <div className="col-span-2 text-left mx-2">
                    {this.state.QuestionForAmswer}
                  </div>
                </div>

                <div onClick={(e) => this.closeAnswerSection()}>
                  <p
                    className={classNames(
                      language === "ar" ? "text-left" : "text-right",
                      "text-sm font-medium text-primary truncate cursor-pointer"
                    )}>
                    {LocaleStrings.close}
                  </p>
                </div>
              </div>
              <div
                className="mt-1 relative rounded-md shadow-sm"
                style={customDirectionstyle}>
                <input
                  type="text"
                  name="answer_of_question_input"
                  id="answer_of_question_input"
                  // value={this.state.question}

                  className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-secondary px-4 rounded-full"
                  placeholder={LocaleStrings.type_your_answer}
                  onChange={(e) => this._handelAnswerChange(e)}
                />
                <div
                  className={classNames(
                    language === "ar" ? "left-0" : "right-0",
                    "absolute inset-y-0  px-3 flex items-center"
                  )}>
                  <img
                    loading="lazy"
                    src="/images2/common/send_btn.svg"
                    className="w-5 cursor-pointer"
                    onClick={(e) => this._submitAnswerForQuestion()}
                  />
                </div>
              </div>
            </div>
            {/* Question Section */}
            <div
              className={classNames(
                !this.state.askQuestion ? "hidden" : "",
                ""
              )}>
              <div className="grid grid-cols-4 gap-2">
                <div></div>
                <div className="col-span-2 text-left"></div>
                <div onClick={(e) => this.closeQuestionSection()}>
                  <p
                    className={classNames(
                      language === "ar" ? "text-left" : "text-right",
                      "text-sm font-medium text-primary truncate cursor-pointer"
                    )}>
                    {LocaleStrings.close}
                  </p>
                </div>
              </div>
              <div
                className="mt-1 relative rounded-md shadow-sm"
                style={customDirectionstyle}>
                <input
                  type="text"
                  name="answer_of_question_input1"
                  id="answer_of_question_input1"
                  className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-secondary px-4 rounded-full"
                  placeholder={LocaleStrings.type_your_question}
                  onChange={(e) => this._handelQuestionChange(e)}
                />
                <div
                  className={classNames(
                    language === "ar" ? "left-0" : "right-0",
                    "absolute inset-y-0 px-3 flex items-center"
                  )}>
                  {this.state.btnLoader ? (
                    <svg
                      className={
                        "animate-spin-medium h-5 w-5 rounded-full mx-2"
                      }
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  ) : (
                    <img
                      loading="lazy"
                      src="/images2/common/send_btn.svg"
                      className="w-5 cursor-pointer"
                      onClick={(e) => this._submitQuestion()}
                    />
                  )}
                </div>
              </div>
            </div>
            <span ref={this.myRef} className="sr-only"></span>
            {/* View All Questions & Answers */}
            {productQaWithOutLimit.length > 3 ? (
              <>
                <ul
                  role="list"
                  className=" divide-y divide-quaternary border-t-2 border-b-2 border-quaternary mt-4"
                  onClick={(e) => this.openQaList()}>
                  <li className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition">
                    <div className="flex items-center space-x-4">
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-primary truncate">
                          {LocaleStrings.view_all_questions_answers}
                        </p>
                      </div>
                      <div>
                        <img
                          loading="lazy"
                          className={
                            "h-8 transform  px-2 duration-300 ease-in-out"
                          }
                          src="/images2/profile_settings_images/arrow-right.svg"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <QaWithOutLimit productID={productID} />
              </>
            ) : (
              ""
            )}
          </>
        )}
        {reportAbuseQaModal ? <ReportAbuseQa type="question" /> : ""}
        <Toaster />
        {/* <ProductFee modalHeadText={LocaleStrings.product_fee} modalFooterText={LocaleStrings.confirm_btn_text} /> */}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user, language } = state;

  return {
    session,
    user,
    language,
    isLoggedIn: state.isLoggedIn,
    productList: state.productList,
    defaultSettings: state.DefaultSettings,
    productQaWithLimit: !_.isEmpty(state.productQaWithLimit)
      ? !_.isEmpty(state.productQaWithLimit.data)
        ? state.productQaWithLimit.data
        : []
      : [],
    productQaWithOutLimit: !_.isEmpty(state.productQaWithOutLimit)
      ? !_.isEmpty(state.productQaWithOutLimit.data)
        ? state.productQaWithOutLimit.data
        : []
      : [],
    productID: ownProps.productID,
    reportAbuseQaModal: state.reportAbuseQaModal, // Abuse report
  };
}

export default connect(mapStateToProps, {
  fetchqa,
  isQaModalOpen,
  questionanswer,
  openQAReportModal,
})(QaWithLimit);
