import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from "@networkCall";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from "@constant";

import _ from "lodash";

import LocaleStrings from "@language";

export const SETTINGS_MASTER = "SETTINGS_MASTER";

/**** Fetch data from  "settings_master" table ****/
export function fetchSettingsMaster(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/settings_master`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SETTINGS_MASTER,
          payload: { data: response.resource[0] },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}
