import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import GoogleMapReact from "google-map-react";

// Import Actions
import { isStoreInfoMapModalOpen } from "../../actions/index";

// Functions Import
import { classNames, isEmpty } from "@commonFunction";

// Component Import
import { APP_API_KEY, BASE_IMAGES_URL, GOOGLE_MAP_KEY } from "@constant";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import styled from "styled-components";
import AutoComplete from "./Autocomplete";
import Marker from "./Marker";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;
class StoreMap extends Component {
  constructor(props) {
    super(props);
    // this.myRef = React.createRef();
    this.state = {
      loading: true,
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      geoCoder: null,
      places: [],
      center: [],
      zoom: 9,
      address: "",
      draggable: true,
      lat: null,
      lng: null,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  componentDidMount({ intialData } = this.props) {
    this.setCurrentLocation(intialData);
  }

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    // Clear Single Discount Object (Pointing to)

    this.props.isStoreInfoMapModalOpen(false);

    /* We could use code like the following to update specific properties */
    this.setState({
      loading: true,
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      geoCoder: null,
      places: [],
      center: [],
      zoom: 9,
      address: "",
      draggable: true,
      lat: null,
      lng: null,
    });
  }

  _handleMapSave = (lat, lng, address) => {
    this.props.handleOutterScreen(lat, lng, address);
    this.closeModal();
  };
  // handleApiLoaded = (map, maps) => {
  //     // use map and maps objects
  // };

  onMarkerInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this._generateAddress();
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    });
  };

  _onClick = (value) => {
    this.setState({
      lat: value.lat,
      lng: value.lng,
    });
    this._generateAddress();
  };

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });

    this._generateAddress();
  };

  addPlace = (place) => {
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    this._generateAddress();
  };

  _generateAddress() {
    const { mapApi } = this.state;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: this.state.lat, lng: this.state.lng } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.setState({ address: results[0].formatted_address });

            document.getElementsByName("location-search-input_2")[0].value =
              results[0].formatted_address;
          } else {
            window.alert(LocaleStrings.no_result_found);
          }
        } else {
          window.alert(LocaleStrings.geocoder_failed_due_to + status);
        }
      }
    );
  }

  // Get Current Location Coordinates
  setCurrentLocation(intialData) {
    // Old Code [Reference]
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // Old Code [Reference]
        // this.setState({
        //     center: [position.coords.latitude, position.coords.longitude],
        //     lat: position.coords.latitude,
        //     lng: position.coords.longitude
        // });
        let latitude = parseFloat(intialData.latitude);
        let longitude = parseFloat(intialData.longitude);
        latitude = latitude ? latitude : 25.909162907492664;
        longitude = longitude ? longitude : 50.56717113562029;
        this.setState({
          center: [latitude, longitude],
          lat: latitude,
          lng: longitude,
        });
      });
    }

    // New Code
    this.setState({
      center: [intialData.latitude, intialData.longitude],
      lat: intialData.latitude,
      lng: intialData.longitude,
    });
  }

  render() {
    const { places, mapApiLoaded, mapInstance, mapApi } = this.state;

    return (
      <>
        <Transition.Root show={this.props.storeInfoMapModal} as={Fragment}>
          <Dialog
            // initialFocus={this.myRef}
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.storeInfoMapModal}
            onClose={this.closeModal1}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-4 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="col-span-2 flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {LocaleStrings.search_address}
                      </div>
                    </div>
                    <div className=" sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}
                        // ref={this.myRef}
                      >
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  {/* Modal Body */}

                  <div className="mx-6 mt-2">
                    {/* Important! Always set the container height explicitly */}
                    <div style={{ height: "100vh", width: "100%" }}>
                      {/* <GoogleMapReact
                                            bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                                            defaultCenter={this.props.center}
                                            defaultZoom={this.props.zoom}
                                            >
                                            <AnyReactComponent
                                                lat={59.955413}
                                                lng={30.337844}
                                                text="My Marker"
                                            />
                                            </GoogleMapReact> */}
                      {/* <GoogleMapReact
                                                bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                                                defaultCenter={this.props.center}
                                                defaultZoom={this.props.zoom}
                                                yesIWantToUseGoogleMapApiInternals
                                                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                                >
                                                <AnyReactComponent
                                                    lat={59.955413}
                                                    lng={30.337844}
                                                    text="My Marker"
                                                />
                                            </GoogleMapReact> */}
                      <Wrapper>
                        {mapApiLoaded && (
                          <div>
                            <AutoComplete
                              map={mapInstance}
                              mapApi={mapApi}
                              addplace={this.addPlace}
                            />
                          </div>
                        )}
                        <GoogleMapReact
                          center={this.state.center}
                          zoom={this.state.zoom}
                          draggable={this.state.draggable}
                          onChange={this._onChange}
                          onChildMouseDown={this.onMarkerInteraction}
                          onChildMouseUp={this.onMarkerInteractionMouseUp}
                          onChildMouseMove={this.onMarkerInteraction}
                          // onChildClick={() => console.log("child click")}
                          onClick={this._onClick}
                          bootstrapURLKeys={{
                            key: GOOGLE_MAP_KEY,
                            libraries: ["places", "geometry"],
                          }}
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps }) =>
                            this.apiHasLoaded(map, maps)
                          }>
                          <Marker
                            text={this.state.address}
                            lat={this.state.lat}
                            lng={this.state.lng}
                          />
                        </GoogleMapReact>

                        <div className="info-wrapper">
                          <div className="map-details">
                            {LocaleStrings.latitude}{" "}
                            <span>{this.state.lat}</span>,{" "}
                            {LocaleStrings.longitude}{" "}
                            <span>{this.state.lng}</span>
                          </div>
                          <div className="map-details">
                            {LocaleStrings.zoom} <span>{this.state.zoom}</span>
                          </div>
                          <div className="map-details">
                            {LocaleStrings.address}{" "}
                            <span>{this.state.address}</span>
                          </div>
                        </div>
                        {/* Modal Footer */}
                        <div className="p-6 border-t-2 border-quaternary">
                          <button
                            className="btn-primary"
                            type="submit"
                            onClick={(e) =>
                              this._handleMapSave(
                                this.state.lat,
                                this.state.lng,
                                this.state.address
                              )
                            }>
                            {LocaleStrings.done}

                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "hidden",
                                "animate-spin-medium h-5 w-5 rounded-full mrl-2"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          </button>
                        </div>
                      </Wrapper>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    storeInfoMapModal: state.isOpenStoreInfoMapModal,
  };
}

export default connect(mapStateToProps, {
  isStoreInfoMapModalOpen,
})(StoreMap);
