import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { ProductTextLocalized } from "@commonFunction";
import { itemCount } from "@constant";
// Import BaseLoader
import BaseLoader from "@baseLoader";
//Icons
import { SearchIcon } from "@heroicons/react/solid";
// Import Modal Page
import RaiseTicketAdd from "./raiseticketadd.js";
// Import Action
import { isRaiseTicketModalopen, fetchFaq, searchFaq } from "../actions/index";
// Import Componets
import FAQNContactus from "./contactus";
import Pagination from "react-js-pagination";

const arrayData = [
  { text: "Not sure How evreything works?View Tutorial again" },
  { text: "I made a payment but order was not successfull" },
  { text: "I am not able to add my credit in the accounts section" },
  { text: "I cannot share my wishlist with other people" },
];

function multiIncludes(text, values) {
  var re = new RegExp(values.join("|"));
  return re.test(text);
}

class FAQNSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_faq_support,
      searchText: "",
      pageNumber: 1,
    };

    // All Binded Functions
  }

  componentDidMount() {
    // Table List Call
    this.props.fetchFaq(this.props.session, 0, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  //Arrow function for
  openModal() {
    this.props.isRaiseTicketModalopen(true);
  }

  /*** Show Hide Answers ***/
  handelAnswers = (e, index) => {
    let clname = document.getElementById("answer_" + index).className;
    let arrow_clname = document.getElementById("arrow_" + index).className;
    if (multiIncludes(clname, ["hidden"])) {
      document.getElementById("answer_" + index).className =
        "text-sm font-medium text-ternary";
      document.getElementById("arrow_" + index).className =
        "h-8 transform rotate-90 px-2 animation cursor-pointer transition duration-500 ease-in-out";
    } else {
      document.getElementById("answer_" + index).className =
        "text-sm font-medium text-ternary hidden";
      document.getElementById("arrow_" + index).className =
        "h-8 transform px-2 cursor-pointer animation duration-500 ease-in-out";
    }
  };

  // Render List
  renderList() {
    let { faqdata, language } = this.props;

    var item = _.map(faqdata, (value, index) => {
      return (
        <li
          key={index}
          className="py-4 bg-custWhite px-6 table-hover-transition">
          <div className="flex items-center space-x-4 cursor-pointer">
            <div className="flex-1 min-w-0 cursor-pointer">
              {/* <p className="text-sm text-secondary">{value.question} ?</p> old code*/}
              <p className="text-sm text-secondary">
                {ProductTextLocalized(
                  value.question_en,
                  value.question_ar,
                  language
                )}
                ?
              </p>
              <p
                className="text-sm font-medium text-ternary hidden"
                id={"answer_" + index}>
                {/* {value.answer_en} */}
                <p className="text-sm text-secondary">
                  {ProductTextLocalized(
                    value.answer_en,
                    value.answer_ar,
                    language
                  )}
                </p>
              </p>
            </div>
            <div className="w-24" align="center">
              <img
                loading="lazy"
                className="h-8 transform px-2 cursor-pointer animation duration-500 ease-in-out"
                id={"arrow_" + index}
                src="/images2/profile_settings_images/arrow-right.svg"
                onClick={(e) => this.handelAnswers(e, index)}
              />
            </div>
          </div>
        </li>
      );
    });

    return item;
  }

  /*** Handel Search ***/
  handelChange = (e) => {
    var pageNumber = 1;
    let searchText = e.target.value;

    if (searchText.trim() === "") {
      this.props.fetchFaq(this.props.session, 0, (CallBackResponse) => {});
    } else {
      this.props.searchFaq(this.props.session, searchText);
    }

    this.setState({ searchText, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    if (this.state.searchText.trim() === "") {
      this.props.fetchFaq(this.props.session, pageNumber, (response) => {});
    } else {
      this.props.searchFaq(
        this.props.session,
        this.state.searchText,
        pageNumber
      );
    }

    this.setState({ ...this.state, pageNumber });
  };

  render() {
    let { faqdata, count } = this.props;
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>
                <BaseLoader data={this.state.loaderDesc} />
              </>
            ) : (
              <div className="py-6 static responsivePadding">
                {/* Header Part Start*/}
                <div className="grid gap-4 grid-cols-1 px-6 md:grid-cols-3 gap-3 sm:grid-cols-2 ">
                  <div className="justify-items-center">
                    <label htmlFor="search-field" className="sr-only">
                      {LocaleStrings.search}
                    </label>
                    <div className="relative w-full text-ternary focus-within:text-secondary ">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        className="revenue-report-search-field"
                        placeholder={LocaleStrings.search_text_faq_support}
                        type="search"
                        name="search"
                        onChange={(e) => this.handelChange(e)}
                      />
                    </div>
                  </div>
                  <div align="center"></div>
                  <div>
                    <button
                      type="submit"
                      className="btn-primary"
                      onClick={(e) => this.openModal()}>
                      {LocaleStrings.raise_a_ticket}
                    </button>
                  </div>
                </div>
                {/* Header Part Ends*/}
                <div>
                  <ul role="list" className="my-5 divide-y divide-gray-200">
                    {this.renderList()}
                  </ul>
                </div>
                <Pagination
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={itemCount}
                  totalItemsCount={!_.isEmpty(faqdata) ? parseInt(count) : 0}
                  pageRangeDisplayed={10}
                  onChange={this.paginationCallback}
                />
                {/* Footer Part*/}
                <FAQNContactus />
                {/* Modal */}
                <RaiseTicketAdd />
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    faqdata: state.faqdata.data,
    count: state.faqdata.count,
  };
}

export default connect(mapStateToProps, {
  isRaiseTicketModalopen,
  fetchFaq,
  searchFaq,
})(FAQNSupport);
