import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  change,
  FieldArray,
  getFormValues,
} from "redux-form";
/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "@language";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import toast from "react-hot-toast";

import { BaseComponent, LABEL_POSITION_TOP, number } from "@baseComponent";

// Common Functions
import { ProductTextLocalized, classNames } from "@commonFunction";

// Import components
import Drop from "./drop";
import Drop2 from "./drop2";

// Import Actions
import { isVariationDeatilsopen } from "@productmanagementActions";

var dynamicRequiredFields = []; //

var variationDetailsObj = {
  id: 0,
  refkey: "",
  name_en: "",
  name_ar: "",
  price_unit: 0,
  quantity: "",
  units: "",
  isavailable: 1,
  isdeleted: false,
  variations_media_raw: [
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
  ],
  variations_media: [],
};
var variationDetailsObj1 = {
  id: 0,
  refkey: "",
  name_en: "",
  name_ar: "",
  price_unit: 0,
  quantity: "",
  units: "",
  isavailable: 1,
  isdeleted: false,
  variations_media_raw: [
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
    {
      type: "",
      file_type: "",
      file_name: "",
      file_thumbnail_name: "",
      file_path: "",
      thumbnamil_path: "",
      raw: "",
      raw_thumbnail: "",
    },
  ],
  variations_media: [],
};

class UpdateVariationDetails extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: "",
      vatiantsFeildArrState: [],
      variationDetailsArrState: [],
      useDefaultphoto: 1,
      selectedvariationId: "",
      selectedvariationForimagerefkey: "",
      flaseupdate: 1,
      imagerefreshsetup: false,
      defaultphotoArray: [
        {
          id: 0,
          isavailable: 1,
          isdeleted: false,
          name_ar: "dummyName",
          name_en: "dummyNameAr",
          price_unit: 0,
          quantity: "",
          refkey: "defaultRefkey",
          units: "",
          variations_media_raw: [
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
          ],
          variations_media: [],
        },
      ],
    };

    // All refrence variables
    this.myRef = React.createRef();

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let { isEdit, initialValues, actualarr } = this.props;
    let { defaultphotoArray } = this.state;
    let newArr = defaultphotoArray;
    if (isEdit) {
      if (Object.keys(initialValues).length > 0) {
        if (
          initialValues.hasdefaultphoto == 1 &&
          initialValues.all_variants.length > 0
        ) {
          newArr[0].variations_media = initialValues.all_variants[0].media;
          this.setState({ defaultphotoArray: newArr });
        } else {
          if (initialValues.product_variants.length > 0) {
            let filteredrefkey = _.filter(
              initialValues.product_variants,
              function (o) {
                return o.id == initialValues.variation_photo;
              }
            );
            this.setState({
              selectedvariationId: initialValues.variation_photo,
            });
            if (filteredrefkey.length > 0) {
              this.setState({
                selectedvariationForimagerefkey:
                  filteredrefkey[0].variantnames[0].refkey,
              });
            }
          }
        }

        if (
          initialValues.hasdefaultphoto == 0 ||
          initialValues.hasdefaultphoto == "0"
        ) {
          this.setState({ useDefaultphoto: 0 });
        } else {
          this.setState({ useDefaultphoto: 1 });
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ open: true });
    }
    if (nextProps.header) {
      this.setState({ headerText: nextProps.header });
    }

    let count = 0;

    _.forEach(nextProps.actualarr, (variant, vindex) => {
      _.forEach(variant.variantnames, (variantnames, vvindex) => {
        if (_.has(variantnames, "name_en") || _.has(variantnames, "name_ar")) {
        } else {
          count++;
        }
      });
    });

    if (count === 0) {
      this.setState({ vatiantsFeildArrState: nextProps.actualarr });
      /* This validation is required for No variant data upload from other component */
      if (
        nextProps?.isEdit &&
        nextProps?.defaultHasvariant === "0" &&
        nextProps?.saletype != "auction"
      ) {
        // console.log("nextProps", nextProps);
        this.setState({
          variationDetailsArrState: this.props.detailsArray,
        });
      }
    }
    // console.log("nextProps", nextProps);
    // isEdit removed as on change variation default array will update 01/06/23
    // if (isEdit && nextProps.newVariantimageRemovestate) {
    if (nextProps.newVariantimageRemovestate) {
      let newdefaultarr = [];
      if (this.state.defaultphotoArray.length > 0) {
        newdefaultarr = this.state.defaultphotoArray;
        newdefaultarr[0].variations_media = [];
        newdefaultarr[0].variations_media_raw = [
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
        ];
        this.setState({ defaultphotoArray: newdefaultarr });
        this.props.newVariantimageRemovefunc();
      }
    }
  }

  updateState = (arr) => {
    console.log("arr", arr);
    this.setState({ variationDetailsArrState: arr });
    this.props.updateVariatVariationFc(arr);
  };

  updateStatemainArr = (arr, refreshimg) => {
    // console.log("arr2", arr);
    this.setState({ vatiantsFeildArrState: arr });
    this.props.updateMainvariantState(arr, refreshimg);
  };

  updateDefaularrfunc = (arr) => {
    // console.log("arr", arr);
    // debugger;
    this.setState({ defaultphotoArray: arr });
  };

  closeModal() {
    this.setState({ open: false });
    // this.setState({ selectedvariationId: "" });
    this.props.isVariationDeatilsopen(false);
  }

  // Form Submit
  onFormSubmit(values) {
    let { isEdit } = this.props;
    let fileEmptyFlag = false;
    let fileEmptyFlagedit = false;

    let { variationDetailsArrState, vatiantsFeildArrState } = this.state;
    /***  IS IMAGE exist or not validation [START] ***/
    let editError = 0;
    _.forEach(variationDetailsArrState, function (value) {
      let countInner = 0;
      _.forEach(value.variations_media_raw, function (innerValue) {
        if (innerValue.type === "" || innerValue.type === null) {
          countInner++;
        }
      });

      if (countInner === value.variations_media_raw.length) {
        fileEmptyFlag = true;
      }
      // Latest code (validation added for image upload)
      if (
        isEdit &&
        value.variations_media &&
        value.variations_media.length == 0
      ) {
        // editError++;
        if (countInner === value.variations_media_raw.length) {
          editError++;
        }
      }
      //  {new code added to restrict while uploading file}
      if (
        isEdit &&
        value.variations_media &&
        value.variations_media.length > 0
      ) {
        let deletecounter = 0;
        _.forEach(value.variations_media, function (innerValue1) {
          if (innerValue1.isdeleted == 1 || innerValue1.isdeleted == "1") {
            deletecounter++;
          }
        });
        if (
          deletecounter === value.variations_media.length &&
          fileEmptyFlag == true
        ) {
          fileEmptyFlagedit = true;
        }
      }
    });

    // return false;
    // console.log("fileEmptyFlagedit", fileEmptyFlagedit);
    if (isEdit && editError != 0) {
      toast.error(LocaleStrings.variation_details_file_error_text);
      return false;
    }
    /***  IS IMAGE exist or not validation [END] ***/

    if (fileEmptyFlag && !isEdit) {
      toast.error(LocaleStrings.variation_details_file_error_text);
      return false;
    } else {
      if (fileEmptyFlagedit) {
        toast.error(LocaleStrings.variation_details_file_error_text);
        return false;
      } else {
        this.setState({ open: false });

        // console.log("variationDetailsArrState", variationDetailsArrState);

        let error_check = 0;

        if (variationDetailsArrState.length > 0) {
          _.forEach(variationDetailsArrState, function (v, i) {
            if (v.price_unit == "" || v.quantity == "" || v.units == "") {
              error_check += 1;
            }
          });
        }
        if (error_check == 0) {
          this.updateState(variationDetailsArrState);
          this.closeModal(); // Update details variation on main page
          this.props.isVariationDeatilsopen(false);
        } else {
          toast.error("Required Price ,Units and Quantity of each variations");
          return false;
        }
      }
    }
  }

  cartesianProductOf(x) {
    return _.reduce(
      x,
      function (a, b) {
        return _.flatten(
          _.map(a, function (x) {
            return _.map(b, function (y) {
              return x.concat([y]);
            });
          }),
          true
        );
      },
      [[]]
    );
  }

  /***** Dynamic Required Field Array *****/
  dynamicRequiredFieldsCreator = (obj) => {
    dynamicRequiredFields = [];
    for (var x = 0; x < obj.length; x++) {
      dynamicRequiredFields.push("price_unit#" + x);
      dynamicRequiredFields.push("units#" + x);
      dynamicRequiredFields.push("quantity#" + x);
    }
  };

  handleVariantDetailsKeyUp(e, curIndex, field) {
    let temp_variationDetailsArrState = this.state.variationDetailsArrState;

    if (field === "isavailable") {
      // As Its return 'on' / 'off'
      temp_variationDetailsArrState[curIndex][field] =
        e.target.checked === true ? 1 : 0;
    } else {
      temp_variationDetailsArrState[curIndex][field] = e.target.value;
    }
    this.updateState(temp_variationDetailsArrState); // old code
  }

  // Fc For render Fileds From array
  renderVariationList = () => {
    var variationDetailsArr = [];
    let {
      initialValues,
      isEdit,
      language,
      detailsArray,
      newVariantAdded,
      enar,
    } = this.props;

    let { vatiantsFeildArrState } = this.state;

    var refundUnitOptions = [];

    // Product Unit Type Object creation
    _.forEach(this.props.defaultSettings.data.product_units, function (value) {
      var obj = {
        name: value.name_en,
        value: value.idc,
      };

      refundUnitOptions.push(obj);
    });

    if (detailsArray.length > 0 && !newVariantAdded) {
      // debugger;
      variationDetailsArr = detailsArray;
      var item = _.map(variationDetailsArr, (curObject, index) => {
        // at the time of edit
        if (
          this.state.variationDetailsArrState.length > 0 &&
          this.state.variationDetailsArrState[index]
        ) {
          // debugger;
          curObject.variations_media_raw =
            this.state.variationDetailsArrState[index].variations_media_raw;
          curObject.price_unit =
            this.state.variationDetailsArrState[index].price_unit;
          curObject.quantity =
            this.state.variationDetailsArrState[index].quantity;
          curObject.units = this.state.variationDetailsArrState[index].units;
          curObject.variations_media =
            this.state.variationDetailsArrState[index].variations_media;
          curObject.isavailable =
            this.state.variationDetailsArrState[index].isavailable;
          curObject.isdeleted =
            this.state.variationDetailsArrState[index].isdeleted;
          curObject.name_ar =
            this.state.variationDetailsArrState[index].name_ar;
          curObject.id = this.state.variationDetailsArrState[index].id;
        } else {
          // at the time of edit
          if (isEdit) {
            curObject.variations_media_raw =
              variationDetailsObj.variations_media_raw;
            if (initialValues.all_variants.length > 0) {
              curObject.variations_media =
                initialValues.all_variants[index].media;

              curObject.price_unit =
                initialValues.all_variants_price_unit[index];
              curObject.quantity = initialValues.all_variants_quantity[index];
              curObject.units = initialValues.all_variants_units[index];
              curObject.isavailable =
                initialValues.all_variants[index].isavailable;
              curObject.isdeleted = initialValues.all_variants[index].isdeleted;
              curObject.name_ar = initialValues.all_variants[index].name_ar;
              curObject.id = initialValues.all_variants[index].id;
            }
          }
        }
        // console.log("variationDetailsArr2", variationDetailsArr);
        return (
          <>
            <div>
              <p className="paragraph-seconday">
                <input
                  id={"isavailable_" + index}
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="mb-1 h-4 w-4 text-primary border-ternary rounded"
                  checked={
                    curObject.isavailable === 1 || curObject.isavailable === "1"
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    this.handleVariantDetailsKeyUp(e, index, "isavailable")
                  }
                />
                <span className="m-2">
                  {" "}
                  {index + 1}
                  {". "}
                  {/* {ProductTextLocalized(
                    curObject.name_en,
                    curObject.name_ar,
                    language
                  )} */}
                  {enar === "en" ? curObject.name_en : curObject.name_ar}
                </span>
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <Field
                  name={"all_variants_price_unit[" + index + "]"}
                  label={LocaleStrings.isVariationDeatilsopen_priceunit}
                  placeholder={LocaleStrings.isVariationDeatilsopen_priceunit}
                  type="number"
                  component={this.renderFieldText}
                  mandatory="true"
                  min="0"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) =>
                    this.handleVariantDetailsKeyUp(e, index, "price_unit")
                  }
                />
              </div>
              <div>
                <Field
                  name={"all_variants_units[" + index + "]"}
                  label={LocaleStrings.isVariationDeatilsopen_unit}
                  placeholder={LocaleStrings.isVariationDeatilsopen_unit}
                  component={this.renderSelect}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  opts={refundUnitOptions}
                  className="cust-input-field2"
                  classNameLabel="block text-sm font-medium text-secondary"
                  onChange={(e) =>
                    this.handleVariantDetailsKeyUp(e, index, "units")
                  }
                  // value={this.props.initialValues.type}
                />
              </div>
              <div>
                <Field
                  name={"all_variants_quantity[" + index + "]"}
                  label={LocaleStrings.quantity}
                  placeholder={LocaleStrings.quantity}
                  type="number"
                  component={this.renderFieldText}
                  mandatory="true"
                  min="0"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) =>
                    this.handleVariantDetailsKeyUp(e, index, "quantity")
                  }
                />
              </div>
            </div>
            <div className="hidden">
              <Drop
                parentIndex={index}
                mainarr={variationDetailsArr}
                updateStateArr={this.updateState}
              />
            </div>
          </>
        );
      });

      return item;
    } else {
      // Newly created [STARTS]
      var x = [];
      _.forEach(vatiantsFeildArrState, function (productVariants, pv_index) {
        var y = [];
        _.forEach(
          productVariants.variantnames,
          function (detailedVariants, dv_index) {
            let variantNameNRefKey =
              (detailedVariants.name_en ? detailedVariants.name_en : "") +
              "_" +
              detailedVariants.refkey +
              "_" +
              (detailedVariants.name_ar ? detailedVariants.name_ar : "");

            y.push(variantNameNRefKey);
          }
        );
        x.push(y);
      });

      let output = this.cartesianProductOf(x);

      /***** Dynamic Required Field Array Call*****/
      this.dynamicRequiredFieldsCreator(output);

      var item = _.map(output, (item, index) => {
        // Storing Variations Detaild Names
        let variationDetailsObj_new = JSON.parse(
          JSON.stringify(variationDetailsObj)
        );

        let variant_variation_name_en = []; // Variant Key en
        let variant_variation_name_ar = []; // Variant Key ar

        let variant_variation_refkry = []; // Take refkey

        // From [BLUE-GJjgg7] -> blue and GJjgg7
        _.map(item, function square(n) {
          let newArray = _.split(n, "_");

          variant_variation_refkry.push(newArray[1]); // Inserting refrence key

          variant_variation_name_en.push(newArray[0]);
          variant_variation_name_ar.push(newArray[2]);
        });

        variationDetailsObj_new.name_en = _.join(
          variant_variation_name_en,
          "-"
        );
        variationDetailsObj_new.name_ar = _.join(
          variant_variation_name_ar,
          "-"
        );
        variationDetailsObj_new.refkey = _.join(variant_variation_refkry, "-");
        // console.log(
        //   "this.state.variationDetailsArrState",
        //   this.state.variationDetailsArrState
        // );
        if (
          this.state.variationDetailsArrState.length > 0 &&
          this.state.variationDetailsArrState[index]
        ) {
          variationDetailsObj_new.variations_media_raw =
            this.state.variationDetailsArrState[index].variations_media_raw;
          variationDetailsObj_new.price_unit =
            this.state.variationDetailsArrState[index].price_unit;
          variationDetailsObj_new.quantity =
            this.state.variationDetailsArrState[index].quantity;
          variationDetailsObj_new.units =
            this.state.variationDetailsArrState[index].units;

          variationDetailsObj_new.variations_media =
            this.state.variationDetailsArrState[index].variations_media;
          variationDetailsObj_new.isavailable =
            this.state.variationDetailsArrState[index].isavailable;
          variationDetailsObj_new.isdeleted =
            this.state.variationDetailsArrState[index].isdeleted;
          variationDetailsObj_new.name_ar =
            this.state.variationDetailsArrState[index].name_ar;
          variationDetailsObj_new.id =
            this.state.variationDetailsArrState[index].id;
        } else {
          // at the time of edit
          if (isEdit) {
            if (initialValues.all_variants[index]) {
              variationDetailsObj_new.variations_media =
                initialValues.all_variants[index].media;
              variationDetailsObj_new.price_unit =
                initialValues.all_variants_price_unit[index];
              variationDetailsObj_new.quantity =
                initialValues.all_variants_quantity[index];
              variationDetailsObj_new.units =
                initialValues.all_variants_units[index];
              variationDetailsObj_new.isavailable =
                initialValues.all_variants[index].isavailable;
              variationDetailsObj_new.isdeleted =
                initialValues.all_variants[index].isdeleted;
              variationDetailsObj_new.name_ar =
                initialValues.all_variants[index].name_ar;
              variationDetailsObj_new.id = initialValues.all_variants[index].id;
            }
          }
        }

        variationDetailsArr.push(variationDetailsObj_new);

        return (
          <>
            <div>
              <p className="paragraph-seconday">
                <input
                  id={"isavailable_" + index}
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="mb-1 h-4 w-4 text-primary border-ternary rounded"
                  checked={
                    variationDetailsObj_new.isavailable === 1 ||
                    variationDetailsObj_new.isavailable === "1"
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    this.handleVariantDetailsKeyUp(e, index, "isavailable")
                  }
                />{" "}
                {index + 1}
                {". "}
                {enar === "en"
                  ? variationDetailsObj_new.name_en
                  : variationDetailsObj_new.name_ar}
                {/* Old code 
                  {ProductTextLocalized(
                  variationDetailsObj_new.name_en,
                  variationDetailsObj_new.name_ar,
                  language
                )} */}
              </p>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <Field
                  name={"all_variants_price_unit[" + index + "]"}
                  label={LocaleStrings.isVariationDeatilsopen_priceunit}
                  placeholder={LocaleStrings.isVariationDeatilsopen_priceunit}
                  type="number"
                  component={this.renderFieldText}
                  mandatory="true"
                  min="0"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) =>
                    this.handleVariantDetailsKeyUp(e, index, "price_unit")
                  }
                />
              </div>
              <div>
                <Field
                  name={"all_variants_units[" + index + "]"}
                  label={LocaleStrings.isVariationDeatilsopen_unit}
                  placeholder={LocaleStrings.isVariationDeatilsopen_unit}
                  component={this.renderSelect}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  opts={refundUnitOptions}
                  className="cust-input-field2"
                  classNameLabel="block text-sm font-medium text-secondary"
                  onChange={(e) =>
                    this.handleVariantDetailsKeyUp(e, index, "units")
                  }
                  // value={this.props.initialValues.type}
                />
              </div>
              <div>
                <Field
                  name={"all_variants_quantity[" + index + "]"}
                  label={LocaleStrings.quantity}
                  placeholder={LocaleStrings.quantity}
                  type="number"
                  component={this.renderFieldText}
                  mandatory="true"
                  min="0"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) =>
                    this.handleVariantDetailsKeyUp(e, index, "quantity")
                  }
                />
              </div>
            </div>
            <div className="hidden">
              <Drop
                parentIndex={index}
                mainarr={variationDetailsArr}
                updateStateArr={this.updateState}
              />
            </div>
          </>
        );
      });

      return item;
      // Newly created [ENDS]
    }
  };

  /**** Fake Close ****/
  closeModal1() {}

  // new functions

  hasDefaultphotochange = (e) => {
    let { isEdit, detailsArray, actualarr } = this.props;
    let { variationDetailsArrState, defaultphotoArray } = this.state;

    let newvatiantsFeildArrState = [];
    let newvariantVariationsState = [];

    if (detailsArray.length > 0) {
      _.forEach(detailsArray, (v, i) => {
        let obj = _.cloneDeep(v);
        obj.variations_media = [];
        obj.variations_media_raw = [
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
        ];
        newvariantVariationsState.push(obj);
        // if(obj.variation_media && obj.variation_media.length > 0 ? (
        //   _.forEach(obj.variation_media, (vm1, im1) => {
        //       let vmobj =
        //   })
        // ):(''))
      });
    }
    if (actualarr.length > 0) {
      _.forEach(actualarr, (v, i) => {
        let obj = _.cloneDeep(v);
        let newvariantnamesarr = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.forEach(obj.variantnames, (vn1, in1) => {
            let vnobj = _.cloneDeep(vn1);
            vnobj.variations_media = [];
            vnobj.variations_media_raw = [
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
            ];
            newvariantnamesarr.push(vnobj);
          });
          obj.variantnames = newvariantnamesarr;
          newvatiantsFeildArrState.push(obj);
        }
      });
    }
    if (defaultphotoArray.length > 0) {
      let newdefaultarr1 = defaultphotoArray;
      newdefaultarr1[0]["variations_media"] = [];
      newdefaultarr1[0]["variations_media_raw"] = [
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
      ];
      this.setState({ defaultphotoArray: newdefaultarr1 });
    }
    this.updateState(newvariantVariationsState);
    this.updateStatemainArr(newvatiantsFeildArrState, "refreshimg");
    // this.setState({ imagerefreshsetup: true });
    if (isEdit) {
      // toast.error;
      this.setState({ useDefaultphoto: e.target.value });
    } else {
      this.setState({ useDefaultphoto: e.target.value });
    }
  };
  variation_photoChange = (e) => {
    let { detailsArray, actualarr } = this.props;
    let { defaultphotoArray } = this.state;

    let newvatiantsFeildArrState = [];
    let newvariantVariationsState = [];

    if (detailsArray.length > 0) {
      _.forEach(detailsArray, (v, i) => {
        let obj = _.cloneDeep(v);
        obj.variations_media = [];
        obj.variations_media_raw = [
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
        ];
        // debugger;
        newvariantVariationsState.push(obj);
        // if(obj.variation_media && obj.variation_media.length > 0 ? (
        //   _.forEach(obj.variation_media, (vm1, im1) => {
        //       let vmobj =
        //   })
        // ):(''))
      });
    }
    if (actualarr.length > 0) {
      _.forEach(actualarr, (v, i) => {
        let obj = _.cloneDeep(v);
        let newvariantnamesarr = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.forEach(obj.variantnames, (vn1, in1) => {
            let vnobj = _.cloneDeep(vn1);
            vnobj.variations_media = [];
            vnobj.variations_media_raw = [
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
            ];
            newvariantnamesarr.push(vnobj);
          });
          obj.variantnames = newvariantnamesarr;
          newvatiantsFeildArrState.push(obj);
        }
      });
    }
    if (defaultphotoArray.length > 0) {
      let newdefaultarr1 = defaultphotoArray;
      newdefaultarr1[0]["variations_media_raw"] = [
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
      ];
      newdefaultarr1[0]["variations_media"] = [];
      this.setState({ defaultphotoArray: newdefaultarr1 });
    }

    // console.log("newvariantVariationsState", newvariantVariationsState);
    // console.log("newvatiantsFeildArrState", newvatiantsFeildArrState);
    this.updateState(newvariantVariationsState);
    this.updateStatemainArr(newvatiantsFeildArrState, "refreshimg");
    // this.setState({ imagerefreshsetup: true });
    this.setState({ selectedvariationId: e.target.value });
  };

  _handelvariationImageSelection = (e, refkey) => {
    this.setState({ selectedvariationForimagerefkey: refkey });
  };

  renderselectedvariationList = () => {
    let { actualarr } = this.props;

    let { selectedvariationId, selectedvariationForimagerefkey } = this.state;
    let filteredArr = [];
    if (actualarr.length > 0 && selectedvariationId != "") {
      filteredArr = _.filter(actualarr, function (o) {
        return o.id == selectedvariationId;
      });
    }
    var item = {};
    if (filteredArr.length > 0) {
      item = _.map(filteredArr[0].variantnames, (data, index) => {
        let name_en = data.name_en;
        let name_ar = data.name_ar;
        let local_lang = this.props.language;
        let imagesArr = [data];
        let fieldText = ProductTextLocalized(name_en, name_ar, local_lang); // language
        return (
          <>
            <div
              className="col-span-2 my-2 cursor-pointer"
              onClick={(e) =>
                this._handelvariationImageSelection(e, data.refkey, index)
              }>
              {fieldText}
            </div>
            <div className="col-span-4">
              {selectedvariationForimagerefkey != "" &&
              data.refkey == selectedvariationForimagerefkey ? (
                <Drop2
                  parentIndex={index}
                  mainarr={filteredArr[0].variantnames}
                  finalarr={actualarr}
                  filteredArr={filteredArr}
                  updatemainStateArrdrop2={this.updateStatemainArr}
                  detailsArray={this.props.detailsArray}
                  updateStateArr={this.updateState}
                  useDefaultphoto="0"
                />
              ) : (
                ""
              )}
            </div>
          </>
        );
      });
    }
    if (_.isEmpty(item)) {
      // If No item Spec Found Show Message
      return <p className="paragraph-seconday">No Variation Found</p>;
    }

    return item;
  };

  addCustomData = (e, type) => {
    let { variationDetailsArrState } = this.state;
    let { formValues } = this.props;
    let value = e.target.value;
    let dataAddedArr = [];

    if (variationDetailsArrState.length > 0) {
      _.forEach(variationDetailsArrState, (val, i) => {
        let obj = {};
        obj = val;

        if (type == "price_unit") {
          obj.price_unit = value;
          var fieldname = `all_variants_price_unit[${i}]`;
          this.props.dispatch(
            change("addUpdateVariationDetailsForm", fieldname, value)
          );
        } else if (type == "units") {
          obj.units = value;
          var fieldname = `all_variants_units[${i}]`;
          this.props.dispatch(
            change("addUpdateVariationDetailsForm", fieldname, value)
          );
        } else if (type == "quantity") {
          obj.quantity = value;
          var fieldname = `all_variants_quantity[${i}]`;
          this.props.dispatch(
            change("addUpdateVariationDetailsForm", fieldname, value)
          );
        }
        dataAddedArr.push(obj);
      });

      this.updateState(dataAddedArr);
    }
  };

  removeAllVal = (e) => {
    let dataAddedArr = [];
    let { variationDetailsArrState } = this.state;
    if (variationDetailsArrState.length > 0) {
      _.forEach(variationDetailsArrState, (val, i) => {
        let obj = {};
        obj = val;
        var fieldname = `all_variants_price_unit[${i}]`;
        this.props.dispatch(
          change("addUpdateVariationDetailsForm", fieldname, "")
        );
        var fieldname1 = `all_variants_units[${i}]`;
        this.props.dispatch(
          change("addUpdateVariationDetailsForm", fieldname1, "")
        );
        var fieldname2 = `all_variants_quantity[${i}]`;
        this.props.dispatch(
          change("addUpdateVariationDetailsForm", fieldname2, "")
        );

        this.props.dispatch(
          change(
            "addUpdateVariationDetailsForm",
            "custom_variants_quantity",
            ""
          )
        );
        this.props.dispatch(
          change("addUpdateVariationDetailsForm", "custom_variants_units", "")
        );
        this.props.dispatch(
          change("addUpdateVariationDetailsForm", "custom_price_unit", "")
        );

        obj.price_unit = "";
        obj.units = "";
        obj.quantity = "";

        dataAddedArr.push(obj);
      });

      this.updateState(dataAddedArr); // old code
    }
  };

  refreshImagesetup = (e) => {
    let { detailsArray, actualarr } = this.props;
    let { defaultphotoArray } = this.state;
    // console.log("actualarr", actualarr);
    // console.log("detailsArray", detailsArray);
    // this.setState({ imagerefreshsetup: true });

    // let newdetailsArr = [];
    // let newactualarr = [];
    // _.forEach(detailsArray, (da, i) => {
    //   let val = da;
    //   val.variations_media = [];
    //   newdetailsArr.push(val);
    // });
    // _.forEach(actualarr, (aa, i) => {
    //   let val = aa;
    //   _.forEach(val,(v,i)=>{

    //   });
    // });
    // console.log()

    let newvatiantsFeildArrState = [];
    let newvariantVariationsState = [];

    if (detailsArray.length > 0) {
      _.forEach(detailsArray, (v, i) => {
        let obj = _.cloneDeep(v);
        obj.variations_media = [];
        obj.variations_media_raw = [
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
        ];
        newvariantVariationsState.push(obj);
        // if(obj.variation_media && obj.variation_media.length > 0 ? (
        //   _.forEach(obj.variation_media, (vm1, im1) => {
        //       let vmobj =
        //   })
        // ):(''))
      });
    }
    if (actualarr.length > 0) {
      _.forEach(actualarr, (v, i) => {
        let obj = _.cloneDeep(v);
        let newvariantnamesarr = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.forEach(obj.variantnames, (vn1, in1) => {
            let vnobj = _.cloneDeep(vn1);
            vnobj.variations_media = [];
            vnobj.variations_media_raw = [
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
            ];
            newvariantnamesarr.push(vnobj);
          });
          obj.variantnames = newvariantnamesarr;
          newvatiantsFeildArrState.push(obj);
        }
      });
    }
    if (defaultphotoArray.length > 0) {
      let newdefaultarr1 = defaultphotoArray;
      newdefaultarr1[0]["variations_media"] = [];
      newdefaultarr1[0]["variations_media_raw"] = [
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
        {
          type: "",
          file_type: "",
          file_name: "",
          file_thumbnail_name: "",
          file_path: "",
          thumbnamil_path: "",
          raw: "",
          raw_thumbnail: "",
        },
      ];
      this.setState({ defaultphotoArray: newdefaultarr1 });
    }
    this.updateState(newvariantVariationsState);
    this.updateStatemainArr(newvatiantsFeildArrState, "refreshimg");
    this.setState({ imagerefreshsetup: true });
    // this.setState({ open: false });
    // this.props.isVariationDeatilsopen(false);
  };

  render() {
    var {
      handleSubmit,
      pristine,
      isEdit,
      actualarr,
      detailsArray,
      initialValues,
    } = this.props;
    var {
      useDefaultphoto,
      defaultphotoArray,
      selectedvariationId,
      variationDetailsArrState,
      vatiantsFeildArrState,
      imagerefreshsetup,
    } = this.state;

    // console.log("detailsArray", detailsArray);
    // this.setState({
    //   variationDetailsArrState: this.props.detailsArray,
    // });

    // if (
    //   initialValues &&
    //   initialValues.hasdefaultphoto &&
    //   initialValues.variation_photo &&
    //   initialValues.isdraft != 1
    // ) {
    //   useDefaultphoto = initialValues.hasdefaultphoto;
    // }

    var variationoptionsArr = [];
    if (actualarr.length > 0) {
      _.forEach(this.props.actualarr, function (value) {
        var obj = {
          name: ProductTextLocalized(value.name_en, value.name_ar),
          value: value.id,
        };
        variationoptionsArr.push(obj);
      });
    }
    // No need for ['pristine'] when its in edit mode
    let custPristine = isEdit ? false : pristine;

    var refundUnitOptionscustom = [];

    // Product Unit Type Object creation
    _.forEach(this.props.defaultSettings.data.product_units, function (value) {
      var obj = {
        name: value.name_en,
        value: value.idc,
      };

      refundUnitOptionscustom.push(obj);
    });
    // console.log("defaultphotoArray", defaultphotoArray);
    return (
      <Transition.Root show={this.state.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.state.open}
          onClose={this.closeModal1}
          initialFocus={this.myRef}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block bigmodal align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:p-6">
                <div className=" sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    ref={this.myRef}
                    className="btn-closeModal"
                    onClick={this.closeModal}>
                    <span className="sr-only">{LocaleStrings.close}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className=" mx-1 md:mx-14 my-4 sm:flex-row-reverse">
                  <div className="mt-3 text-center  sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-secondary">
                      {this.state.headerText}
                      <div className="font-semibold">
                        (*Toggling Default Photo and Variation would remove
                        previous media )
                      </div>
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mx-1 md:mx-14 my-4 sm:flex-row-reverse">
                  <form
                    className="space-y-6"
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {isEdit && !imagerefreshsetup ? (
                      <div className="ml-auto">
                        <div className="grid md:grid-cols-4 grid-cols-1">
                          <button
                            className="btn-primary"
                            type="button"
                            onClick={(e) => this.refreshImagesetup(e)}>
                            Refresh and Add New Image
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{ display: "flex" }}
                      className={classNames(
                        isEdit &&
                          !imagerefreshsetup &&
                          (initialValues?.isdraft == 0 ||
                            initialValues?.isdraft == 0)
                          ? "sr-only"
                          : isEdit &&
                            !imagerefreshsetup &&
                            initialValues?.isdraft == 1 &&
                            initialValues?.all_variants.length > 0
                          ? "sr-only"
                          : ""
                      )}>
                      <label className="mr-5 text-md text-secondary">
                        {LocaleStrings.has_default_photo}
                      </label>

                      <Field
                        name="hasdefaultphoto"
                        label={LocaleStrings.button_yes}
                        component={this.renderFieldRadio}
                        type="radio"
                        value="1"
                        className="mx-2"
                        onChange={this.hasDefaultphotochange.bind(this)}
                      />
                      <Field
                        name="hasdefaultphoto"
                        label={LocaleStrings.button_no}
                        component={this.renderFieldRadio}
                        type="radio"
                        value="0"
                        className="mx-2"
                        onChange={this.hasDefaultphotochange.bind(this)}
                      />
                    </div>
                    {/* {isEdit
                      ? "*Changing to default photo yes/no will lead to deleting of all media files*"
                      : ""} */}
                    {useDefaultphoto == "1" || useDefaultphoto == 1 ? (
                      <div>
                        <Drop2
                          parentIndex={0}
                          mainarr={defaultphotoArray}
                          finalarr={actualarr}
                          filteredArr={[]}
                          updatemainStateArrdrop2={this.updateStatemainArr}
                          detailsArray={this.props.detailsArray}
                          updateStateArr={this.updateState}
                          useDefaultphoto="1"
                          updateDefaularr={this.updateDefaularrfunc}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {useDefaultphoto == "0" || useDefaultphoto == 0 ? (
                      <div>
                        <Field
                          name="variation_photo"
                          label="Photos/Videos"
                          placeholder="Select Variation"
                          component={this.renderSelect}
                          mandatory="true"
                          opts={variationoptionsArr}
                          // isdisabled={selectedvariationId != "" ? "true" : ""}
                          onChange={this.variation_photoChange.bind(this)}
                          className="cust-input-field2"
                          classNameLabel="block text-box-lable"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {useDefaultphoto == "0" || useDefaultphoto == 0 ? (
                      <>
                        <div className="grid grid-cols-6 border-2 border-gray-300 p-4 rounded">
                          {this.renderselectedvariationList()}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <fieldset className="space-y-5">
                      {/* Fc For render Fileds From array  */}

                      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div>
                          <Field
                            name={"custom_price_unit"}
                            label={
                              LocaleStrings.isVariationDeatilsopen_priceunit
                            }
                            placeholder={
                              LocaleStrings.isVariationDeatilsopen_priceunit
                            }
                            type="number"
                            component={this.renderFieldText}
                            mandatory="true"
                            min="0"
                            labelposition={LABEL_POSITION_TOP}
                            onBlur={(e) => this.addCustomData(e, "price_unit")}
                            classNameLabel="text-sm font-medium text-secondary"
                          />
                        </div>
                        <div>
                          <Field
                            name={"custom_variants_units"}
                            label={LocaleStrings.isVariationDeatilsopen_unit}
                            placeholder={
                              LocaleStrings.isVariationDeatilsopen_unit
                            }
                            component={this.renderSelect}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            opts={refundUnitOptionscustom}
                            className="cust-input-field2"
                            classNameLabel="block text-sm font-medium text-secondary"
                            onBlur={(e) => this.addCustomData(e, "units")}
                            // value={this.props.initialValues.type}
                          />
                        </div>
                        <div>
                          <Field
                            name={"custom_variants_quantity"}
                            label={LocaleStrings.quantity}
                            placeholder={LocaleStrings.quantity}
                            type="number"
                            component={this.renderFieldText}
                            mandatory="true"
                            min="0"
                            onBlur={(e) => this.addCustomData(e, "quantity")}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-medium text-secondary"
                          />
                        </div>
                        <div>
                          Clear variant's data
                          <button
                            className="btn-primary "
                            type="button"
                            onClick={(e) => this.removeAllVal(e)}>
                            Delete
                          </button>
                        </div>
                      </div>
                      {this.renderVariationList()}
                      <div className="sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          disabled={custPristine}
                          className={classNames(
                            custPristine ? "cursor-not-allowed" : "",
                            "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          )}>
                          {LocaleStrings.update_variation_details_btn_text}
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  let rowCount = 1;

  var errors = {
    all_variants_price_unit: [],
    all_variants_quantity: [],
    all_variants_units: [],
  };

  var all_variants_price_unit = values["all_variants_price_unit"]
    ? values["all_variants_price_unit"]
    : [];
  var all_variants_quantity = values["all_variants_quantity"]
    ? values["all_variants_quantity"]
    : [];
  var all_variants_units = values["all_variants_units"]
    ? values["all_variants_units"]
    : [];

  // Get the row count
  rowCount =
    _.size(dynamicRequiredFields) / 3 === 0
      ? rowCount
      : _.size(dynamicRequiredFields) / 3;

  for (let j = 0; j < rowCount; j++) {
    all_variants_price_unit[j] = all_variants_price_unit[j]
      ? all_variants_price_unit[j]
      : "";

    all_variants_units[j] = all_variants_units[j] ? all_variants_units[j] : "";

    all_variants_quantity[j] = all_variants_quantity[j]
      ? all_variants_quantity[j]
      : "";
  }

  // Assign error or balnk string for error [Price Unit]

  for (let i = 0; i < all_variants_price_unit.length; i++) {
    errors.all_variants_price_unit[i] = "";
    if (
      !all_variants_price_unit[i] ||
      all_variants_price_unit[i].trim() === ""
    ) {
      errors.all_variants_price_unit[i] = LocaleStrings.required;
    } else if (
      all_variants_price_unit[i] &&
      number(all_variants_price_unit[i])
    ) {
      errors.all_variants_price_unit[i] = LocaleStrings.invalid_price;
    } else {
      errors.all_variants_price_unit[i] = "";
    }
  }

  // Assign error or balnk string for error [Unit]
  for (let i = 0; i < all_variants_units.length; i++) {
    errors.all_variants_units[i] = "";
    if (!all_variants_units[i] || all_variants_units[i].trim() === "") {
      errors.all_variants_units[i] = LocaleStrings.required;
    } else {
      errors.all_variants_units[i] = "";
    }
  }

  // Assign error or balnk string for error [Quantity]
  for (let i = 0; i < all_variants_quantity.length; i++) {
    errors.all_variants_quantity[i] = "";
    if (!all_variants_quantity[i] || all_variants_quantity[i].trim() === "") {
      errors.all_variants_quantity[i] = LocaleStrings.required;
    } else if (all_variants_quantity[i] && number(all_variants_quantity[i])) {
      errors.all_variants_quantity[i] = LocaleStrings.invalid_quantity;
    } else {
      errors.all_variants_quantity[i] = "";
    }
  }

  return errors;
}

function mapStateToProps(state) {
  var addObject = {};
  var editObject = {};
  addObject.hasdefaultphoto = "1";
  if (state.toBeEditproduct && state.toBeEditproduct.response) {
    editObject = state.toBeEditproduct.response;
    let all_variants_price_unit = [];
    let all_variants_units = [];
    let all_variants_quantity = [];

    _.forEach(editObject.all_variants, (value, index) => {
      // value.price_unit
      // value.units
      // value.quantity
      all_variants_price_unit.push(value.price_unit);
      all_variants_units.push(value.units);
      all_variants_quantity.push(value.quantity);
    });
    _.forEach(editObject.product_variants, (value, index) => {
      if (value.hasdefaultphoto == 0 || value.hasdefaultphoto == 0) {
        editObject["variation_photo"] = value.id;
      }
    });

    editObject["all_variants_price_unit"] = all_variants_price_unit;
    editObject["all_variants_units"] = all_variants_units;
    editObject["all_variants_quantity"] = all_variants_quantity;
  }

  var { session, user } = state;

  return {
    session,
    user,
    defaultSettings: state.DefaultSettings,
    isvariationdeatilsopen: state.isVariationDeatilsopen,
    isEdit: !_.isEmpty(state.toBeEditproduct)
      ? !_.isEmpty(state.toBeEditproduct.response)
        ? true
        : false
      : false,
    initialValues: !_.isEmpty(state.toBeEditproduct)
      ? !_.isEmpty(state.toBeEditproduct.response)
        ? editObject
        : addObject
      : addObject,
    formValues: getFormValues("addUpdateVariationDetailsForm")(state),
    formValues1: getFormValues("UploadProductForm")(state),
  };
}

export default connect(mapStateToProps, {
  isVariationDeatilsopen,
})(
  reduxForm({
    validate,
    form: "addUpdateVariationDetailsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(UpdateVariationDetails)
);
