import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { Toaster } from "react-hot-toast";

// Import Components
import BaseLoader from "@baseLoader";
import DiscMasterTableList from "./discmastertablelist";
import DiscountManagHead from "./discountmanaghead";
import DiscountManagementModal from "./addeditdiscmana";

// Import Action
import {
  fetchProductCategory,
  fetchProductSubcategory,
  fetchCriteriaMaster,
  fetchDiscountArea,
  fetchDiscountMaster,
  fetchallProduct,
} from "@discountsmanagementActions";
import { onSidebarMenuSelected } from "@sidebarActions";

import { itemCount, PERSONAL_USER_REDIRECT_TO } from "@constant";

import Pagination from "react-js-pagination";

class DiscountsManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_discounts_management,
      pageNumber: 1,
    };

    // All Binded Functions
  }

  /*** Call Only When Componenet Mount 1 time) ***/
  componentDidMount() {
    let { session } = this.props;
    let { pageNumber } = this.state;
    let { usertype } = session;

    if (usertype === "personal") {
      this.props.onSidebarMenuSelected(2);
      this.props.history.push(PERSONAL_USER_REDIRECT_TO);
    } else {
      // Get Categories
      this.props.fetchProductCategory(this.props.session, (callBack) => {});
      this.props.fetchallProduct(this.props.session, (callBack) => {});

      // Subcategories
      this.props.fetchProductSubcategory(this.props.session, (callBack) => {});

      // Criteria
      this.props.fetchCriteriaMaster(this.props.session, (callBack) => {});

      // Discount Area
      this.props.fetchDiscountArea(this.props.session, (callBack) => {
        this.props.fetchDiscountMaster(
          this.props.session,
          pageNumber,
          (callBack) => {
            this.setState({ loading: false });
          }
        );
      });
    }
  }

  // Render Discount master List
  renderDiscountMasterList() {
    return _.map(this.props.discountMasterStore, (dismasterObj, index) => {
      return (
        <DiscMasterTableList
          key={dismasterObj.id}
          dismasterObj={dismasterObj}
          position={index}
          updatePageNumber={this.updatePageNumber}
        />
      );
    });
  }

  paginationCallback = (pageNumber) => {
    this.props.fetchDiscountMaster(
      this.props.session,
      pageNumber,
      (response) => {}
    );
    this.setState({ ...this.state, pageNumber });
  };

  updatePageNumber = (pagenum) => {
    this.setState({ pageNumber: pagenum });
  };

  render() {
    let { discountMasterStore, count } = this.props;

    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>
                <BaseLoader data={this.state.loaderDesc} />
              </>
            ) : (
              <div className="responsivePadding">
                <DiscountManagHead />
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto ">
                    <div className="py-2 align-middle inline-block min-w-full ">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="sales-table-th">
                                {LocaleStrings.discount_thead_title}
                              </th>
                              <th scope="col" className="sales-table-th">
                                {LocaleStrings.discount_thead_applied_on}
                              </th>
                              <th scope="col" className="sales-table-th">
                                Area
                              </th>
                              <th scope="col" className="sales-table-th">
                                {LocaleStrings.discount_thead_valide_until}
                              </th>
                              <th scope="col" className="sales-table-th">
                                Percentage
                              </th>
                              <th scope="col" className="sales-table-th">
                                {LocaleStrings.action}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {this.renderDiscountMasterList()}
                          </tbody>
                        </table>
                        {!_.isEmpty(discountMasterStore) ? (
                          <Pagination
                            activePage={this.state.pageNumber}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              !_.isEmpty(discountMasterStore)
                                ? parseInt(count)
                                : 0
                            }
                            pageRangeDisplayed={10}
                            onChange={this.paginationCallback}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <DiscountManagementModal updatePageNumber={this.updatePageNumber} />
            <Toaster />
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    discountMasterStore: !_.isEmpty(state.discountMasterStore)
      ? state.discountMasterStore.data
      : [],
    count: state.discountMasterStore.count,
  };
}

export default connect(mapStateToProps, {
  fetchProductCategory,
  fetchProductSubcategory,
  fetchCriteriaMaster,
  fetchDiscountArea,
  fetchDiscountMaster,
  onSidebarMenuSelected,
  fetchallProduct,
})(DiscountsManagement);
