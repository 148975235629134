import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";

import Dropzone from "react-dropzone";
import browserImageSize from "browser-image-size";
import ProcessImage from "react-imgpro";
import LocaleStrings from "@language";

import { ZoomOutIcon, ZoomInIcon } from "@heroicons/react/outline";

export default class StoreInlineCropper extends Component {
  constructor(props) {
    super(props);

    this.imageCallback = null;

    this.state = {
      canvasRecource: props.canvasRecource,
      imagePreviewUrl: props.imagePreviewUrl,
      imagepath: props.imagepath,
      imageErrorClsName: "hide",
      imageErrorMsg: "",
      minScale: 1,
      maxScale: 2,
      scaleStep: 0.05,
      scale: 1,
      defaultScale: 1,
      isProcessing: false,
    };

    this.setEditorRef = this.setEditorRef.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.resetImage = this.resetImage.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
  }

  setEditorRef = (editor) => (this.editor = editor);

  onClickSave = () => {
    this.saveImage(null);
  };

  saveImage = (callback) => {
    this.imageCallback = callback;

    if (this.editor) {
      var imageType = this.props.imageType;
      if (!imageType) imageType = "jpg";

      var img;
      if (imageType === "png") img = this.editor.getImage().toDataURL();
      else img = this.editor.getImage().toDataURL("image/jpeg", 0.8);

      this.setState({ imagepath: img });
    }
  };

  resetImage = () => {
    this.props.onImageAvailableLocally(false);
    this.setState({
      canvasRecource: "",
      imagepath: "",
      scale: this.state.defaultScale,
    });
    this.props.onImageSave("");
  };

  onDropFile = (files) => {
    var that = this;
    let file = files[0];
    let reader = new FileReader();
    if (file && file.type.indexOf("image") != -1) {
      reader.onloadend = () => {
        var image = reader.result;
        browserImageSize(image).then(function (size) {
          that.setState({
            ...that.state,
            canvasRecource: image,
            isProcessing: true,
          });

          that.props.onImageDropped(true);
        });
      };
      reader.readAsDataURL(file);
    }
  };

  onZoomOut() {
    var scale = this.state.scale;
    scale = scale - this.state.scaleStep;
    if (scale < this.state.minScale) scale = this.state.minScale;
    this.setState({ ...this.state, scale });
  }

  onZoomIn() {
    var scale = this.state.scale;
    scale = scale + this.state.scaleStep;
    if (scale > this.state.maxScale) scale = this.state.maxScale;
    this.setState({ ...this.state, scale });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.imagepath !== "" && this.state.imagepath === "") {
        this.setState({ imagepath: nextProps.imagepath });
      }
    }
  }

  onImageProcessing = (src, err) => {
    var that = this;
    this.props.onImageSave(src);
    that.props.onImageAvailableLocally(true);
    if (this.imageCallback) {
      setTimeout(() => {
        that.imageCallback();
        that.setState({ isProcessing: false });
      }, 2000);
    } else {
      that.setState({ isProcessing: false });
    }
  };

  render() {
    let imageUrl = this.state.imagepath;

    var hideDropZoneClass = "";
    var hideCropZineClass = "hide-feature";
    var hideControlButton = "hide-feature";

    if (imageUrl && imageUrl !== "file object not found") {
      hideDropZoneClass = "hide-feature";
      hideCropZineClass = "hide-feature";
      hideControlButton = "";
    }

    if (this.state.canvasRecource && !imageUrl) {
      hideDropZoneClass = "hide-feature";
      hideCropZineClass = "";
      hideControlButton = "hide-feature";
    }

    var imageErrorStyle = {
      padding: "5px",
      marginTop: "5px",
    };

    return (
      <div>
        <Dropzone
          onDrop={(e) => this.onDropFile(e)}
          accept="image/jpeg, image/png ,image.jpg"
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={`${this.props.className} ${hideDropZoneClass}`}
            >
              <input {...getInputProps()} />
              {this.props.innerText}
            </div>
          )}
        </Dropzone>
        <img
          loading="lazy"
          src={imageUrl}
          alt=""
          className={`img-responsive brandingImg ${hideControlButton}`}
          style={{
            width: this.props.width,
            height: this.props.height,
            cursor: "pointer",
            border: "4px solid #fff",
          }}
        />
        <div className={this.state.imageErrorClsName} style={imageErrorStyle}>
          {this.state.imageErrorMsg}
        </div>
        {/* It will hide if dropdzon is showing */}
        <div className={hideCropZineClass}>
          <AvatarEditor
            image={this.state.canvasRecource}
            scale={this.state.scale}
            rotate={0}
            ref={this.setEditorRef}
            disabledrop="true"
            width={this.props.width}
            height={this.props.height}
            className="editor-canvas"
            style={{ background: "#777" }}
          />
          <div className="flex gap-4 mt-4">
            <button
              type="button"
              className="tbl-btn-primary"
              onClick={this.onZoomIn.bind(this)}
            >
              <ZoomInIcon className="w-4 h-4" />{" "}
            </button>
            <button
              type="button"
              className="tbl-btn-primary"
              onClick={this.onZoomOut.bind(this)}
            >
              {" "}
              <ZoomOutIcon className="w-4 h-4" />
            </button>
            <button
              type="button"
              className="tbl-btn-primary"
              onClick={this.onClickSave}
            >
              {" "}
              {LocaleStrings.button_crop_save}{" "}
            </button>
            <button
              type="button"
              className="tbl-btn-danger"
              onClick={this.resetImage}
            >
              {" "}
              X{" "}
            </button>
          </div>
        </div>
        {/* 
            UPLOAD NEW IMAGE BUTTON 
            Note:-  It will hide if dropdzon is showing 
        */}
        <div className={hideControlButton}>
          <button
            type="button"
            style={{ marginTop: 5 }}
            className="btn-primary"
            onClick={this.resetImage}
          >
            {" "}
            {LocaleStrings.button_upload_new_image}{" "}
          </button>
        </div>
        <div style={{ display: "none" }}>
          {this.state.imagepath !== "" && this.state.isProcessing ? (
            <ProcessImage
              image={this.state.imagepath}
              resize={{ width: this.props.imgwidth }}
              processedImage={this.onImageProcessing}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
