import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
// Import Actions
import {
  isQaModalOpen,
  fetchproductlikes,
  questionanswer,
  isLikesModalOpen,
} from "@productmanagementActions";



class PayFail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_product_likes,
      modalHeaderText: LocaleStrings.likes,
    };

    // All Binded Functions

  }

  componentDidMount() {
  
  }

  componentWillUnmount() {
    
  }

  _handleBack(e) {
    var url_string = window.location.href;
    var subquery = url_string.split("?")[1];
        var ids = subquery.split("-")
        let productid = ids[0];
        let variantid = ids[1];
        console.log('productid',productid)
        console.log('productid',productid)
        this.props.history.push(
            `/dashboard/productmanagement/product-details/${productid}-${variantid}`
            );
    // this.props.history.push("/dashboard/legaldocs");
  }

  // // Render Product Likes List
 
  render() {
    let { session } = this.props;
    let { modalHeaderText } = this.state;

    return (
      <>
       <div className="py-6 bg-custWhite ">
          {/* Header Part */}
          <div className="grid gap-4 grid-cols-1 px-6 md:grid-cols-3 gap-3 sm:grid-cols-2 ">
            <div
              align="left"
              className="flex cursor-pointer icon-hover-transition"
              onClick={(e) => this._handleBack(e)}
            >
              <img
                loading="lazy"
                className={" h-6 w-6 "}
                src={"/images2/common/arrow.svg"}
              />
              <p className="text-primary mx-2 text-l font-bold">
                Go Back
              </p>
            </div>
            <div className="justify-items-center"></div>
            <div className="mx-5 mt-2 flex gap-4 flex-row-reverse "></div>
          </div>
        </div>
        <div className="center-item mt-48">
            Payment Failed
        </div>
        <div className=" center-item mt-8"> The payement was not able to processed ! Try again Later</div>
  
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isLikesopen: state.isLikesopen,
    productlikesData: !_.isEmpty(state.productlikesData)
      ? !_.isEmpty(state.productlikesData.data)
        ? state.productlikesData.data
        : []
      : [],
    productID: ownProps.productID,
  };
}

export default connect(mapStateToProps, {
  isQaModalOpen,
  fetchproductlikes,
  questionanswer,
  isLikesModalOpen,
})(PayFail);
