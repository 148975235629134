import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";

import _ from "lodash";
import moment from "moment-timezone";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  CURRENCY_USD,
  CURRENCY_QR,
} from "@constant";

// Common Functions
import {
  ProductTextLocalized,
  getIntToFloat,
  classNames,
  getBilangualeText,
} from "@commonFunction";

// Import Action
import {
  toggleNewOrderDetailsModal,
  setNewOrderOrderID,
  setNewOrderOrderOBJECT,
} from "../../actions/index";

class NewOrderTableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_deliveredorders,
    };
    // All Binded Functions
    this._handleOrderDetails = this._handleOrderDetails.bind(this);

    this.statusArr = [
      { name: "delivered", nametoshow: LocaleStrings.order_delivered },
      { name: "intransit", nametoshow: LocaleStrings.in_transit },
      { name: "dispatched", nametoshow: LocaleStrings.order_dispatched },
      { name: "placed", nametoshow: LocaleStrings.order_Placed },
      { name: "cancelled", nametoshow: LocaleStrings.order_cancelled },
      { name: "returnreceived", nametoshow: LocaleStrings.return_received },
      { name: "returnpicked", nametoshow: LocaleStrings.return_picked },
      { name: "initiatepickup", nametoshow: LocaleStrings.initiate_pickup },
      { name: "return", nametoshow: LocaleStrings.return_requested },
      { name: "replace", nametoshow: LocaleStrings.replacement_requested },
      { name: "refund", nametoshow: LocaleStrings.refund_initiated },
      { name: "pending", nametoshow: LocaleStrings.payment_pending },
      { name: "refundrejected", nametoshow: LocaleStrings.refund_rejected },
      { name: "refunded", nametoshow: LocaleStrings.refunded },
      { name: "reshipdelivered", nametoshow: LocaleStrings.reshipdelivered },
      { name: "reship", nametoshow: LocaleStrings.replace_reshippped },
      { name: "cancelrefund", nametoshow: LocaleStrings.cancel_refund },
      { name: "cancelrefunded", nametoshow: LocaleStrings.cancel_refunded },
      {
        name: "cancelrefundrejected",
        nametoshow: LocaleStrings.cancel_refundrejected,
      },
      {
        name: "initiatereplacepickup",
        nametoshow: LocaleStrings.initiate_replace_pickup,
      },
      {
        name: "cancelreturn",
        nametoshow: LocaleStrings.return_cancelled,
      },
      {
        name: "cancelledrefundprocessing",
        nametoshow: LocaleStrings.refund_processing,
      },
      {
        name: "returnrefundprocessing",
        nametoshow: LocaleStrings.refund_processing,
      },
    ];
  }

  componentWillUnmount() {
    this.props.toggleNewOrderDetailsModal(false);
  }

  _handleOrderDetails(e, orderid, selectedObject) {
    this.props.setNewOrderOrderID(orderid);
    this.props.setNewOrderOrderOBJECT(selectedObject);

    /*
    Open page "neworderdetails.js" [Details modal]
    */
    this.props.toggleNewOrderDetailsModal(true);
  }

  render() {
    let { language, localCurrency, orderObj } = this.props;
    let currency = currencyCode(localCurrency);
    //let currencyrate = currencyRate(localCurrency);

    let callFrom = this.props.callFrom;
    let local_lang = this.props.language;
    var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${this.props.orderObj.product.productimage}?api_key=${APP_API_KEY}`;
    let productname = ProductTextLocalized(
      this.props.orderObj.product.productname_en,
      this.props.orderObj.product.productname_en,
      local_lang
    ); // language
    let currencychoice =
      this.props.orderObj.purchasedby.currencychoice === "USD"
        ? CURRENCY_USD
        : this.props.orderObj.purchasedby.currencychoice === "QAR"
        ? CURRENCY_QR
        : "-";
    let payment_method = this.props.orderObj.payment_method;
    let quantity = this.props.orderObj.product.quantity;
    let invoice_no = this.props.orderObj.product.invoice_no;
    let sales_status = "";
    let date = moment(
      this.props.orderObj.product.currentstatus.updatedat
    ).format("D MMM YYYY");
    let status = this.props.orderObj.product.currentstatus.status;

    if (callFrom == "new") {
      sales_status = "";
    } else if (callFrom == "delivered") {
      sales_status =
        this.props.orderObj.feedback_received === 1
          ? LocaleStrings.feedback_received
          : LocaleStrings.feedback_pending;
    } else if (callFrom == "cancelled") {
    } else if (callFrom == "return") {
    } else if (callFrom == "replace") {
      status =
        status == "replace"
          ? LocaleStrings.replacement_requested
          : LocaleStrings.in_transit;
    }
    //  moment(this.props.orderObj.product.updatedat).format("D MMM YYYY")

    /* Product currency */
    let orderCurrency = orderObj.product.odcurrency;

    // let grand_total = isCurrencyRateApplicable(
    //   localCurrency,
    //   orderCurrency,
    //   orderObj.summary.grand_total
    // );
    let grand_total = orderObj?.summary?.grand_total;

    return (
      <tr
        key={this.props.position}
        className="cursor-pointer"
        // onClick={(e) =>
        //   this._handleOrderDetails(
        //     e,
        //     this.props.orderObj.orderid,
        //     this.props.orderObj
        //   )
        // }
      >
        <td
          className="sales-table-td"
          onClick={(e) =>
            this._handleOrderDetails(
              e,
              this.props.orderObj.orderid,
              this.props.orderObj
            )
          }>
          <div className=" h-10 w-10">
            <img
              loading="lazy"
              className="h-10 w-10 rounded-full"
              src={productImageUrl}
              alt=""
            />
          </div>
          <div className="mt-1">
            <div className="tooltip">
              <div className="text-sm font-medium text-secondary">
                {productname.length > 30
                  ? productname.substring(0, 30) + "..."
                  : productname}
              </div>
              <span className="tooltiptextleft">{productname}</span>
            </div>
          </div>
        </td>
        <td
          className="sales-table-td"
          onClick={(e) =>
            this._handleOrderDetails(
              e,
              this.props.orderObj.orderid,
              this.props.orderObj
            )
          }>
          <div className="sales-table-td-text">{invoice_no}</div>
        </td>
        <td
          className="sales-table-td"
          onClick={(e) =>
            this._handleOrderDetails(
              e,
              this.props.orderObj.orderid,
              this.props.orderObj
            )
          }>
          <div className="sales-table-td-text">{date}</div>
        </td>
        <td
          className="sales-table-td"
          onClick={(e) =>
            this._handleOrderDetails(
              e,
              this.props.orderObj.orderid,
              this.props.orderObj
            )
          }>
          <div className="sales-table-td-text">
            {this.props.orderObj.purchasedby.username}
          </div>
        </td>
        <td
          className="sales-table-td"
          onClick={(e) =>
            this._handleOrderDetails(
              e,
              this.props.orderObj.orderid,
              this.props.orderObj
            )
          }>
          <div className="sales-table-td-text">
            {currency}
            {getIntToFloat(grand_total)}
            {" (" + getBilangualeText(payment_method) + ")"}
          </div>
        </td>
        <td
          className="sales-table-td"
          onClick={(e) =>
            this._handleOrderDetails(
              e,
              this.props.orderObj.orderid,
              this.props.orderObj
            )
          }>
          <div className="sales-table-td-text">{quantity}</div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text capitalize">
            <span className="card-badge">
              {
                _.find(this.statusArr, {
                  name: this.props.orderObj.product.currentstatus.status,
                }).nametoshow
              }{" "}
            </span>
          </div>
          <div className="sales-table-td-text capitalize">
            <span
              className={classNames(
                sales_status.length > 0 ? "card-badge" : "sr-only",
                ""
              )}>
              {sales_status}
            </span>
          </div>
          {/* {new code} */}
          {this.props.orderObj &&
          this.props.orderObj.hasOwnProperty("linkedorder") ? (
            <button
              className="btn-primary mt-2"
              onClick={(e) =>
                this._handleOrderDetails(
                  e,
                  this.props.orderObj.orderid,
                  this.props.orderObj.linkedorder?.[0]
                )
              }>
              {LocaleStrings.view_linked_order}
            </button>
          ) : (
            ""
          )}
          <div className=""></div>
        </td>
      </tr>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  localCurrency: state.currency,
});

export default connect(mapStateToProps, {
  toggleNewOrderDetailsModal,
  setNewOrderOrderID,
  setNewOrderOrderOBJECT,
})(NewOrderTableList);
