import React, { Component } from "react";
import LocaleStrings from "@language";
import toast from "react-hot-toast";
import FetureUnderConstructionToast from "@toasrFeatureunavailable";
class FAQNContactus extends Component {
  constructor(props) {
    super(props);
  }

  _handelFeatureUnavailable = () => {
    toast.custom((t) => (
      <FetureUnderConstructionToast
        header={LocaleStrings.visit_again}
        subheader={LocaleStrings.feature_under_construction}
      />
    ));
  };

  // All Binded Functions
  render() {
    return (
      <div className="w-full bottom-0">
        <ul role="list" className="my-5 divide-y divide-gray-200">
          <li
            key={"need-more-key"}
            className="py-4 bg-custWhite px-6 table-hover-transition "
          >
            <div
              className="flex items-center space-x-4 cursor-pointer"
              onClick={(e) => this._handelFeatureUnavailable()}
            >
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-primary">
                  {LocaleStrings.faq_footer_head_text}
                </p>
              </div>
            </div>
          </li>
          {/* Call Us */}
          <li
            key={"call-us-key"}
            className="py-4 bg-custWhite px-6  table-hover-transition"
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  loading="lazy"
                  className="h-6 w-6"
                  src={"/images2/profile_settings_images/telephone.svg"}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-ternary mx-2">
                  {LocaleStrings.call_us} +000 254698754
                </p>
              </div>
            </div>
          </li>
          {/* Write to us */}
          <li
            key={"write-to-us-key"}
            className="py-4 bg-custWhite px-6 table-hover-transition"
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  loading="lazy"
                  className="h-6 w-6"
                  src={"/images2/profile_settings_images/envelope.svg"}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-ternary mx-2">
                  {LocaleStrings.write_to_us} support.email@gmail.com
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default FAQNContactus;
