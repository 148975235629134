import React, { Component } from "react";
import { connect } from "react-redux";
import { MailIcon } from "@heroicons/react/solid";
import LocaleStrings from "@language";
// Import BaseLoader
import BaseLoader from "@baseLoader";

class RatingsReviews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_ratings_reviews,
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    let { loaderDesc } = this.state;
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>
                <BaseLoader data={loaderDesc} />
              </>
            ) : (
              <div className="py-20 responsivePadding">
                <div className="sm:flex sm:flex-col sm:align-center">
                  <h1 className="text-3xl font-extrabold text-secondary sm:text-center">
                    {LocaleStrings.ratings_reviews}
                  </h1>
                  <p className="mt-5 text-xl text-ternary sm:text-center">
                    {LocaleStrings.rate_us_text}
                  </p>
                  <div className="relative self-center mt-6 bg-quaternary rounded-lg p-0.5 flex sm:mt-8 gap-4">
                    <span
                      type="button"
                      onClick={(e) =>
                        window.open("https://play.google.com", "_blank")
                      }
                      className="inline-flex items-center w-1/2 bg-white border-ternary rounded-md shadow-sm py-2 text-sm font-medium text-secondary whitespace-nowrap sm:w-auto xs:px-8 cursor-pointer">
                      <img
                        loading="lazy"
                        className="mx-2 h-4 w-4"
                        aria-hidden="true"
                        src="/images2/review_ratings/google-plus.png"
                        alt="google_store"
                      />
                      {LocaleStrings.google_play}
                    </span>
                    <span
                      type="button"
                      onClick={(e) =>
                        window.open(
                          "https://www.apple.com/in/app-store/",
                          "_blank"
                        )
                      }
                      className="inline-flex items-center w-1/2 bg-white border-ternary rounded-md shadow-sm py-2 text-sm font-medium text-secondary whitespace-nowrap focus:z-10 sm:w-auto sm:px-8 cursor-pointer">
                      <img
                        loading="lazy"
                        className="mx-2 h-4 w-4"
                        aria-hidden="true"
                        src="/images2/review_ratings/apple.png"
                        alt="app_store"
                      />
                      {LocaleStrings.app_store}
                    </span>
                  </div>
                </div>
                {/* Replace with your content */}
                <div className="p-auto"></div>
                {/* /End replace */}
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(RatingsReviews);
