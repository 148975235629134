import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
import { BaseComponent } from "@baseComponent";
import { classNames } from "@commonFunction";
import { fetchUsersDeatils, updateStoreUsers } from "@profileSettingsActions";
import toast from "react-hot-toast";

class OrderHandle extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaderDesc: LocaleStrings.preparing_profile_settings,
      isBtnDisable: false,
      btnLoader: false,
      order_processed_status: "self",
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { session, user } = this.props;
    this.props.fetchUsersDeatils(this.props.session, (CallBackResponse) => {
      if (
        CallBackResponse.status == 1 &&
        CallBackResponse.data &&
        CallBackResponse.data.length > 0
      ) {
        this.setState({
          order_processed_status: CallBackResponse.data[0].order_processedby,
        });
      }
    });
  }

  onFormSubmit(values) {
    let { session, user } = this.props;
    let { order_processed_status } = this.state;
    this.setState({ isBtnDisable: true, btnLoader: true });
    let obj = {};
    obj.userid = session.userid;
    obj.order_processedby = order_processed_status;

    this.props.updateStoreUsers(session, obj, (callback) => {
      if (callback.status == 1) {
        this.props.fetchUsersDeatils(this.props.session, (CallBackResponse) => {
          if (
            CallBackResponse.status == 1 &&
            CallBackResponse.data &&
            CallBackResponse.data.length > 0
          ) {
            this.setState({ isBtnDisable: false, btnLoader: false });
            toast.success("Updated Successfully");
            this.setState({
              order_processed_status:
                CallBackResponse.data[0].order_processedby,
            });
          }
        });
      } else {
        toast.error("Something Went Wrong");
      }
    });
  }

  order_processedbyChange = (e) => {
    this.setState({ order_processed_status: e.target.value });
  };

  render() {
    let { handleSubmit, initialValues } = this.props;
    return (
      <>
        <CommonHeaderProfileSettings commonHeaderText="Order Handle" />
        <form
          className="pt-4 px-16"
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
          <div className="mr-5 text-sm text-secondary my-4 text-lg text-semibold">
            Order to be handled by
          </div>
          <div style={{ display: "flex" }}>
            <Field
              name="order_processedby"
              label="Self"
              component={this.renderFieldRadio}
              type="radio"
              value="self"
              className="mx-2"
              onChange={this.order_processedbyChange.bind(this)}
            />
            <Field
              name="order_processedby"
              label="By Shopez"
              component={this.renderFieldRadio}
              type="radio"
              value="sa"
              className="mx-2"
              onChange={this.order_processedbyChange.bind(this)}
            />
          </div>

          <button
            type="submit"
            id="uploadproductformbtn"
            disabled={this.state.isBtnDisable}
            className={classNames(
              this.state.isBtnDisable ? "cursor-not-allowed" : "",
              "btn-primary mt-8 "
            )}
            style={{ width: "120px" }}>
            <svg
              className={classNames(
                this.state.btnLoader ? "" : "hidden",
                "animate-spin-medium h-5 w-5 rounded-full mx-2"
              )}
              viewBox="0 0 1024 1024"
              focusable="false"
              data-icon="loading"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
            </svg>
            Update
          </button>
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    initialValues:
      state.vendorDetails && state.vendorDetails.data
        ? state.vendorDetails.data
        : {},
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
  updateStoreUsers,
})(
  reduxForm({
    form: "OrderHandleForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(OrderHandle)
);
