import {
  DISCOUNTMANAGEMENT_MASTER,
  DISCOUNTMANAGEMENT_PRODUCTCATEGORY,
  DISCOUNTMANAGEMENT_CRITERIAMASTER,
  DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY,
  DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION,
  DISCOUNTMANAGEMENT_PRODUCTLIST,
  IS_DISCOUNTMANAGEMENT_MODAL_OPEN,
  DISCOUNTMANAGEMENT_MASTER_SINGLE,
  DISCOUNTMANAGEMENT_ALLPRODUCTLIST,
} from "../actions/index";

/**** Fetch Discount Management Master *****/
export var DiscountMasterStore = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_MASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Get Categories, Subcategories, Criteria,  Discount Area ****/

// PRODUCT BY CATEGORY SUBCATEGORY
export var ProductByCateSubCate = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_PRODUCTLIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// PRODUCT CATEGORY
export var ProductCategory = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_PRODUCTCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var ProductListdiscount = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_ALLPRODUCTLIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// PRODUCT SUBCATEGORY
export var ProductSubCategory = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_PRODUCTSUBCATEGORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// CRITERIA MASTER
export var CriteriaMaster = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_CRITERIAMASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// DISCOUNT AREA SELECTION
export var DiscountArea = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNTMANAGEMENT_DISCOUNTAREASELECTION:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Store IS modal Open Or not As Discount Management  ****/
export var isDiscManageModalopen = (state = false, action) => {
  if (action.type === IS_DISCOUNTMANAGEMENT_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** Store Single Discount Object ****/
export var singleDiscountObject = (state = false, action) => {
  if (action.type === DISCOUNTMANAGEMENT_MASTER_SINGLE) {
    state = action.payload;
  }

  return state;
};
