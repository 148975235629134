import {
  getRequest,
  postRequest,
  deleteRequest,
  patchRequest,
} from "@networkCall";

import { INSTANCE_URL, itemCount } from "@constant";

export var FAQ_LIST = "FAQ_LIST";

// Modal
export const IS_RAISETICKET_MODAL_OPEN = "IS_RAISETICKET_MODAL_OPEN";

/**** Is Open Raise Ticket modal ****/
export function isRaiseTicketModalopen(isOpen) {
  return { type: IS_RAISETICKET_MODAL_OPEN, payload: isOpen };
}

/*** FAQ List ***/
export function fetchFaq(session, pageCount = 0, callback) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${perPage}&offset=${offset}`;

  var orderby = `createdon%20DESC`;
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/faq?include_count=true&filter=(faqfor=vendor)&${offSetQuery}&order=${orderby}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: FAQ_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

/*** FAQ List With Search ***/
export function searchFaq(session, search = "", pageCount = 0) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `&limit=${itemCount}&offset=${offset}`;
  var searchQuery = `(question_en like %${search}%)OR(question_ar like %${search}%)`;
  var filter = encodeURI(`(${searchQuery})`);

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/faq?include_count=true&filter=(${filter})&order=createdon%20DESC${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: FAQ_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
      },
      (error) => {}
    );
  };
}
