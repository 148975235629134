import React, { Component } from "react";

class NoDataFoundComponet extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  render() {
    return (
      <div className="grid grid-cols-6 gap-4 pt-6">
        <div className="col-start-2 col-span-4 ... " align="center">
          <span className="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100">
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        </div>
        <div className="col-start-2 col-span-4 ... " align="center">
          <p className=" text-sm font-medium text-ternary truncate mx-2">
            {this.props.text}
          </p>
        </div>
        <div className="col-start-2 col-span-4 ... " align="center">
          <p className=" text-sm font-medium text-ternary truncate mx-2">
            {this.props.subtext}
          </p>
        </div>
      </div>
    );
  }
}

export default NoDataFoundComponet;
