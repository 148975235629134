import {
  ArrowCircleUpIcon,
  ArrowCircleDownIcon,
  DotsHorizontalIcon,
} from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "@language";
import { Transition, Menu } from "@headlessui/react";
import { confirmAlert } from "react-confirm-alert";
// Common function
import { classNames } from "@commonFunction";
// Constants
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  DATE_FORMAT_TO_SHOW_SHORT,
} from "@constant";

// Import Component
import SingleImageModal from "./single-image-modal";
import ReportAbuse from "@reportAbuse";
// Import Actions
import { openReportModal } from "@profileSettingsActions";

const contentStyle = {
  // height: "250px",
  // width: "250px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  borderRadius: "18px",
};

function ReviewAndRatingLimited(props) {
  const [showReviewList, setShowReviewList] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState("");

  let { productDetails, limit, callFrom, wholeObject, reportAbuseModal } =
    props;
  let sortedData = [];

  let purchasedbyId = "";
  let purchasedbyName = "";
  if (callFrom === "order") {
    purchasedbyId = wholeObject.purchasedby.userid;
    purchasedbyName = wholeObject.purchasedby.username;
  }

  useEffect(() => {
    return () => {
      setShowReviewList(false);
      setisOpen(false);
      setCurrentImg("");
    };
  }, []);

  // Average rating
  let avgRating =
    productDetails && productDetails.ratings && productDetails.ratings.avgrating
      ? productDetails.ratings.avgrating
      : 0;
  let remRating = 5 - avgRating;

  if (
    productDetails &&
    productDetails.reviews &&
    productDetails.reviews.length > 0
  ) {
    // Short data by created date

    if (callFrom === "order") {
      // Order details page
      sortedData = _.filter(
        _.orderBy(productDetails.reviews, ["createdat"], ["desc"]),
        { ratedby: purchasedbyId }
      );
    } else {
      // Product details page
      sortedData = _.orderBy(productDetails.reviews, ["createdat"], ["desc"]);
    }
  }

  const _handelImgeClick = (imgUrl) => {
    setCurrentImg(imgUrl);
    setisOpen(true);
  };

  const renderReviewImages = (item, actindex) => {
    let mediaItem = item?.media?.map((media, index) => {
      return (
        <img
          loading="lazy"
          style={contentStyle}
          className="h-24 w-24 mb-6"
          src={
            `${BASE_IMAGES_URL + "/reviews/" + media}?api_key=` + APP_API_KEY
          }
          alt={`image_${actindex}_${index}`}
          onClick={(e) =>
            _handelImgeClick(
              `${BASE_IMAGES_URL + "/reviews/" + media}?api_key=` + APP_API_KEY
            )
          }></img>
      );
    });

    return mediaItem;
  };

  /* Report abuse on reviews [feed backreported by] */
  const reportAbusive = (userid) => {
    let { isLoggedIn } = props;
    props.openReportModal(true, userid);
    // removed confirm alert latest
    // if (isLoggedIn) {
    //   confirmAlert({
    //     title: LocaleStrings.report_confirm_review_title,
    //     message: LocaleStrings.report_confirm_review_message,
    //     buttons: [
    //       {
    //         label: LocaleStrings.button_yes,
    //         onClick: () => {
    //           props.openReportModal(true, userid);
    //         },
    //       },
    //       {
    //         label: LocaleStrings.button_no,
    //         onClick: () => {},
    //       },
    //     ],
    //   });
    // }
  };

  /* Review list */
  const ReviewList = (item, index) => {
    var userimg = `${BASE_IMAGES_URL}/users/${
      callFrom === "order" ? item.ratedby : item.ratedby.userid
    }.jpg?api_key=${APP_API_KEY}`;

    return (
      <>
        <div className="pb-6">
          {/* Rated By */}
          <div className="flex justify-between">
            <div className="flex">
              {item.ratedby ? (
                <div>
                  <img
                    loading="lazy"
                    src={userimg}
                    className="user-img-rounded"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/images2/common/user.png";
                    }}
                    alt=""
                  />
                </div>
              ) : (
                <div className="bg-delivery rounded-full w-46 h-46"></div>
              )}
              <div className="pl-2 align-top">
                {/* If "feedback_anonymous" => true show "anonymous" otherways show "username" */}
                <div className="text-sm font-medium text-secondary truncate mx-2">
                  {callFrom === "order"
                    ? item.ratedby
                      ? item.feedback_anonymous === 1 ||
                        item.feedback_anonymous === "1"
                        ? LocaleStrings.anonymous
                        : purchasedbyName
                      : ""
                    : item.ratedby && item.ratedby.username
                    ? item.feedback_anonymous === 1 ||
                      item.feedback_anonymous === "1"
                      ? LocaleStrings.anonymous
                      : item.ratedby.username
                    : ""}
                </div>
                <div className="text-sm text-ternary truncate">
                  {moment(item.createdat).format(DATE_FORMAT_TO_SHOW_SHORT)}
                </div>
              </div>
            </div>

            {/* Note:- If manual feedback is present than only show item.feedback */}
            <div
              className={classNames(
                item.feedback ? "" : "hidden",
                "flex justify-end items-end"
              )}>
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <div className="">
                      <Menu.Button className="btn-card">
                        <span className="sr-only">Open options</span>
                        <DotsHorizontalIcon
                          className="h-5 w-5 hover:shadow"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-148 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50">
                        <div className="py-1 divide-y z-50 w-40">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={
                                  () =>
                                    item.reportabuse === 1
                                      ? toast.error(
                                          LocaleStrings.already_reported
                                        )
                                      : reportAbusive(item.id) // report on Question id
                                }
                                className={classNames(
                                  active ? "bg-quaternary " : " ",
                                  "block px-4 py-2 text-sm text-primary"
                                )}>
                                {LocaleStrings.report_as_abusive}
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>

          {/* Types of rating */}
          {callFrom === "order" ? (
            <>
              {/* PRODUCT RATING */}
              <div className="my-4">
                <p className="text-xs font-medium text-secondary mt-2">
                  {LocaleStrings.review_and_rating_text1}
                </p>
                <div className="flex pt-2">
                  {item.productrating && item.productrating > 0
                    ? _.times(item.productrating, (i) => {
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/product_management_images/rating-review.svg"
                              className="w-5"
                              alt=""
                            />
                          </div>
                        );
                      })
                    : ""}

                  {item.productrating &&
                  item.productrating > 0 &&
                  5 - item.productrating > 0
                    ? _.times(5 - item.productrating, (i) => {
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/common/graystar.svg"
                              className="w-5"
                              alt=""
                            />
                          </div>
                        );
                      })
                    : item.productrating && item.productrating === 0
                    ? notRatedStarComponet(5)
                    : null}
                </div>
              </div>
              {/* 2. How accurate was the product description?  */}
              <div className="my-4">
                <p className="text-xs font-medium text-secondary mt-2">
                  {LocaleStrings.review_and_rating_text2}
                </p>
                <div className="flex pt-2">
                  {item.item_as_described_rating &&
                  item.item_as_described_rating > 0
                    ? _.times(item.item_as_described_rating, (i) => {
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/product_management_images/rating-review.svg"
                              className="w-5"
                              alt=""
                            />
                          </div>
                        );
                      })
                    : _.times(5, (i) => {
                        // If rating is '0' show grey star 5 times ['Not rated yet'] with
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/common/graystar.svg"
                              className="w-5"
                              alt=""
                            />
                          </div>
                        );
                      })}

                  {item.item_as_described_rating &&
                  item.item_as_described_rating > 0 &&
                  5 - item.item_as_described_rating > 0
                    ? _.times(5 - item.item_as_described_rating, (i) => {
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/common/graystar.svg"
                              className="w-5"
                              alt=""
                            />
                          </div>
                        );
                      })
                    : item.item_as_described_rating &&
                      item.item_as_described_rating === 0
                    ? notRatedStarComponet(5)
                    : null}
                </div>
              </div>
              {/* 3. How satisfied were you with the selle's Communication? */}
              <div className="my-4">
                <p className="text-xs font-medium text-secondary mt-2">
                  {LocaleStrings.review_and_rating_text3}
                </p>
                <div className="flex pt-2">
                  {item.communication_rating && item.communication_rating > 0
                    ? _.times(item.communication_rating, (i) => {
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/product_management_images/rating-review.svg"
                              className="w-5"
                              alt="rating-img"
                            />
                          </div>
                        );
                      })
                    : ""}

                  {item.communication_rating &&
                  item.communication_rating > 0 &&
                  5 - item.communication_rating > 0
                    ? _.times(5 - item.communication_rating, (i) => {
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/common/graystar.svg"
                              className="w-5"
                              alt=""
                            />
                          </div>
                        );
                      })
                    : item.communication_rating &&
                      item.communication_rating === 0
                    ? notRatedStarComponet(5)
                    : null}
                </div>
              </div>
              {/* 4.How quickly did the seller ship the item? */}
              <div className="my-4">
                <p className="text-xs font-medium text-secondary mt-2">
                  {LocaleStrings.review_and_rating_text4}
                </p>
                <div className="flex pt-2">
                  {item.shipping_time_rating && item.shipping_time_rating > 0
                    ? _.times(item.shipping_time_rating, (i) => {
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/product_management_images/rating-review.svg"
                              className="w-5"
                              alt=""
                            />
                          </div>
                        );
                      })
                    : ""}

                  {item.shipping_time_rating &&
                  item.shipping_time_rating > 0 &&
                  5 - item.shipping_time_rating > 0
                    ? _.times(5 - item.shipping_time_rating, (i) => {
                        return (
                          <div className="pr-3">
                            <img
                              loading="lazy"
                              src="/images2/common/graystar.svg"
                              className="w-5"
                              alt=""
                            />
                          </div>
                        );
                      })
                    : item.shipping_time_rating &&
                      item.shipping_time_rating === 0
                    ? notRatedStarComponet(5)
                    : null}
                </div>
              </div>
            </>
          ) : (
            <div className="flex pt-2">
              {item.productrating && item.productrating > 0
                ? _.times(item.productrating, (i) => {
                    return (
                      <div className="pr-3">
                        <img
                          loading="lazy"
                          src="/images2/product_management_images/rating-review.svg"
                          className="w-5"
                        />
                      </div>
                    );
                  })
                : ""}

              {item.productrating &&
              item.productrating > 0 &&
              5 - item.productrating > 0
                ? _.times(5 - item.productrating, (i) => {
                    return (
                      <div className="pr-3">
                        <img
                          loading="lazy"
                          src="/images2/common/graystar.svg"
                          className="w-5"
                          alt=""
                        />
                      </div>
                    );
                  })
                : item.productrating && item.productrating === 0
                ? notRatedStarComponet(5)
                : null}
            </div>
          )}

          {/* If feedback exist */}
          {item.feedback ? (
            <>
              {" "}
              <div className="text-sm font-semibold text-secondary truncate pt-2">
                {LocaleStrings.reviews}
              </div>
              <div className="text-xs font-medium text-secondary ">
                {item.feedback}
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <div
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => setisOpen(true)}>
          {renderReviewImages(item, index)}
        </div>
      </>
    );
  };

  /* Progress Bar */
  const ProgressBar = (rating, total) => {
    let progressPercentage = (rating / total) * 100;

    return (
      <div className="h-2 w-full bg-ternaryHover rounded-md">
        {/* {progressPercentage > 0 ? ( */}
        <div
          style={{ width: "150px" }}
          className={`h-full rounded-md ${
            progressPercentage > 30
              ? "bg-green-500"
              : progressPercentage > 20 && progressPercentage < 30
              ? "bg-red-500"
              : "bg-orange-500"
          }`}></div>
        {/* ) : (
              ""
            )} */}
      </div>
    );
  };

  /* Toggle Less & more review box list */
  const _reviewListStateBoxToggle = () => {
    setShowReviewList(!showReviewList);
  };

  /* Ordere Ratings */
  const OrderRating = (productDetails) => {
    let remRating = 5;

    let customRatingsObject = {
      avgrating: 0,
      rating1: 0,
      rating2: 0,
      rating3: 0,
      rating4: 0,
      rating5: 0,
      totalratings: 0,
      totalreviews: 0,
    };

    if (productDetails && productDetails.reviews) {
      let counter = 0;
      let avgRating = 0;
      let totalRating = 0;

      _.forEach(
        _.filter(productDetails.reviews, {
          ratedby: purchasedbyId,
        }),
        // productDetails.reviews
        (reviews, index) => {
          customRatingsObject["rating" + parseInt(reviews.productrating)] =
            parseInt(reviews.productrating);

          totalRating = totalRating + parseInt(reviews.productrating);

          counter++;
        }
      );

      // Avg Rating
      avgRating = counter != 0 ? totalRating / counter : avgRating;
      remRating = 5 - avgRating;

      customRatingsObject.avgrating = avgRating;
      customRatingsObject.totalratings = counter;
      customRatingsObject.totalreviews = counter;

      return (
        <>
          {/* FEEDBACK  */}

          {sortedData.length > 0 ? (
            <>
              {/* Show limited Review */}
              <div className="text-left pt-2">
                {sortedData.slice(0, limit).map((item, index) => {
                  return ReviewList(item, index);
                })}
              </div>

              {/* Show data if review list length > limit   */}
              {sortedData.length > limit ? (
                <>
                  {/* More and less for review  Button*/}
                  <div className="grid grid-cols-2 mt-2">
                    <div>{LocaleStrings.prooduct_reviews_rating}</div>
                    <div className="end-item ">
                      {showReviewList ? (
                        <ArrowCircleUpIcon
                          className="h-6 w-6 cursor-pointer mt-1"
                          aria-hidden="true"
                          onClick={_reviewListStateBoxToggle}
                        />
                      ) : (
                        <ArrowCircleDownIcon
                          className="h-6 w-6 cursor-pointer mt-1"
                          aria-hidden="true"
                          onClick={_reviewListStateBoxToggle}
                        />
                      )}
                    </div>
                  </div>

                  {/* Show full Review  List if show button open*/}
                  {sortedData.length > 0 ? (
                    <div
                      className={classNames(
                        showReviewList ? "" : "hidden",
                        "text-left pt-10"
                      )}>
                      {/* Show full review list */}
                      {sortedData.map((item, index) => {
                        return ReviewList(item, index);
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      );
    } else {
      return "";
    }
  };

  /* If not rated yet show start */
  const notRatedStarComponet = (times = 5) => {
    let Item = _.times(times, (i) => {
      // If rating is '0' show grey star 5 times ['Not rated yet'] with
      return (
        <div className="pr-3">
          <img
            loading="lazy"
            src="/images2/common/graystar.svg"
            className="w-5"
            alt=""
          />
        </div>
      );
    });
    return Item;
  };

  return (
    <>
      {/* ACTUAL REVIEW GRAPH */}
      {productDetails.saletype != "auction" ? (
        <>
          <div className="text-base font-semibold text-primary mt-4">
            {LocaleStrings.reviews}
          </div>
          {_.size(productDetails.reviews) === 0 ? (
            <p className="mt-1 text-xs text-ternary">
              {LocaleStrings.no_reviews}
            </p>
          ) : null}
        </>
      ) : (
        ""
      )}

      {/* RATING RELATED STUFFES */}

      {callFrom === "order" ? (
        OrderRating(productDetails)
      ) : (
        <>
          <div className="text-left pt-2.5 flex">
            {avgRating && avgRating > 0
              ? _.times(avgRating, (i) => {
                  return (
                    <div className="pr-3">
                      <img
                        loading="lazy"
                        src="/images2/product_management_images/rating-review.svg"
                      />
                    </div>
                  );
                })
              : ""}

            {avgRating && avgRating > 0 && remRating > 0
              ? _.times(remRating, (i) => {
                  return (
                    <div className="pr-3">
                      <img loading="lazy" src="/images2/common/graystar.svg" />
                    </div>
                  );
                })
              : ""}

            {avgRating && avgRating > 0 ? (
              <div className="px-2 text-primary text-base font-medium">
                {avgRating} {LocaleStrings.out_of_five}
              </div>
            ) : (
              ""
            )}
          </div>
          {productDetails &&
          productDetails.ratings &&
          productDetails.ratings.totalratings &&
          productDetails.ratings.totalreviews ? (
            <div className="text-left pt-3.5 flex text-sm text-cardfooter">
              {productDetails.ratings.totalratings} {LocaleStrings.rating_and}{" "}
              {productDetails.ratings.totalreviews} {LocaleStrings.reviews}
            </div>
          ) : (
            ""
          )}
          {productDetails &&
          productDetails.ratings &&
          productDetails.ratings.totalratings > 0
            ? _.times(5, (i) => {
                return (
                  <div className="text-left pt-3.5 flex">
                    <div>
                      <img
                        loading="lazy"
                        src="/images2/common/graystar.svg"
                        className="w-6"
                      />
                    </div>
                    <div className="px-2 text-primary text-sm">
                      {5 - i} {LocaleStrings.star}
                    </div>
                    <div className="pl-2 w-240 xl:w-292 lg:w-292 md:w-292 flex items-center">
                      {productDetails.ratings
                        ? ProgressBar(
                            productDetails.ratings[`rating${5 - i}`],
                            productDetails.ratings.totalratings
                          )
                        : "rrrr"}
                    </div>
                    <div className="pl-2 text-cardfooter text-sm">
                      {productDetails.ratings
                        ? productDetails.ratings[`rating${5 - i}`]
                        : 0}
                    </div>
                  </div>
                );
              })
            : ""}

          {/* REVIEW COMMENT LIST WITH STARTS   */}

          {sortedData.length > 0 ? (
            <>
              {/* Show limited Review */}
              <div className="text-left pt-10">
                {sortedData.slice(0, limit).map((item, index) => {
                  return ReviewList(item, index);
                })}
              </div>

              {/* Show data if review list length > limit   */}
              {sortedData.length > limit ? (
                <>
                  {/* More and less for review  Button*/}
                  <div className="grid grid-cols-2 mt-2">
                    <div>{LocaleStrings.prooduct_reviews_rating}</div>
                    <div className="end-item ">
                      {showReviewList ? (
                        <ArrowCircleUpIcon
                          className="h-6 w-6 cursor-pointer mt-1"
                          aria-hidden="true"
                          onClick={_reviewListStateBoxToggle}
                        />
                      ) : (
                        <ArrowCircleDownIcon
                          className="h-6 w-6 cursor-pointer mt-1"
                          aria-hidden="true"
                          onClick={_reviewListStateBoxToggle}
                        />
                      )}
                    </div>
                  </div>

                  {/* Show full Review  List if show button open*/}
                  {sortedData.length > 0 ? (
                    <div
                      className={classNames(
                        showReviewList ? "" : "hidden",
                        "text-left pt-10"
                      )}>
                      {/* Show full review list */}
                      {sortedData.map((item, index) => {
                        return ReviewList(item, index);
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}

      <SingleImageModal
        isOpen={isOpen}
        setisOpen={setisOpen}
        currentImg={currentImg}
        setCurrentImg={setCurrentImg}
      />
      {reportAbuseModal ? <ReportAbuse type="review" /> : ""}
    </>
  );
}

function mapStateToProps(state) {
  var { session } = state;

  return {
    session,
    isLoggedIn: state.isLoggedIn,
    reportAbuseModal: state.reportAbuseModal,
  };
}

export default connect(mapStateToProps, { openReportModal })(
  ReviewAndRatingLimited
);
