import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import _ from "lodash";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import toast from "react-hot-toast";
import Dropzone from "react-dropzone";
import moment from "moment";

// Import constant
import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";

// Import Actions
import {
  uploadMediaLargeProfileInfo,
  updateStoreUsers,
  fetchUsersDeatils,
  selectedProfileSettingsMenu,
  isProfileinfoCommonOpen,
  isPrflInfoVerifyEmailModalOpen,
  verifyEmailid,
  removeprofileImage,
} from "@profileSettingsActions";
import { verifyemailProfilesettings } from "@mainActions";
// Functions Import
import { classNames, isEmpty, emptyCache } from "@commonFunction";

// Component Import
import ProfileInfoCommonModal from "./profile-info-common-modal.js";

// Constant

class ProfileInformationEdit extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      verifybtnLoader: false,
      fileUrl: "",
      fileArrNew: {},
      imageLoader: false,
      userName: "",
      userInputShow: false,
      dynaicHeaderText: "",
      dynaicFooterText: "",
      callFromState: "",
      selectedGender: "",
      stateBusinessName: "",
      removedpic: false,
    };

    // All Binded Functions
  }

  componentDidMount() {}

  /**** Toggle User Name Input  ****/
  toggleUserInput(e) {
    let { userInputShow } = this.state;
    this.setState({ userInputShow: userInputShow ? false : true });
  }

  /**** Save ****/
  saveUserInput(e) {
    let username = document.getElementsByName("username");
    if (username[0].value != "" && username[0].value != null) {
      this.setState({ userName: username[0].value });
      this.toggleUserInput();
    }
  }

  // Add File
  onDrop = async (files) => {
    this.setState({ imageLoader: true });

    let file = files[0];
    let filearr = [];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      file.url = e.target.result;
      filearr.push(file);
      this.setState({ imageLoader: false });
    };

    this.setState({ fileArrNew: filearr });
    this.setState({ fileUrl: URL.createObjectURL(file) });
  };

  /**** Open Data with Modal ****/
  onProfileInfoMenuSelect(e, callfor) {
    if (callfor === "list-gender-key") {
      this.setState({ dynaicHeaderText: LocaleStrings.gender });
      this.setState({ dynaicFooterText: LocaleStrings.done });
    } else if (callfor === "list-phone-key") {
      this.setState({ dynaicHeaderText: LocaleStrings.update_phone_number });
      this.setState({ dynaicFooterText: LocaleStrings.update_phone_number });
    } else if (callfor === "list-pass-key") {
      this.setState({ dynaicHeaderText: LocaleStrings.update_password });
      this.setState({ dynaicFooterText: LocaleStrings.update_password });
    }
    this.setState({ callFromState: callfor });
    this.props.isProfileinfoCommonOpen(true);
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ btnLoader: true });

    let { fileArrNew } = this.state;
    let { storeSelectedGender } = this.props;

    let object = {
      username: values.username,
      businessname: values.businessname,
      userid: values.userid,
      gender: storeSelectedGender,
    };

    if (Object.keys(fileArrNew).length === 0) {
      // With out image
      // toast.error(LocaleStrings.attachfile_to_update_error_text);
      // After file upload
      if (this.state.removedpic) {
        object.userimage = null;
      }
      this.props.updateStoreUsers(this.props.session, object, (callback) => {
        if (callback.status === 1) {
          this.setState({ btnLoader: false });
          this.setState({ userInputShow: false });
          toast.success(LocaleStrings.profile_updated_successfully);

          // Fetch Vendor Details With LoginIdStore
          this.props.fetchUsersDeatils(
            this.props.session,
            (callBackResponce) => {}
          );
        }
      });
    } else {
      // Upload Section
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];
      var medianame = parseInt(this.props.session.userid) + "." + "jpg"; // Image Name Creation
      // Blank File Nmae array

      _.forEach(fileArrNew, function (value, index) {
        if (Object.keys(value).length > 0) {
          let coverJson = {
            type: "image",
            name: medianame,
            media: value.url,
          };
          filetobeupload.push(coverJson); //
        }
      });

      let counter = filetobeupload.length;

      // Store File to server first
      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadMediaLargeProfileInfo(
              this.props.session,
              item,
              (response) => {
                if (response.success === 0) {
                  resolve();
                } else {
                  uploaderCounter++;
                  resolve();
                }
              }
            );
          })
        );
      });

      Promise.all(arrPromise).then(() => {
        if (uploaderCounter === counter) {
          // After file upload
          this.props.updateStoreUsers(
            this.props.session,
            object,
            (callback) => {
              if (callback.status === 1) {
                this.setState({ btnLoader: false });
                this.setState({ fileArrNew: {} });
                this.setState({ fileUrl: "" });
                this.setState({ removedpic: false });
                this.setState({ userInputShow: false });
                toast.success(LocaleStrings.profile_updated_successfully);

                // Fetch Vendor Details With LoginIdStore
                this.props.fetchUsersDeatils(
                  this.props.session,
                  (callBackResponce) => {
                    if (callBackResponce.status === 1) {
                      // success code
                    }
                  }
                );
              }
            }
          );
        } else {
          toast.error(LocaleStrings.something_went_wrong);
        }
      });
    }
  }

  showVerifyEmailProflInfoModal = () => {
    // Trigger modal open
    this.props.verifyemailProfilesettings(true);
    this.props.isPrflInfoVerifyEmailModalOpen(true);
  };
  verifyEmailid = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ verifybtnLoader: true });
    this.props.verifyEmailid(this.props.session, (callBack) => {
      this.setState({ verifybtnLoader: false });
      if (callBack.success === 1) {
        toast.success(LocaleStrings.profile_verify_email);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
    // Trigger modal open
    // this.props.verifyEmailid(true);
  };

  removeProfileImg = (e) => {
    if (isEmpty(this.state.fileArrNew)) {
      this.props.removeprofileImage(this.props.session);
    }
    this.setState({ fileUrl: "", fileArrNew: {}, removedpic: true });
  };

  render() {
    let { handleSubmit, vendorDetails, storeSelectedGender, user, session } =
      this.props;

    let { fileUrl, userName, userInputShow, selectedGender } = this.state;
    let coverimage = "";

    if (userName === "" || userName === null) {
      userName = vendorDetails.username;
    }

    if (selectedGender === "" || selectedGender === null) {
      selectedGender = storeSelectedGender;
    }

    if (storeSelectedGender === "male") {
      storeSelectedGender = LocaleStrings.male;
    } else if (storeSelectedGender === "female") {
      storeSelectedGender = LocaleStrings.female;
    } else {
      storeSelectedGender = LocaleStrings.rather_not_say;
    }

    // Profile Image
    if (isEmpty(this.state.fileArrNew)) {
      if (!this.state.removedpic) {
        coverimage = `${BASE_IMAGES_URL}/users/${
          vendorDetails.userid
        }.jpg?api_key=${APP_API_KEY}&t=${moment()}`;
      }
    } else {
      coverimage = fileUrl;
    }

    return (
      <>
        <div className=" xs:px-2 sm:px-10 2xl:px-48">
          {/* Body */}
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
              {/* Upper Part */}
              <div className="max-w-7xl mx-auto py-2 px-4 text-center sm:px-6 lg:px-8 lg:py-2">
                <div className="space-y-5">
                  <div className="space-y-2 sm:mx-auto sm:max-w-xl sm:space-y-2 lg:max-w-5xl">
                    {/* Image Upload Start */}
                    <div className="dropzone-round" key={`drop`}>
                      <div className="img_wrpcover relative">
                        <img
                          loading="lazy"
                          src={coverimage}
                          className="mx-auto h-20 w-20 rounded-full md:w-40 md:h-40"
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images2/common/user.png";
                          }}
                        />
                      </div>

                      <Dropzone
                        onDrop={(e) => this.onDrop(e)}
                        accept="image/jpeg, image/png ,image.jpg"
                        multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="h-20 w-20 md:w-40 md:h-40 absolute  cursor-pointer md:top-28 top-36"
                            align="center">
                            <input {...getInputProps()} />
                            {this.state.imageLoader ? (
                              <svg
                                className={classNames(
                                  this.state.imageLoader ? "" : "hidden",
                                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                )}
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true">
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                            ) : (
                              <p className="paragraph-seconday text-xs pt-4 md:pt-10 ">
                                {coverimage == ""
                                  ? LocaleStrings.drop_or_select
                                  : ""}{" "}
                              </p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {coverimage !== "" ? (
                      <div
                        className="text-red-600 pointer cursor-pointer"
                        onClick={() => this.removeProfileImg()}>
                        Remove
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Image Upload Ends */}
                    {userInputShow ? (
                      <div className="grid grid-cols-6 gap-4">
                        <div className="col-start-3 col-span-2 mt-1">
                          <Field
                            name="username"
                            placeholder={LocaleStrings.name}
                            type="text"
                            component={this.renderFieldText}
                            mandatory="false"
                            maxlength={30}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-medium text-secondary"
                          />
                        </div>
                        <div className="flex mt-2">
                          <div className="mx-1 flex flex-col">
                            <span onClick={(e) => this.saveUserInput(e)}>
                              <svg
                                className="h-6 w-6 text-success cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="var(--color-green)">
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </span>
                          </div>
                          <div className="mx-1 flex flex-col">
                            <span onClick={(e) => this.toggleUserInput(e)}>
                              <svg
                                className="h-6 w-6 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="var(--color-red)">
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex mt-2 justify-center">
                        {/* [NOTE USER.VERIFIED {TRUE / FALSE} SHOW HIDE DIVE] img right sign*/}
                        {/* Latest Changes for gold tick */}
                        {vendorDetails.isgold == true ? (
                          <img
                            loading="lazy"
                            className="h-4 w-4 mt-1.5"
                            src={
                              "/images2/profile_settings_images/rightcircle-yellow.svg"
                            }
                          />
                        ) : (
                          <img
                            loading="lazy"
                            className={classNames(
                              user.verified && user.usertype !== "personal"
                                ? ""
                                : "hidden",
                              "h-4 w-4 mt-1.5"
                            )}
                            src={
                              "/images2/profile_settings_images/rightcircle.svg"
                            }
                          />
                        )}
                        <div className="mx-1 flex flex-col">
                          <p className="text-xl text-custblack">{userName}</p>
                        </div>
                        <img
                          loading="lazy"
                          className="h-4 w-4 mt-1.5 cursor-pointer"
                          src={"/images2/profile_settings_images/pencil.svg"}
                          onClick={(e) => this.toggleUserInput(e)}
                        />
                      </div>
                    )}

                    <div className="flex mt-2 justify-center">
                      <img
                        loading="lazy"
                        className="h-4 w-4"
                        src={
                          "/images2/product_management_images/rating-review.svg"
                        }
                      />
                      <div className="mx-1 flex flex-col">
                        <p className="text-sm font-medium text-ternary">
                          {LocaleStrings.no_rating}
                        </p>
                      </div>
                    </div>
                    <div className="mx-1">
                      <p className="text-sm font-medium text-danger">
                        {!vendorDetails?.isactive
                          ? LocaleStrings.account_temporarily_unavailable
                          : ""}
                      </p>
                    </div>
                    <p className="mt-1 text-sm text-ternary">
                      {LocaleStrings.update_phone_number_tesxt}
                    </p>
                    {/* [NOTE USER.VERIFIED {TRUE / FALSE} SHOW HIDE DIVE] */}
                    <div
                      className={classNames(
                        user.usertype === "vendor" ? "" : "hidden",
                        "grid grid-cols-6 gap-4"
                      )}>
                      <div
                        className={classNames(
                          user.verified ? "hidden" : "",
                          "col-start-3 col-span-2 ... "
                        )}
                        align="center">
                        <button
                          type="button"
                          className="btn-primary"
                          onClick={(e) =>
                            this.props.selectedProfileSettingsMenu(
                              this.props.session,
                              2
                            )
                          }>
                          {LocaleStrings.get_verified}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* List */}
              <div className="py-10 bg-quaternary">
                <div className="flow-root ">
                  <ul role="list" className="-my-5 divide-y divide-gray-200">
                    {/* Email */}
                    <li
                      key={"list-email-key"}
                      className="py-4 bg-custWhite px-4 table-hover-transition"
                      onClick={(e) => this.showVerifyEmailProflInfoModal()}
                      style={{ cursor: "pointer" }}>
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            loading="lazy"
                            className="h-6 w-6"
                            src={
                              "/images2/profile_settings_images/envelope.svg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate mx-2">
                            {vendorDetails.email}
                          </p>
                        </div>
                        {/* latest code */}
                        {user.emailverified !== true ? (
                          <div>
                            <span
                              className="btn-primary cursor-pointer mt-4"
                              onClick={(e) => this.verifyEmailid(e)}>
                              <svg
                                className={classNames(
                                  this.state.verifybtnLoader ? "" : "sr-only",
                                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                )}
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true">
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                              {LocaleStrings.verify_email_button}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                    {/* Busness Type (Company / Home business / Type) */}
                    <li
                      key={"list-type-key"}
                      className="py-4 bg-custWhite px-4 table-hover-transition">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            loading="lazy"
                            className="h-6 w-6"
                            src={
                              "/images2/profile_settings_images/profileinfo.svg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate mx-2">
                            {vendorDetails.usertype === "vendor"
                              ? LocaleStrings.company
                              : vendorDetails.usertype === "homebiz"
                              ? LocaleStrings.home_business
                              : vendorDetails.usertype === "personal"
                              ? LocaleStrings.personal_items
                              : LocaleStrings.consumer}
                          </p>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                              <Field
                                name="businessname"
                                label={
                                  vendorDetails.usertype === "vendor"
                                    ? LocaleStrings.business_name
                                    : vendorDetails.usertype === "homebiz"
                                    ? LocaleStrings.business_name
                                    : vendorDetails.usertype === "personal"
                                    ? LocaleStrings.nick_name
                                    : LocaleStrings.consumer
                                }
                                type="text"
                                component={this.renderFieldText}
                                mandatory="true"
                                isdisabled={true}
                                labelposition={LABEL_POSITION_TOP}
                                classNameLabel="text-sm font-medium text-secondary"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* gender */}
                    <li
                      key={"list-gender-key"}
                      className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
                      onClick={(e) =>
                        this.onProfileInfoMenuSelect(e, "list-gender-key")
                      }>
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            loading="lazy"
                            className="h-6 w-6"
                            src={"/images2/profile_settings_images/gender.svg"}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate capitalize mx-2">
                            {storeSelectedGender}
                          </p>
                        </div>
                        <div>
                          <img
                            loading="lazy"
                            className="h-8 transform rotate-180 px-2 "
                            src={"/images2/profile_settings_images/arrow.svg"}
                          />
                        </div>
                      </div>
                    </li>
                    {/* Add Phone Number */}
                    <li
                      key={"list-phone-key"}
                      className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
                      onClick={(e) =>
                        this.onProfileInfoMenuSelect(e, "list-phone-key")
                      }>
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            loading="lazy"
                            className="h-6 w-6"
                            src={
                              "/images2/profile_settings_images/telephone.svg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate capitalize mx-2">
                            {vendorDetails.phone !== null &&
                            vendorDetails.phone !== "null" &&
                            vendorDetails.phone !== ""
                              ? vendorDetails.phone
                              : LocaleStrings.add_phone_number}
                          </p>
                        </div>
                        <div>
                          <img
                            loading="lazy"
                            className="h-8 transform rotate-180 px-2 "
                            src={"/images2/profile_settings_images/arrow.svg"}
                          />
                        </div>
                      </div>
                    </li>
                    {/* Update Password */}
                    <li
                      key={"list-pass-key"}
                      className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
                      onClick={(e) =>
                        this.onProfileInfoMenuSelect(e, "list-pass-key")
                      }>
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            loading="lazy"
                            className="h-6 w-6"
                            src={
                              "/images2/profile_settings_images/passwordlock.svg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate capitalize mx-2">
                            {LocaleStrings.update_password}
                          </p>
                        </div>
                        <div>
                          <img
                            loading="lazy"
                            className="h-8 transform rotate-180 px-2 "
                            src={"/images2/profile_settings_images/arrow.svg"}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="p-10 space-y-6 border-t-2 border-quaternary">
                {/* Footer */}
                <div className="grid grid-cols-3 gap-4">
                  <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                    <button className="btn-primary" type="submit">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "hidden",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.update_profile}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <ProfileInfoCommonModal
          headerText={this.state.dynaicHeaderText}
          footerText={this.state.dynaicFooterText}
          callFrom={this.state.callFromState}
          genderData={selectedGender}
        />
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["username", "businessname"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  let newObj = {};

  if (state.vendorDetails)
    newObj = {
      username: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.username
          : ""
        : "",
      userid: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.userid
          : ""
        : "",
      email: state.vendorDetails
        ? state.vendorDetails.email
          ? state.vendorDetails.data.email
          : ""
        : "",
      gender: state.storeSelectedGender
        ? state.storeSelectedGender.data
          ? state.storeSelectedGender.data
          : ""
        : "",
      businessname: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.businessname
          : ""
        : "",
    };

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    vendorDetails: state.vendorDetails
      ? state.vendorDetails.data
        ? state.vendorDetails.data
        : []
      : [],
    storeSelectedGender: state.storeSelectedGender
      ? state.storeSelectedGender.data
        ? state.storeSelectedGender.data
        : ""
      : "",
    initialValues: newObj,
    isVerifyEmailPrflInfoModal: state.isVerifyEmailPrflInfoModal, // For verify email modal from profile information
  };
}

export default connect(mapStateToProps, {
  uploadMediaLargeProfileInfo,
  updateStoreUsers,
  fetchUsersDeatils,
  selectedProfileSettingsMenu,
  isProfileinfoCommonOpen,
  isPrflInfoVerifyEmailModalOpen,
  verifyEmailid,
  verifyemailProfilesettings,
  removeprofileImage,
})(
  reduxForm({
    validate,
    form: "updateProfileInfoForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ProfileInformationEdit)
);
