import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import toast, { Toaster } from "react-hot-toast";

// Import Components
import BaseLoader from "@baseLoader";
import CouponManagHead from "./couponmanaghead";
import CoupMasterTableList from "./coupmastertablelist";
import CouponsManagementModal from "./addeditcoupmana";

// Import constant
import { PERSONAL_USER_REDIRECT_TO } from "@constant";

// Import Action
import {
  fetchProductCategory,
  fetchProductSubcategory,
  fetchCriteriaMaster,
} from "../../discountsmanagement/actions/index";
import { fetchCouponMaster } from "@couponsmanagementActions";
import { camefromSalesManagemnet } from "@salesmanagementActions";

class CouponsManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_coupons_management,
    };

    // All Binded Functions
  }

  /*** Call Only When Componenet Mount 1 time) ***/
  componentDidMount() {
    let { session } = this.props;
    let { usertype } = session;

    if (usertype === "personal") {
      // IF personal user redirect him/her to Profile settings
      this.props.camefromSalesManagemnet(2);
      this.props.history.push(PERSONAL_USER_REDIRECT_TO);
    } else {
      // Get Categories
      this.props.fetchProductCategory(this.props.session, (callBack) => {});

      // Subcategories
      this.props.fetchProductSubcategory(this.props.session, (callBack) => {});

      // Criteria
      this.props.fetchCriteriaMaster(this.props.session, (callBack) => {});

      // Get Coupon Master Data
      this.props.fetchCouponMaster(this.props.session, (callBack) => {
        this.setState({ loading: false });
      });
    }
  }

  /**** Render Table Coupons master List ****/
  renderCouponsMasterList() {
    let { storedCouponsTab } = this.props;
    storedCouponsTab = storedCouponsTab ? 0 : 1;

    return _.map(
      _.filter(this.props.couponMasterStore, { active: storedCouponsTab }),
      (coupmasterObj, index) => {
        return (
          <CoupMasterTableList
            key={coupmasterObj.id}
            coupmasterObj={coupmasterObj}
            position={index}
          />
        );
      }
    );
  }

  render() {
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>
                <BaseLoader data={this.state.loaderDesc} />
              </>
            ) : (
              <div className="responsivePadding">
                <CouponManagHead />
                <div className="flex flex-col mt-6">
                  <div
                    className="-my-2 overflow-x-auto
               ">
                    <div className="py-2 align-middle inline-block min-w-full ">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="sales-table-th">
                                {LocaleStrings.coupons_thead_coupon}
                              </th>
                              <th scope="col" className="sales-table-th">
                                {LocaleStrings.coupons_thead_coupon_code}
                              </th>
                              <th scope="col" className="sales-table-th">
                                {LocaleStrings.discount_thead_valide_until}
                              </th>
                              <th scope="col" className="sales-table-th">
                                {LocaleStrings.action}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {this.renderCouponsMasterList()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <CouponsManagementModal />
            <Toaster />
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  couponMasterStore: state.couponMasterStore
    ? state.couponMasterStore.data
    : [],
  storedCouponsTab: state.storedCouponsTab,
});

export default connect(mapStateToProps, {
  fetchProductCategory,
  fetchProductSubcategory,
  fetchCriteriaMaster,
  fetchCouponMaster,
  camefromSalesManagemnet,
})(CouponsManagement);
