import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

import { SearchIcon } from "@heroicons/react/solid";

// Import Actions
import { isOpenDiscManagModal } from "../actions/index";
// Common Functions

class DiscountManagHead extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
  }

  openDiscountManagementModal() {
    this.props.isOpenDiscManagModal(true);
  }

  render() {
    return (
      <>
        {/* Header Part */}
        <div className="grid gap-4 grid-cols-1 mb-2 md:grid-cols-3 gap-3 sm:grid-cols-2 bg-custWhite rounded end-item">
          <div className="px-4 ">
            <button
              type="submit"
              className="btn-primary mt-3"
              onClick={(e) => this.openDiscountManagementModal(e)}
            >
              {LocaleStrings.add_new_discount}
            </button>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  isOpenDiscManagModal,
})(DiscountManagHead);
