import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import LocaleStrings from "@language";
import _ from "lodash";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
// Functions Import
import { classNames } from "@commonFunction";
import toast from "react-hot-toast";
import { fetchCommentList } from "@productmanagementActions";
// Import Actions
import { openQAReportModal, reportAbuse } from "@profileSettingsActions";

// Component Import

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

class ReportAbuseQa extends BaseComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  /**** Fake Close ****/
  closeModal1() {
    this.props.reset("addQaAbuseForm");
  }

  /**** Close Modal  ****/
  closeModal() {
    this.props.reset("addQaAbuseForm");
    this.props.openQAReportModal(false);
  }

  componentWillUnmount() {
    this.setState({
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
    });
    this.props.openQAReportModal(false);
    this.props.reset("addQaAbuseForm");
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    let { reportAbuseQaModal, session, productDetails } = this.props;
    this.setState({
      btnLoader: true,
      isBtnDisable: true,
    });
    let typeid = reportAbuseQaModal.typeid;
    let type = reportAbuseQaModal.type;
    values.type = type ? type : "consumer";

    if (type === "review") {
      values.reviewid = parseInt(typeid); // reviewid && review
    } else if (type === "question") {
      values.qaid = parseInt(typeid); // qaid && question
    } else if (type === "comment") {
      values.commentid = parseInt(typeid); // Comment
    } else {
      values.profileid = parseInt(typeid);
    }

    /* Submit report */
    this.props.reportAbuse(session, values, (res) => {
      this.setState({
        btnLoader: false,
        isBtnDisable: false,
      });
      if (res.success == 0) {
        toast.error(LocaleStrings.common_fail_message);
      } else {
        if (type === "comment" && productDetails?.productid) {
          this.props.fetchCommentList(
            session,
            productDetails.productid,
            (callback) => {}
          );
        }

        this.props.reset("addQaAbuseForm");
        toast.success(LocaleStrings.report_success);
      }
      this.props.openQAReportModal(false);
    });
  }

  render() {
    let { handleSubmit, reportAbuseQaModal } = this.props;
    let { isBtnDisable, btnLoader } = this.state;

    return (
      <>
        <Transition.Root
          show={reportAbuseQaModal && reportAbuseQaModal.showModal}
          as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={reportAbuseQaModal && reportAbuseQaModal.showModal}
            onClose={(e) => {
              this.closeModal1.bind(this);
            }}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center --min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-3 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {LocaleStrings.report}
                      </div>
                    </div>
                    <div className="absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}
                        ref={this.myRef}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {/* MOdal Header Ends*/}
                    <div className="p-10 space-y-8">
                      {/* Body Start */}

                      <Field
                        name={"reporttext"}
                        label={""}
                        placeholder={LocaleStrings.type_your_message}
                        mandatory="true"
                        component={this.renderFieldTextarea}
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-box-lable"
                        className="py-3 px-4 block w-full shadow-sm text-secondary border border-warm-gray-300 rounded-md"
                      />
                    </div>
                    {/* Body Ends*/}

                    <div className="p-6 border-t-2 border-quaternary mt-52">
                      {/* Footer */}
                      <button
                        type="submit"
                        disabled={isBtnDisable}
                        className={classNames(
                          isBtnDisable ? "cursor-not-allowed" : "",
                          "btn-primary"
                        )}>
                        <svg
                          className={classNames(
                            btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {LocaleStrings.report}
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ["reporttext"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    reportAbuseQaModal: state.reportAbuseQaModal,
    initialValues: {},
    productDetails: state.productDetails ? state.productDetails : [],
  };
}

export default connect(mapStateToProps, {
  openQAReportModal,
  reportAbuse,
  fetchCommentList,
})(
  reduxForm({
    validate,
    form: "addQaAbuseForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ReportAbuseQa)
);
