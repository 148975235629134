import React, { Component } from "react";
import { connect } from "react-redux";

import _ from "lodash";

// Import Components
import BaseLoader from "@baseLoader";

// Common Function
import { classNames } from "@commonFunction";

class ReportTypeList extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  componentDidMount() {}

  /**** On Profile Menu Select ****/
  onReportTypeTabSelect(href) {
    this.props.history.push("/dashboard/revenuereports/" + href);
  }

  /**** Render Profile settings Div List *****/
  renderList() {
    let { reportTypeList, session } = this.props;

    var item = _.map(this.props.reportTypeList, (valObj, index) => {
      let position = valObj.position;
      return (
        <a
          onClick={(e) => this.onReportTypeTabSelect(valObj.href)}
          key={"r-" + index}
          className=""
        >
          <li
            key={index}
            className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  loading="lazy"
                  className="h-8 w-8"
                  src={"/images2/revenue_reports/" + valObj.icon + ".svg"}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-secondary truncate mx-2">
                  {valObj.name}
                </p>
                <p className="text-sm text-ternary truncate">
                  {valObj.subname}
                </p>
              </div>
              <div>
                <img
                  loading="lazy"
                  className="h-7 transform rotate-180 px-2 "
                  src={"/images2/profile_settings_images/arrow.svg"}
                />
              </div>
            </div>
          </li>
        </a>
      );
    });

    return item;
  }

  render() {
    return (
      <>
        <div className=" xs:px-2 sm:px-10 2xl:px-48">
          <div className="flow-root mt-6">
            <ul role="list" className="-my-5 divide-y divide-gray-200">
              {this.renderList()}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    reportTypeList: state.storeReportTypeList,
  };
}

export default connect(mapStateToProps, {})(ReportTypeList);
