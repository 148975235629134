import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import _ from "lodash";
import { ProductTextLocalized, getBilangualeText } from "@commonFunction";
import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
// Import Actions
import {
  salesmanagementStatboxSelected,
  isNegotiationOpen,
  updatenegotiation,
  fetchNegotiationDetails,
} from "@salesmanagementActions";
// Import Actions [Side Bar]
import {
  NotificationSingleObject,
  makeNotificationDetailsEmpty,
} from "@sidebarActions";

// Import BaseLoader
import BaseLoader from "@baseLoader";
import BtnLoaderSvg from "@btnLoaderSvg";

// Component Import
import ChatList from "./chatlist";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const hiddenStateArr = ["negotiated", "closed", "initiated", "responded"];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Negotiate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_negotiationsoffers,
      negoSearchText: "",
      btnLoader: false,
    };

    // Refrence variable
    this.myRef = React.createRef();
    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);
    this._handleNegoStateChange = this._handleNegoStateChange.bind(this);
  }

  componentDidMount() {
    // // Call API to Fetch Data

    this._isMounted = true;
    if (this._isMounted) {
      setInterval(() => {
        if (this.props.isNegoOpen) {
          this.props.fetchNegotiationDetails(
            this.props.session,
            this.props.negotiationDetails.negotiationthreadid,
            (callBack) => {}
          );
        }
      }, 3000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    // Reinitialize all state values
    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_negotiationsoffers,
      negoSearchText: "",
      btnLoader: false,
    };
  }

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.isNegotiationOpen(false);

    this.props.NotificationSingleObject({}); // When Close Modal Blank Already selected Notification Object
    this.props.makeNotificationDetailsEmpty({}); // When Close Modal Blank Already selected Notification Object
  }

  // Arrow Function for NEGOTIATION Chats
  renderNegoChats = (negotiationDetails) => {
    if (
      negotiationDetails &&
      negotiationDetails.negotiations &&
      negotiationDetails.negotiations.length > 0
    ) {
      return _.map(negotiationDetails.negotiations, (item, index) => {
        return <ChatList item={item} />;
      });
    }
  };

  //**** Update Negotiation Status (Vendor) ****/
  _handleNegoStateChange(e, threadid, status) {
    this.setState({ btnLoader: true });
    this.props.updatenegotiation(
      this.props.session,
      threadid,
      status,
      (callBack) => {
        this.setState({ btnLoader: false });
      }
    );
  }

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  render() {
    let { isNegoOpen, language, negotiationDetails, localCurrency } =
      this.props;

    let { btnLoader } = this.state;
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    // Price Functionality
    var discountpercent = 0.0;
    var strProductDiscount = "";
    let originalprice = 0.0;
    let prodprice = 0.0;

    let negoCurrency = negotiationDetails?.thread?.odcurrency;
    if (negotiationDetails && negotiationDetails.thread) {
      originalprice = negotiationDetails.thread.originalprice
        ? negotiationDetails.thread.originalprice
        : 0;
      prodprice = negotiationDetails.thread.price
        ? negotiationDetails.thread.price
        : 0;

      if (
        negotiationDetails.thread.discountactive &&
        negotiationDetails.thread.discountactive == true
      ) {
        if (
          negotiationDetails.thread.discount &&
          negotiationDetails.thread.discount.discount_percentage
        ) {
          discountpercent =
            negotiationDetails.thread.discount.discount_percentage;
        }

        if (
          negotiationDetails.thread.discount &&
          negotiationDetails.thread.discount.discounttype
        ) {
          let discounttype = negotiationDetails.thread.discount.discounttype;
          if (discounttype == "fixed") {
            //strProductDiscount = CURRENCY_RATE + discountpercent + " OFF";
            strProductDiscount =
              parseFloat(discountpercent).toFixed(2) + " " + LocaleStrings.off;
          } else {
            strProductDiscount =
              parseFloat(discountpercent).toFixed(2) +
              " % " +
              LocaleStrings.off;
          }
        } else {
          strProductDiscount =
            // currency +
            parseFloat(discountpercent).toFixed(2) + " % " + LocaleStrings.off;
        }
      }
    }

    prodprice = isCurrencyRateApplicable(
      localCurrency,
      negoCurrency,
      prodprice
    );

    originalprice = isCurrencyRateApplicable(
      localCurrency,
      negoCurrency,
      originalprice
    );

    return (
      <>
        <Transition.Root show={isNegoOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            initialFocus={this.myRef}
            open={isNegoOpen}
            onClose={this.closeSlideOvers}>
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              {LocaleStrings.negotiation}
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                className="btn-closeModal"
                                ref={this.myRef}
                                onClick={this.closeSlideOvers}>
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 relative flex-1 px-4 sm:px-6">
                          {Object.keys(negotiationDetails).length > 0 ? (
                            <>
                              <div
                                className={classNames(
                                  "initiated" == "initiated"
                                    ? "rounded "
                                    : "rounded-t ",
                                  "bg-white p-3 flex"
                                )}>
                                <div className="pr-4">
                                  <img
                                    loading="lazy"
                                    className="w-36 h-36  rounded border border-primary"
                                    src={`${BASE_IMAGES_URL}/vendor/variations/${negotiationDetails.thread.productimage}?api_key=${APP_API_KEY}`}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <div className="text-sm font-medium text-secondary">
                                    {ProductTextLocalized(
                                      negotiationDetails.thread.productname_en,
                                      negotiationDetails.thread.productname_ar,
                                      language
                                    )}
                                  </div>

                                  <div className="flex pt-3">
                                    <div className="text-sm text-custblack ">
                                      {currency}
                                      {parseFloat(
                                        negotiationDetails.thread.price
                                      ).toFixed(2)}
                                      /{" "}
                                      {getBilangualeText(
                                        negotiationDetails?.thread?.units
                                      )}
                                    </div>

                                    <div
                                      className={classNames(
                                        negotiationDetails.thread.discountactive
                                          ? ""
                                          : "hidden",
                                        "sales-table-td-text line-through mx-2"
                                      )}>
                                      {currency}
                                      {parseFloat(
                                        negotiationDetails.thread.originalprice
                                      ).toFixed(2)}
                                    </div>

                                    <div className="text-sm text-danger">
                                      {strProductDiscount}
                                    </div>
                                  </div>
                                  <div className="pt-3">
                                    <div className="text-xs font-light text-ternary">
                                      <span className="">* </span>
                                      {LocaleStrings.shipping_and_taxes_extra}
                                    </div>
                                  </div>
                                  <div className="pt-3">
                                    <div className="flex">
                                      <div className="text-sm font-medium text-secondary">
                                        {LocaleStrings.qty} : &nbsp;
                                      </div>
                                      <div className="text-left text-primary font-medium text-sm">
                                        {negotiationDetails.thread.quantity}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* NEGOTIATION CHAT */}
                              {this.renderNegoChats(negotiationDetails)}
                            </>
                          ) : (
                            <>{this.renderLoader()}</>
                          )}
                        </div>
                      </div>
                      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                        {Object.keys(negotiationDetails).length > 0 ? (
                          _.indexOf(
                            hiddenStateArr,
                            negotiationDetails.thread.status,
                            0
                          ) > -1 ? (
                            ""
                          ) : (
                            <>
                              <button
                                type="button"
                                className={classNames(
                                  btnLoader ? "cursor-not-allowed" : "",
                                  "btn-danger m-1"
                                )}
                                disabled={btnLoader}
                                onClick={(e) =>
                                  this._handleNegoStateChange(
                                    e,
                                    negotiationDetails.negotiationthreadid,
                                    "rejected"
                                  )
                                }>
                                <BtnLoaderSvg loader={btnLoader} />
                                <span
                                  className={classNames(
                                    !btnLoader ? "" : "sr-only",
                                    ""
                                  )}>
                                  {LocaleStrings.negotiation_reject}
                                </span>
                              </button>
                              <button
                                type="submit"
                                className={classNames(
                                  btnLoader ? "cursor-not-allowed" : "",
                                  "btn-primary m-1"
                                )}
                                disabled={btnLoader}
                                onClick={(e) =>
                                  this._handleNegoStateChange(
                                    e,
                                    negotiationDetails.negotiationthreadid,
                                    "accepted"
                                  )
                                }>
                                <BtnLoaderSvg loader={btnLoader} />
                                <span
                                  className={classNames(
                                    !btnLoader ? "" : "sr-only",
                                    ""
                                  )}>
                                  {LocaleStrings.negotiation_accept}
                                </span>
                              </button>
                            </>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    localCurrency: state.currency,
    isNegoOpen: state.isNegotiationopen,
    //negotiationDetails: state.negotiationDetails,
    negotiationDetails: state.negotiationDetails
      ? state.negotiationDetails.data
        ? state.negotiationDetails.data
        : []
      : [],
  };
}

export default connect(mapStateToProps, {
  salesmanagementStatboxSelected,
  isNegotiationOpen,
  updatenegotiation,
  fetchNegotiationDetails,
  NotificationSingleObject,
  makeNotificationDetailsEmpty,
})(Negotiate);
