import {
  COUPONMANAGEMENT_MASTER,
  COUPONMANAGEMENT_MASTER_SINGLE,
  IS_COUPONMANAGEMENT_MODAL_OPEN,
  COUPONMANAGEMENT_SELECTED_TAB,
} from "../actions/index";

import LocaleStrings from "@language";

/**** Fetch Coupons Management Master *****/
export var CouponMasterStore = (state = {}, action) => {
  switch (action.type) {
    case COUPONMANAGEMENT_MASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/**** Store IS modal Open Or not As Coupons Management  ****/
export var isCouponManageModalopen = (state = false, action) => {
  if (action.type === IS_COUPONMANAGEMENT_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

// Tabs On Coupons Managment Data Tabs
export var CouponsTabs = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.coupons_tab_text_active,
      href: "#",
      current: false,
      position: 0,
    },
    {
      name: LocaleStrings.coupons_tab_text_inactive,
      href: "#",
      current: false,
      position: 1,
    },
  ];

  return state;
};

/**** Select Coupons active / Inactive Tabs STORED****/
export var storedCouponsTab = (state = 0, action) => {
  if (action.type === COUPONMANAGEMENT_SELECTED_TAB) {
    state = action.payload;
  }

  return state;
};

/**** Store Single Coupon Object ****/
export var singleCouponObject = (state = false, action) => {
  if (action.type === COUPONMANAGEMENT_MASTER_SINGLE) {
    state = action.payload;
  }

  return state;
};
