import {
  VENDOR_PRODUCT_LIST,
  VENDOR_PRODUCTMANAGEMENT_DEFAULTSETTINGS,
  IS_PRODUCT_DISCOUNT_MODAL_OPEN,
  IS_EXCLUDE_SHIPPING_MODAL_OPEN,
  IS_CHARITY_VIEW_MODAL_OPEN,
  IS_CATE_SUBCATE_MODAL_OPEN,
  PRODUCT_TITLE_SUBTITLE_TEXT,
  PRODUCT_CATEGORY_SUBCATEGORY_LIST,
  PRODUCT_SELECTED_SUBCATE,
  IS_VARIANTS_DETAILS_MODAL_OPEN,
  IS_CROPPER_MODAL_OPEN,
  PRODUCT_ITEM_SPECIFICATION_DATA,
  PRODUCT_SELECTED_DISCOUNT,
  IS_PRODUCT_FEE_MODAL_OPEN,
  PRODUCT_FEE_DETAILS,
  IS_PRODUCT_FEE_DEFAULT,
  IS_PRODUCT_SUBMIT_CONFIRM,
  VENDOR_PRODUCTMANAGEMENT_DISCOUNTLIST,
  SELECTED_PRODUCT_DETAILS,
  SELECTED_PRODUCT_QA_WITHLIMIT,
  SELECTED_PRODUCT_QA_WITHOUT_LIMIT,
  IS_PRODUCT_QA_MODAL_OPEN,
  IS_PRODUCT_QA_N0ITIFI_MODAL_OPEN,
  IS_PRODUCT_COMMENTS_MODAL_OPEN,
  IS_PRODUCT_COMMENTS_N0ITIFI_MODAL_OPEN,
  COMMENT_LIST,
  TOBEEDIT_PRODUCT_DATA,
  PRODUCT_TAB_LIST,
  PRODUCT_UPLOAD_SHIPPINGTYPE,
  IS_PRODUCT_LIKES_MODAL_OPEN,
  PRODUCTLIKES_LIST,
  IS_PUBLIC_BIDS_MODAL_OPEN,
  PUBLIC_BIDS,
  VENDOR_GLOBAL_SPECIFICATIONS,
  IS_SETUP_STORE_BLOCKER_MODAL_OPEN,
  CHECK_SHIPPING_BEARER,
  VARIATION_MASTER_LIST,
  SHOW_UPLOAD_SECTION,
  PAYMENT_STATUS_BY_PRODUCTID,
  IS_REPAYMENT_MODAL_OPEN,
  TOGGLE_GRID_AND_LIST, //  Will store grid or list view of product list
  ZONE_PINCODE_LIST, // Will store all xone and pin number here
  RELIST_WHILEUPDATE, // relist while product upload
  SHIIPPING_CHARGES_DEFAULT,
  LISTING_AMOUNT,
  COUNTRY_LIST_ARR,
  CONTINENT_LIST_ARR,
  PRODUCT_EXCLUDE_LOCATION,
  SELECTED_CONTINENT_LENGTH,
  CATSUBCAT_UPDATED,
} from "@productmanagementActions";

import LocaleStrings from "@language";
import _ from "lodash";

// Global state for grid or list view of product list
export var toggleGridAndList = (state = true, action) => {
  if (action.type === TOGGLE_GRID_AND_LIST) {
    state = action.payload;
  }

  return state;
};

// Store vendor Product list
export const ProductList = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_PRODUCT_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// Store variation master list [Action name - fetchVariationMaster]
export const variationMasterList = (state = {}, action) => {
  switch (action.type) {
    case VARIATION_MASTER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

// Store variation master list [Action name - fetchVariationMaster]
export const zoneAndpincodes = (state = {}, action) => {
  switch (action.type) {
    case ZONE_PINCODE_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/*****  *****/
export var fetchProductManagementDefaultSettings = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_PRODUCTMANAGEMENT_DEFAULTSETTINGS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var uploadProductsTabs = (myState = [], action) => {
  //iconName is optional
  var state = "";

  if (action.type === PRODUCT_TAB_LIST || !_.isEmpty(myState)) {
    state = myState;
  } else {
    state = [
      {
        name: LocaleStrings.about_the_product,
        href: "#",
        current: true,
        tabindex: 1,
      },
      {
        // name: LocaleStrings.product_variation,
        name: LocaleStrings.item_specifications,
        href: "#",
        current: false,
        tabindex: 2,
      },
      // commented due to new upload product changes
      // {
      //   name: LocaleStrings.product_specifications,
      //   href: "#",
      //   current: false,
      //   tabindex: 3,
      // },
      // {
      //   name: LocaleStrings.item_specifications,
      //   href: "#",
      //   current: false,
      //   tabindex: 4,
      // },
      // {
      //   name: LocaleStrings.sales_formate_and_pricing,
      //   href: "#",
      //   current: false,
      //   tabindex: 5,
      // },
      {
        name: LocaleStrings.shipping_type_and_service,
        href: "#",
        current: false,
        tabindex: 3,
      },
      {
        name: LocaleStrings.set_preferences,
        href: "#",
        current: false,
        tabindex: 4,
      },
    ];
  }

  return state;
};

// Add discount Modal open storage
export var isProductDiscountopen = (state = false, action) => {
  if (action.type === IS_PRODUCT_DISCOUNT_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};
// Add discount Modal open storage
export var isExcludeshippingopen = (state = false, action) => {
  if (action.type === IS_EXCLUDE_SHIPPING_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};
export var ischarityviewModalopen = (state = false, action) => {
  if (action.type === IS_CHARITY_VIEW_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/***** Is variation details Modal is open or not [Store that] *****/
export var isVariationDeatilsopen = (state = false, action) => {
  if (action.type === IS_VARIANTS_DETAILS_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};
/***** Is cropper details Modal is open or not [Store that] *****/
export var isCropperModalopen = (state = false, action) => {
  if (action.type === IS_CROPPER_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/***** Category - Subcategory Modal Open Or not Store to Reducers *****/
export var isProdCateSubCateModalOpen = (state = false, action) => {
  if (action.type === IS_CATE_SUBCATE_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/***** TO STORE PRODUCT TITLE & SUBTITLE DATA TO REDUX *****/
export var productTitleSubTitleData = (state = [], action) => {
  if (action.type === PRODUCT_TITLE_SUBTITLE_TEXT) {
    state = action.payload;
  }

  return state;
};

/***** Product Category And Subcategory List *****/
export var ProductCategorylist = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_SUBCATEGORY_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/***** (Store) On Product Subcategory Selected From Modal ( Category Subcategory ) *****/
export var allSelectedSubcategorisID = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_SELECTED_SUBCATE:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/***** Subcategory specifications *****/
export const storedItemSpecifications = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ITEM_SPECIFICATION_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/* Store global specification to redux */
export const globalSpecifications = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_GLOBAL_SPECIFICATIONS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/***** Shipping Type data Store *****/
export var ShippngTypeStatus = (state = "flat", action) => {
  if (action.type === PRODUCT_UPLOAD_SHIPPINGTYPE) {
    state = action.payload;
  }

  return state;
};

/***** Product Added Discount data Store *****/
export var productDiscountAddedData = (state = {}, action) => {
  if (action.type === PRODUCT_SELECTED_DISCOUNT) {
    state = action.payload;
  }

  return state;
};

/***** Product Fee Modal *****/
export var isProductFeeOpen = (state = false, action) => {
  if (action.type === IS_PRODUCT_FEE_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/***** Product Details Object For Before add Product Fee Show *****/
export var productObjectForFeeDetails = (state = {}, action) => {
  if (action.type === PRODUCT_FEE_DETAILS) {
    state = action.payload;
  }

  return state;
};

/***** call this before opening edit mode pass the data in response *****/
export var toBeEditproduct = (state = {}, action) => {
  if (action.type === TOBEEDIT_PRODUCT_DATA) {
    state = action.payload;
  }

  return state;
};

/**** Discount ****/
export var discountlist = (state = {}, action) => {
  if (action.type === VENDOR_PRODUCTMANAGEMENT_DISCOUNTLIST) {
    state = action.payload;
  }

  return state;
};

/**** PRODUCT DETAILS ****/

// 1 will give 3 qa
export var productQaWithLimit = (state = {}, action) => {
  if (action.type === SELECTED_PRODUCT_QA_WITHLIMIT) {
    state = action.payload;
  }

  return state;
};

// 0 will give all
export var productQaWithOutLimit = (state = {}, action) => {
  if (action.type === SELECTED_PRODUCT_QA_WITHOUT_LIMIT) {
    state = action.payload;
  }

  return state;
};

// Comment List Data
export var commentList = (state = {}, action) => {
  if (action.type === COMMENT_LIST) {
    state = action.payload;
  }

  return state;
};

export var productDetails = (state = {}, action) => {
  if (action.type === SELECTED_PRODUCT_DETAILS) {
    state = action.payload;
  }

  return state;
};

/***** QA Sideover / Modal Modal *****/
export var isQaopen = (state = false, action) => {
  if (action.type === IS_PRODUCT_QA_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/***** QA Sideover / Modal Modal [NOTIFICATION]*****/
export var isQaNotificOpen = (state = false, action) => {
  if (action.type === IS_PRODUCT_QA_N0ITIFI_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/***** Likes Modal Modal *****/
export var isLikesopen = (state = false, action) => {
  if (action.type === IS_PRODUCT_LIKES_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/***** Public Bids Modal Modal *****/
export var publicBidModal = (state = false, action) => {
  if (action.type === IS_PUBLIC_BIDS_MODAL_OPEN) {
    state = action.payload;
  }
  return state;
};

/***** 
Setup Store
Please setup our Store before adding
a Product 
*****/
export var setupStoreBlockerModal = (state = false, action) => {
  if (action.type === IS_SETUP_STORE_BLOCKER_MODAL_OPEN) {
    state = action.payload;
  }
  return state;
};

// Product List Data
export var productlikesData = (state = {}, action) => {
  if (action.type === PRODUCTLIKES_LIST) {
    state = action.payload;
  }

  return state;
};

/***** Comments Sideover / Modal Modal *****/
export var isCommentsOpen = (state = false, action) => {
  if (action.type === IS_PRODUCT_COMMENTS_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/***** Comments Sideover / Modal Modal [NOTIFICATION]*****/
export var isCommentsNotificOpen = (state = false, action) => {
  if (action.type === IS_PRODUCT_COMMENTS_N0ITIFI_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

export function publicBids(state = [], action) {
  switch (action.type) {
    case PUBLIC_BIDS:
      return action.payload;
    default:
      return state;
  }
}

/**** PRODUCT FEE ****/
export var isProductFeeDefault = (state = true, action) => {
  if (action.type === IS_PRODUCT_FEE_DEFAULT) {
    state = action.payload;
  }

  return state;
};

export var producvtSubmitConfirm = (state = false, action) => {
  if (action.type === IS_PRODUCT_SUBMIT_CONFIRM) {
    state = action.payload;
  }

  return state;
};

/* Store this user has shippingcostbearer option from shopez {is available or not}
  checkShippingBearer -> action name
*/
export const isCheckShippingBearerAvailable = (state = {}, action) => {
  switch (action.type) {
    case CHECK_SHIPPING_BEARER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/***** Screen to show is the subsitute for showUploadSection *****/
export var isShowUploadSection = (state = "VL", action) => {
  if (action.type === SHOW_UPLOAD_SECTION) {
    state = action.payload;
  }
  return state;
};

/*** Store Product payment status by product id for personal vendor [Action - fetchLastPaymentStatusById] ***/
export const productLastPaymentStatus = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_STATUS_BY_PRODUCTID:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

/***** Is repayment option Modal [Action = isRepaymentModalOpen] *****/
export var isRepaymentOpen = (state = false, action) => {
  if (action.type === IS_REPAYMENT_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

// Store variation master list [Action name - fetchVariationMaster]
export const relist_while_product_upload = (state = {}, action) => {
  switch (action.type) {
    case RELIST_WHILEUPDATE:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export const defaultShippingCharges = (state = {}, action) => {
  switch (action.type) {
    case SHIIPPING_CHARGES_DEFAULT:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export const listingamount = (state = {}, action) => {
  switch (action.type) {
    case LISTING_AMOUNT:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export const countryList = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_LIST_ARR:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export const continentList = (state = {}, action) => {
  switch (action.type) {
    case CONTINENT_LIST_ARR:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var productexcludeLocationdata = (state = {}, action) => {
  if (action.type === PRODUCT_EXCLUDE_LOCATION) {
    state = action.payload;
  }

  return state;
};
export var continentLengthdata = (state = {}, action) => {
  if (action.type === SELECTED_CONTINENT_LENGTH) {
    state = action.payload;
  }

  return state;
};

export var auction_daysList = (state = [], action) => {
  //iconName is optional
  var state = [
    { name: "3 days", value: "3", id: 0 },
    { name: "5 days", value: "5", id: 1 },
    { name: "7 days", value: "7", id: 1 },
    { name: "10 days", value: "10", id: 1 },
  ];

  return state;
};

export var isCatsubcatupdatedData = (state = false, action) => {
  if (action.type === CATSUBCAT_UPDATED) {
    state = action.payload;
  }

  return state;
};
