import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import LocaleStrings from "@language";
import _ from "lodash";
import moment from "moment-timezone";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import toast from "react-hot-toast";
import "react-phone-input-2/lib/style.css";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

// Import constant
import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  GOOGLE_MAP_KEY,
  DATE_FORMAT_WITH_TIME_WITH_SECOND,
  MIN_DATE_FORMAT,
  QATAR_LAT,
  QATAR_LONG,
} from "@constant";

// Import Actions
import {
  uploadMediaLarge,
  updatepersonalStore,
  isStoreInfoMapModalOpen,
  fetchStoreDetails,
  addpersonalStore,
  updateStoreCoverLocally,
  fetchCountries,
} from "@profileSettingsActions";

// Functions Import
import { classNames, removePhoneNoSpecialChar } from "@commonFunction";

// Component Import
import StoreMap from "../storeinformation/storemap";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";

var phoneFieldNames = []; // Hold Dynamic Phone number names

class PersonalvendorAddress extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      fileUrl: "",
      fileArr: {},
      fileArrNew: {},
      fileArrMap: {},
      contactNumber: [],
      flag: true,
      address: "",
      flag1: true,
      geolocObject: {
        location: "",
        latitude: "",
        longitude: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        place_id: "",
      },
      storeEventCover: "",
      storeCoverAvailableLocally: false,
      storeCoverAvailableOnDrop: false,
      storeCoverReset: false,
    };

    // All Binded Functions
    this.updateGeolocObject = this.updateGeolocObject.bind(this);
  }

  //return a promise that resolves with a File instance
  urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  handleChange = (address) => {
    this.setState({ address });
    this.setState({ flag1: false });
  };

  handleSelect = (address) => {
    const reader = new FileReader();
    let that = this;
    let { geolocObject } = this.state;
    this.setState({ address });

    let location = address;
    let zipcode = "";
    let place_id = "";
    let country = "";
    let state = "";
    let city = "";
    let latitude = "";
    let longitude = "";

    geocodeByAddress(address)
      .then((results) => {
        _.forEach(results[0].address_components, function (value) {
          // postal_code
          if (_.indexOf(value.types, "postal_code") > -1) {
            zipcode = value.long_name;
          }

          // country
          if (_.indexOf(value.types, "country") > -1) {
            country = value.long_name;
          }

          // state / administrative_area_level_1
          if (_.indexOf(value.types, "administrative_area_level_1") > -1) {
            state = value.long_name;
          }

          // state / administrative_area_level_2
          if (_.indexOf(value.types, "administrative_area_level_2") > -1) {
            city = value.long_name;
          }
        });
        place_id = results[0].place_id; // place_id / zipcode

        geolocObject["zipcode"] = zipcode;
        geolocObject["place_id"] = place_id;
        geolocObject["country"] = country;
        geolocObject["state"] = state;
        geolocObject["city"] = city;
        geolocObject["location"] = location;

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        latitude = latLng.lat;
        longitude = latLng.lng;

        geolocObject["latitude"] = latitude;
        geolocObject["longitude"] = longitude;

        this.setState({ geolocObject: geolocObject });

        // Store Image
        let filearr = [];
        let mapImage = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue|${latitude},${longitude}&zoom=14&size=400x400&key=${GOOGLE_MAP_KEY}`;

        that
          .urltoFile(mapImage, "storeimage.jpeg", "image/jpeg")
          .then(function (file) {
            reader.readAsArrayBuffer(file);

            reader.onload = (e) => {
              file.url = e.target.result;

              filearr.push(file);

              that.setState({ fileArrMap: filearr });
            };
          });
      })
      .catch((error) => console.error("Error", error));
  };

  componentDidMount() {
    let { session } = this.props;
    this.props.fetchCountries(session, (res) => {});
    if (_.isEmpty(this.props.personalvendorStoreDetails)) {
      var arr = [];
      let innnerObj = {
        name: "",
        value: "",
        index: "",
        ccode: "",
      };
      let name = "contactnumber_" + 0;

      innnerObj["name"] = name;
      innnerObj["value"] = "";
      innnerObj["index"] = 0;
      innnerObj["ccode"] = "";

      arr.push(innnerObj);

      this.setState({
        contactNumber: arr,
      });
    }
  }

  componentWillUnmount() {
    this.setState({ loading: true });
    this.setState({ btnLoader: false });
    this.setState({ fileUrl: "" });
    this.setState({ fileArr: {} });
    this.setState({ fileArrNew: {} });
    this.setState({ fileArrMap: {} });
    this.setState({ contactNumber: [] });
    this.setState({ flag: true });
    this.setState({ address: "" });
    this.setState({ flag1: true });
    this.setState({
      geolocObject: {
        location: "",
        latitude: "",
        longitude: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        place_id: "",
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    var arr = [];
    let { geolocObject } = this.state;

    if (this.state.flag === true) {
      if (!_.isEmpty(this.props.initialValues)) {
        // Geo Location array
        geolocObject["zipcode"] = this.props.initialValues.zipcode;
        geolocObject["place_id"] = this.props.initialValues.placeid;
        geolocObject["country"] = this.props.initialValues.country;
        geolocObject["state"] = this.props.initialValues.state;
        geolocObject["city"] = this.props.initialValues.city;
        geolocObject["location"] = this.props.initialValues.location;
        geolocObject["latitude"] = this.props.initialValues.latitude;
        geolocObject["longitude"] = this.props.initialValues.longitude;

        this.setState({ flag: false });
      }
    }
  }

  /**** Modal Open Function  ****/
  openStoreInfoMapModal() {
    this.props.isStoreInfoMapModalOpen(true);
  }

  /**** Update geolocObject state ****/
  updateGeolocObject = async (lat, lng, address) => {
    let that = this;
    const reader = new FileReader();
    let { geolocObject, fileArrNew } = this.state;

    let location = address;
    let zipcode = "";
    let place_id = "";
    let country = "";
    let state = "";
    let city = "";
    let latitude = "";
    let longitude = "";

    this.setState({ address });

    geocodeByAddress(address)
      .then((results) => {
        _.forEach(results[0].address_components, function (value) {
          // postal_code
          if (_.indexOf(value.types, "postal_code") > -1) {
            zipcode = value.long_name;
          }

          // country
          if (_.indexOf(value.types, "country") > -1) {
            country = value.long_name;
          }

          // state / administrative_area_level_1
          if (_.indexOf(value.types, "administrative_area_level_1") > -1) {
            state = value.long_name;
          }

          // state / administrative_area_level_2
          if (_.indexOf(value.types, "administrative_area_level_2") > -1) {
            city = value.long_name;
          }
        });
        place_id = results[0].place_id; // place_id / zipcode

        geolocObject["zipcode"] = zipcode;
        geolocObject["place_id"] = place_id;
        geolocObject["country"] = country;
        geolocObject["state"] = state;
        geolocObject["city"] = city;
        geolocObject["location"] = location;

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        latitude = latLng.lat;
        longitude = latLng.lng;

        geolocObject["latitude"] = latitude;
        geolocObject["longitude"] = longitude;

        this.setState({ geolocObject: geolocObject });
      })
      .catch((error) => console.error("Error", error));

    // Store Image
    let filearr = [];
    let mapImage = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue|${lat},${lng}&zoom=14&size=400x400&key=${GOOGLE_MAP_KEY}`;

    that
      .urltoFile(mapImage, "storeimage.jpeg", "image/jpeg")
      .then(function (file) {
        reader.readAsArrayBuffer(file);

        reader.onload = (e) => {
          file.url = e.target.result;

          filearr.push(file);

          that.setState({ fileArrMap: filearr });
        };
      });
  };

  async getFileFromUrl(url, name, defaultType = "image/jpeg") {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    let {
      storeCoverReset,
      storeCoverAvailableOnDrop,
      storeCoverAvailableLocally,
    } = this.state;
    // console.log('values',values);

    // Variables
    let resource_coverimage = "";
    let resource_mapimage = "";
    let uploaderCounter = 0;
    let filetobeupload = [];
    let arrPromise = [];
    this.setState({ btnLoader: true });
    let that = this;
    let { geolocObject } = this.state;
    let { personalvendorStoreDetails } = this.props;

    /* Validations IF NOT QATAR */
    if (
      (geolocObject.latitude === null ||
        geolocObject.latitude === "null" ||
        geolocObject.latitude === "" ||
        geolocObject.latitude === undefined) &&
      values.country !== "Qatar"
    ) {
      toast.error(LocaleStrings.store_location_verfication_text);
      this.setState({ btnLoader: false });
      return false;
    }

    if (_.isEmpty(personalvendorStoreDetails)) {
      /* ADD */

      if (Object.keys(this.state.fileArrMap).length > 0) {
        // Map Image
        if (Object.keys(this.state.fileArrMap).length > 0) {
          // Image Exist Map Image
          let fileArrMap = this.state.fileArrMap[0];

          let ts = new Date().getTime(); // Timestamp

          var medianame = "storemap_" + ts + "." + "jpeg"; // Image Name Creation
          resource_mapimage = medianame;

          let ext = fileArrMap.name.split(".").pop(); // File extension

          let coverJson = {
            // type: "cover",
            type: "image",
            name: medianame,
            media: fileArrMap.url,
          };
          filetobeupload.push(coverJson);
          var counter = filetobeupload.length;

          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                this.props.uploadMediaLarge(
                  this.props.session,
                  item,
                  (response) => {
                    if (response.success === 0) {
                      resolve();
                    } else {
                      uploaderCounter++;
                      resolve();
                    }
                  }
                );
              })
            );
          });
        }

        Promise.all(arrPromise).then(() => {
          // Store Object
          let storeObject = {
            mapimage: resource_mapimage,
            isverified: 0,
            createdby: this.props.session.userid,
            location:
              values.country === "Qatar"
                ? values.building_no +
                  "~" +
                  // values.zone_no. + //old code
                  values.zone_no.label + //latest code
                  "~" +
                  values.street_no
                : geolocObject.location,
            zone: values.country === "Qatar" ? values.zone_no.value : null,
            latitude:
              values.country === "Qatar" ? QATAR_LAT : geolocObject.latitude,
            longitude:
              values.country === "Qatar" ? QATAR_LONG : geolocObject.longitude,
            city: values.country === "Qatar" ? null : geolocObject.city,
            country:
              values.country === "Qatar"
                ? values.country
                : geolocObject.country,
            state: values.country === "Qatar" ? null : geolocObject.state,
            zipcode:
              values.country === "Qatar"
                ? values.zipcode
                : geolocObject.zipcode,
            place_id: values.country === "Qatar" ? null : geolocObject.place_id,
          };

          // Map array not present
          if (Object.keys(this.state.fileArrMap).length > 0) {
          } else {
            storeObject = _.omit(storeObject, "mapimage"); //It will return a new object
          }

          // Cover array not present

          console.log("storeObject", storeObject);
          debugger;
          //if (counter == uploaderCounter) {
          // After file upload
          this.props.addpersonalStore(
            this.props.session,
            storeObject,
            (callback) => {
              if (callback.status === 1) {
                this.setState({ btnLoader: false });
                this.setState({ fileArr: {} });
                this.setState({ fileArrNew: {} });
                this.setState({ fileArrMap: {} });
                toast.success(LocaleStrings.store_updated_successfully);
                /**** Fetch Store Information ****/
                this.props.fetchStoreDetails(
                  this.props.session,
                  (callback) => {}
                );
              }
            }
          );
          // }
        });
      } else {
        // Without Image

        let storeObject = {
          createdby: this.props.session.userid,
          location:
            values.country === "Qatar"
              ? values.building_no +
                "~" +
                // values.zone_no. + //old code
                values.zone_no.label + //latest code
                "~" +
                values.street_no
              : geolocObject.location,
          zone: values.country === "Qatar" ? values.zone_no.value : null,
          latitude:
            values.country === "Qatar" ? QATAR_LAT : geolocObject.latitude,
          longitude:
            values.country === "Qatar" ? QATAR_LONG : geolocObject.longitude,
          city: values.country === "Qatar" ? null : geolocObject.city,
          country:
            values.country === "Qatar" ? values.country : geolocObject.country,
          state: values.country === "Qatar" ? null : geolocObject.state,
          zipcode:
            values.country === "Qatar" ? values.zipcode : geolocObject.zipcode,
          place_id: values.country === "Qatar" ? null : geolocObject.place_id,
        };

        console.log("storeObject", storeObject);
        debugger;
        this.props.addpersonalStore(
          this.props.session,
          storeObject,
          (callback) => {
            if (callback.status === 1) {
              this.setState({ btnLoader: false });
              toast.success(LocaleStrings.store_updated_successfully);
              /**** Fetch Store Information ****/
              this.props.fetchStoreDetails(
                this.props.session,
                (callback) => {}
              );
            }
          }
        );
      }
    } else {
      /* UPDATE */

      if (Object.keys(this.state.fileArrMap).length > 0) {
        // Map Image
        // Image Exist Map Image
        let fileArrMap = this.state.fileArrMap[0];

        let ts = new Date().getTime(); // Timestamp

        var medianame = "storemap_" + ts + "." + "jpeg"; // Image Name Creation
        resource_mapimage = medianame;

        let ext = fileArrMap.name.split(".").pop(); // File extension

        let coverJson = {
          // type: "cover",
          type: "image",
          name: medianame,
          media: fileArrMap.url,
        };
        filetobeupload.push(coverJson);
        var counter = filetobeupload.length;

        filetobeupload.map((item, index) => {
          arrPromise.push(
            new Promise((resolve, reject) => {
              this.props.uploadMediaLarge(
                this.props.session,
                item,
                (response) => {
                  if (response.success === 0) {
                    resolve();
                  } else {
                    uploaderCounter++;
                    resolve();
                  }
                }
              );
            })
          );
        });

        Promise.all(arrPromise).then(() => {
          // Store Object
          let storeObject = {
            storeid: values.storeid,
            mapimage: resource_mapimage,
            location:
              values.country === "Qatar"
                ? values.building_no +
                  "~" +
                  // values.zone_no. + //old code
                  values.zone_no.label + //latest code
                  "~" +
                  values.street_no
                : geolocObject.location,
            zone: values.country === "Qatar" ? values.zone_no.value : null,
            latitude:
              values.country === "Qatar" ? QATAR_LAT : geolocObject.latitude,
            longitude:
              values.country === "Qatar" ? QATAR_LONG : geolocObject.longitude,
            city: values.country === "Qatar" ? null : geolocObject.city,
            country:
              values.country === "Qatar"
                ? values.country
                : geolocObject.country,
            state: values.country === "Qatar" ? null : geolocObject.state,
            zipcode:
              values.country === "Qatar"
                ? values.zipcode
                : geolocObject.zipcode,
            place_id: values.country === "Qatar" ? null : geolocObject.place_id,
          };

          // Map array not present
          if (Object.keys(this.state.fileArrMap).length > 0) {
          } else {
            storeObject = _.omit(storeObject, "mapimage"); //It will return a new object
          }

          //if (counter == uploaderCounter) {
          // After file upload
          this.props.updatepersonalStore(
            this.props.session,
            storeObject,
            (callback) => {
              if (callback.status === 1) {
                this.setState({ btnLoader: false });
                this.setState({ fileArr: {} });
                this.setState({ fileArrNew: {} });
                this.setState({ fileArrMap: {} });
                toast.success(LocaleStrings.store_updated_successfully);
                /**** Fetch Store Information ****/
                this.props.fetchStoreDetails(
                  this.props.session,
                  (callback) => {}
                );
              }
            }
          );
          // }
        });
      } else {
        // Without Image

        let storeObject = {
          storeid: values.storeid,
          location:
            values.country === "Qatar"
              ? values.building_no +
                "~" +
                // values.zone_no. + //old code
                values.zone_no.label + //latest code
                "~" +
                values.street_no
              : geolocObject.location,
          zone: values.country === "Qatar" ? values.zone_no.value : null,
          latitude:
            values.country === "Qatar" ? QATAR_LAT : geolocObject.latitude,
          longitude:
            values.country === "Qatar" ? QATAR_LONG : geolocObject.longitude,
          city: values.country === "Qatar" ? null : geolocObject.city,
          country:
            values.country === "Qatar" ? values.country : geolocObject.country,
          state: values.country === "Qatar" ? null : geolocObject.state,
          zipcode:
            values.country === "Qatar" ? values.zipcode : geolocObject.zipcode,
          place_id: values.country === "Qatar" ? null : geolocObject.place_id,
        };

        this.props.updatepersonalStore(
          this.props.session,
          storeObject,
          (callback) => {
            if (callback.status === 1) {
              this.setState({ btnLoader: false });
              toast.success(LocaleStrings.store_updated_successfully);
              /**** Fetch Store Information ****/
              this.props.fetchStoreDetails(
                this.props.session,
                (callback) => {}
              );
            }
          }
        );
      }
    } // Eit Else End
  }

  /* Handle Country change */
  _handelCountryChange = (event) => {
    let { geolocObject } = this.state;
    let geolocObject1 = geolocObject;
    console.log("event", event.target.value);
    if (event.target.value !== "") {
      geolocObject1.country = event.target.value;

      if (event.target.value !== "Qatar") {
        geolocObject1.location = "";
        geolocObject1.latitude = "";
        geolocObject1.latitude = "";
        geolocObject1.city = "";
        geolocObject1.state = "";
        geolocObject1.zipcode = "";
        geolocObject1.place_id = "";
        geolocObject1.country = "";
      }
      console.log("geolocObject1", geolocObject1);
      this.setState({ geolocObject1 });
    }
  };

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      formValues,
      storeCountries,
      zoneAndpincodes,
    } = this.props;
    let {
      fileUrl,
      address,
      flag1,
      geolocObject,
      storeCoverAvailableOnDrop,
      storeCoverReset,
    } = this.state;
    console.log("geolocObject", geolocObject);
    var zonePincodeOptions = []; // Zone and pin code list
    // Zone and pin code list objects creation
    _.forEach(zoneAndpincodes, (val, index) => {
      var obj = {};
      // latest code
      obj = {
        label: val.zone,
        value: val.id,
      };
      zonePincodeOptions.push(obj);
    });
    let mapImage = "";

    let storeCoverPreviewUrl = "";

    let pointedAddress = "";
    if ((address === "" || address === null) && flag1 === true) {
      if (!_.isEmpty(initialValues)) {
        pointedAddress = initialValues.location;
      } else {
        pointedAddress = "";
      }
    } else {
      pointedAddress = address;
    }

    if (!_.isEmpty(initialValues) || geolocObject?.latitude !== undefined) {
      if (initialValues?.latitude !== geolocObject?.latitude) {
        mapImage = `https://maps.googleapis.com/maps/api/staticmap?markers=color:blue|${geolocObject?.latitude},${geolocObject?.longitude}&zoom=14&size=400x400&key=${GOOGLE_MAP_KEY}`;
      } else {
        mapImage = `${BASE_IMAGES_URL}/store/${initialValues?.mapimage}?api_key=${APP_API_KEY}`;
      }
    }

    var countriesOptions = []; // Countries array
    _.forEach(storeCountries, function (value) {
      var obj = {
        name: value.name,
        value: value.name,
      };
      countriesOptions.push(obj);
    });

    return (
      <>
        <CommonHeaderProfileSettings commonHeaderText="Add Address" />
        <div className=" xs:px-2 sm:px-10 2xl:px-48">
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
              <div className="p-10 space-y-8">
                {/* Country Name */}
                <Field
                  name="country"
                  label={LocaleStrings.country}
                  placeholder={LocaleStrings.select_country}
                  component={this.renderSelect}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  opts={countriesOptions}
                  className="cust-input-field2"
                  onChange={(e) => this._handelCountryChange(e)}
                  classNameLabel="block text-box-lable"
                />

                {geolocObject?.country == "Qatar" ? (
                  <>
                    <Field
                      name="building_no"
                      label={LocaleStrings.building_no}
                      placeholder={LocaleStrings.enter_building_no}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                    {/* old code */}
                    {/* <Field
                      name="zone_no"
                      label={LocaleStrings.zone_no}
                      placeholder={LocaleStrings.enter_zone_no}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    /> */}
                    {/* latest code */}
                    <Field
                      name="zone_no"
                      label={LocaleStrings.zone_no}
                      placeholder={LocaleStrings.zone_no}
                      options={zonePincodeOptions}
                      component={this.renderAutoComplete1}
                      labelposition={LABEL_POSITION_TOP}
                      labelKey="label"
                      valueKey="value"
                      className="mt-2 ring-2 ring-red-500"
                      classNameLabel="text-box-lable"
                      isMulti={false}
                    />
                    <Field
                      name="street_no"
                      label={LocaleStrings.street_no}
                      placeholder={LocaleStrings.enter_street_no}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                    <Field
                      name="zipcode"
                      label={LocaleStrings.pobox_no}
                      placeholder={LocaleStrings.enter_pobox_no}
                      type="text"
                      component={this.renderFieldText}
                      // mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                  </>
                ) : (
                  <>
                    {/* Auto Complete Map */}
                    <div>
                      <p className="text-sm font-medium text-secondary truncate pb-4">
                        {LocaleStrings.location_header}
                      </p>
                      <p className="text-sm text-ternary truncate pb-1">
                        {LocaleStrings.store_location_text}
                      </p>
                      <PlacesAutocomplete
                        value={pointedAddress}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}>
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: LocaleStrings.search_places,
                                className: "location-search-input",
                              })}
                            />
                            <div
                              className={
                                Object.keys(suggestions).length > 0
                                  ? "autocomplete-dropdown-container"
                                  : "hidden"
                              }>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    key={suggestion.index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}>
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    {/* Google map */}
                    {!_.isEmpty(initialValues) ||
                    geolocObject?.latitude !== undefined ? (
                      <div className="grid grid-cols-3 gap-4">
                        <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                          <div className="aspect-w-3 aspect-h-2">
                            <img
                              loading="lazy"
                              className="object-cover shadow-lg rounded-lg cursor-pointer"
                              src={mapImage}
                              alt=""
                              onClick={(e) => this.openStoreInfoMapModal()}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>

              <div className="p-6 border-t-2 border-quaternary">
                <div className="grid grid-cols-3 gap-4">
                  <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                    <button className="btn-primary" type="submit">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "hidden",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.update_store_info}
                    </button>
                  </div>
                </div>

                {/* Footer */}
              </div>
            </form>
          </div>
        </div>

        {this.props.storeInfoMapModal ? (
          <StoreMap
            intialData={this.state.geolocObject}
            handleOutterScreen={this.updateGeolocObject}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

function validate(values, ownProps) {
  var errors = {};

  var requiredFields = [
    "country",
    "building_no",
    "zone_no",
    "street_no",
    // "zipcode",
  ];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  var newObj = {};

  if (!_.isEmpty(state.personalvendorStoreDetails)) {
    newObj = { ...state.personalvendorStoreDetails.data };
    // let countryName = newObj.country; //Country name
    // newObj.country = { name: countryName, value: countryName };
    if (newObj.country === "Qatar") {
      // latest code
      let obj = {};
      if (newObj.zone_master_by_zone) {
        obj.value = newObj.zone_master_by_zone.id;
        obj.label = newObj.zone_master_by_zone.zone;
      }
      // If not Qatar
      // Search and select address *, manual address line 2, Prefill - City *, State * and Zip * from selected address.
      let newLocArr = _.split(newObj.location, "~", 3);
      newObj["building_no"] = newLocArr[0]; // pos 0
      // newObj["zone_no"] = newLocArr[1]; // pos 1 old code
      newObj["zone_no"] = obj; // latest code
      newObj["street_no"] = newLocArr[2]; // pos 2
    }
  }

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    initialValues: newObj,
    storeInfoMapModal: state.isOpenStoreInfoMapModal,
    personalvendorStoreDetails: !_.isEmpty(state.personalvendorStoreDetails)
      ? !_.isEmpty(state.personalvendorStoreDetails.data)
        ? state.personalvendorStoreDetails.data
        : {}
      : {},
    storeCountries: !_.isEmpty(state.storeCountries)
      ? !_.isEmpty(state.storeCountries.data)
        ? state.storeCountries.data
        : []
      : [],
    formValues: getFormValues("personalvendorAddress")(state),
    zoneAndpincodes: state.zoneAndpincodes?.data,
    phoneLengthCountry: state.phoneLengthCountry,
  };
}

export default connect(mapStateToProps, {
  uploadMediaLarge,
  updatepersonalStore,
  addpersonalStore,
  isStoreInfoMapModalOpen,
  fetchStoreDetails,
  updateStoreCoverLocally,
  fetchCountries,
})(
  reduxForm({
    validate,
    form: "personalvendorAddress",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(PersonalvendorAddress)
);
