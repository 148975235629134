import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import _, { random } from "lodash";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { randomString } from "@commonFunction";
import toast, { Toaster } from "react-hot-toast";
import Dropzone from "react-dropzone";
import { confirmAlert } from "react-confirm-alert";

// Import constant
import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";

// Import Actions
import {
  uploadMediaLargeStoreVerfic,
  updateStoreUsers,
  fetchUsersDeatils,
} from "../../actions/index";

// Functions Import
import { classNames, isEmpty } from "@commonFunction";

// Component Import

// Constant
var threeArr = [{}, {}, {}];

// Constant
let validateExtention = ["doc", "docm", "docx", "docx", "pdf"];

class StoreVerfiEdit extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
    };

    // All Binded Functions
  }

  //return a promise that resolves with a File instance
  urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  /**** Modal Open Function  ****/

  /**** Input File select ****/
  _handleSelect(event, typeindex) {
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      //filearr.push(file);
    };

    let ext = file.name.split(".").pop(); // File extension

    if (_.indexOf(validateExtention, ext) === -1) {
      // Validate File Extensions
      toast.error(LocaleStrings.upload_only_doc_and_pdf_file_error_text);
    } else {
      this.props.autofill(typeindex, file.name);
      if (typeindex === "commercial_reg_doc") {
        threeArr[0] = file;
      } else if (typeindex === "agreement_template") {
        threeArr[1] = file;
      } else if (typeindex === "idproof") {
        threeArr[2] = file;
      }
    }
  }

  /**** Handel File Download ****/
  _handleDownload(event, filename) {
    if (
      filename !== null &&
      filename !== "null" &&
      filename !== undefined &&
      filename !== ""
    ) {
      let filepath = `${BASE_IMAGES_URL}/store/${filename}?api_key=${APP_API_KEY}`;
      window.open(filepath);
    }
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ btnLoader: true });

    if (
      Object.keys(threeArr[0]).length +
        Object.keys(threeArr[1]).length +
        Object.keys(threeArr[2]).length ===
      0
    ) {
      toast.error(LocaleStrings.attachfile_to_update_error_text);
      this.setState({ btnLoader: false });
    } else {
      // Upload Section
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];

      // Blank File Nmae array
      let commercial_reg_doc = "";
      let agreement_template = "";
      let idproof = "";

      _.forEach(threeArr, function (value, index) {
        if (Object.keys(value).length > 0) {
          let filename = "";
          if (index === 0) {
            commercial_reg_doc = value.name;
            filename = value.name;
          }

          if (index === 1) {
            let attachfile_name = randomString(6) + "_" + value.name;
            agreement_template = attachfile_name;
            filename = attachfile_name;
          }

          if (index === 2) {
            idproof = value.name;
            filename = value.name;
          }

          let coverJson = {
            type: value.type,
            name: filename,
            media: value.url,
          };

          filetobeupload.push(coverJson); //
        }
      });

      let counter = filetobeupload.length;

      // Store File to server first
      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadMediaLargeStoreVerfic(
              this.props.session,
              item,
              (response) => {
                if (response.success === 0) {
                  resolve();
                } else {
                  uploaderCounter++;
                  resolve();
                }
              }
            );
          })
        );
      });

      Promise.all(arrPromise).then(() => {
        if (uploaderCounter === counter) {
          // Store Object
          let storeObject = {
            userid: this.props.session.userid,
            commercial_reg_doc: commercial_reg_doc,
            agreement_template: agreement_template,
            idproof: idproof,
          };

          if (commercial_reg_doc === "" || commercial_reg_doc === null) {
            storeObject = _.omit(storeObject, "commercial_reg_doc"); //It will return a new object
          }

          if (agreement_template === "" || agreement_template === null) {
            storeObject = _.omit(storeObject, "agreement_template"); //It will return a new object
          }

          if (idproof === "" || idproof === null) {
            storeObject = _.omit(storeObject, "idproof"); //It will return a new object
          }

          // After file upload
          this.props.updateStoreUsers(
            this.props.session,
            storeObject,
            (callback) => {
              if (callback.status === 1) {
                this.setState({ btnLoader: false });
                threeArr[0] = {};
                threeArr[1] = {};
                threeArr[2] = {};
                this.props.reset();
                toast.success(LocaleStrings.store_updated_successfully);

                // Fetch Vendor Details With LoginIdStore
                this.props.fetchUsersDeatils(
                  this.props.session,
                  (callBackResponce) => {}
                );
              }
            }
          );
        } else {
          toast.error(LocaleStrings.something_went_wrong);
        }
      });
    }
  }

  render() {
    let { initialValues, language, handleSubmit, vendorDetails, user } =
      this.props;
    let usertype = vendorDetails.usertype;
    return (
      <>
        <div className=" xs:px-2 sm:px-10 2xl:px-48">
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
              <div className="p-10 space-y-6 border-t-2 border-quaternary">
                {/* Body [Note user.verified {true / false} Show hide dive] */}
                <div>
                  {user.verified ? (
                    ""
                  ) : (
                    <>
                      <p className="text-sm font-medium text-secondary">
                        {LocaleStrings.store_verification_form_header}
                      </p>
                      <p className="mt-1 text-sm text-ternary">
                        {LocaleStrings.store_verification_form_subheader}
                      </p>
                    </>
                  )}
                  <div className="flex mt-2">
                    <img
                      loading="lazy"
                      className={"h-4 w-4 cursor-pointer"}
                      src={
                        "/images2/profile_settings_images/" +
                        (user.verified ? "rightcircle-yellow" : "rightcircle") +
                        ".svg"
                      }
                    />
                    <div className="mx-1 flex flex-col">
                      <p className="text-xs text-ternary">
                        {user.verified
                          ? LocaleStrings.vendor_verified_text
                          : LocaleStrings.on_your_profile}
                      </p>
                    </div>
                  </div>
                </div>
                {usertype !== "homebiz" ? (
                  <div>
                    <span
                      onClick={(e) =>
                        this._handleDownload(
                          e,
                          this.props.vendorDetails.commercial_reg_doc
                        )
                      }>
                      <Field
                        name="commercial_reg_doc"
                        label={
                          LocaleStrings.digit_one +
                          ". " +
                          LocaleStrings.commercial_registration_document
                        }
                        placeholder={LocaleStrings.attach_document}
                        type="text"
                        component={this.renderFieldText}
                        mandatory="false"
                        isdisabled={true}
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-sm font-medium text-secondary"
                      />
                    </span>
                    <div className="flex justify-end">
                      <label
                        htmlFor="file-upload-commercial_reg_doc"
                        className="relative btn-primary-outline mt-2 cursor-pointer">
                        <img
                          loading="lazy"
                          className={"h-4 w-4 "}
                          src={
                            "/images2/profile_settings_images/attachfile.svg"
                          }
                        />
                        <span>{LocaleStrings.attach_files}</span>
                        <input
                          id="file-upload-commercial_reg_doc"
                          name="file-upload-commercial_reg_doc"
                          type="file"
                          className="sr-only"
                          onChange={(e) =>
                            this._handleSelect(e, "commercial_reg_doc")
                          }
                        />
                      </label>
                    </div>
                  </div>
                ) : (
                  <div>
                    <span
                      onClick={(e) =>
                        this._handleDownload(
                          e,
                          this.props.vendorDetails.idproof
                        )
                      }>
                      <Field
                        name="idproof"
                        label={
                          LocaleStrings.digit_one +
                          ". " +
                          LocaleStrings.valid_id_proof +
                          "Supports documents and pdf's only"
                        }
                        placeholder={LocaleStrings.attach_document}
                        type="text"
                        component={this.renderFieldText}
                        mandatory="false"
                        isdisabled={true}
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-sm font-medium text-secondary"
                      />
                    </span>
                    <div className="flex justify-end">
                      <label
                        htmlFor="file-upload-idproof"
                        className="relative btn-primary-outline mt-2 cursor-pointer">
                        <img
                          loading="lazy"
                          className={"h-4 w-4 "}
                          src={
                            "/images2/profile_settings_images/attachfile.svg"
                          }
                        />
                        <span>{LocaleStrings.attach_files}</span>
                        <input
                          id="file-upload-idproof"
                          name="file-upload-idproof"
                          type="file"
                          className="sr-only"
                          onChange={(e) => this._handleSelect(e, "idproof")}
                        />
                      </label>
                    </div>
                  </div>
                )}
                <div>
                  <span
                    onClick={(e) =>
                      this._handleDownload(
                        e,
                        this.props.vendorDetails.agreement_template
                      )
                    }>
                    <Field
                      name="agreement_template"
                      label={
                        LocaleStrings.digit_two +
                        ". " +
                        LocaleStrings.agreement_template_duly_filled_and_signed
                      }
                      placeholder={LocaleStrings.attach_document}
                      type="text"
                      component={this.renderFieldText}
                      mandatory="false"
                      isdisabled={true}
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-medium text-secondary"
                    />
                  </span>
                  <div className="flex justify-end">
                    <label
                      htmlFor="file-upload-agreement_template"
                      className="relative btn-primary-outline mt-2 cursor-pointer">
                      <img
                        loading="lazy"
                        className={"h-4 w-4 "}
                        src={"/images2/profile_settings_images/attachfile.svg"}
                      />
                      <span>{LocaleStrings.attach_files}</span>
                      <input
                        id="file-upload-agreement_template"
                        name="file-upload-agreement_template"
                        type="file"
                        className="sr-only"
                        onChange={(e) =>
                          this._handleSelect(e, "agreement_template")
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="flex mt-2">
                  <div
                    className="mx-1 flex flex-col"
                    onClick={(e) =>
                      this._handleDownload(
                        e,
                        "agreement_template_1725346829.doc"
                      )
                    }>
                    <span className="underline text-primaryLight cursor-pointer">
                      {LocaleStrings.download_agreement_template}
                    </span>
                  </div>
                  <img
                    loading="lazy"
                    onClick={(e) =>
                      this._handleDownload(
                        e,
                        "agreement_template_1725346829.doc"
                      )
                    }
                    className={"h-6 w-6 cursor-pointer"}
                    src={"/images2/profile_settings_images/download.svg"}
                  />
                </div>
                {/* Footer */}
                <div className="grid grid-cols-3 gap-4">
                  <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                    <button className="btn-primary" type="submit">
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "hidden",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.update_store_info}
                    </button>
                  </div>
                </div>
                {/* Footer */}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = [
    "automessage",
    "awaystartdate",
    "awayenddate",
    "contactname",
    "criteriaid",
  ];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  // if (!values.email) {
  //   errors.email = LocaleStrings.login_form_validation_email_required;
  // }

  // if (!values.password) {
  //   errors.password = LocaleStrings.login_form_validation_password_required;
  // }
  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  let newObj = {};

  if (state.vendorDetails)
    newObj = {
      commercial_reg_doc: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.commercial_reg_doc
          : ""
        : "",
      agreement_template: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.agreement_template
          : ""
        : "",
      idproof: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.idproof
          : ""
        : "",
    };

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    vendorDetails: state.vendorDetails
      ? state.vendorDetails.data
        ? state.vendorDetails.data
        : []
      : [],
    initialValues: newObj,
  };
}

export default connect(mapStateToProps, {
  uploadMediaLargeStoreVerfic,
  updateStoreUsers,
  fetchUsersDeatils,
})(
  reduxForm({
    validate,
    form: "updateStoreVerifiForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(StoreVerfiEdit)
);
