import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import toast from "react-hot-toast";
import LocaleStrings from "@language";

// Import Actions
import { fetchUsersDeatils, updateStoreUsers } from "@profileSettingsActions";

// Functions Import
import { classNames } from "@commonFunction";

/* This example requires Tailwind CSS v2.0+ */

class SalesPolicyEdit extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      enar: "en",
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { session } = this.props;

    // Fetch Vendor Details With LoginIdStore
    this.props.fetchUsersDeatils(session, (callBackResponce) => {});
  }

  componentWillUnmount() {
    this.state = {
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      enar: "en",
    };
  }

  componentWillReceiveProps(nextProps) {}

  /**** Modal Open Function  ****/

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ isBtnDisable: true });
    this.setState({ btnLoader: true });

    // Extra Validation
    if (values.language && values.language === "0" && values.sales_policy_en) {
      let sales_policy_en_text = values.sales_policy_en
        .replace(/<[^>]*>?/gm, "")
        .replace(/&nbsp;/g, "");

      if (sales_policy_en_text === "") {
        toast.error(LocaleStrings.sales_policy_requires_en);
        this.setState({ isBtnDisable: false });
        this.setState({ btnLoader: false });
        return false;
      }
    }

    if (values.language && values.language === "1" && values.sales_policy_ar) {
      let sales_policy_ar_text = values.sales_policy_ar
        .replace(/<[^>]*>?/gm, "")
        .replace(/&nbsp;/g, "");

      if (sales_policy_ar_text === "") {
        toast.error(LocaleStrings.sales_policy_requires_ar);
        this.setState({ isBtnDisable: false });
        this.setState({ btnLoader: false });
        return false;
      }
    }

    if (
      _.size(values.sales_policy_en) === 0 &&
      _.size(values.sales_policy_ar) === 0
    ) {
      toast.error(LocaleStrings.sales_policy_requires_text);
      this.setState({ isBtnDisable: false });
      this.setState({ btnLoader: false });
      return false;
    }
    // Store Object
    let storeObject = {
      userid: this.props.session.userid,
      sales_policy_en: values.sales_policy_en ? values.sales_policy_en : "",
      sales_policy_ar: values.sales_policy_ar ? values.sales_policy_ar : "",
    };
    // After file upload
    this.props.updateStoreUsers(this.props.session, storeObject, (callback) => {
      if (callback.status === 1) {
        this.setState({ btnLoader: false });
        this.setState({ isBtnDisable: false });
        toast.success(LocaleStrings.sales_policy_successfully);

        // Fetch Vendor Details With LoginIdStore
        this.props.fetchUsersDeatils(
          this.props.session,
          (callBackResponce) => {}
        );
      }
    });
  }

  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  render() {
    let { handleSubmit } = this.props;
    let { enar } = this.state;

    return (
      <>
        <div className="">
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
            >
              <div className="xs:p-2 sm:p-10 space-y-8">
                <div className=""></div>

                {/* About the product */}
                <div style={{ display: "flex" }}>
                  <label className="mr-5 text-box-lable">
                    {LocaleStrings.language}
                  </label>

                  <Field
                    name="language"
                    label={LocaleStrings.en}
                    component={this.renderFieldRadio}
                    type="radio"
                    value="0"
                    className="mx-2"
                    onChange={this.handleInputLanguageChange.bind(this)}
                  />

                  <Field
                    name="language"
                    label={LocaleStrings.ar}
                    component={this.renderFieldRadio}
                    type="radio"
                    value="1"
                    className="mx-2"
                    onChange={this.handleInputLanguageChange.bind(this)}
                  />
                </div>

                <Field
                  name={"sales_policy_" + enar}
                  label={LocaleStrings.legal_docs_list_text3}
                  placeholder={LocaleStrings.legal_docs_list_text3}
                  mandatory="true"
                  type="minimal"
                  component={this.renderRichTextareaWithLabelTinymce}
                  labelposition={LABEL_POSITION_TOP}
                  height={300}
                  classNameLabel="block text-box-lable"
                />
              </div>
              <div className="p-6 border-t-2 border-quaternary">
                <div className="">
                  {/* <p className="text-sm  text-ternary pb-4">{LocaleStrings.app_suggestions_footer_text}</p> */}
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                    <button
                      type="submit"
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? "cursor-not-allowed" : "",
                        "btn-primary"
                      )}
                    >
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.update}
                    </button>
                  </div>
                </div>

                {/* Footer */}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

function validate(values) {
  let regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  var errors = {};
  var requiredFields = ["sales_policy_en", "sales_policy_ar", "language"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user, language, isLoggedIn } = state;
  let newObj = {};
  if (state.vendorDetails)
    newObj = {
      sales_policy_en: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.sales_policy_en
          : ""
        : "",
      sales_policy_ar: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.sales_policy_ar
          : ""
        : "",
      language: "0",
    };

  return {
    session,
    user,
    isLoggedIn,
    language,
    vendorDetails: state.vendorDetails
      ? state.vendorDetails.data
        ? state.vendorDetails.data
        : []
      : [],
    initialValues: newObj,
  };
}

export default connect(mapStateToProps, {
  updateStoreUsers,
  fetchUsersDeatils,
})(
  reduxForm({
    validate,
    form: "SalesPolicyEditForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(SalesPolicyEdit)
);
