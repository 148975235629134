import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import LocaleStrings from "@language";

// Import BaseLoader
import BaseLoader from "@baseLoader";
// Import Component
import LegalDocsList from "./legaldocslist";
// Import Action
import { fetchSettingsMaster } from "@legaldocsActions";

class LegalDocs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_legal_docs,
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchSettingsMaster(this.props.session, (callback) => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>
                <BaseLoader data={this.state.loaderDesc} />
              </>
            ) : (
              <>
                <div className="py-6">
                  <LegalDocsList history={this.props.history} />
                </div>
              </>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {
  fetchSettingsMaster,
})(LegalDocs);
