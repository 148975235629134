import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
// Import BaseLoader

// Import Component

class OrderCountDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_legal_docs,
    };

    // All Binded Functions
  }

  //Arrow function for renderLoader
  componentDidMount() {}

  render() {
    let { vendorDetailedSalesStore } = this.props;

    let newOrdersVal = 0;
    let deliveredVal = 0;
    let returnedVal = 0;

    if (!_.isEmpty(vendorDetailedSalesStore)) {
      newOrdersVal = vendorDetailedSalesStore.stats.neworders;
      deliveredVal = vendorDetailedSalesStore.stats.delivered;
      returnedVal = vendorDetailedSalesStore.stats.returned;
    }

    return (
      <>
        <dl className="space-y-6  text-sm py-6 px-6 mt-6 bg-custWhite">
          <div className="flex justify-between">
            <dt className="font-medium text-secondary">
              {LocaleStrings.open_orders}
            </dt>
            <dd className="text-ternary">+{newOrdersVal}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="font-medium text-secondary">
              {LocaleStrings.delivered_orders}
            </dt>
            <dd className="text-ternary">+{deliveredVal}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="font-medium text-secondary">
              {LocaleStrings.tab_text_returns}
            </dt>
            <dd className="text-ternary">+{returnedVal}</dd>
          </div>
        </dl>
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  vendorDetailedSalesStore: !_.isEmpty(state.vendorDetailedSalesStore)
    ? !_.isEmpty(state.vendorDetailedSalesStore.data)
      ? state.vendorDetailedSalesStore.data
      : []
    : [],
});

export default connect(mapStateToProps, {})(OrderCountDetails);
