import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import moment from "moment-timezone";
import _ from "lodash";
import { SearchIcon } from "@heroicons/react/solid";
import Pagination from "react-js-pagination";

// Import BaseLoader
import BaseLoader from "@baseLoader";

// Import Actions
import {
  salesmanagementStatboxSelected,
  fetchVendorOrderList,
} from "@salesmanagementActions";
import { onSidebarMenuSelected } from "@sidebarActions";

// Component Import
import NewOrderTableList from "./neworderlist";
import NewOrderDetails from "./components/neworderdetails";

// Import constant
import { PERSONAL_USER_REDIRECT_TO } from "@constant";

const limit = 10;

class SalesNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_neworders,
      newOrderFilter: "all", // a => All / p => Order Placed / d => Order Dispatched
      pageNumber: 1,
      searchText: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { session } = this.props;
    let { usertype } = session;
    let { pageNumber, newOrderFilter } = this.state;

    if (usertype === "personal") {
      this.props.onSidebarMenuSelected(2);
      this.props.history.push(PERSONAL_USER_REDIRECT_TO);
    }
    /*
      Call API to Fetch Data

      New Order,DeliveredReplace/Return/Cancel
      vendor-datascript/orders
      Params: {"status": "<you know>","usertype":"web","type":"listing","limit":<num of rows>,"page":<cur page>} => For Listing
      {"status": "<you know>","usertype":"web","type":"search","searchterm":<text>} => For Listing
    
      Value for New Orders
      all|dispatched|placed
      */
    this.props.fetchVendorOrderList(
      this.props.session,
      "new", // status
      "web", // usertype
      "listing", // type
      limit, // <limit :num of rows>
      pageNumber, // <page : cur page>
      "", // searchterm _>
      newOrderFilter, // filter
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  componentWillUnmount() {
    this.setState({
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_neworders,
      newOrderFilter: "all", // a => All / p => Order Placed / d => Order Dispatched
      pageNumber: 1,
      searchText: "",
    });
  }

  /* Pagination call back */
  paginationCallback = (pageNumber) => {
    let { newOrderFilter } = this.state;
    this.setState({ loading: true });

    this.props.fetchVendorOrderList(
      this.props.session,
      "new", // status
      "web", // usertype
      "listing", // type
      limit, // <limit :num of rows>
      pageNumber, // <page : cur page>
      "", // searchterm _>
      newOrderFilter, // filter
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    let { userid } = this.props;
    let { newOrderFilter } = this.state;
    var searchText = event.target.value;
    var pageNumber = 1;

    if (searchText === "") {
      // this.setState({ loading: true });
      /* 
      Paginated list
       Params: {"status": "<you know>","usertype":"web","type":"listing","limit":<num of rows>,"page":<cur page>} => For Listing
      */
      this.props.fetchVendorOrderList(
        this.props.session,
        "new", // status
        "web", // usertype
        "listing", // type
        limit, // <limit :num of rows>
        pageNumber, // <page : cur page>
        "",
        newOrderFilter, // filter
        (CallBackResponse) => {
          // this.setState({ loading: false });
        }
      );
    } else {
      /* 
      search list without pagination
       {"status": "<you know>","usertype":"web","type":"search","searchterm":<text>} => For Listing
      */
      this.props.fetchVendorOrderList(
        this.props.session,
        "new", // status
        "web", // usertype
        "search", // type
        limit, // <limit :num of rows>
        pageNumber, // <page : cur page>
        searchText, // searchterm
        newOrderFilter, // filter
        (CallBackResponse) => {
          // this.setState({ loading: false });
        }
      );
    }
    this.setState({ searchText, pageNumber });
  };

  /***** Handle List Local Filte *****/
  _handleFilterList(e, param) {
    this.setState({ loading: true });

    let { pageNumber } = this.state;
    // a => All / p => Order Placed / d => Order Dispatched

    this.props.fetchVendorOrderList(
      this.props.session,
      "new", // status
      "web", // usertype
      "listing", // type
      limit, // <limit :num of rows>
      pageNumber, // <page : cur page>
      "",
      param, // filter
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );

    this.setState({ newOrderFilter: param });
  }

  // Back Button Click
  _handleBack() {
    this.props.salesmanagementStatboxSelected("");
    this.props.history.push("/dashboard/salesmanagement");
  }

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  // Render Order List Tbody
  renderNewOrderList() {
    let { vendorOrderListStore } = this.props;
    let { searchText, pageNumber } = this.state;
    let pageDataLength = vendorOrderListStore.data.length;

    return _.map(vendorOrderListStore.data, (orderObj, index) => {
      return (
        <NewOrderTableList
          key={orderObj.orderid}
          orderObj={orderObj}
          position={index}
          callFrom="new"
          pagination={this.paginationCallback}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
        />
      );
    });
  }

  render() {
    let newOrderFilter = this.state.newOrderFilter;
    let isSelePlacFiltIcon =
      newOrderFilter === "placed" ? "placed" : "placed_black";
    let isSeleDeliFiltIcon =
      newOrderFilter === "dispatched" ? "deliverby-light" : "deliverby";

    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>{this.renderLoader()}</>
            ) : (
              <>
                <div className="py-6 bg-custWhite ">
                  {/* Header Part */}
                  <div className="grid gap-4 grid-cols-1 px-6 md:grid-cols-3 gap-3 sm:grid-cols-2 ">
                    <div align="left" className="flex icon-hover-transition">
                      <img
                        loading="lazy"
                        className={" h-6 w-6 cursor-pointer"}
                        src={"/images2/common/arrow.svg"}
                        onClick={(e) => this._handleBack(e, "")}
                      />
                      <p
                        className="text-primary mx-2 text-l font-bold cursor-pointer"
                        onClick={(e) => this._handleBack(e, "")}>
                        {LocaleStrings.open_orders}
                      </p>
                    </div>
                    <div className="justify-items-center">
                      <label htmlFor="search-field" className="sr-only">
                        {LocaleStrings.search}
                      </label>
                      <div className="relative w-full text-ternary focus-within:text-secondary ">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="bg-quaternary revenue-report-search-field"
                          placeholder={LocaleStrings.search}
                          type="search"
                          name="search"
                          value={this.state.searchText}
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                    <div className="mx-5 mt-2 flex gap-4 flex-row-reverse ">
                      {/* // a => All / p => Order Placed / d => Order Dispatched */}
                      <div className="group relative">
                        <img
                          loading="lazy"
                          className={
                            " h-6 w-6 cursor-pointer icon-hover-transition "
                          }
                          src={
                            "/images2/sales_managemnet_images/" +
                            isSeleDeliFiltIcon +
                            ".svg"
                          }
                          onClick={(e) =>
                            this._handleFilterList(e, "dispatched")
                          }
                        />{" "}
                        <div className="group-hover:opacity-100 transition-opacity -left-6  px-1  bg-primary text-white rounded absolute  -top-6 -translate-x-1/2 translate-y-full opacity-0 mx-auto  text-xs">
                          Dispatched
                        </div>
                      </div>
                      |
                      <div className="group relative">
                        <img
                          loading="lazy"
                          className={
                            " h-6 w-6 cursor-pointer icon-hover-transition"
                          }
                          src={
                            "/images2/sales_managemnet_images/" +
                            isSelePlacFiltIcon +
                            ".svg"
                          }
                          onClick={(e) => this._handleFilterList(e, "placed")}
                        />
                        <div className="group-hover:opacity-100 transition-opacity -left-6  px-1  bg-primary text-white rounded absolute  -top-6 -translate-x-1/2 translate-y-full opacity-0 mx-auto  text-xs">
                          Placed
                        </div>
                      </div>
                      |
                      <span
                        onClick={(e) => this._handleFilterList(e, "all")}
                        className={
                          (newOrderFilter === "all"
                            ? "text-primary"
                            : "text-ternary") +
                          " cursor-pointer icon-hover-transition"
                        }>
                        {LocaleStrings.all}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" bg-quaternary pt-2 pb-8 px-4 sm:px-6 lg:pt-2 lg:pb-8 lg:px-8">
                  <div className=" max-w-7xl mx-auto mt-10">
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full ">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            {_.isEmpty(this.props.vendorOrderListStore.data) ? (
                              <div className="center-item lg:my-48 text-lg text-ternary">
                                {LocaleStrings.no_result_found}
                              </div>
                            ) : (
                              <>
                                <table className="min-w-full divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.title}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_id}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_date}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_by}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_total}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.qty}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.status}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="bg-white divide-y divide-gray-200">
                                    {this.renderNewOrderList()}
                                  </tbody>
                                </table>
                                {this.state.searchText === "" ? (
                                  <Pagination
                                    activePage={this.state.pageNumber}
                                    itemsCountPerPage={limit}
                                    totalItemsCount={parseInt(
                                      this.props.vendorOrderListStore.count
                                    )}
                                    pageRangeDisplayed={limit}
                                    onChange={this.paginationCallback}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <NewOrderDetails
                  headerText={LocaleStrings.order_details}
                  orderCallFrom={"new"}
                />
              </>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    salesmanStatboxSelected: state.salesmanStatboxSelected
      ? state.salesmanStatboxSelected
      : "",
    vendorOrderListStore: _.isEmpty(state.vendorOrderListStore)
      ? {}
      : state.vendorOrderListStore,
  };
}

export default connect(mapStateToProps, {
  salesmanagementStatboxSelected,
  fetchVendorOrderList,
  onSidebarMenuSelected,
})(SalesNew);
