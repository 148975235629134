import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
import Pagination from "react-js-pagination";

//Import Actions
import { fetchVendorDetailedRevenue } from "@revenuereportsActions";

import { updateMRP, ProductTextLocalized } from "@commonFunction";

import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";

class ReveneuTableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_legal_docs,
    };

    // All Binded Functions
  }

  renderProductList() {
    let { vendorDetailedRevenueStore, language, localCurrency } = this.props;
    let currency = currencyCode(localCurrency);
    //let currencyrate = currencyRate(localCurrency);

    return _.map(vendorDetailedRevenueStore.items, (productObj, index) => {
      var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${productObj.productimage}?api_key=${APP_API_KEY}`;
      let variant = _.isEmpty(productObj.product_variant)
        ? "-"
        : ProductTextLocalized(
            productObj.product_variant.name_en,
            productObj.product_variant.name_ar,
            language
          );
      var cardTitle = ProductTextLocalized(
        productObj.productname_en,
        productObj.productname_ar,
        language
      );
      let cardDesc = ProductTextLocalized(
        productObj.product_desc_en,
        productObj.product_desc_ar,
        language
      );
      var variantName = ProductTextLocalized(
        productObj.variant.name_en,
        productObj.variant.name_ar,
        language
      );

      // Convert the itemPrice discounted amount

      /* Product currency */
      let orderCurrency = productObj.odcurrency;

      // let sold_price = isCurrencyRateApplicable(
      //   localCurrency,
      //   orderCurrency,
      //   productObj.sold_price
      // );
      let sold_price = productObj.sold_price;

      return (
        <tr
          id={`table-list-${index}`}
          key={`table-list-${index}`}
          className="table-hover-transition"
        >
          <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  loading="lazy"
                  className="h-10 w-10 rounded-full"
                  src={productImageUrl}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-secondary">
                  {cardTitle}
                  {/* {cardTitle.length > 30
                    ? cardTitle.substring(0, 30) + "..."
                    : cardTitle} */}
                </div>
                <div className="text-sm text-ternary">
                  {cardDesc.length > 60
                    ? cardDesc.substring(0, 60) + "..."
                    : cardDesc}
                </div>
                <div className="text-sm text-ternary">{variant}</div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
            {productObj.hasvariant == '1' ?(
              <>{variantName}</>
            ):(
              'No Variant'
            )}
            <div className="text-sm text-ternary"></div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
            <div className="text-sm text-ternary">
              {currency} {sold_price}
            </div>
          </td>
        </tr>
      );
    });
  }

  paginationCallback = (pageNumber) => {
    let { userid, source, limit, searchText, index, tab, startdate, enddate } =
      this.props;

    /*
    Revenue Report
    vendor-datascript/revenuestats => Listing
    New Params : {"index": 0,"tab": "all","source":"web","limit":<number of rows to show>,"page":<current page>}
    vendor-datascript/revenuesearch => Search
    Params: {searchterm:<text>}
    */

    this.props.fetchVendorDetailedRevenue(
      this.props.session,
      index, // Index
      tab, // tab
      source,
      limit,
      pageNumber, // page
      searchText, // searchterm
      startdate,
      enddate,
      (callBack) => {}
    );

    this.props.updatePageNumber(pageNumber);
  };

  render() {
    let itemTotalSold = 0;

    let { vendorDetailedRevenueStore, localCurrency } = this.props;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    if (!_.isEmpty(vendorDetailedRevenueStore)) {
      itemTotalSold = vendorDetailedRevenueStore.stats.total_sold;
    }

    return (
      <>
        {/* Footer Part Start*/}
        <div className="w-full bottom-0 py-6">
          <div className="bg-custWhite rounded p-6 mb-10">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                  >
                    {LocaleStrings.title}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                  >
                    {LocaleStrings.variant}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider"
                  >
                    {LocaleStrings.deal_amount} ({currency})
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {this.renderProductList()}
              </tbody>
            </table>
            {this.props.searchText === "" ? (
              <Pagination
                activePage={this.props.pageNumber}
                itemsCountPerPage={this.props.limit}
                totalItemsCount={parseInt(vendorDetailedRevenueStore.count)}
                pageRangeDisplayed={this.props.limit}
                onChange={this.paginationCallback}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Footer Part Ends*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, isLoggedIn, language, currency } = state;

  return {
    session,
    user,
    revenueSalesDateTabs: state.revenueSalesDateTabs,
    vendorDetailedRevenueStore: !_.isEmpty(state.vendorDetailedRevenueStore)
      ? !_.isEmpty(state.vendorDetailedRevenueStore.data)
        ? state.vendorDetailedRevenueStore.data
        : []
      : [],
    salesSearchDetailsStore: !_.isEmpty(state.salesSearchDetailsStore)
      ? !_.isEmpty(state.salesSearchDetailsStore.data)
        ? state.salesSearchDetailsStore.data
        : []
      : [],
    localCurrency: currency,
  };
}

export default connect(mapStateToProps, { fetchVendorDetailedRevenue })(
  ReveneuTableList
);
