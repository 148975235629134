import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import LocaleStrings from "@language";
import {
  classNames,
  ProductTextLocalized,
  getTotalPriceToPay,
  getTotalTaxToPay,
  priceConversion,
} from "@commonFunction";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
//Import Actions
import {
  isRepaymentModalOpen,
  getlistingpriceAmount,
  makepaymentPersonalvendor,
} from "@productmanagementActions";
import { fetchUsersDeatils } from "@profileSettingsActions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";

import { CURRENCY_RATE_QR } from "@constant";

import toast from "react-hot-toast";

function RepaymentModal(props) {
  const history = useHistory();
  const [btnLoader, setBtnLoader] = useState(false);
  let {
    session,
    isRepaymentOpen,
    vendorDetails,
    localCurrency,
    settingsMasterStore,
  } = props;

  let listingTotal = 0;
  let tax_n_charges = 0;
  let currency = currencyCode(localCurrency);
  let currencyrate = currencyRate(localCurrency);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    props.fetchUsersDeatils(session, (CallBackResponse) => {});
    props.getlistingpriceAmount(session, (CallBackResponse) => {});
    return () => {
      setBtnLoader(false);
      props.isRepaymentModalOpen(false);
    };
  }, []);

  const closeModal = () => {
    // Close Modal
    props.isRepaymentModalOpen(false);
  };

  const product_listingprice = isCurrencyRateApplicable(
    localCurrency,
    "QAR",
    settingsMasterStore.listing_perproduct
  );

  const openPost = (url, params) => {
    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    formElement.setAttribute("action", url);
    formElement.setAttribute("target", "_parent");
    for (let param in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("name", param);
      hiddenField.setAttribute("value", params[param]);
      formElement.appendChild(hiddenField);
    }

    document.body.appendChild(formElement);
    formElement.submit();
  };
  // old code
  // const makeRepayment = () => {
  //   /* Repayment with all deatils */
  //   let { session, productDetails, localCurrency, settingsMasterStore } = props;
  //   let currency = currencyCode(localCurrency);
  //   let currencyrate = currencyRate(localCurrency);

  //   setBtnLoader(true);
  //   let totalAmountToPAy = getTotalPriceToPay(
  //     settingsMasterStore,
  //     tax_n_charges
  //   );

  //   /* If persobal user charge for listing product [Send to payment page] */
  //   let orderids = session.userid + "o" + productDetails.id; // this should be useridoproductid, userid and productid concatenated with o

  //   /* Create a product array similar to this, you can send quantity=1, price= actual payment price */
  //   let productArray = [
  //     {
  //       productname_en: productDetails.productname_en,
  //       price: totalAmountToPAy,
  //       quantity: 1,
  //     },
  //   ];

  //   var params = [];
  //   // OLD CODE params["total_amount"] = totalAmountToPAy / currencyrate; // send the Total Payment price here
  //   params["total_amount"] =
  //     localCurrency === "USD"
  //       ? totalAmountToPAy * CURRENCY_RATE_QR
  //       : totalAmountToPAy; // send the Total Payment price here
  //   params["userid"] = session.userid;
  //   params["orderids"] = orderids;
  //   params["products"] = JSON.stringify(productArray);
  //   params["origin"] = "web";

  //   setBtnLoader(false);
  //   openPost("https://shopez.teks.co.in/payment/personalpay.php", params); // Replace this with https://shopez.teks.co.in/payment/personalpay.php
  // };

  // latest code
  const makeRepayment = () => {
    /* Repayment with all deatils */
    let { session, productDetails, listingamount, relist } = props;

    setBtnLoader(true);
    let params = {};
    params.amount = listingamount;
    params.productid = productDetails.id;
    params.variantid = productDetails.variantid;
    params.callfrom = "web";
    // params.paytype = "makepayment";
    params.paytype = "makepersonalpayment"; //latest
    params.relist = relist;
    params.callbackurl = `http://shopezvendor.staging.teks.co.in/dashboard/productmanagement/product-details/${productDetails.id}-${productDetails.variantid}`;

    props.makepaymentPersonalvendor(session, params, (callback) => {
      if (callback.success == 1) {
        // toast.error('Payment Failed , something went wrong2');
        if (
          callback.data &&
          (callback.data.success == 1 || callback.data.success == true)
        ) {
          if (
            callback.data.checkout_url &&
            (callback.data?.checkout_url !== null ||
              callback.data?.checkout_url !== "")
          ) {
            // console.log('callback',callback)
            let checkouturl = callback.data?.checkout_url;
            setBtnLoader(false);
            window.location.href = checkouturl;
          } else {
            setBtnLoader(false);
            toast.error(LocaleStrings.repayment_nourlfound);
          }
        } else {
          setBtnLoader(false);
          toast.error(LocaleStrings.repayment_paymentfailed);
        }
      } else {
        setBtnLoader(false);
        toast.error(LocaleStrings.repayment_paymentfailed);
      }
    });
  };

  const getPrice = (data, priceToCal) => {
    if (data.listingfee_type === "percentage") {
      return parseFloat(priceToCal) * (data.listing_perproduct / 100);
    } else {
      return data.listing_perproduct;
    }
  };

  // Tax & Charges
  //OLD CODE tax_n_charges = parseFloat(vendorDetails.tax * currencyrate).toFixed(2);
  tax_n_charges = parseFloat(vendorDetails.tax).toFixed(2);

  /**** Render Detaild variant List ****/

  const renderDetailedVariantsList = () => {
    let { productDetails, language } = props;

    return _.map(productDetails.all_variants, (item, index) => {
      listingTotal = parseFloat(listingTotal + parseFloat(item.price_unit));

      return (
        <tr key={`detailedvariants-${index}`} className="">
          <td className="sales-table-td">
            <div className="sales-table-td-text">
              {ProductTextLocalized(item.name_en, item.name_ar, language)}
            </div>
          </td>
          <td className="sales-table-td">
            <div className="sales-table-td-text capitalize">
              {/* OLD CODE {currency} {parseFloat(item.price_unit * currencyrate).toFixed(2)} */}
              {currency} {parseFloat(item.price_unit).toFixed(2)}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <Transition.Root show={isRepaymentOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isRepaymentOpen}
        onClose={() => closeModal()}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
              style={{ padding: 0 }}>
              {/* Modal Header */}
              <div className="grid grid-cols-3 border-b-2 border-quaternary pb-4 sticky-header z-10">
                <div></div>
                <div className="flex flex-wrap content-center justify-center mt-4">
                  <div className="text-lg leading-6 font-medium text-secondary ml-2">
                    {LocaleStrings.repayment_screen_header_text}
                  </div>
                </div>

                <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                  <button
                    type="button"
                    className="btn-closeModal"
                    onClick={() => closeModal()}>
                    <span className="sr-only">{LocaleStrings.close}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <div className="p-6">
                {/* Table of "detailedvariants" */}
                <div className="flex flex-col mt-6">
                  <div className="-my-2 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full ">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="sales-table-th">
                                {
                                  LocaleStrings.product_fees_listing_detailed_variants_thead_text1
                                }
                              </th>
                              <th scope="col" className="sales-table-th">
                                {
                                  LocaleStrings.product_fees_listing_detailed_variants_thead_text2
                                }
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {renderDetailedVariantsList()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="block mt-4">
                  <p className="text-xs font-semibold text-secondary">
                    {LocaleStrings.listing_fee}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4 ">
                  <div>
                    <p className="text-xs font-semibold text-secondary">
                      {LocaleStrings.product_listing_price}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-ternary text-right">
                      {" "}
                      {currency}{" "}
                      {/*
                       Code listing fee [Don't remove]
                       {parseFloat(
                        (getPrice(settingsMasterStore, listingTotal) +
                          listingTotal) *
                          currencyrate
                      ).toFixed(2)} */}
                      {/* {settingsMasterStore.listing_perproduct.toFixed(2)} */}
                      {/* latestcode */}
                      {/* {product_listingprice.toFixed(2)} */}
                      {props.listingamount !== undefined ? (
                        <>{props.listingamount}</>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-secondary">
                      {/* {LocaleStrings.tax_charges}
                      {parseFloat(vendorDetails.tax)}% */}
                    </p>
                  </div>
                  {/* <div>
                    <p className="text-xs text-ternary text-right">
                      {currency}
                      {getTotalTaxToPay(settingsMasterStore, vendorDetails.tax)}
                    </p>
                  </div> */}
                  {/* Devide */}
                  <div
                    className="col-span-2 inset-0 flex items-center"
                    aria-hidden="true">
                    <div className="w-full border-t border-ternary"></div>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-secondary">
                      {LocaleStrings.total}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-ternary text-right">
                      {currency}{" "}
                      {/* {(
                         Old listing fee [Don't delete]
                        parseFloat(
                          (getPrice(settingsMasterStore, listingTotal) +
                            listingTotal) *
                            currencyrate
                        ) + parseFloat(tax_n_charges * currencyrate)
                      ).toFixed(2)} */}
                      {/* {getTotalPriceToPay(settingsMasterStore, tax_n_charges)} */}
                      {/* latestcode */}
                      {/* {product_listingprice.toFixed(2)} */}
                      {/* {props.listingamount.toFixed(2)} */}
                      {props.listingamount !== undefined ? (
                        <>{props.listingamount}</>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  {/* Devide */}
                  <div
                    className="col-span-2 inset-0 flex items-center"
                    aria-hidden="true">
                    <div className="w-full border-t border-ternary"></div>
                  </div>
                  {/* Tax Inclusive */}
                  <div className="col-span-2 " aria-hidden="true">
                    <p className="text-base font-semibold text-primary text-right">
                      {currency}{" "}
                      {/* {getTotalPriceToPay(settingsMasterStore, tax_n_charges)} */}
                      {/* latestcode */}
                      {/* {product_listingprice.toFixed(2)} */}
                      {/* {props.listingamount.toFixed(2)} */}
                      {props.listingamount !== undefined ? (
                        <>{props.listingamount}</>
                      ) : (
                        ""
                      )}
                      {localCurrency == "USD" ? (
                        <div className="text-right text-cardfooter font-normal text-xl">
                          (= QAR
                          {priceConversion(
                            localCurrency,
                            "QAR",
                            props.listingamount,
                            CURRENCY_RATE_QR
                          )}
                          )
                        </div>
                      ) : (
                        ""
                      )}
                    </p>
                    <p className="text-xs font-semibold text-ternary">
                      {LocaleStrings.tax_inclusive_text}-
                    </p>
                  </div>
                  {/* Approval note */}
                  <div className="col-span-2 ">
                    <p className="text-xs text-ternary">
                      {LocaleStrings.productfee_screen_listing_note_text}1
                    </p>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <br />

              <div className="p-6 border-t-2 border-quaternary center-item">
                {/* FOOTER STARTS*/}
                <button
                  type="button"
                  disabled={btnLoader}
                  className={classNames(
                    btnLoader ? "cursor-not-allowed" : "",
                    "btn-primary"
                  )}
                  onClick={() => makeRepayment()}>
                  <svg
                    className={classNames(
                      btnLoader ? "" : "hidden",
                      "cust-btn-spinner"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>

                  <span className="text-sm">{LocaleStrings.repayment_btn}</span>
                </button>
                {/* FOOTER ENDS*/}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localCurrency: state.currency,
    isRepaymentOpen: state.isRepaymentOpen,
    productDetails: state.productDetails ? state.productDetails : [],
    vendorDetails: !_.isEmpty(state.vendorDetails)
      ? !_.isEmpty(state.vendorDetails.data)
        ? state.vendorDetails.data
        : []
      : [],
    settingsMasterStore: state.settingsMasterStore
      ? state.settingsMasterStore.data !== "undefined"
        ? state.settingsMasterStore.data
        : []
      : [],
    listingamount: state.listingamount
      ? state.listingamount.data !== "undefined"
        ? state.listingamount.data
        : 0
      : 0,
  };
};

export default connect(mapStateToProps, {
  isRepaymentModalOpen,
  fetchUsersDeatils,
  getlistingpriceAmount,
  makepaymentPersonalvendor,
})(RepaymentModal);
