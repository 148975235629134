import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import _, { create } from "lodash";

import {
  ProductTextLocalized,
  getIntToFloat,
  converDateIntoLocal,
  classNames,
  getBilangualeText,
} from "@commonFunction";

import {
  APP_API_KEY,
  BASE_IMAGES_URL,
  DATE_FORMAT_TO_SHOW,
  validateTrackingnumber,
} from "@constant";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { fetchUsersDeatils, fetchStoreDetails } from "@profileSettingsActions";
import moment from "moment";
// Import BaseLoader
import BaseLoader from "@baseLoader";

// Import Actions
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";
import {
  salesmanagementStatboxSelected,
  toggleNewOrderDetailsModal,
  updateorder,
  fetchVendorOrderList,
  createOrOpenOrderThread,
  updateinitiateRefund,
  partnerScriptorder,
  downloadorderInvoice,
} from "@salesmanagementActions";
import {
  changeMessageTabName,
  isSendMessageModalOpen,
  getOrderThreadWithId,
  fetchOrderThreadMsgList,
  updateBasicOrderthreadData,
  updateOrderStep,
} from "@sidebarActions";

// Component Import
import ShippingStatus from "./shippingstatus";
import AllReasonComponent from "./allreasoncomponent";
import ReviewAndRatingLimited from "@commonFolder/review-and-rating-limited.js";

/* This example requires Tailwind CSS v2.0+ */
import { Transition, Dialog } from "@headlessui/react";
import {
  XIcon,
  ArrowCircleUpIcon,
  ArrowCircleDownIcon,
  ClipboardCopyIcon,
} from "@heroicons/react/outline";
import { da } from "date-fns/locale";

class NewOrderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_negotiationsoffers,
      negoSearchText: "",
      updateordStateBox: true,
      firstTimeOpeing: true, // DidMount Type [Flag variable]

      /* Button Loader */
      markAsShiptedBtnLoader: false,
      cancelOrderBtnLoader: false,
      inTransitBtnLoader: false,
      markAsDeliveredBtnLoader: false,
      initiatPickUpBtnLoader: false,
      confirmPickupBtnLoader: false,
      markReceivedBtnLoader: false,
      initiateRefundBtnLoader: false,
      downloadInvoiceBtnLoader: false,
      copedLoader: false,
    };

    // Refrence variable
    this.myRef = React.createRef();

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.closeModal1 = this.closeModal1.bind(this);
    this.updateordStateBoxToggle = this.updateordStateBoxToggle.bind(this);
    this._handelCancelOrder = this._handelCancelOrder.bind(this);
    this._handelInitiateRefund = this._handelInitiateRefund.bind(this);
    this._handelMarkAsShipted = this._handelMarkAsShipted.bind(this);
    this._handelInTransit = this._handelInTransit.bind(this);
    this._handelMarkDelivered = this._handelMarkDelivered.bind(this);
    this._handelInitiatePickup = this._handelInitiatePickup.bind(this);
    this._handelReceived = this._handelReceived.bind(this);
    this._handelConfirmPicup = this._handelConfirmPicup.bind(this);
    this.openImagesInLightBox = this.openImagesInLightBox.bind(this);
    this._handelDownloadInvoice = this._handelDownloadInvoice.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setState({
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_negotiationsoffers,
      negoSearchText: "",
      updateordStateBox: true,

      markAsShiptedBtnLoader: false,
      cancelOrderBtnLoader: false,
      inTransitBtnLoader: false,
      markAsDeliveredBtnLoader: false,
      initiatPickUpBtnLoader: false,
      confirmPickupBtnLoader: false,
      markReceivedBtnLoader: false,
      initiateRefundBtnLoader: false,
      downloadInvoiceBtnLoader: false,

      copedLoader: false,
    });
  }
  componentDidMount() {
    this.props.fetchStoreDetails(this.props.session, (callback) => {});
    this.props.fetchUsersDeatils(this.props.session, (callback) => {});
  }
  /*** Copy email to clicpboard ****/
  copyEmailToClipboard = (evant, emailid) => {
    this.setState({ copedLoader: true });
    navigator.clipboard.writeText(emailid);

    setTimeout(() => {
      this.setState({ copedLoader: false });
    }, 1000);
  };

  /**** Toggle Update Order Status Box  ****/
  updateordStateBoxToggle() {
    let updateordStateBox = this.state.updateordStateBox;
    this.setState({ updateordStateBox: !updateordStateBox });
  }

  closeModal1() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this.myRef.current.focus();
  }

  /**** Close Slide Over  ****/
  closeModal() {
    if (this.props.setImagesForLightBOx) {
      this.props.setImagesForLightBOx([]); // Reinitialize feedback iamges as []
    }

    this.setState({ firstTimeOpeing: true }); // Reinitializing
    this.props.toggleNewOrderDetailsModal(false);
  }

  /**** If vendor Cancel Order ****/
  _handelCancelOrder() {
    let { innerTab, orderCallFrom } = this.props;
    let fetchstatus = "new";
    if (orderCallFrom == "delivered") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else {
        fetchstatus = "replace";
      }
    }

    let cancelReasonVal = _.trim(
      document.querySelector("#cancel_reason_inputbox").value
    );
    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "cancelled",
      cancelreason: cancelReasonVal,
    };

    if (
      cancelReasonVal === "" ||
      cancelReasonVal === "null" ||
      cancelReasonVal === null
    ) {
      toast.error(LocaleStrings.cancel_reason_error_text);
    } else {
      // Order Cancel Confirmation from Vendor
      confirmAlert({
        title: LocaleStrings.alert_head_cancel,
        message: `${LocaleStrings.alert_order_cancel_subtext}`,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              this.setState({ cancelOrderBtnLoader: true });
              // Yes
              this.props.updateorder(
                this.props.session,
                dataObj,
                (callBack) => {
                  if (
                    callBack.status === true ||
                    callBack.status === 1 ||
                    callBack.status
                  ) {
                    toast.success(LocaleStrings.cancel_reason_successfully);
                    // Refresh Order List
                    // this.props.fetchVendorOrderList(this.props.session, "new"); // added as with view linked order it is getting called from different tabs
                    this.props.fetchVendorOrderList(
                      this.props.session,
                      fetchstatus
                    );
                    // Close Modal
                    this.closeModal();
                    this.setState({ cancelOrderBtnLoader: false });
                  }
                }
              );
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {
              this.setState({ cancelOrderBtnLoader: false });
            },
          },
        ],
      }); // Confirmation End
    }
  }

  /**** If Vendor Initiate Refund ****/
  _handelInitiateRefund() {
    let { orderCallFrom, innerTab, currentOrderObj } = this.props;

    let fetchstatus = "new";
    if (orderCallFrom == "deliverd") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else {
        fetchstatus = "replace";
      }
    }

    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    // old code
    // let dataObj = {
    //   orderid: orderidVal,
    //   orderdetailsid: orderdetailsidVal,
    //   status: innerTab === 0 ? "cancelrefund" : "refund",
    // };
    // latest code
    let dataObj = {
      paytype: "refund",
      status: currentOrderObj?.product?.currentstatus?.status,
      orderdetailsid: orderdetailsidVal,
    };
    // Refund Initiate Confirmation from Vendor
    confirmAlert({
      title: LocaleStrings.initiate_refund,
      message: `${LocaleStrings.alert_refund_initiate_subtext}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.setState({ initiateRefundBtnLoader: true }); // Button Loader

            this.props.updateinitiateRefund(
              this.props.session,
              dataObj,
              (callBack) => {
                if (
                  callBack.status === true ||
                  callBack.status === 1 ||
                  callBack.status
                ) {
                  toast.success(LocaleStrings.refund_initiated_successfully);
                  // Refresh Order List
                  this.props.fetchVendorOrderList(
                    this.props.session,
                    // innerTab === 0
                    //   ? "cancelled"
                    //   : innerTab === 1
                    //     ? "return"
                    //     : "replace", // status
                    fetchstatus, //added as with view linked order it is getting called from different tabs
                    "web", // usertype
                    "listing", // type
                    10, // <limit :num of rows>
                    1, // <page : cur page>
                    "", // searchterm _>
                    "all", // filter
                    (CallBackResponse) => {
                      this.setState({ loading: false });
                      this.closeModal();
                    }
                  );

                  // this.props.updatePageNumber(1);
                  // this.props.updateSearchText("");
                  // this.props.updateFilter("all");

                  // Close Modal
                }

                this.setState({ initiateRefundBtnLoader: false }); // Button Loader
              }
            );
            // Yes
            // this.props.updateorder(this.props.session, dataObj, (callBack) => {
            //   if (
            //     callBack.status === true ||
            //     callBack.status === 1 ||
            //     callBack.status
            //   ) {
            //     toast.success(LocaleStrings.refund_initiated_successfully);

            //     // Refresh Order List
            //     this.props.fetchVendorOrderList(
            //       this.props.session,
            //       innerTab === 0
            //         ? "cancelled"
            //         : innerTab === 1
            //         ? "return"
            //         : "replace", // status
            //       "web", // usertype
            //       "listing", // type
            //       10, // <limit :num of rows>
            //       1, // <page : cur page>
            //       "", // searchterm _>
            //       "all", // filter
            //       (CallBackResponse) => {
            //         this.setState({ loading: false });
            //       }
            //     );

            //     this.props.updatePageNumber(1);
            //     this.props.updateSearchText("");
            //     this.props.updateFilter("all");

            //     // Close Modal
            //     this.closeModal();
            //   }

            //   this.setState({ initiateRefundBtnLoader: false }); // Button Loader
            // });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {
            this.setState({ initiateRefundBtnLoader: false });
          },
        },
      ],
    }); // Confirmation End
  }

  /**** From Return Pickup -> Return Received [Btn text mark as received ]****/
  _handelReceived() {
    this.setState({ markReceivedBtnLoader: true });

    let { innerTab, orderCallFrom, currentOrderObj } = this.props;

    let fetchstatus = "new";
    if (orderCallFrom == "deliverd") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else {
        fetchstatus = "replace";
      }
    }

    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "returnreceived",
    };

    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        if (
          currentOrderObj.hasOwnProperty("relatedorder") &&
          currentOrderObj?.summary?.refund_amount > 0
        ) {
          this._handelInitiateRefund_fromMarkreceived("returnreceived");
        } else {
          this.setState({ markReceivedBtnLoader: false });
          toast.success(LocaleStrings.marked_received_successfully);

          // Refresh Order List
          this.props.fetchVendorOrderList(
            this.props.session,
            // "return", // status
            fetchstatus, //added as with view linked order it is getting called from different tabs
            "web", // usertype
            "listing", // type
            10, // <limit :num of rows>
            1, // <page : cur page>
            "", // searchterm _>
            "all", // filter
            (CallBackResponse) => {
              this.setState({ loading: false });
              this.closeModal();
            }
          );

          this.props.updatePageNumber(1);
          this.props.updateSearchText("");
          this.props.updateFilter("all");

          // Close Modal
          this.closeModal();
        }
      } else {
        toast.error(LocaleStrings.something_went_wrong);
        this.setState({ markReceivedBtnLoader: false });
      }
    });
  }

  _handelInitiateRefund_fromMarkreceived(status_sent) {
    let { orderCallFrom, innerTab, currentOrderObj } = this.props;

    let fetchstatus = "new";
    if (orderCallFrom == "deliverd") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else {
        fetchstatus = "replace";
      }
    }

    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    // old code
    // let dataObj = {
    //   orderid: orderidVal,
    //   orderdetailsid: orderdetailsidVal,
    //   status: innerTab === 0 ? "cancelrefund" : "refund",
    // };
    // latest code
    let dataObj = {
      paytype: "refund",
      status: status_sent,
      orderdetailsid: orderdetailsidVal,
    };
    // Refund Initiate Confirmation from Vendor

    this.props.updateinitiateRefund(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        toast.success(LocaleStrings.refund_initiated_successfully);
        // Refresh Order List
        this.props.fetchVendorOrderList(
          this.props.session,
          fetchstatus, //added as with view linked order it is getting called from different tabs
          "web", // usertype
          "listing", // type
          10, // <limit :num of rows>
          1, // <page : cur page>
          "", // searchterm _>
          "all", // filter
          (CallBackResponse) => {
            this.setState({ markReceivedBtnLoader: false });
            this.setState({ loading: false });
            this.closeModal();
          }
        );
      } else {
        toast.error(`Failed to mark recieved failed`);
        this.setState({ markReceivedBtnLoader: false });
        this.setState({ loading: false });
        this.closeModal();
      }
      this.setState({ markReceivedBtnLoader: false });
      this.setState({ initiateRefundBtnLoader: false }); // Button Loader
    });
  }

  /**** Initiate Pickup ****/
  _handelInitiatePickup() {
    let { innerTab, orderCallFrom } = this.props;

    let fetchstatus = "new";
    if (orderCallFrom == "deliverd") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else {
        fetchstatus = "replace";
      }
    }

    this.setState({ initiatPickUpBtnLoader: true });
    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "initiatepickup",
    };

    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        this.setState({ initiatPickUpBtnLoader: false });
        toast.success(LocaleStrings.initiate_pickup_successfully);
        // Refresh Order List
        // this.props.fetchVendorOrderList(this.props.session, "new");
        this.setState({ loading: true });
        // Refresh Order List
        this.props.fetchVendorOrderList(
          this.props.session,
          // innerTab === 0 ? "cancelled" : innerTab === 1 ? "return" : "replace", // status
          fetchstatus, //added as with view linked order it is getting called from different tabs
          "web", // usertype
          "listing", // type
          10, // <limit :num of rows>
          1, // <page : cur page>
          "", // searchterm _>
          "all", // filter
          (CallBackResponse) => {
            this.setState({ loading: false });
            this.closeModal(); //latest
          }
        );
        // Close Modal
        // this.closeModal();//old
      } else {
        this.setState({ initiatPickUpBtnLoader: false });
        toast.error(`Initiate Pick up failed`);
      }
    });
  }

  /***** Mark Delivered *****/
  _handelMarkDelivered() {
    this.setState({ markAsDeliveredBtnLoader: true });
    let { innerTab, orderCallFrom } = this.props;
    let fetchstatus = "new";
    if (orderCallFrom == "deliverd") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else {
        fetchstatus = "replace";
      }
    }

    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "delivered",
    };

    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        this.setState({ markAsDeliveredBtnLoader: false });
        toast.success(LocaleStrings.mark_delivered_successfully);
        // Refresh Order List
        // this.props.fetchVendorOrderList(this.props.session, "new");
        this.props.fetchVendorOrderList(this.props.session, fetchstatus); //added as with view linked order it is getting called from different tabs
        // Close Modal
        this.closeModal();
      } else {
        this.setState({ markAsDeliveredBtnLoader: false });
      }
    });
  }

  /**** make In Transit *****/
  _handelInTransit() {
    this.setState({ inTransitBtnLoader: true });
    let { innerTab, orderCallFrom } = this.props;
    let fetchstatus = "new";
    if (orderCallFrom == "deliverd") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else {
        fetchstatus = "replace";
      }
    }

    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "intransit",
    };
    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        this.setState({ inTransitBtnLoader: false });
        toast.success(LocaleStrings.in_transit);
        // Refresh Order List
        // this.props.fetchVendorOrderList(this.props.session, "new");
        this.props.fetchVendorOrderList(this.props.session, fetchstatus); //added as with view linked order it is getting called from different tabs
        // Close Modal
        this.closeModal();
      } else {
        this.setState({ inTransitBtnLoader: false });
      }
    });
  }

  /**** Mark as shipted with tracking number ****/
  _handelMarkAsShipted() {
    let {
      vendorOrderListStore,
      currentOrderID,
      currentOrderObj,
      innerTab,
      orderCallFrom,
      storeDetails,
    } = this.props;

    let fetchstatus = "new";
    if (orderCallFrom == "deliverd") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else {
        fetchstatus = "replace";
      }
    }

    let data = currentOrderObj;

    let currentstatus = data.product.currentstatus.status;

    this.setState({ markAsShiptedBtnLoader: true });

    let trackingNumberVal = _.trim(
      document.querySelector("#tracking_number").value
    );

    let hasSpecialCharacters = validateTrackingnumber(trackingNumberVal);
    if (hasSpecialCharacters) {
      toast.error(LocaleStrings.tracking_number_error_special_charactertext);
      this.setState({ markAsShiptedBtnLoader: false });
      return false;
    }

    if (
      trackingNumberVal === "" ||
      trackingNumberVal === "null" ||
      trackingNumberVal === null
    ) {
      toast.error(LocaleStrings.tracking_number_error_text);
      this.setState({ markAsShiptedBtnLoader: false });
    } else {
      let orderidVal = _.trim(document.querySelector("#orderid").value);
      let orderdetailsidVal = _.trim(
        document.querySelector("#orderdetailsid").value
      );
      let dataObj = {
        orderid: orderidVal,
        orderdetailsid: orderdetailsidVal,
        trackingnumber: trackingNumberVal,
        status: currentstatus === "returnreceived" ? "reship" : "dispatched",
      };

      let vendorcountry = _.startCase(storeDetails?.data?.country);
      let deliverycountry = _.startCase(
        currentOrderObj?.delivery_address?.country
      );
      if (
        dataObj.status == "dispatched" &&
        vendorcountry == "Qatar" &&
        deliverycountry == "Qatar"
      ) {
        let val = {
          orderdetailsid: orderdetailsidVal,
        };
        this.props.partnerScriptorder(this.props.session, val, (callBack) => {
          // console.log("callBack", callBack);
          if (callBack.status == 1) {
            this.props.fetchVendorOrderList(this.props.session, fetchstatus);
            toast.success(LocaleStrings.marked_asshipped_successfully);
            this.setState({ markAsShiptedBtnLoader: false });
            this.closeModal();
          } else {
            toast.error(LocaleStrings.marked_asreshippedfail);
            this.setState({ markAsShiptedBtnLoader: false });
          }
        });
      } else {
        this.props.updateorder(this.props.session, dataObj, (callBack) => {
          if (
            callBack.status === true ||
            callBack.status === 1 ||
            callBack.status
          ) {
            this.setState({ markAsShiptedBtnLoader: false });

            if (currentstatus === "returnreceived") {
              toast.success(LocaleStrings.marked_asreshipped_successfully);
            } else {
              toast.success(LocaleStrings.marked_asshipped_successfully);
            }

            // Refresh Order List
            // this.props.fetchVendorOrderList(this.props.session, "new");
            this.props.fetchVendorOrderList(this.props.session, fetchstatus); //added as with view linked order it is getting called from different tabs
            // Close Modal
            this.closeModal();
          } else {
            this.setState({ markAsShiptedBtnLoader: false });
          }
        });
      }
      return false;
    }
  }

  /*** Confirm Pickup ***/
  _handelConfirmPicup() {
    let { innerTab, orderCallFrom } = this.props;
    this.setState({ confirmPickupBtnLoader: true });
    let fetchstatus = "new";

    if (orderCallFrom == "delivered") {
      fetchstatus = "delivered";
    } else if (orderCallFrom == "clubbed") {
      if (innerTab == 0) {
        fetchstatus = "cancelled";
      } else if (innerTab == 1) {
        fetchstatus = "return";
      } else if (innerTab == 2) {
        fetchstatus = "replace";
      }
    }

    let orderidVal = _.trim(document.querySelector("#orderid").value);
    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );
    let dataObj = {
      orderid: orderidVal,
      orderdetailsid: orderdetailsidVal,
      status: "returnpicked",
    };

    this.props.updateorder(this.props.session, dataObj, (callBack) => {
      if (
        callBack.status === true ||
        callBack.status === 1 ||
        callBack.status
      ) {
        this.setState({ confirmPickupBtnLoader: false });

        toast.success(LocaleStrings.pickup_confirmed_successfully);

        // Refresh Order List
        this.props.fetchVendorOrderList(
          this.props.session,
          // "return", // status
          fetchstatus, // added as with view linked order it is getting called from different tabs
          "web", // usertype
          "listing", // type
          10, // <limit :num of rows>
          1, // <page : cur page>
          "", // searchterm _>
          "all", // filter
          (CallBackResponse) => {
            this.setState({ loading: false });
            this.closeModal();
          }
        );

        // this.props.updatePageNumber(1);
        // this.props.updateSearchText("");
        // this.props.updateFilter("all");

        // Close Modal
      } else {
        this.setState({ confirmPickupBtnLoader: false });
      }
    });
  }

  /**** Download invoice button  ****/
  _handelDownloadInvoice() {
    let { downloadInvoiceBtnLoader } = this.state;
    this.setState({ downloadInvoiceBtnLoader: true });

    let orderdetailsidVal = _.trim(
      document.querySelector("#orderdetailsid").value
    );

    let val = {
      orderdetailid: orderdetailsidVal,
    };

    this.props.downloadorderInvoice(this.props.session, val, (callBack) => {
      // console.log("callBack", callBack);
      if (callBack.success == 1) {
        // console.log("callBack", callBack);
        let path = callBack?.data?.invoice;
        setTimeout(() => {
          // let invoiceFilePath = "http://www.africau.edu/images/default/sample.pdf";
          let invoiceFilePath = `${BASE_IMAGES_URL}/${path}?api_key=${APP_API_KEY}`;
          window.open(invoiceFilePath);
          this.setState({ downloadInvoiceBtnLoader: false });
        }, 500);
      } else {
        this.setState({ downloadInvoiceBtnLoader: false });
        toast.error(LocaleStrings.something_went_wrong);
      }
    });
  }

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  // Call a fc which will close or open light box
  openImagesInLightBox(index) {
    this.props.setPhotoIndex(index);
    this.props.setIsLightBoxOpen();
  }

  /*** Render Status Media ***/
  renderStatusMedia = (status_media) => {
    let { firstTimeOpeing } = this.state;

    let feedbackImageArr = [];

    var item = _.map(status_media, (media, index) => {
      var imageUrl = `${BASE_IMAGES_URL}/orders/${media.medianame}?api_key=${APP_API_KEY}`;
      feedbackImageArr.push(imageUrl); // Pushing
      return (
        <div className=" mr-1">
          <img
            loading="lazy"
            src={imageUrl}
            className="border-2 border-quaternary h-10 w-10 cursor-pointer"
            alt="image"
            onClick={(e) => this.openImagesInLightBox(index)}
          />
        </div>
      );
    });

    if (_.size(status_media) !== 0 && firstTimeOpeing) {
      if (this.props.setImagesForLightBOx) {
        this.props.setImagesForLightBOx(feedbackImageArr);
      }

      this.setState({ firstTimeOpeing: false });
    }

    return item;
  };

  /* Open or create Order thread */
  _createOrOpenOrderThread = (data) => {
    let { session } = this.props;
    let orderId = data.orderid;
    let buyerId = data.purchasedby.userid;

    this.props.createOrOpenOrderThread(
      session,
      orderId,
      buyerId,
      (callBack) => {
        if (callBack.status === 1) {
          // Callback success
          if (callBack.threadavailable) {
            // We need thread details before opeing the chat list
            this.props.getOrderThreadWithId(
              session,
              callBack.message.id,
              (callback3) => {
                if (callback3.status === 1) {
                  let thread = callback3.message.thread;

                  // Fetch the chat list with thread id
                  this.props.fetchOrderThreadMsgList(
                    session,
                    parseInt(thread?.id),
                    (res) => {
                      // Thread is already created
                      // Close Modal
                      this.closeModal();
                      // Now Open the message modal
                      this.props.isSendMessageModalOpen(true);
                      this.props.changeMessageTabName("order"); // Chnage the tab to product
                    }
                  );
                  // Update basic status
                  this.props.updateBasicOrderthreadData({
                    threadid: parseInt(thread?.id),
                    sku: thread?.orderdata[0]?.sku,
                    firstuserid: thread?.orderdata[0]?.firstuserid,
                    seconduserid: thread?.orderdata[0]?.seconduserid,
                    invoice_no: thread?.orderdata[0]?.invoice_no,
                    productname_en: thread?.orderdata[0]?.productname_en,
                    productname_ar: thread?.orderdata[0]?.productname_ar,
                  });
                  this.props.updateOrderStep(2);
                } else {
                  toast.error(callback3.message);
                }
              }
            );
          } else {
            // Here thread is not there so we created the tread and back with thread id
            // We need thread details before opeing the chat list
            this.props.getOrderThreadWithId(
              session,
              callBack.message.id,
              (callback2) => {
                if (callback2.status === 1) {
                  let thread = callback2.message.thread;
                  // Fetch the chat list with thread id
                  this.props.fetchOrderThreadMsgList(
                    session,
                    parseInt(callBack.message.id),
                    (res) => {}
                  );
                  // Update basic status
                  this.props.updateBasicOrderthreadData({
                    threadid: parseInt(thread?.id),
                    sku: thread?.orderdata[0]?.sku,
                    firstuserid: thread?.orderdata[0]?.firstuserid,
                    seconduserid: thread?.orderdata[0]?.seconduserid,
                    invoice_no: thread?.orderdata[0]?.invoice_no,
                    productname_en: thread?.orderdata[0]?.productname_en,
                    productname_ar: thread?.orderdata[0]?.productname_ar,
                  });
                  this.props.updateOrderStep(2);
                } else {
                  toast.error(callback2.message);
                }
              }
            );

            // Close Modal
            this.closeModal();
            // Now Open the message modal
            this.props.isSendMessageModalOpen(true);
            this.props.changeMessageTabName("order"); // Chnage the tab to product
          }
        } else {
          toast.error(callBack.message);
        }
      }
    );
  };

  render() {
    let {
      isNewOrdDtilOpen,
      language,
      currentOrderID,
      currentOrderObj,
      vendorOrderListStore,
      localCurrency,
      orderCallFrom,
      storeDetails,
    } = this.props;
    let { copedLoader } = this.state;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    let local_lang = this.props.language;

    let data = currentOrderObj;
    var productImageUrl = "";
    let productname = "";
    let date = "";
    let quantity = 0;
    let grand_total = 0.0;
    let returnOrreplace = "";
    // console.log('data:---',data);
    /* RATINGS & REVIEWS */
    let avgRating = "";
    let remRating = "";
    //if (data !== undefined && data != "undefined") {
    if (!_.isEmpty(data)) {
      let initiatepickupPosition = _.findIndex(
        data?.product?.statuses,
        function (o) {
          return o.status == "initiatepickup";
        }
      );

      if (initiatepickupPosition > -1) {
        returnOrreplace =
          data?.product?.statuses[initiatepickupPosition + 1]?.status;
      }

      productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${data?.product?.productimage}?api_key=${APP_API_KEY}`;
      productname = ProductTextLocalized(
        data?.product?.productname_en,
        data?.product?.productname_ar,
        local_lang
      ); // language
      // date = converDateIntoLocal(
      //   data?.product?.currentstatus?.updatedat
      // ).format(DATE_FORMAT_TO_SHOW);

      // date = data?.product?.orderdate;

      date = moment(data?.product?.orderdate).format("D MMM YYYY");

      quantity = data?.product?.quantity;
      /* Product currency */
      let orderCurrency = data?.product?.odcurrency;

      // grand_total = isCurrencyRateApplicable(
      //   localCurrency,
      //   orderCurrency,
      //   data?.summary?.grand_total
      // );

      grand_total = parseFloat(data?.summary?.grand_total).toFixed(2);

      avgRating =
        data && data.ratings && data.ratings.avgrating
          ? data.ratings.avgrating
          : 0;

      remRating = 5 - avgRating;
    }

    /* Product currency */
    let orderCurrency = data?.product?.odcurrency;

    // let product_total = isCurrencyRateApplicable(
    //   localCurrency,
    //   orderCurrency,
    //   data?.summary?.product_total
    // );
    let product_total = data?.summary?.product_total;

    // let discount = isCurrencyRateApplicable(
    //   localCurrency,
    //   orderCurrency,
    //   data?.summary?.discount
    // );
    let discount = data?.summary?.discount;

    // let shipping = isCurrencyRateApplicable(
    //   localCurrency,
    //   orderCurrency,
    //   data?.summary?.shipping
    // );
    let shipping = data?.summary?.shipping;

    // let tax = isCurrencyRateApplicable(
    //   localCurrency,
    //   orderCurrency,
    //   data?.summary?.tax
    // );
    let tax = data?.summary?.tax;

    let mailToLink = "";
    let vendorEmailId = data?.purchasedby?.email;

    if (!_.isEmpty(data)) {
      mailToLink = `mailto:${data?.purchasedby?.email}?subject=Shopez - ${LocaleStrings.order_id} : ${data?.product?.invoice_no}`;
    }

    let process_orderby = data?.product?.order_processedby;
    let deliverycountry = _.startCase(data?.delivery_address?.country);
    let vendorcountry = _.startCase(storeDetails?.data?.country);

    let qatarcountry = false;
    if (deliverycountry == "Qatar" && vendorcountry == "Qatar") {
      qatarcountry = true;
    }

    // console.log("data:-->", data);
    // console.log("process_orderby:-->", process_orderby);
    // console.log("delivery_country:-->", delivery_country);
    return (
      <Transition.Root show={this.props.isNewOrdDtilOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={this.myRef}
          open={this.props.isNewOrdDtilOpen}
          onClose={this.closeModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className=" sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="btn-closeModal"
                    ref={this.myRef}
                    onClick={this.closeModal}>
                    <span className="sr-only">{LocaleStrings.close}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mx-5 my-4 sm:flex-row-reverse">
                  <div className="mt-3 text-center  sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-secondary">
                      {this.props.headerText}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mx-5 my-4 sm:flex-row-reverse">
                  {/* Modal Body Start */}
                  {/* check comment for bug reported by qa */}
                  {/* {this.props.isNewOrdDtilOpen ? ( */}
                  <>
                    <div className="grid grid-cols-2 mt-5 pb-3 border-b-2 border-gray-50">
                      <div>
                        {LocaleStrings.order_id}: {data?.product?.invoice_no}
                      </div>
                      <div className="end-item text-xs text-ternary text-right">
                        {date}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mt-5 pb-3 border-b-2 border-gray-50">
                      <div>
                        <img loading="lazy" src={productImageUrl} />
                      </div>
                      <div className="text-xs text-ternary pl-5">
                        <p className="mt-5 text-secondary">{productname}</p>
                        <p className="mt-5">
                          {LocaleStrings.order_by}:{" "}
                          {data?.delivery_address?.username}
                        </p>
                        <p className="mt-5 uppercase">
                          {LocaleStrings.sku}: {data?.product?.sku}
                        </p>
                        <p className="mt-5">
                          {LocaleStrings.order_date1}: {date}
                        </p>
                        <p className="mt-5">
                          {LocaleStrings.qty}: {quantity}
                        </p>
                        <p className="mt-5">
                          {LocaleStrings.order_total}: {currency} {grand_total}
                        </p>
                      </div>
                      <input
                        type="hidden"
                        name="orderdetailsid"
                        id="orderdetailsid"
                        autoComplete="given-name"
                        className="cust-input-field"
                        value={data?.product?.orderdetailsid}
                      />
                      <input
                        type="hidden"
                        name="orderid"
                        id="orderid"
                        autoComplete="given-name"
                        className="cust-input-field"
                        value={data?.orderid}
                      />
                    </div>
                    {/* {data?.product?.currentstatus?.status === "placed" ||
                    (data?.product?.currentstatus?.status ===
                      "returnreceived" &&
                      returnOrreplace === "replace" &&
                      process_orderby == "self") ? ( */}
                    {/* commented on 10 june 2024 as per discussion with sanowar sir*/}
                    {/* {(data?.product?.currentstatus?.status === "placed" ||
                      (data?.product?.currentstatus?.status ===
                        "returnreceived" &&
                        returnOrreplace === "replace")) &&
                    process_orderby == "self" ? ( */}

                    {data?.product?.currentstatus?.status === "placed" &&
                    process_orderby == "self" ? (
                      <>
                        <div className="grid grid-cols-2 mt-2">
                          <div>{LocaleStrings.update_order_status}</div>
                          <div className="end-item ">
                            {this.state.updateordStateBox ? (
                              <ArrowCircleUpIcon
                                className="h-6 w-6 cursor-pointer mt-1"
                                aria-hidden="true"
                                onClick={this.updateordStateBoxToggle}
                              />
                            ) : (
                              <ArrowCircleDownIcon
                                className="h-6 w-6 cursor-pointer mt-1"
                                aria-hidden="true"
                                onClick={this.updateordStateBoxToggle}
                              />
                            )}
                          </div>
                        </div>
                        {this.state.updateordStateBox &&
                        process_orderby == "self" ? (
                          <div className="grid grid-cols-2">
                            <div className="col-span-2 mt-2">
                              {data?.product?.currentstatus?.status ===
                              "returnreceived" ? (
                                <p className="my-2 text-sm text-secondary">
                                  {
                                    LocaleStrings.order_details_re_ship_item_text
                                  }
                                </p>
                              ) : (
                                ""
                              )}
                              <p className="my-2 text-sm text-ternary">
                                {LocaleStrings.product_shipping_text}
                              </p>
                              <input
                                type="text"
                                name="tracking_number"
                                id="tracking_number"
                                placeholder={LocaleStrings.tracking_number}
                                autoComplete="given-name"
                                className="cust-input-field"
                              />
                              <button
                                onClick={this._handelMarkAsShipted}
                                disabled={this.state.markAsShiptedBtnLoader}
                                className={classNames(
                                  this.state.markAsShiptedBtnLoader
                                    ? "cursor-not-allowed"
                                    : "",
                                  "btn-primary mt-2"
                                )}>
                                <svg
                                  className={classNames(
                                    this.state.markAsShiptedBtnLoader
                                      ? ""
                                      : "hidden",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  disabled={this.state.markAsShiptedBtnLoader}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                {LocaleStrings.mark_as_shipped}
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <ShippingStatus
                      data={data?.product?.statuses}
                      orderCurrency={orderCurrency}
                    />

                    {/* REVIEW & RETINGS as feedback if deliverded only then show */}
                    {orderCallFrom === "delivered" ? (
                      <ReviewAndRatingLimited
                        productDetails={data?.product}
                        wholeObject={data}
                        limit={1}
                        callFrom={"order"}
                      />
                    ) : (
                      ""
                    )}

                    <AllReasonComponent
                      data={data}
                      renderStatusMedia={this.renderStatusMedia}
                    />

                    {/* DELIVERY TO SECTION */}
                    <div className="mt-5 pb-3 border-b-2 border-gray-50">
                      {LocaleStrings.delivery_to}
                      <div className="grid lg:grid-cols-3 grid-cols-3 mt-5">
                        <div>
                          <img
                            loading="lazy"
                            src="/images2/sales_managemnet_images/deliverby.svg"
                            className="h-10 w-10"
                          />
                        </div>
                        <div className="col-span-2">
                          <p>
                            {getBilangualeText(
                              data?.delivery_address?.addresstype
                            )}
                            :{" "}
                            {data?.delivery_address?.username
                              ? data?.delivery_address?.username
                              : LocaleStrings.no_found}
                          </p>
                          <p className="text-xs text-ternary mt-2">
                            {data?.delivery_address?.address}
                          </p>
                          <p className="text-xs text-secondary mt-2">
                            {LocaleStrings.sale_contact_no}:{" "}
                            {data?.delivery_address?.phone
                              ? data?.delivery_address?.phone
                              : LocaleStrings.no_found}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="grid lg:grid-cols-3 grid-cols-3 gap-1 mt-5">
                      {/* EMAIL A BUYER */}
                      <div className=" ">
                        <a
                          className="btn-primary"
                          href={mailToLink}
                          target="_top">
                          {LocaleStrings.email_buyer}
                        </a>
                      </div>
                      {/* Message User */}
                      <div className=" ">
                        <a
                          className="btn-primary cursor-pointer"
                          onClick={() => this._createOrOpenOrderThread(data)}
                          target="_top">
                          {LocaleStrings.message_buyer}
                        </a>
                      </div>
                      {/* Copy Email */}
                      <div
                        className={classNames(
                          vendorEmailId ? "" : "hidden",
                          ""
                        )}>
                        <span
                          className="btn-primary cursor-pointer"
                          onClick={(e) =>
                            this.copyEmailToClipboard(e, vendorEmailId)
                          }
                          target="_top">
                          {copedLoader
                            ? LocaleStrings.copied
                            : LocaleStrings.copy_email}
                          <ClipboardCopyIcon className="w-4 mx-1 icon-hover-transition" />
                        </span>
                      </div>
                    </div>
                    {data.hasOwnProperty("relatedorder") ? (
                      <>
                        {data?.summary?.actionkey &&
                        (data?.summary?.amount_paid > 0 ||
                          data?.summary?.refund_amount > 0) ? (
                          <>
                            {/* new  process  for refund*/}
                            <div className="mt-5 pb-3 border-b-2 border-gray-50">
                              {" "}
                              {LocaleStrings.price_details}
                              <div className="grid grid-cols-2 mt-5 text-ternary">
                                <div>{LocaleStrings.original_product}</div>
                                <div className="end-item ">
                                  {currency}
                                  {data?.summary?.previous_order_price}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.exchanged_product}</div>
                                <div className="end-item ">
                                  {currency}
                                  {data?.summary?.current_order_price}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.discount}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(discount)}
                                </div>
                              </div>
                              {data?.summary?.coupon_discount ? (
                                <div className="grid grid-cols-2 mt-1 text-ternary">
                                  <div>{LocaleStrings.coupon_discount}</div>
                                  <div className="end-item ">
                                    -{currency}
                                    {getIntToFloat(
                                      data?.summary?.coupon_discount
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.shipping_fee}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(shipping)}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.tax_charges}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(tax)}
                                </div>
                              </div>
                              {/* Divider */}
                              <div className="relative mt-2">
                                <div
                                  className="absolute inset-0 flex items-center"
                                  aria-hidden="true">
                                  <div className="w-full border-t border-quternary" />
                                </div>
                              </div>
                              {/* Total */}
                              {data?.summary?.actionkey &&
                              (data?.summary?.amount_paid > 0 ||
                                data?.summary?.refund_amount > 0) ? (
                                data?.summary?.amount_paid > 0 ? (
                                  <div className="grid grid-cols-2 mt-5">
                                    <div>{LocaleStrings.paid_amount}</div>
                                    <div className="end-item ">
                                      {currency}{" "}
                                      {getIntToFloat(
                                        data?.summary?.amount_paid
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="grid grid-cols-2 mt-5">
                                    <div>{LocaleStrings.refund_Amount}</div>
                                    <div className="end-item ">
                                      {currency}{" "}
                                      {getIntToFloat(
                                        data?.summary?.refund_amount
                                      )}
                                    </div>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                              {/* Payment Method */}
                              <div className="grid grid-cols-2 my-3">
                                <div>{LocaleStrings.payment_method}</div>
                                <div className="end-item ">
                                  {getBilangualeText(data.payment_method)}
                                </div>
                              </div>
                              {/* Divider */}
                              {/* Cancel Reason for exhange */}
                              {data?.relatedorder &&
                              data?.relatedorder?.currentstatus == "return" ? (
                                <>
                                  {data?.product?.currentstatus?.status ===
                                    "placed" && process_orderby == "self" ? (
                                    <>
                                      <div className="relative mt-2">
                                        <div
                                          className="absolute inset-0 flex items-center"
                                          aria-hidden="true">
                                          <div className="w-full border-t border-quternary" />
                                        </div>
                                      </div>
                                      <div className="grid grid-cols-2">
                                        <div className="col-span-2 mt-2">
                                          <p className="my-2 text-sm text-secondary">
                                            {LocaleStrings.cancel_reason}:
                                          </p>
                                          <p className="my-2 text-sm text-ternary">
                                            {LocaleStrings.cancel_reason_desc}
                                          </p>
                                          <textarea
                                            id="cancel_reason_inputbox"
                                            name="cancel_reason_inputbox"
                                            placeholder={
                                              LocaleStrings.enter_reason
                                            }
                                            rows={3}
                                            className="cust-input-field"
                                            defaultValue={""}
                                          />
                                        </div>
                                      </div>
                                      <button
                                        onClick={this._handelCancelOrder}
                                        disabled={
                                          this.state.cancelOrderBtnLoader
                                        }
                                        className={classNames(
                                          this.state.cancelOrderBtnLoader
                                            ? "cursor-not-allowed"
                                            : "",
                                          "btn-primary mt-2"
                                        )}>
                                        <svg
                                          className={classNames(
                                            this.state.cancelOrderBtnLoader
                                              ? ""
                                              : "hidden",
                                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                          )}
                                          disabled={
                                            this.state.cancelOrderBtnLoader
                                          }
                                          viewBox="0 0 1024 1024"
                                          focusable="false"
                                          data-icon="loading"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true">
                                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                        </svg>
                                        {LocaleStrings.cancel_order}
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {data?.product?.currentstatus?.status ===
                                "dispatched" &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                                <button
                                  disabled={this.state.inTransitBtnLoader}
                                  className={classNames(
                                    this.state.inTransitBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInTransit}>
                                  <svg
                                    className={classNames(
                                      this.state.inTransitBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.inTransitBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.in_transit}
                                </button>
                              ) : (
                                ""
                              )}
                              {(data?.product?.currentstatus?.status ===
                                "intransit" ||
                                data?.product?.currentstatus?.status ===
                                  "reship") &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                                // <>
                                <button
                                  disabled={this.state.markAsDeliveredBtnLoader}
                                  className={classNames(
                                    this.state.markAsDeliveredBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelMarkDelivered}>
                                  <svg
                                    className={classNames(
                                      this.state.markAsDeliveredBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={
                                      this.state.markAsDeliveredBtnLoader
                                    }
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_delivered}
                                </button>
                              ) : (
                                ""
                              )}
                              {(data?.product?.currentstatus?.status ===
                                "replace" ||
                                data?.product?.currentstatus?.status ===
                                  "return") &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                                <button
                                  disabled={this.state.initiatPickUpBtnLoader}
                                  className={classNames(
                                    this.state.initiatPickUpBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInitiatePickup}>
                                  <svg
                                    className={classNames(
                                      this.state.initiatPickUpBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.initiatPickUpBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.initiate_pickup}
                                </button>
                              ) : data?.product?.currentstatus?.status ===
                                  "initiatepickup" &&
                                process_orderby == "self" ? (
                                <button
                                  disabled={this.state.confirmPickupBtnLoader}
                                  className={classNames(
                                    this.state.confirmPickupBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelConfirmPicup}>
                                  <svg
                                    className={classNames(
                                      this.state.confirmPickupBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.confirmPickupBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.confirm_pickeup}
                                </button>
                              ) : data?.product?.currentstatus?.status ===
                                  "returnpicked" &&
                                process_orderby == "self" ? (
                                <button
                                  disabled={this.state.markReceivedBtnLoader}
                                  className={classNames(
                                    this.state.markReceivedBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelReceived}>
                                  <svg
                                    className={classNames(
                                      this.state.markReceivedBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.markReceivedBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_received}
                                </button>
                              ) : data?.summary?.actionkey &&
                                data?.summary?.actionkey === "refund" &&
                                data?.summary.refund_amount > 0 &&
                                process_orderby == "self" ? (
                                <button
                                  disabled={this.state.initiateRefundBtnLoader}
                                  // style={{ display: "none" }} // added on 14/3/2024 as initiate refund is automated
                                  className={classNames(
                                    this.state.initiateRefundBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-5"
                                  )}
                                  onClick={this._handelInitiateRefund}>
                                  <svg
                                    className={classNames(
                                      this.state.initiateRefundBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.initiatPickUpBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.initiate_refund}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {/* third process */}
                            <div className="mt-5 pb-3 border-b-2 border-gray-50">
                              {" "}
                              {LocaleStrings.price_details}
                              <div className="grid grid-cols-2 mt-5 text-ternary">
                                <div>{LocaleStrings.product_price}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(product_total)}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.discount}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(discount)}
                                </div>
                              </div>
                              {data?.summary?.coupon_discount ? (
                                <div className="grid grid-cols-2 mt-1 text-ternary">
                                  <div>{LocaleStrings.coupon_discount}</div>
                                  <div className="end-item ">
                                    -{currency}
                                    {getIntToFloat(
                                      data?.summary?.coupon_discount
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.shipping_fee}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(shipping)}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-1 text-ternary">
                                <div>{LocaleStrings.tax_charges}</div>
                                <div className="end-item ">
                                  {currency}
                                  {getIntToFloat(tax)}
                                </div>
                              </div>
                              {/* Divider */}
                              <div className="relative mt-2">
                                <div
                                  className="absolute inset-0 flex items-center"
                                  aria-hidden="true">
                                  <div className="w-full border-t border-quternary" />
                                </div>
                              </div>
                              {/* Total */}
                              <div className="grid grid-cols-2 mt-5">
                                <div>{LocaleStrings.total}</div>
                                <div className="end-item ">
                                  {currency} {getIntToFloat(grand_total)}
                                </div>
                              </div>
                              {/* Payment Method */}
                              <div className="grid grid-cols-2 my-3">
                                <div>{LocaleStrings.payment_method}</div>
                                <div className="end-item ">
                                  {getBilangualeText(data.payment_method)}
                                </div>
                              </div>
                              {data?.relatedorder &&
                              data?.relatedorder?.currentstatus == "return" ? (
                                <>
                                  {data?.product?.currentstatus?.status ===
                                    "placed" && process_orderby == "self" ? (
                                    <>
                                      <div className="relative mt-2">
                                        <div
                                          className="absolute inset-0 flex items-center"
                                          aria-hidden="true">
                                          <div className="w-full border-t border-quternary" />
                                        </div>
                                      </div>
                                      <div className="grid grid-cols-2">
                                        <div className="col-span-2 mt-2">
                                          <p className="my-2 text-sm text-secondary">
                                            {LocaleStrings.cancel_reason}:
                                          </p>
                                          <p className="my-2 text-sm text-ternary">
                                            {LocaleStrings.cancel_reason_desc}
                                          </p>
                                          <textarea
                                            id="cancel_reason_inputbox"
                                            name="cancel_reason_inputbox"
                                            placeholder={
                                              LocaleStrings.enter_reason
                                            }
                                            rows={3}
                                            className="cust-input-field"
                                            defaultValue={""}
                                          />
                                        </div>
                                      </div>
                                      <button
                                        onClick={this._handelCancelOrder}
                                        disabled={
                                          this.state.cancelOrderBtnLoader
                                        }
                                        className={classNames(
                                          this.state.cancelOrderBtnLoader
                                            ? "cursor-not-allowed"
                                            : "",
                                          "btn-primary mt-2"
                                        )}>
                                        <svg
                                          className={classNames(
                                            this.state.cancelOrderBtnLoader
                                              ? ""
                                              : "hidden",
                                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                          )}
                                          disabled={
                                            this.state.cancelOrderBtnLoader
                                          }
                                          viewBox="0 0 1024 1024"
                                          focusable="false"
                                          data-icon="loading"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true">
                                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                        </svg>
                                        {LocaleStrings.cancel_order}
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {data?.product?.currentstatus?.status ===
                                "dispatched" &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                                <button
                                  disabled={this.state.inTransitBtnLoader}
                                  className={classNames(
                                    this.state.inTransitBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInTransit}>
                                  <svg
                                    className={classNames(
                                      this.state.inTransitBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.inTransitBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.in_transit}
                                </button>
                              ) : (
                                ""
                              )}
                              {(data?.product?.currentstatus?.status ===
                                "intransit" ||
                                data?.product?.currentstatus?.status ===
                                  "reship") &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                                // <>
                                <button
                                  disabled={this.state.markAsDeliveredBtnLoader}
                                  className={classNames(
                                    this.state.markAsDeliveredBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelMarkDelivered}>
                                  <svg
                                    className={classNames(
                                      this.state.markAsDeliveredBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={
                                      this.state.markAsDeliveredBtnLoader
                                    }
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_delivered}
                                </button>
                              ) : (
                                ""
                              )}
                              {(data?.product?.currentstatus?.status ===
                                "replace" ||
                                data?.product?.currentstatus?.status ===
                                  "return") &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                                <button
                                  disabled={this.state.initiatPickUpBtnLoader}
                                  className={classNames(
                                    this.state.initiatPickUpBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInitiatePickup}>
                                  <svg
                                    className={classNames(
                                      this.state.initiatPickUpBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.initiatPickUpBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.initiate_pickup}
                                </button>
                              ) : data?.product?.currentstatus?.status ===
                                  "initiatepickup" &&
                                process_orderby == "self" ? (
                                <button
                                  disabled={this.state.confirmPickupBtnLoader}
                                  className={classNames(
                                    this.state.confirmPickupBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelConfirmPicup}>
                                  <svg
                                    className={classNames(
                                      this.state.confirmPickupBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.confirmPickupBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.confirm_pickeup}
                                </button>
                              ) : data?.product?.currentstatus?.status ===
                                  "returnpicked" &&
                                process_orderby == "self" ? (
                                <button
                                  disabled={this.state.markReceivedBtnLoader}
                                  className={classNames(
                                    this.state.markReceivedBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelReceived}>
                                  <svg
                                    className={classNames(
                                      this.state.markReceivedBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.markReceivedBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_received}
                                </button>
                              ) : data.summary?.actionkey &&
                                data.summary?.actionkey === "refund" &&
                                data.summary.refund_amount > 0 &&
                                process_orderby == "self" ? (
                                <button
                                  disabled={this.state.initiateRefundBtnLoader}
                                  style={{ display: "none" }} // added on 14/3/2024 as initiate refund is automated
                                  className={classNames(
                                    this.state.initiateRefundBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-5"
                                  )}
                                  onClick={this._handelInitiateRefund}>
                                  <svg
                                    className={classNames(
                                      this.state.initiateRefundBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.initiatPickUpBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.initiate_refund}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* old process */}
                        <div className="mt-5 pb-3 border-b-2 border-gray-50">
                          {" "}
                          {LocaleStrings.price_details}
                          <div className="grid grid-cols-2 mt-5 text-ternary">
                            <div>{LocaleStrings.product_price}</div>
                            <div className="end-item ">
                              {currency}
                              {getIntToFloat(product_total)}
                            </div>
                          </div>
                          <div className="grid grid-cols-2 mt-1 text-ternary">
                            <div>{LocaleStrings.discount}</div>
                            <div className="end-item ">
                              {currency}
                              {getIntToFloat(discount)}
                            </div>
                          </div>
                          {data?.summary?.coupon_discount ? (
                            <div className="grid grid-cols-2 mt-1 text-ternary">
                              <div>{LocaleStrings.coupon_discount}</div>
                              <div className="end-item ">
                                -{currency}
                                {getIntToFloat(data?.summary?.coupon_discount)}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="grid grid-cols-2 mt-1 text-ternary">
                            <div>{LocaleStrings.shipping_fee}</div>
                            <div className="end-item ">
                              {currency}
                              {getIntToFloat(shipping)}
                            </div>
                          </div>
                          <div className="grid grid-cols-2 mt-1 text-ternary">
                            <div>{LocaleStrings.tax_charges}</div>
                            <div className="end-item ">
                              {currency}
                              {getIntToFloat(tax)}
                            </div>
                          </div>
                          {/* Divider */}
                          <div className="relative mt-2">
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true">
                              <div className="w-full border-t border-quternary" />
                            </div>
                          </div>
                          {/* Total */}
                          <div className="grid grid-cols-2 mt-5">
                            <div>{LocaleStrings.total}</div>
                            <div className="end-item ">
                              {currency} {getIntToFloat(grand_total)}
                            </div>
                          </div>
                          {/* Payment Method */}
                          <div className="grid grid-cols-2 mt-3">
                            <div>{LocaleStrings.payment_method}</div>
                            <div className="end-item ">
                              {getBilangualeText(data?.payment_method)}
                            </div>
                          </div>
                          {/* Divider */}
                          {/* Cancel Reason [Note : cancel only before it is dispatched]*/}
                          {data?.product?.currentstatus?.status === "placed" &&
                          process_orderby == "self" ? (
                            <>
                              <div className="relative mt-2">
                                <div
                                  className="absolute inset-0 flex items-center"
                                  aria-hidden="true">
                                  <div className="w-full border-t border-quternary" />
                                </div>
                              </div>
                              <div className="grid grid-cols-2">
                                <div className="col-span-2 mt-2">
                                  <p className="my-2 text-sm text-secondary">
                                    {LocaleStrings.cancel_reason}:
                                  </p>
                                  <p className="my-2 text-sm text-ternary">
                                    {LocaleStrings.cancel_reason_desc}
                                  </p>
                                  <textarea
                                    id="cancel_reason_inputbox"
                                    name="cancel_reason_inputbox"
                                    placeholder={LocaleStrings.enter_reason}
                                    rows={3}
                                    className="cust-input-field"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {
                          //(data.product.currentstatus.status != 'delivered')  initiate
                          this.props.isNewOrdDtilOpen ? (
                            data?.product?.currentstatus?.status === "placed" &&
                            process_orderby == "self" ? (
                              <button
                                onClick={this._handelCancelOrder}
                                disabled={this.state.cancelOrderBtnLoader}
                                className={classNames(
                                  this.state.cancelOrderBtnLoader
                                    ? "cursor-not-allowed"
                                    : "",
                                  "btn-primary mt-2"
                                )}>
                                <svg
                                  className={classNames(
                                    this.state.cancelOrderBtnLoader
                                      ? ""
                                      : "hidden",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  disabled={this.state.cancelOrderBtnLoader}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                {LocaleStrings.cancel_order}
                              </button>
                            ) : data?.product?.currentstatus?.status ===
                                "cancelled" &&
                              //  ||
                              // (data?.product?.currentstatus?.status ===
                              //   "returnreceived" &&
                              //   returnOrreplace === "return")
                              process_orderby == "self" &&
                              data?.payment_method != "cash" ? ( //new checked added as discussed
                              // && !data.hasOwnProperty('linkedorder') //latest code
                              <button
                                disabled={this.state.initiateRefundBtnLoader}
                                className={classNames(
                                  this.state.initiateRefundBtnLoader
                                    ? "cursor-not-allowed"
                                    : "",
                                  "btn-primary"
                                )}
                                onClick={this._handelInitiateRefund}>
                                <svg
                                  className={classNames(
                                    this.state.initiateRefundBtnLoader
                                      ? ""
                                      : "hidden",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  disabled={this.state.initiatPickUpBtnLoader}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                {LocaleStrings.initiate_refund}
                              </button>
                            ) : (data?.product?.currentstatus?.status ===
                                "replace" ||
                                data?.product?.currentstatus?.status ===
                                  "return") &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                              <button
                                disabled={this.state.initiatPickUpBtnLoader}
                                className={classNames(
                                  this.state.initiatPickUpBtnLoader
                                    ? "cursor-not-allowed"
                                    : "",
                                  "btn-primary"
                                )}
                                onClick={this._handelInitiatePickup}>
                                <svg
                                  className={classNames(
                                    this.state.initiatPickUpBtnLoader
                                      ? ""
                                      : "hidden",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  disabled={this.state.initiatPickUpBtnLoader}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                {LocaleStrings.initiate_pickup}
                              </button>
                            ) : data?.product?.currentstatus?.status ===
                                "dispatched" &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                              <>
                                <button
                                  disabled={this.state.inTransitBtnLoader}
                                  className={classNames(
                                    this.state.inTransitBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary"
                                  )}
                                  onClick={this._handelInTransit}>
                                  <svg
                                    className={classNames(
                                      this.state.inTransitBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={this.state.inTransitBtnLoader}
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.in_transit}
                                </button>
                              </>
                            ) : data?.product?.currentstatus?.status ===
                                "returnpicked" && process_orderby == "self" ? (
                              /*
                                  The button will show Initiate Pickup  after  to the Vendor when currentstatus = return
                                  When user taps on Initiate Pickup button the status will become initiatepickup
                                  and The button will show Confirm Pickup tapping on which the status will become returnpicked
                                  The button becomes Mark Received tapping on it the status will become returnreceived
                                  Now the Button will change to Initiate Refund, tapping on button it will change status to refund
                                  */
                              <button
                                disabled={this.state.markReceivedBtnLoader}
                                className={classNames(
                                  this.state.markReceivedBtnLoader
                                    ? "cursor-not-allowed"
                                    : "",
                                  "btn-primary"
                                )}
                                onClick={this._handelReceived}>
                                <svg
                                  className={classNames(
                                    this.state.markReceivedBtnLoader
                                      ? ""
                                      : "hidden",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  disabled={this.state.markReceivedBtnLoader}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                {LocaleStrings.mark_received}
                              </button>
                            ) : (data?.product?.currentstatus?.status ===
                                "intransit" ||
                                data?.product?.currentstatus?.status ===
                                  "reship") &&
                              process_orderby == "self" &&
                              !qatarcountry ? (
                              <>
                                <button
                                  disabled={this.state.markAsDeliveredBtnLoader}
                                  className={classNames(
                                    this.state.markAsDeliveredBtnLoader
                                      ? "cursor-not-allowed"
                                      : "",
                                    "btn-primary mt-2"
                                  )}
                                  onClick={this._handelMarkDelivered}>
                                  <svg
                                    className={classNames(
                                      this.state.markAsDeliveredBtnLoader
                                        ? ""
                                        : "hidden",
                                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                    )}
                                    disabled={
                                      this.state.markAsDeliveredBtnLoader
                                    }
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                  {LocaleStrings.mark_delivered}
                                </button>
                              </>
                            ) : data?.product?.currentstatus?.status ===
                                "initiatepickup" &&
                              process_orderby == "self" ? (
                              <button
                                disabled={this.state.confirmPickupBtnLoader}
                                className={classNames(
                                  this.state.confirmPickupBtnLoader
                                    ? "cursor-not-allowed"
                                    : "",
                                  "btn-primary mt-2"
                                )}
                                onClick={this._handelConfirmPicup}>
                                <svg
                                  className={classNames(
                                    this.state.confirmPickupBtnLoader
                                      ? ""
                                      : "hidden",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  disabled={this.state.confirmPickupBtnLoader}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                {LocaleStrings.confirm_pickeup}
                              </button>
                            ) : (
                              ""
                            )
                          ) : (
                            // (
                            //     (data.product.currentstatus.status === 'returnreceived')
                            //     ?
                            //     ''
                            //     :
                            //     ''
                            // )
                            ""
                          )
                        }
                      </>
                    )}
                  </>
                  {/* ) : (
                    ""
                  )} */}
                  {/* Modal Body Ends */}
                </div>

                {/* Common button for downalod invoice button */}
                {data?.product?.hasOwnProperty("invoice_path") ? (
                  <button
                    onClick={this._handelDownloadInvoice}
                    disabled={this.state.downloadInvoiceBtnLoader}
                    className={classNames(
                      this.state.downloadInvoiceBtnLoader
                        ? "cursor-not-allowed"
                        : "",
                      "btn-primary mt-2"
                    )}>
                    <svg
                      className={classNames(
                        this.state.downloadInvoiceBtnLoader ? "" : "hidden",
                        "animate-spin-medium h-5 w-5 rounded-full mx-2"
                      )}
                      disabled={this.state.downloadInvoiceBtnLoader}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {LocaleStrings.download_invoice}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isNewOrdDtilOpen: state.isNewOrderDetlModalopen,
    headerText: ownProps.headerText,
    currentOrderID: state.currentOrderID,
    currentOrderObj: state.currentOrderObj,
    vendorOrderListStore: state.vendorOrderListStore
      ? state.vendorOrderListStore.data
        ? state.vendorOrderListStore.data
        : []
      : [],
    localCurrency: state.currency,
    storeDetails: state.storeDetails,
  };
}

export default connect(mapStateToProps, {
  salesmanagementStatboxSelected,
  toggleNewOrderDetailsModal,
  updateorder,
  fetchVendorOrderList,
  createOrOpenOrderThread,
  isSendMessageModalOpen, // Open message modal
  changeMessageTabName, // Chnage message tab to order
  getOrderThreadWithId, // Get details
  fetchOrderThreadMsgList,
  updateBasicOrderthreadData,
  updateOrderStep,
  updateinitiateRefund,
  fetchUsersDeatils,
  fetchStoreDetails,
  partnerScriptorder,
  downloadorderInvoice,
})(NewOrderDetails);
