import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import moment from "moment-timezone";

// Import Components
import BaseLoader from "@baseLoader";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";

// Import ACtions
import {
  fetchMyFollowers,
  isFollowersDetailsOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
} from "../../actions/index";

class CurrentSharedWishList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_my_followers,
    };

    // All Binded Functions
  }

  componentDidMount() {
    /**** Fetch My Followers ****/
    this.props.fetchMyFollowers(this.props.session, (callback) => {
      this.setState({ loading: false });
    });
  }

  /**** Followers Details Modal Open Fc ****/
  openFollowersDelaisModal(valObj) {
    this.props.isFollowersDetailsOpen(true);

    this.props.selectedFollowerUserDetails(
      this.props.session,
      valObj.followerid,
      (callback) => {}
    );

    this.props.selectedFollowersDetails(
      this.props.session,
      valObj.followerid,
      (callback) => {}
    );
  }

  /**** Render Profile settings My Followers List *****/
  renderList() {
    var item = _.map(this.props.currentShearedWishlist, (valObj, index) => {
      let itemCount = valObj.items.length;
      let privacy =
        valObj.privacy === "public"
          ? LocaleStrings.public
          : LocaleStrings.private;

      //var productImageUrl   =`${BASE_IMAGES_URL}/users/${usersByFollowerid.userid}.jpg?api_key=${APP_API_KEY}`;

      return (
        <li
          key={index}
          className="py-4 my-2 bg-custWhite px-4 cursor-pointer border-2 border-quaternary rounded table-hover-transition"
          // onClick={(e) =>
          //     this.openFollowersDelaisModal(valObj)
          // }
        >
          <div className="mb-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 ">
            {_.map(valObj.items, (itemVal, i) => {
              var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${itemVal.productdetails.productimage}?api_key=${APP_API_KEY}`;

              return (
                <div className="">
                  <img
                    loading="lazy"
                    className="w-16 h-16  lg:w-20 lg:h-20"
                    src={productImageUrl}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex-0 min-w-0">
              <p className="text-xs font-semibold text-secondary">
                {valObj.name}
              </p>
              <div className="flex items-center mt-2">
                <img
                  loading="lazy"
                  className={"h-4 w-4"}
                  src={`/images2/profile_settings_images/${valObj.privacy}.svg`}
                />
                <p className="text-xs text-ternary px-2 pb-0.5 capitalize">
                  {privacy} {" | "} {itemCount + " " + LocaleStrings.items}
                </p>
              </div>
            </div>
          </div>
        </li>
      );
    });

    return item;
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <BaseLoader data={this.state.loaderDesc} />
        ) : (
          <>
            <div className="xs:px-2">
              <div className="flow-root my-8">
                <ul role="list" className=" ">
                  {this.renderList()}
                </ul>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    currentShearedWishlist: state.currentShearedWishlist
      ? state.currentShearedWishlist.data
        ? state.currentShearedWishlist.data
        : []
      : [],
  };
}

export default connect(mapStateToProps, {
  fetchMyFollowers,
  isFollowersDetailsOpen,
})(CurrentSharedWishList);
