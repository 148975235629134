import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import toast from "react-hot-toast";
import LocaleStrings from "@language";

// Import constant
import { validateEmail } from "@constant";

// Import Actions
import {
  sendsuggestions,
  uploadMediaLargeAppSuggestion,
} from "../actions/index";

// Functions Import
import { classNames } from "@commonFunction";

class AppSuggestions extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      fileName: "",
      fileArr: [],
    };

    // All Binded Functions
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  /**** Modal Open Function  ****/

  /**** Input File select ****/
  _handleSelect(event) {
    let { fileArr } = this.state;
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
    };

    //let ext = file.name.split(".").pop(); // File extension

    // if(_.indexOf(validateExtention, ext) === -1) { // Validate File Extensions
    //     toast.error(LocaleStrings.upload_only_doc_and_pdf_file_error_text);
    // }else {

    // }
    this.setState({ fileName: file.name });
    //this.props.autofill(typeindex,file.name);

    fileArr.push(file);
    this.setState({ fileArr: fileArr });
  }

  /**** RED cross Click ****/
  _handleUnSelect = (e) => {
    this.setState({ fileArr: [] });
  };

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ isBtnDisable: true });
    this.setState({ btnLoader: true });

    let { fileArr } = this.state;

    values["userid"] = this.props.session.userid;
    // Upload Section
    let uploaderCounter = 0;
    let filetobeupload = [];
    let arrPromise = [];
    let attachmentNames = [];

    _.forEach(fileArr, function (value, index) {
      attachmentNames.push(value.name);

      let coverJson = {
        type: value.type,
        name: value.name,
        media: value.url,
      };

      filetobeupload.push(coverJson); //
    });

    let counter = filetobeupload.length;

    // Store File to server first
    filetobeupload.map((item, index) => {
      arrPromise.push(
        new Promise((resolve, reject) => {
          this.props.uploadMediaLargeAppSuggestion(
            this.props.session,
            item,
            (response) => {
              if (response.success === 0) {
                resolve();
              } else {
                uploaderCounter++;
                resolve();
              }
            }
          );
        })
      );
    });

    if (counter > 0) {
      //Save With Files
      Promise.all(arrPromise).then(() => {
        if (uploaderCounter === counter) {
          values["attachment"] = _.join(attachmentNames, ",");

          // After file upload
          this.props.sendsuggestions(this.props.session, values, (callback) => {
            if (callback.status === 1) {
              this.setState({ isBtnDisable: false });
              this.setState({ btnLoader: false });
              this.setState({ fileName: "" });
              this.setState({ fileArr: [] });

              toast.success(LocaleStrings.app_suggestions_submit_success);
              this.props.reset();
            } else {
              this.setState({ isBtnDisable: false });
              this.setState({ btnLoader: false });

              toast.error(LocaleStrings.something_went_wrong);
            }
          });
        } else {
          toast.error(LocaleStrings.something_went_wrong);
        }
      });
    } else {
      //Save With out Files
      values["attachment"] = "";

      this.props.sendsuggestions(this.props.session, values, (callback) => {
        if (callback.status === 1) {
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });
          this.setState({ fileName: "" });
          this.setState({ fileArr: [] });

          toast.success(LocaleStrings.app_suggestions_submit_success);
          this.props.reset();
        } else {
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });

          toast.error(LocaleStrings.something_went_wrong);
        }
      });
    }
  }

  render() {
    let { handleSubmit } = this.props;
    let { fileArr } = this.state;

    return (
      <>
        <div className=" xs:px-2 sm:px-10 2xl:px-48">
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
            >
              <div className="p-10 space-y-8">
                <div className="">
                  <p className="text-base font-medium text-secondary pb-4">
                    {LocaleStrings.app_suggestions_header_text}
                  </p>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      loading="lazy"
                      className="h-5 w-5"
                      src="/images2/common/app-suggestion.svg"
                      alt=""
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-ternary truncate mx-2">
                      {LocaleStrings.bug}
                    </p>
                  </div>
                  <div>
                    <Field
                      name="requesttype"
                      label={""}
                      component={this.renderFieldRadio}
                      type="radio"
                      value="bug"
                      className="mx-2"
                      // onChange={this.handleInputLanguageChange.bind(this)}
                    />
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      loading="lazy"
                      className="h-5 w-5"
                      src="/images2/common/rating-review.svg"
                      alt=""
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-ternary truncate mx-2">
                      {LocaleStrings.app_feature}
                    </p>
                  </div>
                  <div>
                    <Field
                      name="requesttype"
                      label={""}
                      component={this.renderFieldRadio}
                      type="radio"
                      value="feature"
                      className="mx-2"
                      // onChange={this.handleInputLanguageChange.bind(this)}
                    />
                  </div>
                </div>

                <Field
                  name="emailid"
                  label={LocaleStrings.app_suggestions_lbl_email_address}
                  placeholder={
                    LocaleStrings.app_suggestions_plshldr_email_address
                  }
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
                <Field
                  name={"suggestion"}
                  label={LocaleStrings.app_suggestions_lbl_suggestion_desc}
                  placeholder={
                    LocaleStrings.app_suggestions_plshldr_suggestion_desc
                  }
                  mandatory="false"
                  component={this.renderFieldTextarea}
                  maxlength={200}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-box-lable"
                  className="py-3 px-4 block w-full shadow-sm text-secondary border border-warm-gray-300 rounded-md"
                />
                {/* File Attachment */}
                <div className="flex justify-between">
                  <div className=" flex">
                    <span className="text-sm text-ternary">
                      {_.size(fileArr) > 0 ? _.size(fileArr) : ""}{" "}
                      {_.size(fileArr) === 1
                        ? LocaleStrings.file_attached
                        : _.size(fileArr) > 1
                        ? LocaleStrings.files_attached
                        : null}
                    </span>
                    <img
                      loading="lazy"
                      className={classNames(
                        _.size(fileArr) > 0 ? "" : "hidden",
                        "h-4 w-4 m-0.5 cursor-pointer"
                      )}
                      src={"/images2/common/red-cross.svg"}
                      onClick={(e) => this._handleUnSelect(e)}
                    />
                  </div>
                  <div className="flex ">
                    <label
                      htmlFor="attachment"
                      className="relative btn-primary-outline cursor-pointer"
                    >
                      <img
                        loading="lazy"
                        className={"h-4 w-4 "}
                        src={"/images2/profile_settings_images/attachfile.svg"}
                      />
                      <span>{LocaleStrings.attach_files}</span>
                      <input
                        id="attachment"
                        name="attachment"
                        type="file"
                        accept="image/*"
                        className="sr-only"
                        onChange={(e) => this._handleSelect(e)}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="p-6 border-t-2 border-quaternary">
                <div className="">
                  <p className="text-sm  text-ternary pb-4">
                    {LocaleStrings.app_suggestions_footer_text}
                  </p>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                    <button
                      type="submit"
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? "cursor-not-allowed" : "",
                        "btn-primary"
                      )}
                    >
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "sr-only",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.send_suggestion}
                    </button>
                  </div>
                </div>

                {/* Footer */}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ["emailid", "requesttype", "suggestion"];
  var email = values["emailid"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  if (email && !validateEmail(email)) {
    errors["emailid"] = LocaleStrings.invalid_email;
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user, language, isLoggedIn } = state;

  return {
    session,
    user,
    isLoggedIn,
    language,
    initialValues: { requesttype: "bug" },
  };
}

export default connect(mapStateToProps, {
  sendsuggestions,
  uploadMediaLargeAppSuggestion,
})(
  reduxForm({
    validate,
    form: "AppSuggestionsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AppSuggestions)
);
