import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import toast from "react-hot-toast";
import _ from "lodash";
// IMPORT COMPONENTS
import BaseLoader from "@baseLoader";
import { BaseComponent, LABEL_POSITION_TOP, renderOTP } from "@baseComponent";
// IMPORT ACTIONS
import {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  updateStoreUsers,
  sendSms,
  verifyOtp,
} from "@profileSettingsActions";
import { countryPhoneLength } from "@mainActions";

// FUNCTIONS IMPORT
import {
  classNames,
  removePhoneNoSpecialChar,
  isValidQatarPhoneNumber,
} from "@commonFunction";

class AddPhoneSection extends BaseComponent {
  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = {
      loading: true,
      btnLoader: false,
      loaderDesc: LocaleStrings.preparing_profile_information,
      verificStateCode: "",
      fieldsEmptyState: true,
      isVerified: false,
      phone: "",
      country: "",
      sendLoader: false,
      verifyLoader: false,

      resendLoader: false,
      time: {},
      seconds: 60,
      firsttime: true,
    };

    // All Binded Functions
    this.resendVerificationCode = this.resendVerificationCode.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    this.setState({ resendLoader: true });

    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
      this.setState({
        resendLoader: false,
        time: {},
        seconds: 30,
        firsttime: false,
      });
      this.timer = 0;
      let timeLeftVar = this.secondsToTime(30);
      this.setState({ time: timeLeftVar });
    }
  }

  secondsToTime(secs) {
    let obj = {
      s: secs,
    };
    return obj;
  }

  componentWillUnMount() {
    this.setState({
      loading: true,
      btnLoader: false,
      loaderDesc: LocaleStrings.preparing_profile_information,
      verificStateCode: "",
      fieldsEmptyState: true,
      isVerified: false,
      phone: "",
      country: "",
      sendLoader: false,
      verifyLoader: false,

      resendLoader: false,
      time: {},
      seconds: 60,
      firsttime: true,
    });
  }

  /* Resend verification code */
  resendVerificationCode = () => {
    let { session, vendor } = this.props;

    this.startTimer();
    let { phone } = this.state;

    phone = phone.replace("-", "");
    phone = phone.replace(" ", "");

    this.props.sendSms(this.props.session, phone, true, (callback) => {
      if (callback.success === 1) {
        toast.success(LocaleStrings.otp_has_been_sent_success);
      } else {
        toast.error(callback.message);
      }
    });
  };

  /***** FORM SUBMIT *****/
  onFormSubmit(values) {
    let actiontaken = values.actiontaken;
    if (actiontaken == "send") {
      if (values.phone) {
        this.startTimer(); // Satrt Timer which disabled send code btn
        this.setState({ sendLoader: true });
        let phone = values.phone;
        phone = removePhoneNoSpecialChar(phone); // This function will remove special chars from phone number
        this.props.sendSms(this.props.session, phone, false, (callback) => {
          this.setState({ sendLoader: false });
          if (callback.success === 1) {
            toast.success(LocaleStrings.send_code_success);
          } else {
            toast.error(callback.message);
          }
        });
      } else {
        toast.error(LocaleStrings.verification_code_mismatch);
      }
    } else if (actiontaken == "verify") {
      if (!this.state.isVerified) {
        this.setState({ verifyLoader: true });
        let otp = values.otp;

        this.props.verifyOtp(this.props.session, otp, (callback) => {
          this.setState({ verifyLoader: false });
          if (callback.success === 1) {
            this.setState({ isVerified: true });
            this.setState({ fieldsEmptyState: true });
            toast.success(LocaleStrings.otp_verified_successfully);
          } else {
            this.setState({ fieldsEmptyState: true });
            toast.error(LocaleStrings.otp_not_verified);
          }
        });
      }
    } else if (actiontaken == "update") {
      if (this.state.isVerified) {
        this.setState({ btnLoader: true });
        let data = {};
        let phone = values.phone;
        phone = removePhoneNoSpecialChar(phone); // This function will remove special chars from phone number
        data.phone = phone;
        data.userid = values.userid;
        this.props.updateStoreUsers(this.props.session, data, (callback) => {
          if (callback.status === 1) {
            this.setState({ btnLoader: false });
            this.setState({ verificStateCode: "" });
            this.setState({ fieldsEmptyState: true });
            this.setState({ isVerified: false });

            toast.success(LocaleStrings.phone_updated_successfully);

            this.props.isProfileinfoCommonOpen(false);

            // Fetch Vendor Details With LoginIdStore
            this.props.fetchUsersDeatils(
              this.props.session,
              (callBackResponce) => {}
            );
          }
        });
      } else {
        toast.error(LocaleStrings.mobile_not_verified);
      }
    }
  }

  clickSubmit = (key) => {
    this.props.autofill("actiontaken", key);
  };

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
    this.props.countryPhoneLength(country.dialCode);
  };

  handleChange = (otp) => {
    if (otp.length == 6) {
      this.setState({ fieldsEmptyState: false });
    } else {
      this.setState({ fieldsEmptyState: true });
    }
  };

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      user,
      vendor,
      phoneLengthCountry,
    } = this.props;
    let { resendLoader } = this.state;

    return (
      <>
        <form
          className=""
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
          <div className="md:px-6 sm:px-0 xs:px-0 space-y-8">
            <div>
              <p className="text-xl text-custblack">
                {LocaleStrings.current_number}
              </p>
              <p className="text-sm font-medium text-ternary py-2">
                {/* {LocaleStrings.current_number} */}
                {vendor.phone !== null &&
                vendor.phone !== "null" &&
                vendor.phone !== ""
                  ? vendor.phone
                  : LocaleStrings.add_phone_number}
              </p>
              <p className=" text-sm text-ternary">
                {LocaleStrings.update_phone_number_text}
              </p>
            </div>
            <div className="">
              <Field
                name={"phone"}
                label={LocaleStrings.phone_number}
                mandatory="true"
                type="text"
                component={this.renderPhonewithFlag}
                className="cust-input-field"
                classNameLabel="text-box-lable"
                phone={this.state.phone}
                countryCodeEditable={false}
                phoneChange={this.phoneChange}
              />
            </div>

            {/* Send Code Button */}
            {this.state.firsttime == true ? (
              <div>
                <button
                  className={classNames(
                    resendLoader ? "btn-disabled" : "btn-primary",
                    ""
                  )}
                  // type="submit"
                  type={!resendLoader ? "submit" : "button"}
                  onClick={() => this.clickSubmit("send")}
                  disabled={this.state.sendLoader ? true : false}>
                  {this.state.sendLoader ? (
                    <svg
                      className="animate-spin h-5 w-5 rounded-full mr-3"
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  ) : (
                    ""
                  )}
                  <span className="text-sm">{LocaleStrings.send_code}</span>
                </button>
              </div>
            ) : (
              ""
            )}

            {/* Resend? */}
            <div>
              {resendLoader === false ? (
                <>
                  {this.state.firsttime == false ? (
                    <p className="py-2 text-center">
                      <span
                        className="verify-email-resend"
                        onClick={this.resendVerificationCode}>
                        {LocaleStrings.resend}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <p className="py-2 text-center">
                  <span className="verify-email-resend-disabled">
                    {LocaleStrings.resend}
                    {" : "}
                    {this.state.time.s}
                  </span>
                </p>
              )}
            </div>

            <div>
              <p className=" text-sm font-medium text-custblack">
                {LocaleStrings.phone_number_verfication_text}
              </p>
            </div>
            {/* Verification input boxes */}
            <div className="flex --justify-center space-x-4 overflow-x-auto">
              <Field
                name="otp"
                type="text"
                component={this.renderOTP}
                mandatory="false"
                maxlength="1"
                onChange={this.handleChange}
              />
            </div>
            {/* Verify button */}
            <div>
              <button
                className={classNames(
                  this.state.fieldsEmptyState ? "btn-disabled" : "btn-primary",
                  ""
                )}
                type="submit"
                onClick={() => this.clickSubmit("verify")}
                disabled={this.state.fieldsEmptyState ? true : false}>
                {this.state.verifyLoader ? (
                  <svg
                    className="animate-spin h-5 w-5 rounded-full mr-3"
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                ) : (
                  ""
                )}
                <span className="text-sm">{LocaleStrings.verify}</span>
              </button>
            </div>
          </div>
          <div className="px-6 py-12 border-t-2 border-quaternary">
            {/* Footer */}
            <button
              className="btn-primary"
              type="submit"
              onClick={() => this.clickSubmit("update")}>
              {this.state.btnLoader ? (
                <svg
                  className="animate-spin-medium h-5 w-5 rounded-full mx-2"
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">
                {LocaleStrings.update_phone_number}
              </span>
            </button>
          </div>
        </form>
      </>
    );
  }
}

function validate(values, ownProps) {
  var errors = {};

  if (values.actiontaken == "send") {
    var requiredFields = ["phone"];
    requiredFields.forEach((field) => {
      if (!values[field] || values[field] === "") {
        errors[field] = LocaleStrings.required;
      }
    });

    // if (_.size(values.phone) < 10) {
    //   errors.phone = LocaleStrings.invalid_number;
    // }
  }

  let countrycode = ownProps.phoneLengthCountry;
  if (countrycode == "974" && values.phone) {
    let phone = values.phone.replace(/[- )( +]/g, "");
    if (isValidQatarPhoneNumber("+" + phone) == false) {
      errors.phone = "Please enter valid phone number";
    }
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    isOpenProfileInfoModal: state.isOpenProfileInfoModal,
    vendor: state.vendorDetails.data,
    phoneLengthCountry: state.phoneLengthCountry,
    initialValues: {
      userid: state.vendorDetails
        ? state.vendorDetails.data
          ? state.vendorDetails.data.userid
          : ""
        : "",
      phone: "",
    },
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  updateStoreUsers,
  sendSms,
  verifyOtp,
  countryPhoneLength,
})(
  reduxForm({
    validate,
    form: "updatePhoneNumForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddPhoneSection)
);
