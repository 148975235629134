import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import Lightbox from "react-image-lightbox";
import _ from "lodash";
import { classNames } from "@commonFunction";
import Pagination from "react-js-pagination";
import { SearchIcon } from "@heroicons/react/solid";
import "react-image-lightbox/style.css";

import { PERSONAL_USER_REDIRECT_TO, OTHERS_USER_REDIRECT_TO } from "@constant";

// Import BaseLoader
import BaseLoader from "@baseLoader";

// Import Actions
import {
  fetchVendorOrderList,
  salesmanagementStatboxSelected,
} from "@salesmanagementActions";
import { onSidebarMenuSelected } from "@sidebarActions";

// Component Import
import NewOrderTableList from "./neworderlist";
import NewOrderDetails from "./components/neworderdetails";
const limit = 10;
class SalesCancRetuRepl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_salescancreturepl,
      currentCancCretnReplTab: 0,
      currentCancCretnReplTabText: "cancelled",
      pageNumber: 1,
      searchText: "",
      filter: "", // Dont delete [only for param we need it]

      //Light box
      isLightBoxOpen: false,
      photoIndex: 0,
      images: [],
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { pageNumber, filter } = this.state;
    let { session } = this.props;
    let { usertype } = session;

    if (usertype === "personal") {
      this.props.onSidebarMenuSelected(2);
      this.props.history.push(PERSONAL_USER_REDIRECT_TO);
    }
    /*
      Call API to Fetch Data

      New Order,DeliveredReplace/Return/Cancel
      vendor-datascript/orders
      Params: {"status": "<you know>","usertype":"web","type":"listing","limit":<num of rows>,"page":<cur page>} => For Listing
      {"status": "<you know>","usertype":"web","type":"search","searchterm":<text>} => For Listing
    
    */

    this.props.fetchVendorOrderList(
      this.props.session,
      "cancelled", // status
      "web", // usertype
      "listing", // type
      limit, // <limit :num of rows>
      pageNumber, // <page : cur page>
      "", // searchterm _>
      filter, // filter
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
  }

  componentWillUnmount() {
    this.setState({
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_salescancreturepl,
      currentCancCretnReplTab: 0,
      currentCancCretnReplTabText: "cancelled",
      pageNumber: 1,
      searchText: "",
      filter: "",
      //Light box
      isLightBoxOpen: false,
      photoIndex: 0,
      images: [],
    });
  }

  updatePageNumber = (pageNum) => {
    this.setState({ pageNumber: pageNum });
  };

  updateSearchText = (searchKey) => {
    this.setState({ searchText: searchKey });
  };

  updateFilter = (filterKey) => {
    this.setState({ filter: filterKey });
  };

  /* Pagination call back */
  paginationCallback = (pageNumber) => {
    let { newOrderFilter, currentCancCretnReplTabText } = this.state;
    this.setState({ loading: true });

    this.props.fetchVendorOrderList(
      this.props.session,
      currentCancCretnReplTabText, // status
      "web", // usertype
      "listing", // type
      limit, // <limit :num of rows>
      pageNumber, // <page : cur page>
      "", // searchterm _>
      newOrderFilter, // filter
      (CallBackResponse) => {
        this.setState({ loading: false });
      }
    );
    this.setState({ ...this.state, pageNumber });
  };

  // /* Search */
  handleSearch = (event) => {
    let { currentCancCretnReplTabText, filter } = this.state;
    var searchText = event.target.value;
    var pageNumber = 1;

    if (searchText === "") {
      // this.setState({ loading: true });
      /*
      Paginated list
       Params: {"status": "<you know>","usertype":"web","type":"listing","limit":<num of rows>,"page":<cur page>} => For Listing
      */
      this.props.fetchVendorOrderList(
        this.props.session,
        currentCancCretnReplTabText, // status
        "web", // usertype
        "listing", // type
        limit, // <limit :num of rows>
        pageNumber, // <page : cur page>
        "",
        filter, // filter
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    } else {
      /*
      search list without pagination
       {"status": "<you know>","usertype":"web","type":"search","searchterm":<text>} => For Listing
      */
      this.props.fetchVendorOrderList(
        this.props.session,
        currentCancCretnReplTabText, // status
        "web", // usertype
        "search", // type
        limit, // <limit :num of rows>
        pageNumber, // <page : cur page>
        searchText, // searchterm
        filter, // filter
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    }
    this.setState({ searchText, pageNumber });
  };

  // Date Tab Nav Chnage {Daily,weekly,Monthly}
  _handleCancCretnReplTab = (e, selectedTabPos) => {
    // Reinitialize page no. when tab changes
    this.setState({ loading: true, pageNumber: 1 });

    let { searchText, pageNumber, filter } = this.state;

    let textTocall =
      selectedTabPos === 0
        ? "cancelled"
        : selectedTabPos === 1
        ? "return"
        : "replace";
    this.setState({ currentCancCretnReplTab: selectedTabPos });
    this.setState({ currentCancCretnReplTabText: textTocall });

    if (searchText === "") {
      // Listing
      this.props.fetchVendorOrderList(
        this.props.session,
        textTocall, // status
        "web", // usertype
        "listing", // type
        limit, // <limit :num of rows>
        1, // <page : cur page>
        "", // searchterm _>
        filter, // filter
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    } else {
      // Search
      this.props.fetchVendorOrderList(
        this.props.session,
        textTocall, // status
        "web", // usertype
        "search", // type
        limit, // <limit :num of rows>
        1, // <page : cur page>
        searchText, // searchterm _>
        filter, // filter
        (CallBackResponse) => {
          this.setState({ loading: false });
        }
      );
    }
  };

  // Back Button Click
  _handleBack() {
    let { session } = this.props;
    let { usertype } = session;

    this.props.salesmanagementStatboxSelected("");
    this.props.history.push(
      usertype === "personal"
        ? PERSONAL_USER_REDIRECT_TO
        : OTHERS_USER_REDIRECT_TO
    );
  }

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  // // Render Order List Tbody
  renderOrderList() {
    let { vendorOrderListStore } = this.props;
    let { searchText, pageNumber, currentCancCretnReplTabText } = this.state;
    let pageDataLength = vendorOrderListStore.data.length;

    return _.map(vendorOrderListStore.data, (orderObj, index) => {
      return (
        <NewOrderTableList
          key={orderObj.orderid}
          orderObj={orderObj}
          position={index}
          callFrom={currentCancCretnReplTabText}
          pagination={this.paginationCallback}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
        />
      );
    });
  }

  // Light box open show function
  setIsLightBoxOpen = () => {
    let { isLightBoxOpen } = this.state;
    this.setState({ isLightBoxOpen: !isLightBoxOpen });
  };

  // Set images for light box
  setImagesForLightBOx = (imageData) => {
    this.setState({ images: imageData });
  };

  // Set image position [Note : it will open selected index image first ]
  setPhotoIndex = (index) => {
    this.setState({ photoIndex: index });
  };

  render() {
    let { isLightBoxOpen, photoIndex, images } = this.state;
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>{this.renderLoader()}</>
            ) : (
              <>
                <div className="py-6 bg-custWhite ">
                  {/* Header Part */}
                  <div className="grid gap-4 grid-cols-1 px-6 md:grid-cols-3 gap-3 sm:grid-cols-2 ">
                    <div align="left" className="flex icon-hover-transition">
                      <img
                        loading="lazy"
                        className={" h-6 w-6 cursor-pointer"}
                        src={"/images2/common/arrow.svg"}
                        onClick={(e) => this._handleBack(e, "")}
                      />
                      <p
                        className="text-primary mx-2 text-l font-bold cursor-pointer"
                        onClick={(e) => this._handleBack(e, "")}>
                        {LocaleStrings.canceled_orders}
                      </p>
                    </div>
                    <div className="justify-items-center">
                      <label htmlFor="search-field" className="sr-only">
                        {LocaleStrings.search}
                      </label>
                      <div className="relative w-full text-ternary focus-within:text-secondary ">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="bg-quaternary revenue-report-search-field"
                          placeholder={LocaleStrings.search}
                          type="search"
                          name="search"
                          value={this.state.searchText}
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                    <div className="mx-5 mt-2 flex gap-4 flex-row-reverse "></div>
                  </div>
                </div>
                <div className="mx-6 mt-2">
                  <nav
                    className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                    aria-label="Tabs">
                    {this.props.salesCancRetuReplTabs.map((tab, tabIdx) => (
                      <a
                        key={tab.name}
                        onClick={(e) =>
                          this._handleCancCretnReplTab(e, tab.position)
                        }
                        className={classNames(
                          tab.position == this.state.currentCancCretnReplTab
                            ? "text-primary"
                            : "text-ternary hover:text-secondary",
                          tabIdx === 0 ? "rounded-l-lg" : "",
                          tabIdx === this.props.salesCancRetuReplTabs.length - 1
                            ? "rounded-r-lg"
                            : "",
                          "saler-date-tabs"
                        )}
                        aria-current={
                          tab.position == this.state.currentCancCretnReplTab
                            ? "page"
                            : undefined
                        }>
                        <span>{tab.name}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            tab.position == this.state.currentCancCretnReplTab
                              ? "bg-primary"
                              : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5"
                          )}
                        />
                      </a>
                    ))}
                  </nav>
                </div>

                {/* Table Headers */}

                <div className="relative bg-quaternary mt-2 pt-2 pb-8 px-4 sm:px-6 lg:pt-2 lg:pb-8 lg:px-8">
                  <div className="relative max-w-7xl mx-auto mt-10">
                    <div className="flex flex-col">
                      <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full ">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            {_.isEmpty(this.props.vendorOrderListStore.data) ? (
                              <div className="center-item lg:my-48 text-lg text-ternary">
                                {LocaleStrings.no_result_found}
                              </div>
                            ) : (
                              <>
                                <table className="min-w-full divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.title}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_id}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_date}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_by}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_total}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.quantity}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.status}
                                        {" / "}
                                        {LocaleStrings.sale_status}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="bg-white divide-y divide-gray-200">
                                    {this.renderOrderList()}
                                  </tbody>
                                </table>
                                {this.state.searchText === "" ? (
                                  <Pagination
                                    activePage={this.state.pageNumber}
                                    itemsCountPerPage={limit}
                                    totalItemsCount={parseInt(
                                      this.props.vendorOrderListStore.count
                                    )}
                                    pageRangeDisplayed={limit}
                                    onChange={this.paginationCallback}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <NewOrderDetails
                  updatePageNumber={this.updatePageNumber}
                  updateSearchText={this.updateSearchText}
                  updateFilter={this.updateFilter}
                  headerText={LocaleStrings.order_details}
                  orderCallFrom={"clubbed"}
                  innerTab={
                    this.state.currentCancCretnReplTab !== null &&
                    this.state.currentCancCretnReplTab !== "" &&
                    this.state.currentCancCretnReplTab !== "null"
                      ? this.state.currentCancCretnReplTab
                      : ""
                  } // Only for clubbed tab , so we can Identify [cancelled / returns / replaced]
                  setIsLightBoxOpen={(e) => this.setIsLightBoxOpen(e)} // Light box open / close
                  setImagesForLightBOx={(e) => this.setImagesForLightBOx(e)}
                  // Photo Index
                  setPhotoIndex={(e) => this.setPhotoIndex(e)}
                />
                {isLightBoxOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={
                      images[(photoIndex + images.length - 1) % images.length]
                    }
                    //Light box
                    onCloseRequest={() =>
                      this.setState({
                        isLightBoxOpen: false,
                      })
                    }
                    onMovePrevRequest={() =>
                      // this.setState({
                      //     photoIndex: (photoIndex + images.length - 1) % images.length,
                      // })
                      this.setState({
                        photoIndex:
                          (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      // this.setState({
                      //     photoIndex: (photoIndex + 1) % images.length,
                      // })
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                  />
                )}
              </>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    salesmanStatboxSelected: state.salesmanStatboxSelected
      ? state.salesmanStatboxSelected
      : "",
    vendorOrderListStore: _.isEmpty(state.vendorOrderListStore)
      ? {}
      : state.vendorOrderListStore,
    salesCancRetuReplTabs: state.salesCancRetuReplTabs
      ? state.salesCancRetuReplTabs
      : [],
  };
}

export default connect(mapStateToProps, {
  salesmanagementStatboxSelected,
  fetchVendorOrderList,
  onSidebarMenuSelected,
})(SalesCancRetuRepl);
