import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

// Import Components

// Import Actions
import { selectedProfileSettingsMenu } from "../actions/index";

class CommonHeaderProfileSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  /**** Handle Back Button ****/
  _handleBack(e, intPosition) {
    this.props.selectedProfileSettingsMenu(this.props.session, intPosition);
  }

  render() {
    return (
      <>
        <div className="py-6 bg-custWhite responsivePadding">
          {/* Header Part */}
          <div className="grid gap-4 grid-cols-1 px-6 md:grid-cols-2 gap-3 sm:grid-cols-2 ">
            <div
              align="left"
              className="flex cursor-pointer icon-hover-transition"
              onClick={(e) => this._handleBack(e, 9)}
            >
              <img
                loading="lazy"
                className={" h-6 w-6 "}
                src={"/images2/common/arrow.svg"}
              />
              <p className="text-primary mx-2 text-l font-bold">
                {this.props.commonHeaderText}
              </p>
            </div>
            <div className="justify-items-center"></div>
            <div className="mx-5 mt-2 flex gap-4 flex-row-reverse "></div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let { commonHeaderText } = ownProps;
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    commonHeaderText,
  };
}

export default connect(mapStateToProps, {
  selectedProfileSettingsMenu,
})(CommonHeaderProfileSettings);
