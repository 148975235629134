import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
// Import Actions
import {
  isFollowersDetailsOpen,
  setFollowunfollow,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  viewPublicProfile,
  profileViewNavigation,
  emptySharedWishlist,
  selectedFollowersShearedWishlist,
  openReportModal,
} from "@profileSettingsActions";

// Functions Import
import { classNames, isEmpty } from "@commonFunction";

// Component Import
import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";
import NoDataFoundComponet from "../../../../../common/no-data-found-basecomponet";
import CurrentFollowerList from "./currentfollowerlist";
import CurrentSharedWishList from "./currentsharedwishlist";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog, Menu } from "@headlessui/react";
import {
  XIcon,
  ArrowLeftIcon,
  DotsHorizontalIcon,
} from "@heroicons/react/outline";

class FollowerDetails extends Component {
  constructor(props) {
    super(props);
    // this.myRef = React.createRef();
    this.state = {
      loading: true,
      couponText: "",
      currentTab: 0,
      followLoader: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillUnmount() {
    this.props.profileViewNavigation([]);
  }

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    // Clear Single Discount Object (Pointing to)

    this.props.isFollowersDetailsOpen(false);
    this.props.profileViewNavigation([]);
  }

  /**** Tab Change ****/
  _handleDateTab(e, position) {
    this.setState({ currentTab: position });
  }

  /**** Follow Unfollow ****/
  _handleFollowUnfollow(followerid) {
    let { isLoggedIn, session } = this.props;

    if (isLoggedIn) {
      this.setState({ followLoader: true });

      this.props.setFollowunfollow(
        this.props.session,
        followerid,
        (callback) => {
          if (callback.status) {
            this.props.selectedFollowerUserDetails(
              this.props.session,
              followerid,
              (callback) => {}
            );

            this.props.selectedFollowersDetails(
              this.props.session,
              followerid,
              (callback) => {}
            );

            this.props.viewPublicProfile(session, followerid, (res) => {
              this.setState({ followLoader: false });
            });
          }
        }
      );
    } else {
      this.props.history.push("/login");
    }
  }

  previousView() {
    let { navigateUser, session } = this.props;

    let userArr = navigateUser;
    if (userArr.length >= 1) {
      let userid = _.nth(userArr, -2);
      let removeduserid = _.nth(userArr, -1); //latest added

      this.props.emptySharedWishlist();
      this.props.selectedFollowerUserDetails(session, userid, (callback) => {});
      this.props.selectedFollowersDetails(session, userid, (callback) => {});
      this.props.selectedFollowersShearedWishlist(
        session,
        userid,
        (callback) => {}
      );
      // let newArr = userArr.filter((el) => el !== userid);
      let newArr = userArr.filter((el) => el !== removeduserid); //latest added
      this.props.profileViewNavigation(newArr);
    }
  }

  reportAbusive(userid) {
    let { isLoggedIn } = this.props;
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.report_confirm_consumer_title,
        message: LocaleStrings.report_confirm_consumer_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              this.closeModal();
              //this.props.openReportModal(true, userid, "consumer");
              this.props.openReportModal(true, userid);
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    }
  }

  render() {
    let { session, navigateUser, publicProfile } = this.props;

    let { followLoader } = this.state;
    let modalHeadText = "";
    let productImageUrl = "";
    let followerName = "";
    let userid = "";
    let followbtn = false;
    let isreported = 0;
    if (Object.keys(this.props.storeCurrentMyFollowersUserData).length > 0) {
      let valueObject = this.props.storeCurrentMyFollowersUserData[0];

      modalHeadText = valueObject.username + "'s Profile";
      productImageUrl = `${BASE_IMAGES_URL}/users/${valueObject.userid}.jpg?api_key=${APP_API_KEY}`;
      followerName =
        session.userid === valueObject.userid
          ? LocaleStrings.text_you
          : valueObject.username;
      userid = valueObject.userid;
      followbtn = valueObject.userid == session.userid ? false : true;
      isreported = _.size(valueObject.reportabuse);
    }
    let restrictreport = 0;
    if (publicProfile && publicProfile.data && publicProfile.data.length > 0) {
      restrictreport = publicProfile.data[0].reportabuse;
    }
    // console.log("publicProfile", publicProfile);
    return (
      <>
        <Transition.Root
          show={this.props.followersDetailsModalOpen}
          as={Fragment}>
          <Dialog
            // initialFocus={this.myRef}
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.followersDetailsModalOpen}
            onClose={this.closeModal1}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-4 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div className="flex flex-wrap items-center content-center justify-center mt-4">
                      {navigateUser.length > 1 ? (
                        <ArrowLeftIcon
                          className="h-6 w-6 cursor-pointer"
                          aria-hidden="true"
                          onClick={() => this.previousView()}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-span-2 flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {modalHeadText}
                      </div>
                    </div>
                    <div className=" sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}
                        // ref={this.myRef}
                      >
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  {/* Modal Body */}
                  <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-2 px-4 text-center sm:px-6 lg:px-8 lg:py-2">
                      {userid != session.userid ? (
                        <div className="flex justify-end items-end">
                          <Menu
                            as="div"
                            className="relative inline-block text-left">
                            {({ open }) => (
                              <>
                                <div className="">
                                  <Menu.Button className="btn-card">
                                    <span className="sr-only">
                                      Open options
                                    </span>
                                    <DotsHorizontalIcon
                                      className="h-5 w-5 hover:shadow"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95">
                                  <Menu.Items
                                    static
                                    className="origin-top-right absolute right-0 mt-2 w-148 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50">
                                    <div className="py-1 divide-y z-50 w-40">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            //report_abuse_by_reportby

                                            onClick={
                                              () =>
                                                isreported > 0 ||
                                                restrictreport == 1
                                                  ? toast.error(
                                                      LocaleStrings.already_reported
                                                    )
                                                  : this.reportAbusive(userid) // report on user
                                            }
                                            className={classNames(
                                              active ? "bg-quaternary " : " ",
                                              "block px-4 py-2 text-sm text-primary"
                                            )}>
                                            {LocaleStrings.report_as_abusive}
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="space-y-12">
                        <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                          <img
                            loading="lazy"
                            className="mx-auto h-20 w-20 rounded-full xl:w-36 xl:h-36"
                            src={productImageUrl}
                            alt="user-image"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/images2/common/user.png";
                            }}
                          />
                          <p className="text-xl text-ternary">{followerName}</p>
                          {/* latest code */}
                          {/* <div className="grid grid-cols-6 gap-4">
                            <div
                              className="col-start-3 col-span-2 ... "
                              align="center"
                            >
                              {followbtn && publicProfile?.data?.length > 0 ? (
                                <div className="mt-5">
                                  <button
                                    className="btn-primary"
                                    onClick={() =>
                                      this._handleFollowUnfollow(
                                        publicProfile.data[0].userid
                                      )
                                    }
                                  >
                                    {followLoader ? (
                                      <svg
                                        className="animate-spin-medium h-5 w-5 rounded-full mr-3"
                                        viewBox="0 0 1024 1024"
                                        focusable="false"
                                        data-icon="loading"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                      </svg>
                                    ) : publicProfile.data[0].following ? (
                                      LocaleStrings.btn_unfollow
                                    ) : (
                                      LocaleStrings.btn_follow
                                    )}
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Tabs */}
                  <div className="mx-6 mt-2">
                    <nav
                      className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                      aria-label="Tabs">
                      {this.props.myFolloersTabList.map((tab, tabIdx) => (
                        <a
                          key={tab.name}
                          onClick={(e) => this._handleDateTab(e, tab.position)}
                          className={classNames(
                            tab.position == this.state.currentTab
                              ? "text-primary"
                              : "text-ternary hover:text-secondary",
                            tabIdx === 0 ? "rounded-l-lg" : "",
                            tabIdx === this.props.myFolloersTabList.length - 1
                              ? "rounded-r-lg"
                              : "",
                            "saler-date-tabs"
                          )}
                          aria-current={
                            tab.position == this.state.currentTab
                              ? "page"
                              : undefined
                          }>
                          <span>{tab.name}</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              tab.position == this.state.currentTab
                                ? "bg-primary"
                                : "bg-transparent",
                              "absolute inset-x-0 bottom-0 h-0.5"
                            )}
                          />
                        </a>
                      ))}
                    </nav>

                    {this.state.currentTab == 0 ? (
                      //
                      Object.keys(this.props.currentShearedWishlist).length >
                      0 ? (
                        <CurrentSharedWishList />
                      ) : (
                        <NoDataFoundComponet
                          text={LocaleStrings.no_shared_wishlist}
                          subtext={LocaleStrings.user_havenot_shared_wishlist}
                        />
                      )
                    ) : Object.keys(this.props.storeCurrentMyFollowersData)
                        .length > 0 ? (
                      <CurrentFollowerList />
                    ) : (
                      <NoDataFoundComponet
                        text={LocaleStrings.no_following}
                        subtext={LocaleStrings.user_not_following_user}
                      />
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    followersDetailsModalOpen: state.isFollowersDetailsModalOpen,
    storeCurrentMyFollowersData: state.storeCurrentMyFollowersData
      ? state.storeCurrentMyFollowersData.data
        ? state.storeCurrentMyFollowersData.data
        : []
      : [],
    storeCurrentMyFollowersUserData: state.storeCurrentMyFollowersUserData
      ? state.storeCurrentMyFollowersUserData.data
        ? state.storeCurrentMyFollowersUserData.data
        : []
      : [],
    myFolloersTabList: state.myFolloersTabList,
    currentShearedWishlist: state.currentShearedWishlist
      ? state.currentShearedWishlist.data
        ? state.currentShearedWishlist.data
        : []
      : [],
    navigateUser: state.navigateUser,
    publicProfile: state.publicProfile,
  };
}

export default connect(mapStateToProps, {
  isFollowersDetailsOpen,
  setFollowunfollow,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  profileViewNavigation,
  viewPublicProfile,
  emptySharedWishlist,
  selectedFollowersShearedWishlist,
  openReportModal,
})(FollowerDetails);
