import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "@language";

import moment from "moment-timezone";
import toast from "react-hot-toast";
import { MIN_DATE_FORMAT } from "@constant";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
// Common Functions
import { isEmpty, classNames } from "@commonFunction";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { parse } from "date-fns";
// Import Actions
import {
  isProductDiscountopen,
  productAddedDiscount,
  fetchDiscountList,
} from "@productmanagementActions";
import { deleteDiscountWithID } from "@discountsmanagementActions";

class Productdiscount extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: "",
      discTypeState: "percentage",
      isBtnDisable: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.deleteLocalDiscount = this.deleteLocalDiscount.bind(this);
    this.handelDiscountType = this.handelDiscountType.bind(this);
  }

  handelDiscountType(param) {
    this.setState({ discTypeState: param });
  }

  // Delete Dicount Locally
  deleteLocalDiscount() {
    let { prodDiscAddedData, session, selectedSubcategorids } = this.props;

    /*
    Delete DISCOUNT will delete discount from db if discount id is available
    other ways just remove from redux
    */
    if (!_.isEmpty(prodDiscAddedData) && _.has(prodDiscAddedData, "id")) {
      this.setState({ isBtnDisable: true });

      let discountId = prodDiscAddedData.id;
      // Delete from store & db
      this.props.deleteDiscountWithID(session, discountId, (callback) => {
        if (callback.status === 1) {
          /**** Fetch discount price Based On Category and subcategory selected ****/
          this.props.fetchDiscountList(
            this.props.session,
            selectedSubcategorids[1],
            selectedSubcategorids[0],
            "",
            (callBack) => {}
          );

          // Delete from store
          this.props.productAddedDiscount({});
          this.props.autofill("discount_title", "");
          this.props.autofill("validity_from", "");
          this.props.autofill("validity_end", "");
          this.props.autofill("discount_percentage", "");
          this.setState({ discTypeState: "percentage", isBtnDisable: false });

          this.closeModal();
        } else {
          this.setState({ isBtnDisable: false });
          toast.error(LocaleStrings.something_went_wrong);
        }
      });
    } else {
      // Delete from store
      this.props.productAddedDiscount({});
      this.props.autofill("discount_title", "");
      this.props.autofill("validity_from", "");
      this.props.autofill("validity_end", "");
      this.props.autofill("discount_percentage", "");
      this.setState({ discTypeState: "percentage", isBtnDisable: false });
    }
  }

  renderField(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element ${
      touched && error ? "text-danger text-xs" : ""
    }`;
    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ open: true });
    }
    if (nextProps.hedaer) {
      this.setState({ headerText: nextProps.hedaer });
    }
  }

  closeModal() {
    this.setState({ open: false });
    this.props.isProductDiscountopen(false);
  }

  /**** Fake Close ****/
  closeModal1() {}

  // Form Submit
  onFormSubmit(values) {
    if (Number.isNaN(parseFloat(values.discount_percentage))) {
      toast.error(LocaleStrings.discount_percentage_not_valide);
      return false;
    } else if (
      parseFloat(values.discount_percentage) < 0.1 ||
      parseFloat(values.discount_percentage) > 100
    ) {
      toast.error(LocaleStrings.discount_percentage_not_valide);
      return false;
    } else if (
      values.discount_title === null ||
      values.discount_title === "null" ||
      values.discount_title === ""
    ) {
      toast.error(LocaleStrings.discount_title_required);
      return false;
    } else if (!values.hasOwnProperty("discount_title")) {
      toast.error(LocaleStrings.discount_title_required);
      return false;
    } else {
    }

    if (
      moment(values.validity_from).unix() >= moment(values.validity_end).unix()
    ) {
      toast.error(LocaleStrings.invalid_date_range);
      return false;
    } else {
      values["discounttype"] = this.state.discTypeState;
      this.props.productAddedDiscount(values);
      this.closeModal();
    }
  }

  componentWillUnmount() {
    this.state = {
      open: false,
      headerText: "",
      discTypeState: "percentage",
      isBtnDisable: false,
    };
  }

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      prodDiscAddedData,
      productData,
    } = this.props;

    return (
      <Transition.Root show={this.state.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.state.open}
          onClose={this.closeModal1}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                style={{ padding: 0 }}>
                {/* Modal Header */}
                <div className="grid grid-cols-4 border-b-2 border-quaternary pb-4 sticky-header z-10">
                  <div></div>
                  <div className="flex flex-wrap col-span-2 content-center justify-center mt-4">
                    <div className="text-lg leading-6 font-medium text-secondary">
                      {isEmpty(prodDiscAddedData)
                        ? this.state.headerText
                        : LocaleStrings.edit_discount}
                    </div>
                  </div>
                  <div className=" absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="btn-closeModal"
                      onClick={this.closeModal}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <div className="mx-14 my-4 sm:flex-row-reverse">
                  <form
                    className="space-y-6"
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    <fieldset className="space-y-5">
                      <div>
                        <Field
                          name="discount_title"
                          label={LocaleStrings.discount_title}
                          placeholder={LocaleStrings.enter_discount_title}
                          type="text"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-medium text-secondary"
                        />
                      </div>
                      <div>
                        <Field
                          name="validity_from"
                          label={LocaleStrings.validity_starting_date}
                          placeholder={LocaleStrings.select_validity_from}
                          component={this.renderDateTimePickerWithLabel1}
                          minDate={
                            _.has(initialValues, ["id"])
                              ? new Date(
                                  moment(productData.createdon).format(
                                    MIN_DATE_FORMAT
                                  )
                                )
                              : new Date(moment().format(MIN_DATE_FORMAT))
                          }
                          //component={this.renderDateTimePickerWithLabel}
                          mandatory="true"
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="off"
                          // selected={moment()}
                          className="cust-input-field"
                          classNameLabel="text-box-lable"
                        />
                      </div>
                      <div>
                        <Field
                          name="validity_end"
                          label={LocaleStrings.validity_end_date}
                          placeholder={LocaleStrings.select_validity_until}
                          component={this.renderDateTimePickerWithLabel1}
                          mandatory="true"
                          minDate={
                            _.has(initialValues, ["id"])
                              ? new Date(
                                  moment(productData.createdon).format(
                                    MIN_DATE_FORMAT
                                  )
                                )
                              : new Date(moment().format(MIN_DATE_FORMAT))
                          }
                          className="cust-input-field"
                          classNameLabel="text-box-lable"
                        />
                      </div>
                      <div>
                        <Field
                          name="discount_percentage"
                          label={LocaleStrings.discount}
                          placeholder={LocaleStrings.discount}
                          type="text"
                          component={this.renderFieldTextDiscount}
                          labelposition={LABEL_POSITION_TOP}
                          mandatory="true"
                          className="cust-input-field"
                          classNameLabel="text-box-lable"
                          discountType={this.state.discTypeState}
                          handelDiscountType={this.handelDiscountType}
                        />
                      </div>
                      {/* Form submit button */}
                      <div className="">
                        <button type="submit" className="btn-primary">
                          {isEmpty(prodDiscAddedData)
                            ? LocaleStrings.add_new_discount
                            : LocaleStrings.update_discount}
                        </button>
                      </div>
                    </fieldset>
                  </form>
                  {/* Delete discount*/}
                  {/* commented and taken outside */}
                  {/* <div
                    className={classNames(
                      isEmpty(prodDiscAddedData) ? "invisible" : "",
                      "pt-8"
                    )}>
                    <button
                      type="type"
                      onClick={this.deleteLocalDiscount}
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? "cursor-not-allowed" : "",
                        "btn-primary"
                      )}>
                      <svg
                        className={classNames(
                          this.state.isBtnDisable ? "" : "hidden",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        disabled={this.state.isBtnDisable}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.delete_discount}
                    </button>
                  </div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["discount_title", "discount_percentage"];

  let requiredFieldsDtaes = ["validity_from", "validity_end"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  requiredFieldsDtaes.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  if (values["validity_from"] && values["validity_end"]) {
    let validity_from = "";
    let validity_end = "";

    // start date
    if (typeof values.validity_from === "string") {
      validity_from = parse(
        moment(values.validity_from).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    } else {
      validity_from = values.validity_from;
    }

    // end date
    if (typeof values.validity_end === "string") {
      validity_end = parse(
        moment(values.validity_end).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    } else {
      validity_end = values.validity_end;
    }

    if (validity_from.getTime() > validity_end.getTime()) {
      errors["validity_from"] =
        LocaleStrings.validity_end_date_and_greater_date_validation_text;
      return errors;
    }
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isProductDiscountopen: state.isProductDiscountopen,
    prodDiscAddedData: state.prodDiscAddedData ? state.prodDiscAddedData : [],
    initialValues: state.prodDiscAddedData, // Added Discount data ACTION NAME [ productAddedDiscount ]
    selectedSubcategorids: state.selectedSubcategorids, // [ACTION NAME - allSelectedSubcategorisID]
    productData: !_.isEmpty(state.toBeEditproduct)
      ? !_.isEmpty(state.toBeEditproduct.response)
        ? state.toBeEditproduct.response
        : {}
      : {},
  };
}

export default connect(mapStateToProps, {
  isProductDiscountopen,
  productAddedDiscount,
  deleteDiscountWithID,
  fetchDiscountList,
})(
  reduxForm({
    validate,
    form: "addProductDiscountForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Productdiscount)
);
