import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import LocaleStrings from "@language";
import { DATE_FORMAT } from "@constant";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";

// Import Actions
import {
  isCouponManageModalopen,
  fetchProductsByCateSubCate,
  getSelectedCouponsObj,
  deactivateCoupon,
  fetchCouponMaster,
  deleteCoupon,
} from "../actions/index";

// Common Functions
import { converDateIntoLocal, classNames } from "@commonFunction";

class CoupMasterTableList extends Component {
  constructor(props) {
    super(props);

    // All Binds Functions
  }

  /**** Coupon Delete Handel ****/
  _handleDelete(e, coupmasterObj) {
    confirmAlert({
      title: LocaleStrings.delete_confirmation_header_text,
      message: `${LocaleStrings.delete_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteCoupon(
              this.props.session,
              coupmasterObj,
              (callBack) => {
                toast.success(LocaleStrings.coupon_deleted_successfully);

                // Get Coupon Master Data
                this.props.fetchCouponMaster(
                  this.props.session,
                  (callBack) => {}
                );
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  /**** Coupon Deactivate Handle ****/
  _handleDeactivate(e, coupmasterObj) {
    let objBody = {
      id: coupmasterObj.id,
      active: 0,
    };

    confirmAlert({
      title: LocaleStrings.deactivate_confirmation_header_text,
      message: `${LocaleStrings.deactivate_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deactivateCoupon(
              this.props.session,
              objBody,
              (callBack) => {
                toast.success(LocaleStrings.coupon_deactivated_successfully);

                // Get Coupon Master Data
                this.props.fetchCouponMaster(
                  this.props.session,
                  (callBack) => {}
                );
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  /**** Modal Open Function ****/
  openCouponsManagementModal(e, coupmasterObj) {
    if (Object.keys(coupmasterObj.couponrule_by_couponid).length > 0) {
      let categoryid = coupmasterObj.couponrule_by_couponid[0].categoryid;
      let subcategoryid = coupmasterObj.couponrule_by_couponid[0].subcategoryid;

      this.props.fetchProductsByCateSubCate(
        this.props.session,
        categoryid,
        subcategoryid,
        (callBack) => {}
      );
    }

    this.props.getSelectedCouponsObj(coupmasterObj, this.props.session);
    this.props.isCouponManageModalopen(true);
  }

  render() {
    let values = this.props.coupmasterObj;
    let discountArea = this.props.discountArea;
    let actionBtnText =
      Object.keys(values).length > 0
        ? values.active
          ? LocaleStrings.deactivate
          : LocaleStrings.delete
        : "";
    let minCartValText = "";

    if (
      values.min_cart_value !== null &&
      values.min_cart_value !== "" &&
      values.min_cart_value !== 0
    ) {
      minCartValText =
        Object.keys(values).length > 0
          ? values.min_cart_value !== null && values.min_cart_value !== ""
            ? " (" +
              LocaleStrings.coupons_thead_above +
              " $" +
              values.min_cart_value +
              ")"
            : ""
          : "";
    }

    if (values.couponrule_by_couponid.length > 0) {
      if (
        values.couponrule_by_couponid[0].discounareaid !== null &&
        values.couponrule_by_couponid[0].discounareaid !== ""
      ) {
        let afterFindObj = _.find(discountArea, {
          id: values.couponrule_by_couponid[0].discounareaid,
        });
      }
    }
    return (
      <tr key={this.props.position} className="table-hover-transition">
        <td className="sales-table-td">
          <div className="sales-table-td-text">
            {values.title}
            {minCartValText}
          </div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text ">{values.code}</div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text">
            {values.validity_from} - {values.validity_end}
          </div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text ">
            {values.active ? (
              <button
                type="button"
                className="tbl-btn-primary"
                onClick={(e) => this._handleDeactivate(e, values)}>
                {actionBtnText}
              </button>
            ) : (
              <button
                type="button"
                className="tbl-btn-primary"
                onClick={(e) => this._handleDelete(e, values)}>
                {actionBtnText}
              </button>
            )}
            {/* Edit Button */}
            <button
              type="button"
              className={classNames(
                values.active ? "" : "sr-only",

                "tbl-btn-primary mx-2"
              )}
              onClick={(e) => this.openCouponsManagementModal(e, values)}>
              {LocaleStrings.edit}
            </button>
          </div>
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    discountArea: state.discountArea ? state.discountArea.data : [],
  };
}

export default connect(mapStateToProps, {
  isCouponManageModalopen,
  getSelectedCouponsObj,
  fetchProductsByCateSubCate,
  deactivateCoupon,
  fetchCouponMaster,
  deleteCoupon,
})(CoupMasterTableList);
