import React, { Component } from "react";
import { connect } from "react-redux";

import LocaleStrings from "@language";
import moment from "moment-timezone";
import _ from "lodash";
import Pagination from "react-js-pagination";

import { PERSONAL_USER_REDIRECT_TO } from "@constant";

// Import Actions
import {
  salesmanagementStatboxSelected,
  fetchVendorNegotiationList,
} from "@salesmanagementActions";
import { onSidebarMenuSelected } from "@sidebarActions";

// Import BaseLoader
import BaseLoader from "@baseLoader";

// Component Import
import NegotiationTableList from "./negotiationtablelist";
import Negotiate from "../negotiate";
/* This example requires Tailwind CSS v2.0+ */
import { SearchIcon } from "@heroicons/react/solid";

const limit = 10;
const usertype = "vendor";
const source = "web";

class SalesNegotiation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_negotiationsoffers,
      pageNumber: 1,
      searchText: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { pageNumber } = this.state;
    let { session } = this.props;
    let { usertype } = session;

    if (usertype === "personal") {
      this.props.onSidebarMenuSelected(2);
      this.props.history.push(PERSONAL_USER_REDIRECT_TO);
    }
    /*
     // Call API to Fetch Data
        Negotiation -> vendor-datascript/negotiations
        Params: {"usertype":"vendor","source":"web","type":"listing","limit":<num of rows>,"page":<cur page>} => For Listing
        {"usertype":"vendor","source":"web","type":"search","searchterm":<searchterm>} => For Search
    */

    this.props.fetchVendorNegotiationList(
      this.props.session,
      "listing", // type
      limit, // <limit :num of rows>
      pageNumber, // <page : cur page>
      "", // searchterm _>
      (callBack) => {
        this.setState({ loading: false });
      }
    );
  }

  componentWillUnmount() {
    this.setState({
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_negotiationsoffers,
      pageNumber: 1,
      searchText: "",
    });
  }

  /* Pagination call back */
  paginationCallback = (pageNumber) => {
    this.setState({ loading: true });

    this.props.fetchVendorNegotiationList(
      this.props.session,
      "listing", // type
      limit, // <limit :num of rows>
      pageNumber, // <page : cur page>
      "", // searchterm _>
      (callBack) => {
        this.setState({ loading: false });
      }
    );
    this.setState({ ...this.state, pageNumber });
  };

  handleSearch = (event) => {
    var searchText = event.target.value;
    var pageNumber = 1;

    if (searchText === "") {
      // this.setState({ loading: true });
      /* 
      Paginated list
       Params: {"usertype":"vendor","source":"web","type":"listing","limit":<num of rows>,"page":<cur page>} => For Listing
      */
      this.props.fetchVendorNegotiationList(
        this.props.session,
        "listing", // type
        limit, // <limit :num of rows>
        pageNumber, // <page : cur page>
        "", // searchterm _>
        (callBack) => {
          this.setState({ loading: false });
        }
      );
    } else {
      /* 
      search list without pagination
       {"status": "<you know>","usertype":"web","type":"search","searchterm":<text>} => For Listing
      */
      this.props.fetchVendorNegotiationList(
        this.props.session,
        "search", // type
        limit, // <limit :num of rows>
        pageNumber, // <page : cur page>
        searchText, // searchterm _>
        (callBack) => {
          this.setState({ loading: false });
        }
      );
    }
    this.setState({ searchText, pageNumber });
  };

  // Back Button Click
  _handleBack() {
    this.props.salesmanagementStatboxSelected("");
    this.props.history.push("/dashboard/salesmanagement");
  }

  //Arrow function for renderLoader
  renderLoader = () => {
    return <BaseLoader data={this.state.loaderDesc} />;
  };

  // Render Order List Tbody
  renderNegotiationList() {
    let { vendorNegotiationListStore } = this.props;
    let { searchText, pageNumber } = this.state;
    let pageDataLength = vendorNegotiationListStore.data.length;

    return _.map(vendorNegotiationListStore.data, (negotiationObj, index) => {
      return (
        <NegotiationTableList
          key={negotiationObj.orderid}
          negotiationObj={negotiationObj}
          position={index}
          callFrom="nego"
          pagination={this.paginationCallback}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
        />
      );
    });
  }

  render() {
    return (
      <>
        {this.props.user.isfreeze == false ? (
          <>
            {this.state.loading ? (
              <>{this.renderLoader()}</>
            ) : (
              <>
                <div className="py-6 bg-custWhite ">
                  {/* Header Part */}
                  <div className="grid gap-4 grid-cols-1 px-6 md:grid-cols-3 gap-3 sm:grid-cols-2 ">
                    <div align="left" className="flex icon-hover-transition">
                      <img
                        loading="lazy"
                        className={" h-6 w-6 cursor-pointer"}
                        src={"/images2/common/arrow.svg"}
                        onClick={(e) => this._handleBack(e, "")}
                      />
                      <p
                        className="text-primary mx-2 text-l font-bold cursor-pointer"
                        onClick={(e) => this._handleBack(e, "")}>
                        {LocaleStrings.negotiation_offers}
                      </p>
                    </div>
                    <div className="justify-items-center">
                      <label htmlFor="search-field" className="sr-only">
                        {LocaleStrings.search}
                      </label>
                      <div className="relative w-full text-ternary focus-within:text-secondary ">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="bg-quaternary revenue-report-search-field"
                          placeholder={LocaleStrings.search}
                          type="search"
                          name="search"
                          value={this.state.searchText}
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                    <div className="mx-5 mt-2 flex gap-4 flex-row-reverse "></div>
                  </div>
                </div>
                <div className=" bg-quaternary pt-2 pb-8 px-4 sm:px-6 lg:pt-2 lg:pb-8 lg:px-8">
                  <div className=" max-w-7xl mx-auto mt-10">
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full ">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            {_.isEmpty(
                              this.props.vendorNegotiationListStore.data
                            ) ? (
                              <div className="center-item lg:my-48 text-lg text-ternary">
                                {LocaleStrings.no_result_found}
                              </div>
                            ) : (
                              <>
                                <table className="min-w-full divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.title}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.order_by}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.pricing}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.qty}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sales-table-th">
                                        {LocaleStrings.status}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="bg-white divide-y divide-gray-200">
                                    {this.renderNegotiationList()}
                                  </tbody>
                                </table>
                                {this.state.searchText === "" ? (
                                  <Pagination
                                    activePage={this.state.pageNumber}
                                    itemsCountPerPage={limit}
                                    totalItemsCount={parseInt(
                                      this.props.vendorNegotiationListStore
                                        .count
                                    )}
                                    pageRangeDisplayed={limit}
                                    onChange={this.paginationCallback}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Negotiate />
              </>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center">{LocaleStrings.account_freezed}</div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    vendorNegotiationListStore: _.isEmpty(state.vendorNegotiationListStore)
      ? {}
      : state.vendorNegotiationListStore,
  };
}

export default connect(mapStateToProps, {
  salesmanagementStatboxSelected,
  fetchVendorNegotiationList,
  onSidebarMenuSelected,
})(SalesNegotiation);
